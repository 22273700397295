import moment from 'moment';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import { eventList } from 'Enums/eventTypes';
import { taskPriority } from 'Enums/taskPriority';
import * as validators from 'Utils/validators';
import AttachmentFiles from 'SharedComponent/AttachmentFiles';
import DialogBox from 'SharedComponent/DialogBox';
import { FieldSelect, FieldSelectCreation, AsyncSearch } from 'SharedComponent/SelectOption/FieldSelect';
import SubmitButton from 'SharedComponent/SubmitButton';
import { TextArea } from 'SharedComponent/TextArea/TextArea';
import TextField from 'SharedComponent/TextField';
import TimeInputField from 'SharedComponent/TimeInputField';
import ToggleGroup from 'SharedComponent/ToggleGroup';
import { CustomCheckbox } from 'SharedComponent/CustomCheckbox';

import { at } from 'Utils/objectUtils';

import './EditTask.scss';

class EditTask extends Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  closeModal(elem) {
    this.props.reset();
    this.props.dismiss(elem);
  }

  submit(task) {
    if (this.props.initialValues && !this.props.isCreate) {
      this.props.updateTask({ ...task, timezone: this.props.timezone });
    } else {
      this.props.onSave({ ...task, timezone: this.props.timezone });
    }
    this.props.reset();
    this.closeModal();
  }

  get canCreateZoom() {
    return [1, 2].includes(at(this.props.formValues, 'eventype_id')) && this.props.isZoomAuthorized;
  }

  render() {
    const { handleSubmit, pristine, submitting, formValues, initialValues } = this.props;
    if (!this.props.show) return null;
    return (
      <div className='EditTask'>
        <DialogBox
          isVisible
          onClickOut={this.closeModal}
          onClose={() => this.closeModal('icon')}
          className='small-dialog'
          title={initialValues && !initialValues.editing ? 'Update Task' : 'New Task'}
        >
          <form className='p-3' onSubmit={handleSubmit(this.submit)}>
            <div className='pl-3 pr-3 mb-2'>
              <Field name={`eventype_id`} component={ToggleGroup} options={eventList} defaultValue value={4} flat={true} icons={true} />
            </div>
            <div className='pl-3 pr-3 mb-2 mt-2'>
              <Field
                name={`title`}
                component={TextField}
                type='text'
                labelClass='default-color font-weight-500 font-size-14'
                placeholder={'title'}
                validate={[validators.required]}
              />
            </div>
            <div className='pl-3 pr-3 mb-2 mt-2'>
              <Field
                name={initialValues ? `assignee.id` : `assigned_to`}
                component={FieldSelect}
                type='text'
                options={this.props.users}
                placeholder='Assign to'
                withImage={true}
                multi={false}
              />
            </div>
            <div className='pl-3 pr-3 mb-2'>
              <Field name={`task_priority`} component={ToggleGroup} options={taskPriority} defaultValue={false} flat={true} />
            </div>
            <div className='pl-3 pr-3 mb-2'>
              <Field
                name={`deal`}
                component={AsyncSearch}
                placeholder='Related deal'
                url='/deals'
                field='name'
                listValue='deal_id'
                filters='deal_id,image,name'
                multi={false}
                withImage={true}
              />
            </div>
            <div className='pl-3 pr-3 mb-2'>
              <Field name={`people`} component={FieldSelectCreation} type='text' options={this.props.users} placeholder='Invite people' withImage={true} multi={true} />
            </div>
            <div className='pl-3 pr-3 mb-2 mt-2'>
              <Field
                name={`due_date`}
                component={TimeInputField}
                datePlaceholder='Date'
                timePlaceholder='Time'
                format={value => (value ? moment(value).tz(this.props.timezone) : null)}
                parse={value => (value ? new Date(value).getTime() : null)}
                validate={at(formValues, 'is_zoom_call') ? [validators.required] : []}
              />
            </div>
            <div className='pl-3 pr-3 mb-2'>
              <Field name={`description`} component={TextArea} placeholder='Notes' style={{ width: '100%', height: '120px' }} />
            </div>

            {this.canCreateZoom && (
              <div className='pl-3 pr-3 mb-2'>
                <Field label='Make it a Zoom call' name='is_zoom_call' component={CustomCheckbox} format={value => (value ? true : false)} />
              </div>
            )}

            <div className='pl-3 pr-3 mb-2 mt-2'>
              <Field name='files' component={AttachmentFiles} onClick={() => this.props.trackEvent('ATTACH_FILES')} />
            </div>
            <div className='display-flex-end'>
              <div className='m-3'>
                <SubmitButton
                  label='Cancel'
                  icon='icon-close'
                  style={{
                    width: '150px',
                    color: '#53627c',
                    backgroundColor: '#fff',
                    border: '1px solid #e1e4e8',
                  }}
                  onClick={() => this.closeModal('button')}
                />
              </div>
              <div className='m-3'>
                <Button disabled={submitting || pristine} type='submit' className='submit-btn-p' style={{ width: '150px' }}>
                  <i className='icon-arrow-down-circle mr-2' />
                  {initialValues ? 'Update' : 'Save'}
                </Button>
              </div>
            </div>
          </form>
        </DialogBox>
      </div>
    );
  }
}

EditTask = reduxForm({ form: 'dashboard-edit-task', enableReinitialize: true })(EditTask);

function mapStateToProps(state) {
  return {
    formValues: getFormValues('dashboard-edit-task')(state),
    isZoomAuthorized: at(state, 'auth.user.is_zoom_authorized'),
    timezone: at(state, 'auth.user.profile.timezone').toString(),
  };
}

export default connect(mapStateToProps, {})(EditTask);
