import { SelectItemData } from '../../../shared/interfaces/select-item';
import { TaskResponseDTO } from '../../generated/model/models';
import { TaskPriorityEnum } from '../../enums/task/task-priority.enum';
import { TaskTypeEnum } from '../../enums/task/task-type.enum';
import { ZoomStatusEnum } from '../../enums/zoom/zoom-status.enum';

import { DealLightData } from '../deal/deal-light-data';
import { FileLightData } from '../file/file-light-data';
import { UserLightData } from '../user/user-light-data';

import { MeetingInfoData } from '../meeting/meeting-info-data';

export interface DashboardTaskDetailsData {
  uuid: string;
  title: string;
  attendees: SelectItemData[];
  guests: string[];
  people: (SelectItemData | string)[];
  description: string;
  assignee: SelectItemData;
  dueDate: number;
  deal: SelectItemData;
  files: FileLightData[];
  type: TaskTypeEnum;
  zoom: boolean;
  meetingInfo: MeetingInfoData;
  zoomStatus: ZoomStatusEnum;
  priority: TaskPriorityEnum;
}

export namespace DashboardTaskDetailsData {
  export function mapFromApi(task: TaskResponseDTO): DashboardTaskDetailsData {
    const attendees = (task.attendees || []).map(attendee => UserLightData.mapToSelectedItem(attendee));
    const guests = task.guests || [];

    return {
      uuid: task.task_uuid,
      title: task.title,
      attendees,
      guests,
      people: [...attendees, ...guests],
      description: task.description,
      assignee: task.assignee && UserLightData.mapToSelectedItem(task.assignee),
      dueDate: task.due_date ? task.due_date * 1000 : null,
      deal: task.deal && DealLightData.mapToSelectedItem(task.deal),
      files: (task.files || []).map(file => FileLightData.mapFromApi(file)),
      type: TaskTypeEnum.convertFromApiValue.getValue(task.eventype),
      zoom: task.is_zoom_call,
      meetingInfo: task.meeting_info && MeetingInfoData.mapFromApi(task.meeting_info),
      zoomStatus: ZoomStatusEnum.convertFromApiValue(task.zoom_meeting_status),
      priority: TaskPriorityEnum.convertFromApiValue.getValue(task.task_priority),
    };
  }
}
