import { createAction } from '@reduxjs/toolkit';

import { ErrorResponseData } from '../../../../core/models/http-params.type';
import { IKpiList } from './types';

export const loadKpis = createAction('[Dashboard/kpis] load kpis');
export const loadKpisSuccess = createAction<IKpiList>('[Dashboard/kpis] load kpis success');
export const loadKpisFail = createAction<ErrorResponseData>('[Dashboard/kpis] load kpis fail');

export const loadAllKpis = createAction('[Dashboard/kpis] load all kpis');
export const loadAllKpisSuccess = createAction<IKpiList>('[Dashboard/kpis] load all kpis success');
export const loadAllKpisFail = createAction<ErrorResponseData>('[Dashboard/kpis] load all kpis fail');

export const reorderKpis = createAction<IKpiList>('[Dashboard/kpis] reorder kpis');
export const reorderKpisSuccess = createAction('[Dashboard/kpis] reorder kpis success');
export const reorderKpisFail = createAction<ErrorResponseData>('[Dashboard/kpis] reorder kpis fail');

export const resetAllKpisState = createAction('[Dashboard/kpis] reset all kpis state');
