export enum IconPrefixEnum {
  Antd = 'antd',
  Material = 'material',
  Remix = 'remix',
}

export interface IconData {
  name: string;
  prefix: IconPrefixEnum.Antd | IconPrefixEnum.Material | IconPrefixEnum.Remix;
  iconClassName?: string;
}

export const defaultIcon: IconData = { name: 'exclamation-circle', prefix: IconPrefixEnum.Antd };
