import React from 'react';
import { Tabs as AntdTabs } from 'antd';

import { Badge } from '../Badge/badge.component';
import { TabItemData } from '../../interfaces/tab-item';
import { Icon } from '../Icon/icon.component';

// interface TabsProps {
//   tabs: TabItemData[];
//   onChange?(value: string): void;
// }

export const Tabs = props => {
  return (
    <AntdTabs onChange={props.onChange}>
      {props.tabs.map(tab => (
        <AntdTabs.TabPane
          tab={
            <Badge dot={tab.tag}>
              <span className='d-flex align-items-center'>
                {tab.icon && (
                  <div className='d-flex mr-rem-0_5'>
                    <Icon iconData={tab.icon} />
                  </div>
                )}
                <span className='tab-item'>{tab.name}</span>
              </span>
            </Badge>
          }
          key={tab.value}
        />
      ))}
    </AntdTabs>
  );
};
