import pick from 'lodash/pick';
import { Modal } from 'antd';
import React from 'react';

import { reduxForm, Field, FieldArray } from 'redux-form';

import SubmitButton from 'SharedComponent/SubmitButton';
import TextField from 'SharedComponent/TextField';
import RenderLPs from './renderLPs';

import * as validators from 'Utils/validators';

function FundModal(props) {
  const closeModal = () => {
    props.reset();
    props.handleCancel();
  };

  function footer() {
    return (
      <div className='display-flex-end'>
        <div className='m-1'>
          <SubmitButton
            label='Cancel'
            icon='icon-close'
            style={{
              width: '150px',
              color: '#53627c',
              backgroundColor: '#fff',
              border: '1px solid #e1e4e8',
            }}
            onClick={closeModal}
          />
        </div>
        <div className='m-1'>
          <SubmitButton label='SAVE' icon='icon-check' style={{ width: '150px' }} className='submit-btn-p' type='submit' formId='fund-form-create-update' />
        </div>
      </div>
    );
  }

  function onSubmit(values) {
    const fund = {
      ...values,
      lps: values.lps ? values.lps.map(lp => parseLP(lp)) : [],
    };

    if (props.mode == 'create') props.onCreate(fund);
    else props.onUpdate(fund);
    closeModal();
  }

  const parseLP = lp => {
    if (typeof lp.lp_name == 'object') return pick({ ...lp, ...lp.lp_name }, ['lp_name', 'lp_id', 'lp_commitment']);
    else return lp;
  };

  return (
    <div className='FundsContext'>
      <Modal title='New Fund' footer={footer()} visible={props.visible} onCancel={closeModal} bodyStyle={{ padding: 0 }} width={650} wrapClassName='FundsContext'>
        <div className='fund-form-container'>
          <form className='m-4' id='fund-form-create-update' onSubmit={props.handleSubmit(e => onSubmit(e))}>
            <div>
              <Field
                name={`fund_name`}
                label='Fund name'
                component={TextField}
                placeholder="Enter Fund's name"
                labelClass='font-size-14 font-weight-500 label-color'
                validate={[validators.required]}
                required
              />
            </div>
            <div className='mt-3 mb-3'>
              <Field name={`fund_description`} label='Description' component={TextField} placeholder='Description' labelClass='font-size-14 font-weight-500 label-color' />
            </div>
            <FieldArray name='lps' component={RenderLPs} currencies={props.currencies} />
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default reduxForm({
  form: 'fund-form-create-update',
  enableReinitialize: true,
})(FundModal);
