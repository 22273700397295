import remove from 'lodash/remove';
import orderBy from 'lodash/orderBy';
import {
  LOADING_PIPELINES,
  LOADED_PIPELINES,
  PIPELINE_DELETED,
  LOADING_PIPELINES_FAILED,
  PIPELINE_CREATED,
  EDITING_PIPELINE,
  PIPELINE_UPDATED,
  LIST_PIPELINE_DELETED,
  LIST_PIPELINE_UPDATED,
  PIPELINE_TYPE_UPDATED,
} from 'ReduxActions/pipelineActions';
import { replace } from 'Utils/arrayUtils.js';

const initialState = {
  all: [],
  loading: false,
  count: 0,
  editing: null,
  deals: [],
};

function reorderStages(pipeline) {
  return {
    ...pipeline,
    stages: orderBy(pipeline.stages, ['stage_order'], ['asc']),
  };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_PIPELINES:
      return {
        ...state,
        all: [],
        loading: true,
      };

    case LOADING_PIPELINES_FAILED:
      return {
        ...state,
        loading: false,
      };

    case PIPELINE_DELETED:
      const pipelines = [...state.all];
      remove(pipelines, { pipeline_id: action.payload });
      return {
        ...state,
        all: pipelines,
      };

    case LIST_PIPELINE_UPDATED:
    case LIST_PIPELINE_DELETED:
      return {
        ...state,
        all: [...state.all].filter(item => {
          return action.payload.indexOf(item.pipeline_id) == -1;
        }),
      };

    case LOADED_PIPELINES:
      return {
        ...state,
        all: action.payload,
        loading: false,
        count: action.payload.length,
      };

    case PIPELINE_CREATED:
      return {
        ...state,
        all: [action.payload, ...state.all],
      };

    case EDITING_PIPELINE:
      return {
        ...state,
        editing: action.payload ? reorderStages(action.payload) : null,
      };

    case PIPELINE_UPDATED:
      return {
        ...state,
        all: replace(state.all, { pipeline_id: action.payload.pipeline_id }, action.payload),
      };

    case PIPELINE_TYPE_UPDATED:
      const updated = [...state.all];
      remove(updated, { pipeline_id: action.payload.pipeline_id });
      return {
        ...state,
        all: updated,
      };
  }

  return state;
}
