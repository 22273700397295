import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { removeForm, resetForm, createForm, updateForm, loadForm, onAddStep, onChangeStep, onRemoveStep, renameStep } from 'ReduxActions/formActions';
import { loadPipelineStage, loadPipelineTypeList } from 'ReduxActions/pipelineActions';
import FormSteps from '../form-steps';
import FormBuilder from '../form-builder';
import FormGenerator from '../FormGenerator';
import PipelineChoice from '../pipeline-choice';
import { formStatusList } from 'Enums/onlineForm';
import defaultImage from 'Assets/img/default.png';
import { at } from 'Utils/objectUtils';

import './css/application.css.scss';
import './FormView.scss';

const actions = {
  removeForm,
  resetForm,
  createForm,
  updateForm,
  loadForm,
  loadPipelineStage,
  loadPipelineTypeList,
  onAddStep,
  onChangeStep,
  onRemoveStep,
  renameStep,
};
class FormView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  componentWillMount() {
    if (this.props.params.id) {
      this.props.loadForm(this.props.params.id);
    }
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  beforeSave() {
    this.props.loadPipelineTypeList(0);
    if (this.props.params.id) this.props.loadPipelineStage(this.props.editing.pipeline_id);
    this.setState({ display: true });
  }

  render() {
    const action = this.props.params.id ? 'Update' : 'Save';
    return (
      <div className='FormView form-build-generator-details'>
        {this.props.loading ? (
          <div className='custom-spinner'>
            <div id='custom-spinner-id' />
          </div>
        ) : (
          <div className='card-body no-padding'>
            <FormSteps
              steps={this.props.steps}
              activeStep={this.props.activeStep}
              onAddStep={this.props.onAddStep}
              onChangeStep={this.props.onChangeStep}
              onRemoveStep={this.props.onRemoveStep}
              renameStep={this.props.renameStep}
            />
            <div className='alert alert-callout no-margin'>
              <div className='card-flex'>
                <FormBuilder data={this.props.steps[this.props.activeStep].content} language={this.props.language} />
              </div>
            </div>
            <FormGenerator removeForm={this.props.removeForm} onSave={this.beforeSave.bind(this)} data={[]} steps={this.props.steps} type={action} language={this.props.language} />
          </div>
        )}
        <PipelineChoice
          show={this.state.display}
          onHide={() => this.setState({ display: false })}
          loadStages={this.props.loadPipelineStage}
          pipelines={this.parsePipelines(this.props.pipelines)}
          stages={this.parseStages(this.props.stages)}
          status={formStatusList}
          users={this.parseUsers(this.props.users)}
          onSave={values => this.props.createForm({ ...values, data: this.props.steps })}
          onUpdate={values => this.props.updateForm({ ...values, data: this.props.steps })}
          initialValues={this.props.editing}
        />
      </div>
    );
  }

  parsePipelines(list) {
    return list.map(item => {
      return {
        value: item.pipeline_id,
        label: item.name,
      };
    });
  }

  parseUsers(users) {
    return users.map(user => {
      return {
        value: user.id,
        email: user.email,
        label: user.profile ? user.profile.fullname : 'N/A',
        image: user.profile && user.profile.image ? user.profile.image : defaultImage,
      };
    });
  }

  parseStages(stages) {
    return stages.map(stage => {
      return {
        value: stage.stage_id,
        label: stage.name,
      };
    });
  }
}

function mapStateToProps(state) {
  return {
    language: at(state, 'settings.formBuilder.editing.language') || 'en',
    steps: state.settings.formBuilder.steps,
    activeStep: state.settings.formBuilder.activeStep,
    editing: state.settings.formBuilder.editing,
    loading: state.settings.formBuilder.loading,
    pipelines: state.pipeline.pipelines,
    stages: state.pipeline.stages,
    users: state.settings.users.all,
  };
}
export default connect(mapStateToProps, actions)(withRouter(FormView));
