export const types = [
  { value: 'text', label: 'Short text' },
  { value: 'number', label: 'Number' },
  { value: 'list', label: 'Multiple options' },
  { value: 'percentage', label: 'Percentage' },
  { value: 'date', label: 'Date' },
  { value: 'year', label: 'Year' },
  { value: 'paragraph', label: 'Paragraph' },
  { value: 'countries', label: 'Countries' },
  { value: 'monetary', label: 'Monetary' },
  { value: 'users', label: 'List of users' },
];

export const sections = [
  { value: 'pipeline', label: 'Pipeline' },
  { value: 'portfolio', label: 'Portfolio' },
  { value: 'contact', label: 'Contact' },
];

export const ALL_SECTIONS = {
  PIPELINE: { value: 'pipeline', label: 'Pipeline' },
  PORTFOLIO: { value: 'portfolio', label: 'Portfolio' },
  CONTACT: { value: 'contact', label: 'Contact' },
};
