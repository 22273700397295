import PropTypes from 'prop-types';
import React from 'react';
import { Radio } from 'antd';
import { Field } from 'redux-form';

import './RadioButton.scss';

export const RadioButtonGroup = ({ name, options, title, validate, isInline, directionRight }) => (
  <div className='RadioButtonGroup'>
    <Field
      name={name}
      options={options}
      validate={validate}
      component={({ input, meta, options }) => {
        return (
          <div className='flex'>
            <Radio.Group
              style={directionRight && !isInline ? { width: '1rem' } : {}}
              value={input.value && input.value.response}
              onChange={e => input.onChange({ question: title, response: e.target.value })}
            >
              {options.map(option => (
                <Radio key={option.id} value={option.value} style={isInline ? {} : verticalStyle}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
            <div>
              {meta.touched && meta.error && (
                <div className='display-flex-start'>
                  <span className='material-icons error-icon-required-form mr-2'>error</span>
                  <span className='error-message-form'>{meta.error}</span>
                </div>
              )}
            </div>
          </div>
        );
      }}
    />
  </div>
);

const verticalStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

RadioButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  name: PropTypes.string.isRequired,
};
