import moment from 'moment';
import React, { Component } from 'react';
import defaultImage from '../../../../assets/img/default.png';
import { TooltipAvatar } from 'SharedComponent/antd/CustomAvatar';

import './ContactCard.scss';

export default class ContactCard extends Component {
  checkField(fields, field) {
    const item = fields.find(e => e.value == field);
    return item ? item.show : false;
  }

  emptyValue(field) {
    return field == 'image' ? defaultImage : '';
  }

  checkFieldValue(field) {
    const data = this.props.item.data;
    if (data) return data[field] ? data[field] : this.emptyValue(field);
    else return this.emptyValue(field);
  }

  switchField(field, data) {
    if (!data) return '--';
    switch (field) {
      case 'date':
        return moment(data * 1000).format('ll');
      case 'users':
        return (
          <div>
            {data.map(i => (
              <TooltipAvatar key={i.id} size='small' tooltip={i.fullname} image={i.image || defaultImage} />
            ))}
          </div>
        );
      default:
        return data;
    }
  }

  render() {
    const item = this.props.item;
    const fields = item ? item.fields : [];
    const tableFields = fields.filter(it => !['logo', 'name'].includes(it.value));
    return (
      <div className='ContactCard'>
        <div className='card p-3' style={{ backgroundColor: item.properties.color.backgroundColor, border: 'none' }}>
          <div className='card-body'>
            <div className='display-flex-center'>
              {this.checkField(fields, 'logo') && <Logo logo={this.checkFieldValue('image')} />}
              {this.checkField(fields, 'name') && <Name firstName={this.checkFieldValue('first_name')} lastName={this.checkFieldValue('last_name')} />}
            </div>
            <hr />
            <div className='display-flex-start m-3'>
              <table>
                <tbody>
                  {tableFields.map(field => {
                    if (field.show) {
                      const isField = this.checkFieldValue(field.value);
                      return <TableRow key={field.value} label={field.label} value={this.switchField(field.type, isField)} />;
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Logo = props => (
  <div className='flex-1 mr-1'>
    <img height='100%' width='100%' style={{ borderRadius: '10px' }} src={props.logo} />
  </div>
);

const Name = props => <div className='flex-2 ml-1 text-label'>{`${props.firstName} ${props.lastName}`}</div>;

const TableRow = props => (
  <tr>
    <td>
      <div className='label-info mr-2'>{props.label}</div>
    </td>
    <td>
      <div className='label-value'>{props.value}</div>
    </td>
  </tr>
);
