/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ActionPermissionEnumDTO =
  | 'update_company'
  | 'update_custom_field'
  | 'delete_custom_field'
  | 'create_custom_field'
  | 'create_or_update_form'
  | 'duplicate_form'
  | 'delete_form'
  | 'export_form_deal_to_excel'
  | 'create_fund'
  | 'update_fund'
  | 'delete_fund'
  | 'list_rates'
  | 'create_rate'
  | 'update_rate'
  | 'delete_rate'
  | 'create_pipeline'
  | 'create_portfolio'
  | 'update_pipeline'
  | 'update_portfolio'
  | 'delete_all_pipeline'
  | 'delete_all_portfolio'
  | 'delete_pipeline'
  | 'delete_portfolio'
  | 'move_pipeline_to_portfolio'
  | 'move_portfolio_to_pipeline'
  | 'get_pipeline_deals'
  | 'get_portfolio_deals'
  | 'get_pipeline_statistics'
  | 'export_pipeline_deals'
  | 'export_portfolio_deals'
  | 'get_portfolio_statistics'
  | 'export_portfolio_dashboard_data'
  | 'create_user'
  | 'update_user'
  | 'delete_user'
  | 'block_unblock_user'
  | 'create_investor'
  | 'update_investor'
  | 'delete_investor'
  | 'link_unlink_investor'
  | 'resend_investor_invitation'
  | 'list_wealth_investors'
  | 'block_unblock_investor'
  | 'share_deal_notes_with_investors'
  | 'share_deal_files_with_investors'
  | 'exit_investor_deal'
  | 'create_pipeline_deal'
  | 'create_portfolio_deal'
  | 'update_pipeline_deal'
  | 'update_portfolio_deal'
  | 'delete_pipeline_deal'
  | 'delete_portfolio_deal'
  | 'list_pipeline_deal_Notifications'
  | 'list_portfolio_deal_notifications'
  | 'won_pipeline_deal'
  | 'lost_pipeline_deal'
  | 'reopen_deal'
  | 'enable_disable_angel_inv_vote'
  | 'create_contact'
  | 'update_contact'
  | 'delete_contact'
  | 'link_contact_to_pipeline_deal'
  | 'link_contact_to_portfolio_deal'
  | 'create_pipeline_note'
  | 'create_portfolio_note'
  | 'update_pipeline_note'
  | 'update_portfolio_note'
  | 'list_pipeline_note'
  | 'list_portfolio_note'
  | 'create_deal_round'
  | 'update_deal_round'
  | 'delete_deal_round'
  | 'list_deal_round'
  | 'create_pipeline_deal_file'
  | 'create_portfolio_deal_file'
  | 'dropbox_import_pipeline_deal_file'
  | 'dropbox_import_portfolio_deal_file'
  | 'gdrive_import_pipeline_deal_file'
  | 'gdrive_import_portfolio_deal_file'
  | 'update_pipeline_deal_file'
  | 'update_portfolio_deal_file'
  | 'delete_pipeline_deal_file'
  | 'delete_portfolio_deal_file'
  | 'download_pipeline_deal_file'
  | 'download_portfolio_deal_file'
  | 'list_pipeline_deal_files'
  | 'list_portfolio_deal_files'
  | 'send_email_pipeline_deal'
  | 'send_email_portfolio_deal'
  | 'create_or_update_report'
  | 'share_report'
  | 'delete_report'
  | 'list_pipeline_metrics_group'
  | 'list_portfolio_metrics_group'
  | 'create_pipeline_metrics_group'
  | 'create_portfolio_metrics_group'
  | 'update_pipeline_metrics_group'
  | 'update_portfolio_metrics_group'
  | 'delete_pipeline_metrics_group'
  | 'delete_portfolio_metrics_group'
  | 'list_angel_investors'
  | 'create_angel_investor'
  | 'delete_angel_investor'
  | 'link_unlink_pipeline_deal_to_angel_investors'
  | 'link_unlink_portfolio_deal_to_angel_investors'
  | 'export_angel_investor_vote_result'
  | 'update_angel_investor'
  | 'share_notes_with_angel_investor'
  | 'list_tasks'
  | 'create_task'
  | 'create_pipeline_deal_task'
  | 'create_portfolio_deal_task'
  | 'update_task'
  | 'update_pipeline_deal_task'
  | 'update_portfolio_deal_task'
  | 'delete_task'
  | 'delete_pipeline_deal_task'
  | 'delete_portfolio_deal_task'
  | 'vote_on_deal_task'
  | 'vote_on_pipeline_deal_task'
  | 'vote_on_portfolio_deal_task'
  | 'list_dashboard_notes'
  | 'list_dashboard_emails'
  | 'list_dashboard_files'
  | 'list_dashboard_notifications'
  | 'integrate_zoom'
  | 'migrate_pipedrive_data'
  | 'import_deals_from_excel'
  | 'google_calendar_integration';

export const ActionPermissionEnumDTO = {
  UpdateCompany: 'update_company' as ActionPermissionEnumDTO,
  UpdateCustomField: 'update_custom_field' as ActionPermissionEnumDTO,
  DeleteCustomField: 'delete_custom_field' as ActionPermissionEnumDTO,
  CreateCustomField: 'create_custom_field' as ActionPermissionEnumDTO,
  CreateOrUpdateForm: 'create_or_update_form' as ActionPermissionEnumDTO,
  DuplicateForm: 'duplicate_form' as ActionPermissionEnumDTO,
  DeleteForm: 'delete_form' as ActionPermissionEnumDTO,
  ExportFormDealToExcel: 'export_form_deal_to_excel' as ActionPermissionEnumDTO,
  CreateFund: 'create_fund' as ActionPermissionEnumDTO,
  UpdateFund: 'update_fund' as ActionPermissionEnumDTO,
  DeleteFund: 'delete_fund' as ActionPermissionEnumDTO,
  ListRates: 'list_rates' as ActionPermissionEnumDTO,
  CreateRate: 'create_rate' as ActionPermissionEnumDTO,
  UpdateRate: 'update_rate' as ActionPermissionEnumDTO,
  DeleteRate: 'delete_rate' as ActionPermissionEnumDTO,
  CreatePipeline: 'create_pipeline' as ActionPermissionEnumDTO,
  CreatePortfolio: 'create_portfolio' as ActionPermissionEnumDTO,
  UpdatePipeline: 'update_pipeline' as ActionPermissionEnumDTO,
  UpdatePortfolio: 'update_portfolio' as ActionPermissionEnumDTO,
  DeleteAllPipeline: 'delete_all_pipeline' as ActionPermissionEnumDTO,
  DeleteAllPortfolio: 'delete_all_portfolio' as ActionPermissionEnumDTO,
  DeletePipeline: 'delete_pipeline' as ActionPermissionEnumDTO,
  DeletePortfolio: 'delete_portfolio' as ActionPermissionEnumDTO,
  MovePipelineToPortfolio: 'move_pipeline_to_portfolio' as ActionPermissionEnumDTO,
  MovePortfolioToPipeline: 'move_portfolio_to_pipeline' as ActionPermissionEnumDTO,
  GetPipelineDeals: 'get_pipeline_deals' as ActionPermissionEnumDTO,
  GetPortfolioDeals: 'get_portfolio_deals' as ActionPermissionEnumDTO,
  GetPipelineStatistics: 'get_pipeline_statistics' as ActionPermissionEnumDTO,
  ExportPipelineDeals: 'export_pipeline_deals' as ActionPermissionEnumDTO,
  ExportPortfolioDeals: 'export_portfolio_deals' as ActionPermissionEnumDTO,
  GetPortfolioStatistics: 'get_portfolio_statistics' as ActionPermissionEnumDTO,
  ExportPortfolioDashboardData: 'export_portfolio_dashboard_data' as ActionPermissionEnumDTO,
  CreateUser: 'create_user' as ActionPermissionEnumDTO,
  UpdateUser: 'update_user' as ActionPermissionEnumDTO,
  DeleteUser: 'delete_user' as ActionPermissionEnumDTO,
  BlockUnblockUser: 'block_unblock_user' as ActionPermissionEnumDTO,
  CreateInvestor: 'create_investor' as ActionPermissionEnumDTO,
  UpdateInvestor: 'update_investor' as ActionPermissionEnumDTO,
  DeleteInvestor: 'delete_investor' as ActionPermissionEnumDTO,
  LinkUnlinkInvestor: 'link_unlink_investor' as ActionPermissionEnumDTO,
  ResendInvestorInvitation: 'resend_investor_invitation' as ActionPermissionEnumDTO,
  ListWealthInvestors: 'list_wealth_investors' as ActionPermissionEnumDTO,
  BlockUnblockInvestor: 'block_unblock_investor' as ActionPermissionEnumDTO,
  ShareDealNotesWithInvestors: 'share_deal_notes_with_investors' as ActionPermissionEnumDTO,
  ShareDealFilesWithInvestors: 'share_deal_files_with_investors' as ActionPermissionEnumDTO,
  ExitInvestorDeal: 'exit_investor_deal' as ActionPermissionEnumDTO,
  CreatePipelineDeal: 'create_pipeline_deal' as ActionPermissionEnumDTO,
  CreatePortfolioDeal: 'create_portfolio_deal' as ActionPermissionEnumDTO,
  UpdatePipelineDeal: 'update_pipeline_deal' as ActionPermissionEnumDTO,
  UpdatePortfolioDeal: 'update_portfolio_deal' as ActionPermissionEnumDTO,
  DeletePipelineDeal: 'delete_pipeline_deal' as ActionPermissionEnumDTO,
  DeletePortfolioDeal: 'delete_portfolio_deal' as ActionPermissionEnumDTO,
  ListPipelineDealNotifications: 'list_pipeline_deal_Notifications' as ActionPermissionEnumDTO,
  ListPortfolioDealNotifications: 'list_portfolio_deal_notifications' as ActionPermissionEnumDTO,
  WonPipelineDeal: 'won_pipeline_deal' as ActionPermissionEnumDTO,
  LostPipelineDeal: 'lost_pipeline_deal' as ActionPermissionEnumDTO,
  ReopenDeal: 'reopen_deal' as ActionPermissionEnumDTO,
  EnableDisableAngelInvVote: 'enable_disable_angel_inv_vote' as ActionPermissionEnumDTO,
  CreateContact: 'create_contact' as ActionPermissionEnumDTO,
  UpdateContact: 'update_contact' as ActionPermissionEnumDTO,
  DeleteContact: 'delete_contact' as ActionPermissionEnumDTO,
  LinkContactToPipelineDeal: 'link_contact_to_pipeline_deal' as ActionPermissionEnumDTO,
  LinkContactToPortfolioDeal: 'link_contact_to_portfolio_deal' as ActionPermissionEnumDTO,
  CreatePipelineNote: 'create_pipeline_note' as ActionPermissionEnumDTO,
  CreatePortfolioNote: 'create_portfolio_note' as ActionPermissionEnumDTO,
  UpdatePipelineNote: 'update_pipeline_note' as ActionPermissionEnumDTO,
  UpdatePortfolioNote: 'update_portfolio_note' as ActionPermissionEnumDTO,
  ListPipelineNote: 'list_pipeline_note' as ActionPermissionEnumDTO,
  ListPortfolioNote: 'list_portfolio_note' as ActionPermissionEnumDTO,
  CreateDealRound: 'create_deal_round' as ActionPermissionEnumDTO,
  UpdateDealRound: 'update_deal_round' as ActionPermissionEnumDTO,
  DeleteDealRound: 'delete_deal_round' as ActionPermissionEnumDTO,
  ListDealRound: 'list_deal_round' as ActionPermissionEnumDTO,
  CreatePipelineDealFile: 'create_pipeline_deal_file' as ActionPermissionEnumDTO,
  CreatePortfolioDealFile: 'create_portfolio_deal_file' as ActionPermissionEnumDTO,
  DropboxImportPipelineDealFile: 'dropbox_import_pipeline_deal_file' as ActionPermissionEnumDTO,
  DropboxImportPortfolioDealFile: 'dropbox_import_portfolio_deal_file' as ActionPermissionEnumDTO,
  GdriveImportPipelineDealFile: 'gdrive_import_pipeline_deal_file' as ActionPermissionEnumDTO,
  GdriveImportPortfolioDealFile: 'gdrive_import_portfolio_deal_file' as ActionPermissionEnumDTO,
  UpdatePipelineDealFile: 'update_pipeline_deal_file' as ActionPermissionEnumDTO,
  UpdatePortfolioDealFile: 'update_portfolio_deal_file' as ActionPermissionEnumDTO,
  DeletePipelineDealFile: 'delete_pipeline_deal_file' as ActionPermissionEnumDTO,
  DeletePortfolioDealFile: 'delete_portfolio_deal_file' as ActionPermissionEnumDTO,
  DownloadPipelineDealFile: 'download_pipeline_deal_file' as ActionPermissionEnumDTO,
  DownloadPortfolioDealFile: 'download_portfolio_deal_file' as ActionPermissionEnumDTO,
  ListPipelineDealFiles: 'list_pipeline_deal_files' as ActionPermissionEnumDTO,
  ListPortfolioDealFiles: 'list_portfolio_deal_files' as ActionPermissionEnumDTO,
  SendEmailPipelineDeal: 'send_email_pipeline_deal' as ActionPermissionEnumDTO,
  SendEmailPortfolioDeal: 'send_email_portfolio_deal' as ActionPermissionEnumDTO,
  CreateOrUpdateReport: 'create_or_update_report' as ActionPermissionEnumDTO,
  ShareReport: 'share_report' as ActionPermissionEnumDTO,
  DeleteReport: 'delete_report' as ActionPermissionEnumDTO,
  ListPipelineMetricsGroup: 'list_pipeline_metrics_group' as ActionPermissionEnumDTO,
  ListPortfolioMetricsGroup: 'list_portfolio_metrics_group' as ActionPermissionEnumDTO,
  CreatePipelineMetricsGroup: 'create_pipeline_metrics_group' as ActionPermissionEnumDTO,
  CreatePortfolioMetricsGroup: 'create_portfolio_metrics_group' as ActionPermissionEnumDTO,
  UpdatePipelineMetricsGroup: 'update_pipeline_metrics_group' as ActionPermissionEnumDTO,
  UpdatePortfolioMetricsGroup: 'update_portfolio_metrics_group' as ActionPermissionEnumDTO,
  DeletePipelineMetricsGroup: 'delete_pipeline_metrics_group' as ActionPermissionEnumDTO,
  DeletePortfolioMetricsGroup: 'delete_portfolio_metrics_group' as ActionPermissionEnumDTO,
  ListAngelInvestors: 'list_angel_investors' as ActionPermissionEnumDTO,
  CreateAngelInvestor: 'create_angel_investor' as ActionPermissionEnumDTO,
  DeleteAngelInvestor: 'delete_angel_investor' as ActionPermissionEnumDTO,
  LinkUnlinkPipelineDealToAngelInvestors: 'link_unlink_pipeline_deal_to_angel_investors' as ActionPermissionEnumDTO,
  LinkUnlinkPortfolioDealToAngelInvestors: 'link_unlink_portfolio_deal_to_angel_investors' as ActionPermissionEnumDTO,
  ExportAngelInvestorVoteResult: 'export_angel_investor_vote_result' as ActionPermissionEnumDTO,
  UpdateAngelInvestor: 'update_angel_investor' as ActionPermissionEnumDTO,
  ShareNotesWithAngelInvestor: 'share_notes_with_angel_investor' as ActionPermissionEnumDTO,
  ListTasks: 'list_tasks' as ActionPermissionEnumDTO,
  CreateTask: 'create_task' as ActionPermissionEnumDTO,
  CreatePipelineDealTask: 'create_pipeline_deal_task' as ActionPermissionEnumDTO,
  CreatePortfolioDealTask: 'create_portfolio_deal_task' as ActionPermissionEnumDTO,
  UpdateTask: 'update_task' as ActionPermissionEnumDTO,
  UpdatePipelineDealTask: 'update_pipeline_deal_task' as ActionPermissionEnumDTO,
  UpdatePortfolioDealTask: 'update_portfolio_deal_task' as ActionPermissionEnumDTO,
  DeleteTask: 'delete_task' as ActionPermissionEnumDTO,
  DeletePipelineDealTask: 'delete_pipeline_deal_task' as ActionPermissionEnumDTO,
  DeletePortfolioDealTask: 'delete_portfolio_deal_task' as ActionPermissionEnumDTO,
  VoteOnDealTask: 'vote_on_deal_task' as ActionPermissionEnumDTO,
  VoteOnPipelineDealTask: 'vote_on_pipeline_deal_task' as ActionPermissionEnumDTO,
  VoteOnPortfolioDealTask: 'vote_on_portfolio_deal_task' as ActionPermissionEnumDTO,
  ListDashboardNotes: 'list_dashboard_notes' as ActionPermissionEnumDTO,
  ListDashboardEmails: 'list_dashboard_emails' as ActionPermissionEnumDTO,
  ListDashboardFiles: 'list_dashboard_files' as ActionPermissionEnumDTO,
  ListDashboardNotifications: 'list_dashboard_notifications' as ActionPermissionEnumDTO,
  IntegrateZoom: 'integrate_zoom' as ActionPermissionEnumDTO,
  MigratePipedriveData: 'migrate_pipedrive_data' as ActionPermissionEnumDTO,
  ImportDealsFromExcel: 'import_deals_from_excel' as ActionPermissionEnumDTO,
  GoogleCalendarIntegration: 'google_calendar_integration' as ActionPermissionEnumDTO,
};
