import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Field } from 'redux-form';

import './CheckboxGroup.scss';

export default class CheckboxGroup extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  field = ({ input, meta, options }) => {
    const { name, onChange, onBlur, onFocus } = input;
    const { touched, error } = meta;
    const inputValue = input.value ? input.value : { question: '', response: [] };
    const { className, style, title } = this.props;

    const checkboxes = options.map(({ label, value }, index) => {
      const handleChange = event => {
        const arr = [...inputValue.response];
        if (event.target.checked) {
          arr.push(value);
        } else {
          arr.splice(arr.indexOf(value), 1);
        }
        // onBlur(arr);
        // return onChange(arr);
        onBlur({ question: title, response: arr, multiple: true });
        return onChange({ question: title, response: arr, multiple: true });
      };

      const checked = inputValue && inputValue.response.includes(value);

      return (
        <label key={`checkbox-${index}`} className={classNames('checkbox-inline', className)} style={style}>
          <input type='checkbox' name={`${name}[${index}]`} value={value} checked={checked} onChange={handleChange} onFocus={onFocus} />
          <span className={this.props.directionRight ? 'mr-2' : 'ml-2'}>{label}</span>
        </label>
      );
    });

    return (
      <div>
        <div className={this.props.directionRight && this.props.isInline ? 'display-flex-start' : ''}>{checkboxes}</div>
        <div>
          {touched && error && (
            <div className='display-flex-start'>
              <span className='material-icons error-icon-required-form mr-2'>error</span>
              <span className='error-message-form'>{error}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={!this.props.blue ? 'CheckboxGroup' : 'CheckboxGroupBlue'}>
        <Field {...this.props} type='checkbox' component={this.field} validate={this.props.validate} />
      </div>
    );
  }
}
