import { createReducer, Action, createEntityAdapter, EntityState, current } from '@reduxjs/toolkit';

import { MetaQuery } from '../../../../../core/models/queries/meta-query.model';
import { ErrorResponseData } from '../../../../../core/models/http-params.type';
import { NewsSummaryData } from '../../../../../core/models/news/news-summary-data';
import { NewsTagsData } from '../../../../../core/models/news/news-tags-data';

import * as newsActions from './news.actions';

const newsAdapter = createEntityAdapter<NewsSummaryData>({
  selectId: news => news.id,
  sortComparer: (a, b) => b.unixTime - a.unixTime,
});

const savedNewsAdapter = createEntityAdapter<NewsSummaryData>({
  selectId: news => news.id,
  sortComparer: (a, b) => b.unixTime - a.unixTime,
});

const tagsAdapter = createEntityAdapter<NewsTagsData>({
  selectId: tags => tags.id,
  sortComparer: (a, b) => b.id - a.id,
});

export const selectAllNews = newsAdapter.getSelectors().selectAll;
export const selectAllSavedNews = newsAdapter.getSelectors().selectAll;
export const selectAllTags = tagsAdapter.getSelectors().selectAll;

export interface NewsState extends EntityState<NewsSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  meta: MetaQuery;
  saveLoading: number;
}
export interface SavedNewsState extends EntityState<NewsSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  meta: MetaQuery;
  removeLoading: number;
}

export interface TagsState extends EntityState<NewsTagsData> {
  loading: boolean;
  error: ErrorResponseData;
  meta: MetaQuery;
  removeLoading: number;
  addTagLoading: boolean;
}

export const savedNewsInitialState: SavedNewsState = savedNewsAdapter.getInitialState<SavedNewsState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  meta: null,
  removeLoading: null,
});

export const newsInitialState: NewsState = newsAdapter.getInitialState<NewsState>({
  ids: [],
  entities: {},
  meta: null,
  loading: false,
  error: null,
  saveLoading: null,
});

export const tagsInitialState: TagsState = newsAdapter.getInitialState<TagsState>({
  ids: [],
  entities: {},
  meta: null,
  loading: false,
  error: null,
  removeLoading: null,
  addTagLoading: false,
});

export interface DashboardNewsState {
  News: NewsState;
  SavedNews: SavedNewsState;
  Tags: TagsState;
}
const dashboardNewsInitialState: DashboardNewsState = {
  News: newsInitialState,
  SavedNews: savedNewsInitialState,
  Tags: tagsInitialState,
};
const _dashboardNewsReducer = createReducer<DashboardNewsState>(dashboardNewsInitialState, builder => {
  builder
    .addCase(newsActions.loadNews, (state): DashboardNewsState => ({ ...state, News: { ...state.News, loading: true } }))
    .addCase(
      newsActions.loadNewsSuccess,
      (state, { payload: { list, meta, reset } }): DashboardNewsState => {
        const adapterAction = reset
          ? newsAdapter.setAll({ ...current(state.News), loading: false, meta: meta }, list)
          : newsAdapter.addMany({ ...current(state.News), loading: false, meta: meta }, list);

        const newState: DashboardNewsState = {
          ...current(state),
          News: adapterAction,
        };

        return newState;
      },
    )
    .addCase(newsActions.loadNewsFail, (state): DashboardNewsState => ({ ...state, News: { ...state.News, loading: true } }))
    .addCase(newsActions.loadSavedNews, (state): DashboardNewsState => ({ ...state, SavedNews: { ...state.SavedNews, loading: true } }))
    .addCase(
      newsActions.loadSavedNewsSuccess,
      (state, { payload: { list, meta, reset } }): DashboardNewsState => {
        const adapterAction = reset
          ? savedNewsAdapter.setAll({ ...current(state.SavedNews), loading: false, meta: meta }, list)
          : savedNewsAdapter.addMany({ ...current(state.SavedNews), loading: false, meta: meta }, list);
        const newState: DashboardNewsState = {
          ...current(state),
          SavedNews: adapterAction,
        };

        return newState;
      },
    )
    .addCase(newsActions.saveNews, (state, { payload }): DashboardNewsState => ({ ...state, News: { ...state.News, saveLoading: payload.newsId } }))
    .addCase(
      newsActions.saveNewsSuccess,
      (state, { payload }): DashboardNewsState => {
        const newState: DashboardNewsState = { ...state, News: newsAdapter.updateOne({ ...state.News, saveLoading: null }, { id: payload.id, changes: payload }) };

        return newState;
      },
    )
    .addCase(
      newsActions.removeSavedNews,
      (state, { payload }): DashboardNewsState => {
        const newState = { ...state, SavedNews: { ...state.SavedNews, removeLoading: payload.id } };

        return newState;
      },
    )
    .addCase(
      newsActions.removeNewsSuccess,
      (state, { payload }): DashboardNewsState => {
        const newState: DashboardNewsState = {
          ...state,
          SavedNews: savedNewsAdapter.updateOne({ ...state.SavedNews, removeLoading: null }, { id: payload.id, changes: { ...payload, isRemoved: true } }),
        };

        return newState;
      },
    )
    .addCase(newsActions.loadTags, (state): DashboardNewsState => ({ ...state, Tags: { ...state.Tags, loading: true } }))
    .addCase(
      newsActions.loadTagsSuccess,
      (state, { payload: { list, meta, reset } }): DashboardNewsState => {
        const adapterAction = reset
          ? tagsAdapter.setAll({ ...current(state.Tags), loading: false, meta: meta }, list)
          : tagsAdapter.addMany({ ...current(state.Tags), loading: false, meta: meta }, list);
        const newState: DashboardNewsState = {
          ...current(state),
          Tags: adapterAction,
        };

        return newState;
      },
    )
    .addCase(
      newsActions.removeTag,
      (state, { payload }): DashboardNewsState => {
        const newState = { ...state, Tags: { ...state.Tags, removeLoading: payload.id } };

        return newState;
      },
    )
    .addCase(
      newsActions.removeTagSuccess,
      (state, { payload }): DashboardNewsState => {
        const newState: DashboardNewsState = {
          ...state,
          Tags: tagsAdapter.updateOne({ ...state.Tags, removeLoading: null }, { id: payload.id, changes: { ...payload, isRemoved: true } }),
        };

        return newState;
      },
    )
    .addCase(
      newsActions.addTag,
      (state): DashboardNewsState => {
        const newState = { ...state, Tags: { ...state.Tags, addTagLoading: true } };

        return newState;
      },
    )
    .addCase(
      newsActions.addTagSuccess,
      (state, { payload }): DashboardNewsState => {
        const newState: DashboardNewsState = { ...state, Tags: tagsAdapter.addOne({ ...state.Tags, addTagLoading: false }, payload) };

        return newState;
      },
    )
    .addCase(
      newsActions.resetNewsStore,
      (state): DashboardNewsState => {
        const newState: DashboardNewsState = { ...state, News: newsInitialState };

        return newState;
      },
    )
    .addCase(
      newsActions.resetSavedNewsStore,
      (state): DashboardNewsState => {
        const newState: DashboardNewsState = { ...state, SavedNews: savedNewsInitialState };

        return newState;
      },
    );
});

export function dashboardNewsReducer(state: DashboardNewsState | undefined, action: Action): DashboardNewsState {
  return _dashboardNewsReducer(state, action);
}
