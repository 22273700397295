import React, { Component } from 'react';
import { withRouter } from 'react-router';
import './slack.scss';
import Success from './success.svg';
import Fail from './fail.svg';

class Slack extends Component {
  render() {
    const status = this.props.location.query.status === '1';
    if (status) {
      return (
        <div className='display-flex-center' style={{ width: '100vw', height: '100vh' }}>
          <div className='display-flex-center' style={{ flexDirection: 'column' }}>
            <img src={Success} width='300' />
            <h3 className='mt-3'> Slack integration was successful</h3>
            <p>
              You can <a href='/'> go back </a> to home page.
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className='display-flex-center' style={{ width: '100vw', height: '100vh' }}>
        <div className='display-flex-center' style={{ flexDirection: 'column' }}>
          <img src={Fail} width='300' />
          <h3 className='mt-3'> Slack integration has failed.</h3>
          <p>
            You can <a href='/'> go back </a> to home page and contact us.
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(Slack);
