import classNames from 'classnames';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { actions } from 'ReduxActions/authActions';
import defaultImage from '../../../assets/img/default.png';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './Account.scss';
import { Mixpanel } from '../../../app';
class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentWillMount() {
    document.addEventListener('mouseup', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) this.setState({ isOpen: false });
  }

  toggleDropDown() {
    this.setState({ isOpen: !this.state.isOpen });
    gaEvent(appCategories.header, appActions(appTypes.account, appEvents.opened).dropdown);
  }

  signOut() {
    gaEvent(appCategories.header, appActions(appTypes.account + ':LOGOUT', appEvents.clicked).item);
    this.props.signOut();
    Mixpanel.track('Logged out');
  }

  handleItemClick(action, ga) {
    return () => {
      this.toggle(false);
      action && action();
      gaEvent(appCategories.header, appActions(`${appTypes.account}:${ga}`, appEvents.clicked).item);
      Mixpanel.track(`Clicked on ${ga}`);
    };
  }

  toggle(open) {
    this.setState({ isOpen: open == undefined ? !this.state.open : open });
  }

  render() {
    const user = this.props.user;
    const items = [
      { id: 'profile', label: 'Account Settings', route: '/profile', ga: 'EDIT_PROFILE', icon: 'icon-settings', action: () => {} },
      // { id: "integrations", label: "Integrations", route: "/integrations", ga: "INTEGRATION_SETTINGS", action: () => {} },
      // { id: "calendar", label: "Calendar", route: "/calendar", action: () => {} },
      // { id: "setting", label: "Setting", route: "/settings", action: () => {}},
    ];

    return (
      <div className='Account display-flex-center' id='toggle-account-id' ref={this.setWrapperRef}>
        <ul className={classNames('dropdown', this.state.isOpen && 'open')}>
          <a onClick={this.toggleDropDown} data-toggle='dropdown' className='display-flex-end c-pointer'>
            <div className='img-details'>
              <img src={(this.props.user && this.props.user.profile && this.props.user.profile.image) || defaultImage} style={{ width: '40px', height: '40px' }} />
            </div>
            <div className='name-details font-size-14'>
              <FormattedMessage id='header.name' values={{ name: user.profile && user.profile.fullname }} defaultMessage='Hello, {name}' />
            </div>
            <i className='icon-arrow-down dropdown-details font-size-12' />
          </a>
          <ul className='dropdown-menu-p'>
            <li className='arrow' />
            {items.map(item => (
              <li key={item.id}>
                {item.route && (
                  <Link to={item.route} onClick={this.handleItemClick(item.action, item.ga)} className='display-flex-start'>
                    <i className={item.icon} />
                    <FormattedMessage id={`header.account.${item.id}`} defaultMessage={item.label} style={{ marginLeft: '5px' }} />
                  </Link>
                )}
                {item.url && (
                  <a href={item.url} target='_blank'>
                    <i className={item.icon} />
                    <FormattedMessage id={`header.account.${item.id}`} defaultMessage={item.label} style={{ marginLeft: '5px' }} />
                  </a>
                )}
              </li>
            ))}
            <li className='split' />
            <li onClick={() => this.signOut()} className='display-flex-start'>
              <a className='cursor-pointer'>
                <i className='icon-logout' />
                <FormattedMessage id='header.account.logout' defaultMessage='Log Out' />
              </a>
            </li>
          </ul>
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, actions)(Account);
