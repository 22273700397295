/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TaskUpdateStatusRequestDTO {
  /**
   *      0 - UNDONE     1 - DONE
   */
  status?: TaskUpdateStatusRequestDTO.StatusEnum;
}
export namespace TaskUpdateStatusRequestDTO {
  export type StatusEnum = 0 | 1;
  export const StatusEnum = {
    NUMBER_0: 0 as StatusEnum,
    NUMBER_1: 1 as StatusEnum,
  };
}
