// import { all, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { all, takeLatest } from 'redux-saga/effects';

import * as modalActions from './modal.actions';

function* onOpenModal(): Generator {
  // yield put(modalActions.resetDialogsState());
}

export function* watchModal(): Generator {
  yield all([takeLatest(modalActions.openModal, onOpenModal)]);
}
