import React, { Component } from 'react';
import { reduxForm, Field, change, touch } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import * as validators from 'Utils/validators';
import MetricPeriod from '../../../../deal/metrics/MetricPeriod';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';

import './Period.scss';

class Period extends Component {
  static defaultProps = {
    disableFrequency: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      year_to: null,
      period_to: null,
      // frequency: this.props.initialValues.frequency_id,
      frequency: this.props.initialValues ? this.props.initialValues.frequency_id : null,
    };
  }

  parseFrequencies(list) {
    return list.map(it => {
      return {
        value: it.metric_frequency_id,
        label: it.frequency,
      };
    });
  }

  displayPeriod(from, to) {
    const start = `${from.period ? from.period.label : 'Year'} ${from.year.label}`;
    const end = `${to.period ? to.period.label : 'Year'} ${to.year.label}`;
    if (start == end) return start;
    return `${start} to ${end}`;
  }

  onSubmit(values) {
    const period = {
      ...values,
      label: this.displayPeriod(values.from, values.to),
    };
    this.props.updatePeriod(period);
    this.props.closePopover();
  }

  render() {
    return (
      <div className='Period m-3'>
        <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
          {!this.props.hideFrequency && (
            <Field
              name={`frequency_id`}
              component={FieldSelect}
              options={this.parseFrequencies(this.props.frequencies)}
              onLoad={frequency => {
                this.setState({ frequency, year_to: null, period_to: null });
                this.props.dispatch(change('create-report-period', 'to', null));
                this.props.dispatch(change('create-report-period', 'from', null));
              }}
              validate={[validators.required]}
              label=''
              placeholder='Select frequency'
              type='text'
              multi={false}
              withImage={false}
              disabled={this.props.disableFrequency}
            />
          )}
          <Field
            name='from'
            haslabel={true}
            label='From'
            component={MetricPeriod}
            placeholderYear='Year'
            placeholder='Period'
            frequency={this.state.frequency}
            startYear={new Date().getFullYear() - 11}
            endYear={new Date().getFullYear() + 5}
            startPeriod={1}
            disabled={false}
            multiYear={false}
            multiPeriod={false}
            has_period={this.state.frequency !== 4}
            validate={[this.state.frequency !== 4 ? validators.period : validators.year]}
            onPeriodChange={e => {
              this.props.dispatch(change('create-report-period', 'to', null));
              this.props.dispatch(touch('create-report-period', 'to'));
              this.setState({ year_to: e.year, period_to: e.period });
            }}
            flexYear='flex-2'
            flexPeriod='flex-3'
            disabled={!this.state.frequency}
          />
          <div className='mt-2'>
            <Field
              name='to'
              haslabel={true}
              label='To'
              component={MetricPeriod}
              placeholderYear='Year'
              placeholder='Period'
              frequency={this.state.frequency}
              startYear={this.state.year_to}
              endYear={new Date().getFullYear() + 5}
              startPeriod={this.state.period_to}
              disabled={false}
              multiYear={false}
              multiPeriod={false}
              has_period={this.state.frequency !== 4}
              validate={[this.state.frequency !== 4 ? validators.period : validators.year]}
              onPeriodChange={e => {
                this.setState({ year_to: new Date().getFullYear() - 11, period_to: e.period });
              }}
              flexYear='flex-2'
              flexPeriod='flex-3'
              disabled={!this.state.frequency}
            />
          </div>
          <div className='mt-2 display-flex-center'>
            <Button type='submit' className='submit-btn-p'>
              <i className='icon-check mr-2' />
              Apply
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

Period = reduxForm({ form: 'create-report-period' })(Period);
export default Period;
