import _ from 'lodash';

export function at(obj, path, defaultValue) {
  return _.at(obj, path)[0] || defaultValue;
}

export function shortenName(name) {
  let Name = name;
  var values = name.split(' ').filter(function (v) {
    return v !== '';
  });
  if (values.length > 2) {
    var toMatch = Name;
    Name = toMatch.replace(/(\w)\w*\W*/g, function (_, i) {
      return i.toUpperCase();
    });
    return Name.substring(0, 3);
  } else {
    return name;
  }
}

export function moneyFormatter(number = 0, currency = 'USD', language = 'en-US') {
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0,
  }).format(number); // de-DE
}

export function compactMoney(number = 0, currency = 'USD', digits = 3, language = 'en-US') {
  return new Intl.NumberFormat(language, {
    style: 'currency',
    notation: 'compact',
    currency: currency || 'USD',
    compactDisplay: 'short',
    maximumSignificantDigits: digits,
  }).format(number);
}

export function compactNumber(number = 0, digits = 3, language = 'en-US') {
  return new Intl.NumberFormat(language, {
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits: digits,
  }).format(number);
}

export function trunc(string, n) {
  return _.truncate(string, { length: n });
}

export function blobToBase64(blob, callback) {
  var reader = new FileReader();
  reader.onload = function () {
    var dataUrl = reader.result;
    callback(dataUrl);
  };
  reader.readAsDataURL(blob);
}

export function numberFormatter(input, decimalScale = 2) {
  if (!input) return '';
  if (isNaN(input)) return '';

  if (Number.isInteger(Number(input)))
    return String(input)
      .replace(/,/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  else
    return String(Number(input).toFixed(decimalScale))
      .replace(/,/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function normalizeNumber(number) {
  return String(number).replace(/,/g, '');
}

export function numberRoundFormatter(number = 0, currency = 'USD', fraction = 0, language = 'en-US') {
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: fraction,
  }).format(roundNumber(number));
}

export function roundNumber(number) {
  if (!number) return 0;
  if (isNaN(number)) return 0;
  return Math.round(number);
}
