import React from 'react';
import { Icon as AntdIcon } from 'antd';

import { IconData, IconPrefixEnum } from '../../interfaces/icon-data';
import { Tooltip } from '..';

interface IconProps {
  iconData: IconData;
  tooltip?: string;
  spin?: boolean;
  style?: { [key: string]: string | number };
  onClick?(): void;
}

export function Icon(props: IconProps): JSX.Element {
  const onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick();
      event.stopPropagation();
      event.preventDefault();
    }
  };

  switch (props.iconData.prefix) {
    case IconPrefixEnum.Antd:
      return (
        <Tooltip title={props.tooltip}>
          <AntdIcon onClick={onClick} type={props.iconData.name} className={props.iconData.iconClassName} spin={props.spin} style={props.style} />
        </Tooltip>
      );

    case IconPrefixEnum.Material:
      return (
        <Tooltip title={props.tooltip}>
          <span onClick={onClick} className={`material-icons ${props.iconData.iconClassName}`} style={props.style}>
            {props.iconData.name}
          </span>
        </Tooltip>
      );

    case IconPrefixEnum.Remix:
      return (
        <Tooltip title={props.tooltip}>
          <i onClick={onClick} className={`${props.iconData.name} ${props.iconData.iconClassName}`} style={props.style} />
        </Tooltip>
      );

    default:
      return null;
  }
}

Icon.defaultProps = {
  spin: false,
  style: { fontSize: '1.25rem' },
};
