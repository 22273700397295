import { Mixpanel } from 'App/app';
import api from './api';
import { dispatchAlert } from './notificationActions';

export const LOADING_FUNDS = 'settings:funds:loading';
export const FUNDS_LOADED = 'settings:fund:loaded';
export const FUND_CREATED = 'settings:fund:created';
export const FUND_UPDATED = 'settings:fund:updated';
export const FUND_DELETED = 'settings:fund:deleted';

export function loadFunds() {
  return dispatch => {
    dispatch({ type: LOADING_FUNDS, payload: true });
    api
      .get('/funds')
      .then(({ data }) => {
        // console.log(data)
        dispatch({ type: FUNDS_LOADED, payload: data });
      })
      .catch(error => {
        dispatch({ type: LOADING_FUNDS, payload: false });
      });
  };
}

export function createFund(fund) {
  return dispatch => {
    api
      .post('/funds', fund)
      .then(({ data }) => {
        dispatch({ type: FUND_CREATED, payload: data });
        dispatchAlert(dispatch, {
          message: `Fund ${fund.fund_name} have been successfully added.`,
          type: 'success',
        });
        Mixpanel.track('Fund Created', { fund_id: data.fund_id });
      })
      .catch(error => {
        Mixpanel.track('Fund Creation Failed', { error: error.message });
      });
  };
}

export function updateFund(fund) {
  return dispatch => {
    api
      .put(`/funds/${fund.fund_id}`, fund)
      .then(({ data }) => {
        dispatch({ type: FUND_UPDATED, payload: data });
        dispatchAlert(dispatch, {
          message: `Fund ${fund.fund_name} have been successfully updated.`,
          type: 'primary',
        });
        Mixpanel.track('Fund Updated', { fund_id: data.fund_id });
      })
      .catch(error => {
        Mixpanel.track('Fund Update Failed', { error: error.message });
      });
  };
}

export function deleteFund(fund) {
  return dispatch => {
    api
      .delete(`/funds/${fund.fund_id}`)
      .then(({ data }) => {
        dispatch({ type: FUND_DELETED, payload: fund.fund_id });
        dispatchAlert(dispatch, {
          message: `Fund have been successfully deleted.`,
          type: 'danger',
        });
        Mixpanel.track('Fund Deleted', { fund_id: fund.fund_id });
      })
      .catch(error => {
        Mixpanel.track('Fund Deletion Failed', { error: error.message });
      });
  };
}
