import classNames from 'classnames';
import React, { Component } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import './Pages.scss';

const DragHandle = SortableHandle(({ isDisabled }) => (
  <i className='material-icons handle-dnd ml-1 mr-3' style={isDisabled ? { display: 'none', pointer: 'none' } : undefined}>
    dehaze
  </i>
));

const SortablePagesContainer = SortableContainer(({ children }) => {
  return <div>{children}</div>;
});

export default class Pages extends Component {
  remove(e, page) {
    e.stopPropagation();
    e.preventDefault();
    this.props.onRemovePage(page);
  }

  render() {
    const { tools } = this.props;
    const hasCoverPage = tools.showCoverPage;
    return (
      <div className='Pages'>
        <div className='pages-max-height'>
          <SortablePagesContainer onSortEnd={({ oldIndex, newIndex }) => this.props.onMovePage(oldIndex, newIndex)} useDragHandle>
            {Array(tools.pages)
              .fill()
              .map((item, idx) => (
                <SortablePageItem
                  key={idx + 1}
                  isDisabled={hasCoverPage && idx === 0}
                  disabled={hasCoverPage && idx === 0}
                  index={idx}
                  can_remove={tools.pages > 1}
                  active={tools.activePage == idx + 1}
                  label={`Page ${idx + 1}`}
                  onDelete={e => this.remove(e, idx + 1)}
                  onClick={() => this.props.onChangePage(idx + 1)}
                />
              ))}
          </SortablePagesContainer>
        </div>
        <hr />
        <PageItem can_add={true} label='Add a page' onClick={this.props.onAddPage} />
      </div>
    );
  }
}

const PageItem = props => (
  <span className={classNames('item-details display-flex-center', props.active && 'active')} onClick={props.onClick}>
    <DragHandle isDisabled={props.isDisabled} />
    <span className='flex-4 item-label'>{props.label}</span>
    {props.can_remove && (
      <i className='flex-1 material-icons on-delete-icon' onClick={props.onDelete}>
        delete
      </i>
    )}
    {props.can_add && <i className='flex-1 material-icons'>add</i>}
  </span>
);

const SortablePageItem = SortableElement(PageItem);
