import React, { ReactChild, ReactChildren } from 'react';

import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { PopoverPlacementEnum } from '../../enums/popover-placement.enum';

interface TooltipProps {
  title: string;
  placement: TooltipPlacement;
  children: ReactChildren | ReactChild;
}

export const Tooltip = (props: TooltipProps): JSX.Element => {
  return <AntdTooltip title={props.title}>{props.children}</AntdTooltip>;
};

Tooltip.defaultProps = {
  placement: PopoverPlacementEnum.Top,
};
