import {
  IS_LOADING_FAILED,
  IS_LOADING,
  LOADING_SPINNER,
  START_SYNC,
  LOADED_NYLAS_SETTINGS,
  LOADED_CONTACTS_SYNC,
  LOADED_CALENDAR_SYNC,
  LOADED_IS_CONTACTS,
  LOADED_IS_CALENDAR,
  LOADED_IS_EMAIL,
  LOADING_CONTACTS_STATS,
  LOADED_CONTACTS_STATS,
  LOADED_CONTACTS_GROUPS,
  LOADED_CALENDARS_LIST,
} from '../actions/integrationSettingsActions';

const initialState = {
  loading: false,
  spinner: false,
  syncing: false,
  settings: null,
  details: null,
  syncData: null,
  accounts: [],
  groups: [],
  // ---------
  calendars: [],
  calendar: null,
  email: null,
  errorPage: false,
  initialValues: {},
  statsLoading: false,
  stats: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_LOADING_FAILED:
      return {
        ...state,
        loading: false,
        errorPage: true,
      };

    case IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case LOADING_SPINNER:
      return {
        ...state,
        spinner: action.payload,
      };

    case START_SYNC:
      return {
        ...state,
        syncing: action.payload,
      };

    case LOADED_NYLAS_SETTINGS:
      return {
        ...state,
        loading: false,
        spinner: false,
        accounts: action.payload.accounts,
        details: action.payload.activeAccount ? action.payload.activeAccount.details : null,
        settings: action.payload.activeAccount ? action.payload.activeAccount.settings : null,
      };
    case LOADED_CALENDAR_SYNC:
      let calendarSettings = state.settings ? state.settings : null;
      let newCalendarSettings = { ...calendarSettings, sync_status: 1 };
      if (action.payload.sync_finished) {
        return {
          ...state,
          settings: newCalendarSettings,
          syncData: action.payload,
        };
      }
      return {
        ...state,
        syncData: action.payload,
      };
    case LOADED_CONTACTS_SYNC:
      let contactSettings = state.settings ? state.settings : null;
      let newContactSettings = { ...contactSettings, sync_status: 1 };
      if (action.payload.sync_finished) {
        return {
          ...state,
          settings: newContactSettings,
          syncData: action.payload,
        };
      }
      return {
        ...state,
        syncData: action.payload,
      };

    case LOADED_CALENDARS_LIST:
    case LOADED_CONTACTS_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };

    // -----------------------

    case LOADED_CONTACTS_STATS:
    case LOADED_IS_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
        loading: false,
        errorPage: false,
        stats: action.payload,
      };
    case LOADING_CONTACTS_STATS:
      return {
        ...state,
        statsLoading: true,
      };
    case LOADED_IS_EMAIL:
      return {
        ...state,
        email: action.payload,
        loading: false,
        errorPage: false,
      };

    case LOADED_IS_CALENDAR:
      let {
        activeAccount: { calendarSetting },
      } = action.payload;
      let event_ids = calendarSetting && calendarSetting.event_ids ? calendarSetting.event_ids.map(string => Number(string)) : [];
      let newData = {
        ...action.payload,
        activeAccount: {
          ...action.payload.activeAccount,
          calendarSetting: {
            ...calendarSetting,
            p2c_events: { question: '', response: event_ids },
          },
        },
      };
      const settings = newData.activeAccount.calendarSetting;
      const syncActive = settings ? settings.sync_status == '1' : false;

      return {
        ...state,
        calendar: newData,
        loading: false,
        initialValues: syncActive
          ? settings
          : {
              sync_direction: '1',
              p2c_events: { response: [1, 2, 3, 4, 5], question: '' },
              calendar_event_type: 1,
            },
        errorPage: false,
      };
  }

  return state;
}
