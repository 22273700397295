import React, { Component } from 'react';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';

import './BsPagination.scss';

class BsPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 5,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(currentPage) {
    if (currentPage !== this.props.currentPage) {
      this.props.reload(currentPage);
    }
  }

  renderItems() {
    return this.pagination(this.props.currentPage, this.props.pageCount).map((item, index) => {
      return (
        <PaginationItem
          key={index}
          active={item == this.props.currentPage}
          disabled={item == '...'}
          className={item == '...' ? 'disabled-case' : undefined}
          onClick={() => this.handleSelect(item)}
        >
          <PaginationLink>{item}</PaginationLink>
        </PaginationItem>
      );
    });
  }

  render() {
    const { props, state } = this;
    return (
      <div className='BsPagination'>
        <Pagination aria-label=''>
          <PaginationItem
            disabled={props.currentPage == 1}
            className={props.currentPage == 1 ? 'disabled-case' : undefined}
            onClick={() => this.handleSelect(props.currentPage - 1)}
          >
            <PaginationLink previous />
          </PaginationItem>
          {this.renderItems()}
          <PaginationItem
            className={props.currentPage == props.pageCount ? 'disabled-case' : undefined}
            disabled={props.currentPage == props.pageCount}
            onClick={() => this.handleSelect(props.currentPage + 1)}
          >
            <PaginationLink next />
          </PaginationItem>
        </Pagination>
      </div>
    );
  }

  pagination(c, m) {
    var current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }
}

export default BsPagination;
