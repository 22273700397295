import React from 'react';
import { connect } from 'react-redux';
import { Result, Card } from 'antd';
import { PentugramLogo2C } from 'SharedComponent/PentugramLogo';
import Header from '../../../app/Header';

import './SuspendedAccount.scss';

function SuspendedAccount(props) {
  return (
    <div className='SuspendedAccount'>
      <div className='logo-header'>
        <PentugramLogo2C width='60' height='60' />
      </div>
      <Header />
      <div className='row justify-content-md-center' style={{ marginTop: '100px' }}>
        <div className='col-md-6'>
          <Card>
            <Result
              status='error'
              title='Your subscription is currently on hold.'
              subTitle={
                <span>
                  Please contact the owner of your company. <strong>({props.me && props.me.company_owner_name})</strong>
                </span>
              }
              extra={[]}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    me: state.auth.user,
  };
}

export default connect(mapStateToProps, {})(SuspendedAccount);
