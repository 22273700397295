import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Radio } from 'antd';
import ID from 'Utils/UUID';
import debounce from 'lodash/debounce';
import CustomPopover from 'SharedComponent/CustomPopover';
import SubmitButton from 'SharedComponent/SubmitButton';
import CustomIcon from 'SharedComponent/CustomIcon';
import Spinner from 'SharedComponent/Spinner';
import DialogBox from 'SharedComponent/DialogBox/DialogBox';
import { headerHtml, footerHtml, coverBody, htmlShareTemplate } from './defaultCardContent';
import { PentugramComponents, Layouts } from './components/Components';
import { items, portfolio_items, company_items, pipeline_items, layouts, companies, extraOptions, fund_items } from './items';
import ActionsModal from './ActionsModal';
import Charts from './Charts';
import Period from './Period';
import Pages from './Pages';
import PeriodModal from './PeriodModal';
import ShareModal from './ShareModal';
import ViewReport from './ViewReport';

import './TemplateToolbar.scss';
const listCharts = ['bar_chart', 'line_chart'];
const group_charts = ['bar_chart', 'line_chart', 'portfolio_chart'];
export default class TemplateToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveModal: false,
      showPeriod: false,
      showShare: false,
      locationY: 1,
      showPageNumbersModal: false,
      pageNumberPosition: 'bottom_right',
    };
    this.page_layout = React.createRef();
    this.handleFieldListAction = this.handleFieldListAction.bind(this);
    this.listenToScroll = debounce(this.listenToScroll, 500).bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    this.setState({ locationY: winScroll });

    // const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    // const scrolled = ((winScroll / height) * 100).toFixed(2);

    // if(scrolled > 80) {
    //   this.setState({
    //     locationY: "50",
    //   })
    // } else {
    //   this.setState({
    //     locationY: scrolled,
    //   })
    // }
  };

  getItem(id, key, type, name, shared, color, x, y, width, height, html, url, htmlContent, isPageNumber) {
    let object = {
      [id]: {
        id,
        key,
        name,
        type,
        shared,
        properties: {
          alignment: '',
          color: { color: '#000', backgroundColor: color },
          border: { show: false, color: '#e9e9e9' },
          header: { backgroundColor: 'transparent' },
          location: { x, y },
          size: { width, height },
        },
        isLoading: false,
      },
    };
    if (htmlContent) object[id].htmlContent = htmlContent;
    if (html) object[id].html = html;
    if (url) object[id].url = url;
    if (isPageNumber) object[id].isPageNumber = true;

    return object;
  }

  addCoverPage() {
    const user = this.props.user;

    const object = {
      ...this.getItem(ID.uuid(), 'text', 'default', 'Header', false, '#c1e1f5', 0, 0, 793, 50, headerHtml(user.company.name), null),
      ...this.getItem(ID.uuid(), 'image', 'default', 'Image', false, 'transparent', 273, 273, 300, 300, null, user.company.logo || ' '),
      ...this.getItem(ID.uuid(), 'text', 'default', 'Description', false, 'transparent', 0, 780, 793, 150, coverBody(user.company.name, 'Quarterly'), null),
      ...this.getItem(ID.uuid(), 'text', 'default', 'Footer', false, '#c1e1f5', 0, 1072, 793, 50, footerHtml('Private & Confidential'), null),
    };

    this.props.addCover(object);
  }

  addHeader(item) {
    const user = this.props.user;
    const orientation = this.props.tools.orientation;
    const object = {
      ...this.getItem(ID.uuid(), 'text_content', item.type, 'Header', true, '#c1e1f5', 0, 0, 'fullwidth', 70, null, null, headerHtml(user.company.name)),
    };

    this.props.addSharedComponent(item.key, item.field, object);
  }

  addFooter(item) {
    const user = this.props.user;
    const object = {
      ...this.getItem(ID.uuid(), 'text_content', item.type, 'Footer', true, '#c1e1f5', 0, 'bottom', 'fullwidth', 70, null, null, footerHtml('Private & Confidential')),
    };

    this.props.addSharedComponent(item.key, item.field, object);
  }

  updateLayout(item, value) {
    switch (item.key) {
      case 'cover_page_item':
        this.props.updateTools(item.field, value);
        if (value) this.addCoverPage();
        else this.props.onRemovePage(1);
        break;

      case 'header_item':
        this.props.updateTools(item.field, value);
        if (value) this.addHeader(item);
        else this.props.removeSharedComponent(item);
        break;

      case 'footer_item':
        this.props.updateTools(item.field, value);
        if (value) this.addFooter(item);
        else this.props.removeSharedComponent(item);
        break;

      case 'grid_item':
        this.props.updateTools(item.field, value);
        break;

      default:
        this.props.updateTools(item.field, value);
    }
  }

  onPeriodOpen() {
    this.props.onChangePage(1);
    this.props.onUpdateReport();
    this.setState({ showPeriod: true });
  }

  getCustomFields(type) {
    // TODO cf should be inside sidebar
    return this.props.customFields[type]
      ? this.props.customFields[type]
          .filter(it => it.show)
          .map(it => {
            return { value: it.field_name, label: it.field_label, show: false, type: it.field_type };
          })
      : [];
  }

  handleFieldListAction(item) {
    const element = {
      id: ID.uuid(),
      key: item.key,
      name: item.name,
      type: 'default',
      shared: false,
      properties: {
        lockAspectRatio: item.key == 'image',
        alignment: '',
        color: {
          color: '#000',
          backgroundColor: this.props.tools.backgroundColor,
        },
        border: {
          show: false,
          color: this.props.tools.borderColor,
        },
        header: {
          backgroundColor: 'transparent',
        },
        location: {
          x: 1,
          y: this.state.locationY,
        },
        size: {
          width: item.width,
          height: item.height,
        },
      },
      isLoading: false,
    };

    if (item.can_update) {
      element['can_update'] = true;
      element['back_field'] = item.back_field;
    }

    if (item.title) {
      element['title'] = item.title;
    }

    if (item.target) {
      element['html'] = 'Pentugram app';
      element['target'] = item.target;
      element['newWindow'] = false;
    }

    if (item.search) {
      element['search'] = item.search;
    }

    if (item.fields) {
      if (item.customFields) {
        element['fields'] = [...item.fields, ...this.getCustomFields(item.customFields)];
      } else element['fields'] = item.fields;
    }

    if (element.key == 'text_content') {
      element['htmlContent'] = item.html;
    }

    if (item.group == 'geometric') {
      element['geometric'] = {
        backgroundColor: '#03a6ff',
        round: item.key == 'rectangle' ? false : true,
      };
    }

    if (item.checkbox) {
      element['checkbox'] = item.checkbox;
    }

    if (element.key == 'image') {
      element['url'] = item.url;
    }
    if (item.is_pipeline) {
      element['is_pipeline'] = item.is_pipeline;
    }
    if (item.group == 'table') {
      // metrics
      element['source_tools'] = true;
      element['type'] = 1; // selectField option
      element['header'] = 'Table header';
      element['data_align'] = 'left';
      element['aggregation'] = false;
      element['aggregation_type'] = 1;
      element['data'] = {
        axes: ['Period 1', 'Period 2', 'Period 3'],
        data: [
          {
            name: 'Category 1',
            metrics: [
              {
                metric_id: Math.random() * 100,
                name: 'Metric 1',
                values: [{ value: Math.random() * 100 }, { value: Math.random() * 100 }, { value: Math.random() * 100 }],
              },
              {
                metric_id: Math.random() * 100,
                name: 'Metric 2',
                values: [{ value: Math.random() * 100 }, { value: Math.random() * 100 }, { value: Math.random() * 100 }],
              },
              {
                metric_id: Math.random() * 100,
                name: 'Metric 3',
                values: [{ value: Math.random() * 100 }, { value: Math.random() * 100 }, { value: Math.random() * 100 }],
              },
            ],
          },
        ],
      };
    }

    if (item.group == 'portfolio_table') {
      // portfolio
      // element["source_tools"] = true;
      element['title'] = 'Table header';
      element['data_align'] = 'left';
      element['aggregation'] = false;
      element['aggregation_type'] = 1;
    }
    if (item.key === 'portfolio_card') {
      element['group'] = item.group;
    }
    if (item.is_investments_table) {
      element['is_investments_table'] = true;
    }

    if (item.is_lps_table) {
      element['is_lps_table'] = true;
    }
    if (item.is_funds_table) {
      element['is_funds_table'] = true;
    }
    if (item.items_name) {
      element['items_name'] = item.items_name;
    }
    if (item.is_rounds_table) {
      element['is_rounds_table'] = item.is_rounds_table;
    }

    if (item.key == 'pipeline_card') {
      element['title'] = item.title || 'Untitled chart';
      element['chartType'] = 'bar_chart';
      element['legend_position'] = 'bottom_left';
      element['title_position'] = 'top_center';
      element['is_pipeline'] = true;
      if (item.chartType) {
        element['chartType'] = 'pie_chart';
        element['pieOptions'] = item.pieOptions;
      } else {
        element['chartType'] = 'bar_chart';
      }
      if (item.is_deal_rounds) {
        element['is_deal_rounds'] = true;
      }
      element['data'] = [
        { value: Math.random() * 100, name: 'data 1' },
        { value: Math.random() * 100, name: 'data 2' },
        { value: Math.random() * 100, name: 'data 3' },
        { value: Math.random() * 100, name: 'data 4' },
      ];
    }
    if (item.key == 'portfolio-dash_card') {
      element['key'] = 'pipeline_card';
      element['title'] = 'untitled chart';

      if (item.cascader_options) {
        element['cascader_options'] = item.cascader_options;
      }

      if (item.chartType) {
        element['chartType'] = 'pie_chart';
        element['pieOptions'] = item.pieOptions;
      } else {
        element['chartType'] = 'bar_chart';
      }

      element['title_position'] = 'top_center';
      element['legend_position'] = 'bottom_left';
      element['is_pipeline'] = false;
      element['data'] = [
        { value: Math.random() * 100, name: 'data 1' },
        { value: Math.random() * 100, name: 'data 2' },
        { value: Math.random() * 100, name: 'data 3' },
        { value: Math.random() * 100, name: 'data 4' },
      ];
    }
    if (item.key == 'manual_table') {
      element['title'] = 'Table header';
      element['manual'] = true;
      element['tableData'] = [
        [
          {
            id: ID.uuid(),
            style: {
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
              width: 100,
              height: 20,
            },
            colSpan: 1,
            rowSpan: 1,
            content: '',
          },
          {
            id: ID.uuid(),
            style: {
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
              width: 100,
              height: 20,
            },
            colSpan: 1,
            rowSpan: 1,
            content: '',
          },
          {
            style: {
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
              width: 100,
              height: 20,
            },
            colSpan: 1,
            rowSpan: 1,
            content: '',
          },
        ],
        [
          {
            id: ID.uuid(),
            style: {
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
              width: 100,
              height: 20,
            },
            colSpan: 1,
            rowSpan: 1,
            content: '',
          },
          {
            id: ID.uuid(),
            style: {
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
              width: 100,
              height: 20,
            },
            colSpan: 1,
            rowSpan: 1,
            content: '',
          },
          {
            id: ID.uuid(),
            style: {
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
              width: 100,
              height: 20,
            },
            colSpan: 1,
            rowSpan: 1,
            content: '',
          },
        ],
        [
          {
            id: ID.uuid(),
            style: {
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
              width: 100,
              height: 20,
            },
            colSpan: 1,
            rowSpan: 1,
            content: '',
          },
          {
            id: ID.uuid(),
            style: {
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
              width: 100,
              height: 20,
            },
            colSpan: 1,
            rowSpan: 1,
            content: '',
          },
          {
            id: ID.uuid(),
            style: {
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
              width: 100,
              height: 20,
            },
            colSpan: 1,
            rowSpan: 1,
            content: '',
          },
        ],
      ];
      element['columns'] = [
        { name: '#', value: '#'.replace(/\W/g, '_') },
        { name: 'First column', value: 'First column'.replace(/\W/g, '_') },
        { name: 'Second column', value: 'Second column'.replace(/\W/g, '_') },
        { name: 'Third column', value: 'Third column'.replace(/\W/g, '_') },
      ];
      element['data'] = [
        {
          id: ID.uuid(),
          ['#'.replace(/\W/g, '_')]: 'Row 01',
          ['First column'.replace(/\W/g, '_')]: 'First column 01',
          ['Second column'.replace(/\W/g, '_')]: 'Second column 01',
          ['Third column'.replace(/\W/g, '_')]: 'Third column 01',
        },
        {
          id: ID.uuid(),
          ['#'.replace(/\W/g, '_')]: 'Row 02',
          ['First column'.replace(/\W/g, '_')]: 'First column 02',
          ['Second column'.replace(/\W/g, '_')]: 'Second column 02',
          ['Third column'.replace(/\W/g, '_')]: 'Third column 02',
        },
        {
          id: ID.uuid(),
          ['#'.replace(/\W/g, '_')]: 'Row 03',
          ['First column'.replace(/\W/g, '_')]: 'First column 03',
          ['Second column'.replace(/\W/g, '_')]: 'Second column 03',
          ['Third column'.replace(/\W/g, '_')]: 'Third column 03',
        },
      ];
    }

    if (item.group == 'data_table') {
      element['value'] = Math.random() * 100;
    }

    if (item.group == 'portfolio_chart') {
      element['params'] = item.params;
      // element["description"] = "";
      element['items'] = [
        { value: Math.random() * 100, name: 'data 1' },
        { value: Math.random() * 100, name: 'data 2' },
        { value: Math.random() * 100, name: 'data 3' },
        { value: Math.random() * 100, name: 'data 4' },
      ];
    }

    if (group_charts.includes(item.group)) {
      element['title_position'] = 'top_center';
      element['legend_position'] = 'bottom_left';
    }

    if (listCharts.includes(item.group)) {
      element['source_tools'] = true;
      element['type'] = 1; // selectField option
      // element["description"] = "";
      element['can_update'] = true;
      element['back_field'] = 'metric';
      element['data'] = {
        axes: ['Period 1', 'Period 2', 'Period 3'],
        data: [
          {
            name: 'Category 1',
            metrics: [
              {
                metric_id: Math.random() * 100,
                name: 'Metric 1',
                values: [{ value: Math.random() * 100 }, { value: Math.random() * 100 }, { value: Math.random() * 100 }],
              },
              {
                metric_id: Math.random() * 100,
                name: 'Metric 2',
                values: [{ value: Math.random() * 100 }, { value: Math.random() * 100 }, { value: Math.random() * 100 }],
              },
              {
                metric_id: Math.random() * 100,
                name: 'Metric 3',
                values: [{ value: Math.random() * 100 }, { value: Math.random() * 100 }, { value: Math.random() * 100 }],
              },
            ],
          },
        ],
      };
    }

    this.props.onFieldAdd(element);
  }

  render() {
    return (
      <div>
        {this.props.loading ? (
          <div className='TemplateToolbar display-flex-center'>
            <div className='overlay-spinner'>
              <Spinner isLoading={this.props.loading} />
            </div>
          </div>
        ) : (
          this.renderToolbar()
        )}
      </div>
    );
  }

  canSwitch(pages, page, type) {
    if (type == 'previous') {
      if (page == 1) return false;
      if (pages == 1) return false;
    } else {
      if (pages == 1) return false;
      if (pages == page) return false;
    }
    return true;
  }
  handlePageNumbersClick = () => {
    this.page_layout.current.toggle();
    this.setState({ showPageNumbersModal: true });
  };
  handlePageOrientationClick = () => {
    this.page_layout.current.toggle();
    this.props.togglePageOrientation('all');
  };

  handlePageBackgroundClick = () => {
    this.page_layout.current.toggle();
    this.props.updateTools('showPageBGModal', true);
  };
  parsePosition = (position, height, width) => {
    switch (position) {
      case 'top_right':
        return ['right', 0];
      case 'top_left':
        return [0, 0];
      case 'bottom_right':
        return ['right', 'bottom'];
      case 'bottom_left':
        return [0, 'bottom'];
      default:
        return [0, 0];
    }
  };
  handlePageNumbersConfirm = () => {
    let [x, y] = this.parsePosition(this.state.pageNumberPosition, 60, 60);
    const object = {
      ...this.getItem(ID.uuid(), 'text_content', 'text_content', 'Page number', true, '#transparent', x, y, 60, 60, null, null, '<p>{{page_num}}</p>', true),
    };

    this.props.addSharedComponent('text_content', 'text_content', object);
  };

  handleRadioChange = e => {
    let value = e.target.value;
    this.setState({ pageNumberPosition: value });
  };

  renderToolbar() {
    const { editor_prop, tools } = this.props;
    return (
      <div className='TemplateToolbar ml-3'>
        <ReactTooltip place='bottom' />
        <div className='toolbar-items'>
          <div className='display-flex-center'>
            <i
              className='material-icons material-details mr-2'
              data-tip='Previous page'
              disabled={!this.canSwitch(tools.pages, tools.activePage, 'previous')}
              onClick={() => this.props.onChangePage(tools.activePage - 1)}
            >
              skip_previous
            </i>
            <CustomPopover
              label={`Page ${this.props.tools.activePage} of ${this.props.tools.pages}`}
              classes='period-popover'
              hideAfterClick={true}
              arrow={true}
              title=''
              icon=''
              popoverID='report-pages-id'
            >
              <Pages
                tools={tools}
                onAddPage={this.props.onAddPage}
                onMovePage={this.props.onMovePage}
                onRemovePage={this.props.onRemovePage}
                onChangePage={this.props.onChangePage}
              />
            </CustomPopover>
            <i
              className='material-icons material-details mr-2'
              data-tip='Next page'
              disabled={!this.canSwitch(tools.pages, tools.activePage, 'next')}
              onClick={() => this.props.onChangePage(tools.activePage + 1)}
            >
              skip_next
            </i>
          </div>
          {items.map((it, idx) => (
            <div key={idx + 1} className='display-flex-start ml-3 toolbar-item-basic'>
              {it.divider ? (
                <i className='divider ml-3 mr-3' />
              ) : (
                <i className='material-icons material-details mr-2' onClick={() => this.handleFieldListAction(it)} data-tip={it.tooltip}>
                  {it.icon}
                </i>
              )}
            </div>
          ))}
          <div className='items-for-smaller-screens'>
            <CustomPopover label='Basics' hideAfterClick={true} arrow={true} classes='' title='' icon='' popoverID='items-pentugram-id'>
              {items.map((it, idx) => (
                <div key={idx + 1} className='display-flex-start material-details'>
                  {it.divider ? null : (
                    <div className='display-flex-start pt-1 pl-1 pr-1 dropdown-item' onClick={() => this.handleFieldListAction(it)}>
                      <i className='material-icons mr-2'>{it.icon}</i>
                      <label> {it.name}</label>
                    </div>
                  )}
                </div>
              ))}
            </CustomPopover>
          </div>
          <div className=''>
            <CustomPopover label='Funds' hideAfterClick={true} arrow={true} classes='' title='' icon='' popoverID='funds-pentugram-id'>
              <Charts items={fund_items} onAdd={it => this.handleFieldListAction(it)} />
            </CustomPopover>
          </div>
          <div className=''>
            <CustomPopover label='Portfolio' hideAfterClick={true} arrow={true} classes='' title='' icon='' popoverID='portfolio-pentugram-id'>
              <Charts items={portfolio_items} onAdd={it => this.handleFieldListAction(it)} />
            </CustomPopover>
          </div>
          <div className=''>
            <CustomPopover label='Pipeline' hideAfterClick={true} arrow={true} classes='' title='' icon='' popoverID='pipeline-pentugram-id'>
              <Charts items={pipeline_items} onAdd={it => this.handleFieldListAction(it)} />
            </CustomPopover>
          </div>
          <div className='ml-3'>
            <CustomPopover label='Deals' hideAfterClick={true} arrow={true} classes='' title='Deal' icon='' popoverID='deal-pentugram-id'>
              <Charts items={company_items} onAdd={it => this.handleFieldListAction(it)} />
              <PentugramComponents divider={true} items={companies} onAdd={it => this.handleFieldListAction(it)} />
            </CustomPopover>
          </div>
          <div className='ml-3'>
            <CustomPopover
              label='Page Layout'
              hideAfterClick={true}
              arrow={true}
              classes='period-popover'
              title=''
              icon='dashboard'
              ref={this.page_layout}
              popoverID='report-layout-id'
            >
              <Layouts
                items={layouts}
                tools={this.props.tools}
                onAdd={it => this.handleFieldListAction(it)}
                onAddCover={() => this.addCoverPage()}
                updateTools={this.updateLayout.bind(this)}
              >
                <span className='item-details' onClick={this.handlePageNumbersClick}>
                  <i className='material-icons'>looks_one</i>
                  Page numbers
                </span>
                <span className='item-details' onClick={this.handlePageOrientationClick}>
                  <i className='material-icons'>{!this.props.tools.orientation ? 'crop_landscape' : 'crop_portrait'}</i>
                  Switch pages orientation
                </span>
                <span className='item-details' onClick={this.props.addContentTable}>
                  <i className='material-icons'>format_list_numbered_rtl</i>
                  Table of content
                </span>
                {this.props.hasBG && (
                  <span className='item-details' onClick={this.handlePageBackgroundClick}>
                    <i className='material-icons'>wallpaper</i>
                    Page background
                  </span>
                )}
              </Layouts>
            </CustomPopover>
          </div>
        </div>
        <div />
        <div className='display-flex-end mr-2'>
          <CustomIcon styles={{ marginRight: '8px' }} icon='person_add' label='' tooltip='Share this report' onClick={e => this.setState({ showShare: true })} />
          <CustomIcon styles={{ marginRight: '8px' }} icon='visibility' label='' tooltip='View report' onClick={e => this.props.onViewReport()} />
          <SubmitButton
            // label={editor_prop.mode == "create" ? "Save" : "Update"}
            label='Save'
            icon='icon-check'
            className='ml-1'
            style={{ width: '100px' }}
            onClick={() => this.setState({ saveModal: true })}
          />
          <CustomPopover
            label=''
            hideAfterClick={true}
            arrow={false}
            btnStyle={{ padding: 0, background: 'transparent' }}
            title=''
            icon='more_vert'
            popoverID='extra-options-pentugram-id'
          >
            <Layouts items={extraOptions} tools={this.props.tools} updateTools={this.updateLayout.bind(this)} />
          </CustomPopover>
          <ActionsModal
            show={this.state.saveModal}
            onHide={() => this.setState({ saveModal: false })}
            saveReport={this.props.saveReport}
            onSaving={this.props.onSaving}
            onChangePage={this.props.onChangePage}
            initialValues={tools}
            editor_prop={editor_prop}
          />
          <PeriodModal show={this.state.showPeriod} initialValues={tools.period} onClose={() => this.setState({ showPeriod: false })} updatePeriod={this.props.updatePeriod} />
          <ShareModal
            show={this.state.showShare}
            onClose={() => this.setState({ showShare: false })}
            shareReport={this.props.shareReport}
            initialValues={{ html: htmlShareTemplate(process.env.PENTUGRAM_URL, tools.period, editor_prop.key) }}
          />
        </div>
        <DialogBox
          className='page-numbers-modal'
          isVisible={this.state.showPageNumbersModal}
          title='Page numbers'
          handleConfirm={this.handlePageNumbersConfirm}
          onClose={() => this.setState({ showPageNumbersModal: false })}
          confirmLabel='Apply'
          confirmIcon='icon-check'
        >
          <div className='p-3'>
            <h6> Position </h6>
            <Radio.Group onChange={this.handleRadioChange} value={this.state.pageNumberPosition} defaultValue={this.state.pageNumberPosition}>
              <Radio.Button value='top_left'>Top left</Radio.Button>
              <Radio.Button value='top_right'>Top right</Radio.Button>
              <Radio.Button value='bottom_left'>Bottom left</Radio.Button>
              <Radio.Button value='bottom_right'>Bottom right</Radio.Button>
            </Radio.Group>
          </div>
        </DialogBox>
      </div>
    );
  }
}
