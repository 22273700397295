import { createReducer, Action, createEntityAdapter } from '@reduxjs/toolkit';

import { ActivitySummaryData } from '../../../../../core/models/summary-log/activity/activity-summary-data';
import { EmailSummaryData } from '../../../../../core/models/summary-log/email/email-summary-data';
import { FileSummaryData } from '../../../../../core/models/summary-log/file/file-summary-data';
import { NoteSummaryData } from '../../../../../core/models/summary-log/note/note-summary-data';

import * as fromSummaryActions from './summary.action';
import { ActivitiesState, EmailsState, FilesState, NotesState, SummaryLogState } from './types';

const activityAdapter = createEntityAdapter<ActivitySummaryData>({
  selectId: activity => activity.activityId,
  sortComparer: (a, b) => Number(b.activityId) - Number(a.activityId),
});
const notesAdapter = createEntityAdapter<NoteSummaryData>({
  selectId: note => note.noteId,
  sortComparer: (a, b) => Number(b.noteId) - Number(a.noteId),
});
const emailsAdapter = createEntityAdapter<EmailSummaryData>({
  selectId: email => email.emailId,
  sortComparer: (a, b) => Number(b.emailId) - Number(a.emailId),
});
const filesAdapter = createEntityAdapter<FileSummaryData>({
  selectId: email => email.fileId,
  sortComparer: (a, b) => Number(b.fileId) - Number(a.fileId),
});

export const selectAllActivity = activityAdapter.getSelectors().selectAll;
export const selectAllNotes = notesAdapter.getSelectors().selectAll;
export const selectAllEmails = emailsAdapter.getSelectors().selectAll;
export const selectAllFiles = filesAdapter.getSelectors().selectAll;

export const activityInitialState: ActivitiesState = activityAdapter.getInitialState<ActivitiesState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  meta: null,
});

export const notesInitialState: NotesState = notesAdapter.getInitialState<NotesState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  meta: null,
});

export const emailsInitialState: EmailsState = emailsAdapter.getInitialState<EmailsState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  meta: null,
});

export const filesInitialState: FilesState = emailsAdapter.getInitialState<FilesState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  meta: null,
});

const initialSummaryState: SummaryLogState = {
  activities: activityInitialState,
  notes: notesInitialState,
  emails: emailsInitialState,
  files: filesInitialState,
  note: {} as NoteSummaryData,
};

const _summaryLogReducer = createReducer<SummaryLogState>(initialSummaryState, builder => {
  builder
    .addCase(
      fromSummaryActions.loadingActivityList,
      (state): SummaryLogState => ({
        ...state,
        activities: { ...state.activities, loading: true },
      }),
    )
    .addCase(fromSummaryActions.getNoteSuccess, (state, { payload: { note } }): SummaryLogState => ({ ...state, note: note }))
    .addCase(
      fromSummaryActions.loadActivitySuccess,
      (state, { payload }): SummaryLogState => {
        if (payload.reset) {
          return { ...state, activities: activityAdapter.setAll({ ...state.activities, loading: false, meta: payload.meta }, payload.list) };
        } else {
          return { ...state, activities: activityAdapter.addMany({ ...state.activities, loading: false, meta: payload.meta }, payload.list) };
        }
      },
    )
    .addCase(
      fromSummaryActions.resetStore,
      (state): SummaryLogState => ({
        ...state,
        activities: activityInitialState,
      }),
    )
    .addCase(
      fromSummaryActions.loadingNotes,
      (state): SummaryLogState => ({
        ...state,
        notes: { ...state.notes, loading: true },
      }),
    )
    .addCase(
      fromSummaryActions.loadNotesSuccess,
      (state, { payload }): SummaryLogState => {
        if (payload.reset) {
          return { ...state, notes: notesAdapter.setAll({ ...state.notes, loading: false, meta: payload.meta }, payload.list) };
        } else {
          return { ...state, notes: notesAdapter.addMany({ ...state.notes, loading: false, meta: payload.meta }, payload.list) };
        }
      },
    )
    .addCase(
      fromSummaryActions.resetStoreNotes,
      (state): SummaryLogState => ({
        ...state,
        notes: notesInitialState,
      }),
    )
    .addCase(
      fromSummaryActions.loadingEmails,
      (state): SummaryLogState => ({
        ...state,
        emails: { ...state.emails, loading: true },
      }),
    )
    .addCase(
      fromSummaryActions.loadEmailsSuccess,
      (state, { payload }): SummaryLogState => {
        if (payload.reset) {
          return { ...state, emails: emailsAdapter.setAll({ ...state.emails, loading: false, meta: payload.meta }, payload.list) };
        } else {
          return { ...state, emails: emailsAdapter.addMany({ ...state.emails, loading: false, meta: payload.meta }, payload.list) };
        }
      },
    )
    .addCase(
      fromSummaryActions.resetStoreEmails,
      (state): SummaryLogState => ({
        ...state,
        emails: emailsInitialState,
      }),
    )
    .addCase(
      fromSummaryActions.loadingFiles,
      (state): SummaryLogState => ({
        ...state,
        files: { ...state.files, loading: true },
      }),
    )
    .addCase(
      fromSummaryActions.loadFilesSuccess,
      (state, { payload }): SummaryLogState => {
        if (payload.reset) {
          return { ...state, files: filesAdapter.setAll({ ...state.files, loading: false, meta: payload.meta }, payload.list) };
        } else {
          return { ...state, files: filesAdapter.addMany({ ...state.files, loading: false, meta: payload.meta }, payload.list) };
        }
      },
    )
    .addCase(
      fromSummaryActions.resetStoreFiles,
      (state): SummaryLogState => ({
        ...state,
        files: filesInitialState,
      }),
    );
});

export function summaryReducer(state: SummaryLogState | undefined, action: Action): SummaryLogState {
  return _summaryLogReducer(state, action);
}
