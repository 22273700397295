import React from 'react';

import './SearchBox.scss';

export default function SearchBox(props) {
  return (
    <div className='SearchBox'>
      <div className='search-bar'>
        <input onChange={e => props.onChange(e.target.value)} type='search' name='search' pattern='.*\S.*' required />
        <button className='search-btn' type='submit'>
          <span>Search</span>
        </button>
      </div>
    </div>
  );
}
