import api from './api';

export const LOADING_PAYMENT_HISTORY = 'settings:billing:loading';
export const LOADED_PAYMENT_HISTORY = 'settings:billing:history:loaded';
export const LOADED_PAYMENT_PACKS = 'settings:billing:packs:loaded';
export const SET_PADDLE = 'settings:billing:set:paddle';

export function setPaddle(paddle) {
  return dispatch => {
    dispatch({
      type: SET_PADDLE,
      payload: paddle,
    });
  };
}

export function loadPaymentHistory(page, size) {
  return dispatch => {
    dispatch({ type: LOADING_PAYMENT_HISTORY });
    api
      .get('/billing/history')
      .then(({ data }) => {
        dispatch({
          type: LOADED_PAYMENT_HISTORY,
          payload: data,
        });
      })
      .catch(err => {});
  };
}

export function loadPacks() {
  return dispatch => {
    api
      .get('/billing/plan')
      .then(({ data }) => {
        dispatch({
          type: LOADED_PAYMENT_PACKS,
          payload: data,
        });
      })
      .catch(err => {});
  };
}

export function callbackSubscription(data) {
  return dispatch => {
    api
      .post('/billing/callback', data)
      .then(() => location.reload())
      .catch();
  };
}
