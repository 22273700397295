import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, ButtonGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table/lib/BootstrapTable';
import ReactTooltip from 'react-tooltip';

import CheckBoxColumn from './CkeckBoxColumn';
import { withOptionsColumn } from './TableOptionsHOC';

class Table extends Component {
  static propTypes = {
    additionalToolbarContent: PropTypes.object,
    filtersWindowContent: PropTypes.object,
    items: PropTypes.array.isRequired,
    noDataText: PropTypes.any,
    page: PropTypes.number,
    searchPlaceholder: PropTypes.string,
    sizePerPage: PropTypes.number,
    totalDataSize: PropTypes.number,
    onApplyFilters: PropTypes.func,
    onPageChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    onSizePerPageList: PropTypes.func,
    showingEntries: PropTypes.func,
    onSortChange: PropTypes.func,
    pagination: PropTypes.bool,
    remote: PropTypes.bool,
    striped: PropTypes.bool,
  };

  static defaultProps = {
    additionalToolbarContent: null,
    clearSearch: false,
    noDataText: 'No items found',
    searchPlaceholder: 'Search items',
    pagination: true,
    remote: true,
    striped: false,
    backgroundColor: '#fff',
  };

  static filtersPopoverRef;

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      showArrangeModal: false,
      columnNames: [],
      defaultColumns: [],
      initialColumns: null,
      savedColumns: null,
    };

    this.createCustomToolBar = this.createCustomToolBar.bind(this);
  }

  render() {
    const { props } = this;
    const selectRowProp = {
      mode: 'checkbox',
      customComponent: this.customMultiSelect,
      clickToSelect: false,
      onSelect: props.onRowSelect,
      onSelectAll: props.onSelectAll,
    };
    const checkedRows = {
      mode: 'checkbox',
      customComponent: this.customMultiSelect,
      clickToSelect: true,
      onSelect: props.onRowSelect,
      onSelectAll: props.onSelectAll,
      selected: props.selected || [],
    };
    const options = {
      clearSearch: false,
      noDataText: props.noDataText,
      page: props.page,
      searchDelayTime: 500,
      sizePerPage: props.sizePerPage,
      sizePerPageList: [5, 10, 50],
      toolBar: this.createCustomToolBar,
      onPageChange: props.onPageChange,
      onSearchChange: props.onSearchChange,
      onSizePerPageList: props.onSizePerPageList,
      onSortChange: props.onSortChange,
      sizePerPageDropDown: props.showingEntries,
      onRowClick: props.onRowClick,
    };
    return (
      <div className='pentugram-table'>
        <BootstrapTable
          height={props.height}
          ref={props.setRef}
          maxHeight={props.maxHeight}
          scrollTop={props.scrollTop}
          data={props.items}
          options={options}
          remote={props.remote}
          search={props.search}
          pagination={this.props.pagination}
          bordered={false}
          fetchInfo={{ dataTotalSize: props.totalDataSize }}
          tableStyle={{ backgroundColor: props.backgroundColor }}
          searchPlaceholder={props.searchPlaceholder}
          striped={props.striped}
          keyField={this.props.keyField}
          selectRow={props.checkBox ? (props.checkedRows ? checkedRows : selectRowProp) : {}}
          tableHeaderClass='custom-select-header-class'
          tableBodyClass='custom-select-body-class'
          printable={props.printable}
          bodyStyle={props.bodyStyle}
        >
          {this.props.children}
        </BootstrapTable>
      </div>
    );
  }

  customMultiSelect(props) {
    const { type, checked, disabled, onChange, rowIndex } = props;
    if (rowIndex === 'Header') {
      return (
        <div className='checkbox-personalized'>
          <CheckBoxColumn {...props} />
          <label htmlFor={'checkbox' + rowIndex}>
            <div className='check' />
          </label>
        </div>
      );
    } else {
      return (
        <div className='checkbox-personalized'>
          <input
            type={type}
            name={'checkbox' + rowIndex}
            id={'checkbox' + rowIndex}
            checked={checked}
            disabled={disabled}
            onChange={e => onChange(e, rowIndex)}
            ref={input => {
              if (input) {
                input.indeterminate = props.indeterminate;
              }
            }}
          />
          <label htmlFor={'checkbox' + rowIndex}>
            <div className='check' />
          </label>
        </div>
      );
    }
  }

  renderFiltersPopover() {
    const { props } = this;

    return (
      <Popover id='table-manage-filters-popover' title='Manage filters' style={{ height: 'auto', overflowY: 'visible' }}>
        <div style={{ padding: '5px 12px' }}>
          {props.filtersWindowContent}

          <hr style={{ margin: '12px 0' }} />

          <div className='text-center'>
            <ButtonGroup>
              <Button bsStyle='primary' onClick={props.onApplyFilters}>
                Apply
              </Button>

              <Button bsStyle='primary' onClick={() => this.filtersPopoverRef.hide()}>
                Close
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Popover>
    );
  }

  createCustomToolBar(tableProps) {
    return (
      <div className='text-right' style={{ margin: '0 15px' }}>
        <ReactTooltip />
        {this.props.filtersWindowContent && (
          <span style={{ position: 'relative' }}>
            <OverlayTrigger ref={ref => (this.filtersPopoverRef = ref)} container={this} trigger='click' placement='bottom' overlay={this.renderFiltersPopover()}>
              <Button
                bsStyle='primary'
                bsSize='small'
                style={{
                  height: '30px',
                  marginRight: '8px',
                  width: '36px',
                }}
              >
                {/* <FontAwesome name="filter" /> */}
              </Button>
            </OverlayTrigger>
          </span>
        )}
        {this.props.additionalToolbarContent}
        {this.props.search && <div style={{ display: 'inline-block', minWidth: '180px', width: '36%' }}>{tableProps.components.searchPanel}</div>}
      </div>
    );
  }
}

export default withOptionsColumn(Table);
