import React, { Component } from 'react';

import './LinkCard.scss';

export default class LinkCard extends Component {
  render() {
    const item = this.props.item;
    const url = item.target.match(/^http[s]?:\/\//) ? item.target : 'http://' + item.target;
    const formattedText = item.html.replace(/<br>/g, '&nbsp;');
    return (
      <div className='LinkCard'>
        {item.newWindow ? (
          <a href={url} target='_blank' className='text-element' dangerouslySetInnerHTML={{ __html: formattedText }} />
        ) : (
          <a href={url} className='text-element' dangerouslySetInnerHTML={{ __html: formattedText }} />
        )}
      </div>
    );
  }
}
