import { Tooltip, Checkbox } from 'antd';
import React from 'react';

export function CustomCheckbox(props) {
  if (props.tooltip) {
    return (
      <Tooltip placement='topLeft' title={props.tooltip}>
        <Checkbox checked={props.input.value} onChange={props.input.onChange} disabled={props.disabled}>
          <span className={props.labelClass}>{props.label}</span>
        </Checkbox>
      </Tooltip>
    );
  }
  return (
    <Checkbox checked={props.input.value} onChange={props.input.onChange} disabled={props.disabled}>
      <span className={props.labelClass}>{props.label}</span>
    </Checkbox>
  );
}
