import React from 'react';
import { Menu, Dropdown as AntdDropdown } from 'antd';

import { SelectItemData } from '../../interfaces/select-item';
import { Icon } from '../Icon/icon.component';
import { DropdownTableActionsEnum } from '../../enums/dropdown-table-actions.enum';

import './dropdown.component.scss';

interface DropdownProps {
  trigger: JSX.Element;
  items: SelectItemData<DropdownTableActionsEnum>[];
  tooltip?: string;
  onSelectItem(key: DropdownTableActionsEnum): void;
}

export function Dropdown(props: DropdownProps): JSX.Element {
  const onSelectItem = ({ key }) => props.onSelectItem(key as DropdownTableActionsEnum);

  const menu = (
    <Menu onClick={onSelectItem}>
      {props.items.map(item => (
        <Menu.Item key={item.value} disabled={item.disabled}>
          <div className='menu-item-details'>
            {item.icon && <Icon iconData={item.icon} />}
            <span>{item.name}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <AntdDropdown overlay={menu} trigger={['click']} overlayClassName='Dropdown'>
      {props.trigger}
    </AntdDropdown>
  );
}
