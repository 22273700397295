import { TOGGLE_MENU, PAGE_CHANGED } from 'ReduxActions/headerActions';

const initialState = {
  collapsed: true,
  page: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        collapsed: action.payload,
      };

    case PAGE_CHANGED:
      return {
        ...state,
        page: action.payload,
      };
  }

  return state;
}
