import { SelectItemData } from '../../../shared/interfaces/select-item';
import { LocalStorageKeyEnum } from '../../enums/local-storage.enum';
import { InnerSortDirectionEnum } from '../../enums/sort-direction.enum';
import { TaskPriorityEnum } from '../../enums/task/task-priority.enum';
import { TaskStatusEnum } from '../../enums/task/task-status.enum';
import { DataProviderRequestDTO } from '../../generated/model/dataProviderRequestDTO';
import { getFromLocalStorage } from '../../utils/local-storage-utils';
import { QueryFilters } from '../filters/filters-data';
import { BaseQuery, initializeQueries } from '../queries/base-query.model';
import { TasksSummaryData } from './tasks-summary-data';

export interface TaskQueryRequest extends BaseQuery<TasksSummaryData> {
  priority: TaskPriorityEnum;
  status: TaskStatusEnum;
  assignee: SelectItemData;
}

export namespace TaskQueryRequest {
  // export type taskFields = keyof TaskResponseDTO; // todo improve later @ahmed
  export function mapToApiValue(params: TaskQueryRequest): DataProviderRequestDTO {
    return {
      page: params.page,
      perPage: params.size,
      textSearch: params.textSearch || undefined,
      sort: (params.sort && { attribute: params.sort.attribute, direction: InnerSortDirectionEnum.convertToApiValue.getValue(params.sort.direction) }) || undefined,
      filters: QueryFilters.mapToApiValue({
        task_priority: params.priority && TaskPriorityEnum.convertToApiValue.getValue(params.priority),
        status: params.status && TaskStatusEnum.convertToApiValue.getValue(params.status),
        assignee: params.assignee && params.assignee.value,
      }),
    };
  }

  export function queries(): TaskQueryRequest {
    const params = getFromLocalStorage<TaskQueryRequest>(LocalStorageKeyEnum.TasksQueries);

    return {
      ...initializeQueries<TasksSummaryData>(params.sort ? params.sort.attribute : null, params.sort && params.sort.direction),
      priority: params.priority || undefined,
      status: params.status || undefined,
      assignee: params.assignee || undefined,
    };
  }
}
