import React from 'react';
import { Popover as AntdPopover, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { PopoverPlacementEnum } from '../../enums/popover-placement.enum';

interface FlatButtonProps {
  tooltip: string;
  title: string;
  placement: PopoverPlacementEnum;
  trigger: JSX.Element;
  content: JSX.Element;
  className?: string;
}

export function Popover(props: FlatButtonProps): JSX.Element {
  return (
    <AntdPopover placement={props.placement as TooltipPlacement} className={props.className} title={props.title} content={props.content} trigger='click'>
      <Tooltip title={props.tooltip}>{props.trigger}</Tooltip>
    </AntdPopover>
  );
}

Popover.defaultProps = {
  tooltip: null,
  title: null,
  placement: PopoverPlacementEnum.Bottom,
  onClick: () => {},
};
