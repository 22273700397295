import React from 'react';
import { Steps as AntdSteps } from 'antd';

import { StepsData } from '../../interfaces/steps-data';
import { Icon } from '../Icon/icon.component';

// interface StepsProps<T> {
//   phases: StepsData<T>[];
//   currentStep: number;
// }

export function Steps(props) {
  return (
    <AntdSteps current={props.currentStep}>
      {props.phases.map((phase, index) => (
        <AntdSteps.Step key={index + 1} title={phase.title} icon={phase.icon && <Icon iconData={phase.icon} />} subTitle={phase.subTitle} description={phase.description} />
      ))}
    </AntdSteps>
  );
}
