import classNames from 'classnames';
import React, { Component } from 'react';
import Error from '../TextField/Error';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import './AutoSearch.scss';

const exist = {
  0: { class: 'deal-null', label: '', color: '', show: false },
  1: { class: 'deal-exist', label: 'Deal available', color: '#64da6e', show: true },
  2: { class: 'deal-not-exist', label: 'Deal not available', color: '#f1988c', show: true },
};
class AutoSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      dataSource: [],
    };
    this.requestTimer = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options && this.props.options.length > 0) {
      const _options = this.props.options.map((item, index) => {
        return {
          id: item.name + index,
          ...item,
        };
      });
      this.setState({ dataSource: _options });
    }
  }

  render() {
    const { props, state } = this;
    const availableDeal = props.available === undefined ? exist[0] : props.available ? exist[1] : exist[2];
    return (
      <div className='AutoSearch'>
        <div className='display-flex-between'>
          {props.label && (
            <div className={classNames('default-color', props.labelClass ? props.labelClass : 'input-label')}>
              {props.label}
              {props.required && (
                <span className='' style={{ color: '#ff8181' }}>
                  &nbsp;&#9679;
                </span>
              )}
            </div>
          )}
          <Error touched={props.meta.touched} error={props.meta.error} warning={props.meta.warning} />
        </div>
        <ReactSearchAutocomplete
          items={this.state.dataSource}
          onSelect={selectedItem => {
            props.input.onChange(selectedItem[props.fieldRequired]);
            props.onSelect(selectedItem);
          }}
          styling={{ zIndex: 2 }}
          showIcon={false}
          inputSearchString={props.input.value}
          onSearch={value => {
            props.input.onChange(value);
            props.onUpdate(value);
          }}
          formatResult={(item, index) => (
            <div className={`item display-flex-start `} key={item[props.fieldRequired] + index}>
              <img src={item.image} height='25' width='25' className='m-2' />
              {item[props.fieldRequired]}
            </div>
          )}
        />
        {availableDeal.show && (
          <div className='available-message' style={{ color: availableDeal.color }}>
            {availableDeal.label}
          </div>
        )}
      </div>
    );
  }
}

export default AutoSearch;
