import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Tooltip } from 'antd';
import Button from 'react-bootstrap/lib/Button';
import './SubmitButton.scss';

class SubmitButton extends Component {
  static propTypes = {
    label: PropTypes.any,
    icon: PropTypes.string,
    material: PropTypes.string,
    disabled: PropTypes.bool,
    center: PropTypes.bool,
    isLoading: PropTypes.bool,
  };
  static defaultProps = {
    label: 'Submit',
    icon: undefined,
    material: undefined,
    disabled: false,
    isLoading: false,
    placement: 'top',
  };
  render() {
    const { props } = this;
    return (
      <div title={props.title} className='SubmitButton' style={props.center ? { display: 'flex', justifyContent: 'center' } : undefined}>
        <Tooltip placement={props.placement} title={props.tooltip}>
          <Button
            className={classNames('btn custom-btn', props.className)}
            disabled={props.disabled}
            onClick={props.onClick}
            style={props.style}
            form={props.formId}
            type={props.type}
          >
            {props.isLoading && <div className='pentugram-loader'>Loading...</div>}
            {!props.isLoading && props.icon && <span className={classNames(this.props.label && 'mr-2', props.icon)} style={props.labelStyle} />}
            {!props.isLoading && props.material && <span className={props.label ? 'mr-2 material-icons' : 'material-icons'}>{props.material}</span>}
            {!props.isLoading && <span style={props.labelStyle}>{props.label}</span>}
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default SubmitButton;
