import { PipeDriveMigrationEnum } from '../../enums/pipedrive/pipedrive-status.enum';
import { CompanyDTO } from '../../generated/model/companyDTO';

export interface CompanyData {
  id: number;
  angelInvestor: boolean;
  isPipeDrive: PipeDriveMigrationEnum;
  importingData: boolean;
}
export namespace CompanyData {
  export function mapFromApi(company: CompanyDTO): CompanyData {
    return {
      id: company.company_id,
      isPipeDrive: company.is_pipedrive_migrated && PipeDriveMigrationEnum.convertFromApiValue.getValue(company.is_pipedrive_migrated),
      importingData: company.is_importing_data,
      angelInvestor: company.angel_investor_enabled,
    };
  }
}
