import React, { Component } from 'react';

import './HandleUploadFile.scss';

class HandleUploadFile extends Component {
  constructor(props) {
    super(props);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleUpload(it) {
    this.props.isLoading(true);
    this.props.url('');
    let url = process.env.CLOUDINARY_URL_UPLOAD;
    let xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState == 4 && xhr.status == 200) {
        let response = JSON.parse(xhr.responseText);
        let url = response.secure_url;

        this.props.isLoading(false);
        this.props.url(url);
      }
    }.bind(this);

    xhr.onerror = function (e) {
      this.props.isLoading(false);
      this.props.url('');
    }.bind(this);

    let file = typeof it == 'string' ? Object.assign((0, _dataUriToBlob2.default)(it), { name: 'photo.jpg' }) : it.target.files[0];

    if (file == null) {
      this.props.isLoading(false);
      this.props.url('');
      return;
    }

    let data = new FormData();
    data.append('file', file);

    data.append('upload_preset', process.env.CLOUDINARY_UPLOAD_PRESET);
    data.append('tags', 'browser_upload');
    xhr.send(data);
  }

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        onChange: this.handleUpload,
      });
    });
    return <div>{children}</div>;
  }
}

export default HandleUploadFile;
