import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import { FieldSelectCreation } from 'SharedComponent/SelectOption/FieldSelect';
import { CheckBoxFieldEditor } from 'SharedComponent/CheckBox/CheckBox';
import TextEditor from 'SharedComponent/TextEditor';
import TextField from 'SharedComponent/TextField';

import * as validators from 'Utils/validators';

import './ShareModal.scss';

class ShareModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      send_pdf: false,
    };
  }

  onSubmit(values) {
    const shared = {
      ...values,
      email: values.email ? values.email.map(e => e.value) : [],
      send_pdf: this.state.send_pdf,
    };
    this.props.shareReport(shared);
    this.props.onClose();
    this.props.reset();
  }

  render() {
    const { initialValues, editor_prop } = this.props;
    if (!this.props.show) return null;
    return (
      <div className='ShareModal'>
        <DialogBox isVisible onClickOut={this.props.onClose} onClose={this.props.onClose} withoutClose={true} className='small-dialog'>
          <div className='header-dialog'>SHARE REPORT</div>
          <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
            <div className='modal-body'>
              <div>
                <div className='mt-2'>
                  <Field
                    name={`email`}
                    component={FieldSelectCreation}
                    placeholder='Shared report with'
                    validate={[validators.required]}
                    options={[]}
                    withImage={false}
                    multi={true}
                  />
                </div>
                <div className='mt-2 display-flex-center'>
                  <Field name={`subject`} component={TextField} type='text' placeholder='Type email subject' validate={[validators.required]} required />
                </div>
                <div className='mt-2'>
                  <Field name={`html`} component={TextEditor} toolbarCustomButtons={[]} />
                </div>
                <div className='mt-2'>
                  <div className='display-flex-start save-as-pdf'>
                    <Field name='send_pdf' label='Send report as pdf' component={CheckBoxFieldEditor} onClick={e => this.setState({ send_pdf: e.nativeEvent.target.checked })} />
                  </div>
                </div>
              </div>
            </div>
            <hr className='hr-details' />
            <div className='display-flex-start'>
              <div className='m-3 display-flex-start'>
                <SubmitButton
                  label='Cancel'
                  icon='icon-close'
                  style={{
                    width: '150px',
                    color: '#53627c',
                    backgroundColor: '#fff',
                    border: '1px solid #e1e4e8',
                  }}
                  onClick={() => this.props.onClose()}
                />
                <div className='ml-2'>
                  <Button type='submit' className='submit-btn-p display-flex-center'>
                    <i className='icon-share mr-2' />
                    Share
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </DialogBox>
      </div>
    );
  }
}

ShareModal = reduxForm({
  form: 'share-report-period-modal',
  destroyOnUnmount: false,
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
})(ShareModal);

export default ShareModal;
