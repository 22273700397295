import React, { Component } from 'react';
import ID from 'Utils/UUID';

import './AttachmentFiles.scss';

const humanFileSize = (bytes, si) => {
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};

export default class AttachmentFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };

    this.handleUpload = this.handleUpload.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  handleUpload(event) {
    const file = Object.assign(event.target.files[0], { key: ID.uuid() });
    if (file) {
      const newFiles = this.props.input.value || [];
      this.props.input.onChange([...newFiles, file]);
      this.setState({ files: [...newFiles, file] });
    }
  }

  removeFile(index) {
    const files = this.props.input.value || [];
    files.splice(index, 1);
    this.props.input.onChange(files);
    this.setState({ files });
  }

  render() {
    return (
      <div className='AttachmentFiles'>
        <div className=''>
          <div className='form'>
            <div className='form__group'>
              <label htmlFor='attachment' className='form__file'>
                <span className='form__file-filename' id='filename' data-placeholder='Attach file'>
                  Attach file
                </span>
                <span className='form__file-browse'>Browse</span>
                <input type='file' name='files[]' className='form__file-input' id='attachment' onChange={this.handleUpload} onClick={this.props.onClick} />
              </label>
              {this.props.input.value && (
                <ul className={this.props.input.value.length ? 'form__files form__files--show mb-2' : 'form__files form__files--hide mb-2'} id='attachment-files'>
                  {(this.props.input.value || this.state.files).map((file, idx) => {
                    return (
                      <li key={idx} className='form__files-item form__files-item--done'>
                        <span>
                          <span className='form__files-item-link href-text'>
                            <a href={file.url || '#'} target='_blank'>
                              {file.name || file.title}
                            </a>
                          </span>
                          <span className='form__files-item-link href-text'>{humanFileSize(file.size)}</span>
                          <span className='form__files-item-remove' onClick={() => this.removeFile(idx)}>
                            remove
                          </span>
                          <span className='form__files-item-progress' />
                        </span>
                      </li>
                    );
                  })}
                </ul>
              )}
              {/* form__files-item--loading ** style={{ overflow: "hidden", width: "50%" }} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class AttachFilesButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleUpload(event) {
    const file = Object.assign(event.target.files[0], { key: ID.uuid() });
    if (file) {
      const newFiles = this.props.input.value || [];
      this.props.input.onChange([...newFiles, file]);
      this.setState({ files: [...newFiles, file] });
    }
  }

  render() {
    return (
      <div className='AttachmentFiles'>
        <div className=''>
          <div className='form'>
            <div className='form__group'>
              <label htmlFor='attachment' className='form__file-button'>
                <span className='form__file-text' id='filename' data-placeholder='Attach file'>
                  <i className='material-icons' style={{ fontSize: '16px' }}>
                    attach_file
                  </i>
                  Attach file
                </span>
                <input type='file' name='files[]' className='form__file-input' id='attachment' onChange={this.handleUpload} />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const Files = ({ files, removeFile }) => {
  return files ? (
    <div className='AttachmentFiles'>
      <ul className={files.length ? 'form_files-list form__files--show mb-2' : 'form_files-list form__files--hide mb-2'} id='attachment-files'>
        {files.map((file, idx) => {
          return (
            <li key={idx} className='form__files-file form__files-item--done'>
              <span>
                <span className='form__files-item-link href-text'>
                  <a href={file.url || '#'} target='_blank'>
                    {file.name || file.title}
                  </a>
                </span>
                <span className='form__files-item-link href-text'>{humanFileSize(file.size)}</span>
                <span className='form__files-item-remove' onClick={() => removeFile(idx)}>
                  remove
                </span>
                <span className='form__files-item-progress' />
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};
