import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { useDispatch } from 'react-redux';

import { resetRole } from '../../../settings/permissions/state/permissions.actions';
import { DefaultFooter, Icon, TextField } from '../../../../shared/components';
import { RoleCreateFormNamesEnum } from '../../../../core/enums/form-names/role-create-form-names.enum';
import { IconPrefixEnum } from '../../../../shared/interfaces/icon-data';
import { required } from '../../../../shared/utils/validators';
import { ButtonTypeEnum } from '../../../../shared/enums/button-type.enum';

import './role-form.component.scss';

export function RoleForm() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetRole());
    };
  }, []);

  return (
    <div className='role-form'>
      <div className='form-container'>
        <div className='field-container'>
          <div className='field-label'>
            <Icon iconData={{ name: 'ri-shield-user-line', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_325' }} />
          </div>
          <div className='field-control'>
            <Field
              component={TextField}
              name={RoleCreateFormNamesEnum.formFields.getValue(RoleCreateFormNamesEnum.RoleName)}
              placeholder={RoleCreateFormNamesEnum.toPlaceholder.getValue(RoleCreateFormNamesEnum.RoleName)}
              validate={[required]}
            />
          </div>
        </div>
      </div>
      <DefaultFooter saveLabel='Save' htmlType={ButtonTypeEnum.Submit} />
    </div>
  );
}
