import { createAction } from '@reduxjs/toolkit';
import { CreateRoleData } from '../../../../core/models/role/role-for-create';

import { ErrorResponseData } from '../../../../core/models/http-params.type';
import { PageActionPermissionData } from '../../../../core/models/permission/page-action-permission-data';
import { PermissionForUpdate } from '../../../../core/models/permission/permission-for-update';
import { RoleSummaryData } from '../../../../core/models/role/role-summary-data';
import { UpdateRoleData } from '../../../../core/models/role/role-for-update';

export const setSubmitting = createAction<{ submitting: boolean }>('[sPermissions/Api] set submitting value');

export const loadRoleList = createAction('[sPermissions/Api] load role list');
export const loadRoleListSuccess = createAction<{ roles: RoleSummaryData[] }>('[sPermissions/Api] load role list success');
export const loadRoleListFail = createAction<ErrorResponseData>('[sPermissions/Api] load role list fail');

export const loadPermissions = createAction<string>('[sPermissions/Api] load permissions by role');
export const loadPermissionsSuccess = createAction<{ data: PageActionPermissionData; roleId: string }>('[sPermissions/Api] load permissions by role success');
export const loadPermissionsFail = createAction<ErrorResponseData>('[sPermissions/Api] load permissions by role fail');

export const updatePermissions = createAction<PermissionForUpdate>('[sPermissions/Api] update permissions by role');
export const updatePermissionsSuccess = createAction('[sPermissions/Api] update permissions by role success');
export const updatePermissionsFail = createAction<ErrorResponseData>('[sPermissions/Api] update permissions by role fail');

export const createRole = createAction<CreateRoleData>('[sPermissions/Api] create role by role');
export const createRoleSuccess = createAction<RoleSummaryData>('[sPermissions/Api] create role success');
export const createRoleFail = createAction<ErrorResponseData>('[sPermissions/Api] create role fail');

export const deleteRole = createAction<{ roleId: string | React.MouseEvent<HTMLElement> }>('[sPermissions/Api] delete Role');
export const deleteRoleSuccess = createAction<{ roleId: string }>('[sPermissions/Api] delete Role success');
export const deleteRoleFail = createAction<ErrorResponseData>('[sPermissions/Api] delete Role fail');

export const getRoleById = createAction<{ roleId: string }>('[sPermissions/Api] get RoleById');
export const getRoleByIdSuccess = createAction<RoleSummaryData>('[sPermissions/Api]] get RoleById success');
export const getRoleByIdFail = createAction<ErrorResponseData>('[sPermissions/Api] get RoleById fail');

export const updateRole = createAction<UpdateRoleData>('[sPermissions/Api] update role task');
export const updateRoleSuccess = createAction<{ previousRoleId: string; role: RoleSummaryData }>('[sPermissions/Api] update role success');
export const updateRoleFail = createAction<ErrorResponseData>('[sPermissions/Api] update role fail');

export const duplicateRole = createAction<{ roleId: string }>('[sPermissions/Api] duplicate role task');
export const duplicateRoleSuccess = createAction<RoleSummaryData>('[sPermissions/Api] duplicate role success');
export const duplicateRoleFail = createAction<ErrorResponseData>('[sPermissions/Api] duplicate role fail');

export const resetRole = createAction('[sPermissions/Api] reset role');
export const destroyData = createAction('[sPermissions/Api] destroy Data');
