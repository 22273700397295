import React, { Component } from 'react';

import './NotFound.scss';

export default class NotFound extends Component {
  render() {
    return (
      <div className='NotFound'>
        <h1>401</h1>
        <h2>Unauthorized access</h2>
        <h3>You do not have permission to access this page, contact your administrator.</h3>
      </div>
    );
  }
}
