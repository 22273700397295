import moment from 'moment';
import { Table, Empty, Icon } from 'antd';
import React from 'react';
import SubmitButton from 'SharedComponent/SubmitButton';
import { paymentStatus } from 'Enums/payment.js';
import { moneyFormatter } from 'Utils/objectUtils';

export default function BillingTable(props) {
  const columns = [
    {
      title: 'Billing Period',
      dataIndex: 'period',
      key: 'period',
      render: period => <span>{moment(period * 1000).format('ll')}</span>,
    },
    {
      title: 'Amount',
      dataIndex: 'sale_gross',
      key: 'sale_gross',
      render: amount => <span>{moneyFormatter(amount)}</span>,
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <span className='label-billing' style={{ backgroundColor: paymentStatus[status].color }}>
          {paymentStatus[status].label}
        </span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (field, row) => (
        <div className='actions-details'>
          {!row.hideActions ? (
            <SubmitButton
              label='Invoice'
              onClick={() => window.open(row.invoices_url)}
              style={{
                color: '#03a6ff',
                backgroundColor: 'rgba(3, 166, 255, 0.06)',
              }}
            />
          ) : null}
        </div>
      ),
    },
  ];

  const showingEntries = () => {
    return (
      <span className='font-size-12' style={{ color: '#4a4a4a' }}>
        Showing {props.items.length} to {props.totalDataSize} of enteries
      </span>
    );
  };

  return (
    <div>
      {!props.isLoading && !props.totalDataSize ? (
        <Empty imageStyle={{ height: 80 }} description={<span className='deal-export-pdf'>No payment history found.</span>} />
      ) : (
        <Table
          columns={columns}
          dataSource={props.items}
          rowKey='created_at'
          loading={{
            spinning: props.isLoading,
            indicator: <Icon type='loading' />,
          }}
          footer={showingEntries}
          locale={{
            emptyText: 'No payment history found',
          }}
        />
      )}
    </div>
  );
}
