import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import { loadCompany, updateCompany, discardCompanyErrors } from 'ReduxActions/settingActions';
import { utilActions } from 'ReduxActions/utilActions';
import * as validators from 'Utils/validators';
import AutoSearch from 'SharedComponent/AutoSearch';
import ImageUpload from 'SharedComponent/ImageUpload';
import SubmitButton from 'SharedComponent/SubmitButton';
import SwitchField from 'SharedComponent/CustomSwitch/SwitchField';
import { PermissibleRender } from 'SharedComponent/permissions';
import { CAN_ENABLE_ANGEL_INVESTOR, OWNER } from 'Enums/permissions';

import { Permissible } from '../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../v2.0/core/enums/permission/permission-type.enum';

import { FieldSelect, AsyncSearch } from 'SharedComponent/SelectOption/FieldSelect';
import TextField from 'SharedComponent/TextField';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './Company.scss';
import { Mixpanel } from 'App/app';

const actions = {
  loadCompany,
  ...utilActions,
  updateCompany,
  discardCompanyErrors,
};
class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.initializeClearbit = this.initializeClearbit.bind(this);
  }

  componentWillMount() {
    this.props.loadCompany();
    this.props.loadActivities();
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps.initialValues !== this.props.initialValues) {
      this.setState({ image: this.props.initialValues.logo });
    }
  }

  componentWillUnmount() {
    this.props.discardCompanyErrors();
  }

  renderAlert() {
    if (Array.isArray(this.props.errors))
      return (
        <div>
          {this.props.errors.map((error, index) => (
            <div key={index + 1} className='message danger mb-2'>
              {error.message}
            </div>
          ))}
        </div>
      );
    else return <div className='message danger mb-2'>{this.props.errors && this.props.errors.message}</div>;
  }

  onSubmit(values) {
    const company = {
      ...values,
      logo: this.state.image,
      country: values.countryName ? values.countryName.country_id : null,
      currency_id: values.currency ? values.currency.currency_id : null,
      activity_id: values.activity ? values.activity.activity_id : null,
      plan_id: values.plan ? values.plan.plan_id : null,
    };
    Mixpanel.track('Clicked on Save company button');
    this.props.updateCompany(company);
    gaEvent(appCategories.settings_company, appActions(`${appTypes.save}`, appEvents.clicked).button);
  }

  handleChangeName(suggest) {
    this.props.searchClearbit(suggest);
  }

  initializeClearbit(object) {
    // this.props.dispatch(change("add-new-deal", "website", object.website));
    this.setState({ logo: object.image });
  }

  parseActivities(activities) {
    return activities.map(activity => {
      return {
        value: activity.activity_id,
        label: activity.name,
      };
    });
  }

  parseCountries(countries) {
    return countries.map(country => {
      return {
        value: country.country_id,
        label: country.name,
      };
    });
  }

  parseCompanies(companies) {
    return companies.map(company => {
      return {
        name: company.name,
        image: company.logo,
        website: company.domain,
      };
    });
  }
  handleImageUpload = image => {
    this.setState({ image });
  };
  handleImageLoading = isLoading => {
    this.setState({ isLoading });
  };
  reset = () => {
    Mixpanel.track('Clicked on Reset company button');
    this.props.reset();
  };
  render() {
    const { error, handleSubmit, pristine, reset, submitting } = this.props;
    const activities = this.parseActivities(this.props.activities);
    const countries = this.parseCountries(this.props.countries);
    const image = this.props.initialValues && this.props.initialValues.logo;
    const imagePristine = image === this.state.image;
    return (
      <div className={classNames('Company', this.props.loading && 'company-loading')}>
        {this.props.loading && (
          <div className='custome-overlay'>
            <div className='custom-spinner'>
              <div id='custom-spinner-id' />
            </div>
          </div>
        )}
        <div className='company-container display-flex-start'>
          <div className='row'>
            <div className='col-xs-12 col-md-12 margin-top-20'>
              {this.renderAlert()}
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className='row'>
                  <div className='col-md-3 display-flex-center'>
                    <ImageUpload appCategory={appCategories.settings_company} value={this.state.image} onChange={this.handleImageUpload} loading={this.handleImageLoading} />
                  </div>
                  <div className='col-md-9 margin-div-8'>
                    <Field label='Company name' name={`name`} component={TextField} type='text' placeholder='Type company name' withIcon={false} required disabled />
                    <Field
                      label='Company email'
                      name={`cc_email`}
                      component={TextField}
                      type='text'
                      placeholder=''
                      withIcon={false}
                      disabled
                      copyToClipboard={true}
                      googleAnayticsEvent={() => gaEvent(appCategories.settings_company, appActions(`${appTypes.copyToClipboard}`, appEvents.clicked).icon)}
                    />
                    <Field
                      label='We are using Pentugram for:'
                      options={activities}
                      component={FieldSelect}
                      placeholder='Select activity'
                      name={`activity.activity_id`}
                      clearable={false}
                      disabled={true}
                    />
                  </div>
                </div>
                <Field
                  label='Website'
                  name={`website`}
                  component={AutoSearch}
                  type='text'
                  placeholder='www.example.com'
                  withIcon={false}
                  validate={[validators.url]}
                  options={this.parseCompanies(this.props.clearbitCompanies)}
                  loading={this.props.clearbitLoading}
                  onUpdate={this.props.searchClearbit}
                  onSelect={object => this.setState({ image: object.image })}
                  fieldRequired='website'
                />
                <Field label='Address' name={`address`} component={TextField} type='text' placeholder='' withIcon={false} />
                <div className='display-flex-between'>
                  <div className='flex-1'>
                    <Field label='Country' name={`countryName`} component={AsyncSearch} placeholder='Type country' url='/countries' field='name' listValue='country_id' />
                  </div>
                  <div className='ml-2 flex-1'>
                    <Field
                      label='Currency'
                      name={`currency`}
                      component={AsyncSearch}
                      placeholder='Type your currency'
                      url='/currencies'
                      field='name'
                      clearable={false}
                      listValue='currency_id'
                    />
                  </div>
                </div>

                {/* <PermissibleRender userPermissions={[]} requiredPermissions={[[CAN_ENABLE_ANGEL_INVESTOR], [OWNER]]}>
                  <div className='display-flex-between'>
                    <div className='flex-1'>
                      <Field
                        label='Enable Investor Network.'
                        labelClass='font-size-14 font-weight-500 label-color ml-2'
                        name='angel_investor_enabled'
                        component={SwitchField}
                        format={value => (value ? true : false)}
                      />
                    </div>
                    <div className='ml-2 flex-1'></div>
                  </div>
                </PermissibleRender> */}
                <div className='mt-3'>
                  <div className='row'>
                    <div className='col-md-6'></div>
                    <Permissible can={PermissionTypeEnum.UpdateCompany}>
                      <div className='col-md-3'>
                        <SubmitButton
                          label='Reset'
                          icon='icon-refresh'
                          style={{
                            width: '100%',
                            color: '#53627c',
                            backgroundColor: '#fff',
                            border: '1px solid #e1e4e8',
                          }}
                          onClick={this.reset}
                        />
                      </div>
                      <div className='col-md-3'>
                        <Button
                          type='submit'
                          disabled={pristine && imagePristine ? submitting || pristine || imagePristine : false}
                          className='submit-btn-p display-flex-center'
                          style={{ width: '100%' }}
                        >
                          <i className='icon-check mr-2' />
                          SAVE
                        </Button>
                      </div>
                    </Permissible>
                  </div>
                </div>
              </form>
            </div>
            {/* <div className="col-xs-4 col-md-8">
              <div className="company-logo-container">
                <div className="logo-container display-flex-center">
                  {this.state.uploading ? (
                    <div className="lds-roller">
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  ) : (
                      <img src={this.state.logo} height="100%" width="100%" />
                    )}
                </div>
                <div className="logo-input">
                  <FileInput
                    gaEvent={() =>
                      gaEvent(
                        appCategories.settings_company,
                        appActions(`CHANGE_COMPANY_LOGO`, appEvents.clicked).button
                      )
                    }
                    name="fileUrl"
                    label="Change company logo"
                    types="image/*,application/pdf"
                    className="companyLogo"
                    onChange={logo => this.setState({ logo })}
                    isLoading={uploading => this.setState({ uploading })}
                  />
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.settings.company.myCompany,
    activities: state.utils.activities,
    countries: state.utils.countries,
    loading: state.settings.company.loading,
    errors: state.settings.company.errors,
    clearbitCompanies: state.utils.companies,
    clearbitLoading: state.utils.loading,
  };
}

Company = reduxForm({ form: 'settings-company', enableReinitialize: true })(Company);
export default connect(mapStateToProps, actions)(Company);
