import get from 'lodash/get';
import { replace } from '../../../../../utils/arrayUtils.js';
import React, { Component } from 'react';
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn';
import Table from '../../../../shared/Table';

import './CheckboxTools.scss';

export default class CheckboxTools extends Component {
  static defaultProps = {
    portfolio_card: { path: 'data', isKey: 'deal_id', field: 'name', label: 'Company' },
    funds_table: { path: 'data', isKey: 'fund_name', field: 'fund_name', label: 'Funds' },
    rounds_table: { path: 'data', isKey: 'round_id', field: 'name', label: 'Rounds' },
    lps_table: { path: 'data', isKey: 'name', field: 'name', label: 'LPs' },
    tables_list: ['lps_table', 'rounds_table', 'funds_table'],
  };

  getselectedItems(list, key) {
    return list
      .filter(it => !it.hide)
      .map((it, idx) => {
        return it[key];
      });
  }

  onSelectAll(bool, array) {
    const list = array.map(it => {
      return { ...it, hide: !bool };
    });
    this.props.updateList(list);
  }

  onRowSelect(object, bool, list, key) {
    const newList = replace(list, { [key]: object[key] }, { ...object, hide: !bool });
    this.props.updateList(newList);
  }

  render() {
    const { props } = this;
    let properties = props[props.tables_list.includes(props.item.group) ? props.item.group : props.item.key];
    properties = {
      ...properties,
      path: get(props.item, 'data.deals', null) ? 'data.deals' : 'data',
      label: (props.item.is_lps_table && 'LPs') || (props.item.is_funds_table && 'Funds') || (props.item.is_rounds_table && 'Rounds') || 'Company',
    };
    const items = props.item.data && properties ? get(props.item, properties.path) : [];
    return (
      <div className='CheckboxTools'>
        <div className='checkbox-tools-container'>
          <Table
            items={items}
            pagination={false}
            loading={false}
            onSortChange={() => {}}
            noDataText={props.noDataText}
            checkBox={false}
            maxHeight='400'
            onSelectAll={bool => this.onSelectAll(bool, items)}
            onRowSelect={(object, bool) => this.onRowSelect(object, bool, items, properties.isKey)}
            checkBox={true}
            checkedRows={true}
            selected={this.getselectedItems(items, properties.isKey)}
          >
            <TableHeaderColumn dataField={properties.isKey} isKey hidden>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField={properties.field} dataAlign='left'>
              {properties.label}
            </TableHeaderColumn>
          </Table>
        </div>
      </div>
    );
  }
}
