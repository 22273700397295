import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Image from 'react-bootstrap/lib/Image';
import { moneyFormatter, trunc } from 'Utils/objectUtils';
import { TooltipAvatar } from 'SharedComponent/antd/CustomAvatar';
import defaultImage from '../../../../assets/img/default.png';

import './PortfolioCard.scss';

class PortfolioCard extends Component {
  pickFromDeal(deal, cfs) {
    let object = {};
    cfs.forEach(cf => {
      if (cf.type == 'date') object[cf.value] = deal[cf.value] ? moment(deal[cf.value] * 1000).format('ll') : '--';
      else if (cf.type == 'monetary') object[cf.value] = moneyFormatter(deal[cf.value], deal[cf.value + '_code'] || this.props.defaultCurrency);
      else if (cf.type == 'percentage') object[cf.value] = deal[cf.value] ? `% ${deal[cf.value]}` : '--';
      else if (cf.type == 'users') object[cf.value] = deal[cf.value] ? this.cardUsers(deal[cf.value]) : '--';
      else if (cf.type == 'paragraph' || cf.type == 'text') object[cf.value] = deal[cf.value] ? trunc(deal[cf.value], 10) : '';
      else object[cf.value] = deal[cf.value] || '--';
    });
    return object;
  }

  cardUsers(users) {
    return (
      <div>
        {users.map(i => (
          <TooltipAvatar key={i.id} size='small' tooltip={i.fullname} image={i.image || defaultImage} />
        ))}
      </div>
    );
  }

  parseDeals(deals = []) {
    return deals
      .filter(it => !it.hide)
      .map(deal => {
        const size = deal.size ? deal.size / (deal.currencyName ? deal.currencyName.value : 1) : 0;
        return {
          ...this.pickFromDeal(deal, this.props.item.fields),
          image: <Logo url={deal.image} letter={deal.name && deal.name[0]} />,
          name: deal.name,
          amount_invested: moneyFormatter(size),
          countryName: get(deal, 'countryName.name', '--'),
          country: get(deal, 'country.name', undefined),
          domain: get(deal, 'domain.name', '--'),
          assignee: get(deal, 'assignee.profile.fullname', '--'),
        };
      });
  }

  aggregationTitle(type) {
    switch (type) {
      case 1:
        return 'Total';
        break;
      case 2:
        return 'Max';
        break;
      case 3:
        return 'Min';
        break;
      case 4:
        return 'Average';
        break;
      default:
        return 'Total';
        break;
    }
  }

  aggregationcolumns(item) {
    const deals = (item.data.deals || item.data).filter(it => !it.hide);
    return item.fields
      .filter(it => it.show)
      .map(it => {
        if (['number', 'monetary'].includes(it.type)) return this.aggregationValue(item.aggregation_type, deals, it.value);
        else return null;
      });
  }

  aggregationValue(type, items, field) {
    switch (type) {
      case 1:
        return this.calculSum(items, field);
        break;
      case 2:
        return this.calculMax(items, field);
        break;
      case 3:
        return this.calculMin(items, field);
        break;
      case 4:
        return this.calculAverage(items, field);
        break;
      default:
        return this.calculSum(items, field);
        break;
    }
  }

  calculSum(deals, field) {
    const sum = deals.reduce((prev, cur) => this.getSum(prev, cur, field), 0);
    return sum;
  }

  calculAverage(deals, field) {
    const avg = deals.reduce((prev, cur) => this.getSum(prev, cur, field), 0) / deals.length;
    return avg;
  }

  calculMin(deals, field) {
    const min = Math.min.apply(
      Math,
      deals.map(deal => {
        return this.getDealField(deal, field);
      }),
    );
    return min;
  }

  calculMax(deals, field) {
    const max = Math.max.apply(
      Math,
      deals.map(deal => {
        return this.getDealField(deal, field);
      }),
    );
    return max;
  }

  getSum(prev, cur, field) {
    return Number(prev) + Number(this.getDealField(cur, field));
  }

  getDealField(deal, field) {
    return deal[field] || 0;
  }

  displayPortfolioFields(field) {
    if (Number(field)) return moneyFormatter(field);
    return field;
  }

  render() {
    const { item } = this.props;
    const items = item.data ? this.parseDeals(item.data.deals || item.data) : [];
    return (
      <div className='PortfolioCard'>
        {item.title && <div className='table-title'>{item.title}</div>}
        <div className='table-fix-head'>
          <table id={`${this.props.mode}-${item.id}`} style={{ textAlign: item.data_align }}>
            <thead>
              <tr style={{ borderBottom: '2px solid #f2f2f2' }}>
                {item.fields.map((it, idx) => {
                  if (it.show)
                    return (
                      <th style={{ backgroundColor: item.properties.color.backgroundColor }} key={idx + 1}>
                        {it.label}
                      </th>
                    );
                })}
              </tr>
            </thead>

            {items.length ? (
              <tbody>
                {items.map((deal, index) => (
                  <tr key={index + 1}>
                    {item.fields.map((item, ind) => {
                      if (item.show) return <td key={ind + index + 1}>{deal[item.value]}</td>;
                    })}
                  </tr>
                ))}
                {item.aggregation && (
                  <tr className='aggregation-field'>
                    <td colSpan='100%'>{this.aggregationTitle(item.aggregation_type)}</td>
                  </tr>
                )}
                {item.aggregation && (
                  <tr className='aggregation-field'>
                    {this.aggregationcolumns(item).map((e, index) => (
                      <td>{e}</td>
                    ))}
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan='100%'>
                    No {item.items_name || 'companies'} found
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    defaultCurrency: get(state, 'auth.user.company.currency.code'),
  };
}

export default connect(mapStateToProps, {})(PortfolioCard);

const Logo = props => (
  <div>
    {props.url ? (
      <Image
        src={props.url}
        alt='Logo'
        style={{
          height: '35px',
          margin: '0 auto',
          width: '35px',
          radius: '10px',
        }}
        thumbnail
      />
    ) : (
      <div className='deal-image-null deal-image-null-border'> {props.letter} </div>
    )}
  </div>
);
