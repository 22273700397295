import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn';
import Image from 'react-bootstrap/lib/Image';
import formsSvg from 'Assets/img/empty-forms.svg';
import { formStatusObject } from 'Enums/onlineForm';
import Table from 'SharedComponent/Table';
import DropdownList from 'SharedComponent/DropdownList';

import { canForClass, Permissible } from '../../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import { Tooltip } from 'antd';

import './FormsTable.scss';
class FormsTable extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    onEdit: PropTypes.func,
    onSortChange: PropTypes.func,
    noDataText: PropTypes.string,
    pagination: PropTypes.bool,
    checkBox: PropTypes.bool,
  };

  static defaultProps = {
    noDataText: 'No forms found',
    pagination: false,
    checkBox: true,
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.actionsFormatter = this.actionsFormatter.bind(this);
    this.logoFormatter = this.logoFormatter.bind(this);
    this.linkFormatter = this.linkFormatter.bind(this);
  }

  onClickMenuAction(row) {
    gaEvent(appCategories.settings_forms, appActions('MENU_ACTIONS', appEvents.clicked).icon);
    this.props.showActions(row);
  }

  render() {
    const { props, state } = this;
    return (
      <div className='FormsTable'>
        <Table
          items={props.items}
          pagination={props.pagination}
          loading={props.isLoading}
          onSortChange={props.onSortChange}
          noDataText={this.emptyData()}
          checkBox={props.checkBox}
          onSelectAll={props.onSelectAll}
          onRowSelect={props.onRowSelect}
        >
          <TableHeaderColumn dataField='form_id' isKey hidden>
            ID
          </TableHeaderColumn>

          <TableHeaderColumn dataField='title' dataAlign='left' width='60'>
            Form title
          </TableHeaderColumn>

          <TableHeaderColumn dataField='deal_number' dataAlign='left' width='40'>
            Number of deals
          </TableHeaderColumn>

          <TableHeaderColumn dataFormat={this.logoFormatter} dataAlign='left' width='80'>
            Created by
          </TableHeaderColumn>

          <TableHeaderColumn dataField='createdAt' dataAlign='left' width='80'>
            Created at
          </TableHeaderColumn>

          <TableHeaderColumn dataAlign='left' dataFormat={this.statusFormatter} width='40'>
            Status
          </TableHeaderColumn>

          <TableHeaderColumn dataFormat={this.actionsFormatter} dataAlign='right' width='30' columnClassName='custome-table-outline-details' isActionColumn>
            Actions
          </TableHeaderColumn>
        </Table>
      </div>
    );
  }

  actionsFormatter(cell, row) {
    const { props } = this;
    const DuplicateForm = props.canForClass(PermissionTypeEnum.DuplicateForm);
    const DeleteForm = props.canForClass(PermissionTypeEnum.DeleteForm);
    const ExportFormDealToExcel = props.canForClass(PermissionTypeEnum.ExportFormDealToExcel);
    const items = [
      {
        label: 'Integrate Form',
        icon: 'icon-link',
        onClick: () => props.onIntegrate(row),
      },
      {
        label: 'Export deals',
        icon: 'icon-cloud-download',
        onClick: () => props.onUpload(row),
        disabled: !ExportFormDealToExcel,
      },
      {
        label: 'Duplicate form',
        icon: 'icon-docs',
        onClick: () => props.onDuplicate(row),
        disabled: !DuplicateForm,
      },

      {
        label: 'Delete Form',
        icon: 'icon-trash',
        onClick: () => props.onDelete(row),
        disabled: !DeleteForm,
      },
    ];
    return (
      <div className='actions-details'>
        <Tooltip title='Share From'>
          <i className='icon-share' style={{ width: '36px' }} onClick={() => props.onShare(row)} />
        </Tooltip>
        <Permissible can={PermissionTypeEnum.CreateUpdateForm}>
          <Tooltip title='Edit Form'>
            <i className='icon-pencil' style={{ width: '36px' }} onClick={() => props.onEdit(row)} />
          </Tooltip>
        </Permissible>
        <div className='ml-1'>
          <DropdownList icon='more_vert' items={items} tooltip='More actions' />
        </div>
      </div>
    );
  }

  statusFormatter(cell, row) {
    const color = formStatusObject[row.status] && formStatusObject[row.status].color;
    const label = formStatusObject[row.status] ? formStatusObject[row.status].label : '';
    return (
      <div className='status-text' style={{ color }}>
        {label}
      </div>
    );
  }

  linkFormatter(cell, row) {
    return (
      <div>
        <a href={row.link} target='_blank'>
          {row.link}
        </a>
      </div>
    );
  }

  logoFormatter(cell, row) {
    return (
      <div>
        <Image
          src={row.logo}
          alt='Logo'
          style={{
            height: '35px',
            margin: '0 auto',
            width: '35px',
            borderRadius: '50%',
          }}
          circle
        />
        <span style={{ marginLeft: '24px' }}>{row.owner}</span>
      </div>
    );
  }

  checkBoxFormatter(cell, row) {
    return <Label bsStyle='success'>check</Label>;
  }

  emptyData() {
    return (
      <div>
        {this.props.isLoading ? (
          <div className='custom-spinner'>
            <div id='custom-spinner-id' />
          </div>
        ) : (
          <div className='m-4'>
            <img src={formsSvg} />
            <div className='empty-tpl'>Looks like you dont have any Forms yet, so lets start by adding some</div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, { canForClass })(FormsTable);
