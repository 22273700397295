import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';

import './ViewButtons.scss';
import { Mixpanel } from 'App/app';

class ViewButtons extends Component {
  static propTypes = {
    bsSize: PropTypes.string,
    options: PropTypes.array,
  };
  static defaultProps = {
    bsSize: 'small',
    options: [],
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { props, state } = this;
    return (
      <div className='ViewButtons'>
        <ButtonGroup className={props.className}>
          {props.options.map(item => {
            const optClassName = classNames({
              selected: item.value == props.selected,
            });
            return (
              <Button
                key={`${item.value};${item.label}`}
                bsSize='small'
                title={item.title}
                className={optClassName}
                onClick={() => {
                  props.update(item.value);
                  Mixpanel.track(`Clicked on ${item.label}`);
                }}
                style={props.styles}
              >
                <div className='display-flex-center'>
                  {props.icon && <i className={item.icon} />}
                  {props.icon && item.materialIcon && <i className='material-icons'> {item.materialIcon} </i>}
                  {props.label && <span>{item.label}</span>}
                </div>
              </Button>
            );
          })}
        </ButtonGroup>
      </div>
    );
  }
}

export default ViewButtons;
