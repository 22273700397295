import { ApiUrlsEnum } from '../helpers/api-url';
import { get, post, put, _delete } from './helpers/base-http';
import { apiUrlMatcher } from '../helpers/api-url-matcher';

import { RbacRoleCreateRequest, RbacRoleDTO, RbacRolePermissionResponse, RbacRoleUpdateRequest, RbacSyncRolePermissionsRequest } from '../generated/model/models';
import { RoleSummaryData } from '../models/role/role-summary-data';
import { PageActionPermissionData } from '../models/permission/page-action-permission-data';
import { HttpParamsType } from '../models/queries/http-params.type';

export async function getRoles(): Promise<RoleSummaryData[]> {
  const roles = await get<RbacRoleDTO[]>(apiUrlMatcher(ApiUrlsEnum.Roles));

  return roles.map(role => RoleSummaryData.mapFromApi(role));
}

export async function getPermissionsByRole(roleId: string): Promise<PageActionPermissionData> {
  const data = await get<RbacRolePermissionResponse>(apiUrlMatcher(ApiUrlsEnum.PermissionsByRole, { roleId }));

  return PageActionPermissionData.mapFromApi(data);
}

export async function updatePermissionsByRole(roleId: string, params: HttpParamsType<RbacSyncRolePermissionsRequest>): Promise<PageActionPermissionData> {
  const data = await post<RbacRolePermissionResponse>(apiUrlMatcher(ApiUrlsEnum.SetPermissions, { roleId }), params);

  return PageActionPermissionData.mapFromApi(data);
}

export async function createRole(params: HttpParamsType<RbacRoleCreateRequest>): Promise<RoleSummaryData> {
  const roles = await post<RbacRoleDTO>(apiUrlMatcher(ApiUrlsEnum.CreateRole), params);

  return RoleSummaryData.mapFromApi(roles);
}

export async function deleteRole(roleId: string): Promise<boolean> {
  const role = await _delete<boolean>(apiUrlMatcher(ApiUrlsEnum.Role, { roleId }));

  return role;
}

export async function loadRoleById(roleId: string): Promise<RoleSummaryData> {
  const role = await get<RbacRoleDTO>(apiUrlMatcher(ApiUrlsEnum.Role, { roleId }));

  return RoleSummaryData.mapFromApi(role);
}

export async function updateRole(roleId: string, params: HttpParamsType<RbacRoleUpdateRequest>): Promise<RoleSummaryData> {
  const role = await put<RbacRoleDTO>(apiUrlMatcher(ApiUrlsEnum.Role, { roleId }), params);

  return RoleSummaryData.mapFromApi(role);
}

export async function duplicateRole(roleId: string): Promise<RoleSummaryData> {
  const role = await post<RbacRoleDTO>(apiUrlMatcher(ApiUrlsEnum.DuplicateRole, { roleId }), {});

  return RoleSummaryData.mapFromApi(role);
}
