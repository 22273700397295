import React from 'react';

import { AvatarShapeEnum } from '../../../../shared/interfaces/avatar-data';
import { Avatar, Button } from '../../../../shared/components';

import './integration-card.component.scss';

interface IntegrationCardProps {
  imagePath: string;
  disabled?: boolean;
  loading?: boolean;
  name: string;
  description: string;
  isThirdParty?: boolean;
  imageBackgroundColor: string;
  onClick(): void;
}

export const IntegrationCard = (props: IntegrationCardProps) => {
  return (
    <div className='IntegrationCard'>
      <div className='header-container'>
        <Avatar
          src={props.imagePath}
          style={{ padding: '0.5rem', backgroundColor: props.imageBackgroundColor, verticalAlign: 'middle' }}
          shape={AvatarShapeEnum.Square}
          size={72}
        />
        <div>
          <div className='integration-card-name'>{props.name}</div>
          <Button
            btnClass='integration-card-button'
            label={props.disabled && props.isThirdParty ? 'Account Connected' : 'Add to Pentugram'}
            loading={props.loading}
            disabled={props.disabled}
            onClick={props.onClick}
          />
        </div>
      </div>
      <div className='integration-card-description'>{props.description}</div>
    </div>
  );
};
