import { REMOVE_ERROR, RESET_ERRORS } from 'ReduxActions/errorsActions';

export default function (state = [], action) {
  switch (action.type) {
    case RESET_ERRORS: {
      return [];
    }
    case REMOVE_ERROR: {
      return state.filter(error => error.id !== action.payload.id);
    }
    default: {
      if (action.error) {
        const data = action.error.data ? action.error.data : action.error.toString();
        let errorBody = data.internalCode ? data.internalCode : data.message ? data.message : data;
        if (errorBody.length > 80) {
          errorBody = errorBody.substring(0, 80);
        }
        const message = action.customMessage ? action.customMessage : action.message ? `${action.message}: ${errorBody}` : errorBody;

        return [
          ...state,
          {
            id: Math.floor(Math.random() * 1000000),
            level: 'error',
            message,
          },
        ];
      }
    }
  }
  return state;
}
