import { createAction } from '@reduxjs/toolkit';

import { TaskDetailsData } from '../../../core/models/__task/task-details-data';
import { ErrorResponseData } from '../../../core/models/http-params.type';
import { UpdateTaskStatus } from '../../../core/models/__task/task-for-status';
import { MeetingInfoData } from '../../../core/models/meeting/meeting-info-data';
import { TasksSummaryData } from '../../../core/models/__task/tasks-summary-data';
import { TaskForVote } from '../../../core/models/__task/task-for-vote';
import { ZoomTaskForCreate } from '../../../core/models/__task/zoom-task-for-create';
import { BaseCalenderQuery } from '../../../core/models/queries/base-calender-query';
import { TasksCalenderData } from '../../../core/models/calendar/tasks-calender-data';

import { CalenderTasksList, ITasksList } from './types';

export const loadAllTasksList = createAction('[tasks/Api] load tasks list');
export const loadAllTasksNextPage = createAction<{ currentPage: number }>('[tasks/Api] load tasks next page');
export const loadAllTasksListSuccess = createAction<ITasksList>('[tasks/Api] load tasks list success');
export const loadAllTasksListFail = createAction<ErrorResponseData>('[tasks/Api] load tasks list fail');

export const addTask = createAction<{ task: TaskDetailsData }>('[Tasks/Api] add task');
export const addTaskSuccess = createAction('[Tasks/Api] add task success');
export const addTaskFail = createAction<ErrorResponseData>('[Tasks/Api] add task fail');

export const deleteTask = createAction<{ uuid: string }>('[Tasks/Api] delete task');
export const deleteTaskSuccess = createAction<{ uuid: string }>('[Tasks/Api] delete task success');
export const deleteTaskFail = createAction<ErrorResponseData>('[Tasks/Api] delete task fail');

export const updateTask = createAction<{ task: TaskDetailsData }>('[Tasks/Api] update task');
export const updateTaskSuccess = createAction<TasksSummaryData>('[Tasks/Api] update task success');
export const updateTaskFail = createAction<ErrorResponseData>('[Tasks/Api] update task fail');

export const updateStatus = createAction<UpdateTaskStatus>('[Tasks/Api] update task status');
export const updateStatusFail = createAction<ErrorResponseData>('[Tasks/Api] update task status fail');

export const getZoomUrl = createAction<MeetingInfoData>('[Tasks/Api] get zoom url');
export const getZoomUrlSuccess = createAction<{ url: string }>('[Tasks/Api] get zoom url success');
export const getZoomUrlFail = createAction<ErrorResponseData>('[Tasks/Api] get zoom url fail');

export const startZoom = createAction<ZoomTaskForCreate>('[Dashboard/tasks] start zoom call');
export const startZoomSuccess = createAction<{ url: string }>('[Dashboard/tasks] start zoom call success');
export const startZoomFail = createAction<ErrorResponseData>('[Dashboard/tasks] start zoom call fail');

export const voteForTask = createAction<TaskForVote>('[Tasks/Api] vote for task');
export const voteForTaskFail = createAction<ErrorResponseData>('[Tasks/Api] vote for task fail');

export const getTask = createAction<{ uuid: string }>('[Tasks/Api] get task');
export const getTaskSuccess = createAction<{ task: TaskDetailsData }>('[Tasks/Api] get task success');
export const getTaskFail = createAction<ErrorResponseData>('[Tasks/Api] get task fail');

/** calender action*/
export const loadCalenderTasksList = createAction('[tasks/Api] load Calender tasks list');
export const loadCalenderTasksCurrentDate = createAction<{ currentDate: BaseCalenderQuery }>('[tasks/Api] load Calender tasks currentDate page');
export const loadCalenderTasksListSuccess = createAction<CalenderTasksList>('[tasks/Api] load Calender tasks list success');
export const loadCalenderTasksListFail = createAction<ErrorResponseData>('[tasks/Api] load Calender tasks list fail');

export const addTaskCalender = createAction<{ task: TaskDetailsData }>('[Tasks/Api] add Calender task');
export const addTaskCalenderSuccess = createAction<TasksCalenderData>('[Tasks/Api] add task Calender success');
export const addTaskCalenderFail = createAction<ErrorResponseData>('[Tasks/Api] add task Calender fail');

export const updateCalenderTask = createAction<{ task: TaskDetailsData }>('[Tasks/Api] update calender task');
export const updateCalenderTaskSuccess = createAction<TasksCalenderData>('[Tasks/Api] update task calender success');
export const updateCalenderTaskFail = createAction<ErrorResponseData>('[Tasks/Api] update calender task fail');

export const getTaskCalender = createAction<{ Date: number }>('[Tasks/Api] get Calender task');
export const getTaskCalenderSuccess = createAction<{ task: TaskDetailsData }>('[Tasks/Api] get task Calender success');
export const getTaskCalenderFail = createAction<ErrorResponseData>('[Tasks/Api] get task Calender fail');

export const resetTasks = createAction('[Tasks/Api] reset tasks');
export const destroyDataTasks = createAction('[Tasks/Api] destroy Data tasks');
