import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { countries } from 'Enums/countries';
import { frequencies } from 'Enums/periods';
import { numberFormatter, normalizeNumber } from 'Utils/objectUtils';

import DateField from 'SharedComponent/DateField';
import MonetaryField from 'SharedComponent/MonetaryField';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';
import AntSelectField from 'SharedComponent/SelectOption/AntSelectField';
import { TextArea } from 'SharedComponent/TextArea/TextArea';
import TextField from 'SharedComponent/TextField';

import * as validators from 'Utils/validators';
import defaultImage from 'Assets/img/default.png';

export function customFieldProperties(customField) {
  switch (customField.field_type) {
    case 'text':
      return {
        component: TextField,
        validation: !!customField.required ? [validators.required] : [],
        col: '6',
      };

    case 'number':
      return {
        component: TextField,
        validation: !!customField.required ? [validators.required, validators.number] : [validators.number],
        format: val => numberFormatter(val),
        normalize: val => normalizeNumber(val),
        col: '6',
      };

    case 'percentage':
      return {
        component: TextField,
        validation: !!customField.required ? [validators.required, validators.percentage] : [validators.percentage],
        withIcon: true,
        prefix: '%',
        divider: { height: '40px' },
        col: '6',
      };

    case 'date':
      return {
        component: DateField,
        validation: !!customField.required ? [validators.required] : [],
        prefix: 'icon-calendar',
        col: '6',
      };

    case 'year':
      return {
        component: FieldSelect,
        options: frequencies(new Date().getFullYear() - 40, new Date().getFullYear() + 1)[4],
        validation: !!customField.required ? [validators.required] : [],
        top: false,
        col: '6',
      };

    case 'list':
      return {
        component: FieldSelect,
        options: Array.isArray(customField.field_list)
          ? customField.withLabel
            ? customField.field_list
            : customField.field_list.map(it => {
                return { label: it, value: it };
              })
          : [],
        validation: !!customField.required ? [validators.required] : [],
        col: customField.col || '6',
        style: customField.style || undefined,
      };

    case 'paragraph':
      return {
        component: TextArea,
        style: { width: '100%', height: '120px' },
        validation: !!customField.required ? [validators.required] : [],
        col: '12',
      };

    case 'text':
      return {
        component: TextField,
        validation: !!customField.required ? [validators.required] : [],
        col: '6',
      };

    case 'countries':
      return {
        component: FieldSelect,
        withImage: true,
        options: countries,
        top: true,
        validation: !!customField.required ? [validators.required] : [],
        col: '6',
      };

    case 'monetary':
      return {
        component: MonetaryField,
        validation: !!customField.required ? [validators.required] : [], // validation amount & currency
        format: val => numberFormatter(val),
        normalize: val => normalizeNumber(val),
        col: '6',
      };

    case 'users':
      return {
        component: AntSelectField,
        withImage: true,
        top: true,
        validation: !!customField.required ? [validators.required] : [],
        col: '6',
      };

    default:
      return {
        component: null,
        col: '6',
      };
  }
}

function TypeUsers(props) {
  const parseUsers = users => {
    return users.map(user => {
      return {
        value: user.id,
        label: user.profile ? user.profile.fullname : 'N/A',
        image: user.profile && user.profile.image ? user.profile.image : defaultImage,
        is_user: true,
      };
    });
  };

  const inputs = value => (Array.isArray(value) ? (value.length ? (typeof value[0] == 'object' ? value.map(i => i.id) : value) : value) : []);

  return (
    <Field
      label={props.label}
      placeholder={props.placeholder}
      required={props.required}
      validate={props.validate}
      name={props.name}
      labelClass={props.labelClass}
      component={AntSelectField}
      options={parseUsers(props.users)}
      format={e => inputs(e)}
    />
  );
}

function mapStateToProps(state) {
  return {
    users: state.settings.users.all,
  };
}

export const CustomFieldUsers = connect(mapStateToProps, {})(TypeUsers);
