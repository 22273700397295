import React from 'react';
import Error from '../TextField/Error';
import TextAreaAutosize from 'react-textarea-autosize';

import './TextArea.scss';

export const TextArea = props => {
  return (
    <div className='TextArea'>
      <div className='display-flex-between'>
        {props.label && (
          <div className={props.labelClass ? props.labelClass : 'label-selector'}>
            {props.label}
            {props.required && (
              <span className='' style={{ color: '#ff8181' }}>
                &nbsp;&#9679;
              </span>
            )}
          </div>
        )}
        {props.meta && <Error touched={props.meta.touched} error={props.meta.error} warning={props.meta.warning} />}
      </div>
      <div>
        <TextAreaAutosize
          type='text'
          className='text-area-details'
          cachemeasurements={true}
          placeholder={props.placeholder || 'Comment'}
          style={props.style && props.style}
          {...props.input}
          {...props.custom}
        />
      </div>
    </div>
  );
};
