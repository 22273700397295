import { SelectItemData } from '../../../shared/interfaces/select-item';
import { CalenderTasksModeEnum } from '../../enums/calendar/calender-task-mode.enum';
import { LocalStorageKeyEnum } from '../../enums/local-storage.enum';
import { TaskPriorityEnum } from '../../enums/task/task-priority.enum';
import { TaskStatusEnum } from '../../enums/task/task-status.enum';
import { TaskCalendarRequestDTO } from '../../generated/model/taskCalendarRequestDTO';
import { getFromLocalStorage } from '../../utils/local-storage-utils';
import { QueryFilters } from '../filters/filters-data';
import { initializeCalenderQueries } from '../queries/base-calender-query';

export interface TaskQueryCalenderRequest {
  priority: TaskPriorityEnum;
  status: TaskStatusEnum;
  assignee: SelectItemData;
  mode: CalenderTasksModeEnum;
  date: number;
}

export namespace TaskQueryCalenderRequest {
  // export type taskFields = keyof TaskResponseDTO; // todo improve later @ahmed
  export function mapToApiValue(params: TaskQueryCalenderRequest): TaskCalendarRequestDTO {
    return {
      mode: CalenderTasksModeEnum.convertToApiValue.getValue(params.mode),
      date: params.date,
      filters: QueryFilters.mapToApiValue({
        task_priority: params.priority && TaskPriorityEnum.convertToApiValue.getValue(params.priority),
        status: params.status && TaskStatusEnum.convertToApiValue.getValue(params.status),
        assignee: params.assignee && params.assignee.value,
      }),
    };
  }
  export function queries(): TaskQueryCalenderRequest {
    const params = getFromLocalStorage<TaskQueryCalenderRequest>(LocalStorageKeyEnum.TasksQueries);

    return {
      ...initializeCalenderQueries(),
      priority: params.priority || undefined,
      status: params.status || undefined,
      assignee: params.assignee || undefined,
    };
  }
}
