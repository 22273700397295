import { replace } from 'Utils/arrayUtils.js';
import remove from 'lodash/remove';

import { LOADING_FUNDS, FUNDS_LOADED, FUND_CREATED, FUND_UPDATED, FUND_DELETED } from 'ReduxActions/fundActions';

const initialState = {
  all: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_FUNDS:
      return {
        ...state,
        loading: action.payload,
      };

    case FUNDS_LOADED:
      return {
        ...state,
        all: action.payload,
        loading: false,
      };

    case FUND_CREATED:
      return {
        ...state,
        all: [action.payload, ...state.all],
      };

    case FUND_UPDATED:
      return {
        ...state,
        all: replace(state.all, { fund_id: action.payload.fund_id }, action.payload),
      };

    case FUND_DELETED:
      const deletedFunds = [...state.all];
      remove(deletedFunds, { fund_id: action.payload });
      return {
        ...state,
        all: deletedFunds,
      };
  }

  return state;
}
