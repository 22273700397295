export const eventTypes = {
  Call: {
    value: 1,
    label: 'Call',
    color: '#ffbc34',
    icon: 'icon-call-end',
  },
  Meeting: {
    value: 2,
    label: 'Meeting',
    color: '#03a6ff',
    icon: 'icon-user',
  },
  Conference: {
    value: 3,
    label: 'Conference',
    color: '#06d26f',
    icon: 'icon-flag',
  },
  Email: {
    value: 4,
    label: 'Task',
    color: '#53f66e',
    icon: 'icon-clock',
  },
  Task: {
    value: 5,
    label: 'Email',
    color: '#0fe3ff',
    icon: 'icon-envelope',
  },
};

export const eventList = [
  {
    value: 5,
    label: 'Email',
    color: '#f50',
    icon: 'icon-envelope',
  },
  {
    value: 4,
    label: 'Task',
    color: '#53f66e',
    icon: 'icon-clock',
  },
  {
    value: 1,
    label: 'Call',
    color: '#ffbc34',
    icon: 'icon-call-end',
  },
  {
    value: 2,
    label: 'Meeting',
    color: '#03a6ff',
    icon: 'icon-user',
  },
  {
    value: 3,
    label: 'Conference',
    color: '#06d26f',
    icon: 'icon-flag',
  },
];
