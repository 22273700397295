import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connectAutoComplete } from 'react-instantsearch/connectors';
import { InstantSearch, Configure, Index } from 'react-instantsearch/dom';
import { at } from 'Utils/objectUtils';

import Example from './Example';

import './SearchBar.scss';

const actions = {};
class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='SearchBar'>
        {this.props.algoliaToken && (
          <div className='inner-addon left-addon'>
            <i className='icon-magnifier searchIcon' />
            <InstantSearch appId={process.env.ALGOLIA_APP_ID} apiKey={this.props.algoliaToken} indexName='deal'>
              <AutoComplete />
              <Configure hitsPerPage={15} />
              <Index indexName='contact' />
              {/* <Index indexName="task" /> */}
            </InstantSearch>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    algoliaToken: at(state, 'auth.user.algolia_token'),
  };
}
const AutoComplete = connectAutoComplete(Example);
export default connect(mapStateToProps, actions)(SearchBar);
