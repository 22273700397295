import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addPipeline, updatePipeline, editingPipeline, deletePipelines, updatePipelines } from 'ReduxActions/pipelineActions';
import SubmitButton from 'SharedComponent/SubmitButton';
import AddPipeline from '../AddPipeline';
import PipelinesDialog from '../pipelinesDialog';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import { Permissible as PermissibleV2 } from '../../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';

import { PermissibleRender, Permissible } from 'SharedComponent/permissions';
import { VC_ACCELERATOR, SALES } from 'Enums/permissions';

import './HeaderPipelines.scss';
import { Mixpanel } from 'App/app';
const actions = {
  addPipeline,
  editingPipeline,
  updatePipeline,
  deletePipelines,
  updatePipelines,
  Permissible,
};
class HeaderPipelines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      showDialog: false,
      type: null,
    };

    this.showDetails = this.showDetails.bind(this);
    this.hideDetails = this.hideDetails.bind(this);
    this.hideDialogAction = this.hideDialogAction.bind(this);
  }

  showDetails() {
    this.setState({ showDetails: true });
  }

  hideDetails() {
    this.props.editingPipeline(null);
    this.setState({ showDetails: false });
  }

  hideDialogAction() {
    this.setState({ showDialog: false, type: null });
  }

  onClickMoveBtn() {
    this.props.isPortfolio
      ? gaEvent(appCategories.settings_portfolio, appActions(`${appTypes.mass}_${appTypes.move}`, appEvents.clicked).button)
      : gaEvent(appCategories.settings_pipelines, appActions(`${appTypes.mass}_${appTypes.move}`, appEvents.clicked).button);
    this.setState({ showDialog: true, type: 'move' });
  }

  onClickDeleteBtn() {
    this.props.isPortfolio
      ? gaEvent(appCategories.settings_portfolio, appActions(`${appTypes.mass}_${appTypes.delete}`, appEvents.clicked).button)
      : gaEvent(appCategories.settings_pipelines, appActions(`${appTypes.mass}_${appTypes.delete}`, appEvents.clicked).button);
    this.setState({ showDialog: true, type: 'delete' });
  }

  onClickAddBtn() {
    this.props.isPortfolio
      ? gaEvent(appCategories.settings_portfolio, appActions(`${appTypes.add}`, appEvents.clicked).button)
      : gaEvent(appCategories.settings_pipelines, appActions(`${appTypes.add}`, appEvents.clicked).button);
    this.showDetails();
  }

  onRemove(pipelines) {
    this.props.deletePipelines(pipelines);
    this.props.updateSelectedLists(pipelines);
  }

  onUpdate(object) {
    this.props.updatePipelines(object);
    this.props.updateSelectedLists(object.pipelines);
  }

  render() {
    const { isPortfolio } = this.props;
    return (
      <div>
        <div className='HeaderPipelines display-flex-between'>
          <div className='display-flex-start'>
            <div className='list-pipelines-text mr-5'>List of {isPortfolio ? 'portfolio' : 'pipelines'}</div>
          </div>
          <div className='display-flex-end'>
            <PermissibleRender userPermissions={[]} requiredPermissions={[[VC_ACCELERATOR, SALES]]}>
              <PermissibleV2 can={isPortfolio ? PermissionTypeEnum.MovePortfolioToPipeline : PermissionTypeEnum.MovePipelineToPortfolio}>
                <div
                  className={classNames('ml-5 display-flex-center', this.props.isSelected ? 'cursor-pointer' : 'disable-button')}
                  style={{ color: '#06d26f' }}
                  onClick={() => this.onClickMoveBtn()}
                >
                  <span className='icon-cursor-move mr-2 icon-span-details' />
                  <span className='icon-span-text'>Move</span>
                </div>
              </PermissibleV2>
            </PermissibleRender>
            <PermissibleV2 can={isPortfolio ? PermissionTypeEnum.DeleteAllPortfolio : PermissionTypeEnum.DeleteAllPipeline}>
              <div
                className={classNames('ml-5 display-flex-center', this.props.isSelected ? 'cursor-pointer' : 'disable-button')}
                style={{ color: '#ff8181' }}
                onClick={() => this.onClickDeleteBtn()}
              >
                <span className='icon-trash mr-2 icon-span-details' />
                <span className='icon-span-text'>Delete</span>
              </div>
            </PermissibleV2>
            <PermissibleV2 can={isPortfolio ? PermissionTypeEnum.CreatePortfolio : PermissionTypeEnum.CreatePipeline}>
              <div className='ml-5'>
                <SubmitButton
                  label={isPortfolio ? 'New Portfolio' : 'New Pipeline'}
                  icon='icon-plus'
                  onClick={() => {
                    this.onClickAddBtn();
                    Mixpanel.track('Clicked on Add Pipeline settings');
                  }}
                />
              </div>
            </PermissibleV2>
          </div>
        </div>
        {(this.state.showDetails || this.props.editing) && (
          <AddPipeline
            showDetails={this.state.showDetails || this.props.editing}
            onDismiss={this.hideDetails}
            addPipeline={this.props.addPipeline}
            update={this.props.updatePipeline}
            isPortfolio={isPortfolio}
            isVC={this.props.Permissible([], [VC_ACCELERATOR])}
          />
        )}
        <PipelinesDialog
          showDialog={this.state.showDialog}
          type={this.state.type}
          onDismissDialog={this.hideDialogAction}
          pipelines={this.props.rows}
          deletePipelines={this.onRemove.bind(this)}
          updatePipelines={this.onUpdate.bind(this)}
          isPortfolio={isPortfolio}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    count: state.settings.pipelines.count,
    editing: state.settings.pipelines.editing,
  };
}

export default connect(mapStateToProps, actions)(HeaderPipelines);
