import React from 'react';

import { LocalStorageKeyEnum } from '../enums/local-storage.enum';

export function setToLocalStorage<T>(field: LocalStorageKeyEnum, value: T): void {
  localStorage.setItem(field, JSON.stringify(value));
}

export function getFromLocalStorage<T>(field: LocalStorageKeyEnum, defaultValue?: T): T {
  return (JSON.parse(localStorage.getItem(field)) || defaultValue) as T;
}

export function useStateWithLocalStorage<T>(localStorageKey: LocalStorageKeyEnum, defaultValue: T): readonly [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = React.useState<T>(getFromLocalStorage<T>(localStorageKey, defaultValue));

  React.useEffect(() => {
    setToLocalStorage(localStorageKey, value || defaultValue);
  }, [value]);

  return [value || defaultValue, setValue];
}
