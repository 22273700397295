import React from 'react';
import { Field } from 'redux-form';
import TextField from 'SharedComponent/TextField';
import * as validators from 'Utils/validators';

export const renderFieldList = ({ fields, meta: { error, submitFailed } }) => {
  return (
    <div className='pl-3 pr-3'>
      {fields.map((cfl, index) => (
        <div className='row display-flex-center mb-2' key={index + 1}>
          <div className='col-md-6 col-sm-10'>
            <Field component={TextField} name={cfl} placeholder='Option name' required={true} validate={[validators.required]} labelClass='' type='text' />
          </div>
          <div className='col-md-6 col-sm-2'>
            <i className='icon-trash icon-trash-details' onClick={() => fields.remove(index)} />
          </div>
        </div>
      ))}
      <div className='row'>
        <div className='col-md-6 mt-2 mb-2'>
          <div className='dashed-button' onClick={() => fields.push()}>
            Add new value
          </div>
        </div>
      </div>
    </div>
  );
};
