export const roundFields = {
  investment_type: {
    default: 'Equity',
    field_label: 'Type of investment',
    field_list: ['Equity', 'Convertible note', 'Loan'],
    field_name: 'investment_type',
    field_type: 'list',
    style: { width: '100%' },
    placeHolder: null,
    required: 1,
    show: 1,
  },
  number_of_shares: {
    default: '0',
    field_label: 'Number of shares',
    field_list: null,
    field_name: 'number_of_shares',
    field_type: 'number',
    placeHolder: 'Number of shares',
    required: 0,
    show: 1,
  },
  price_per_share: {
    field_label: 'Price per share',
    field_list: null,
    field_name: 'price_per_share',
    field_type: 'monetary',
    placeholder: 'Price per share',
    required: 0,
    show: 1,
  },
  discount: {
    default: '0',
    field_label: 'Discount',
    field_list: null,
    field_name: 'discount',
    field_type: 'percentage',
    placeHolder: 'Discount %',
    required: 0,
    show: 1,
  },
  valuation_cap: {
    field_label: 'Valuation cap',
    field_list: null,
    field_name: 'valuation_cap',
    field_type: 'monetary',
    placeholder: 'Amount',
    required: 0,
    show: 1,
  },
  amount_invested_loan: {
    field_label: 'Amount',
    field_list: null,
    field_name: 'amount_invested',
    field_type: 'monetary',
    placeholder: 'Amount',
    required: 1,
    show: 1,
  },
  amount_invested: {
    field_label: 'Amount invested',
    field_list: null,
    field_name: 'amount_invested',
    field_type: 'monetary',
    placeholder: 'Amount invested',
    required: 1,
    show: 1,
  },
  investment_date: {
    default: 0,
    field_label: 'Investment date',
    field_list: null,
    field_name: 'investment_date',
    field_type: 'date',
    placeHolder: null,
    required: 1,
    show: 1,
  },
  round_stage: {
    default: 0,
    field_label: 'Stage',
    field_list: ['Pre Seed', 'Seed', 'Seed 2', 'Pre Series A', 'Series A', 'Bridge to B', 'Series B', 'Bridge to C', 'Series C'],
    field_name: 'round_stage',
    field_type: 'list',
    style: { width: '100%' },
    placeHolder: null,
    required: 1,
    show: 1,
  },
  total_raised_amount: {
    field_label: 'Total raised',
    field_list: null,
    field_name: 'total_raised_amount',
    field_type: 'monetary',
    placeholder: 'Total raised',
    required: 0,
    show: 1,
  },
  loan_rate: {
    default: '0',
    field_label: 'Loan rate',
    field_list: null,
    field_name: 'loan_rate',
    field_type: 'percentage',
    placeHolder: 'Loan rate %',
    required: 1,
    show: 1,
  },
  date_of_operation: {
    default: 0,
    field_label: 'Operation date',
    field_list: null,
    field_name: 'date_of_operation',
    field_type: 'date',
    placeHolder: null,
    required: 1,
    show: 1,
  },
  date_of_repayment: {
    default: 0,
    field_label: 'Repayment date',
    field_list: null,
    field_name: 'date_of_repayment',
    field_type: 'date',
    placeHolder: null,
    required: 0,
    show: 1,
  },
};
export function mapWithMissingParticipate(data) {
  return {
    co_investors: data.co_investors,
    is_participate: data.is_participate ? 1 : 0,
    post_money_valuation: data.post_money_valuation,
    pre_money_valuation: data.pre_money_valuation,
    round_stage: data.round_stage,
    total_raised_amount: data.total_raised_amount,
    total_raised_amount_code: data.total_raised_amount_code,
    total_raised_amount_currency_id: data.total_raised_amount_currency_id,
    investment_rational: data.investment_rational,
    deal_id: data.deal_id,
    round_id: data.round_id,
  };
}
export function mapToEquityData(data) {
  return {
    is_participate: data.is_participate ? 1 : 0,
    currency_id: data.amount_invested_currency_id,
    number_of_shares: data.number_of_shares,
    round_stage: data.round_stage,
    deal_id: data.deal_id,
    investment_type: data.investment_type,
    price_per_share_code: data.price_per_share_code,
    price_per_share: data.price_per_share,
    price_per_share_currency_id: data.price_per_share_currency_id,
    amount_invested_code: data.amount_invested_code,
    amount_invested_currency_id: data.amount_invested_currency_id,
    amount_invested: data.amount_invested,
    investment_date: data.investment_date,
    total_raised_amount: data.total_raised_amount,
    total_raised_amount_code: data.total_raised_amount_code,
    total_raised_amount_currency_id: data.total_raised_amount_currency_id,
    post_money_valuation: data.post_money_valuation,
    pre_money_valuation: data.pre_money_valuation,
    investment_rational: data.investment_rational,
    co_investors: data.co_investors,
    amount_invested_usd: data.amount_invested_usd,
    round_id: data.round_id,
  };
}

export function mapToConvertibleNoteData(data) {
  return {
    is_participate: data.is_participate ? 1 : 0,
    currency_id: data.amount_invested_currency_id,
    discount: data.discount,
    deal_id: data.deal_id,
    investment_type: data.investment_type,
    valuation_cap: data.valuation_cap,
    valuation_cap_code: data.valuation_cap_code,
    valuation_cap_currency_id: data.valuation_cap_currency_id,
    amount_invested: data.amount_invested,
    amount_invested_code: data.amount_invested_code,
    amount_invested_currency_id: data.amount_invested_currency_id,
    investment_date: data.investment_date,
    round_stage: data.round_stage,
    total_raised_amount: data.total_raised_amount,
    total_raised_amount_code: data.total_raised_amount_code,
    total_raised_amount_currency_id: data.total_raised_amount_currency_id,
    post_money_valuation: data.post_money_valuation,
    pre_money_valuation: data.pre_money_valuation,
    investment_rational: data.investment_rational,
    co_investors: data.co_investors,
    amount_invested_usd: data.amount_invested_usd,
    round_id: data.round_id,
  };
}

export function mapToCurrentAccountData(data) {
  return {
    is_participate: data.is_participate ? 1 : 0,
    currency_id: data.amount_invested_currency_id,
    investment_type: data.investment_type,
    deal_id: data.deal_id,
    date_of_operation: data.date_of_operation,
    date_of_repayment: data.date_of_repayment,
    round_stage: data.round_stage,
    investment_date: data.investment_date,
    amount_invested: data.amount_invested,
    amount_invested_code: data.amount_invested_code,
    amount_invested_currency_id: data.amount_invested_currency_id,
    loan_rate: data.loan_rate,
    round_id: data.round_id,
  };
}
