import { Modal, Spin, Icon, Result } from 'antd';
import React, { useState } from 'react';
import api from 'ReduxActions/api';
import SubmitButton from 'SharedComponent/SubmitButton';

export default function ConfirmChange(props) {
  const [waiting, setWaiting] = useState(false);
  const [step, setStep] = useState(1);

  const spinner = <Icon type='loading' style={{ fontSize: 24 }} spin />;

  const onConfirm = () => {
    setWaiting(true);
    api
      .post('/billing/update', { new_plan_id: props.product })
      .then(() => {
        setWaiting(false);
        setStep(2);
      })
      .catch(() => {
        setWaiting(false);
        setStep(3);
      });
  };

  const retry = () => {
    setStep(1);
    onConfirm();
  };

  const onClose = () => {
    props.onClose();
    if (step == 2) location.reload();
    setWaiting(false);
    setStep(1);
  };

  function renderContent() {
    switch (step) {
      case 1:
        return (
          <Spin spinning={waiting} tip='Upgrading...' indicator={spinner}>
            <div className='modal-title' style={{ fontSize: '2em' }}>
              Confirm the change of your subscription.
            </div>
            <div className='m-3' />
            <SubmitButton label='Confirm' icon='icon-check' style={{ width: '100%' }} onClick={() => onConfirm()} />
          </Spin>
        );
      case 2:
        return <ResultCard type='success' title='Your subscription has been upgraded' extra={[]} />;
      case 3:
        return (
          <ResultCard
            type='error'
            title="we couldn't process your upgrade"
            subTitle='Please make sure to update your billing information and try again'
            extra={[<SubmitButton label='Retry' icon='icon-check' style={{ width: '100%' }} onClick={() => retry()} />]}
          />
        );
    }
  }

  return (
    <div>
      <Modal
        visible={props.isVisible}
        wrapClassName='UpgradeModal'
        style={{ top: 24 }}
        bodyStyle={{ padding: 32, backgroundColor: '#f8f8f8' }}
        // width={900}
        footer={null}
        closable={false}
        onCancel={onClose}
      >
        {renderContent()}
      </Modal>
    </div>
  );
}

const ResultCard = ({ type, title, subTitle, extra }) => <Result status={type || 'error'} title={title} subTitle={subTitle} extra={extra} />;
