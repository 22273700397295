import api from './api';
import moment from 'moment';
import { message } from 'antd';
import objectToFormData from 'Utils/objToFormData';
import omit from 'lodash/omit';
import { Notifier } from 'SharedComponent/Notifier';
import { baseHttpService } from '../v2.0/core/services/helpers/base-http';

export const LOADING_TASKS = 'tasks:loading:LOADING_TASKS';
export const TASKS_LOADED = 'tasks:loaded:TASKS_LOADED';
export const EDITING_TASK = 'tasks:editing:EDITING_TASK';
export const TASK_UPDATED = 'tasks:updated:TASK_UPDATED';
export const TASK_DELETED = 'tasks:deleted:TASK_DELETED';

function normalizeTask(task) {
  let guests = task.people ? task.people.filter(it => !it.is_user).map(it => it.value) : [];
  let attendees = task.people ? task.people.filter(it => it.is_user).map(it => it.value) : [];
  let due_date = task.due_date ? moment(task.due_date).tz(task.timezone).format('L HH:mm') : null;

  return {
    ...omit(task, ['people']),
    guests: JSON.stringify(guests),
    attendees: JSON.stringify(attendees),
    due_date,
  };
}

export function loadAllTasks(page, size, priority, user, status, sort) {
  return dispatch => {
    let filter = `&per-page=${size}&sort=-created_at`;
    if (sort) filter += `&sort=${sort}`;
    if (priority) filter += `&filter[task_priority]=${priority}`;
    if (user) filter += `&filter[assigned_to]=${user}`;
    if (status != null) filter += `&filter[status]=${status}`;
    dispatch({ type: LOADING_TASKS, payload: true });
    api
      .get(`/tasks?page=${page}${filter}`)
      .then(({ data }) => {
        dispatch({
          type: TASKS_LOADED,
          payload: data,
        });
      })
      .catch(error => dispatch({ type: LOADING_TASKS, payload: false }));
  };
}

export function loadV2Tasks(page, size, priority = '', user = '', status = '', sort = '') {
  return dispatch => {
    const filter = {
      page,
      perPage: size,
    };
    dispatch({ type: LOADING_TASKS, payload: true });
    baseHttpService
      .post(`/v2/tasks/lazy`, filter)
      .then(({ data }) => {
        dispatch({
          type: TASKS_LOADED,
          payload: data,
        });
      })
      .catch(error => dispatch({ type: LOADING_TASKS, payload: false }));
  };
}

export function editingTask(task) {
  return dispatch => dispatch({ type: EDITING_TASK, payload: task });
}

export function updatedTask(task) {
  return dispatch => {
    dispatch({ type: TASK_UPDATED, payload: task });
    dispatch({ type: 'dashboard:tasks:updated', payload: task }); // refactoring
    dispatch({ type: 'deal:tasks:updated', payload: task }); // refactoring
  };
}

export function updateTask(task) {
  const config = {
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  };

  const newTask = normalizeTask(task);
  let oldFile = JSON.stringify(
    task.files.filter(function (file) {
      return file.hasOwnProperty('title');
    }),
  );
  let files = task.files.filter(function (file) {
    return file.hasOwnProperty('key');
  });

  const formattedTask = { ...newTask, oldFile, files };
  let formData = objectToFormData(formattedTask);
  return dispatch => {
    api
      .post(`/tasks/${task.task_id}/update`, formData, config)
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Success',
          message: `Task has been successfully updated!`,
        });
        dispatch({
          type: TASK_UPDATED,
          payload: data,
        });
      })
      .catch(error => {});
  };
}

export function deletetask(id) {
  return dispatch => {
    api
      .delete(`/tasks/${id}/delete`)
      .then(() => {
        dispatch({ type: TASK_DELETED, payload: id });
        Notifier({
          type: 'oprn',
          title: 'Success',
          message: `Task has been successfully deleted!`,
        });
      })
      .catch(err => {});
  };
}

export function getZoomUrl({ meeting_id, meeting_start }) {
  message.loading(`${meeting_start ? 'Starting' : 'Join'}  meeting...`, 2.5);
  return dispatch => {
    api
      .post(`/zoom/meeting/${meeting_id}`, { meeting_start })
      .then(({ data }) => {
        window.open(data.url);
      })
      .catch(err => message.error(`Failed to ${meeting_start ? 'start' : 'join'} this meeting, try again.`));
  };
}
