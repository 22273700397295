import React, { Component } from 'react';

import './CustomLabel.scss';

export default class CustomLabel extends Component {
  render() {
    const { label, color, backgroundColor, border, font } = this.props;
    return (
      <div className='CustomLabel'>
        <span className='label-font label-danger' style={{ color, backgroundColor, border: `1px solid ${border}`, font }}>
          {label}
        </span>
      </div>
    );
  }
}
