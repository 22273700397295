import React from 'react';

import { FormIdEnum } from '../../../core/enums/entity-ids/form-id.enum';
import { ModalIdEnum } from '../../../core/enums/entity-ids/modal-id.enum';
import { Form, Modal } from '../../../shared/components';
import { RoleForm } from './role-form/role-form.component';
import { RoleSummaryData } from '../../../core/models/role/role-summary-data';

export interface CreateUpdateRoleProp {
  submitting?: boolean;
  editing?: RoleSummaryData;
  onSubmit(values: {}): void;
}

export function CreateUpdateRole(props: CreateUpdateRoleProp): JSX.Element {
  return (
    <Modal modalId={ModalIdEnum.createPermission} title='Create new role' editTitle='Update role' width='40rem'>
      <Form formId={FormIdEnum.createPermissionForm} initialValues={props.editing} onSubmit={props.onSubmit}>
        <RoleForm />
      </Form>
    </Modal>
  );
}
