import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import fileLogo from './file_drop.svg';

import './FileDrop.scss';
// import 'dropzone/dist/min/dropzone.min.css';

let dropzoneRef;
var _validFileExtensions = ['jpg', 'jpeg', 'bmp', 'gif', 'png'];
const CanvasComponent = props => {
  return (
    <div className='dz-preview dz-file-preview dz-processing dz-success dz-complete'>
      <div className='dz-image'>
        <img data-dz-thumbnail='' />
      </div>
      <div className='dz-details'>
        <div className='dz-size'>
          <a
            className='remove-image'
            style={{ display: 'inline' }}
            onClick={e => {
              props.remove();
              e.stopPropagation();
            }}
          >
            &#215;
          </a>
        </div>
        <div className='dz-filename'>
          <span data-dz-name=''>{props.type} file</span>
        </div>
      </div>
      <div className='dz-error-message'>
        <span data-dz-errormessage='' />
      </div>
    </div>
  );
};

const ImageComponent = props => {
  return (
    <div className='image-area'>
      <img src={props.file} width='100' height='100' alt='Preview' />
      <a
        className='remove-image'
        style={{ display: 'inline' }}
        onClick={e => {
          props.remove();
          e.stopPropagation();
        }}
      >
        &#215;
      </a>
    </div>
  );
};
class FileDrop extends Component {
  constructor(props) {
    super(props);
    this.state = { file: null };
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidUpdate(prevProps, nextProps) {
    if (this.props.url !== prevProps.url) {
      this.setState({ file: this.props.url });
    }
  }

  onDrop(acceptedFiles) {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.props.onUpload(file);
        this.setState({ file });
      };
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');

      reader.readAsBinaryString(file);
    });
  }

  getType(url) {
    if (!url) return '';
    if (url.type) return url.type;
    const array = (url.name || '.Unknown').split('.');
    return array[array.length - 1];
  }

  render() {
    return (
      <div className='FileDrop'>
        <Dropzone
          ref={node => {
            dropzoneRef = node;
          }}
          onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
          style={this.props.styles}
        >
          {({ getRootProps, getInputProps }) => (
            <div className={this.props.classNames} {...getRootProps()}>
              <input {...getInputProps()} multiple={false} />
              {this.state.file ? (
                <aside>
                  <div className='preview'>
                    {this.state.file.hasOwnProperty('preview') ? (
                      this.state.file.type.includes('image/') ? (
                        <ImageComponent remove={() => this.setState({ file: null })} file={this.state.file.preview || this.state.file} />
                      ) : (
                        <CanvasComponent remove={() => this.setState({ file: null })} type={this.state.file.name} />
                      )
                    ) : this.state.file && _validFileExtensions.includes(this.getType(this.state.file)) ? (
                      <ImageComponent remove={() => this.setState({ file: null })} file={this.state.file.preview || this.state.file} />
                    ) : (
                      <CanvasComponent remove={() => this.setState({ file: null })} type={this.getType(this.state.file)} />
                    )}
                  </div>
                </aside>
              ) : (
                <img src={this.props.fileLogo || fileLogo} />
              )}
              <div className='drop-file-txt'>{this.props.placeholder}</div>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default FileDrop;
