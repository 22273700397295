import { ApiUrlsEnum } from '../helpers/api-url';
import { get, lazyLoad } from './helpers/base-http';
import { apiUrlMatcher } from '../helpers/api-url-matcher';
import { LazyLoadResponse } from '../models/queries/lazy-response.model';
import { EmailResponseDTO, FileResponseDTO, NoteResponseDTO } from '../generated/model/models';
import { NotificationResponseDTO } from '../generated/ActivityDTO';
import { HttpParamsType } from '../models/queries/http-params.type';
import { PaginationQuery } from '../models/queries/pagination-query.model';
import { ActivitySummaryData } from '../models/summary-log/activity/activity-summary-data';
import { NoteSummaryData } from '../models/summary-log/note/note-summary-data';
import { EmailSummaryData } from '../models/summary-log/email/email-summary-data';
import { FileSummaryData } from '../models/summary-log/file/file-summary-data';

export function getActivityList(params: HttpParamsType<PaginationQuery>): Promise<LazyLoadResponse<ActivitySummaryData[]>> {
  return lazyLoad<NotificationResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.SummaryActivity), params).then(data => ({
    ...data,
    results: data.results.map(res => ActivitySummaryData.mapFromApi(res)),
  }));
}

export function getNoteList(params: HttpParamsType<PaginationQuery>): Promise<LazyLoadResponse<NoteSummaryData[]>> {
  return lazyLoad<NoteResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.SummaryNotes), params).then(data => ({
    ...data,
    results: data.results.map(res => NoteSummaryData.mapFromApi(res)),
  }));
}

export function getEmailsList(params: HttpParamsType<PaginationQuery>): Promise<LazyLoadResponse<EmailSummaryData[]>> {
  return lazyLoad<EmailResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.SummaryEmails), params).then(data => ({
    ...data,
    results: data.results.map(res => EmailSummaryData.mapFromApi(res)),
  }));
}
export function getFilesList(params: HttpParamsType<PaginationQuery>): Promise<LazyLoadResponse<FileSummaryData[]>> {
  return lazyLoad<FileResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.SummaryFiles), params).then(data => ({
    ...data,
    results: data.results.map(res => FileSummaryData.mapFromApi(res)),
  }));
}

export async function getNoteById(uuid: string): Promise<NoteSummaryData> {
  const data = await get<NoteResponseDTO>(apiUrlMatcher(ApiUrlsEnum.Note, { uuid }));

  return NoteSummaryData.mapFromApi(data);
}
