import { IconData, IconPrefixEnum } from '../../../shared/interfaces/icon-data';
import { StepsData } from '../../../shared/interfaces/steps-data';
import { IMap } from '../../utils/map-utils';

export enum ExcelStepsEnum {
  Template = 'download',
  Form = 'form',
  Upload = 'upload',
}

export namespace ExcelStepsEnum {
  export const values: ExcelStepsEnum[] = [ExcelStepsEnum.Template, ExcelStepsEnum.Form, ExcelStepsEnum.Upload];

  export const toString: IMap<ExcelStepsEnum, string> = new IMap<ExcelStepsEnum, string>([
    [ExcelStepsEnum.Template, 'Templates'],
    [ExcelStepsEnum.Form, 'Form'],
    [ExcelStepsEnum.Upload, 'Upload File'],
  ]);

  export const stepToIndex: IMap<ExcelStepsEnum, number> = new IMap<ExcelStepsEnum, number>([
    [ExcelStepsEnum.Template, 0],
    [ExcelStepsEnum.Form, 1],
    [ExcelStepsEnum.Upload, 2],
  ]);

  export const indexToStep: IMap<number, ExcelStepsEnum> = new IMap<number, ExcelStepsEnum>([
    [0, ExcelStepsEnum.Template],
    [1, ExcelStepsEnum.Form],
    [2, ExcelStepsEnum.Upload],
  ]);

  export const previousStep: IMap<number, number> = new IMap<number, number>([
    [0, 0],
    [1, 0],
    [2, 1],
  ]);

  export const nextStep: IMap<number, number> = new IMap<number, number>([
    [0, 1],
    [1, 2],
    [2, 2],
  ]);

  export const iconData: IMap<ExcelStepsEnum, IconData> = new IMap<ExcelStepsEnum, IconData>([
    [ExcelStepsEnum.Template, { name: 'download', prefix: IconPrefixEnum.Antd }],
    [ExcelStepsEnum.Form, { name: 'form', prefix: IconPrefixEnum.Antd }],
    [ExcelStepsEnum.Upload, { name: 'upload', prefix: IconPrefixEnum.Antd }],
  ]);

  export const selectItems: StepsData<ExcelStepsEnum>[] = values.map(step => ({
    value: step,
    index: stepToIndex.getValue(step),
    title: toString.getValue(step),
  }));
}
