import debounce from 'lodash/debounce';
import React, { Component } from 'react';

import { Field, FieldArray } from 'redux-form';
import * as validators from 'Utils/validators';

import { numberFormatter, normalizeNumber } from 'Utils/objectUtils';

import InputCustomFields from 'SharedComponent/functions/InputCustomFields';
import AutoSearch from 'SharedComponent/AutoSearch';
import { FieldSelect, AsyncSearch } from 'SharedComponent/SelectOption/FieldSelect';
import TextField from 'SharedComponent/TextField';
import ImageUploadField from 'SharedComponent/ImageUpload/ImageUploadField.js';

import { Permissible } from '../../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';

import { renderContacts } from '../renderContacts.js';

import defaultImage from 'Assets/img/default.png';
class DealForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.clearbitApi = debounce(this.clearbitApi, 500);
  }

  clearbitApi(suggest) {
    this.props.searchClearbit(suggest);
  }

  render() {
    const { clearbitCompanies, clearbitLoading, label_value, extra, customFields } = this.props;
    const dealValue = this.props.formValues && this.props.formValues[label_value.field];
    return (
      <div className='m-3'>
        <div className='row'>
          <div className='col-md-3'>
            <Field name='image' component={ImageUploadField} />
          </div>
          <div className='col-md-9'>
            <Field
              label='Name'
              name={`name`}
              placeholder='Type the name of your deal'
              labelClass='font-size-14 font-weight-500'
              component={AutoSearch}
              options={this.parseCompanies(clearbitCompanies)}
              loading={clearbitLoading}
              onUpdate={event => this.clearbitApi(event)}
              onSelect={object => this.props.initializeClearbit(object)}
              validate={[validators.required]}
              required
              fieldRequired='name'
            />
            <div className='mt-2'>
              <span className='font-size-14 font-weight-500'>{label_value.label}</span>
              <div className='currency-input'>
                <div className='currency-input-child'>
                  <Field
                    className=''
                    name={label_value.field}
                    component={TextField}
                    placeholder={label_value.label}
                    validate={[validators.number]}
                    disabled={label_value.read_only}
                    onChange={event => {}}
                    format={numberFormatter}
                    normalize={normalizeNumber}
                  />
                </div>
                <div className='currency-input-child'>
                  <Field
                    name={`currencyName`}
                    component={AsyncSearch}
                    placeholder='Type currency'
                    url='/currencies'
                    field='code'
                    listValue='currency_id'
                    validate={dealValue ? [validators.required] : []}
                    onLoad={event => {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Field
              label={this.props.isPortfolio ? 'Portfolio' : 'Pipeline'}
              name={`pipeline_id`}
              component={FieldSelect}
              labelClass='font-size-14 font-weight-500'
              placeholder={this.props.isPortfolio ? 'Portfolio' : 'Pipeline'}
              options={extra ? this.props.extraPipelines : this.props.pipelines}
              labelKey='name'
              valueKey='pipeline_id'
              onLoad={this.props.onLoadPipelineStage}
              validate={[validators.required]}
              required
            />
          </div>
          <div className='col-md-6'>
            <Field
              label='Stage'
              name={`stage_id`}
              component={FieldSelect}
              labelClass='font-size-14 font-weight-500'
              placeholder='Stage'
              options={this.props.stages}
              labelKey='name'
              valueKey='stage_id'
              onLoad={event => {}}
              onHover='If the deal stage is not specified it will automatically be placed in the first stage of your process'
            />
          </div>
        </div>
        <div className='row'>
          {customFields.map(customField => (
            <InputCustomFields
              customField={customField}
              key={customField.field_id}
              domains={this.props.domains}
              users={this.parseUsers(this.props.users)}
              classLabel='font-size-14 font-weight-500'
            />
          ))}
        </div>
        <Permissible can={PermissionTypeEnum.CreateContact}>
          <FieldArray className='m-3' name='contacts' component={renderContacts} customFields={this.props.contactCustomFields} trackEvent={() => {}} />
        </Permissible>
      </div>
    );
  }

  parseCurrencies(currencies) {
    return currencies.map(currency => {
      return {
        value: currency.currency_id,
        label: `${currency.code} ${currency.symbol}`,
      };
    });
  }

  parseCompanies(companies) {
    return companies.map(company => {
      return {
        name: company.name,
        image: company.logo,
        website: company.domain,
      };
    });
  }

  parseUsers(users) {
    return users.map(user => {
      return {
        value: user.id,
        label: user.profile ? user.profile.fullname : 'N/A',
        image: user.profile && user.profile.image ? user.profile.image : defaultImage,
      };
    });
  }
}

export default DealForm;
