import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import { actions } from 'ReduxActions/authActions';
import { CheckBoxField } from 'SharedComponent/CheckBox/CheckBox';
import PasswordButton from 'SharedComponent/PasswordButton';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';
import TextField from 'SharedComponent/TextField';
import timezones from 'SharedComponent/TimeZoneField/timezones.js';
import PasswordDialog from '../PasswordDialog';
import ImageUpload from 'SharedComponent/ImageUpload';
import SubmitButton from 'SharedComponent/SubmitButton';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './Profile.scss';

const days = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];
const dateFormatter = time => {
  return moment(time * 1000).format('ll');
};
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      isLoading: false,
      show: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.dismiss = this.dismiss.bind(this);
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        image: this.props.user && this.props.user.profile ? this.props.user.profile.image : '',
      });
    }
  }
  componentWillMount() {
    const image = this.props.user && this.props.user.profile && this.props.user.profile.image;
    this.setState({ image });
  }
  onSubmit(values) {
    const image = this.state.image;
    const user = {
      fullname: values.profile.fullname,
      phone: values.profile.phone,
      image,
      email: values.email,
      setting: values.setting,
      timezone: values.profile.timezone,
      week_start: values.profile.week_start,
    };

    this.props.updateMe(user);
    gaEvent(appCategories.profile, appActions(appTypes.save, appEvents.clicked).button);
  }

  dismiss() {
    this.setState({ show: false });
  }
  handleImageUpload = image => {
    this.setState({ image });
  };
  handleImageLoading = isLoading => {
    this.setState({ isLoading });
  };
  reset = () => {
    this.props.reset();
  };
  render() {
    const { error, handleSubmit, pristine, reset, submitting } = this.props;
    const image = this.props.user && this.props.user.profile && this.props.user.profile.image;
    const imagePristine = image === this.state.image;
    return (
      <div className={classNames('Profile', this.props.loading && 'profile-loading')}>
        {this.props.loading && (
          <div className='custome-overlay'>
            <div className='custom-spinner'>
              <div id='custom-spinner-id' />
            </div>
          </div>
        )}
        <form id='edit-profile-form' onSubmit={handleSubmit(this.onSubmit)}>
          <div className='display-flex-top profile-content'>
            <div className='flex-1'>
              <div className='display-flex-start flex-wrap mb-3'>
                <div className='flex-1 display-flex-center'>
                  <ImageUpload appCategory={appCategories.profile} value={image} onChange={this.handleImageUpload} loading={this.handleImageLoading} />
                </div>
                <div className='flex-2 flex-wrap ml-2'>
                  <div className='mb-3'>
                    <Field
                      label='Full name'
                      name={`profile.fullname`}
                      component={TextField}
                      labelClass='font-size-14 font-weight-500'
                      type='text'
                      placeholder='Full name'
                      required
                    />
                  </div>
                  <div className='mb-3'>
                    <Field label='Email' name={`email`} component={TextField} labelClass='font-size-14 font-weight-500' type='text' placeholder='Email' required />
                  </div>
                  <div className='mb-3'>
                    <Field label='Phone number' name={`profile.phone`} component={TextField} labelClass='font-size-14 font-weight-500' type='text' placeholder='Phone number' />
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap'>
                <div className='flex-1 mb-3 mr-3'>
                  <Field label='Company' name={`company.name`} component={TextField} labelClass='font-size-14 font-weight-500' type='text' placeholder='Company' disabled />
                </div>
                <div className='flex-1 mb-3 ml-3'>
                  <Field
                    label='Member since'
                    name={`created_at`}
                    component={TextField}
                    format={dateFormatter}
                    labelClass='font-size-14 font-weight-500'
                    type='text'
                    placeholder='Title'
                    disabled
                  />
                </div>
              </div>
              <div>
                <PasswordButton
                  label='Password'
                  onClick={() => {
                    this.setState({ show: true });
                    gaEvent(appCategories.profile, appActions(`CHANGE_PASSWORD`, appEvents.clicked).button);
                  }}
                />
              </div>
            </div>
            <div className='flex-1 ml-5'>
              <div className='display-flex-top'>
                <div className='flex-3'>
                  <div className='mb-5 dashboard-settings-txt'>Other Settings</div>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td className='default-color font-size-14 font-weight-500'>Timezone:</td>
                          <td>
                            <Field name={`profile.timezone`} component={FieldSelect} placeholder='Select Timezone...' options={timezones} clearable={null} />
                          </td>
                        </tr>
                        <tr>
                          <td className='default-color font-size-14 font-weight-500'>Week start:</td>
                          <td>
                            <Field name={`profile.week_start`} placeholder='Select week start' component={FieldSelect} options={days} clearable={null} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <PasswordDialog showDetails={this.state.show} onDismiss={this.dismiss} />
        <div className='row'>
          <div className='col-md-6'></div>
          <div className='col-md-3'>
            <SubmitButton
              label='Reset'
              icon='icon-refresh'
              style={{
                width: '100%',
                color: '#53627c',
                backgroundColor: '#fff',
                border: '1px solid #e1e4e8',
              }}
              onClick={this.reset}
            />
          </div>
          <div className='col-md-3'>
            <Button
              // ooutput
              // pristine true  true  false true
              // imagepri false true  true  false
              // output   true  true  true  false
              disabled={pristine && imagePristine ? submitting || pristine || imagePristine : false}
              type='submit'
              form='edit-profile-form'
              className='submit-btn-p'
            >
              <i className='icon-check mr-2' /> Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.auth.user,
    user: state.auth.user,
    loading: state.auth.spinner,
  };
}

Profile = reduxForm({ form: 'edit-profile-form', enableReinitialize: true })(Profile);
export default connect(mapStateToProps, actions)(Profile);
