import React, { Component } from 'react';

import './NotFound.scss';

export default class NotFound extends Component {
  render() {
    return (
      <div className='NotFound'>
        <h1>404</h1>
        <h2>Sorry, this page isn't available</h2>
        <h3>The link followed may be broken, or the page may have been removed</h3>
      </div>
    );
  }
}
