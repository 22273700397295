import { CSSProperties } from 'react';
import { IconData } from './icon-data';

export enum AvatarShapeEnum {
  Circle = 'circle',
  Square = 'square',
}

export interface AvatarData {
  shape: AvatarShapeEnum;
  tooltip?: string;
  src?: string;
  icon?: IconData;
  size?: number;
  style?: CSSProperties;
}
