import {
  CURRENCIES_LOADED,
  DOMAINS_LOADED,
  COUNTRIES_LOADED,
  ACTIVITIES_LOADED,
  CURRENCY_CONVERTER,
  DOWNLOADING,
  DOWNLOADING_FAILED,
  DOWNLOADING_SUCCESS,
  CLEARBIT_LOADING,
  CLEARBIT_COMPANIES,
  CLEARBIT_LOADING_FAILED,
  DISCARD_MESSAGES,
  IMPORTING_EXCEL,
  EXCEL_IMPORTED_SUCCESSFULLY,
  EXCEL_IMPORTED_FAILED,
} from 'ReduxActions/utilActions';

const initialState = {
  download: false,
  importing: false,
  error: null,
  message: null,
  currencies: [],
  countries: [],
  domains: [],
  activities: [],
  conversions: null,
  companies: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CURRENCIES_LOADED:
      return {
        ...state,
        currencies: action.payload,
      };

    case COUNTRIES_LOADED:
      return {
        ...state,
        countries: action.payload,
      };

    case DOMAINS_LOADED:
      return {
        ...state,
        domains: action.payload,
      };

    case ACTIVITIES_LOADED:
      return {
        ...state,
        activities: action.payload,
      };

    case CURRENCY_CONVERTER:
      return {
        ...state,
        conversions: action.payload,
      };

    case DOWNLOADING:
      return {
        ...state,
        download: action.payload,
        message: null,
        error: null,
      };

    case DOWNLOADING_SUCCESS:
      return {
        ...state,
        download: false,
        message: action.payload,
      };

    case DOWNLOADING_FAILED:
      return {
        ...state,
        download: false,
        error: action.error,
      };

    case DISCARD_MESSAGES:
      return {
        ...state,
        error: null,
        message: null,
      };

    case IMPORTING_EXCEL:
      return {
        ...state,
        importing: action.payload,
      };

    case EXCEL_IMPORTED_SUCCESSFULLY:
      return {
        ...state,
        error: action.payload.exceptions,
        message: action.payload.message,
      };

    case EXCEL_IMPORTED_FAILED:
      return {
        ...state,
        error: action.payload,
        message: null,
      };

    case CLEARBIT_LOADING:
      return {
        ...state,
        companies: [],
        loading: true,
      };

    case CLEARBIT_COMPANIES:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };

    case CLEARBIT_LOADING_FAILED:
      return {
        ...state,
        companies: [],
        loading: false,
      };
  }

  return state;
}
