import React, { Component } from 'react';

import './TableCard.scss';
import { numberFormatter } from '../../../../utils/objectUtils';

export default class TableCard extends Component {
  getColumns(item) {
    if (!item) return [];
    return item.data ? item.data.axes : [];
  }

  getRows(item) {
    let series = [];
    item.data.data.forEach(category => {
      category.metrics.forEach(metric => {
        if (!metric.hide) series.push(metric);
      });
    });
    return series;
  }

  aggregationTitle(type) {
    switch (type) {
      case 1:
        return 'Sum';
      case 2:
        return 'Max';
      case 3:
        return 'Min';
      case 4:
        return 'Average';
      default:
        return 'Total';
    }
  }

  aggregationValue(type, metrics, index) {
    switch (type) {
      case 1:
        return this.calculSum(metrics, index);
        break;
      case 2:
        return this.calculMax(metrics, index);
        break;
      case 3:
        return this.calculMin(metrics, index);
        break;
      case 4:
        return this.calculAverage(metrics, index);
        break;
      default:
        return this.calculSum(metrics, index);
        break;
    }
  }

  metricValue(list, index) {
    if (list.length && list[index] && Array.isArray(list)) {
      return list[index].values;
    }
    return [];
  }

  calculSum(metrics, index) {
    let sum = 0;
    let list = this.metricValue(metrics, index);
    sum = list.reduce((a, b) => a + b.value, 0);
    return sum.toFixed(2);
  }

  calculAverage(metrics, index) {
    const avg = this.calculSum(metrics, index) / metrics.length;
    return avg.toFixed(2);
  }

  calculMin(metrics, index) {
    let values = this.metricValue(metrics, index).map(v => v.value);
    return Math.min(...values).toFixed(2);
  }

  calculMax(metrics, index) {
    let values = this.metricValue(metrics, index).map(v => v.value);
    return Math.max(...values).toFixed(2);
  }

  render() {
    const item = this.props.item;
    const properties = item.properties;
    const rows = this.getRows(item);
    const columns = this.getColumns(item);
    return (
      <div className='TableCard'>
        {item.hasHeader && (
          <div style={{ backgroundColor: item.properties.header.backgroundColor }}>
            <div className='text-element' dangerouslySetInnerHTML={{ __html: item.header }} />
          </div>
        )}
        <table
          id={`${this.props.mode}-${item.id}`}
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'scroll',
            overflowX: 'scroll',
            display: 'block',
            tableLayout: 'auto',
            borderCollapse: 'collapse',
            backgroundColor: properties.color.backgroundColor,
          }}
        >
          <thead>
            <tr
              style={{
                borderBottom: '2px solid #e9e9e9',
                textTransform: 'uppercase',
              }}
            >
              <th
                style={{
                  textAlign: item.data_align,
                  paddingLeft: '20',
                  width: '100%',
                  padding: '5px 10px',
                }}
              />
              {columns.map((colName, index) => (
                <th
                  key={`${colName}_${index + 1}`}
                  style={{
                    textAlign: item.data_align,
                    paddingLeft: '20',
                    width: '100%',
                    padding: '5px 10px',
                  }}
                >
                  {colName}
                </th>
              ))}
              {item.aggregation && <th className='aggregation-field'>{this.aggregationTitle(item.aggregation_type)}</th>}
            </tr>
          </thead>
          <tbody style={{ width: '100%' }}>
            {rows.map((row, idx) => (
              <tr
                style={{
                  textAlign: item.data_align,
                  paddingBottom: '18',
                  paddingTop: '18',
                  paddingLeft: '20',
                  borderBottom: '1px solid #e9e9e9',
                  width: '100%',
                }}
                key={`${row.name}_${(idx + 1) * 3}`}
              >
                <td
                  style={{
                    width: '100%',
                    padding: '5px 10px',
                    fontWeight: 'bold',
                  }}
                  key={row.name}
                >
                  {row.name}
                </td>
                {row.values.map((col, index) => (
                  <td style={{ width: '100%', padding: '5px 10px', color: Number(col.value) < 0 ? 'red' : 'black' }} key={`${col.value}_${index + idx + 1}`}>
                    {numberFormatter(Number(col.value))}
                  </td>
                ))}
                {item.aggregation && <td className='aggregation-field'>{this.aggregationValue(item.aggregation_type, rows, idx)}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
