import { IMap } from '../../utils/map-utils';

export enum FileTypeEnum {
  Bmp = 'bmp',
  Doc = 'doc',
  Eps = 'eps',
  Gif = 'gif',
  Iso = 'iso',
  Jpg = 'jpg',
  Mpg = 'mpg',
  Pdf = 'pdf',
  Png = 'png',
  Ppt = 'ppt',
  Svg = 'svg',
  Txt = 'txt',
  Wmv = 'wmv',
  Xls = 'xls',
  Xml = 'xml',
  Zip = 'zip',
  File = 'file',
}

export namespace FileTypeEnum {
  export const getImage: IMap<string | FileTypeEnum, string> = new IMap<string | FileTypeEnum, string>(
    [
      [FileTypeEnum.Zip, require('Images/files/zip')],
      [FileTypeEnum.Doc, require('Images/files/doc')],
      [FileTypeEnum.Png, require('Images/files/png')],
      [FileTypeEnum.Gif, require('Images/files/gif')],
      [FileTypeEnum.Xml, require('Images/files/xml')],
      [FileTypeEnum.Xls, require('Images/files/xls')],
      [FileTypeEnum.Ppt, require('Images/files/ppt')],
      [FileTypeEnum.Svg, require('Images/files/svg')],
      [FileTypeEnum.Txt, require('Images/files/txt')],
      [FileTypeEnum.Jpg, require('Images/files/jpg')],
      [FileTypeEnum.Pdf, require('Images/files/pdf')],
    ],
    require('Images/files/file'),
  );
}
