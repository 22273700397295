import classNames from 'classnames';
import React, { Component } from 'react';
import { Link } from 'react-router';

import './SubTabs.scss';
import { Mixpanel } from 'App/app';

export default class SubTabs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='SubTabs'>
        <div className='content-header' style={this.props.styles}>
          {this.props.items.map(item => {
            const locationPath = window.location.pathname;
            const itemFullRoute = this.props.root + item.route;

            const active = (item.route === '/' && new RegExp(`${this.props.root}\\/?$`, 'i').test(locationPath)) || (item.route !== '/' && locationPath.startsWith(itemFullRoute));

            const classes = classNames({ active });

            return (
              <div className='border-tabs' key={item.label} style={{ borderBottom: active && !this.props.hideLabel && '3px solid #03a6ff' }}>
                <Link
                  className={classes}
                  key={item.route}
                  to={itemFullRoute}
                  onClick={() => {
                    this.props.onClickTab && this.props.onClickTab(item.label);
                    Mixpanel.track(`Clicked on ${item.label}`);
                  }}
                >
                  <span style={{ color: active ? '#03a6ff' : null }} data-tip={item.label}>
                    {item.icon}
                  </span>
                  {!this.props.hideLabel && <span className='ml-3'>{item.label}</span>}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
