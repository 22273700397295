import { NotificationSourceTypeEnumDTO } from '../../generated/model/notificationSourceTypeEnumDTO';
import { IMap } from '../../utils/map-utils';

export enum DashboardNotificationTypeEnum {
  Task = 1,
  Form = 2,
  User = 3,
  Field = 4,
  Event = 5,
  Stage = 6,
  ReturnRate = 7,
  GroupMetrics = 8,
  EmailTemplate = 9,
  Fund = 10,
  Note = 11,
  AngelInvDeal = 12,
  MetricGroup = 13,
  FormInProgress = 14,
  DealVote = 15,
  Email = 16,
  ContactDeal = 17,
  Contact = 18,
  File = 19,
  Deal = 20,
  Report = 21,
  LPs = 22,
  Round = 23,
  Pipeline = 24,
}

export namespace DashboardNotificationTypeEnum {
  export const convertFromApiValue: IMap<NotificationSourceTypeEnumDTO, DashboardNotificationTypeEnum> = new IMap<NotificationSourceTypeEnumDTO, DashboardNotificationTypeEnum>([
    [NotificationSourceTypeEnumDTO.Task, DashboardNotificationTypeEnum.Task],
    [NotificationSourceTypeEnumDTO.Form, DashboardNotificationTypeEnum.Form],
    [NotificationSourceTypeEnumDTO.User, DashboardNotificationTypeEnum.User],
    [NotificationSourceTypeEnumDTO.Field, DashboardNotificationTypeEnum.Field],
    [NotificationSourceTypeEnumDTO.Event, DashboardNotificationTypeEnum.Event],
    [NotificationSourceTypeEnumDTO.Stage, DashboardNotificationTypeEnum.Stage],
    [NotificationSourceTypeEnumDTO.ReturnRate, DashboardNotificationTypeEnum.ReturnRate],
    [NotificationSourceTypeEnumDTO.GroupMetrics, DashboardNotificationTypeEnum.GroupMetrics],
    [NotificationSourceTypeEnumDTO.Emailtpl, DashboardNotificationTypeEnum.EmailTemplate],
    [NotificationSourceTypeEnumDTO.Fund, DashboardNotificationTypeEnum.Fund],
    [NotificationSourceTypeEnumDTO.Note, DashboardNotificationTypeEnum.Note],
    [NotificationSourceTypeEnumDTO.AngelInvDeal, DashboardNotificationTypeEnum.AngelInvDeal],
    [NotificationSourceTypeEnumDTO.MetricGroup, DashboardNotificationTypeEnum.MetricGroup],
    [NotificationSourceTypeEnumDTO.FormInProgress, DashboardNotificationTypeEnum.FormInProgress],
    [NotificationSourceTypeEnumDTO.DealVote, DashboardNotificationTypeEnum.DealVote],
    [NotificationSourceTypeEnumDTO.Email, DashboardNotificationTypeEnum.Email],
    [NotificationSourceTypeEnumDTO.ContactDeal, DashboardNotificationTypeEnum.ContactDeal],
    [NotificationSourceTypeEnumDTO.Contact, DashboardNotificationTypeEnum.Contact],
    [NotificationSourceTypeEnumDTO.File, DashboardNotificationTypeEnum.File],
    [NotificationSourceTypeEnumDTO.Deal, DashboardNotificationTypeEnum.Deal],
    [NotificationSourceTypeEnumDTO.Report, DashboardNotificationTypeEnum.Report],
    [NotificationSourceTypeEnumDTO.LPs, DashboardNotificationTypeEnum.LPs],
    [NotificationSourceTypeEnumDTO.Round, DashboardNotificationTypeEnum.Round],
    [NotificationSourceTypeEnumDTO.Pipeline, DashboardNotificationTypeEnum.Pipeline],
  ]);
  export const convertToApiValue: IMap<DashboardNotificationTypeEnum, NotificationSourceTypeEnumDTO> = new IMap<DashboardNotificationTypeEnum, NotificationSourceTypeEnumDTO>([
    [DashboardNotificationTypeEnum.Task, NotificationSourceTypeEnumDTO.Task],
    [DashboardNotificationTypeEnum.Form, NotificationSourceTypeEnumDTO.Form],
    [DashboardNotificationTypeEnum.User, NotificationSourceTypeEnumDTO.User],
    [DashboardNotificationTypeEnum.Field, NotificationSourceTypeEnumDTO.Field],
    [DashboardNotificationTypeEnum.Event, NotificationSourceTypeEnumDTO.Event],
    [DashboardNotificationTypeEnum.Stage, NotificationSourceTypeEnumDTO.Stage],
    [DashboardNotificationTypeEnum.ReturnRate, NotificationSourceTypeEnumDTO.ReturnRate],
    [DashboardNotificationTypeEnum.GroupMetrics, NotificationSourceTypeEnumDTO.GroupMetrics],
    [DashboardNotificationTypeEnum.EmailTemplate, NotificationSourceTypeEnumDTO.Emailtpl],
    [DashboardNotificationTypeEnum.Fund, NotificationSourceTypeEnumDTO.Fund],
    [DashboardNotificationTypeEnum.Note, NotificationSourceTypeEnumDTO.Note],
    [DashboardNotificationTypeEnum.AngelInvDeal, NotificationSourceTypeEnumDTO.AngelInvDeal],
    [DashboardNotificationTypeEnum.MetricGroup, NotificationSourceTypeEnumDTO.MetricGroup],
    [DashboardNotificationTypeEnum.FormInProgress, NotificationSourceTypeEnumDTO.FormInProgress],
    [DashboardNotificationTypeEnum.DealVote, NotificationSourceTypeEnumDTO.DealVote],
    [DashboardNotificationTypeEnum.Email, NotificationSourceTypeEnumDTO.Email],
    [DashboardNotificationTypeEnum.ContactDeal, NotificationSourceTypeEnumDTO.ContactDeal],
    [DashboardNotificationTypeEnum.Contact, NotificationSourceTypeEnumDTO.Contact],
    [DashboardNotificationTypeEnum.File, NotificationSourceTypeEnumDTO.File],
    [DashboardNotificationTypeEnum.Deal, NotificationSourceTypeEnumDTO.Deal],
    [DashboardNotificationTypeEnum.Report, NotificationSourceTypeEnumDTO.Report],
    [DashboardNotificationTypeEnum.LPs, NotificationSourceTypeEnumDTO.LPs],
    [DashboardNotificationTypeEnum.Round, NotificationSourceTypeEnumDTO.Round],
    [DashboardNotificationTypeEnum.Pipeline, NotificationSourceTypeEnumDTO.Pipeline],
  ]);
}
