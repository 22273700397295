import { MeetingInfoDTO } from '../../generated/model/meetingInfoDTO';

export interface MeetingInfoData {
  id: string;
  started: boolean;
}

export namespace MeetingInfoData {
  export function mapFromApi(meeting: MeetingInfoDTO): MeetingInfoData {
    return {
      id: meeting.meeting_id.toString(),
      started: meeting.meeting_start,
    };
  }
}
