import React from 'react';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import StepsContainer from './StepsContainer';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './FormGenerator.scss';
export default class FormGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
    };
  }

  showPreview() {
    this.setState({
      previewVisible: true,
    });
    if (this.props.type === 'Update') {
      gaEvent(appCategories.form_editor_edit, appActions(appTypes.preview, appEvents.clicked).button);
    } else {
      gaEvent(appCategories.form_editor, appActions(appTypes.preview, appEvents.clicked).button);
    }
  }

  closePreview() {
    this.setState({
      previewVisible: false,
    });
  }

  onClickCancel() {
    this.props.removeForm();
    if (this.props.type === 'Update') {
      gaEvent(appCategories.form_editor_edit, appActions(appTypes.cancel, appEvents.clicked).button);
    } else {
      gaEvent(appCategories.form_editor, appActions(appTypes.cancel, appEvents.clicked).button);
    }
  }

  onClickCancelIconDialogBox() {
    this.closePreview();
    if (this.props.type === 'Update') {
      gaEvent(appCategories.form_editor_edit, appActions(`${appTypes.preview}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
    } else {
      gaEvent(appCategories.form_editor, appActions(`${appTypes.preview}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
    }
  }

  onClickCancelDialogBox() {
    this.closePreview();
    if (this.props.type === 'Update') {
      gaEvent(appCategories.form_editor_edit, appActions(`${appTypes.preview}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    } else {
      gaEvent(appCategories.form_editor, appActions(`${appTypes.preview}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    }
  }

  save() {
    this.props.onSave();
    if (this.props.type === 'Update') {
      gaEvent(appCategories.form_editor_edit, appActions(appTypes.update, appEvents.clicked).button);
    } else {
      gaEvent(appCategories.form_editor, appActions(appTypes.save, appEvents.clicked).button);
    }
  }

  render() {
    let modalClass = 'modal';
    if (this.state.previewVisible) {
      modalClass += ' show';
    }
    return (
      <div className='clearfix m-3'>
        <div className='display-flex-end'>
          <div className=''>
            <SubmitButton
              label='Reset'
              icon='icon-close'
              style={{
                width: '150px',
                color: '#53627c',
                backgroundColor: '#fff',
                border: '1px solid #e1e4e8',
              }}
              onClick={() => this.onClickCancel()}
            />
          </div>
          <div className='ml-3'>
            <SubmitButton
              label='Preview'
              icon='icon-eye'
              style={{
                width: '150px',
                backgroundColor: '#06d26f',
              }}
              onClick={this.showPreview.bind(this)}
            />
          </div>
          <div className='ml-3'>
            <SubmitButton
              label={this.props.type}
              icon='icon-check'
              style={{ width: '150px' }}
              onClick={() => {
                this.save();
              }}
            />
          </div>
        </div>

        {this.state.previewVisible && (
          <DialogBox isVisible onClickOut={this.closePreview.bind(this)} onClose={() => this.onClickCancelIconDialogBox()} className='small-dialog view-modal' withoutClose={true}>
            <StepsContainer
              steps={this.props.steps}
              isMultiSteps={Object.keys(this.props.steps).length > 1}
              saveDataForm={() => {}}
              countries={[]}
              language={this.props.language}
            />
            <div className='modal-footer'>
              <SubmitButton
                label='Cancel'
                icon='icon-close'
                style={{
                  width: '150px',
                  color: '#53627c',
                  backgroundColor: '#fff',
                  border: '1px solid #e1e4e8',
                }}
                onClick={() => this.onClickCancelDialogBox()}
              />
            </div>
          </DialogBox>
        )}
      </div>
    );
  }
}
