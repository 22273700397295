import React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

class SortableContainer extends React.Component {
  render() {
    return <div className='Sortable'>{this.props.items}</div>;
  }
}

SortableContainer = DragDropContext(HTML5Backend)(SortableContainer);
export default SortableContainer;
