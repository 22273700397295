import React, { useEffect, Fragment } from 'react';
import PieChartItem from '../PieChartItem';
import BarChartItem from '../BarChartItem/SimpleBarChart';
import { FunnelChart } from '../../../pipeline/PipelineDashboard/PipelineDashboardWrapper/FunnelChart';

const PipelineCard = ({ item, mode }) => {
  return (
    <Fragment>
      {item.chartType === 'bar_chart' && <BarChartItem item={item} mode={mode} />}
      {item.chartType === 'pie_chart' && <PieChartItem item={item} mode={mode} />}
      {item.chartType === 'funnel_chart' && <FunnelChart item={item} mode={mode} />}
    </Fragment>
  );
};

export default PipelineCard;
