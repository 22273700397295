import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Card, CardHeader, CardBody, Alert } from 'reactstrap';
import { reduxForm, FieldArray } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import { loadCompanyCustomFields, updateCompanyCustomFields, createCustomField, deleteCustomField, updateCustomField } from 'ReduxActions/settingActions';
import Spinner from 'SharedComponent/Spinner';
import ConfirmModal from 'SharedComponent/ConfirmModal';
import CustomiseFields from '../CustomiseFields';
import CustomRow from '../CustomRow';
import { ALL_SECTIONS } from 'Enums/customFields';

import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';
import { Permissible as PermissibleV2 } from '../../../../v2.0/shared/components';

import { Permissible } from 'SharedComponent/permissions';
import { CATEGORIES } from 'Enums/permissions';

import './CustomFields.scss';
import { Mixpanel } from 'App/app';

const actions = { loadCompanyCustomFields, updateCompanyCustomFields, createCustomField, deleteCustomField, updateCustomField, Permissible };

class CustomFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addField: false,
      customField: null,
      confrim: false,
    };
  }

  componentWillMount() {
    // this.props.loadCompanyCustomFields();
  }

  onSubmit(values) {
    Mixpanel.track('Clicked on Save custom fields');
    this.props.updateCompanyCustomFields(values);
  }

  onCloseModal() {
    this.setState({ addField: false, confrim: false, customField: null });
  }

  onEdit(item, section) {
    const customField = { ...item, section };
    this.setState({ customField, addField: true });
  }

  onRemove(customField) {
    this.setState({ customField, confrim: true });
  }

  render() {
    const { handleSubmit, submitting, customFields } = this.props;
    // const VC_SALES = this.props.Permissible([], [CATEGORIES[1], CATEGORIES[6]], true);
    const VC = this.props.Permissible([], [CATEGORIES[1]]);
    const WEALTH = this.props.Permissible([], [CATEGORIES[5]]);
    const SALES = this.props.Permissible([], [CATEGORIES[6]]);

    const sections = [ALL_SECTIONS['CONTACT']];
    const blocks = [{ name: 'contact', label: 'Contact custom fields' }];

    if (VC || SALES) {
      blocks.unshift({ name: 'pipeline', label: 'Pipeline custom fields' });
      sections.unshift(ALL_SECTIONS['PIPELINE']);
    }
    if (VC || WEALTH) {
      blocks.unshift({ name: 'portfolio', label: 'Portfolio custom fields' });
      sections.unshift(ALL_SECTIONS['PORTFOLIO']);
    }

    const alertMessage = `You can enable and disable the fields you want,
      to have a form that fits your need in
      ${VC ? 'Pipeline, Portfolio' : SALES ? 'Pipeline' : 'Portfolio'} and Contact section.`;

    return (
      <div className='CustomFields'>
        {this.props.loading ? (
          <Spinner isLoading={this.props.loading} />
        ) : (
          <form className='validate-form' onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            <Alert color='primary' className='mt-3 text-center'>
              {alertMessage}
            </Alert>
            <div className='display-flex-between'>
              <ReactTooltip />
              <div className='list-pipelines-text mr-5'>List of custom fields:</div>
              <div className='display-flex-center'>
                <i className='material-icons icons-details mr-2' onClick={() => this.props.reset()} data-tip='Reset last changes'>
                  settings_backup_restore
                </i>
                <PermissibleV2 can={PermissionTypeEnum.CreateCustomField}>
                  <i className='material-icons icons-details mr-2' onClick={() => this.setState({ addField: true })} data-tip='Add new custom field'>
                    add
                  </i>
                </PermissibleV2>
                <PermissibleV2 can={PermissionTypeEnum.UpdateCustomField}>
                  <Button type='submit' className='ml-2 submit-btn-p'>
                    <i className='icon-check mr-2' />
                    Save
                  </Button>
                </PermissibleV2>
              </div>
            </div>
            {blocks.map((block, index) => (
              <FieldArray
                key={index + 1}
                name={block.name}
                label={block.label}
                component={renderItems}
                rerenderOnEveryChange={true}
                onEdit={customField => this.onEdit(customField, block.name)}
                onRemove={item => this.onRemove(item)}
              />
            ))}
          </form>
        )}
        <CustomiseFields
          show={this.state.addField}
          initialValues={this.state.customField}
          onClose={() => this.onCloseModal()}
          onCreate={this.props.createCustomField}
          onUpdate={this.props.updateCustomField}
          showSection={true}
          sections={sections}
        />
        <ConfirmModal
          show={this.state.confrim}
          title={`Delete custom field "${this.state.customField && this.state.customField.field_label}"`}
          message='Deleting the field will erase it from everywhere in your Pentugram as well as delete data stored within this field.'
          itemName=''
          onClose={() => this.onCloseModal()}
          onConfirm={() => {
            this.props.deleteCustomField(this.state.customField);
            this.onCloseModal();
          }}
        />
      </div>
    );
  }
}

const renderItems = ({ fields, label, onEdit, onRemove }) => {
  return (
    <div className='mt-3'>
      <Card>
        <CardHeader>
          <div className='row'>
            <div className='col-th-details col-md-8'>{label}</div>
            <div className='col-md-4'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='text-center'>Hide / Show</div>
                </div>
                <div className='col-md-4'>
                  <div className='text-center'>Required</div>
                </div>
                <div className='col-md-4'>
                  <div className='text-center' />
                </div>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className='p-3 card-body-details'>
          {fields.length ? (
            <div>
              {fields.map((item, index) => (
                <CustomRow key={index + 1} item={item} data={fields.get(index)} onEdit={() => onEdit(fields.get(index))} onRemove={() => onRemove(fields.get(index))} />
              ))}
            </div>
          ) : (
            <div className='col-th-details text-center'>No fields found</div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.settings.company.loading,
    // customFields: state.settings.company.customFields,
    initialValues: state.settings.company.customFields,
  };
}

CustomFields = reduxForm({ form: 'settings-custom-fields', enableReinitialize: true })(CustomFields);
export default connect(mapStateToProps, actions)(CustomFields);
