import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import TextField from 'SharedComponent/TextField';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './PipelineDialog.scss';

class PipelineDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: true,
    };
    this.closeModal = this.closeModal.bind(this);
    this.checkPipelineName = this.checkPipelineName.bind(this);
  }

  closeModal() {
    this.props.reset();
    this.setState({ disable: true });
    this.props.onDismiss();
  }

  handleAction() {
    this.props.remove(this.props.pipeline);
    this.props.isPortfolio
      ? gaEvent(appCategories.portfolio, appActions(`${appTypes.delete}_${appTypes.modal}_${appTypes.delete}`, appEvents.clicked).button)
      : gaEvent(appCategories.pipeline, appActions(`${appTypes.delete}_${appTypes.modal}_${appTypes.delete}`, appEvents.clicked).button);

    this.closeModal();
  }

  checkPipelineName(input) {
    if (this.props.pipeline.stage) {
      if (input == this.props.pipeline.title) {
        this.setState({ disable: false });
      } else this.setState({ disable: true });
    }
  }

  onCloseDialogBox() {
    this.props.isPortfolio
      ? gaEvent(appCategories.portfolio, appActions(`${appTypes.delete}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon)
      : gaEvent(appCategories.pipeline, appActions(`${appTypes.delete}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
    this.closeModal();
  }

  onClickCancelBtn() {
    this.props.isPortfolio
      ? gaEvent(appCategories.portfolio, appActions(`${appTypes.delete}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button)
      : gaEvent(appCategories.pipeline, appActions(`${appTypes.delete}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    this.closeModal();
  }

  render() {
    if (!this.props.showDetails) return null;
    const isEmpty = this.props.pipeline.stage ? false : true;
    return (
      <div className='PipelineDialog'>
        <DialogBox
          isVisible
          onClickOut={this.closeModal}
          onClose={() => this.onCloseDialogBox()}
          className='small-dialog'
          title={this.props.isPortfolio ? 'Delete Portfolio' : 'Delete Pipeline'}
        >
          {isEmpty ? (
            <div className='p-3'>
              <span className='font-size-16 font-weight-500'>
                Are you sure you want to delete the {this.props.isPortfolio ? 'portfolio' : 'pipeline'}
                <span className='font-size-16 font-weight-bold'> "{this.props.pipeline ? this.props.pipeline.title : 'N/A'}"</span>?
              </span>
            </div>
          ) : (
            <div className='p-3'>
              <span className='font-size-16 font-weight-500'>
                The {this.props.isPortfolio ? 'portfolio' : 'pipeline'}
                <span className='font-size-16 font-weight-bold'> {this.props.pipeline ? this.props.pipeline.title : 'N/A'}</span> is not empty, Are you sure you want to delete it,
                if sure type the {this.props.isPortfolio ? 'portfolio' : 'pipeline'} name to confirm.
              </span>
              <div className='mt-3'>
                <Field name={`pipeline`} component={TextField} type='text' placeholder='Pipeline name' onChange={e => this.checkPipelineName(e.target.value)} />
              </div>
            </div>
          )}
          <div className='display-flex-end'>
            <div className='m-3'>
              <SubmitButton
                label='Cancel'
                icon='icon-close'
                style={{
                  width: '150px',
                  color: '#53627c',
                  backgroundColor: '#fff',
                  border: '1px solid #e1e4e8',
                }}
                onClick={() => this.onClickCancelBtn()}
              />
            </div>
            <div className='m-3'>
              <SubmitButton
                label='Delete'
                icon='icon-trash'
                style={{
                  width: '150px',
                  opacity: !isEmpty && this.state.disable ? 0.5 : 1,
                  pointerEvents: !isEmpty && this.state.disable ? 'none' : 'auto',
                  backgroundColor: '#ff8181',
                }}
                onClick={() => this.handleAction()}
              />
            </div>
          </div>
        </DialogBox>
      </div>
    );
  }
}

PipelineDialog = reduxForm({ form: 'delete-pipeline' })(PipelineDialog);
export default PipelineDialog;
