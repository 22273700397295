import { ApiUrlsEnum } from '../helpers/api-url';
import { lazyLoad, post } from './helpers/base-http';
import { LazyLoadResponse } from '../models/queries/lazy-response.model';
import { NewsSummaryData } from '../models/news/news-summary-data';
import { NewsTagsData } from '../models/news/news-tags-data';
import { NewsTagForCreate } from '../models/news/news-tag-for-create';
import { NewsDTO } from '../generated/model/newsDTO';
import { UserNewsTagDTO } from '../generated/model/userNewsTagDTO';
import { apiUrlMatcher } from '../helpers/api-url-matcher';
import { HttpParamsType } from '../models/queries/http-params.type';
import { PaginationQuery } from '../models/queries/pagination-query.model';

export function getNewsList(params: HttpParamsType<PaginationQuery>): Promise<LazyLoadResponse<NewsSummaryData[]>> {
  return lazyLoad<NewsDTO[]>(apiUrlMatcher(ApiUrlsEnum.News), params).then(data => ({
    _meta: data._meta,
    results: data.results.map(res => NewsSummaryData.mapFromApi(res)),
  }));
}

export function getSavedNewsList(params: HttpParamsType<PaginationQuery>): Promise<LazyLoadResponse<NewsSummaryData[]>> {
  return lazyLoad<NewsDTO[]>(apiUrlMatcher(ApiUrlsEnum.SavedNews), params).then(data => ({
    _meta: data._meta,
    results: data.results.map(res => NewsSummaryData.mapFromApi(res)),
  }));
}

export function removeSavedNews(id: number): Promise<NewsSummaryData> {
  const newsId = id.toString(); // check with ahmed

  return post<NewsDTO>(apiUrlMatcher(ApiUrlsEnum.RemoveSavedNews, { newsId }), {}).then(data => NewsSummaryData.mapFromApi(data));
}

export function saveNews(id: number): Promise<NewsSummaryData> {
  const newsId = id.toString(); // check with ahmed

  return post<NewsDTO>(apiUrlMatcher(ApiUrlsEnum.SaveNews, { newsId }), {}).then(data => NewsSummaryData.mapFromApi(data));
}

export function getTagsList(params: HttpParamsType<PaginationQuery>): Promise<LazyLoadResponse<NewsTagsData[]>> {
  return lazyLoad<UserNewsTagDTO[]>(apiUrlMatcher(ApiUrlsEnum.Tags), params).then(data => ({
    _meta: data._meta,
    results: data.results.map(res => NewsTagsData.mapFromApi(res)),
  }));
}

export function removeTag(id: number): Promise<NewsTagsData> {
  const tagId = id.toString(); // check with ahmed

  return post<UserNewsTagDTO>(apiUrlMatcher(ApiUrlsEnum.RemoveTag, { tagId }), {}).then(data => NewsTagsData.mapFromApi(data));
}

export function addTag(value: NewsTagForCreate): Promise<NewsTagsData> {
  return post<UserNewsTagDTO>(apiUrlMatcher(ApiUrlsEnum.AddTag), { body: NewsTagForCreate.mapToApi(value) }).then(data => NewsTagsData.mapFromApi(data));
}
