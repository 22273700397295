import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import pentugramLogo from 'Assets/img/Gold@3x.svg';

export const CoverPage = props => (
  <div className='column first-page'>
    <div className='row'>
      <div className='col-12 text-center'>
        <img height='150' width='150' src={props.myCompany && props.myCompany.logo ? props.myCompany.logo : pentugramLogo} className='logo' />
        <Typography.Title level={2}>
          <div className='mt-5'>{props.investorName}</div>
        </Typography.Title>
      </div>
    </div>
    <Typography.Title level={4}>
      <div>Statement Of Account – {moment().format('D MMMM YYYY')}</div>
    </Typography.Title>
    <div className='footer-content'>
      <div>
        {props.myCompany && props.myCompany.name} - {props.myCompany && props.myCompany.address}
      </div>
    </div>
  </div>
);

export const EndPage = props => (
  <div className='column first-page'>
    <div className='row'>
      <div className='col-12 text-center'>
        <img height='150' width='150' src={props.myCompany && props.myCompany.logo ? props.myCompany.logo : pentugramLogo} className='logo' />
      </div>
    </div>
    <div>
      <Typography.Text strong>
        Disclaimer: <br />
      </Typography.Text>
      <Typography.Text>
        {props.myCompany && props.myCompany.name} takes no responsibility with regards to the accuracy of any information provided in this statement. This statement is prepared
        solely based on the information provided by the independent administrators of the SPVs and this is for information purposes only. If you believe any of the information
        shown in this statement is incorrect, kindly contact us advising of the correct position and the reasons for any changes.
      </Typography.Text>
    </div>
    <div className='footer-content'>
      <div>
        {props.myCompany && props.myCompany.name} - {props.myCompany && props.myCompany.address}
      </div>
    </div>
  </div>
);

export const Page = props => (
  <div className='column'>
    <header>
      <div className='row'>
        <div className='col-7'>
          <img height='50' src={props.myCompany && props.myCompany.logo ? props.myCompany.logo : pentugramLogo} className='logo' />
        </div>
      </div>
    </header>
    <section>
      <div className='text-center'>
        <Typography.Title level={4} underline={true}>
          <div>{props.title}</div>
        </Typography.Title>
      </div>
      {props.children}
    </section>
    <footer className='footer-content'>
      <div>
        {props.myCompany && props.myCompany.name} - {props.myCompany && props.myCompany.address}
      </div>
    </footer>
  </div>
);
