import React from 'react';

import { Breadcrumb as AntdBreadcrumb } from 'antd';

import './breadcrumb.component.scss';

interface BreadcrumbProps {
  title: string;
  icon: string;
}

// tslint:disable-next-line:no-any tslint:disable-next-line: variable-name
export const Breadcrumb = (props: BreadcrumbProps): any => {
  return (
    <div className='Breadcrumb'>
      <AntdBreadcrumb>
        <AntdBreadcrumb.Item>
          {props.icon && <span className={`${props.icon} breadcrumb-icon`} />}
          <span className='breadcrumb-title'>{props.title}</span>
        </AntdBreadcrumb.Item>
      </AntdBreadcrumb>
    </div>
  );
};

Breadcrumb.defaultProps = {
  title: '',
  icon: null,
};
