import React, { ReactChild, ReactChildren } from 'react';
import { Drawer as AntdDrawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '../Spinner/spinner.component';
import { DrawerIdEnum } from '../../../core/enums/entity-ids/drawer-id.enum';
import { getCurrentDrawerId, getDrawerLoading, getDrawerVisibility } from './state/drawer.selectors';
import { closeDrawer } from './state/drawer.actions';

import './drawer.component.scss';

type DrawerPlacement = 'right' | 'left' | 'bottom' | 'top';
interface DrawerProps {
  drawerId: DrawerIdEnum;
  title: string;
  closable: boolean;
  width: string | number;
  placement: DrawerPlacement;
  children: ReactChildren | ReactChild;
}

export function Drawer(props: DrawerProps): JSX.Element {
  const dispatch = useDispatch();

  const visible = useSelector(getDrawerVisibility);
  const currentDrawerId = useSelector(getCurrentDrawerId);
  const loading = useSelector(getDrawerLoading);

  const handleCancel = () => {
    dispatch(closeDrawer());
  };

  return (
    <AntdDrawer
      title={props.title}
      placement={props.placement}
      closable={props.closable}
      onClose={handleCancel}
      visible={visible && currentDrawerId === props.drawerId}
      width={props.width}
      className='SharedDrawer'
      maskClosable={false}
      drawerStyle={{ display: 'flex', flexDirection: 'column' }}
      headerStyle={{ flex: '0 0' }}
      bodyStyle={{ padding: 0, flex: '1 1' }}
    >
      <div className='shared-drawer-body'>
        {loading ? (
          <div className='spinner-shared-drawer'>
            <Spinner />
          </div>
        ) : (
          props.children
        )}
      </div>
    </AntdDrawer>
  );
}

Drawer.defaultProps = {
  placement: 'right',
  width: '30%',
  closable: true,
};
