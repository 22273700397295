import React from 'react';
import { Input } from 'antd';
import { debounce } from 'lodash';

import './search.component.scss';

interface SearchProps {
  debounceTime: number;
  loading: boolean;
  placeholder: string;
  onChange(searchText: string): void;
  onSearch?(searchText: string): void;
}

export function Search(props: SearchProps): JSX.Element {
  const debouncedSearch = debounce(props.onChange, props.debounceTime);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    debouncedSearch(event.target.value);
  }

  return (
    <div className='Search'>
      <div className='m-rem-0_75'>
        <Input.Search allowClear={true} placeholder={props.placeholder} size='large' onChange={handleChange} onSearch={props.onSearch} loading={props.loading} />
      </div>
    </div>
  );
}

Search.defaultProps = {
  debounceTime: 500,
  loading: false,
};
