import { Input } from 'antd';
import React from 'react';
import classNames from 'classnames';
import Error from '../TextField/Error';

import './NumberField.scss';

function NumberField(props) {
  const onChange = e => {
    const { value } = e.target;
    const removedText = value.replace(/\D+/g, '');
    props.input.onChange(removedText);
  };

  return (
    <div className='NumberField' style={props.style}>
      <div className='display-flex-between'>
        {props.label && (
          <div className={classNames('default-color', props.labelClass ? props.labelClass : 'input-label')}>
            {props.label}
            {props.required && (
              <span className='' style={{ color: '#ff8181' }}>
                &nbsp;&#9679;
              </span>
            )}
          </div>
        )}
        <Error
          touched={props.meta && props.meta.touched}
          error={props.meta && props.meta.error}
          warning={props.meta && props.meta.warning}
          asyncValidating={props.meta.asyncValidating}
        />
      </div>
      <Input
        {...props.input}
        addonBefore={props.addonBefore}
        addonAfter={props.addonAfter}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        prefix={props.prefix}
        suffix={props.suffix}
        onChange={onChange}
        size={props.size}
        onBlur={() => props.blur && props.blur()}
        onPressEnter={() => props.onPressEnter && props.onPressEnter()}
        ref={input => props.setFieldToBeFocused && props.setFieldToBeFocused(input)}
      />
    </div>
  );
}

NumberField.defaultProps = {
  size: 'large',
};

export default NumberField;
