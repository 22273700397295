import React, { Component } from 'react';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';

import './ConfirmModal.scss';

export default class ConfirmModal extends Component {
  render() {
    const { props } = this;
    return (
      <div className='ConfirmModal'>
        <DialogBox isVisible={props.show} onClickOut={props.onClose} onClose={props.onClose} withoutClose={true} className='small-dialog'>
          <div className='p-3'>
            <span className='font-size-20 font-weight-600'>{props.title}</span>
          </div>
          <div className='p-3'>
            <span className='font-size-16 font-weight-500'>
              {props.message}
              <span className='font-size-16 font-weight-bold'>
                {'  '}
                {props.itemName}
              </span>
            </span>
          </div>
          <div className='display-flex-end'>
            <div className='m-3'>
              <SubmitButton
                label='Cancel'
                icon='icon-close'
                style={{
                  width: '150px',
                  color: '#53627c',
                  backgroundColor: '#fff',
                  border: '1px solid #e1e4e8',
                }}
                onClick={props.onClose}
              />
            </div>
            <div className='m-3'>
              <SubmitButton
                label='Delete'
                icon='icon-trash'
                style={{
                  width: '150px',
                  backgroundColor: '#ff8181',
                }}
                onClick={() => props.onConfirm()}
              />
            </div>
          </div>
        </DialogBox>
      </div>
    );
  }
}
