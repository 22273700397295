import { createReducer, Action } from '@reduxjs/toolkit';

import { DrawerState } from './drawer-type';
import * as fromDrawerActions from './drawer.actions';

const initialDrawerState: DrawerState = {
  drawerId: null,
  visible: false,
  loading: false,
  submitted: false,
};

const _drawerReducer = createReducer(initialDrawerState, builder => {
  builder
    .addCase(fromDrawerActions.closeDrawer, () => initialDrawerState)
    .addCase(fromDrawerActions.setDrawerLoading, (state, { payload: { loading } }) => ({ ...state, loading }))
    .addCase(fromDrawerActions.setDrawerSubmitted, (state, { payload: { submitted } }) => ({ ...state, submitted }))
    .addCase(fromDrawerActions.setDrawerId, (state, { payload: { drawerId } }) => ({ ...state, drawerId, visible: true }));
});

export function drawerReducer(state: DrawerState | undefined, action: Action): DrawerState {
  return _drawerReducer(state, action);
}
