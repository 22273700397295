import { IntegrationExcelData } from '../../models/integration-excel/integration-excel-data';
import { IMap } from '../../utils/map-utils';

export enum IntegrationExcelFormNamesEnum {
  Pipeline = 1,
  Stage = 2,
  File = 3,
}

export namespace IntegrationExcelFormNamesEnum {
  export type formFieldName = keyof IntegrationExcelData;

  export const toString: IMap<IntegrationExcelFormNamesEnum, string> = new IMap<IntegrationExcelFormNamesEnum, string>([
    [IntegrationExcelFormNamesEnum.Pipeline, 'Pipeline / Portfolio'],
    [IntegrationExcelFormNamesEnum.Stage, 'stage'],
    [IntegrationExcelFormNamesEnum.File, 'File'],
  ]);

  export const formFields: IMap<IntegrationExcelFormNamesEnum, formFieldName> = new IMap<IntegrationExcelFormNamesEnum, formFieldName>([
    [IntegrationExcelFormNamesEnum.Pipeline, 'pipeline'],
    [IntegrationExcelFormNamesEnum.Stage, 'stage'],
    [IntegrationExcelFormNamesEnum.File, 'file'],
  ]);
}
