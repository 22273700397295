import { createSelector } from '@reduxjs/toolkit';

import { StateV2 } from '../../../../store/v2.type';

const drawerState = (state: StateV2) => state.v2.drawer;

export const getDrawerVisibility = createSelector(drawerState, state => state.visible);
export const getCurrentDrawerId = createSelector(drawerState, state => state.drawerId);
export const getDrawerLoading = createSelector(drawerState, state => state.loading);
export const getDrawerSubmitted = createSelector(drawerState, state => state.submitted);
