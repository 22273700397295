import { RbacRoleUpdateRequest } from '../../generated/model/rbacRoleUpdateRequest';
import { RoleSummaryData } from './role-summary-data';

export interface UpdateRoleData extends Partial<RoleSummaryData> {}

export namespace UpdateRoleData {
  export function mapToApi(role: UpdateRoleData): RbacRoleUpdateRequest {
    return {
      name: role.name,
    };
  }
}
