import React from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { bindActionCreators } from 'redux';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import HeaderBar from './header-bar';
import { utilActions } from 'ReduxActions/utilActions';
import { checkDealForm } from 'ReduxActions/formActions';
import HandleUploadFile from 'SharedComponent/HandleUploadFile';
import { AsyncSearch } from 'SharedComponent/SelectOption/FieldSelect';
import TextField from 'SharedComponent/TextField';
import AutoSearch from 'SharedComponent/AutoSearch';
import InputCustomFields from 'SharedComponent/functions/InputCustomFields';
import * as validators from 'Utils/validators';
import defaultImage from 'Assets/img/default.png';
import emptyImage from 'Assets/img/no-image.png';
import { at } from 'Utils/objectUtils';

import DealTranslation from 'Translations/deal.json';
import { convertApiValueToRTLBoolean } from 'Enums/languages';

class Deal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      isLoading: false,
      confirm: false,
      size: '',
    };

    this.initializeClearbit = this.initializeClearbit.bind(this);
    this.clearbitApi = debounce(this.clearbitApi, 500).bind(this);
  }

  componentWillMount() {
    this.props.loadDomains();
  }

  initializeClearbit(object) {
    this.props.dispatch(change('form-editor-generator', 'deal.image', object.image));
    this.setState({ image: object.image });
  }

  clearbitApi(suggest) {
    if (this.props.form_key) this.props.checkDealForm(this.props.form_key, suggest);
    this.props.searchClearbit(suggest);
  }

  render() {
    let baseClasses = 'SortableItem rfb-item';
    const language = this.props.language;
    const data = this.props.data;
    const defaultStyle = {
      border: '1px solid #edeff1',
      borderRadius: '3px',
      margin: '16px 0px 16px 0px',
    };

    return (
      <div className={classNames(baseClasses, this.props.isDragging ? 'is-placeholder' : '')} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={data.static}
              required={data.required}
            />
          </div>
        )}
        <p
          dangerouslySetInnerHTML={{
            __html: data.content,
          }}
        />
        <div className='form-group'>
          <div>
            {data.logo && (
              <div>
                <div className='deal-form-build-details'>
                  <div className='text-center input-label'>{DealTranslation[language].logo}</div>
                  <div className='picture'>
                    {this.state.isLoading ? (
                      <div className='lds-roller'>
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    ) : (
                      <img src={this.state.image || emptyImage} className='picture-src' id='wizardPicturePreview' height='100' width='100' title='' />
                    )}
                    <input type='file' id='wizard-picture' onChange={this.handleUpload} />
                    <HandleUploadFile
                      isLoading={isLoading => this.setState({ isLoading })}
                      url={image => {
                        this.props.dispatch(change('form-editor-generator', 'deal.image', image));
                        this.setState({ image });
                      }}
                    >
                      <input type='file' id='wizard-picture' onChange={this.handleUpload} />
                    </HandleUploadFile>
                  </div>
                </div>
              </div>
            )}
            <div className='mb-2'>
              <Field
                label={DealTranslation[language].name}
                name={`deal.name`}
                placeholder={DealTranslation[language].name}
                labelClass='font-size-14 font-weight-500'
                component={AutoSearch}
                available={this.props.available_deal}
                options={this.parseCompanies(this.props.clearbitCompanies)}
                loading={this.props.clearbitLoading}
                onUpdate={this.clearbitApi}
                onSelect={object => this.initializeClearbit(object)}
                validate={[validators.required]}
                rtl={convertApiValueToRTLBoolean(language)}
                hideMenuItems={true}
                fieldRequired='name'
                required
              />
            </div>
            {data.size && (
              <div className='row mb-2'>
                <div className='col-md-8'>
                  <Field
                    label={DealTranslation[language].value}
                    name={`deal.size`}
                    component={TextField}
                    type='text'
                    placeholder={DealTranslation[language].value}
                    validate={[validators.number]}
                    onChange={event => this.setState({ size: event.target.value })}
                  />
                </div>
                <div className='col-md-4'>
                  <Field
                    label={DealTranslation[language].currency}
                    name={`deal.currencyName`}
                    component={AsyncSearch}
                    placeholder={DealTranslation[language].currency}
                    rtl={convertApiValueToRTLBoolean(language)}
                    url='/currencies'
                    field='code'
                    listValue='currency_id'
                  />
                </div>
              </div>
            )}
            <div className='row'>
              {this.props.pipelineCustomFields.map(customField => {
                if (data[customField.field_name])
                  return (
                    <InputCustomFields
                      customField={{ ...customField, field_label: DealTranslation[language][customField.field_name] || customField.field_label }}
                      key={customField.field_id}
                      domains={this.props.domains}
                      users={this.parseUsers(this.props.users)}
                      classLabel=''
                      fieldArray={`deal.`}
                      rtl={convertApiValueToRTLBoolean(language)}
                    />
                  );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  parseCompanies(companies) {
    return companies.map(company => {
      return {
        name: company.name,
        image: company.logo,
        website: company.domain,
      };
    });
  }

  parseUsers(users) {
    return users.map(user => {
      return {
        value: user.id,
        label: user.profile ? user.profile.fullname : 'N/A',
        image: user.profile && user.profile.image ? user.profile.image : defaultImage,
      };
    });
  }
}

function mapStateToProps(state) {
  return {
    language: at(state, 'settings.formBuilder.editing.language') || 'en',
    currencies: state.utils.currencies,
    users: state.settings.users.all,
    domains: state.utils.domains,
    countries: state.utils.countries,
    clearbitCompanies: state.utils.companies,
    clearbitLoading: state.utils.loading,
    form_key: state.settings.formBuilder.form_key,
    available_deal: state.settings.formBuilder.available_deal,
    pipelineCustomFields: state.settings.company.pipelineCustomFields,
    contactCustomFields: state.settings.company.contactCustomFields,
  };
}

export default connect(mapStateToProps, function (dispatch, props) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        ...utilActions,
        checkDealForm,
      },
      dispatch,
    ),
  };
})(Deal);
