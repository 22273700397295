import ReactEcharts from 'echarts-for-react';
import React, { Component } from 'react';
import { numberFormatter, shortenName } from 'Utils/objectUtils';

import './PieChartItem.scss';

export default class PieChartItem extends Component {
  static defaultProps = {
    types: {
      pie_chart: { radius: '55%' },
      pipeline_card: { radius: '55%' },
      ring_graphic: { radius: ['50%', '70%'] },
    },
  };

  componentWillUnmount() {}

  onChartReady = chart => {
    chart.setOption(this.getOption());
  };

  getLoadingOption = () => {
    return {
      text: 'Loading...',
      color: '#4413c2',
      textColor: '#270240',
      maskColor: 'rgba(0, 0, 0, 0.5)',
      zlevel: 0,
    };
  };

  getTtitlePos(position, coords) {
    switch (position) {
      case 'custom':
        return { ...coords };
      case 'top_center':
        return { x: 'center' };
      case 'top_right':
        return { top: 10, right: 10 };
      case 'top_left':
        return { top: 10, left: 10 };
      case 'bottom_center':
        return { top: 'bottom', left: 'center' };
      case 'bottom_right':
        return { bottom: 10, right: 10 };
      case 'bottom_left':
        return { bottom: 10, left: 10 };
      default:
        return { x: 'center' };
    }
  }

  getLegendPos(position, coords) {
    switch (position) {
      case 'custom':
        return { ...coords };
      case 'top_center':
        return { top: 20, left: 'center' };
      case 'top_left':
        return { top: 20, left: 'left' };
      case 'top_right':
        return { top: 20, right: 'left' };
      case 'bottom_left':
        return { top: '85%', left: 'left' };
      case 'bottom_center':
        return { top: '85%', left: 'center' };
      case 'bottom_right':
        return { top: '85%', right: 'left' };
      default:
        return { left: 'left' };
    }
  }

  getOption() {
    const item = this.props.item;
    const properties = this.props.types[item.key];
    // const metric = item.items ? item.items : { axes: [], data: []};
    const legend = item.data ? item.data.map(e => e.name) : item.items ? item.items.map(e => e.name) : [];
    let option = {
      title: {
        text: item.title,
        subtext: item.description,
        ...this.getTtitlePos(item.title_position, item.titlePos),
      },

      tooltip: {
        trigger: 'item',
        formatter: data => `${data.name} :  ${numberFormatter(data.value)}`,
      },
      legend: {
        orient: item.legend_horizontal_orient ? 'horizontal' : 'vertical',
        ...this.getLegendPos(item.legend_position, item.legendPos),
        data: legend,
        ...item.legend,
        ...this.props.legend,
        textStyle: {
          align: 'right',
        },
      },
      grid: {
        top: '20%',
        left: '10%',
        right: '10%',
        bottom: '20%',
        containLabel: true,
      },
      animation: false,
      color: item.properties && item.properties.colorPalette,
      series: [
        {
          name: item.title,
          type: 'pie',
          label: {
            normal: {
              formatter: item.showPercent ? data => `${shortenName(data.name)} :  ${numberFormatter(data.value)} ` : this.props.showStat ? this.props.labelFormatter : '{b}',
              position: this.props.insideLabel ? 'inside' : 'outside',
            },
          },
          radius: properties.radius,
          center: item.center ? item.center : ['50%', '60%'],
          data: item.data ? item.data : item.items ? item.items : [],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          ...item.pieOptions,
        },
      ],
    };
    if (this.props.item.noSaveImage) return option;
    option.toolbox = {
      feature: {
        saveAsImage: {
          title: 'Save as image',
        },
      },
      right: '30px',
    };
    return option;
  }

  render() {
    let onEvents =
      this.props.mode == 'view'
        ? {}
        : {
            click: () => {},
            legendselectchanged: () => {},
          };
    return (
      <div className='PieChartItem'>
        <ReactEcharts option={{}} opts={{ renderer: 'canvas' }} theme={this.props.theme} onChartReady={this.onChartReady} onEvents={onEvents} />
      </div>
    );
  }
}
