import { createReducer, Action } from '@reduxjs/toolkit';

import { RouterState } from '../../core/models/router/router-details';
import * as fromRouteActions from './router.actions';

export const routerInitialState: RouterState = {
  url: '',
  params: {},
  routeId: undefined,
  breadCrumbs: [],
};

const createRouteReducer = createReducer<RouterState>(routerInitialState, builder => {
  builder.addCase(fromRouteActions.routerChangeSuccess, (state, { payload }): RouterState => ({ ...state, ...payload.routerState }));
});

export function routeReducer(state: RouterState | undefined, action: Action): RouterState {
  return createRouteReducer(state, action);
}
