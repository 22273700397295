import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import { Field, FieldArray, reduxForm, formValueSelector, change } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import * as validators from 'Utils/validators';
import { loadFunds } from 'ReduxActions/fundActions';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import SwitchField from 'SharedComponent/CustomSwitch/SwitchField';
import TextField from 'SharedComponent/TextField';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';
import SelectComponent from 'SharedComponent/SelectOption/SelectComponent';

import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';
import { Permissible } from '../../../../v2.0/shared/components';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './AddPipeline.scss';
import { Mixpanel } from 'App/app';
const selector = formValueSelector('add-new-pipeline');

const DragHandle = SortableHandle(() => <i className={classNames('icon-menu ml-2 pt-3 cursor-pointer')} />);

const SortableItem = SortableElement(({ stage, i, fields }) => {
  return (
    <li key={i} className='stageItem'>
      <div className='display-flex-between mb-4'>
        <div className='flex-grow-3'>
          <Field
            label='Stage Name'
            name={`${stage}.name`}
            component={TextField}
            type='text'
            placeholder="Enter stage's name"
            required
            validate={[validators.required]}
            labelClass='font-size-14 font-weight-500 label-color'
          />
        </div>
        <i className={classNames('icon-trash ml-2 pt-3 cursor-pointer', !i && 'icon-trash-hide')} onClick={() => fields.remove(i)} />
        <DragHandle />
      </div>
    </li>
  );
});

const renderStages = SortableContainer(({ fields, meta: { error, submitFailed }, onClickAddStage }) => {
  if (!fields.length) fields.push();
  return (
    <div className='stages-container p-3'>
      {fields.map((value, i) => (
        <SortableItem key={`item-${i}`} i={i} stage={value} index={i} fields={fields} />
      ))}
      <Button
        className='login-form-btn display-flex-center mb-4'
        onClick={() => {
          fields.push({});
          onClickAddStage();
        }}
        style={{
          width: '100%',
          backgroundColor: 'rgba(3, 166, 255, 0.06)',
          color: '#03a6ff',
        }}
      >
        <i className='icon-plus mr-2' />
        New Stage
      </Button>
    </div>
  );
});

class AddPipeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      online: false,
      voting: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    if (this.props.isPortfolio && this.props.isVC) {
      this.props.loadFunds();
    }
  }

  closeModal() {
    Mixpanel.track('Clicked on Cancel pipeline settings');
    this.props.reset();
    this.props.onDismiss();
  }

  submit(pipeline) {
    const formattedObject = {
      ...pipeline,
      stages: pipeline.stages.map((stage, idx) => {
        return {
          ...stage,
          stage_order: idx + 1,
        };
      }),
    };
    this.props.initialValues
      ? this.props.update(formattedObject)
      : this.props.addPipeline({
          ...formattedObject,
          is_portfolio: this.props.isPortfolio ? 1 : 0,
        });

    let gaActions = null;
    let gaCategories = null;

    if (this.props.isPortfolio) {
      gaCategories = appCategories.portfolio;
    } else {
      gaCategories = appCategories.pipeline;
    }
    if (this.props.initialValues) {
      gaActions = appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.update}`, appEvents.clicked).button;
    } else {
      gaActions = appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.save}`, appEvents.clicked).button;
    }
    gaEvent(gaCategories, gaActions);

    this.closeModal();
    this.props.reset();
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let oldFields = this.props.fields;
    let newFields = arrayMove(oldFields, oldIndex, newIndex);
    const reordredStages = newFields.map((stage, index) => {
      return {
        ...stage,
        stage_order: index + 1,
      };
    });
    this.props.dispatch(change('add-new-pipeline', `stages`, reordredStages));
  };

  onCloseDialogBox() {
    let gaActions = null;
    let gaCategories = null;

    if (this.props.isPortfolio) {
      gaCategories = appCategories.portfolio;
    } else {
      gaCategories = appCategories.pipeline;
    }
    if (this.props.initialValues) {
      gaActions = appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon;
    } else {
      gaActions = appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon;
    }
    gaEvent(gaCategories, gaActions);
    this.closeModal();
  }

  onClickCancelBtn() {
    let gaActions = null;
    let gaCategories = null;

    if (this.props.isPortfolio) {
      gaCategories = appCategories.portfolio;
    } else {
      gaCategories = appCategories.pipeline;
    }
    if (this.props.initialValues) {
      gaActions = appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button;
    } else {
      gaActions = appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button;
    }
    gaEvent(gaCategories, gaActions);
    Mixpanel.track('Clicked on Cancel pipeline settings');
    this.closeModal();
  }

  onClickAddStage() {
    let gaCategories = null;
    let gaActions = null;

    if (this.props.isPortfolio) {
      gaCategories = appCategories.portfolio;
    } else {
      gaCategories = appCategories.pipeline;
    }
    if (this.props.initialValues) {
      gaActions = appActions(`${appTypes.edit}_${appTypes.modal}_ADD_STAGE`, appEvents.clicked).button;
    } else {
      gaActions = appActions(`${appTypes.add}_${appTypes.modal}_ADD_STAGE`, appEvents.clicked).button;
    }
    gaEvent(gaCategories, gaActions);
  }

  parseUsers(users) {
    return users.map(user => ({
      id: user.id,
      label: user.profile && user.profile.fullname,
      image: user.profile && user.profile.image,
    }));
  }

  render() {
    const { initialValues, handleSubmit, isVC, submitting, pristine, isPortfolio } = this.props;

    if (!this.props.showDetails) return null;
    return (
      <div className='AddPipeline'>
        <DialogBox
          isVisible
          onClickOut={this.closeModal}
          onClose={() => this.onCloseDialogBox()}
          className='small-dalog'
          title={initialValues ? (isPortfolio ? 'Update Portfolio' : 'Update Pipeline') : isPortfolio ? 'New Portfolio' : 'New Pipeline'}
        >
          <div className='dialog-container'>
            <form className='' onSubmit={handleSubmit(this.submit)}>
              <div className='mb-3'>
                <Field
                  label={isPortfolio ? 'Portfolio Name' : 'Pipeline Name'}
                  name={`name`}
                  component={TextField}
                  type='text'
                  placeholder={isPortfolio ? "Enter portfolio's name" : "Enter pipeline's name"}
                  required
                  validate={[validators.required]}
                  labelClass='font-size-14 font-weight-500 label-color'
                />
              </div>
              {isPortfolio && isVC && (
                <div className='mb-3'>
                  <div className='display-flex-center'>
                    <Field
                      label='Link to fund'
                      placeholder='Select fund'
                      labelClass='font-size-14 font-weight-500 label-color'
                      type='text'
                      name={`fund_id`}
                      multi={false}
                      withImage={false}
                      component={FieldSelect}
                      options={this.props.funds}
                      onLoad={() => {}}
                      labelKey='fund_name'
                      valueKey='fund_id'
                    />
                  </div>
                </div>
              )}
              <div className='mb-2'>
                <Field
                  label={`Private ${isPortfolio ? 'Portfolio' : 'Pipeline'} ?`}
                  labelClass='font-size-14 font-weight-500 label-color ml-2'
                  name='is_private'
                  component={SwitchField}
                  format={value => (value ? true : false)}
                />
              </div>
              {this.props.isPrivate && (
                <div className='mb-2'>
                  <Field
                    name='access_user_ids'
                    component={SelectComponent}
                    options={this.parseUsers(this.props.users)}
                    placeholder={`Link users to ${isPortfolio ? 'Portfolio' : 'Pipeline'}`}
                    notFoundContent='No users found'
                  />
                </div>
              )}
              <FieldArray
                name='stages'
                component={renderStages}
                props={{
                  onSortEnd: this.onSortEnd,
                  useDragHandle: true,
                }}
                onClickAddStage={() => this.onClickAddStage()}
              />
              <div className='display-flex-end'>
                <div className='m-3'>
                  <SubmitButton
                    label='Cancel'
                    icon='icon-close'
                    style={{
                      width: '150px',
                      color: '#53627c',
                      backgroundColor: '#fff',
                      border: '1px solid #e1e4e8',
                    }}
                    onClick={() => {
                      this.onClickCancelBtn();
                      Mixpanel.track('Clicked on Cancel pipeline settings');
                    }}
                  />
                </div>
                <div className='m-3'>
                  <Button
                    disabled={submitting || pristine}
                    type='submit'
                    onClick={() => Mixpanel.track(`Clicked on ${initialValues ? 'UPDATE' : 'SAVE'} ${isPortfolio ? 'Portifolio' : 'Pipeline'} settings`)}
                    className='submit-btn-p'
                  >
                    <i className='icon-check mr-2' />
                    {initialValues ? 'UPDATE' : 'SAVE'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </DialogBox>
      </div>
    );
  }
}

AddPipeline = reduxForm({ form: 'add-new-pipeline', enableReinitialize: true })(AddPipeline);

AddPipeline = connect(
  (state, ownProps) => {
    return {
      initialValues: ownProps.edit ? state.pipeline.default || state.pipeline.defaultHeader : state.settings.pipelines.editing,
      funds: state.settings.funds.all,
      users: state.settings.users.all,
      fields: selector(state, 'stages'),
      isPrivate: selector(state, 'is_private'),
    };
  },
  { loadFunds },
)(AddPipeline);

export default AddPipeline;
