import React, { useState, useCallback, useEffect } from 'react';
import { updateItem, updateTools } from 'ReduxActions/reportActions';
import { useDispatch, useSelector } from 'react-redux';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './Figure.scss';
import get from 'lodash/get';
import { blobToBase64 } from 'Utils/objectUtils';

const Figure = ({ item, isEdit, isNavigationSideBar }) => {
  let dispatch = useDispatch();
  let isCropping = !isEdit ? false : useSelector(state => get(state, 'reports.tools.isCropping')) === item.id;
  let filters = get(item, 'imgStyle.filter', {});
  let transforms = get(item, 'imgStyle.transform', {});
  let borderRadius = get(item, 'imgStyle.borderRaduis', 0);
  let prevUrl = item.prevUrl;
  let [crop, setCrop] = useState({ unit: 'px', width: 50, height: 50 });
  let filter = '';
  let transform = '';
  for (let f in filters) {
    if (f === 'hue-rotate') filter += `${f}(${filters[f]}deg) `;
    else filter += `${f}(${filters[f]}%) `;
  }
  for (let t in transforms) {
    if (t === 'rotate') transform = `${t}(${transforms[t]}deg) `;
    else transform = `${t}(${transforms[t]}) `;
  }

  // crop
  const makeClientCrop = crop => {
    if (item.url && crop.width && crop.height) {
      createCropPreview(item.url, crop, 'newFile.jpeg');
    }
  };

  const createCropPreview = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.origin = 'anonymous';
    img.src = image;
    img.width = get(item, 'properties.size.width', '100%');
    img.height = get(item, 'properties.size.height', '100%');

    const scaleX = get(item, prevUrl ? 'properties.originalSize.height' : 'properties.originalSize.width', img.width) / img.width;
    const scaleY = get(item, prevUrl ? 'properties.originalSize.width' : 'properties.originalSize.height', img.height) / img.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    img.onload = () => {
      ctx.drawImage(img, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
      return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          blob.name = fileName;
          blobToBase64(blob, base64 => {
            dispatch(
              updateItem(item.id, {
                ...item,
                properties: {
                  ...item.properties,
                  crop: {
                    width: crop.width,
                    height: crop.height,
                  },
                },
                croppedImage: base64,
              }),
            );
          });
        }, 'image/jpg');
      });
    };
  };
  let imgClickable = item.img_link ? (isEdit ? true : isNavigationSideBar ? true : false) : true;
  return (
    <div className='Figure'>
      <div className='item-image full-hight-width'>
        {item.url ? (
          <a href={item.img_link || ''} className={imgClickable ? 'disable-link' : ''} onClick={e => imgClickable && e.preventDefault()} target='_blank'>
            <div className='  ' onMouseDown={e => isCropping && e.stopPropagation()}>
              {isCropping && (
                <ReactCrop imageStyle={{ filter: filter, transform, borderRadius }} src={item.url} crop={crop} onChange={c => setCrop(c)} onComplete={makeClientCrop} />
              )}
              {!isCropping && <img src={item.croppedImage || item.url} style={{ filter: filter, transform, borderRadius }} height='100%' width='100%' />}
              {item.title && <div className='image-legend'>{item.title}</div>}
            </div>
          </a>
        ) : (
          <div className='image-empty full-hight-width display-flex-center'>
            <i className='material-icons image-icon' style={{ fontSize: '48px' }}>
              image
            </i>
          </div>
        )}
      </div>
    </div>
  );
};

export default Figure;
