import { LOCATION_CHANGE, RouterAction } from 'react-router-redux';
import { createAction } from '@reduxjs/toolkit';

import { RouteIdEnum } from '../../core/enums/router-enums/route-id.enum';
import { RouterState } from '../../core/models/router/router-details';
import { BreadCrumbData } from '../../core/models/router/route-segment';

export const routerNavigate = createAction<{ parentId: RouteIdEnum }>('[Router] Route navigate');
export const routerBack = createAction('[Router] Route back');
export const routerForward = createAction('[Router] Route forward');

export const routerChange = createAction<RouterAction>(LOCATION_CHANGE);
export const routerChangeSuccess = createAction<{ routerState: Partial<RouterState> }>('[Router] Route change Success');
export const buildBreadCrumb = createAction<{ breadCrumbs: BreadCrumbData[] }>('[Router] Route navigate Success');
