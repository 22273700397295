import React from 'react';
import ReactDOM from 'react-dom';
import {
  Header,
  Paragraph,
  Label,
  LineBreak,
  TextInput,
  NumberInput,
  TextArea,
  Dropdown,
  Image,
  Checkboxes,
  DatePicker,
  RadioButtons,
  Rating,
  Tags,
  Signature,
  HyperLink,
  Download,
  Camera,
  Range,
} from './form-elements';
import Deal from './deal';
import Contact from './contacts';
import { Field } from 'redux-form';
import * as validators from 'Utils/validators';

class ReactForm extends React.Component {
  constructor(props) {
    super(props);
  }

  _checkboxesDefaultValue(item) {
    let defaultChecked = [];
    item.options.forEach(option => {
      defaultChecked.push(this.props.answer_data[`option_${option.key}`]);
    });
    return defaultChecked;
  }

  _isIncorrect(item) {
    let incorrect = false;
    if (item.canHaveAnswer) {
      if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
        item.options.forEach(option => {
          let $option = ReactDOM.findDOMNode(this.refs[item.field_name].refs[`child_ref_${option.key}`]);
          if ((option.hasOwnProperty('correct') && !$option.checked) || (!option.hasOwnProperty('correct') && $option.checked)) {
            incorrect = true;
          }
        });
      } else {
        let $item = null;
        if (item.element === 'Rating') {
          $item = {};
          $item.value = this.refs[item.field_name].refs[`child_ref_${item.field_name}`].state.rating;
          if ($item.value.toString() !== item.correct) {
            incorrect = true;
          }
        } else {
          if (item.element === 'Tags') {
            $item = {};
            $item.value = this.refs[item.field_name].refs[`child_ref_${item.field_name}`].state.value;
          } else if (item.element === 'DatePicker') {
            $item = {};
            $item.value = this.refs[item.field_name].state.value;
          } else {
            $item = ReactDOM.findDOMNode(this.refs[item.field_name].refs[`child_ref_${item.field_name}`]);
            $item.value = $item.value.trim();
          }

          if ($item.value.toLowerCase() !== item.correct.trim().toLowerCase()) {
            incorrect = true;
          }
        }
      }
    }
    return incorrect;
  }

  _isInvalid(item) {
    let invalid = false;
    if (item.required === true) {
      if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
        let checked_options = 0;
        item.options.forEach(option => {
          let $option = ReactDOM.findDOMNode(this.refs[item.field_name].refs[`child_ref_${option.key}`]);
          if ($option.checked) {
            checked_options += 1;
          }
        });
        if (checked_options < 1) {
          // errors.push(item.label + ' is required!');
          invalid = true;
        }
      } else {
        let $item = null;
        if (item.element === 'Rating') {
          $item = {};
          $item.value = this.refs[item.field_name].refs[`child_ref_${item.field_name}`].state.rating;
          if ($item.value === 0) {
            invalid = true;
          }
        } else {
          if (item.element === 'Tags') {
            $item = {};
            $item.value = this.refs[item.field_name].refs[`child_ref_${item.field_name}`].state.value;
          } else if (item.element === 'DatePicker') {
            $item = {};
            $item.value = this.refs[item.field_name].state.value;
          } else {
            $item = ReactDOM.findDOMNode(this.refs[item.field_name].refs[`child_ref_${item.field_name}`]);
            $item.value = $item.value.trim();
          }

          if ($item.value === undefined || $item.value.length < 1) {
            invalid = true;
          }
        }
      }
    }
    return invalid;
  }

  _getSignatureImg(item) {
    let $canvas_sig = this.refs[item.field_name].refs[`canvas_${item.field_name}`];
    let base64 = $canvas_sig.toDataURL().replace('data:image/png;base64,', '');
    let isEmpty = $canvas_sig.isEmpty();
    let $input_sig = ReactDOM.findDOMNode(this.refs[item.field_name].refs[`child_ref_${item.field_name}`]);
    if (isEmpty) {
      $input_sig.value = '';
    } else {
      $input_sig.value = base64;
    }
    return true;
  }

  handleSubmit(values) {
    console.log('handle submit', values);
    // this.props.saveDataForm(e);
  }

  validateForm() {
    let errors = [];
    let data_items = this.props.data;

    if (this.props.display_short) {
      data_items = this.props.data.filter(i => i.alternateForm === true);
    }

    data_items.forEach(item => {
      if (item.element === 'Signature') {
        this._getSignatureImg(item);
      }

      if (this._isInvalid(item)) {
        errors.push(`${item.label} is required!`);
      }

      if (this.props.validateForCorrectness && this._isIncorrect(item)) {
        errors.push(`${item.label} was answered incorrectly!`);
      }
    });

    return errors;
  }

  render() {
    let data_items = this.props.data;

    if (this.props.display_short) {
      data_items = this.props.data.filter(i => i.alternateForm === true);
    }

    data_items.forEach(item => {
      if (item.readOnly && item.variableKey && this.props.variables[item.variableKey]) {
        this.props.answer_data[item.field_name] = this.props.variables[item.variableKey];
      }
    });

    let items = data_items.map(item => {
      switch (item.element) {
        case 'Deal':
          return <Deal mutable={true} key={`form_${item.id}`} data={item} />;
        case 'Contact':
          return <Contact noImageCountries={this.props.noImageCountries} mutable={true} key={`form_${item.id}`} data={item} />;
        case 'Header':
          return <Header mutable={true} key={`form_${item.id}`} data={item} />;
        case 'Paragraph':
          return <Paragraph mutable={true} key={`form_${item.id}`} data={item} />;
        case 'Label':
          return <Label mutable={true} key={`form_${item.id}`} data={item} />;
        case 'LineBreak':
          return <LineBreak mutable={true} key={`form_${item.id}`} data={item} />;
        case 'TextInput':
          return (
            <Field
              name={`questions.${item.field_name}`}
              component={TextInput}
              ref={item.field_name}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              read_only={this.props.read_only}
              defaultValue={this.props.answer_data[item.field_name]}
              validate={[item.required ? validators.required : validators.notRequired]}
            />
          );
        case 'NumberInput':
          return (
            <Field
              ref={item.field_name}
              read_only={this.props.read_only}
              name={`questions.${item.field_name}`}
              component={NumberInput}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              defaultValue={this.props.answer_data[item.field_name]}
              validate={[item.required ? validators.required : validators.notRequired]}
            />
          );
        case 'TextArea':
          return (
            <Field
              ref={item.field_name}
              read_only={this.props.read_only}
              name={`questions.${item.field_name}`}
              component={TextArea}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              defaultValue={this.props.answer_data[item.field_name]}
              validate={[item.required ? validators.required : validators.notRequired]}
            />
          );
        case 'Dropdown':
          return (
            <Dropdown
              ref={item.field_name}
              read_only={this.props.read_only}
              handleChange={this.handleChange}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              defaultValue={this.props.answer_data[item.field_name]}
            />
          );
        case 'Checkboxes':
          return (
            <Checkboxes
              ref={item.field_name}
              read_only={this.props.read_only}
              handleChange={this.handleChange}
              name={`questions.${item.field_name}`}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              defaultValue={this._checkboxesDefaultValue(item)}
              validate={[item.required ? validators.required : validators.notRequired]}
            />
          );
        case 'DatePicker':
          return (
            <Field
              ref={item.field_name}
              read_only={this.props.read_only}
              name={`questions.${item.field_name}`}
              component={DatePicker}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              rtl={this.props.rtl}
              defaultValue={this.props.answer_data[item.field_name]}
              validate={[item.required ? validators.required : validators.notRequired]}
            />
          );
        case 'RadioButtons':
          return (
            <RadioButtons
              ref={item.field_name}
              read_only={this.props.read_only}
              handleChange={this.handleChange}
              name={`questions.${item.field_name}`}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              defaultValue={this.props.answer_data[item.field_name]}
              validate={[item.required ? validators.required : validators.notRequired]}
            />
          );
        case 'Rating':
          return (
            <Rating
              ref={item.field_name}
              read_only={this.props.read_only}
              handleChange={this.handleChange}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              defaultValue={this.props.answer_data[item.field_name]}
            />
          );
        case 'Image':
          return (
            <Image
              ref={item.field_name}
              handleChange={this.handleChange}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              defaultValue={this.props.answer_data[item.field_name]}
            />
          );
        case 'Tags':
          return (
            <Field
              ref={item.field_name}
              read_only={this.props.read_only}
              name={`questions.${item.field_name}`}
              component={Tags}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              options={item.options}
              defaultValue={this.props.answer_data[item.field_name]}
              validate={[item.required ? validators.required : validators.notRequired]}
              countries={this.props.countries}
              // labelKey={this.props.rtl ? "ar_name" : "name"}
              rtl={this.props.rtl}
            />
          );
        case 'Signature':
          return (
            <Signature
              ref={item.field_name}
              read_only={this.props.read_only || item.readOnly}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              defaultValue={this.props.answer_data[item.field_name]}
            />
          );
        case 'HyperLink':
          return <HyperLink mutable={true} key={`form_${item.id}`} data={item} />;
        case 'Download':
          return <Download download_path={this.props.download_path} mutable={true} key={`form_${item.id}`} data={item} />;
        case 'Camera':
          return (
            <Field
              ref={item.field_name}
              name={`files.${item.field_name}`}
              component={Camera}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              validate={[item.required ? validators.required : validators.notRequired]}
            />
          );
        case 'Range':
          return (
            <Range
              ref={item.field_name}
              read_only={this.props.read_only}
              handleChange={this.handleChange}
              mutable={true}
              key={`form_${item.id}`}
              data={item}
              defaultValue={this.props.answer_data[item.field_name]}
            />
          );
      }
    });

    return (
      <div>
        <div className='react-form-builder-form online-application-for-deal-print' id='online-application-for-deal-print'>
          {items}
        </div>
      </div>
    );
  }
}

ReactForm.defaultProps = { validateForCorrectness: false };

export default ReactForm;
