import { Action, createReducer } from '@reduxjs/toolkit';
import { MeData } from '../../core/models/me/me-data';

import * as fromActions from './me.actions';
export interface MeState {
  user: MeData;
}

const meInitialState: MeState = {
  user: {} as MeData,
};

const _meReducer = createReducer<MeState>(meInitialState, builder => {
  builder.addCase(fromActions.loadMeSuccess, (state, { payload: { me } }): MeState => ({ ...state, user: me }));
});

export function meReducer(state: MeState | undefined, action: Action): MeState {
  return _meReducer(state, action);
}
