import React, { Component, Fragment } from 'react';
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn';

import DropdownList from 'SharedComponent/DropdownList';
import DialogBox from 'SharedComponent/DialogBox';
import { ArrangeInputsForm } from './arrangeForm';
import _ from 'lodash';
import './CheckBoxColumn.scss';
import './Table.scss';

export function withOptionsColumn(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        dropdownOpen: false,
        showArrangeModal: false,
        columnNames: [],
        defaultColumns: [],
        initialColumns: null,
        savedColumns: null,
      };
    }

    componentDidMount() {
      // children column names doens't include the one added in render. But guess this is fine because we only need the passed column names.
      const columnNames = this.getChildrenColumnNames(_.compact(_.flatten(this.props.children)));
      this.setState({ initialColumns: columnNames.slice(0, 5) });
      let storedColumnNames = _.intersection(this.getLocalStorageValues(), columnNames);
      if (storedColumnNames.length < 1) {
        this.setState({ columnNames, defaultColumns: columnNames.slice(0, 5) });
      } else {
        for (let i = storedColumnNames.length; storedColumnNames.length < 5; i++) {
          storedColumnNames[i] = null;
        }
        this.setState({
          columnNames,
          savedColumns: storedColumnNames,
          defaultColumns: storedColumnNames,
        });
      }
    }
    // Toggle modal
    toggle = () => {
      this.setState(prevState => ({
        dropdownOpen: !prevState.dropdownOpen,
      }));
    };
    // Modal form actions to save and clear values methods.
    // Those actions go inside the form to save then close the related modaL.

    saveSubmittedValues = values => {
      this.setState({ savedColumns: values, showArrangeModal: false }, () => this.setLocalStorageValues(values));
    };

    clearStoredValues = e => {
      e.preventDefault();
      this.clearLocalStorageValues();
      this.setState({
        showArrangeModal: false,
        savedColumns: this.state.initialColumns,
        defaultColumns: this.state.initialColumns,
      });
    };

    // Column filtering related methods.
    // Here we take list of children and only show what's allowed.

    getChildrenColumnNames(children) {
      const filteredColumns = children.filter(
        child =>
          (child.props.dataField !== undefined || child.props.dataFormat !== undefined) && (child.props.hidden !== true || !child.props.isKey) && !child.props.isActionColumn,
      );
      const childrenColumnNames = filteredColumns.map(child => child.props.children);
      return childrenColumnNames;
    }

    getFirstColumns(children) {
      const childrenColumnNames = this.getChildrenColumnNames(children);
      const firstColumns = this.state.savedColumns || childrenColumnNames.slice(0, 5);
      //const firstColumns = this.state.savedColumns;
      // return children.filter(child => firstColumns.includes(child.props.children) )
      const firstColumnNodes = [];
      for (const colName of firstColumns) {
        firstColumnNodes.push(children.find(child => child.props.children === colName));
      }
      return firstColumnNodes;
    }

    filteredChildren(children) {
      // get first children
      const neededColumns = children.filter(
        child =>
          !((child.props.dataField !== undefined || child.props.dataFormat !== undefined) && (child.props.hidden !== true || !child.props.isKey) && !child.props.isActionColumn),
      );
      const firstColumns = this.getFirstColumns(children);
      return [...firstColumns, ...neededColumns];
    }

    // LocalStorage related methods
    // Here we store columns for each table and retrieve them.

    setLocalStorageValues(value) {
      localStorage.setItem(`table-option-arrange-${window.location.pathname}-${this.props.tableName ? this.props.tableName : ''}`, value.join(','));
    }

    getLocalStorageValues() {
      let values = localStorage.getItem(`table-option-arrange-${window.location.pathname}-${this.props.tableName ? this.props.tableName : ''}`);
      return values ? values.split(',') : [];
    }

    clearLocalStorageValues() {
      localStorage.setItem(`table-option-arrange-${window.location.pathname}-${this.props.tableName ? this.props.tableName : ''}`, null);
    }
    renderArrangeModal() {
      return (
        <DialogBox isVisible={this.state.showArrangeModal} className='small-dialog' onClose={() => this.setState({ showArrangeModal: false })} title='Change column order'>
          <div className='p-3'>Choose columns to show and drag to change order. </div>
          <ArrangeInputsForm
            clearStoredValues={this.clearStoredValues}
            initialValues={{ inputs: this.state.defaultColumns }}
            onSubmitValues={this.saveSubmittedValues}
            onValuesChange={values =>
              this.setState((state, props) => ({
                defaultColumns: values.inputs,
              }))
            }
            defaultColumns={this.state.defaultColumns}
            options={this.state.columnNames}
          />
        </DialogBox>
      );
    }
    render() {
      const { props } = this;
      const items = [
        {
          key: 0,
          label: 'Arrange columns',
          onClick: () => this.setState({ showArrangeModal: true }),
        },
      ];
      let filteredChildrenProp = this.filteredChildren(
        _.compact(
          _.flatten(
            props.children.concat(
              <TableHeaderColumn key='drop-down-list-key' dataAlign='right' width='13'>
                <DropdownList positionFixed items={items} icon='more_vert' />
              </TableHeaderColumn>,
            ),
          ),
        ),
      );
      return (
        <Fragment>
          <WrappedComponent {...this.props} children={filteredChildrenProp} />
          {this.renderArrangeModal()}
        </Fragment>
      );
    }
  };
}
