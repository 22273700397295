import { ApiUrlsEnum } from '../helpers/api-url';
import { apiUrlMatcher } from '../helpers/api-url-matcher';
import { get, post } from './helpers/base-http';
import { KpisSummaryData } from '../models/kpis/kpis-summary-data';
import { KpiResponseDTO } from '../generated/model/kpiResponseDTO';
import { KpisForReorder } from '../models/kpis/kpis-for-reorder';

export async function dashboardKpis(): Promise<KpisSummaryData[]> {
  const data = await get<KpiResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.ActiveKpis));

  return data.map(kpi => KpisSummaryData.mapFromApi(kpi));
}

export async function dashboardAllKpis(): Promise<KpisSummaryData[]> {
  const data = await get<KpiResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.AllKpis));

  return data.map(kpi => KpisSummaryData.mapFromApi(kpi));
}

export async function reorderKpis(list: KpisSummaryData[]): Promise<boolean> {
  await post<boolean>(apiUrlMatcher(ApiUrlsEnum.ReorderKpis), { body: list.map(kpi => KpisForReorder.mapToApi(kpi)) });

  return true;
}
