import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addUser,
  loadRoles,
  updateUser,
  discardEditing,
  deleteUsers,
  addInvestor,
  updateInvestor,
  loadInvestors,
  resendInvitations,
  investorResendInvitations,
  loadUsers,
  filterInvestors,
  addAngelInvestor,
  updateAngelInvestor,
  loadAngelInvestors,
  angelInvestorResendInvitations,
} from 'ReduxActions/settingActions';
import SubmitButton from 'SharedComponent/SubmitButton';
import { PermissibleRender, Permissible as PermissibleV1 } from 'SharedComponent/permissions';
import { ANGEL_NETWORK_ACTIVATED, WEALTH_MANAGEMENT, BILLABLE_STATUS, OWNER } from 'Enums/permissions';
import AddUser from '../AddUser';
import UsersDialog from '../UsersDialog';
import SearchBox from './SearchBox';
import { goToBilling } from './goToBilling';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import { Permissible } from '../../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';

import './HeaderUsers.scss';
import ViewButtons from '../../../contacts/ViewButtons/ViewButtons';
import AddInvestor from '../AddInvestor';
import AddAngelInvestor from '../AddAngelInvestor';
import { get } from 'lodash';

const actions = {
  addUser,
  loadRoles,
  loadInvestors,
  addInvestor,
  updateInvestor,
  loadUsers,
  discardEditing,
  updateUser,
  deleteUsers,
  resendInvitations,
  investorResendInvitations,
  filterInvestors,
  PermissibleV1,
  addAngelInvestor,
  updateAngelInvestor,
  loadAngelInvestors,
  angelInvestorResendInvitations,
};
class HeaderUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      showDialog: false,
      showAngelInvestorModal: false,
      showInvestorDetails: false,
      type: 'delete',
    };
    this.showDetails = this.showDetails.bind(this);
    this.hideDetails = this.hideDetails.bind(this);
    this.hideDialogAction = this.hideDialogAction.bind(this);
  }

  componentWillMount() {
    this.props.loadRoles();
  }

  showDetails() {
    if (this.props.selected === 'users') {
      this.setState({ showDetails: true });
    } else if (this.props.selected === 'angel-investors') {
      this.setState({ showAngelInvestorModal: true });
    } else {
      this.setState({ showInvestorDetails: true });
    }
  }

  hideDetails() {
    this.props.discardEditing(null);
    this.setState({ showDetails: false, showInvestorDetails: false, showAngelInvestorModal: false });
  }

  hideDialogAction() {
    this.setState({ showDialog: false });
  }
  viewList = isAngel => {
    const list = [
      {
        materialIcon: 'person',
        value: 'users',
        title: 'Users',
      },
      // {
      //   materialIcon: 'business_center',
      //   value: isAngel ? 'angel-investors' : 'investors',
      //   title: 'Investors',
      // },
    ];
    return list;
  };
  render() {
    const isUsersView = this.props.selected === 'users';
    const isAngelInvestorsView = this.props.selected === 'angel-investors';
    return (
      <div>
        <div className='HeaderUsers display-flex-between flex-wrap'>
          <div className='display-flex-start'>
            <div className='list-users-text mr-5'>List of {isUsersView ? 'users' : 'investors'}</div>
            {!isUsersView && !isAngelInvestorsView && (
              <div>
                <SearchBox onChange={this.props.filterInvestors} />
              </div>
            )}
          </div>
          <div className='display-flex-end flex-wrap'>
            {isUsersView && (
              <Permissible can={PermissionTypeEnum.CreateUser}>
                <div
                  className={classNames('ml-5 display-flex-center', this.props.isSelected ? 'cursor-pointer' : 'disable-button')}
                  style={{ color: '#ffbc34' }}
                  onClick={() => {
                    gaEvent(appCategories.settings_users, appActions(`${appTypes.mass}_${appTypes.resend}`, appEvents.clicked).button);
                    this.setState({
                      type: 'resend',
                      showDialog: true,
                    });
                  }}
                >
                  <span className='icon-paper-plane mr-2 icon-span-details' />
                  <span className='icon-span-text'>Resend invitation</span>
                </div>
              </Permissible>
            )}
            <div className='ml-5'>
              <PermissibleRender userPermissions={[]} requiredPermissions={[[WEALTH_MANAGEMENT]]}>
                <ViewButtons
                  options={this.viewList()}
                  selected={this.props.selected}
                  update={selected => {
                    this.props.updateView(selected);
                    if (selected === 'users') this.props.loadUsers();
                    else this.props.loadInvestors();
                  }}
                  styles={{ width: '40px', height: '40px', border: '1px solid #eaeaea', color: '#929699' }}
                  label
                  icon
                />
              </PermissibleRender>
              <PermissibleRender userPermissions={[]} requiredPermissions={[[ANGEL_NETWORK_ACTIVATED]]}>
                <ViewButtons
                  options={this.viewList(true)}
                  selected={this.props.selected}
                  update={selected => {
                    this.props.updateView(selected);
                    if (selected === 'users') this.props.loadUsers();
                    else this.props.loadAngelInvestors();
                  }}
                  styles={{ width: '40px', height: '40px', border: '1px solid #eaeaea', color: '#929699' }}
                  label
                  icon
                />
              </PermissibleRender>
            </div>

            <Permissible can={isUsersView ? PermissionTypeEnum.CreateUser : isAngelInvestorsView ? PermissionTypeEnum.CreateAngelInvestor : PermissionTypeEnum.CreateInvestor}>
              <div className='ml-2'>
                <PermissibleRender userPermissions={[]} requiredPermissions={[[BILLABLE_STATUS.NOT_BILLABLE_COMPANY]]}>
                  <SubmitButton
                    label={`New ${isUsersView ? 'User' : 'Investor'}`}
                    icon='icon-plus'
                    disabled={!this.props.PermissibleV1([], [OWNER])}
                    onClick={() => goToBilling()}
                  />
                </PermissibleRender>
                <PermissibleRender userPermissions={[]} requiredPermissions={[[BILLABLE_STATUS.BILLABLE_COMPANY]]}>
                  <SubmitButton
                    label={`New ${isUsersView ? 'User' : 'Investor'}`}
                    icon='icon-plus'
                    onClick={() => {
                      gaEvent(appCategories.settings_users, appActions(`${appTypes.mass}_${appTypes.add}`, appEvents.clicked).button);
                      this.showDetails();
                    }}
                  />
                </PermissibleRender>
              </div>
            </Permissible>
          </div>
        </div>
        <AddUser
          showDetails={this.state.showDetails || (this.props.editing && this.props.view === 'users') ? true : false}
          onDismiss={this.hideDetails}
          onSave={this.props.addUser}
          onUpdate={this.props.updateUser}
          roles={this.props.roles}
        />
        <AddInvestor
          showDetails={this.state.showInvestorDetails || (this.props.editing && this.props.view === 'investors') ? true : false}
          onDismiss={this.hideDetails}
          onSave={this.props.addInvestor}
          onUpdate={this.props.updateInvestor}
        />
        <AddAngelInvestor
          showDetails={this.state.showAngelInvestorModal || (this.props.editing && this.props.view === 'angel-investors') ? true : false}
          onDismiss={this.hideDetails}
          onSave={this.props.addAngelInvestor}
          onUpdate={this.props.updateAngelInvestor}
        />
        <UsersDialog
          type={this.state.type}
          showDialog={this.state.showDialog}
          onDismissDialog={this.hideDialogAction}
          users={this.props.rows}
          deleteUsers={this.props.deleteUsers}
          resendInvitations={isUsersView ? this.props.resendInvitations : this.props.investorResendInvitations}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    count: state.settings.users.count,
    roles: state.settings.users.roles,
    editing: state.settings.users.editing,
    angel_investor_enabled: !!get(state, 'auth.user.company.angel_investor_enabled', false),
    view: state.settings.users.view,
  };
}

export default connect(mapStateToProps, actions)(HeaderUsers);
