import React from 'react';
import { numberRoundFormatter } from 'Utils/objectUtils';
import { Table, Card } from 'antd';

import './items.scss';

export function PortfolioPanel({ distribution, frequency, currency }) {
  const columns = period => [
    {
      title: 'Entity',
      width: 200,
      dataIndex: 'name',
      render: (text, it) => (
        <span style={{ fontWeight: 600 }}>
          {text} {it.svp_name && `( ${it.svp_name} )`}
        </span>
      ),
    },
    {
      title: 'Amount Invested',
      dataIndex: 'investment_amount',
      render: (text, it) => <span>{numberRoundFormatter(text, it.currency)}</span>,
    },
    {
      title: 'Rate of return',
      dataIndex: 'return_rate',
      render: (text, it) => <span>{text} %</span>,
    },
    {
      title: `${period} distributions`,
      dataIndex: 'last_month_distribution',
      render: (text, it) => <span>{numberRoundFormatter(text, it.currency)}</span>,
    },
    {
      title: 'Cumulative distribution',
      dataIndex: 'return_per_date',
      render: (text, it) => <span>{numberRoundFormatter(text, it.currency)}</span>,
    },
  ];

  return (
    <div className='items mb-3'>
      <Card
        style={{}}
        type='inner'
        title={
          <div className='period-block'>
            All amounts in {currency} - ( {frequency} Distribution )
          </div>
        }
      >
        <Table pagination={false} bordered={true} columns={columns(frequency)} rowKey={(record, key) => key} dataSource={distribution} rowClassName='table-row-style' />
      </Card>
    </div>
  );
}
