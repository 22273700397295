import React from 'react';

export function PentugramLogo2C(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55 55' height={props.height} width={props.width}>
      <defs>
        <linearGradient x1='-1.11022302e-14%' y1='50%' x2='100%' y2='50%' id={props.id}>
          <stop stopColor={props.colors[0]} offset='0%'></stop>
          <stop stopColor={props.colors[1]} offset='100%'></stop>
        </linearGradient>
      </defs>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='pricing' transform='translate(-289.000000, -239.000000)' fill={`url(#${props.id})`} fillRule='nonzero'>
          <g id='FREE' transform='translate(196.000000, 208.000000)'>
            <g id='text' transform='translate(47.000000, 31.000000)'>
              <g id='Group' transform='translate(46.000000, 0.000000)'>
                <path
                  d='M6.93051929,45.7262886 C4.97776807,48.2228267 2.67352442,50.0994643 0.0177883295,51.3562012 C-0.00592944318,37.5827701 -0.00592944318,29.7426411 0.0177883295,27.8358142 C0.0201890131,27.6428076 0.0234968221,27.4571951 0.0277117567,27.2789769 C0.146412134,12.1928994 12.4067095,0 27.5134212,0 C42.6938395,0 55,12.3121694 55,27.5 C55,42.6878306 42.6938395,55 27.5134212,55 C19.3213057,55 11.9662381,51.4144214 6.93051929,45.7262886 Z M27.5134212,40.8247423 C34.8688816,40.8247423 40.8316604,34.8590519 40.8316604,27.5 C40.8316604,20.1409481 34.8688816,14.1752577 27.5134212,14.1752577 C20.1579609,14.1752577 14.1951821,20.1409481 14.1951821,27.5 C14.1951821,34.8590519 20.1579609,40.8247423 27.5134212,40.8247423 Z'
                  id='Blue'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

PentugramLogo2C.defaultProps = {
  height: '80px',
  width: '80px',
  colors: ['#1871D1', '#0095FF'],
  id: 'linearGradient-1',
};

export function PentugramLogo(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13' height={props.height} width={props.width}>
      <path
        fill={props.color}
        fillRule='evenodd'
        d='M180.638123,18.8080319 C180.176563,19.3981227 179.631924,19.8416916 179.004205,20.1387385 C178.998598,16.8832002 178.998598,15.0300788 179.004205,14.5793743 C179.004772,14.5337545 179.005554,14.4898825 179.00655,14.4477582 C179.034607,10.881958 181.932495,8 185.503172,8 C189.091271,8 192,10.9101491 192,14.5 C192,18.0898509 189.091271,21 185.503172,21 C183.566854,21 181.828384,20.1524996 180.638123,18.8080319 Z M185.503172,17.6494845 C187.241736,17.6494845 188.65112,16.2394123 188.65112,14.5 C188.65112,12.7605877 187.241736,11.3505155 185.503172,11.3505155 C183.764609,11.3505155 182.355225,12.7605877 182.355225,14.5 C182.355225,16.2394123 183.764609,17.6494845 185.503172,17.6494845 Z'
        transform='translate(-179 -8)'
      />
    </svg>
  );
}

PentugramLogo.defaultProps = {
  height: '80px',
  width: '80px',
  color: '#C5C9CC',
};
