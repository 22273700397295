import defaultImage from '../assets/img/default.png';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import remove from 'lodash/remove';
import findIndex from 'lodash/findIndex';
import {
  COLLAPSE_ALL_STAGES,
  LOADING_DEFAULT_PIPELINE,
  LOADING_DEFAULT_BODY,
  DEFAULT_BODY_PIPELINE_CLEARED,
  DEFAULT_PIPELINE_LOADED,
  DEFAULT_HEADER_PIPELINE_LOADED,
  DEFAULT_BODY_PIPELINE_LOADED,
  MOVE_CARD,
  MOVE_LIST,
  TOGGLE_DRAGGING,
  DEAL_LOADED,
  INVESTMENTS_LOADED,
  DEAL_LOADING,
  VOTE_LOADING,
  DEAL_LOADED_FAILED,
  PIPELINE_LIST_LOADED,
  MULTI_FILES_DEAL_CREATED,
  UPDATE_ALL_FILTERS,
  UPDATE_FILTER,
  LOADED_PIPELINE_STAGES,
  STAGES_LOADING,
  DEAL_CREATED,
  CLEAN_PIPELINE,
  DEAL_NOTIFICATIONS_LOADED,
  DEAL_NOTIFICATIONS_LOADING,
  TASKS_DEAL_LOADED,
  TASK_DEAL_CREATED,
  TASK_DEAL_UPDATED,
  TASK_DEAL_DELETED,
  DEAL_UPDATED,
  UPDATE_LIVE_DEAL,
  ALL_DEAL_LIST_LOADED,
  NOTES_DEAL_LOADED,
  EDITING_NOTE,
  NOTE_DEAL_UPDATED,
  NOTE_DEAL_LOADING,
  NOTE_DEAL_CREATED,
  SET_DEAL_NAVIGATION,
  DEAL_NAVIGATION_LOADING,
  MEETINGS_DEAL_LOADED,
  MEETING_DEAL_CREATED,
  FILES_DEAL_LOADED,
  FILE_DEAL_CREATED,
  FILE_DEAL_UPLOADIND,
  FILE_DEAL_DELETED,
  SELECTED_FILE_IDS,
  FILE_VIEW_UPDATED,
  REMOVE_BULK_FOLDERS,
  DEAL_FOLDER_UPDATED,
  EMAILS_DEAL_LOADED,
  EDITING_DEAL_TASK,
  EMAIL_DEAL_CREATED,
  DEAL_STATUS_UPDATED,
  LOADING_REOPEN_PIPELINES,
  EDITING_DEAL_EMAIL,
  EXTRA_PIPELINE_LOADED,
  DELETE_ROUND,
  INVESTMENTS_CREATED,
  ROUND_UPDATED,
  DEAL_RATED,
  INVESTORS_LOADED,
  INVESTOR_DELETED,
  INVESTOR_CREATED,
  INVESTOR_UPDATED,
  ALL_INVESTORS_LOADED,
  DEAL_RATE_CREATED,
  DEAL_CASH_OUT_CREATED,
  DEAL_RATE_UPDATED,
  DEAL_CASH_OUT_UPDATED,
  DEAL_RATE_DELETED,
  DEAL_CASH_OUT_DELETED,
  DEAL_RATES_LOADED,
  DEAL_CASHOUT_LOADED,
  PORTFOLIO_DASH_STATS_LOADING,
  PORTFOLIO_DASH_STATS_LOADED,
  PORTFOLIO_DASH_STATS_EXPORT_LOADING,
  PORTFOLIO_DASH_STATS_EXPORT_LOADED,
  ANGEL_INVESTORS_LOADED,
} from 'ReduxActions/pipelineActions';

import { replace } from 'Utils/arrayUtils.js';

const initialState = {
  loadingDealNote: false,
  collapseAll: false,
  isFetching: true,
  isloadingBody: true,
  loadingPrecent: 0,
  default: null,
  defaultHeader: {},
  defaultBody: {},
  bodyDeals: [],
  isDragging: false,
  editing: null,
  loader: false,
  filter: {
    stage_id: null,
  },
  pipelines: [],
  extra: [],
  extraPipelines: [],
  stages: [],
  dealNotifications: [],
  dealLoader: false,
  dealTasks: [],
  allDeals: [],
  dealNotes: [],
  investments: [],
  investors: [],
  rates: [],
  cash: [],
  editingNote: null,
  pageContNotes: 0,
  dealMeetings: [],
  dealFiles: [],
  navigation: {},
  navigationLoading: false,
  selectedFilesId: [],
  pathFiles: [],
  allInvestors: [],
  fileParent: 0,
  fileViewSelected: 'view-grid',
  dealFileUploading: false,
  dealEmails: [],
  editTask: null,
  editingEmail: null,
  statistics: {},
  statisticsLoading: false,
  statisticsExport: {},
  statisticsExportLoading: false,
};

const groupBy = key => array =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat({
        ...obj,
        assignee_name: obj.assignee_info ? obj.assignee_info.fullname : 'No assignee for this deal',
        assignee_image: (obj.assignee_info && obj.assignee_info.image) || defaultImage,
        image: obj.image || `https://ui-avatars.com/api/?font-size=0.7&background=fafafa&color=d4d4d4&length=1&name=${obj.name}`,
        source: obj.source || 1,
        created_at: moment(obj.created_at * 1000).format('ll'),
        size: obj.size || 0,
        total_investment: obj.total_investment || 0,
      }),
    }),
    {},
  );

function reorderStages(pipeline) {
  return {
    ...pipeline,
    stages: parsestages(orderBy(pipeline.stages, ['stage_order'], ['asc'])),
  };
}

function parsestages(stages) {
  return stages.map(stage => {
    const new_deals = stage.deals.map(deal => {
      return {
        ...deal,
        id: deal.deal_id,
      };
    });
    return {
      ...stage,
      id: stage.stage_id,
      deals: orderBy(new_deals, ['created_at'], ['desc']),
    };
  });
}

function insertIntoPipeline(deal, pipeline) {
  if (pipeline === null || deal === null) return null;
  if (deal.pipeline_id === pipeline.pipeline_id) {
    return {
      ...pipeline,
      stages: pipeline.stages.map(stage => {
        if (stage.stage_id == deal.stage_id) {
          return {
            ...stage,
            deals: [{ ...deal, updated: true, id: deal.deal_id }, ...stage.deals],
          };
        } else return stage;
      }),
    };
  } else return pipeline;
}

function updateLiveDeals(deal, deals) {
  switch (deal.pusher_action) {
    case 'create':
      return [deal, ...deals];
    case 'update':
      return replace(deals, { deal_id: deal.deal_id }, deal);
    case 'won':
    case 'lost':
    case 'delete':
      remove(deals, { deal_id: deal.deal_id });
      return deals;
    default:
      return deals;
  }
}

function removeFromPipeline(deal, pipeline) {
  return {
    ...pipeline,
    stages: pipeline.stages.map(stage => {
      return {
        ...stage,
        deals: stage.deals.filter(it => it.deal_id !== deal.deal_id),
      };
    }),
  };
}

function updateFilter(object, filter) {
  const filterd = Object.assign({}, filter);
  filterd[object.filter] = object.id;
  return filterd;
}

function parsePathFolder(folders, folder) {
  if (folder) {
    const path = findIndex(folders, { file_id: folder.file_id });
    if (path < 0) return [...folders, folder];
    folders.splice(path + 1, folders.length);
    return folders;
  }
  return [];
}

export default function lists(state = initialState, action) {
  switch (action.type) {
    case PORTFOLIO_DASH_STATS_LOADING:
      return {
        ...state,
        statisticsLoading: true,
      };
    case PORTFOLIO_DASH_STATS_LOADED:
      return {
        ...state,
        statistics: action.payload,
        statisticsLoading: false,
      };
    case PORTFOLIO_DASH_STATS_EXPORT_LOADING:
      return {
        ...state,
        statisticsExportLoading: true,
      };
    case PORTFOLIO_DASH_STATS_EXPORT_LOADED:
      return {
        ...state,
        statisticsExport: action.payload,
        statisticsExportLoading: false,
      };
    case COLLAPSE_ALL_STAGES:
      return {
        ...state,
        collapseAll: action.payload ? false : !state.collapseAll,
      };

    case LOADING_DEFAULT_PIPELINE:
      return {
        ...state,
        isFetching: action.payload,
      };

    case DEFAULT_PIPELINE_LOADED:
      return {
        ...state,
        isFetching: false,
        default: action.payload ? reorderStages(action.payload) : null,
      };

    case DEFAULT_HEADER_PIPELINE_LOADED:
      return {
        ...state,
        defaultHeader: action.payload,
      };

    case LOADING_DEFAULT_BODY:
      return {
        ...state,
        isloadingBody: action.payload,
        loadingPrecent: action.percent,
      };

    case DEFAULT_BODY_PIPELINE_LOADED:
      const grouped = groupBy('stage_id');
      return {
        ...state,
        bodyDeals: [...state.bodyDeals, ...action.payload],
        defaultBody: grouped([...state.bodyDeals, ...action.payload]),
      };

    case DEFAULT_BODY_PIPELINE_CLEARED:
      return {
        ...state,
        bodyDeals: [],
        defaultBody: {},
      };

    case MOVE_CARD: {
      let newLists = state.defaultBody;
      const { lastX, nextX, item, lastY, nextY } = action;
      const lastStageID = item.stage_id;
      const nextStageID = state.defaultHeader.stages[nextX].stage_id;
      if (lastStageID == nextStageID) return state;
      else {
        if (newLists[nextStageID] == undefined) newLists = { ...newLists, [nextStageID]: [] };
        newLists[nextStageID].splice(nextY, 0, { ...item, stage_id: nextStageID });
        newLists[lastStageID].splice(lastY, 1);
        return {
          ...state,
          defaultBody: newLists,
          bodyDeals: lastStageID != nextStageID ? replace([...state.bodyDeals], { deal_id: item.deal_id }, { ...item, stage_id: nextStageID }) : [...state.bodyDeals],
        };
      }
    }

    case MOVE_LIST: {
      const newLists = [...state.lists];
      const { lastX, nextX } = action;
      const t = newLists.splice(lastX, 1)[0];

      newLists.splice(nextX, 0, t);

      return {
        ...state,
        lists: newLists,
      };
    }

    case TOGGLE_DRAGGING:
      return {
        ...state,
        isDragging: action.isDragging,
      };

    case DEAL_LOADED:
      return {
        ...state,
        editing: action.payload,
        loader: false,
      };
    case SET_DEAL_NAVIGATION:
      return {
        ...state,
        navigationLoading: false,
        navigation: action.payload,
      };
    case DEAL_NAVIGATION_LOADING:
      return {
        ...state,
        navigationLoading: true,
      };
    case INVESTMENTS_LOADED:
      return {
        ...state,
        investments: action.payload,
      };
    case DELETE_ROUND:
      return {
        ...state,
        investments: state.investments.filter(round => round.round_id !== action.payload),
      };
    case INVESTMENTS_CREATED:
      return {
        ...state,
        investments: [action.payload, ...state.investments],
      };
    case ROUND_UPDATED:
      return {
        ...state,
        investments: [...state.investments.filter(round => round.round_id !== action.payload.round_id), action.payload],
      };
    case DEAL_LOADING:
      return {
        ...state,
        editing: null,
        loader: true,
      };
    case VOTE_LOADING:
      return {
        ...state,
        loader: true,
      };

    case DEAL_LOADED_FAILED:
      return {
        ...state,
        loader: false,
      };

    case DEAL_STATUS_UPDATED:
      return {
        ...state,
        editing: action.payload,
        loader: false,
        default: state.default ? removeFromPipeline(action.payload, state.default) : null,
      };

    case PIPELINE_LIST_LOADED:
      return {
        ...state,
        pipelines: action.payload,
      };

    case EXTRA_PIPELINE_LOADED:
      return {
        ...state,
        extra: action.payload,
      };

    case LOADING_REOPEN_PIPELINES:
      return {
        ...state,
        extraPipelines: action.payload,
      };

    case STAGES_LOADING:
      return {
        ...state,
        stages: [],
      };

    // case FILTER_BY:
    //   return {
    //     ...state,
    //     filter: updateFilter(action.payload, state.filter),
    //   };

    case UPDATE_ALL_FILTERS:
      return {
        ...state,
        filter: action.payload,
      };

    case UPDATE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.filter]: action.payload.value,
        },
      };

    case LOADED_PIPELINE_STAGES:
      return {
        ...state,
        stages: action.payload,
      };

    case DEAL_CREATED:
      return {
        ...state,
        // default: insertIntoPipeline(action.payload, state.default),
      };

    case UPDATE_LIVE_DEAL:
      if (action.payload.pipeline_id == (state.defaultHeader && state.defaultHeader.pipeline_id)) {
        let newDeals = updateLiveDeals(action.payload, state.bodyDeals);
        return {
          ...state,
          defaultBody: groupBy('stage_id')(newDeals),
          bodyDeals: newDeals,
        };
      }

    case DEAL_NOTIFICATIONS_LOADED:
      return {
        ...state,
        dealNotifications: action.payload,
        dealLoader: false,
      };

    case DEAL_NOTIFICATIONS_LOADING:
      return {
        ...state,
        dealLoader: action.payload,
      };

    case TASKS_DEAL_LOADED:
      return {
        ...state,
        dealTasks: action.payload,
      };

    case TASK_DEAL_CREATED:
      return {
        ...state,
        dealTasks: [action.payload, ...state.dealTasks],
      };

    case TASK_DEAL_UPDATED:
      return {
        ...state,
        dealTasks: replace([...state.dealTasks], { task_id: action.payload.task_id }, action.payload),
      };

    case TASK_DEAL_DELETED:
      const dealTaskDeleted = [...state.dealTasks];
      remove(dealTaskDeleted, { task_id: action.payload });
      return {
        ...state,
        dealTasks: dealTaskDeleted,
      };

    case DEAL_UPDATED:
      let newDeal = state.editing ? { ...state.editing, ...action.payload } : action.payload;
      return {
        ...state,
        editing: newDeal,
        loader: false,
      };

    case ALL_DEAL_LIST_LOADED:
      return {
        ...state,
        allDeals: action.payload,
      };

    case NOTES_DEAL_LOADED:
      return {
        ...state,
        dealNotes: action.payload ? [...state.dealNotes, ...action.payload.results] : [],
        pageCont: action.payload ? action.payload._meta.pageCount : 0,
      };
    case NOTE_DEAL_LOADING:
      return {
        ...state,
        loadingDealNote: action.payload,
      };

    case NOTE_DEAL_CREATED:
      return {
        ...state,
        dealNotes: [action.payload, ...state.dealNotes],
      };

    case NOTE_DEAL_UPDATED:
      return {
        ...state,
        dealNotes: replace([...state.dealNotes], { note_id: action.payload.note_id }, action.payload),
      };

    case EDITING_NOTE:
      return {
        ...state,
        editingNote: action.payload,
      };

    case MEETINGS_DEAL_LOADED:
      return {
        ...state,
        dealMeetings: action.payload,
      };

    case MEETING_DEAL_CREATED:
      return {
        ...state,
        dealMeetings: [...state.dealMeetings, action.payload],
      };

    case FILES_DEAL_LOADED:
      return {
        ...state,
        selectedFilesId: [],
        fileParent: action.pathFiles ? action.pathFiles.file_id : 0,
        pathFiles: parsePathFolder(state.pathFiles, action.pathFiles),
        dealFiles: action.payload.map(i => ({ ...i, file_id: String(i.file_id) })),
      };

    case DEAL_FOLDER_UPDATED:
      return {
        ...state,
        dealFiles: replace([...state.dealFiles], { file_id: action.payload.file_id }, action.payload),
      };

    case REMOVE_BULK_FOLDERS:
      return {
        ...state,
        dealFiles: [...state.dealFiles].filter(it => !action.payload.includes(it.file_id)),
      };

    case FILE_DEAL_CREATED:
      return {
        ...state,
        dealFiles: [...action.payload, ...state.dealFiles],
      };

    case MULTI_FILES_DEAL_CREATED:
      return {
        ...state,
        dealFiles: [...action.payload, ...state.dealFiles],
      };

    case FILE_DEAL_UPLOADIND:
      return {
        ...state,
        dealFileUploading: action.payload,
      };

    case FILE_DEAL_DELETED:
      const deletedFiles = [...state.dealFiles];
      remove(deletedFiles, { file_id: action.payload });
      return {
        ...state,
        dealFiles: deletedFiles,
      };

    case SELECTED_FILE_IDS:
      return {
        ...state,
        selectedFilesId: action.payload,
      };

    case EDITING_DEAL_TASK:
      return {
        ...state,
        editTask: action.payload,
      };

    case FILE_VIEW_UPDATED:
      return {
        ...state,
        fileViewSelected: action.payload,
      };

    case EMAILS_DEAL_LOADED:
      return {
        ...state,
        dealEmails: action.payload ? [...state.dealEmails, ...action.payload.results] : [],
        pageCont: action.payload ? action.payload._meta.pageCount : 0,
      };

    case EMAIL_DEAL_CREATED:
      return {
        ...state,
        dealEmails: [action.payload, ...state.dealEmails],
      };

    case EDITING_DEAL_EMAIL:
      return {
        ...state,
        editingEmail: action.payload,
      };

    case DEAL_RATED:
      return {
        ...state,
        editing: { ...state.editing, rate: action.payload },
      };
    case ALL_INVESTORS_LOADED:
      return {
        ...state,
        allInvestors: action.payload,
      };
    case INVESTORS_LOADED:
      return {
        ...state,
        investors: action.payload,
      };
    case ANGEL_INVESTORS_LOADED:
      return {
        ...state,
        investors: action.payload,
      };
    case INVESTOR_DELETED:
      return {
        ...state,
        investors: state.investors.filter(investor => investor.investor_id !== action.payload),
      };
    case INVESTOR_CREATED:
      return {
        ...state,
        investors: [action.payload, ...state.investors],
      };
    case INVESTOR_UPDATED:
      return {
        ...state,
        investors: [action.payload, ...state.investors.filter(investor => investor.deal_investor_id !== action.payload.deal_investor_id)],
      };

    case DEAL_RATE_CREATED:
      return {
        ...state,
        rates: [action.payload, ...state.rates],
      };

    case DEAL_CASH_OUT_CREATED:
      return {
        ...state,
        cash: [action.payload, ...state.cash],
      };
    case DEAL_RATE_UPDATED:
      return {
        ...state,
        rates: replace([...state.rates], { return_rate_id: action.payload.return_rate_id }, action.payload),
      };
    case DEAL_CASH_OUT_UPDATED:
      return {
        ...state,
        cash: replace([...state.cash], { id: action.payload.id }, action.payload),
      };

    case DEAL_RATE_DELETED:
      const ratesRemoved = [...state.rates];
      remove(ratesRemoved, { return_rate_id: action.payload });
      return {
        ...state,
        rates: ratesRemoved,
      };

    case DEAL_CASH_OUT_DELETED:
      const cashRemoved = [...state.cash];
      remove(cashRemoved, { id: action.payload });
      return {
        ...state,
        cash: cashRemoved,
      };
    case DEAL_RATES_LOADED:
      return {
        ...state,
        rates: action.payload,
      };

    case DEAL_CASHOUT_LOADED:
      return {
        ...state,
        cash: action.payload,
      };

    case CLEAN_PIPELINE:
      return initialState;

    default:
      return state;
  }
}
