import React from 'react';
import { Field } from 'redux-form';
import * as validators from 'Utils/validators';
import SubmitButton from 'SharedComponent/SubmitButton';
import TextField from 'SharedComponent/TextField';
import InputCustomFields from 'SharedComponent/functions/InputCustomFields';

import './DealModal.scss';

export const renderContacts = ({ fields, trackEvent, customFields, meta: { error, submitFailed } }) => {
  return (
    <div className='col-md-12 mt-3'>
      {fields.map((contact, index) => (
        <li className='' key={index}>
          <div className='dashed-border mb-2' />
          <div className='display-flex-between'>
            <div>Add Related Contacts</div>
            <div>
              <i
                className='icon-close pt-3 cursor-pointer'
                onClick={() => {
                  if (trackEvent) trackEvent('REMOVE_REMOVE_CONTACT_FORM', undefined, 'icon');
                  fields.remove(index);
                }}
              />
            </div>
          </div>
          {renderContact(contact)}
          <div className='row'>
            {customFields.map(customField => (
              <InputCustomFields customField={customField} key={customField.field_name} fieldArray={`${contact}.`} classLabel='' />
            ))}
          </div>
        </li>
      ))}
      <SubmitButton
        label='New Contact'
        icon='icon-user-follow'
        style={{
          width: '100%',
          color: '#03a6ff',
          backgroundColor: 'rgba(3, 166, 255, 0.06)',
        }}
        onClick={() => {
          if (trackEvent) trackEvent('ADD_RELATED_CONTACT_FORM');
          fields.push({});
        }}
      />
    </div>
  );
};

function renderContact(key) {
  return (
    <div className='mt-3 mb-3'>
      <div className='row'>
        <div className='col-md-6'>
          <Field label='First name' name={`${key}.first_name`} component={TextField} type='text' placeholder='First name' validate={[validators.required]} required />
        </div>
        <div className='col-md-6'>
          <Field label='Last name' name={`${key}.last_name`} component={TextField} type='text' placeholder='Last name' validate={[validators.required]} required />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <Field
            label='Title'
            name={`${key}.title`}
            component={TextField}
            type='text'
            placeholder='Title'
            // validate={[validators.required]}
          />
        </div>
        <div className='col-md-6'>
          <Field
            label='Email'
            name={`${key}.email`}
            component={TextField}
            type='text'
            placeholder='Email'
            // validate={[validators.required]}
            // required
          />
        </div>
      </div>
    </div>
  );
}
