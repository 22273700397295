import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import CustomSwitch from 'SharedComponent/CustomSwitch';

import './SwitchFields.scss';

export default class SwitchFields extends Component {
  render() {
    const item = this.props.item;
    return (
      <div className='SwitchFields'>
        <Container>
          {item.fields.map((it, idx) => (
            <Row key={idx + 1}>
              <Col xs='12'>
                <SwitchToggle onChange={e => this.props.updateField(it.value, e)} defaultChecked={it.show} label={it.label} value='name' disabled={it.disabled} />
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    );
  }
}

export const SwitchToggle = props => {
  return (
    <div className='SwitchFields'>
      <div className='display-flex-between'>
        <div>{props.label}</div>
        <div className='mb-1'>
          <CustomSwitch
            onChange={value => props.onChange(value, props.value)}
            disabled={props.disabled}
            defaultChecked={props.defaultChecked}
            checked={props.defaultChecked}
            className='switch-log'
          />
        </div>
      </div>
    </div>
  );
};
