import React, { Component } from 'react';

import './ScoreCard.scss';
import { numberFormatter } from '../../../../utils/objectUtils';

export default class ScoreCard extends Component {
  formatNumber(x) {
    return Number(x) ? numberFormatter(Number(x)) : 'N/A';
  }

  render() {
    const { item } = this.props;
    return (
      <div className='ScoreCard'>
        <div className='score-title'>{item.title}</div>
        <div className='score-value'>{this.formatNumber(item.value)}</div>
      </div>
    );
  }
}
