import { GetMeResponseDTO } from '../../generated/model/getMeResponseDTO';
import { IMap } from '../../utils/map-utils';

export enum PermissionAttributeEnum {
  Vc = 1,
  Wealth = 2,
  Sales = 3,
  User = 4,
  Admin = 5,
  Owner = 6,
  IcMember = 7,
  Registered = 8,
  NotRegistered = 9,
  StatusActive = 10,
  StatusTrialing = 11,
  StatusPastDue = 12,
  StatusPaused = 13,
  StatusDeleted = 14,
  StatusPending = 15,
  CanIntegrateCalendar = 16,
  BillableCompany = 17,
  CanIntegrateZoom = 18,
  CanIntegrateDrive = 19,
  StatusExpired = 20,
  StatusNotExpired = 21,
  CanNotCreateReport = 22,
  CanCreateReport = 23,
  CanNotCreateDeal = 24,
  CanCreateDeal = 25,
  CanEnableAngelInvestor = 26,
  AngelNetworkActivated = 27,
}
export namespace PermissionAttributeEnum {
  export const toString: IMap<PermissionAttributeEnum, string> = new IMap<PermissionAttributeEnum, string>(
    [
      [PermissionAttributeEnum.Vc, 'Vc'],
      [PermissionAttributeEnum.Wealth, 'Wealth'],
      [PermissionAttributeEnum.Sales, 'Sales'],
      [PermissionAttributeEnum.User, 'User'],
      [PermissionAttributeEnum.Admin, 'Admin'],
      [PermissionAttributeEnum.Owner, 'Owner'],
      [PermissionAttributeEnum.IcMember, 'IcMember'],
      [PermissionAttributeEnum.Registered, 'Registered'],
      [PermissionAttributeEnum.NotRegistered, 'NotRegistered'],
      [PermissionAttributeEnum.StatusActive, 'StatusActive'],
      [PermissionAttributeEnum.StatusTrialing, 'StatusTrialing'],
      [PermissionAttributeEnum.StatusPastDue, 'StatusPastDue'],
      [PermissionAttributeEnum.StatusPaused, 'StatusPaused'],
      [PermissionAttributeEnum.StatusDeleted, 'StatusDeleted'],
      [PermissionAttributeEnum.StatusPending, 'StatusPending'],
      [PermissionAttributeEnum.CanIntegrateCalendar, 'CanIntegrateCalendar'],
      [PermissionAttributeEnum.BillableCompany, 'BillableCompany'],
      [PermissionAttributeEnum.CanIntegrateZoom, 'CanIntegrateZoom'],
      [PermissionAttributeEnum.CanIntegrateDrive, 'CanIntegrateDrive'],
      [PermissionAttributeEnum.StatusNotExpired, 'StatusNotExpired'],
      [PermissionAttributeEnum.StatusExpired, 'StatusExpired'],
      [PermissionAttributeEnum.CanNotCreateReport, 'CanNotCreateReport'],
      [PermissionAttributeEnum.CanCreateReport, 'CanCreateReport'],
      [PermissionAttributeEnum.CanNotCreateDeal, 'CanNotCreateDeal'],
      [PermissionAttributeEnum.CanCreateDeal, 'CanCreateDeal'],
      [PermissionAttributeEnum.CanEnableAngelInvestor, 'CanEnableAngelInvestor'],
      [PermissionAttributeEnum.AngelNetworkActivated, 'AngelNetworkActivated'],
    ],
    undefined,
  );

  export const convertFromApiValue: IMap<GetMeResponseDTO.UserAttributesEnum, PermissionAttributeEnum> = new IMap<GetMeResponseDTO.UserAttributesEnum, PermissionAttributeEnum>(
    [
      [GetMeResponseDTO.UserAttributesEnum.VCACCELERATOR, PermissionAttributeEnum.Vc],
      [GetMeResponseDTO.UserAttributesEnum.WEALTHMANAGEMENT, PermissionAttributeEnum.Wealth],
      [GetMeResponseDTO.UserAttributesEnum.SALES, PermissionAttributeEnum.Sales],
      [GetMeResponseDTO.UserAttributesEnum.USER, PermissionAttributeEnum.User],
      [GetMeResponseDTO.UserAttributesEnum.ADMIN, PermissionAttributeEnum.Admin],
      [GetMeResponseDTO.UserAttributesEnum.OWNER, PermissionAttributeEnum.Owner],
      [GetMeResponseDTO.UserAttributesEnum.ICMEMBER, PermissionAttributeEnum.IcMember],
      [GetMeResponseDTO.UserAttributesEnum.REGISTERED, PermissionAttributeEnum.Registered],
      [GetMeResponseDTO.UserAttributesEnum.NOTREGISTERED, PermissionAttributeEnum.NotRegistered],
      [GetMeResponseDTO.UserAttributesEnum.STATUSACTIVE, PermissionAttributeEnum.StatusActive],
      [GetMeResponseDTO.UserAttributesEnum.STATUSTRIALING, PermissionAttributeEnum.StatusTrialing],
      [GetMeResponseDTO.UserAttributesEnum.STATUSPASTDUE, PermissionAttributeEnum.StatusPastDue],
      [GetMeResponseDTO.UserAttributesEnum.STATUSPAUSED, PermissionAttributeEnum.StatusPaused],
      [GetMeResponseDTO.UserAttributesEnum.STATUSDELETED, PermissionAttributeEnum.StatusDeleted],
      [GetMeResponseDTO.UserAttributesEnum.STATUSPENDING, PermissionAttributeEnum.StatusPending],
      [GetMeResponseDTO.UserAttributesEnum.CANINTEGRATECALENDAR, PermissionAttributeEnum.CanIntegrateCalendar],
      [GetMeResponseDTO.UserAttributesEnum.BILLABLECOMPANY, PermissionAttributeEnum.BillableCompany],
      [GetMeResponseDTO.UserAttributesEnum.CANINTEGRATEZOOM, PermissionAttributeEnum.CanIntegrateZoom],
      [GetMeResponseDTO.UserAttributesEnum.CANINTEGRATEDRIVE, PermissionAttributeEnum.CanIntegrateDrive],
      [GetMeResponseDTO.UserAttributesEnum.STATUSNOTEXPIRED, PermissionAttributeEnum.StatusNotExpired],
      [GetMeResponseDTO.UserAttributesEnum.STATUSEXPIRED, PermissionAttributeEnum.StatusExpired],
      [GetMeResponseDTO.UserAttributesEnum.CANNOTCREATEREPORT, PermissionAttributeEnum.CanNotCreateReport],
      [GetMeResponseDTO.UserAttributesEnum.CANCREATEREPORT, PermissionAttributeEnum.CanCreateReport],
      [GetMeResponseDTO.UserAttributesEnum.CANNOTCREATEDEAL, PermissionAttributeEnum.CanNotCreateDeal],
      [GetMeResponseDTO.UserAttributesEnum.CANCREATEDEAL, PermissionAttributeEnum.CanCreateDeal],
      [GetMeResponseDTO.UserAttributesEnum.CANENABLEANGELINVESTOR, PermissionAttributeEnum.CanEnableAngelInvestor],
      [GetMeResponseDTO.UserAttributesEnum.ANGELNETWORKACTIVATED, PermissionAttributeEnum.AngelNetworkActivated],
    ],
    undefined,
  );

  export const convertToApiValue: IMap<PermissionAttributeEnum, GetMeResponseDTO.UserAttributesEnum> = new IMap<PermissionAttributeEnum, GetMeResponseDTO.UserAttributesEnum>();
}
