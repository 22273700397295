export const appCategories = {
  header: 'HEADER',
  leftMenu: 'LEFT_MENU',
  dashboard: 'DASHBOARD',
  pipeline: 'PIPELINE',
  portfolio: 'PORTFOLIO',
  reports: 'REPORTS',
  contacts: 'CONTACTS',
  tasks: 'TASKS',
  settings: 'SETTINGS',
  profile: 'PROFILE',
  integrations: 'INTEGRATIONS',
  settings_company: 'SETTINGS_COMAPANY',
  settings_users: 'SETTINGS_USERS',
  settings_pipelines: 'SETTINGS_PIPELINES',
  settings_portfolio: 'SETTINGS_PORTFOLIO',
  settings_emailTemplates: `SETTINGS_EMAIL_TEMPLATES`,
  settings_forms: 'SETTINGS_FORMS',
  settings_billing: 'SETTINGS_BILLING',
  settings_data_import: 'SETTINGS_DATA_IMPORT',
  deal: 'DEAL',
  deal_notes: 'DEAL_NOTES',
  deal_emails: 'DEAL_EMAILS',
  deal_tasks: 'DEAL_TASKS',
  deal_files: 'DEAL_FILES',
  deal_metrics: 'DEAL_METRICS',
  deal_history: 'DEAL_HISTORY',
  form_editor: 'FORM_EDITOR',
  form_editor_edit: 'FORM_EDITOR_EDIT',
  forms: 'FORMS',
  metric_form: 'METRIC_ONLINE_FORM',
};

export const appActions = (label, event) => {
  return {
    input: `${label}_INPUT_${event}`,
    button: `${label}_BUTTON_${event}`,
    icon: `${label}_ICON_${event}`,
    label: `${label}_LABEL_${event}`,
    tab: `${label}_TAB_${event}`,
    dropdown: `${label}_DROPDOWN_${event}`,
    checkbox: `${label}_CHECKBOX_${event}`,
    item: `${label}_ITEM_${event}`,
    dnd: `${label}_DRAG_AND_DROP_${event}`,
    switch: `${label}_SWITCH_${event}`,
  };
};

export const appEvents = {
  clicked: 'CLICKED',
  focused: 'FOCUSED',
  typed: 'TYPED',
  opened: 'OPENED',
  entered: 'ENTERED',
};

export const appTypes = {
  search: 'SEARCH',
  extra_options: 'EXTRA_OPTIONS',
  support: 'SUPPORT',
  notifications: 'NOTIFICATIONS',
  account: 'ACCOUNT',
  save: 'SAVE',
  submit: 'SUBMIT',
  delete: 'DELETE',
  update: 'UPDATE',
  cancel: 'CANCEL',
  close: 'CLOSE',
  tab: 'TAB',
  show: 'SHOW',
  preview: 'PREVIEW',
  trash: 'TRASH',
  edit: 'EDIT',
  add: 'ADD',
  mass: 'MASS',
  move: 'MOVE',
  resend: 'RESEND',
  copyToClipboard: 'COPY_TO_CLIPBOARD',
  modal: 'MODAL',
  list: 'LIST',
  cards: 'CARDS',
  block: 'BLOCK',
  unblock: 'UNBLOCK',
};
