import React, { ReactNode } from 'react';
import { Modal } from 'antd';

import { NotificationTypeEnum } from '../../enums/notification-type.enum';
import { Copyable } from '../Copyable/copyable.component';

interface ModalNotificationProps {
  title: string;
  type: NotificationTypeEnum;
  confirmText?: string;
  cancelText?: string;
  copyable?: boolean;
  copyableText?: string;
  content?: string | ReactNode;
  onConfirm?(): void;
}

export function modalNotification(props: ModalNotificationProps): void {
  Modal.confirm({
    title: props.title,
    content: props.copyable ? <Copyable text={props.copyableText} /> : props.content,
    okText: props.confirmText,
    cancelText: props.cancelText,
    onOk(): void {
      props.onConfirm();
    },
    okButtonProps: {
      className: NotificationTypeEnum.toBtnClass.getValue(props.type),
    },
    autoFocusButton: null,
    icon: null,
  });
}

modalNotification.defaultProps = {
  confirmText: 'Save',
  cancelText: 'Cancel',
};
