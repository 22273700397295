import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import './CustomPopover.scss';

export default class CustomPopover extends Component {
  static propTypes = {
    hideArrow: PropTypes.bool,
    hasHeader: PropTypes.bool,
    popoverID: PropTypes.string.isRequired,
  };

  static defaultProps = {
    hideArrow: true,
    hasHeader: false,
    popoverID: 'popover-id',
  };

  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  getChildrenWithProps() {
    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        closePopover: () => this.setState({ popoverOpen: false }),
      });
    });
  }

  render() {
    const { props, state } = this;
    const btnActive = props.isActive ? { backgroundColor: '#e4e8eb' } : {};
    return (
      <div className='CustomPopover'>
        <Button id={props.popoverID} type='button' block className='button primary' onClick={this.toggle} style={{ ...btnActive, ...props.btnStyle }}>
          <div className='display-flex-center'>
            {props.icon && <i className={classNames('icons-details material-icons', props.isActive && 'active-button')}>{props.icon}</i>}
            <span className={classNames('label-details', props.isActive && 'active-button')}>{props.label}</span>
            {props.arrow && <i className='icons-details ml-2 material-icons'>arrow_drop_down</i>}
          </div>
        </Button>
        <Popover
          target={props.popoverID}
          placement='bottom'
          isOpen={this.state.popoverOpen}
          toggle={this.toggle}
          hideArrow={props.hideArrow}
          className='popover-details'
          innerClassName='popover-body-details'
          trigger={this.props.hideAfterClick ? 'legacy' : 'click'}
        >
          {props.hasHeader && <PopoverHeader>{props.popoverHeader}</PopoverHeader>}
          <PopoverBody style={{ padding: '0' }}>
            <React.Fragment>{this.getChildrenWithProps()}</React.Fragment>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}
