import { Steps } from 'antd';

import React, { useState } from 'react';

import { reduxForm } from 'redux-form';
import SubmitButton from 'SharedComponent/SubmitButton';
import ReactFormGenerator from '../form';
import isEmpty from 'lodash/isEmpty';
import { convertLanguageToDirection, convertApiValueToRTLBoolean } from 'Enums/languages';
import ActionsTranslation from 'Translations/actions.json';

function StepsContainer({ steps, initialValues, saveDataForm, handleSubmit, isMultiSteps, countries, recaptcha, onSaveLater, showAction, language }) {
  const [currentStep, setCurrentStep] = useState(0);

  function renderStepsTitle() {
    if (isMultiSteps)
      return (
        <div className='p-3' style={{ overflow: 'scroll' }}>
          <Steps size='small' current={currentStep} className='ant-steps-label-vertical'>
            {Object.keys(steps).map(key => (
              <Steps.Step key={key} title={steps[key].title} />
            ))}
          </Steps>
        </div>
      );
  }

  function renderRTLStepsAction() {
    if (isMultiSteps)
      return (
        <div className={'display-flex-between pl-3 pr-3'}>
          <SubmitButton
            label={ActionsTranslation[language].next}
            icon='icon-arrow-left'
            style={{
              width: '150px',
              color: '#53627c',
              backgroundColor: '#fff',
              border: '1px solid #e1e4e8',
            }}
            disabled={currentStep == Object.keys(steps).length - 1}
            type='submit'
            formId={Object.keys(steps)[currentStep]}
          />
          <SubmitButton
            label={ActionsTranslation[language].saveForLater}
            disabled={recaptcha}
            icon='icon-key'
            style={{
              width: '150px',
              color: '#53627c',
              backgroundColor: '#fff',
              border: '1px solid #e1e4e8',
            }}
            onClick={() => onSaveLater()}
          />
          {currentStep == Object.keys(steps).length - 1 && (
            <SubmitButton label={ActionsTranslation[language].submit} icon='icon-check' style={{ width: '150px' }} type='submit' formId={Object.keys(steps)[currentStep]} />
          )}
          <SubmitButton
            label={ActionsTranslation[language].previous}
            icon='icon-arrow-right'
            style={{
              width: '150px',
              color: '#53627c',
              backgroundColor: '#fff',
              border: '1px solid #e1e4e8',
            }}
            disabled={currentStep == 0}
            onClick={() => previous()}
          />
        </div>
      );
    else return null;
  }

  function renderStepsAction() {
    if (isMultiSteps)
      return (
        <div className={'display-flex-between pl-3 pr-3'}>
          <SubmitButton
            label={ActionsTranslation[language].previous}
            icon='icon-arrow-left'
            style={{
              width: '150px',
              color: '#53627c',
              backgroundColor: '#fff',
              border: '1px solid #e1e4e8',
            }}
            disabled={currentStep == 0}
            onClick={() => previous()}
          />
          {currentStep == Object.keys(steps).length - 1 && (
            <SubmitButton label={ActionsTranslation[language].submit} icon='icon-check' style={{ width: '150px' }} type='submit' formId={Object.keys(steps)[currentStep]} />
          )}
          {showAction && (
            <SubmitButton
              label={ActionsTranslation[language].saveForLater}
              disabled={recaptcha}
              icon='icon-key'
              style={{
                width: '150px',
                color: '#53627c',
                backgroundColor: '#fff',
                border: '1px solid #e1e4e8',
              }}
              onClick={() => onSaveLater()}
            />
          )}
          <SubmitButton
            label={ActionsTranslation[language].next}
            icon='icon-arrow-right'
            style={{
              width: '150px',
              color: '#53627c',
              backgroundColor: '#fff',
              border: '1px solid #e1e4e8',
            }}
            disabled={currentStep == Object.keys(steps).length - 1}
            type='submit'
            formId={Object.keys(steps)[currentStep]}
          />
        </div>
      );
    else return null;
  }

  const next = () => setCurrentStep(currentStep + 1);
  const previous = () => setCurrentStep(currentStep - 1);

  function onSubmit(values) {
    saveDataForm(values);
  }

  return (
    <div>
      {renderStepsTitle()}
      <div className='p-3' style={{ direction: convertLanguageToDirection(language) }}>
        <form
          id={Object.keys(steps)[currentStep]}
          onSubmit={handleSubmit(e => (isMultiSteps ? (currentStep == Object.keys(steps).length - 1 ? onSubmit(e) : next()) : onSubmit(e)))}
        >
          <ReactFormGenerator
            download_path=''
            back_action='/'
            back_name='Back'
            answer_data={{}}
            action_name='Save'
            form_action='/'
            form_method='POST'
            data={!isEmpty(steps[Object.keys(steps)[currentStep]]) ? steps[Object.keys(steps)[currentStep]].content : []}
            initialValues={initialValues}
            saveDataForm={saveDataForm}
            countries={countries}
            rtl={convertApiValueToRTLBoolean(language)}
          />
        </form>
      </div>
      {convertApiValueToRTLBoolean(language) ? renderRTLStepsAction() : renderStepsAction()}
    </div>
  );
}

StepsContainer = reduxForm({
  form: 'form-editor-generator',
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
})(StepsContainer);

export default StepsContainer;
