import { Select, Avatar, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import Error from '../TextField/Error';

const { Option } = Select;

import './SelectOption.scss';

export default function AntSelectField(props) {
  const inputs = props.input.value ? props.input.value : [];
  useEffect(() => {
    props.input.onChange(inputs);
  }, []);

  function handleChange(value) {
    props.input.onChange(value);
  }

  return (
    <div className='SelectOption'>
      <div className='display-flex-between'>
        {props.label && (
          <div className={classNames('default-color', props.labelClass ? props.labelClass : 'label-selector')}>
            {props.label}
            {props.required && (
              <span className='' style={{ color: '#ff8181' }}>
                &nbsp;&#9679;
              </span>
            )}
          </div>
        )}
        <Error touched={props.meta.touched} error={props.meta.error} warning={props.meta.warning} />
      </div>
      <Select
        mode='multiple'
        style={{ width: '100%' }}
        placeholder=''
        onBlur={() => props.input.onBlur(inputs)}
        value={inputs}
        onChange={handleChange}
        optionLabelProp='label'
        size='large'
      >
        {props.options.map(opt => (
          <Option
            key={opt.value}
            value={opt.value}
            label={inputs.length > 4 ? <SmallInput image={opt.image || defaultImage} name={opt.label} /> : <LargeInput image={opt.image || defaultImage} name={opt.label} />}
          >
            <Avatar src={opt.image} />
            <span className='ml-2' role='img'>
              {opt.label}
            </span>
          </Option>
        ))}
      </Select>
    </div>
  );
}

const SmallInput = ({ image, name }) => (
  <Tooltip title={name}>
    <Avatar src={image} />
  </Tooltip>
);
const LargeInput = ({ image, name }) => (
  <span>
    <Avatar src={image} />
    <span className='ml-2'>{name}</span>
  </span>
);
