import { PayloadAction } from '@reduxjs/toolkit';
import { all } from '@redux-saga/core/effects';
import { put, PutEffect, takeLatest } from 'redux-saga/effects';

import { notification } from '../../../../shared/components';
import { NotificationTypeEnum } from '../../../../shared/enums/notification-type.enum';
import { closeModal, setLoading, setSubmitted } from '../../../../shared/components/Modal/state/modal.actions';

import { ErrorResponseData } from '../../../../core/models/http-params.type';
import { KpisSummaryData } from '../../../../core/models/kpis/kpis-summary-data';
import { dashboardAllKpis, dashboardKpis, reorderKpis } from '../../../../core/services/kpis.service';

import * as kpisActions from './kpis.actions';
import { IKpiList } from './types';

function* loadKpisEffect(): Generator<Promise<KpisSummaryData[]> | PutEffect, void, KpisSummaryData[]> {
  try {
    const kpis = yield dashboardKpis();
    yield put(kpisActions.loadKpisSuccess({ list: kpis }));
  } catch (error) {
    yield put(kpisActions.loadKpisFail(error));
  }
}

function* loadAllKpisEffect(): Generator<Promise<KpisSummaryData[]> | PutEffect, void, KpisSummaryData[]> {
  try {
    yield put(setLoading({ loading: true }));

    const AllKpis = yield dashboardAllKpis();

    yield put(kpisActions.loadAllKpisSuccess({ list: AllKpis }));

    yield put(setLoading({ loading: false }));
  } catch (error) {
    yield put(kpisActions.loadAllKpisFail(error));
    yield put(setLoading({ loading: false }));
  }
}

function* reorderKpisEffect({ payload: { list } }: PayloadAction<IKpiList>): Generator<Promise<boolean> | PutEffect, void> {
  try {
    yield put(setSubmitted({ submitted: true }));

    yield reorderKpis(list);
    yield put(kpisActions.reorderKpisSuccess());

    yield put(setSubmitted({ submitted: false }));
    yield put(closeModal());
  } catch (error) {
    yield put(kpisActions.reorderKpisFail(error));
    yield put(setSubmitted({ submitted: false }));
  }
}

function* reorderKpisSuccessEffect(): Generator {
  try {
    notification({ type: NotificationTypeEnum.Success, message: 'Kpis updated with success' });
    yield put(kpisActions.loadKpis());
  } catch (error) {}
}

function* closeModalKpisEffect(): Generator {
  try {
    yield put(kpisActions.resetAllKpisState());
  } catch (error) {}
}

function* failEffect({ payload }: PayloadAction<ErrorResponseData>): Generator {
  try {
    notification({ type: NotificationTypeEnum.Error, message: payload.message });
  } catch (error) {}
}

export function* watchKpis(): Generator {
  yield all([
    takeLatest(kpisActions.loadKpis.type, loadKpisEffect),
    takeLatest(kpisActions.loadAllKpis.type, loadAllKpisEffect),
    takeLatest(kpisActions.reorderKpis.type, reorderKpisEffect),
    takeLatest(kpisActions.reorderKpisSuccess.type, reorderKpisSuccessEffect),
    takeLatest(closeModal, closeModalKpisEffect),
    takeLatest([kpisActions.loadKpisFail.type, kpisActions.loadAllKpisFail.type, kpisActions.reorderKpisFail.type], failEffect),
  ]);
}
