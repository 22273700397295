/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriptionDTO {
  subscription_id?: number;
  owner_id?: number;
  /**
   *      1 - Active     2 - Trailing     3 - Past Due     4 - Paused     5 - Deleted     6 - Pending
   */
  status?: SubscriptionDTO.StatusEnum;
  /**
   *      589449 - Silver monthely     589533 - Silver yearley     589532 - Gold monthely     589451 - Gold Yearly     5 - Wealth     6 - Sales
   */
  subscription_plan_id?: SubscriptionDTO.SubscriptionPlanIdEnum;
  cancel_url?: string;
  update_url?: string;
  next_bill_date?: number;
  paddle_user_id?: string;
  trial_expire_at?: number;
  is_trial?: boolean;
  created_at?: number;
  quantity?: number;
  checkout_id?: string;
  unit_price?: number;
}
export namespace SubscriptionDTO {
  export type StatusEnum = 1 | 2 | 3 | 4 | 5 | 6;
  export const StatusEnum = {
    NUMBER_1: 1 as StatusEnum,
    NUMBER_2: 2 as StatusEnum,
    NUMBER_3: 3 as StatusEnum,
    NUMBER_4: 4 as StatusEnum,
    NUMBER_5: 5 as StatusEnum,
    NUMBER_6: 6 as StatusEnum,
  };
  export type SubscriptionPlanIdEnum = 589449 | 589533 | 589532 | 589451 | 5 | 6;
  export const SubscriptionPlanIdEnum = {
    NUMBER_589449: 589449 as SubscriptionPlanIdEnum,
    NUMBER_589533: 589533 as SubscriptionPlanIdEnum,
    NUMBER_589532: 589532 as SubscriptionPlanIdEnum,
    NUMBER_589451: 589451 as SubscriptionPlanIdEnum,
    NUMBER_5: 5 as SubscriptionPlanIdEnum,
    NUMBER_6: 6 as SubscriptionPlanIdEnum,
  };
}
