import React, { Component } from 'react';
import { connect } from 'react-redux';
import defaultImage from '../../../../assets/img/default.png';

import './AuthorCard.scss';

class AuthorCard extends Component {
  checkField(fields, field) {
    const item = fields.find(e => e.value == field);
    return item ? item.show : false;
  }

  render() {
    const { item, user } = this.props;
    return (
      <div className='AuthorCard'>
        <div className='display-flex-start'>
          {this.checkField(item.fields, 'image') && (
            <div className='flex-1 mr-2'>
              <img height='100%' width='100%' style={{ borderRadius: '50%' }} src={(user && user.profile && user.profile.image) || defaultImage} />
            </div>
          )}
          <div className='flex-3 name-email-details'>
            {this.checkField(item.fields, 'name') && <div className='name-details'>{user && user.profile && user.profile.fullname}</div>}
            {this.checkField(item.fields, 'email') && <div className='email-details'>{user && user.email}</div>}
            {this.checkField(item.fields, 'phone') && <div className='email-details'>{user && user.profile && user.profile.phone}</div>}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, {})(AuthorCard);
