import React from 'react';
import { Tag } from 'antd';

import { PentugramLogo2C } from 'SharedComponent/PentugramLogo';

const features = (dealLimitMessage, reportLimitMessage) => [
  { label: 'Pipeline management', icon: <span className='material-icons'>check</span> },
  { label: 'Portfolio management', icon: <span className='material-icons'>check</span> },
  { label: 'Number of companies', tag: <Tag>{dealLimitMessage}</Tag> },
  { label: 'Reporting tool', icon: <span className='material-icons'>check</span> },
  { label: 'Number of reports', tag: <Tag>{reportLimitMessage}</Tag> },
  { label: 'Task management', icon: <span className='material-icons'>check</span> },
  { label: 'Contacts management', icon: <span className='material-icons'>check</span> },
  { label: 'Data migration tools & support', icon: <span className='material-icons'>check</span> },
];

export const PlanItem = props => (
  <div className='pricing-box'>
    <div className='pricing-box-header'>
      <div className='text-center'>
        <PentugramLogo2C colors={props.colors} width='60' height='60' id={props.id} />
        <div className='pricing-box-header-title'>{props.title}</div>
      </div>
      <div className='pricing-box-header-price'>
        {props.price}
        <span className='pricing-box-header-price-duration'>{props.duration}</span>
      </div>
    </div>
    <div className='pricing-box-feature'>
      <ul>
        {features(props.dealLimitMessage, props.reportLimitMessage).map(it => (
          <li key={it.label}>
            {it.label} {it.icon} {it.tag}
          </li>
        ))}
      </ul>
    </div>
    <div className='pricing-box-footer'>
      <a onClick={props.onSubscribe} className='subscribe-btn'>
        Subscribe
      </a>
    </div>
  </div>
);
