import { LOADING_EVENTS, EVENTS_LOADED, EVENTS_CREATED, EVENT_UPDATED, EDITING_EVENT, MODAL_DISMISS } from 'ReduxActions/calendarActions';
import { replace } from 'Utils/arrayUtils.js';

const initialState = {
  all: [],
  loading: false,
  editing: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_EVENTS:
      return {
        ...state,
        loading: action.payload,
      };

    case EVENTS_LOADED:
      return {
        ...state,
        all: action.payload,
        loading: false,
      };

    case EVENTS_CREATED:
      return {
        ...state,
        all: [...state.all, action.payload],
        loading: false,
      };

    case EVENT_UPDATED:
      return {
        ...state,
        all: replace(state.all, { event_id: action.payload.event_id }, action.payload),
        loading: false,
      };

    case EDITING_EVENT:
      return {
        ...state,
        editing: action.payload,
      };

    case MODAL_DISMISS:
      return {
        ...state,
        editing: null,
      };
  }

  return state;
}
