import React from 'react';
import { Badge as AntdBadge } from 'antd';

interface BadgeProps {
  dot?: boolean;
  count?: React.ReactNode;
  color?: string;
  title?: string;
  showZero?: boolean;
  text?: string;
  children?: React.ReactNode;
  className?: string;
}

export const Badge = (props: BadgeProps): JSX.Element => {
  return (
    <AntdBadge className={props.className} text={props.text} dot={props.dot} count={props.count} showZero={props.showZero} title={props.title} color={props.dot && props.color}>
      {props.children}
    </AntdBadge>
  );
};

Badge.defaultProps = {
  showZero: false,
};
