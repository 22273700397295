import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';
import SelectComponent from 'SharedComponent/SelectOption/SelectComponent';
import SwitchField from 'SharedComponent/CustomSwitch/SwitchField';
import TextEditor from 'SharedComponent/TextEditor';
import * as validators from 'Utils/validators';
import Button from 'react-bootstrap/lib/Button';

import { formLanguages } from 'Enums/languages';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

const selector = formValueSelector('form-build-choice');
class PipelineChoice extends React.Component {
  submit(values) {
    if (this.props.initialValues) {
      this.props.onUpdate({ ...this.props.initialValues, ...values });
      gaEvent(appCategories.form_editor_edit, appActions(`${appTypes.save}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    } else {
      this.props.onSave(values);
      gaEvent(appCategories.form_editor, appActions(`${appTypes.save}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    }
    this.props.reset();
    this.props.onHide();
  }

  onClickCancelIconDialogBox() {
    this.props.onHide();
    if (this.props.initialValues) {
      gaEvent(appCategories.form_editor_edit, appActions(`${appTypes.update}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
    } else {
      gaEvent(appCategories.form_editor, appActions(`${appTypes.save}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
    }
  }

  onClickCancelDialogBox() {
    this.props.onHide();
    if (this.props.initialValues) {
      gaEvent(appCategories.form_editor_edit, appActions(`${appTypes.update}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    } else {
      gaEvent(appCategories.form_editor, appActions(`${appTypes.save}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    }
  }

  render() {
    const { handleSubmit, submitting, pristine, pipelines, stages, status, users, sendEmails } = this.props;
    if (!this.props.show) return null;
    return (
      <div className='pipelien-choice'>
        <DialogBox isVisible onClickOut={this.props.onHide} onClose={() => this.onClickCancelIconDialogBox()} className=''>
          <div className='p-3'>
            <div className='pipeline-modal-header'>{this.props.initialValues ? 'Update Form' : ' NewForm'}</div>
            <form onSubmit={handleSubmit(this.submit.bind(this))}>
              <div>
                <div className='m-3'>
                  <div className='pipeline-modal-description'>Please connect the form with a pipeline and stage.</div>
                  <Row className='p-3'>
                    <Col xs={12} md={6}>
                      <Field
                        label='Select a pipeline'
                        name={`pipeline_id`}
                        component={FieldSelect}
                        type='text'
                        placeholder='Select a portfolio pipeline'
                        validate={[validators.required]}
                        options={pipelines}
                        onLoad={id => {
                          this.props.dispatch(change('form-build-choice', 'stage_id', null));
                          this.props.loadStages(id);
                        }}
                        clearable={false}
                        required
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Field
                        label='Stage'
                        name={`stage_id`}
                        component={FieldSelect}
                        type='text'
                        placeholder='Select a stage'
                        options={stages}
                        onHover='If the deal stage is not specified it will automatically be placed in the first stage of your process'
                      />
                    </Col>
                  </Row>
                  <Row className='p-3'>
                    <Col xs={12} md={6}>
                      <Field
                        label='Status'
                        name={`status`}
                        component={FieldSelect}
                        validate={[validators.required]}
                        required
                        type='text'
                        placeholder='Change status'
                        options={status}
                        clearable={false}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Field
                        label='Assignee'
                        name={`assignee.id`}
                        component={FieldSelect}
                        type='text'
                        placeholder='Change status'
                        options={users}
                        withImage={true}
                        clearable={true}
                      />
                    </Col>
                  </Row>
                  <Row className='p-3'>
                    <Col xs={12} md={6}>
                      <Field
                        label='Language'
                        name={`language`}
                        component={FieldSelect}
                        type='text'
                        placeholder='Change language'
                        options={formLanguages}
                        // withImage={true}
                        clearable={false}
                      />
                    </Col>
                  </Row>
                  <Row className='p-3'>
                    <Col xs={12} md={12}>
                      <label className='confirmation-message'>Confirmation message</label>
                      <Field name={`confirmation`} component={TextEditor} />
                    </Col>
                  </Row>
                  <Row className='p-3'>
                    <Col xs={12} md={12}>
                      <Field label='Notify us for each new submission.' name='send_email' component={SwitchField} format={value => (value ? true : false)} />
                    </Col>
                  </Row>
                  {sendEmails && (
                    <Row className='p-3'>
                      <Col xs={12} md={12}>
                        <Field name='email_users' component={SelectComponent} options={users} keyValue='email' placeholder={`Select users`} notFoundContent='No users found' />
                      </Col>
                    </Row>
                  )}
                </div>
                <div className='display-flex-end'>
                  <div className='m-3'>
                    <SubmitButton
                      label='Cancel'
                      icon='icon-close'
                      style={{
                        width: '150px',
                        color: '#53627c',
                        backgroundColor: '#fff',
                        border: '1px solid #e1e4e8',
                      }}
                      onClick={() => this.onClickCancelDialogBox()}
                    />
                  </div>
                  <div className='m-3'>
                    <Button type='submit' className='submit-btn-p'>
                      <i className='icon-check mr-2' />
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </DialogBox>
      </div>
    );
  }
}

PipelineChoice = connect(state => {
  return { sendEmails: selector(state, 'send_email') };
}, {})(PipelineChoice);

PipelineChoice = reduxForm({
  form: 'form-build-choice',
  enableReinitialize: true,
})(PipelineChoice);

export default PipelineChoice;
