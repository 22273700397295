import React, { CSSProperties } from 'react';

import { Button as AntdButton } from 'antd';

import { FormIdEnum } from '../../../core/enums/entity-ids/form-id.enum';
import { ButtonSize, ButtonType } from '../../interfaces/button-data';
import { ButtonSizeEnum } from '../../enums/button-type.enum';

import './button.component.scss';

interface ButtonProps {
  label?: string;
  icon?: string;
  loading?: boolean;
  btnClass?: string;
  disabled?: boolean;
  style?: CSSProperties;
  htmlType?: ButtonType;
  formId?: FormIdEnum;
  size?: ButtonSize;
  onClick(event?: React.MouseEvent<HTMLElement, MouseEvent>): void; // event: React.MouseEvent<HTMLElement, MouseEvent>
}

export function Button(props: ButtonProps): JSX.Element {
  return (
    <AntdButton
      className={`${props.btnClass}`}
      onClick={props.onClick}
      size={props.size}
      style={props.style}
      icon={props.icon}
      loading={props.loading}
      disabled={props.disabled}
      htmlType={props.htmlType}
      form={props.formId}
    >
      {props.label && <span className='pentugram-btn-label'>{props.label}</span>}
    </AntdButton>
  );
}

Button.defaultProps = {
  icon: null,
  disabled: false,
  loading: false,
  btnClass: '',
  style: {},
  onClick: () => {},
  size: ButtonSizeEnum.Large,
};
