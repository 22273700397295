import React from 'react';
import { SwitchToggle } from '../../TemplateSidebar/SwitchFields';

import './Components.scss';

export const PentugramComponents = props => (
  <div className='Components mt-3 mb-3'>
    {props.divider && <hr />}
    {props.items.map((it, idx) => (
      <span
        key={idx + 1}
        className='item-details'
        onClick={() => {
          props.onAdd(it);
          props.closePopover();
        }}
      >
        <div className='display-flex-start'>
          {it.icon && <i className='material-icons material-icons-details mr-3'>{it.icon}</i>}
          <span>{it.label}</span>
        </div>
      </span>
    ))}
  </div>
);

export const Layouts = props => (
  <div className='Components mt-3 mb-3'>
    {props.items.map((it, idx) => (
      <div key={idx + 1}>
        {it.divider ? (
          <hr />
        ) : (
          <div>
            {it.toggle ? (
              <div className='item-details'>
                <SwitchToggle label={it.label} defaultChecked={props.tools[it.field]} onChange={e => props.updateTools(it, e)} />
              </div>
            ) : (
              <span
                className='item-details'
                onClick={() => {
                  props.onAdd(it);
                  props.closePopover();
                }}
              >
                <i className='material-icons'>{it.icon}</i>
                {it.label}
              </span>
            )}
          </div>
        )}
      </div>
    ))}
    {props.children}
  </div>
);
