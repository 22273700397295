export const required = value => (value ? undefined : 'This field is required');
export const notRequired = value => undefined;
export const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
export const valueLessThan = min => value => (value && value > min ? `Must be less than ${min}` : undefined);
export const valueGreaterThan = min => value => (value && value <= min ? `Must be greater than ${min}` : undefined);
export const minLength = value => {
  return value ? (value.length <= 5 ? `Must be 6 characters or more` : undefined) : 'This field is required';
};
// value && value.length <= min ? `Must be ${min} characters or more` : undefined;
export const number = value => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);
export const percentage = value => {
  if (!value) return undefined;
  const str = `${value}`;
  return str.match(/^(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/) ? undefined : 'Must be a number between 0 and 100';
};
export const email = value => {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(String(value).toLowerCase()) ? undefined : 'Please enter a valid email address';
};
export const url = value => {
  var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  var pattern = new RegExp(expression);
  if (!value) return value;
  return pattern.test(value) ? undefined : 'Please enter a valid URL.';
};

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const period = value => (value && value.period ? undefined : 'Period is required');
export const year = value => (value && value.year ? undefined : 'Year is required');

export const rateQuarter = value => {
  return value && value.split('-')[0] && value.split('-')[1] ? undefined : 'Year/Quarter is required';
};

export const checkbox = value => !value;

export const metricNotValid = value => 'Metric not valid';
export const metricValid = value => undefined;

export const multiselect = value => (Array.isArray(value) && value.length ? undefined : 'This field is required');
