import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { FormIdEnum } from '../../../core/enums/entity-ids/form-id.enum';

// interface FormProps {
//   // tslint:disable-next-line: no-any
//   initialValues: { [key: string]: any };
//   formId: FormIdEnum;
//   children: React.ReactNode;
//   className?: string;
//   // tslint:disable-next-line: no-any
//   onSubmit: (data: any) => void;
// }

class HocForm extends React.Component {
  render() {
    const { handleSubmit, formId, onSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(onSubmit)} id={formId} className={this.props.className}>
        {this.props.children}
      </form>
    );
  }
}

// tslint:disable-next-line: typedef
function mapStateToProps(_, ownProps) {
  return {
    form: ownProps.formId,
  };
}

export const Form = compose(connect(mapStateToProps, {}), reduxForm({ enableReinitialize: true }))(HocForm);
