import moment from 'moment';
import React, { Component } from 'react';
import NotificationsTable from '../Table/NotificationsTable.js';

import './NotificationsList.scss';

export default class NotificationsList extends Component {
  constructor(props) {
    super(props);
  }

  formatDate(date) {
    return date
      ? moment(date * 1000)
          .tz(this.props.timezone)
          .format('lll')
      : 'Not specified';
  }

  parseNotifications(notifs = []) {
    return notifs.map(notif => {
      return {
        ...notif,
        createdAt: this.formatDate(notif.created_at),
      };
    });
  }

  render() {
    return (
      <div className='NotificationsList'>
        <NotificationsTable
          items={this.parseNotifications(this.props.notifs)}
          pagination={true}
          onPageChange={this.props.reloadData}
          loading={this.props.loading}
          meta={this.props.meta}
          tableOptions={this.props.tableOptions}
          redirectTo={this.props.redirectTo}
        />
      </div>
    );
  }
}
