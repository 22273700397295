//@ts-ignore
import { Mixpanel } from 'App/app';
import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { all } from '@redux-saga/core/effects';

import { ErrorResponseData } from '../../../../core/models/http-params.type';
import { PipedriveForCreateModel } from '../../../../core/models/settings/integrations/pipedrive/pipedrive-for-create';
import { SlackIntegrationModel } from '../../../../core/models/settings/integrations/slack/slack-for-create';
import { ZoomIntegrationModel } from '../../../../core/models/settings/integrations/zoom/zoom-for-create';
import { connectPipedrive, connectGoogleCalendar, connectSlack, connectZoom, downloadTemplate, uploadExcelTemplate } from '../../../../core/services/integrations.service';
import { CalendarForCreateModel } from '../../../../core/models/settings/integrations/google/calendar/calendar-for-create';
import { IntegrationExcelData } from '../../../../core/models/integration-excel/integration-excel-data';

import { notification } from '../../../../shared/components';
import { NotificationTypeEnum } from '../../../../shared/enums/notification-type.enum';
import { setSubmitted, closeModal } from '../../../../shared/components/Modal/state/modal.actions';

import { actions } from '../../../../../actions/authActions';
import * as integrationActions from './integration.actions';
// @ts-expect-error
import { Notifier } from 'SharedComponent/Notifier';

function* connectPipedriveEffect({ payload: { token } }: PayloadAction<{ token: PipedriveForCreateModel }>): Generator {
  try {
    yield put(setSubmitted({ submitted: true }));
    yield connectPipedrive(PipedriveForCreateModel.mapToApi(token));
    yield put(integrationActions.connectPipedriveSuccess());
    notification({ type: NotificationTypeEnum.Info, message: 'Pipedrive Data imported successfully' });
    Mixpanel.track('Pipedrive Data imported successfully');
    yield put(setSubmitted({ submitted: false }));
    yield put(closeModal());
    // @ts-ignore
    yield put(actions.checkToken());
  } catch (error) {
    yield put(closeModal());
    yield put(setSubmitted({ submitted: false }));
    yield put(integrationActions.connectPipedriveFail(error));
    Mixpanel.track('Pipedrive Data import failed');
  }
}
function* connectSlackEffect({ payload }: PayloadAction<SlackIntegrationModel>): Generator {
  try {
    yield connectSlack(SlackIntegrationModel.mapToApi(payload));
    yield put(integrationActions.connectSlackSuccess());
    notification({ type: NotificationTypeEnum.Info, message: 'Slack connected successfully' });
    Mixpanel.track('Slack connected successfully');
  } catch (error) {
    yield put(integrationActions.connectSlackFail(error));
    Mixpanel.track('Slack connection failed');
  }
}

function* connectZoomEffect({ payload }: PayloadAction<ZoomIntegrationModel>): Generator {
  try {
    yield connectZoom(ZoomIntegrationModel.mapToApi(payload));
    yield put(integrationActions.connectZoomSuccess());
    notification({ type: NotificationTypeEnum.Info, message: 'Zoom connected successfully' });
    Mixpanel.track('Zoom connected successfully');
    // @ts-ignore
    yield put(actions.checkToken());
  } catch (error) {
    Mixpanel.track('Zoom connection failed');
    yield put(integrationActions.connectZoomFail(error));
  }
}

function* connectCalendarEffect({ payload }: PayloadAction<CalendarForCreateModel>): Generator {
  try {
    yield connectGoogleCalendar(CalendarForCreateModel.mapToApi(payload));
    yield put(integrationActions.connectCalendarSuccess());
    notification({ type: NotificationTypeEnum.Info, message: 'Google Calendar connected successfully' });
    yield put(closeModal());
    // @ts-ignore
    yield put(actions.checkToken());
    Mixpanel.track('Google Calendar connected successfully');
  } catch (error) {
    yield put(closeModal());
    Mixpanel.track('Google Calendar connection failed');
    yield put(integrationActions.connectCalendarFail(error));
  }
}

function* uploadTemplateEffect({ payload }: PayloadAction<IntegrationExcelData>): Generator {
  try {
    yield put(setSubmitted({ submitted: true }));

    yield uploadExcelTemplate({ body: IntegrationExcelData.mapToApi(payload) });

    // @ts-ignore
    yield put(actions.checkToken());

    yield put(setSubmitted({ submitted: false }));
    yield put(closeModal());
    Notifier({
      type: 'success',
      title: 'Template uploaded',
      message: 'Template uploaded successfully',
    });
    Mixpanel.track('Template excel uploaded successfully');
  } catch (error) {
    yield put(integrationActions.uploadTemplateFail(error));
    yield put(setSubmitted({ submitted: false }));
    console.log('error', error);
    Notifier({
      type: 'error',
      title: 'Error',
      message: error,
    });
    Mixpanel.track('Template excel upload failed');
  }
}

function* downloadTemplateEffect({ payload }: PayloadAction<boolean>): Generator {
  try {
    yield downloadTemplate(payload);
  } catch (error) {}
}

function* failEffect({ payload }: PayloadAction<ErrorResponseData>): Generator {
  try {
    notification({ type: NotificationTypeEnum.Error, message: payload.message });
  } catch (error) {}
}

export function* watchIntegration(): Generator {
  yield all([
    takeLatest(integrationActions.connectPipedrive.type, connectPipedriveEffect),
    takeLatest(integrationActions.connectSlack.type, connectSlackEffect),
    takeLatest(integrationActions.connectCalendar.type, connectCalendarEffect),
    takeLatest(integrationActions.connectZoom.type, connectZoomEffect),
    takeLatest(integrationActions.downloadTemplate.type, downloadTemplateEffect),
    takeLatest(integrationActions.uploadTemplate.type, uploadTemplateEffect),
    takeLatest(
      [
        integrationActions.connectPipedriveFail.type,
        integrationActions.connectSlackFail.type,
        integrationActions.connectCalendarFail.type,
        integrationActions.connectZoomFail.type,
        integrationActions.uploadTemplateFail.type,
      ],
      failEffect,
    ),
  ]);
}
