import React from 'react';
import { Empty as AntdEmpty } from 'antd';

interface EmptyProps {
  image: string;
  description: string;
}

export const Empty = (props: EmptyProps): JSX.Element => {
  return <AntdEmpty description={props.description} image={props.image} />;
};

Empty.defaultProps = {
  image: AntdEmpty.PRESENTED_IMAGE_SIMPLE,
  description: 'No data',
};
