import React, { Component } from 'react';
import HandleUploadFile from './../HandleUploadFile/HandleUploadFile';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';
import { appActions, appCategories, appEvents } from 'GoogleAnalytics/eventArgs';

import './ImageUpload.scss';
export default class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      isLoading: false,
      removed: false,
    };
  }
  static defaultProps = {
    appCategory: appCategories.forms,
  };
  handleLoading = isLoading => {
    this.setState({ isLoading }, () => this.props.loading(isLoading));
    this.props.onChange({});
  };

  handleUpload = url => {
    this.setState({ image: url, removed: false }, () => this.props.onChange(url));
  };
  handleRemove = () => {
    this.setState({ image: null, removed: true }, () => this.props.onChange(null));
  };
  render() {
    const image = this.state.removed || this.state.image ? this.state.image || '' : this.props.value || '';
    return (
      <div className='avatar-upload'>
        <div>
          <div className='avatar-edit' title='Upload image'>
            <HandleUploadFile isLoading={this.handleLoading} url={this.handleUpload}>
              <div
                onClick={() => {
                  gaEvent(this.props.appCategory, appActions('UPLOAD_IMAGE', appEvents.clicked).button);
                }}
              >
                <input type='file' id='imageUpload' accept='.png, .jpg, .jpeg' />
              </div>
              <label htmlFor='imageUpload' className='icon-note' />
            </HandleUploadFile>
          </div>
          <div className='avatar-preview'>
            <i className='material-icons remove-img-icon' title='Remove image' onClick={this.handleRemove}>
              cancel
            </i>
            {this.state.isLoading ? (
              <div className='display-flex-center'>
                <div className='lds-roller'>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            ) : (
              <div
                id='imagePreview'
                className='text-muted'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundImage: `url(${image} )`,
                }}
              >
                {' '}
                {!image ? 'No image.' : null}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
