import moment from 'moment';
import classNames from 'classnames';
import React, { Component } from 'react';
import defaultImage from '../../../../assets/img/default.png';

class NotificationItem extends Component {
  redirectNotification() {
    if (this.props.deal_id) {
      this.props.redirect();
    }
  }

  render() {
    const { props } = this;
    return (
      <li className={classNames('media', props.deal_id && 'cursor-pointer')} onClick={() => this.redirectNotification()}>
        <a>
          <div className='pull-left'>
            <img src={(props.user && props.user.image) || defaultImage} className='media-object' alt='' />
          </div>
          <div className='media-body'>
            <h6 className='media-heading'>{props.user && props.user.fullname}</h6>
            <p>
              {props.message} {props.deal_id && <span>{props.deal_name}</span>}
            </p>
            <div className='text-muted'>{moment(props.created_at * 1000).fromNow()}</div>
          </div>
        </a>
      </li>
    );
  }
}

export default NotificationItem;
