/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NewsDTO {
  news_id?: number;
  news_website_name?: NewsDTO.NewsWebsiteNameEnum;
  news_link?: string;
  news_image_url?: string;
  news_title?: string;
  new_website_url?: string;
  news_time?: number;
  is_saved?: boolean;
}
export namespace NewsDTO {
  export type NewsWebsiteNameEnum = 'MenaByte' | 'techCrunch' | 'magnitt' | 'Wamda';
  export const NewsWebsiteNameEnum = {
    MenaByte: 'MenaByte' as NewsWebsiteNameEnum,
    TechCrunch: 'techCrunch' as NewsWebsiteNameEnum,
    Magnitt: 'magnitt' as NewsWebsiteNameEnum,
    Wamda: 'Wamda' as NewsWebsiteNameEnum,
  };
}
