import { AnyAction } from '@reduxjs/toolkit';
import { all, put, takeLatest } from 'redux-saga/effects';

import * as routeActions from './router.actions';
import { mapRouterStateToRouterDetails } from '../../core/models/router/router-details';

function* onRouteChange({ payload }: AnyAction): Generator {
  yield put(routeActions.routerChangeSuccess({ routerState: mapRouterStateToRouterDetails(payload) }));
}

export function* watchRoutes(): Generator {
  yield all([takeLatest(routeActions.routerChange, onRouteChange)]);
}

// function buildBreadCrumb(segments: RouteSegment[]): BreadCrumbData[] {
//   const routeSegments = ['/'];
//   const breadCrumbs = segments.map(segment => {
//     routeSegments.push(segment.url);

//     return segment.data && segment.data[RouteDataEnum.breadCrumb]
//       ? ({
//           routeId: segment.data[RouteDataEnum.routeId],
//           segments: routeSegments.slice(),
//           params: segment.params,
//           icon: '',
//           label: '',
//         } as BreadCrumbData)
//       : undefined;
//   });

//   return lodash.uniqBy(lodash.compact(breadCrumbs), RouteDataEnum.routeId);
// }
