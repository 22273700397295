export const apiUrl = '/v2';

export enum ApiUrlsEnum {
  Pipelines = '/pipelines',
  AllPipelines = '/pipelines/all',
  StageByPipeline = '/pipelines/{ pipelineId }/stages',
  LightStageByPipeline = '/pipelines/{ pipelineId }/stages/light',
  GridDealsByPipeline = '/pipelines/{ pipelineId }/deals', // fixme: change url v2.0
  SummaryActivity = '/notifications/dashboard-notifications',
  SummaryNotes = '/notes/dashboard-notes',
  SummaryEmails = '/emails/dashboard-emails',
  SummaryFiles = '/files/dashboard-files',
  LightDeals = '/deals/light',
  LightUsers = '/users/light',
  ActiveKpis = '/kpis/active-kpis',
  AllKpis = '/kpis/all-kpis',
  ReorderKpis = '/kpis/set-active-kpis',
  News = '/news/all',
  SavedNews = '/news/saved',
  RemoveSavedNews = '/news/{ newsId }/remove',
  SaveNews = '/news/{ newsId }/save',
  Tags = '/news/tags/all',
  AddTag = '/news/tags',
  RemoveTag = '/news/tags/{ tagId }/remove',
  DashboardTaskLazy = '/tasks/dashboard-tasks',
  Task = '/tasks/{ uuid }',
  DeleteTask = '/tasks/{ uuid }',
  TaskStatus = '/tasks/{ uuid }/update-status',
  UpdateTask = '/tasks/{ uuid }/update',
  Tasks = '/tasks',
  LazyTasks = '/tasks/lazy',
  ZoomTask = '/zoom/meeting-task',
  ZoomCallUrl = '/zoom/meeting-url/{ id }',
  VoteTask = '/tasks/{ uuid }/deal-vote',
  CalenderTasks = '/tasks/calendar',
  ConnectPipedrive = '/migration/pipedrive',
  ConnectSlack = '/slack/callback',
  ConnectGoogleCalendar = '/google-calendar',
  ConnectZoom = '/zoom',
  DownloadTemplate = '/import/download-deal-template',
  UploadExcelTemplate = '/import/import-from-deal-template',
  Note = '/notes/{ uuid }',

  // Permissions
  Roles = '/authorization/roles',
  PermissionsByRole = '/authorization/roles/{ roleId }/permissions',
  SetPermissions = '/authorization/roles/{ roleId }/set-permission',
  CreateRole = '/authorization/roles',
  Role = '/authorization/roles/{ roleId }',
  DuplicateRole = '/authorization/roles/{ roleId }/duplicate',
}
