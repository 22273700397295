import moment from 'moment';

const getYears = (start, end) => {
  if (!start) return [];
  return fillRange(start, end);
};

const fillRange = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((item, index) => {
      return { label: start + index, value: start + index };
    });
};

function getWeeks(year, start) {
  const weeksCount = moment(year).weeksInYear();
  const weeks = Array(weeksCount)
    .fill()
    .map((item, index) => {
      var day = moment().startOf('year').add(index, 'weeks');
      var weekStart = day.startOf('isoWeek').format('MMM Do');
      var weekEnd = day.endOf('isoWeek').format('MMM Do');
      return {
        value: index + 1,
        label: `Week ${index + 1}: ${weekStart} to ${weekEnd}`,
      };
    });

  return weeks.filter(it => it.value >= start);
}

export const years = (start, end) => getYears(start, end).reverse();

export const months = start => monthList.filter(it => it.value >= start);

export const quarters = start => quarterList.filter(it => it.value >= start);

export const weeks = (year, start) => getWeeks(year, start);

let quarterList = [
  { value: 1, label: '1st quarter' },
  { value: 2, label: '2nd quarter' },
  { value: 3, label: '3rd quarter' },
  { value: 4, label: '4th quarter' },
];

let monthList = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

export const frequencies = (startYear, endYear, year, startPeriod) => {
  return {
    1: weeks(year, startPeriod),
    2: months(startPeriod),
    3: quarters(startPeriod),
    4: years(startYear, endYear),
  };
};
