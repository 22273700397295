import React, { Component } from 'react';
import TemplateNavbar from '../TemplateNavbar';

import './TemplateEditorContext.scss';

export default class TemplateEditorContext extends Component {
  render() {
    return (
      <div className='TemplateEditorContext'>
        <TemplateNavbar />
        <div className='content-details'>{this.props.children}</div>
      </div>
    );
  }
}
