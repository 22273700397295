import React, { Component } from 'react';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import Editor from '../../../ReportingTool/Editor';

import domtoimage from 'Utils/domtoimage.js';
import jsPDF from 'jspdf';

import './ViewReport.scss';
import get from 'lodash/get';

class ViewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.tocPage = 1;
  }

  RecursiveDownLoad(pdf, page) {
    if (this.props.report.length < page) {
      throw new Error('');
    }

    const node = document.getElementById(`report_page_${page}`);
    const nextNode = document.getElementById(`report_page_${page + 1}`);

    domtoimage
      .toJpeg(node)
      .then(dataUrl => {
        this.handleTableOfContentLinks(pdf, page);
        pdf.addImage(dataUrl, 'PNG', 0, 0);
        if (this.props.report.length == page) {
          this.setState({ loading: false });
          pdf.setPage(this.tocPage);
          pdf.save(`${this.props.tools.title}.pdf`);
        } else {
          pdf.addPage('a4', nextNode.getAttribute('data-orientation'));
          this.RecursiveDownLoad(pdf, page + 1);
        }
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  downLoadAsPDF() {
    const firstPageOrientation = get(this.props, 'report.0.orientation') ? 'l' : 'p';
    const pdf = new jsPDF(firstPageOrientation, 'mm', 'a4', true, true);
    this.setState({ loading: true });
    this.RecursiveDownLoad(pdf, 1);
  }

  handleTableOfContentLinks = (pdf, page) => {
    const tocQuery = `#report_page_${page} #table_of_contents`;
    try {
      let pageHasTOC = document.querySelector(tocQuery);
      let report = this.props.report[page - 1];
      if (pageHasTOC) {
        let toc_content = report[pageHasTOC.getAttribute('data-id')];
        let {
          properties: {
            location: { x, y },
            size: { width },
          },
          content_table,
        } = toc_content;
        let count = 1;
        for (const heading of content_table) {
          let mmX = this.PxToMm(x);
          let mmY = this.PxToMm(y + 40);
          let headingHeight = this.PxToMm(20);
          let headingWidth = this.PxToMm(width);
          let headingYLocation = mmY + this.PxToMm(30) * count;
          pdf.link(mmX, headingYLocation, headingWidth, headingHeight, { pageNumber: heading.page_number, top: 0, zoom: 0, magFactor: 'Fit' });
          count++;
        }
        this.tocPage = page;
      }
    } catch (error) {}
    return;
  };

  PxToMm = px => {
    var div = document.createElement('div');
    div.style.display = 'block';
    div.style.height = '100mm';
    document.body.appendChild(div);
    var mm = parseFloat(window.getComputedStyle(div, null).height);
    div.parentNode.removeChild(div);
    return parseFloat((px / (mm / 100)).toFixed(3));
  };
  render() {
    if (!this.props.show) return null;
    return (
      <div className='ViewReport'>
        <DialogBox isVisible={true} onClickOut={this.props.onClose} onClose={this.props.onClose} withoutClose={true} className='small-dialog'>
          <div className='modal-body'>
            <div>
              {this.props.report.map((report, idx) => {
                let objects = { ...report, ...this.props.shared };
                return (
                  <div className='mb-3' key={idx + 1}>
                    <p>
                      Page {idx + 1} of {this.props.tools.pages}
                    </p>
                    <Editor
                      objects={{ ...report, ...this.props.shared }}
                      tools={this.props.tools}
                      loading={false}
                      editorPageNumber={idx + 1}
                      selected={null}
                      dropElement={() => {}}
                      makeElementActive={() => {}}
                      addToScene={() => {}}
                      loadTemplate={() => {}}
                      loadTemplate={() => {}}
                      updateEditorProps={() => {}}
                      mode='view'
                      id={`report_page_${idx + 1}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className='hr-details'>
            <div className='m-3 display-flex-start'>
              <SubmitButton
                label='Cancel'
                icon='icon-close'
                style={{
                  width: '150px',
                  color: '#53627c',
                  backgroundColor: '#fff',
                  border: '1px solid #e1e4e8',
                }}
                onClick={() => this.props.onClose()}
              />
              <div className='ml-2'>
                {this.state.loading ? (
                  <div className='spin-details display-flex-center'>
                    <div className='pentugram-loader'>Loading...</div>
                    <div className='spin-txt ml-2'>Please wait...</div>
                  </div>
                ) : (
                  <SubmitButton label='Download as PDF' icon='icon-cloud-download' disabled={this.state.loading} style={{ width: '150px' }} onClick={() => this.downLoadAsPDF()} />
                )}
              </div>
            </div>
          </div>
        </DialogBox>
      </div>
    );
  }
}

export default ViewReport;
