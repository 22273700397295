import classNames from 'classnames';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import TextField from 'SharedComponent/TextField';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './ActionsModal.scss';

class ActionsModal extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 0,
    };

    this.getElement = this.getElement.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getElement() {
    switch (this.state.activeTab) {
      case 0:
        return <ShareComponent initialValues={this.props.initialValues} />;
      case 1:
        return <IFrameComponent initialValues={this.props.initialValues} />;
      case 2:
        return <DeleteComponent initialValues={this.props.initialValues} />;
    }
  }

  onSave() {
    this.props.remove(this.props.initialValues);
    this.props.onHide();
  }

  onClickCancelBtn() {
    gaEvent(appCategories.settings_forms, appActions(`ACTIONS_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    this.props.onHide();
  }

  render() {
    if (!this.props.show) return null;
    const tabs = [
      {
        value: 0,
        label: 'Share',
        index: 0,
        icon: 'link',
        className: 'Tabs__tab Tab',
        lg: true,
        onClick: () => {
          this.toggle(0);
          gaEvent(appCategories.settings_forms, appActions(`ACTIONS_${appTypes.modal}_SHARE`, appEvents.clicked).tab);
        },
      },
      {
        value: 1,
        label: 'Integrate',
        index: 1,
        icon: 'code',
        className: 'Tabs__tab Tab',
        lg: true,
        onClick: () => {
          this.toggle(1);
          gaEvent(appCategories.settings_forms, appActions(`ACTIONS_${appTypes.modal}_INTEGRATE`, appEvents.clicked).tab);
        },
      },
      {
        value: 2,
        label: 'Delete',
        index: 2,
        icon: 'delete',
        className: 'Tabs__tab Tab',
        lg: true,
        onClick: () => {
          this.toggle(2);
          gaEvent(appCategories.settings_forms, appActions(`ACTIONS_${appTypes.modal}_${appTypes.delete}`, appEvents.clicked).tab);
        },
      },
      {
        value: 4,
        label: 'Export',
        index: 4,
        icon: 'cloud_download',
        className: 'small-tab',
        lg: false,
        onClick: () => {
          this.props.onHide();
          this.props.export(this.props.initialValues);
          gaEvent(appCategories.settings_forms, appActions(`ACTIONS_${appTypes.modal}_CLOUD_DOWNLOAD`, appEvents.clicked).tab);
        },
      },
      {
        value: 3,
        label: 'Edit',
        index: 3,
        icon: 'create',
        className: 'small-tab',
        lg: false,
        onClick: () => {
          gaEvent(appCategories.settings_forms, appActions(`ACTIONS_${appTypes.modal}_${appTypes.edit}`, appEvents.clicked).tab);
          this.props.onEdit(this.props.initialValues);
        },
      },
    ];
    return (
      <div className='ActionsModal'>
        <DialogBox isVisible onClickOut={this.props.onHide} onClose={this.props.onHide} withoutClose={true} className=''>
          <div>
            <div>
              <div className='Panel'>
                <nav>
                  <ul className='Tabs'>
                    {tabs.map(tab => (
                      <Tab key={tab.value} {...tab} activeTab={this.state.activeTab} toggle={idx => this.toggle(idx)} />
                    ))}
                    <li className='Tabs__presentation-slider' role='presentation' />
                  </ul>
                </nav>
                <div className='Panel__body'>{this.getElement()}</div>
              </div>
            </div>
          </div>
          <div className='display-flex-start'>
            <div className='m-3'>
              <SubmitButton
                label='Cancel'
                icon='icon-close'
                style={{
                  width: '150px',
                  color: '#53627c',
                  backgroundColor: '#fff',
                  border: '1px solid #e1e4e8',
                }}
                onClick={() => this.onClickCancelBtn()}
              />
            </div>
            {this.state.activeTab === 2 && (
              <div className='m-3'>
                <Button
                  onClick={() => {
                    gaEvent(appCategories.settings_forms, appActions(`ACTIONS_${appTypes.modal}_${appTypes.delete}`, appEvents.clicked).button);
                    this.props.onHide();
                    this.props.remove(this.props.initialValues);
                  }}
                  type='submit'
                  className='submit-btn-p'
                  style={{
                    width: '150px',
                    backgroundColor: '#ff8181',
                  }}
                >
                  <i className='icon-trash mr-2' />
                  Delete
                </Button>
              </div>
            )}
          </div>
        </DialogBox>
      </div>
    );
  }
}

const ShareComponent = props => {
  return (
    <div className='mt-4'>
      <div className='content-tab-text'>Form link</div>
      <Field
        name='link'
        component={TextField}
        type='text'
        placeholder=''
        withIcon={false}
        disabled
        copyToClipboard={true}
        googleAnayticsEvent={() => gaEvent(appCategories.settings_forms, appActions(`ACTIONS_${appTypes.modal}_FORM_LINK_${appTypes.copyToClipboard}`, appEvents.clicked).icon)}
      />
    </div>
  );
};

const IFrameComponent = props => {
  return (
    <div>
      <div className='mt-4'>
        <div className='content-tab-text'>Integrate HTML code</div>
        <Field
          name='iframe'
          component={TextField}
          type='text'
          placeholder=''
          withIcon={false}
          disabled
          copyToClipboard={true}
          googleAnayticsEvent={() =>
            gaEvent(appCategories.settings_forms, appActions(`ACTIONS_${appTypes.modal}_INTEGRATE_HTML_CODE_${appTypes.copyToClipboard}`, appEvents.clicked).icon)
          }
        />
      </div>
    </div>
  );
};

const DeleteComponent = props => {
  return (
    <div className='text-center content-tab-text'>
      Are you sure you want to delete Form "<strong>{props.initialValues.title}</strong>" ?
    </div>
  );
};

const Tab = props => (
  <li className={classNames(props.className, props.activeTab === props.index && 'active')} onClick={props.onClick}>
    <div className='display-flex-center p-3'>
      <span className='material-icons'>{props.icon}</span>
      {props.lg && <span className='ml-2'>{props.label}</span>}
    </div>
  </li>
);

ActionsModal = reduxForm({
  form: 'share-integrate-form',
  enableReinitialize: true,
})(ActionsModal);
export default ActionsModal;
