import React, { Component } from 'react';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './ConfirmationDialog.scss';

class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.onDismiss();
  }

  handleAction() {
    this.props.remove(this.props.template);
    this.closeModal();
    gaEvent(appCategories.settings_emailTemplates, appActions(`${appTypes.delete}_${appTypes.modal}_${appTypes.delete}`, appEvents.clicked).button);
  }

  onCloseDialogBox() {
    gaEvent(appCategories.settings_emailTemplates, appActions(`${appTypes.delete}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
    this.closeModal();
  }

  onClickCancelBtn() {
    gaEvent(appCategories.settings_emailTemplates, appActions(`${appTypes.delete}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    this.closeModal();
  }

  render() {
    if (!this.props.showDetails) return null;
    return (
      <div className='ConfirmationDialog'>
        <DialogBox isVisible onClickOut={this.closeModal} onClose={() => this.onCloseDialogBox()} className='small-dalog' title='Delete Email Template'>
          <div className='p-3'>
            <span className='font-size-16 font-weight-500'>
              Are you sure you want to delete the template
              <span className='font-size-16 font-weight-bold'> "{this.props.template ? this.props.template.name : 'N/A'}"</span>?
            </span>
          </div>
          <div className='display-flex-end'>
            <div className='m-3'>
              <SubmitButton
                label='Cancel'
                icon='icon-close'
                style={{
                  width: '150px',
                  color: '#53627c',
                  backgroundColor: '#fff',
                  border: '1px solid #e1e4e8',
                }}
                onClick={() => this.onClickCancelBtn()}
              />
            </div>
            <div className='m-3'>
              <SubmitButton
                label='Delete'
                icon='icon-trash'
                style={{
                  width: '150px',
                  backgroundColor: '#ff8181',
                }}
                onClick={() => this.handleAction()}
              />
            </div>
          </div>
        </DialogBox>
      </div>
    );
  }
}

export default ConfirmationDialog;
