import { NewsDTO } from '../../generated/model/models';

export enum WebsiteEnum {
  MenaByte = 1,
  TeckCrunch = 2,
  Magnitt = 3,
  Wamda = 4,
}

export namespace WebsiteEnum {
  export function mapFromApi(value: NewsDTO.NewsWebsiteNameEnum): WebsiteEnum {
    switch (value) {
      case NewsDTO.NewsWebsiteNameEnum.Magnitt:
        return WebsiteEnum.Magnitt;
      case NewsDTO.NewsWebsiteNameEnum.TechCrunch:
        return WebsiteEnum.TeckCrunch;
      case NewsDTO.NewsWebsiteNameEnum.MenaByte:
        return WebsiteEnum.MenaByte;
      case NewsDTO.NewsWebsiteNameEnum.Wamda:
        return WebsiteEnum.Wamda;
      default:
        return null;
    }
  }
  export function toString(value: WebsiteEnum): string {
    switch (value) {
      case WebsiteEnum.Magnitt:
        return 'magnitt';
      case WebsiteEnum.TeckCrunch:
        return 'techCrunch';
      case WebsiteEnum.MenaByte:
        return 'MenaByte';
      case WebsiteEnum.Wamda:
        return 'Wamda';
      default:
        return null;
    }
  }
}
