import api from './api';
import { Notifier } from 'SharedComponent/Notifier';
import { actions } from './authActions';
export const IS_LOADING = 'integrationSettings:loading';
export const IS_LOADING_FAILED = 'integrationSettings:load_failed';
export const LOADING_SPINNER = 'integrationSettings:loading:spinner';
export const START_SYNC = 'integrationSettings:start:syncing';
export const LOADED_NYLAS_SETTINGS = 'integrationSettings:loaded:nylas:settings';
export const LOADED_CONTACTS_SYNC = 'integrationSettings:loaded:nylas:contacts:sync';
export const LOADED_CALENDAR_SYNC = 'integrationSettings:loaded:nylas:contacts:sync';

export const LOADED_IS_CONTACTS = 'integrationSettings:loaded:contacts';
export const LOADED_IS_EMAIL = 'integrationSettings:loaded:emails';
export const LOADED_IS_CALENDAR = 'integrationSettings:loaded:calendar';
export const LOADING_CONTACTS_STATS = 'integrationSettings:contacts:loading:stats';
export const LOADED_CONTACTS_STATS = 'integrationSettings:contacts:loaded:stats';
export const LOADING_CONTACTS_GROUPS = 'integrationSettings:contacts:loading:groups';
export const LOADED_CONTACTS_GROUPS = 'integrationSettings:contacts:loaded:groups';
export const LOADED_CALENDARS_LIST = 'integrationSettings:calendars:loaded:list';

export function loadNylasSettings(page = 1) {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    api
      .get(`/nylas/setting?page_code=${page}`)
      .then(({ data }) => {
        dispatch({
          type: LOADED_NYLAS_SETTINGS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({ type: IS_LOADING, payload: false });
        Notifier({
          type: 'error',
          title: 'Error',
          message: `Couldn't load sync settings. Please try again later or contact support.`,
        });
      });
  };
}

export function reactivateAccount(account_id, page_code) {
  return dispatch => {
    dispatch({ type: LOADING_SPINNER, payload: true });
    api
      .post('/nylas/reactivate', { account_id, page_code })
      .then(({ data }) => {
        dispatch({
          type: LOADED_NYLAS_SETTINGS,
          payload: data,
        });
        Notifier({
          type: 'info',
          title: 'Account reconnected',
          message: `Your email account is connected again with Pentugram.`,
        });
      })
      .catch(error => {
        dispatch({ type: LOADING_SPINNER, payload: false });
        Notifier({
          type: 'error',
          title: 'Error',
          message: `Couldn't reconnect account. Please try again later or contact support.`,
        });
      });
  };
}

export function removeNylasAccount(account_id) {
  return dispatch => {
    dispatch({ type: LOADING_SPINNER, payload: true });
    api
      .post('/nylas/revoke', { account_id })
      .then(({ data }) => {
        dispatch({ type: LOADING_SPINNER, payload: false });
        dispatch({
          type: LOADED_NYLAS_SETTINGS,
          payload: data,
        });
        Notifier({
          type: 'info',
          title: 'Account sync removed',
          message: `Your email account is not synced with Pentugram.`,
        });
      })
      .catch(error => {
        dispatch({ type: LOADING_SPINNER, payload: false });
        Notifier({
          type: 'error',
          title: 'Error',
          message: `Couldn't remove account. Please try again later or contact support.`,
        });
      });
  };
}

export function ActivateContactSync(data, account_id) {
  return dispatch => {
    dispatch({ type: START_SYNC, payload: true });
    api
      .post(`/nylas/contacts/sync`, { ...data, account_id })
      .then(({ data }) => {
        dispatch({ type: START_SYNC, payload: false });
        dispatch({
          type: LOADED_NYLAS_SETTINGS,
          payload: data,
        });
      })
      .catch(({ response }) => {
        let msg = response && response.data && response.data.message;
        dispatch({ type: START_SYNC, payload: false });
        Notifier({
          type: 'error',
          title: 'Error',
          message: msg || `Contacts sync settings couldn't be saved. Please try again.`,
        });
      });
  };
}

export function calendarStats(data) {
  return dispatch => {
    if (data.sync_finished) {
      Notifier({
        type: 'success',
        title: 'Calendar syncing activated',
        message: `Your contacts will be synced with Pentugram...`,
      });
    }
    dispatch({
      type: LOADED_CALENDAR_SYNC,
      payload: data,
    });
  };
}

export function contactStats(data) {
  return dispatch => {
    if (data.sync_finished) {
      Notifier({
        type: 'success',
        title: 'Contacts synced.',
        message: `Your contacts are now synced with Pentugram.`,
      });
    }
    dispatch({
      type: LOADED_CALENDAR_SYNC,
      payload: data,
    });
  };
}

export function loadContactStats(id) {
  return dispatch => {
    dispatch({ type: START_SYNC, payload: true });
    api
      .get(`/nylas/${id}/contacts/stats`)
      .then(({ data }) => {
        dispatch({ type: START_SYNC, payload: false });
        dispatch({
          type: LOADED_CONTACTS_SYNC,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({ type: START_SYNC, payload: false });
        Notifier({
          type: 'error',
          title: "Couldn't load contact sync statistics.",
          message: `Please try again later or contact support.`,
        });
      });
  };
}

export function stopContactSync(account_id) {
  return dispatch => {
    api
      .put(`/nylas/${account_id}/setting`, { sync_status: 0 })
      .then(({ data }) => {
        dispatch({
          type: LOADED_NYLAS_SETTINGS,
          payload: data,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: "Couldn't stop account contact sync.",
          message: `Please try again later or contact support.`,
        });
      });
  };
}

export function stopCalendarSync(id) {
  return dispatch => {
    api
      .put(`/nylas/${id}/setting?page_code=2`, { sync_status: 0 })
      .then(({ data }) => {
        Notifier({
          type: 'info',
          title: 'Calendar sync',
          message: `Contacts sync stopped.`,
        });
        dispatch({
          type: LOADED_NYLAS_SETTINGS,
          payload: data,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Error',
          message: `Couldn't stop calendar sync. Please try again later or contact support.`,
        });
      });
  };
}

export function ActivateEmailSync(account_id) {
  return dispatch => {
    api
      .post(`/nylas/email/sync`, { sync_status: 1, account_id })
      .then(({ data }) => {
        dispatch(actions.checkToken());
        Notifier({
          type: 'success',
          title: 'Success',
          message: `Email account sync is enabled.`,
        });
        dispatch({
          type: LOADED_NYLAS_SETTINGS,
          payload: data,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: "Couldn't activate email sync.",
          message: `Please try again or contact support.`,
        });
      });
  };
}

export function stopEmailSync(id) {
  return dispatch => {
    api
      .put(`/nylas/${id}/setting?page_code=3`, { sync_status: 0 })
      .then(({ data }) => {
        Notifier({
          type: 'info',
          title: 'Email sync',
          message: `Emails sync stopped.`,
        });
        dispatch({
          type: LOADED_NYLAS_SETTINGS,
          payload: data,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Error',
          message: `Couldn't stop account email sync. Please try again later or contact support.`,
        });
      });
  };
}

// -----------------------

export function loadContactSettings() {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    api
      .get(`/nylas/contacts/setting`)
      .then(({ data }) => {
        dispatch({
          type: LOADED_IS_CONTACTS,
          payload: data,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Error',
          message: `Couldn't load contact sync settings. Please try again later or contact support.`,
        });
      });
  };
}

export function loadEmailSettings() {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    api
      .get(`/nylas/email/setting`)
      .then(({ data }) => {
        dispatch({
          type: LOADED_IS_EMAIL,
          payload: data,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Error',
          message: `Couldn't load email sync settings. Please try again later or contact support.`,
        });
      });
  };
}

export function loadContactGroups(id) {
  return dispatch => {
    dispatch({ type: LOADING_CONTACTS_GROUPS, payload: true });
    api
      .get(`/nylas/${id}/contacts/groups`)
      .then(({ data }) => {
        dispatch({
          type: LOADED_CONTACTS_GROUPS,
          payload: data.results,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Error',
          message: `Couldn't load contact lists. Please try again later or contact support.`,
        });
      });
  };
}

export function loadCalendarList(id) {
  return dispatch => {
    // dispatch({ type: LOADING_CONTACTS_GROUPS, payload: true });
    api
      .get(`/nylas/${id}/calendar/list`)
      .then(({ data }) => {
        dispatch({
          type: LOADED_CALENDARS_LIST,
          payload: data.results,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Error',
          message: `Couldn't load calendars list. Please try again later or contact support.`,
        });
      });
  };
}

export function ActivateCalendarSync(account) {
  return dispatch => {
    dispatch({ type: START_SYNC, payload: true });
    api
      .post(`/nylas/calendar/sync`, { ...account })
      .then(({ data }) => {
        dispatch({
          type: LOADED_NYLAS_SETTINGS,
          payload: data,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Error',
          message: `Calendar sync settings couldn't be saved. Please try again.`,
        });
      });
  };
}

export function loadCalendarSettings() {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    api
      .get(`/nylas/calendar/setting`)
      .then(({ data }) => {
        return dispatch({
          type: LOADED_IS_CALENDAR,
          payload: data,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Error',
          message: `Section couldn't be loaded. Try again.`,
        });
      });
  };
}
