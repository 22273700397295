import { IconData, IconPrefixEnum } from '../../../shared/interfaces/icon-data';
import { SelectItemData } from '../../../shared/interfaces/select-item';
import { TaskResponseDTO } from '../../generated/model/taskResponseDTO';
import { IMap } from '../../utils/map-utils';

export enum TaskPriorityEnum {
  Urgent = 'urgent',
  High = 'high',
  Normal = 'normal',
  Low = 'low',
}

export namespace TaskPriorityEnum {
  export const displayedItems = [TaskPriorityEnum.Urgent, TaskPriorityEnum.High, TaskPriorityEnum.Normal, TaskPriorityEnum.Low];

  export const convertFromApiValue: IMap<TaskResponseDTO.TaskPriorityEnum, TaskPriorityEnum> = new IMap<TaskResponseDTO.TaskPriorityEnum, TaskPriorityEnum>(
    [
      [TaskResponseDTO.TaskPriorityEnum.NUMBER_1, TaskPriorityEnum.Urgent],
      [TaskResponseDTO.TaskPriorityEnum.NUMBER_2, TaskPriorityEnum.High],
      [TaskResponseDTO.TaskPriorityEnum.NUMBER_3, TaskPriorityEnum.Normal],
      [TaskResponseDTO.TaskPriorityEnum.NUMBER_4, TaskPriorityEnum.Low],
    ],
    undefined,
  );
  export const convertToApiValue: IMap<TaskPriorityEnum, TaskResponseDTO.TaskPriorityEnum> = new IMap<TaskPriorityEnum, TaskResponseDTO.TaskPriorityEnum>(
    [
      [TaskPriorityEnum.Urgent, TaskResponseDTO.TaskPriorityEnum.NUMBER_1],
      [TaskPriorityEnum.High, TaskResponseDTO.TaskPriorityEnum.NUMBER_2],
      [TaskPriorityEnum.Normal, TaskResponseDTO.TaskPriorityEnum.NUMBER_3],
      [TaskPriorityEnum.Low, TaskResponseDTO.TaskPriorityEnum.NUMBER_4],
    ],
    undefined,
  );
  export const toString: IMap<TaskPriorityEnum, string> = new IMap<TaskPriorityEnum, string>(
    [
      [TaskPriorityEnum.Urgent, 'Urgent'],
      [TaskPriorityEnum.High, 'High'],
      [TaskPriorityEnum.Normal, 'Normal'],
      [TaskPriorityEnum.Low, 'Low'],
    ],
    undefined,
  );
  export const getColor: IMap<TaskPriorityEnum, string> = new IMap<TaskPriorityEnum, string>(
    [
      [TaskPriorityEnum.Urgent, '#ff7b65'],
      [TaskPriorityEnum.High, '#ffc266'],
      [TaskPriorityEnum.Normal, '#9adbad'],
      [TaskPriorityEnum.Low, '#b1afdb'],
    ],
    undefined,
  );
  export const getIcon: IMap<TaskPriorityEnum, IconData> = new IMap<TaskPriorityEnum, IconData>(
    [
      [TaskPriorityEnum.Urgent, { name: 'arrow-up', prefix: IconPrefixEnum.Antd, iconClassName: 'priority-color-urgent' }],
      [TaskPriorityEnum.High, { name: 'arrow-up', prefix: IconPrefixEnum.Antd, iconClassName: 'priority-color-high' }],
      [TaskPriorityEnum.Normal, { name: 'arrow-down', prefix: IconPrefixEnum.Antd, iconClassName: 'priority-color-normal' }],
      [TaskPriorityEnum.Low, { name: 'arrow-down', prefix: IconPrefixEnum.Antd, iconClassName: 'priority-color-low' }],
    ],
    null,
  );

  export function mapToSelectedItem(type: TaskPriorityEnum): SelectItemData<TaskPriorityEnum> {
    return {
      value: type,
      name: toString.getValue(type),
      icon: getIcon.getValue(type),
    };
  }

  export const taskPriorities: SelectItemData<TaskPriorityEnum>[] = displayedItems.map(item => mapToSelectedItem(item));
}
