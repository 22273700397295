import React from 'react';
import DialogBox from 'SharedComponent/DialogBox';
import FormElementsEdit from './form-elements-edit';
import SubmitButton from 'SharedComponent/SubmitButton';

export default class EditModal extends React.Component {
  render() {
    if (!this.props.show) return null;
    return (
      <div className='EditModal pn-ltr'>
        <DialogBox isVisible onClickOut={this.props.onHide} onClose={this.props.onHide} className='small-dialog'>
          <div className='p-3'>
            <FormElementsEdit
              showCorrectColumn={this.props.showCorrectColumn}
              updateElement={this.props.updateElement}
              element={this.props.element}
              preview={this.props.preview}
              files={this.props.files}
              switchElementDeal={this.props.switchElementDeal}
              switchElementContact={this.props.switchElementContact}
              pipelineCustomFields={this.props.pipelineCustomFields}
              contactCustomFields={this.props.contactCustomFields}
            />
            <div>
              <div className='display-flex-end'>
                <div className='m-3'>
                  <SubmitButton label='Save' icon='icon-check' style={{ width: '150px' }} onClick={this.props.onHide} />
                </div>
              </div>
            </div>
          </div>
        </DialogBox>
      </div>
    );
  }
}
