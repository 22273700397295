import React from 'react';
import { Skeleton as AntdSkeleton } from 'antd';
import { SkeletonAvatarProps, SkeletonParagraphProps } from '../../interfaces/skelton-props';

interface SkeletonProps {
  active?: boolean;
  loading: boolean;
  title?: boolean;
  className?: string;
  avatar?: boolean | SkeletonAvatarProps;
  paragraph?: boolean | SkeletonParagraphProps;
}

export function Skeleton(props: SkeletonProps): JSX.Element {
  return <AntdSkeleton active={props.active} loading={props.loading} paragraph={props.paragraph} avatar={props.avatar} title={props.title} className={props.className} />;
}

Skeleton.defaultProps = {
  active: true,
  title: false,
  avatar: false,
  paragraph: false,
};
