import { createReducer, Action, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import moment from 'moment';
import { TasksSummaryData } from '../../../core/models/__task/tasks-summary-data';
import { ErrorResponseData } from '../../../core/models/http-params.type';
import { MetaQuery } from '../../../core/models/queries/meta-query.model';
import { TaskDetailsData } from '../../../core/models/__task/task-details-data';
import { BaseCalenderQuery } from '../../../core/models/queries/base-calender-query';
import { TasksCalenderData } from '../../../core/models/calendar/tasks-calender-data';
import { CalenderTasksModeEnum } from '../../../core/enums/calendar/calender-task-mode.enum';

import * as fromTasksAction from './tasks.actions';

const adapterTaskTable = createEntityAdapter<TasksSummaryData>({
  selectId: task => task.uuid,
});
const adapterTaskCalender = createEntityAdapter<TasksCalenderData>({
  selectId: task => task.uuid,
});

export interface ListTasksState extends EntityState<TasksSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  meta: MetaQuery;
}
export interface ListCalenderTasksState extends EntityState<TasksCalenderData> {
  loading: boolean;
  error: ErrorResponseData;
  currentDate: BaseCalenderQuery;
}
export const tasksInitialState: ListTasksState = adapterTaskTable.getInitialState<ListTasksState>({
  ids: [],
  entities: {},
  meta: { currentPage: 0, perPage: 0, pageCount: 0, totalCount: 0 }, // fixme see with @ahmed
  loading: false,
  error: null,
});
export const calenderTasksInitialState: ListCalenderTasksState = adapterTaskTable.getInitialState<ListCalenderTasksState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
  currentDate: {
    mode: CalenderTasksModeEnum.Month,
    date: moment().unix(),
  },
});
export interface TasksState {
  tasksList: ListTasksState;
  calenderTasksList: ListCalenderTasksState;
  editing: TaskDetailsData;
}
const initialTasksState: TasksState = {
  tasksList: tasksInitialState,
  editing: {} as TaskDetailsData,
  calenderTasksList: calenderTasksInitialState,
};
const _tasksReducer = createReducer<TasksState>(initialTasksState, builder => {
  builder
    .addCase(fromTasksAction.destroyDataTasks, (): TasksState => initialTasksState)
    .addCase(fromTasksAction.resetTasks, (state): TasksState => ({ ...state, editing: {} as TaskDetailsData }))
    .addCase(fromTasksAction.loadAllTasksList, (state): TasksState => ({ ...state, tasksList: { ...state.tasksList, loading: true } }))
    .addCase(fromTasksAction.loadAllTasksNextPage, (state): TasksState => ({ ...state, tasksList: { ...state.tasksList, loading: true } }))
    .addCase(fromTasksAction.getTaskSuccess, (state, { payload: { task } }): TasksState => ({ ...state, editing: task }))
    .addCase(fromTasksAction.loadCalenderTasksCurrentDate, (state): TasksState => ({ ...state, calenderTasksList: { ...state.calenderTasksList, loading: true } }))
    .addCase(fromTasksAction.loadCalenderTasksList, (state): TasksState => ({ ...state, calenderTasksList: { ...state.calenderTasksList, loading: true } }))
    .addCase(
      fromTasksAction.loadAllTasksListSuccess,
      (state, { payload: { list, meta } }): TasksState => {
        const newState: ListTasksState = { ...state.tasksList, loading: false, meta };

        return { ...state, tasksList: adapterTaskTable.setAll(newState, list) };
      },
    )
    .addCase(
      fromTasksAction.deleteTaskSuccess,
      (state, { payload: { uuid } }): TasksState => {
        const newTaskState: ListTasksState = { ...state.tasksList };

        return {
          ...state,
          tasksList: adapterTaskTable.removeOne(newTaskState, uuid),
        };
      },
    )
    .addCase(
      fromTasksAction.updateTaskSuccess,
      (state, { payload }): TasksState => {
        const newState: ListTasksState = { ...state.tasksList };

        return {
          ...state,
          tasksList: adapterTaskTable.updateOne(newState, { id: payload.uuid, changes: payload }),
        };
      },
    )
    .addCase(
      fromTasksAction.loadCalenderTasksListSuccess,
      (state, { payload }): TasksState => {
        const newState: ListCalenderTasksState = { ...state.calenderTasksList, loading: false, currentDate: payload.currentDate };

        return { ...state, calenderTasksList: adapterTaskCalender.setAll(newState, payload.list) };
      },
    )
    .addCase(
      fromTasksAction.addTaskCalenderSuccess,
      (state, { payload }): TasksState => {
        const newState: ListCalenderTasksState = { ...state.calenderTasksList, loading: false };

        return { ...state, calenderTasksList: adapterTaskCalender.addOne(newState, payload) };
      },
    )
    .addCase(
      fromTasksAction.updateCalenderTaskSuccess,
      (state, { payload }): TasksState => {
        const newState: ListCalenderTasksState = { ...state.calenderTasksList };

        return {
          ...state,
          calenderTasksList: adapterTaskCalender.updateOne(newState, { id: payload.uuid, changes: payload }),
        };
      },
    );
});

export function tasksReducer(state: TasksState | undefined, action: Action): TasksState {
  return _tasksReducer(state, action);
}
export const selectAllTasks = adapterTaskTable.getSelectors().selectAll;
export const selectAllCalenderTasks = adapterTaskCalender.getSelectors().selectAll;
