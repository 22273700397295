import React, { CSSProperties } from 'react';
import { Select } from 'antd';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { Error } from '../../../Error/error.component';
import { SelectItemData } from '../../../../interfaces/select-item';
import { Icon } from '../../..';

import '../select-field.component.scss';

const { Option } = Select;

// interface SelectFieldProps {
//   meta: WrappedFieldMetaProps;
//   input: WrappedFieldInputProps;
//   options: SelectItemData[];
//   allowClear: boolean;
//   disabled: boolean;
//   placeholder?: string;
//   style?: CSSProperties;
//   onSelect?(): void;
// }

export function SelectField(props) {
  const handleChange = value => props.input.onChange(value || null);

  return (
    <div className='SelectField'>
      <Error touched={props.meta && props.meta.touched} error={props.meta && props.meta.error} warning={props.meta && props.meta.warning} />
      <Select
        value={props.input.value || undefined}
        onChange={handleChange}
        size='large'
        style={{ width: '100%', ...props.style }}
        placeholder={props.placeholder}
        allowClear={props.allowClear}
        disabled={props.disabled}
        onSelect={props.onSelect}
      >
        {props.options.map(opt => (
          <Option key={opt.value}>
            <div className='label-icon-option'>
              {opt.icon && <Icon iconData={opt.icon} />}
              <span>{opt.name}</span>
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
}

SelectField.defaultProps = {
  allowClear: true,
  disabled: false,
  options: [],
  style: { backgroundColor: '#f8f9fa' },
};
