import React, { Component } from 'react';
import Select from 'react-select';
import { frequencies } from 'Enums/periods';

import './MetricPeriod.scss';
import 'react-select/dist/react-select.css';

export default class MetricPeriod extends Component {
  static defaultProps = {
    flexYear: 'flex-1',
    flexPeriod: 'flex-2',
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  formatInputValue(multiYear, multiPeriod) {
    return { year: '', period: '' };
  }

  checkInput(input) {
    if (Array.isArray(input)) {
      return input.length;
    }
    return input;
  }

  setYear(year) {
    this.props.input.onChange({ year, period: null });
    if (this.props.has_period) {
      if (!year) this.props.onPeriodChange({ year: null, period: null });
    } else this.props.onPeriodChange({ year: year && year.value, period: null });
  }

  setPeriod(period) {
    const value = this.props.input.value ? this.props.input.value : { year: null, period: null };
    this.props.input.onChange({ ...value, period });
    if (period && value && value.year) {
      this.props.onPeriodChange({ year: value.year.value, period: period.value });
    }
  }

  periods(startYear, endYear, value, startPeriod) {
    let startP;
    let splitP;
    if (value && value.year) {
      startP = value.year.value == this.props.startYear ? startPeriod : 1;
      if (this.props.splitPeriods) {
        splitP = value.year.value == this.props.startYear ? 4 : this.props.showOnly;
        return frequencies(startYear, endYear, value.year.value, startP)
          [this.props.frequency].slice(0, splitP)
          .map(i => i);
      } else return frequencies(startYear, endYear, value.year.value, startP)[this.props.frequency];
    } else return [];
  }

  render() {
    const { props, state } = this;
    const value = props.input.value ? props.input.value : { year: null, period: null };
    return (
      <div className='MetricPeriod'>
        {props.haslabel && <div className='label-text'>{props.label}</div>}
        <div className='display-flex-start'>
          <div className={props.flexYear}>
            <Select
              arrowRenderer={arrowRenderer}
              onChange={this.setYear.bind(this)}
              onBlur={() => props.input.onBlur()}
              options={frequencies(props.startYear, props.endYear)[4]}
              disabled={props.disabled}
              closeOnSelect={true}
              clearable={props.clearable}
              placeholder={props.placeholderYear}
              multi={false}
              value={props.input.value.year}
              style={{
                borderTop: props.meta.touched && !this.checkInput(value.year) ? '1px solid #e19393' : '1px solid #ccc',
                borderLeft: props.meta.touched && !this.checkInput(value.year) ? '1px solid #e19393' : '1px solid #ccc',
                borderBottom: props.meta.touched && !this.checkInput(value.year) ? '1px solid #e19393' : '1px solid #ccc',
                borderRight: props.meta.touched && !this.checkInput(value.year) ? '1px solid #e19393' : '1px solid #e6e8ea',
                borderRadius: props.has_period ? '2px 0px 0px 2px' : '2px',
                backgroundColor: props.disabled ? '#f9f9f9' : '#ffffff',
                cursor: props.disabled ? 'not-allowed' : 'default',
              }}
            />
          </div>
          {props.has_period ? (
            <div className={props.flexPeriod}>
              <Select
                arrowRenderer={arrowRenderer}
                onChange={this.setPeriod.bind(this)}
                onBlur={() => props.input.onBlur()}
                options={this.periods(props.startYear, props.endYear, value, props.startPeriod)}
                disabled={props.disabled}
                closeOnSelect={true}
                clearable={props.clearable}
                placeholder={props.placeholder}
                multi={false}
                value={props.input.value.period}
                style={{
                  borderTop: props.meta.touched && !this.checkInput(value.period) ? '1px solid #e19393' : '1px solid #ccc',
                  borderLeft: props.meta.touched && !this.checkInput(value.period) ? '1px solid #e19393' : '1px solid #e6e8ea',
                  borderBottom: props.meta.touched && !this.checkInput(value.period) ? '1px solid #e19393' : '1px solid #ccc',
                  borderRight: props.meta.touched && !this.checkInput(value.period) ? '1px solid #e19393' : '1px solid #ccc',
                  borderRadius: '0px 2px 2px 0px',
                  backgroundColor: props.disabled ? '#f9f9f9' : '#ffffff',
                  cursor: props.disabled ? 'not-allowed' : 'default',
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

function arrowRenderer() {
  return <i className='icon-arrow-down' />;
}
