import React, { Component } from 'react';
import EmailTemplatesHeader from '../EmailTemplatesHeader';
import EmailTemplatesList from '../EmailTemplatesList';

import './EmailTemplatesContext.scss';

class EmailTemplatesContext extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='EmailTemplatesContext'>
        <EmailTemplatesHeader />
        <EmailTemplatesList />
      </div>
    );
  }
}

export default EmailTemplatesContext;
