export const paymentStatus = {
  PENDING: {
    value: 'pending',
    label: 'Pending',
    type: 'default',
    color: '#ffbc34',
  },
  PAID: {
    value: 'approved',
    label: 'Approved',
    type: 'default',
    color: '#06d26f',
  },
  REJECTED: {
    value: 'rejected',
    label: 'Rejected',
    type: 'default',
    color: '#ff7474',
  },
};

const MONTH = 'Monthly';
const YEAR = 'Yearly';

const TRIAL_COLORS = ['#1871D1', '#0095FF'];
const SILVER_COLORS = ['#808080', '#C0C0C0'];
const GOLD_COLORS = ['#FFC92F', '#FF9815'];

const VC_TEST_ID = 591250; // for test
const VC_SILVER_MONTHLY_ID = 'pri_01hqgqngzkk22ns53tfd7vcwck';
const VC_SILVER_YEARLY_ID = 'pri_01hqgqt2hrxpz9r006p64ndzep';
const VC_GOLD_MONTHLY_ID = 'pri_01hqgqvbqx7k66qy2c4yncb76q';
const VC_GOLD_YEARLY_ID = 'pri_01hqgqw64ytjx8jd1a7h86h3ah';

export const TRIAL = {
  id: 'trial',
  name: 'Trial',
  frequency: '',
  tagColor: undefined,
  colors: TRIAL_COLORS,
  order: 1,
};

const VC_TEST_FIELDS = {
  id: VC_TEST_ID,
  tagColor: '',
  frequency: MONTH,
  colors: TRIAL_COLORS,
  order: 2,
  dealLimitMessage: 'Up to 20',
  reportLimitMessage: 'Up to 6',
  allowedReports: 2,
  allowedDeals: 5,
};

const VC_SILVER_MONTHLY_FIELDS = {
  id: VC_SILVER_MONTHLY_ID,
  name: 'Silver',
  tagColor: '',
  frequency: MONTH,
  colors: SILVER_COLORS,
  order: 2,
  dealLimitMessage: 'Up to 20',
  reportLimitMessage: 'Up to 6',
  allowedReports: 2,
  allowedDeals: 5,
};

const VC_SILVER_YEARLY_FIELDS = {
  id: VC_SILVER_YEARLY_ID,
  name: 'Silver',
  tagColor: '',
  frequency: YEAR,
  colors: SILVER_COLORS,
  order: 2,
  dealLimitMessage: 'Up to 20',
  reportLimitMessage: 'Up to 6',
  allowedReports: 2,
  allowedDeals: 5,
};

const VC_GOLD_MONTHLY_FIELDS = {
  id: VC_GOLD_MONTHLY_ID,
  name: 'Gold',
  tagColor: 'gold',
  frequency: MONTH,
  colors: GOLD_COLORS,
  order: 3,
  dealLimitMessage: 'Unlimited',
  reportLimitMessage: 'Unlimited',
  default: true,
};

const VC_GOLD_YEARLY_FIELDS = {
  id: VC_GOLD_YEARLY_ID,
  frequency: YEAR,
  name: 'Gold',
  tagColor: 'gold',
  colors: GOLD_COLORS,
  order: 3,
  dealLimitMessage: 'Unlimited',
  reportLimitMessage: 'Unlimited',
  default: true,
};

const VC_PACKS = {
  TRIAL: TRIAL,
  [VC_SILVER_MONTHLY_ID]: VC_SILVER_MONTHLY_FIELDS,
  [VC_SILVER_YEARLY_ID]: VC_SILVER_YEARLY_FIELDS,
  [VC_GOLD_MONTHLY_ID]: VC_GOLD_MONTHLY_FIELDS,
  [VC_GOLD_YEARLY_ID]: VC_GOLD_YEARLY_FIELDS,
};

export { VC_PACKS };
