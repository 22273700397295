import { Divider, Icon } from 'antd';
import React, { useState } from 'react';
import get from 'lodash/get';

import { FormSection, Field, FieldArray } from 'redux-form';

import { TextArea } from 'SharedComponent/TextArea';
import { CustomCheckbox } from 'SharedComponent/CustomCheckbox';
import InputCustomFields from 'SharedComponent/functions/InputCustomFields';
import { roundFields } from '../../../../enums/roundFields';
import CoInvestors from './CoInvestors';

export default function RoundForm({ formValues, isParticipate, initialValues }) {
  const [roundValuationPanel, setRoundValuationPanel] = useState(false);
  const [coInvestorsPanel, setCoInvestorsPanel] = useState(false);

  const getCustomFields = () => {
    const customFields = [];
    if (get(formValues, 'round.is_participate')) {
      customFields.push(roundFields.investment_type);
      if (get(formValues, 'round.investment_type') === 'Equity') {
        customFields.push(roundFields.number_of_shares);
        customFields.push(roundFields.price_per_share);
        customFields.push(roundFields.amount_invested);
        customFields.push(roundFields.investment_date);
        customFields.push(roundFields.round_stage);
        customFields.push(roundFields.total_raised_amount);
      } else if (get(formValues, 'round.investment_type') === 'Convertible note') {
        customFields.push(roundFields.discount);
        customFields.push(roundFields.valuation_cap);
        customFields.push(roundFields.amount_invested);
        customFields.push(roundFields.investment_date);
        customFields.push(roundFields.round_stage);
        customFields.push(roundFields.total_raised_amount);
      } else if (get(formValues, 'round.investment_type') === 'Loan') {
        customFields.push(roundFields.loan_rate);
        customFields.push(roundFields.amount_invested_loan);
        customFields.push(roundFields.date_of_operation);
        customFields.push(roundFields.date_of_repayment);
        customFields.push(roundFields.round_stage);
      }
    } else {
      customFields.push(roundFields.round_stage);
      customFields.push(roundFields.total_raised_amount);
    }

    return customFields;
  };

  const roundValuation = [
    {
      default: 0,
      field_label: 'Pre-money valuation',
      field_list: null,
      field_name: 'pre_money_valuation',
      field_type: 'number',
      placeHolder: 'Pre-money valuation',
      required: 0,
      show: 1,
    },
    {
      default: 0,
      field_label: 'Post-money valuation',
      field_list: null,
      field_name: 'post_money_valuation',
      field_type: 'number',
      placeHolder: 'Post-money valuation',
      required: 0,
      show: 1,
    },
  ];

  return (
    <FormSection name='round'>
      <div className='m-3'>
        <div className='row'>
          <div className='col-md-12'>
            <Field
              name='is_participate'
              label='Did you participate ?'
              component={CustomCheckbox}
              format={value => (value ? true : false)}
              parse={value => (value ? 1 : 0)}
              // defaultChecked={true}
              disabled={isParticipate}
            />
          </div>
        </div>
        <div className='row'>
          {getCustomFields().map(customField => (
            <InputCustomFields customField={customField} key={customField.field_name} classLabel='font-size-14 font-weight-500' formSection='round' />
          ))}
        </div>
        {get(formValues, 'round.investment_type') !== 'Loan' && (
          <React.Fragment>
            <div className='row align-items-center'>
              <div className='col-md-11'>
                <Divider orientation='left'>Round valuation</Divider>
              </div>
              <div className='col-md-1'>
                {roundValuationPanel ? (
                  <Icon type='down' style={{ cursor: 'pointer' }} onClick={() => setRoundValuationPanel(false)} />
                ) : (
                  <Icon type='left' style={{ cursor: 'pointer' }} onClick={() => setRoundValuationPanel(true)} />
                )}
              </div>
            </div>
            <div style={{ display: roundValuationPanel ? 'block' : 'none' }}>
              <div className='row'>
                {roundValuation.map(customField => (
                  <InputCustomFields customField={customField} key={customField.field_name} classLabel='font-size-14 font-weight-500' formSection='round' />
                ))}
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Field name='investment_rational' style={{ width: '100%' }} component={TextArea} labelClass='font-size-14 font-weight-500' label='Investment rationale ' />
                </div>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-md-11'>
                <Divider orientation='left'>Co-Investors</Divider>
              </div>
              <div className='col-md-1'>
                {coInvestorsPanel ? (
                  <Icon type='down' style={{ cursor: 'pointer' }} onClick={() => setCoInvestorsPanel(false)} />
                ) : (
                  <Icon type='left' style={{ cursor: 'pointer' }} onClick={() => setCoInvestorsPanel(true)} />
                )}
              </div>
            </div>
            <div style={{ display: coInvestorsPanel ? 'block' : 'none' }}>
              <FieldArray name='co_investors' component={CoInvestors} />
            </div>
          </React.Fragment>
        )}
      </div>
    </FormSection>
  );
}
