import classNames from 'classnames';
import React, { Component, useEffect } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import * as validators from 'Utils/validators';
import { aggregations } from 'Enums/metricAggregations';
import set from 'lodash/set';
import AutoSearch from 'SharedComponent/AutoSearch';
import FileInput from 'SharedComponent/FileInput';
import Spinner from 'SharedComponent/Spinner';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';
import TextEditor from 'SharedComponent/TextEditor';
import TextField from 'SharedComponent/TextField';
import ToggleGroup from 'SharedComponent/ToggleGroup';
import ChartTools from './ChartTools';
import CheckboxTools from './CheckboxTools';
import AggregationTools from './AggregationTools';
import ButtonColorPicker, { FieldInputNumber } from './ItemColor/ButtonColorPicker';
import ItemColorPicker from './ItemColor/ItemColorPicker';
import SwitchFields, { SwitchToggle } from './SwitchFields';
import ManualTools from './ManualTools';

import { Collapse, Button, Radio, Input, Select, Cascader } from 'antd';
import ID from 'Utils/UUID';

const { Option } = Select;
const { Panel } = Collapse;

import './TemplateSidebar.scss';
import { colorPalettes, colorPalettesNames } from 'Enums/chartPalette';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { getDealRounds, getPentugramItems, updateItem } from '../../../../actions/reportActions';

const dataAlign = [
  { value: 'left', material: 'format_align_left' },
  { value: 'center', material: 'format_align_center' },
  { value: 'right', material: 'format_align_right' },
];
const titlePos = [
  { value: 'top_center', label: 'Top center' },
  { value: 'top_left', label: 'Top left' },
  { value: 'top_right', label: 'Top right' },
  { value: 'bottom_right', label: 'Bottom right' },
  { value: 'bottom_left', label: 'Bottom left' },
  { value: 'bottom_center', label: 'Bottom center' },
  { value: 'custom', label: 'Custom' },
];
const defined_cf_type = ['list', 'year', 'date', 'countries'];
const defined_cf_name = ['countryName', 'domain'];

function includesChart(item, includePieCharts) {
  let key = item.key === 'pipeline_card' ? item.chartType : item.key;
  let pie_charts = ['pie_chart', 'ring_graphic'];
  let charts = [
    'time_series_graph',
    'sparkline_chart',
    'smoothed_time_series_graph',
    'stacked_area_chart',
    'column_chart',
    'stacked_histogram',
    'bar_graph',
    'bar_chart',
    'funnel_chart',
    'stacked_bar_chart',
  ];
  if (includePieCharts) charts = charts.concat(pie_charts);
  return charts.includes(key);
}

const PipelinesMultiSelect = ({ pipelines, loadPipelines, item, handlePipelineSelection, loadData }) => {
  useEffect(() => {
    loadPipelines(item.is_pipeline ? 0 : 1);
  }, []);
  return (
    <React.Fragment>
      <div>
        <label> {item.is_pipeline ? 'Pipeline' : 'Portfolio'} name </label>
      </div>
      <Field
        name={`pipeline_id`}
        placeholder={`Type ${item.is_pipeline ? 'pipeline' : 'portfolio'} name`}
        labelClass='font-size-14 font-weight-500'
        component={FieldSelect}
        multi
        multiVal
        options={pipelines}
        loading={!pipelines.length}
        onLoad={ids => {
          if (item.key === 'pipeline_card' || item.key === 'score_card') {
            return handlePipelineSelection(item, { pipeline_id: ids });
          }
          if (item.is_investments_table) {
            return loadData(item, 'table', 'chart', ids);
          }
          if (item.is_lps_table) {
            return loadData(item, 'commitment', 'LP', ids);
          }
          if (item.is_funds_table) {
            return loadData(item, 'funds', 'table', ids);
          }
          if (item.key === 'portfolio_card') {
            return loadData(item, 'portfolios', 'deals', ids);
          }
        }}
        valueKey='pipeline_id'
        labelKey='name'
        fieldRequired='name'
        fieldUnique='pipeline_id'
      />
    </React.Fragment>
  );
};

const DealsSelect = ({ item }) => {
  let pentugramItems = useSelector(state => state.reports.pentugramItems);
  let dispatch = useDispatch();
  const dealSelected = deal => {
    dispatch(getDealRounds(item, deal.deal_id, deal.name));
  };
  // use getDealRounds
  // use getPentugramItems
  return (
    <Field
      name={`deal_name`}
      placeholder={`Type the name of your deal`}
      component={AutoSearch}
      options={pentugramItems}
      loading={false}
      onUpdate={event => dispatch(getPentugramItems(item.id, 'deals', 'name', event))}
      onSelect={deal => dealSelected(deal)}
      validate={[]}
      fieldRequired='name'
      fieldUnique='deal_id'
      itemStyles={{ top: '40px' }}
    />
  );
};

class TemplateSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      tab: 1,
    };
  }

  get scorecardCascaderOptions() {
    return [
      { value: 'amount_invested', label: 'Invested amount' },
      { value: 'deal_id', label: 'Deals' },
    ];
  }
  get scorecardCascaderOperatorOptions() {
    let item = this.props.activeElement;
    if (item.metric === 'amount_invested') {
      return [
        { value: 'AVG', label: 'AVG' },
        { value: 'SUM', label: 'SUM' },
        { value: 'MIN', label: 'MIN' },
        { value: 'MAX', label: 'MAX' },
      ];
    }
    return [{ value: 'COUNT', label: 'COUNT' }];
  }
  get cascaderOptions() {
    const pipeline_options = [
      {
        value: 'deals',
        label: 'Number of deals per',
        children: [
          {
            value: 'country',
            label: 'Country',
          },
          {
            value: 'activity',
            label: 'Activity',
          },
          {
            value: 'user',
            label: 'User',
          },
        ],
      },
    ];

    const portfolio_options = [
      {
        value: 'investment',
        label: 'Invested amount per',
        children: [
          {
            value: 'country',
            label: 'Country',
          },
          {
            value: 'stage',
            label: 'Stage',
          },
          {
            value: 'activity',
            label: 'Activity',
          },
          {
            value: 'round',
            label: 'Round',
          },
        ],
      },
      {
        value: 'deals',
        label: 'Number of deals per',
        children: [
          {
            value: 'stage',
            label: 'Stage',
          },
          {
            value: 'user',
            label: 'User',
          },
          {
            value: 'round',
            label: 'Round',
          },
        ],
      },
    ];
    if (this.props.activeElement && this.props.activeElement.cascader_options) {
      return this.props.activeElement.cascader_options;
    }
    if (this.props.activeElement && this.props.activeElement.is_pipeline) {
      if (this.props.activeElement.pipeline_id && String(this.props.activeElement.pipeline_id).split(',').length === 1) {
        pipeline_options[0].children.push({
          value: 'stage',
          label: 'Stage',
        });
        return pipeline_options;
      }
      return pipeline_options;
    } else {
      return portfolio_options;
    }
  }

  toggle(tab) {
    this.setState({ tab });
  }

  sharedComponent(field, value) {
    let item = {
      ...this.props.activeElement,
      [field]: value,
    };
    this.props.sharedComponent(item, value);
  }

  updateElement(field, value) {
    let item = {
      ...this.props.activeElement,
      [field]: value,
    };
    this.props.updateItem(item.id, item);
  }

  handleColorPaletteClick = (palette, name) => {
    this.updateElementX('properties.colorPalette', palette);
    this.updateElementX('properties.colorPaletteName', name);
  };

  calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    if (srcWidth < maxWidth && srcHeight < maxHeight) return { width: srcWidth, height: srcHeight };
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }

  updateFigureElement(url, width, height) {
    const aspectRatio = this.calculateAspectRatioFit(width, height, this.props.tools.width, this.props.tools.height);
    let item = {
      ...this.props.activeElement,
      url: url,
      properties: {
        ...this.props.activeElement.properties,
        size: {
          width: aspectRatio.width,
          height: aspectRatio.height,
        },
        originalSize: {
          width,
          height,
        },
      },
    };
    this.props.updateItem(item.id, item);
  }

  updateHeaderProperties(field, value) {
    const object = this.props.activeElement;
    let item = {
      ...object,
      properties: {
        ...object.properties,
        header: {
          ...object.properties.header,
          [field]: value,
        },
      },
    };
    this.props.updateItem(item.id, item);
  }

  updateBorderProperties(field, value) {
    const object = this.props.activeElement;
    let item = {
      ...object,
      properties: {
        ...object.properties,
        border: {
          ...object.properties.border,
          [field]: value,
        },
      },
    };
    this.props.updateItem(item.id, item);
  }

  updateHeadingProperties(value) {
    let object = this.props.activeElement;
    let item = {
      ...object,
      properties: {
        ...object.properties,
        isHeading: value,
        headingLevel: 1,
      },
    };
    if (value) {
      this.props.addToContentTable(item);
    } else {
      this.props.removeFromContentTable(item.id);
    }
    this.props.updateItem(item.id, item);
  }

  updateYAxisProperties(value) {
    let object = this.props.activeElement;
    let item = {
      ...object,
      hasPercentageAxis: value,
    };
    this.props.updateItem(item.id, item);
  }

  updateLegendOrientationProperty(value) {
    let object = this.props.activeElement;
    let item = {
      ...object,
      legend_horizontal_orient: value,
    };
    this.props.updateItem(item.id, item);
  }

  showChartLegend(value) {
    let object = this.props.activeElement;
    let item = {
      ...object,
      legend: {
        show: value,
      },
    };
    this.props.updateItem(item.id, item);
  }

  updateElementFields(field, value) {
    const object = this.props.activeElement;
    let item = {
      ...object,
      fields: object.fields.map(it => {
        if (it.value == field)
          return {
            ...it,
            show: value,
          };
        return it;
      }),
    };
    this.props.updateItem(item.id, item);
  }

  updateItemData(values, search) {
    const object = this.props.activeElement;
    let item = {
      ...object,
      data: values,
    };

    if (search.name === 'potfolio_id') {
      item.reload_data = {
        can_update: true,
        type: 'portfolio',
        portfolio_id: values.pipeline_id,
      };
    }
    if (search.name === 'deal') {
      item.reload_data = {
        can_update: true,
        type: 'deal_card',
        [search.unique]: values[search.unique],
      };
    }
    if (search.name === 'contact') {
      item.reload_data = {
        can_update: true,
        type: 'contact_card',
        [search.unique]: values[search.unique],
      };
    }
    this.props.updateItem(item.id, item);
  }

  updateElementX(fields, value) {
    let object = this.props.activeElement;
    set({ ...object }, fields, value);
    this.props.updateItem(object.id, object);
  }

  updateElementDataDeals(values) {
    const object = this.props.activeElement;
    let item = {
      ...object,
      data: values,
    };
    this.props.updateItem(item.id, item);
  }

  updatePieChartParams(value, toDelete) {
    const object = this.props.activeElement;
    let item = {
      ...object,
      ...value,
      reload_data: {
        can_update: true,
        type: 'portfolio_pie',
        portfolio_id: value.portfolio_id,
        field_name: value.field_name,
      },
    };

    delete item[toDelete];

    this.props.updateItem(item.id, item);
  }

  getPortfolioByType(portfolio_id, type) {
    const object = this.props.activeElement;
    let item = {
      ...object,
      portfolio_types: type,
    };
    this.props.getPortfolioByType(portfolio_id, type, item);
  }

  parseCustomFields(customFields = []) {
    return customFields
      .filter(cf => defined_cf_type.includes(cf.field_type) || defined_cf_name.includes(cf.field_name))
      .map(cf => {
        return {
          label: cf.field_label,
          value: cf.field_name,
        };
      });
  }

  handleHeadingLevelChange = value => {
    const object = this.props.activeElement;
    let item = {
      ...object,
      properties: {
        ...object.properties,
        headingLevel: value,
      },
    };
    this.props.updateItem(item.id, item);
  };

  addManualTableColumn = item => {
    let emptyCell = {
      id: ID.uuid(),
      style: {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: 1,
        width: 100,
        height: 20,
      },
      colSpan: 1,
      rowSpan: 1,
      content: '',
    };
    let newTableData = item.tableData.map(row => {
      return [...row, { ...emptyCell, id: ID.uuid() }];
    });
    this.props.updateItem(item.id, { ...item, tableData: newTableData });
  };
  addManualTableRow = item => {
    let emptyCell = {
      id: ID.uuid(),
      style: {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: 1,
        width: 100,
        height: 20,
      },
      colSpan: 1,
      rowSpan: 1,
      content: '',
    };
    let getTotalItems = row => row.reduce((curr, agg) => curr + agg.colSpan, 0);
    let emptyRow = new Array(getTotalItems(item.tableData[0])).fill(null).map(() => ({ ...emptyCell, id: ID.uuid() }));
    let newTableData = [...item.tableData, emptyRow];
    this.props.updateItem(item.id, { ...item, tableData: newTableData });
  };
  handleCellBorderWidthChange = (value, item) => {
    let selectedIds = this.props.tools.manualTableSelectedItems;
    let newTableData = [...item.tableData];
    selectedIds.forEach(path => {
      let cell = get(newTableData, path);
      if (cell) {
        let newCell = {
          ...cell,
          style: {
            ...cell.style,
            borderWidth: Number(value),
          },
        };
        set(newTableData, path, newCell);
      }
    });
    let newItem = {
      ...item,
      tableData: newTableData,
    };
    this.props.updateItem(item.id, newItem);
  };

  handleTableCellPaddingChange = (value, item) => {
    let selectedIds = this.props.tools.manualTableSelectedItems;
    let newTableData = [...item.tableData];
    selectedIds.forEach(path => {
      let cell = get(newTableData, path);
      if (cell) {
        let newCell = {
          ...cell,
          style: {
            ...cell.style,
            padding: Number(value),
          },
        };
        set(newTableData, path, newCell);
      }
    });
    let newItem = {
      ...item,
      tableData: newTableData,
    };
    this.props.updateItem(item.id, newItem);
  };
  handleTableAlignmentChange = (e, item) => {
    let newTableStyle = { ...item.tableStyle, TableAlignment: e.target.value };
    this.props.updateItem(item.id, { ...item, tableStyle: newTableStyle });
  };
  handleChartTypeChange = (e, item) => {
    let newItem = { ...item, chartType: e.target.value };
    this.props.updateItem(item.id, newItem);
  };
  mergeSelectedTableCells = item => {
    let ids = this.props.tools.manualTableSelectedItems;
    let details = this.props.tools.selectedItemsInfos;
    // add colSpan and rowSpan to first cell.
    if (details.firstIndex) {
      let firstCell = get(item.tableData, details.firstIndex);
      let newFirstCell = {
        ...firstCell,
        rowSpan: details.rowSpan,
        colSpan: details.colSpan,
      };
      // delete rest cell ids.
      let newTable = item.tableData.map((row, i) => {
        return row.filter((cell, j) => {
          if (!ids.slice(1).includes(`${i}.${j}`)) return true;
          return false;
        });
      });

      set(newTable, details.firstIndex, newFirstCell);
      let newItem = {
        ...item,
        tableData: newTable,
      };
      // update table data.
      this.props.updateItem(item.id, newItem);
      this.props.updateTools('manualTableSelectedItems', [details.firstIndex]);
      this.props.updateTools('selectedItemsInfos', { ...details, merge: false });
    }
  };
  splitSelectedTableCells = item => {
    let emptyCell = {
      id: ID.uuid(),
      style: {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: 1,
        width: 100,
        height: 20,
      },
      colSpan: 1,
      rowSpan: 1,
      content: '',
    };
    let ids = this.props.tools.manualTableSelectedItems;
    let details = this.props.tools.selectedItemsInfos;
    let newTable = [...item.tableData];
    if (details.firstIndex) {
      ids.forEach(path => {
        let cell = get(item.tableData, path);
        let [cellRow, cellCol] = path.split('.');
        if (cell) {
          if (Number(cell.rowSpan) === 1 && Number(cell.colSpan) > 1) {
            let newCells = new Array(Number(cell.colSpan) - 1).fill(null).map(() => ({ ...emptyCell, id: ID.uuid() }));
            let newRow = [...newTable[cellRow], ...newCells];
            newRow = newRow.map(originalCell => {
              if (originalCell.id === cell.id) {
                return { ...cell, rowSpan: 1, colSpan: 1 };
              }
              return originalCell;
            });
            newTable[cellRow] = newRow;
          } else {
            for (let start = Number(cellRow), end = Number(cellRow) + Number(cell.rowSpan) - 1; start <= end; start++) {
              if (Number(cell.colSpan) >= 1 && Number(cell.rowSpan) > 1) {
                let newCells = new Array(start === Number(cellRow) ? Number(cell.colSpan) - 1 : Number(cell.colSpan)).fill(null).map(() => ({ ...emptyCell, id: ID.uuid() }));
                let newRow = [...newTable[start], ...newCells];
                if (start === Number(cellRow))
                  newRow = newRow.map(originalCell => {
                    if (originalCell.id === cell.id) {
                      return { ...cell, rowSpan: 1, colSpan: 1 };
                    }
                    return originalCell;
                  });
                newTable[start] = newRow;
              }
            }
          }
          cell = set(item.tableData, path, { ...cell, rowSpan: 1, colSpan: 1 });
        }
      });
      let newItem = {
        ...item,
        tableData: newTable,
      };
      this.props.updateItem(item.id, newItem);
      this.props.updateTools('manualTableSelectedItems', [details.firstIndex]);
    }
  };
  setColorForSelectedTableCells = (color, item) => {
    let selectedIds = this.props.tools.manualTableSelectedItems;
    let newTableData = [...item.tableData];
    selectedIds.forEach(path => {
      let cell = get(newTableData, path);
      if (cell) {
        let newCell = {
          ...cell,
          style: {
            ...cell.style,
            backgroundColor: color,
          },
        };
        set(newTableData, path, newCell);
      }
    });
    let newItem = {
      ...item,
      tableData: newTableData,
    };
    this.props.updateItem(item.id, newItem);
  };
  setBackgroundColorForSelectedTableCells = (color, item) => {
    let selectedIds = this.props.tools.manualTableSelectedItems;
    let newTableData = [...item.tableData];
    selectedIds.forEach(path => {
      let cell = get(newTableData, path);
      if (cell) {
        let newCell = {
          ...cell,
          style: {
            ...cell.style,
            borderColor: color,
          },
        };
        set(newTableData, path, newCell);
      }
    });
    let newItem = {
      ...item,
      tableData: newTableData,
    };
    this.props.updateItem(item.id, newItem);
  };
  setColumnWidthForSelectedTableCells = (value, item) => {
    let selectedIds = this.props.tools.manualTableSelectedItems;
    let newTableData = [...item.tableData];
    selectedIds.forEach(path => {
      let cell = get(newTableData, path);
      if (cell) {
        let newCell = {
          ...cell,
          style: {
            ...cell.style,
            width: Number(value),
            display: 'table-cell',
          },
        };
        set(newTableData, path, newCell);
      }
    });
    let newItem = {
      ...item,
      tableData: newTableData,
    };
    this.props.updateItem(item.id, newItem);
  };
  setMinRowHeightForSelectedTableCells = (value, item) => {};
  removeSelectedColumn = item => {
    let { colIndex } = this.props.tools.columnSelected;
    let newTable = [...item.tableData];
    if (colIndex) {
      newTable = newTable.map(row => row.filter((cell, index) => String(index) !== colIndex));
      let newItem = {
        ...item,
        tableData: newTable,
      };
      this.props.updateItem(item.id, newItem);
    }
  };
  removeSelectedRow = item => {
    let { rowIndex } = this.props.tools.rowSelected;
    let newTable = [...item.tableData];
    if (rowIndex) {
      newTable = newTable.filter((row, index) => String(index) !== rowIndex);
      let newItem = {
        ...item,
        tableData: newTable,
      };
      this.props.updateItem(item.id, newItem);
    }
  };

  handleConvertToManualTable = item => {
    let emptyCell = {
      id: ID.uuid(),
      style: {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: 1,
        width: 100,
        height: 20,
      },
      colSpan: 1,
      rowSpan: 1,
      content: '',
    };
    let TableData = [...document.querySelectorAll(`#edit-${item.id} tr`)].map(tr => {
      return [...tr.children].map(cell => ({
        ...emptyCell,
        id: ID.uuid(),
        style: {
          ...emptyCell.style,
          height: getComputedStyle(cell).height || 20,
          width: getComputedStyle(cell).width || 100,
          padding: getComputedStyle(cell).padding || 3,
        },
        content: cell.innerHTML,
      }));
    });
    let newItem = {
      properties: item.properties,
      id: item.id,
      key: 'manual_table',
      manual: true,
      tableData: TableData,
    };
    this.props.updateItem(item.id, newItem);
  };

  handleImageFilter = (value, item, filter) => {
    let newFilter;
    if (item.imgStyle && item.imgStyle.filter) {
      newFilter = {
        ...item.imgStyle.filter,
        [filter]: value,
      };
    } else {
      newFilter = {
        [filter]: value,
      };
    }
    let newItem = {
      ...item,
      imgStyle: {
        ...item.imgStyle,
        filter: newFilter,
      },
    };
    this.props.updateItem(item.id, newItem);
  };

  handleImageLinkChange = event => {
    let item = this.props.activeElement;
    let value = event.target.value;
    let newItem = {
      ...item,
      img_link: value,
    };
    this.props.updateItem(item.id, newItem);
  };

  getImageLink = item => {
    let link = get(item, 'img_link', '');
    return link;
  };

  handleImageTransform = (way, item, transform, value) => {
    let isMulti = way === 'multi';
    let newTransform;
    let prevTransform = get(item, 'imgStyle.transform.' + transform, isMulti ? 1 : 0);
    prevTransform = Number(prevTransform ? prevTransform : isMulti ? 1 : 0);
    let addValue = (prevTransform + value) % 360;
    let newValue = isMulti ? prevTransform * value : addValue;
    let isCropped = !!item.croppedImage;
    newTransform = {
      [transform]: newValue,
    };
    let newItem = {
      ...item,
      imgStyle: {
        ...item.imgStyle,
        transform: newTransform,
      },
    };
    let isRotated = get(newItem, 'properties.size.isRotated');
    if (!isMulti && !isRotated && !isCropped) {
      newItem = this.switchDimensions(newItem, true);
      // set rotation to 0;
      set(newItem, 'imgStyle.transform.rotate', 0);

      // create canvas
      let offScreenCanvas = document.createElement('canvas');
      let offScreenCanvasCtx = offScreenCanvas.getContext('2d');

      // link image to it
      let url = newItem.url;
      var img = new Image();
      img.crossOrigin = 'anonymous';
      img.origin = 'anonymous';
      img.src = url;

      img.onload = () => {
        offScreenCanvas.width = img.height;
        offScreenCanvas.height = img.width;
        if (value === 90) {
          offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
          offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
        } else {
          offScreenCanvasCtx.rotate((-90 * Math.PI) / 180);
          offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
        }

        offScreenCanvasCtx.drawImage(img, 0, 0);

        let newUrl = offScreenCanvas.toDataURL();
        set(newItem, 'url', newUrl);
        set(newItem, 'prevUrl', url);
        this.props.updateItem(item.id, newItem);
      };
    } else {
      if (!isMulti && isRotated && !isCropped) {
        set(newItem, 'imgStyle.transform.rotate', 0);
        newItem = this.switchDimensions(newItem, false);
        set(newItem, 'url', newItem.prevUrl);
        set(newItem, 'prevUrl', undefined);
      }
      this.props.updateItem(item.id, newItem);
    }
  };
  switchDimensions = (newItem, rotateValue = true) => {
    let height = get(newItem, 'properties.size.height');
    let width = get(newItem, 'properties.size.width');
    set(newItem, 'properties.size.height', width);
    set(newItem, 'properties.size.width', height);
    set(newItem, 'properties.size.isRotated', rotateValue);
    return newItem;
  };
  handleImageReset = item => {
    let beforeCrop = get(item, 'properties.beforeCrop', item.properties.size);
    let prevUrl = get(item, 'prevUrl', item.url);
    let newItem = {
      ...item,
      imgStyle: {},
      croppedImage: null,
      properties: {
        ...item.properties,
        size: beforeCrop,
      },
      url: prevUrl,
      prevUrl: undefined,
    };
    if (item.prevUrl) {
      newItem = this.switchDimensions(newItem, false);
    }
    this.props.updateItem(item.id, newItem);
  };

  handleImageCrop = (item, confirm = true) => {
    if (!confirm) {
      let newItem = {
        ...item,
        croppedImage: null,
      };
      this.props.updateItem(item.id, newItem);
    }
    let newItem = {
      ...item,
      properties: {
        ...item.properties,
        size: item.properties.crop,
        beforeCrop: item.properties.size,
      },
    };
    this.props.updateItem(item.id, newItem);
    this.props.updateTools('isCropping', false);
  };

  handleImageShadow = (value, item, prop) => {
    let shadow;
    if (item.imgStyle && item.imgStyle.shadow) {
      shadow = {
        ...item.imgStyle.shadow,
        [prop]: value,
      };
    } else {
      shadow = {
        [prop]: value,
      };
    }
    let newItem = {
      ...item,
      imgStyle: {
        ...item.imgStyle,
        shadow: shadow,
      },
    };
    this.props.updateItem(item.id, newItem);
  };

  handleBorderRadius = (value, item) => {
    let newItem = {
      ...item,
      imgStyle: {
        ...item.imgStyle,
        borderRaduis: value,
      },
    };
    this.props.updateItem(item.id, newItem);
  };

  handleCloneImageOtherPages = (item, clone) => {
    if (!clone) {
      this.props.removeComponent(item);
      let newItem = {
        ...item,
        shared: undefined,
      };
      this.props.addComponent(newItem);
    } else {
      this.props.removeComponent(item);
      let newItem = {
        ...item,
        shared: true,
      };
      this.props.addSharedComponent(item.key, 'image', { [item.id]: newItem });
    }
  };

  handleChartGridChange = (value, item, side) => {
    let pastGrid = get(item, 'grid', {});
    let newItem = {
      ...item,
      grid: {
        ...pastGrid,
        [side]: `${value}%`,
      },
    };
    this.props.updateItem(item.id, newItem);
  };

  handleChartPiePosChange = (value, item, side) => {
    let pastPieOptions = get(item, 'pieOptions', {});
    let [left, top] = get(item, 'pieOptions.center', ['50%', '40%']);
    let newItem = {
      ...item,
      pieOptions: {
        ...pastPieOptions,
        center: [side === 'left' ? `${value}%` : left, side === 'top' ? `${value}%` : top],
      },
    };
    this.props.updateItem(item.id, newItem);
  };
  handleChartPieSizeChange = (value, item, side) => {
    let pastPieOptions = get(item, 'pieOptions', {});
    let isRing = get(item, 'key') === 'ring_graphic';
    let [gap, circle] = get(item, 'pieOptions.radius', isRing ? ['50%', '50%'] : ['0%', '50%']);
    let newItem = {
      ...item,
      pieOptions: {
        ...pastPieOptions,
        radius: [side === 'gap' ? `${value}%` : gap, side === 'circle' ? `${value}%` : circle],
      },
    };
    this.props.updateItem(item.id, newItem);
  };

  handleChartLegendPosChange = (value, item, side) => {
    let pastLegendPos = get(item, 'legendPos', {});
    let newItem = {
      ...item,
      legendPos: {
        ...pastLegendPos,
        [side]: `${value}%`,
      },
    };
    this.props.updateItem(item.id, newItem);
  };

  handleChartTitlePosChange = (value, item, side) => {
    let pastTitlePos = get(item, 'titlePos', {});
    let newItem = {
      ...item,
      titlePos: {
        ...pastTitlePos,
        [side]: `${value}%`,
      },
    };
    this.props.updateItem(item.id, newItem);
  };
  getGrid = (side, item) => {
    let value = Number(get(item, `grid.${side}`, '10').replace('%', ''));
    return value;
  };

  getLegendValue = (side, item) => {
    let value = Number(get(item, `legendPos.${side}`, '10').replace('%', ''));
    return value;
  };

  getTitleValue = (side, item) => {
    let value = Number(get(item, `titlePos.${side}`, '10').replace('%', ''));
    return value;
  };

  getPiePosition = (side, item) => {
    let [left, top] = get(item, `pieOptions.center`, ['50%', '40%']);
    return side === 'left' ? Number(String(left).replace('%', '')) : Number(String(top).replace('%', ''));
  };
  updateShowPercentPie = value => {
    let newItem = {
      ...this.props.activeElement,
      showPercent: value,
    };
    this.props.updateItem(newItem.id, newItem);
  };

  getPieSize = (side, item) => {
    let isRing = item.key === 'ring_graphic';
    let [gap, circle] = get(item, `pieOptions.radius`, isRing ? ['50%', '70%'] : ['0%', '50%']);
    return side === 'gap' ? Number(String(gap).replace('%', '')) : Number(String(circle).replace('%', ''));
  };

  handleChartLegendTextChange = (value, item, param) => {
    let pastLegend = get(item, 'legend', {});
    let pastLegendTextStyle = get(item, 'legend.textStyle', {});
    let newItem = {
      ...item,
      legend: {
        ...pastLegend,
        [param]: value,
        textStyle: {
          ...pastLegendTextStyle,
          [param]: value,
        },
      },
    };
    this.props.updateItem(item.id, newItem);
  };

  handleChartLegendChange = (value, item, param) => {
    let pastLegend = get(item, 'legend', {});
    let newItem = {
      ...item,
      legend: {
        ...pastLegend,
        [param]: value,
      },
    };
    this.props.updateItem(item.id, newItem);
  };

  handlePipelineSelection = (item, object) => {
    let newItem = {
      ...item,
      pipeline_id: object.pipeline_id,
      //pipeline_name: object.name
    };

    this.props.updateItem(item.id, newItem);
  };

  handlePipelineCardMetricSelection = values => {
    let [metric, dimension] = values;
    const newItem = this.props.activeElement;
    if (metric && dimension) {
      if (newItem.pipeline_id) {
        this.props.getPipelineCardData(newItem, metric, dimension, newItem.pipeline_id);
      }
    }
  };

  handleScoreCardMetricSelection = values => {
    let [metric] = values;
    const newItem = this.props.activeElement;
    if (newItem.pipeline_id) {
      this.props.updateItem(newItem.id, { ...newItem, metric, operator: undefined });
    }
  };
  handleScoreCardOperatorSelection = values => {
    let [operator] = values;
    const item = this.props.activeElement;
    if (operator) {
      if (item.pipeline_id) {
        this.props.getScoreCardData(item, operator);
      }
    }
  };

  render() {
    const selected = this.props.activeElement;
    return (
      <div className='TemplateSidebar' style={{ width: '20%' }}>
        {this.renderSideBar()}
      </div>
    );
  }

  renderSideBar() {
    const selected = this.props.activeElement;
    const headingLevels = [
      {
        value: 1,
        label: 'Heading 1',
      },
      {
        value: 2,
        label: 'Heading 2',
      },
      {
        value: 3,
        label: 'Heading 3',
      },
      {
        value: 4,
        label: 'Heading 4',
      },
    ];
    if (!selected) return null;
    let tools = this.props.tools;
    let selectedCellsLength = tools.manualTableSelectedItems.length;
    let details = tools.selectedItemsInfos;
    let colSelected = tools.columnSelected.isSelected;
    let rowSelected = tools.rowSelected.isSelected;
    return (
      <div>
        <div className='tab-properties-styles'>
          <Nav className='tabs-details' tabs>
            {!selected.hasOwnProperty('isPageNumber') && (
              <NavItem>
                <NavLink className={classNames({ active: this.state.tab === 1 })} onClick={() => this.toggle(1)}>
                  <div className='card-header-details'>Properties</div>
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink className={classNames({ active: this.state.tab === 2 })} onClick={() => this.toggle(2)}>
                <div className='card-header-details'>Styles</div>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div>
          <form className='m-2'>
            {this.state.tab === 1 && (
              <div>
                {/* <div className="mb-2">
                  <SwitchToggle
                    label="Shared component"
                    defaultChecked={selected.shared}
                    onChange={e => this.sharedComponent("shared", e)}
                  />
                </div> */}
                {selected.hasOwnProperty('title') && (
                  <div className='mb-2'>
                    <div className='label-name'>Title</div>
                    <Field
                      name={`title`}
                      component={TextField}
                      type='text'
                      placeholder='Title'
                      withIcon={false}
                      onChange={e => this.updateElement('title', e.nativeEvent.target.value)}
                    />
                  </div>
                )}
                {(selected.hasOwnProperty('htmlContent') || selected.hasOwnProperty('title')) && (
                  <div className='mb-2'>
                    <SwitchToggle label='Set as heading' disabled={false} defaultChecked={selected.properties.isHeading} onChange={e => this.updateHeadingProperties(e)} />
                  </div>
                )}
                {selected && selected.properties && selected.properties.isHeading && (
                  <div className='mb-2'>
                    <Field
                      name={`heading_level`}
                      component={FieldSelect}
                      options={headingLevels}
                      placeholder='Heading Level'
                      defaultValue={selected.properties.headingLevel}
                      clearable={false}
                      onLoad={this.handleHeadingLevelChange}
                    />
                  </div>
                )}

                {selected.hasOwnProperty('tableData') && (
                  <Collapse destroyInactivePanel>
                    {selectedCellsLength && (
                      <Panel header={selectedCellsLength > 1 ? 'Selected Cells options' : 'Selected Cell options'} active key='1'>
                        {details.split && (
                          <Button type='dashed' className='mb-2' block onClick={() => this.splitSelectedTableCells(selected)}>
                            {' '}
                            Split Cells{' '}
                          </Button>
                        )}
                        {details.merge && (
                          <Button type='dashed' className='mb-2' block onClick={() => this.mergeSelectedTableCells(selected)}>
                            {' '}
                            Merge cells{' '}
                          </Button>
                        )}
                        {colSelected && (
                          <Button type='dashed' className='mb-2' block onClick={() => this.removeSelectedColumn(selected)}>
                            {' '}
                            Remove selected column{' '}
                          </Button>
                        )}
                        {rowSelected && (
                          <Button type='dashed' className='mb-2' block onClick={() => this.removeSelectedRow(selected)}>
                            {' '}
                            Remove selected row{' '}
                          </Button>
                        )}
                        <div className='mb-2'>
                          <ButtonColorPicker
                            label='Background color'
                            background={'white'}
                            onColorChange={color => {
                              this.setColorForSelectedTableCells(color, selected);
                            }}
                          />
                        </div>
                        <div className='mb-2'>
                          <ButtonColorPicker
                            label='Border color'
                            background={'white'}
                            onColorChange={color => {
                              this.setBackgroundColorForSelectedTableCells(color, selected);
                            }}
                          />
                        </div>
                        <FieldInputNumber unit='px' label='Cell border width' onChange={value => this.handleCellBorderWidthChange(value, selected)} />
                        <FieldInputNumber defaultValue={100} unit='px' label='Cell padding' onChange={value => this.handleTableCellPaddingChange(value, selected)} />
                        <FieldInputNumber defaultValue={100} unit='px' label='Cell width' max={200} onChange={value => this.setColumnWidthForSelectedTableCells(value, selected)} />
                        {/*                     
                    <p onClick={() => this.setMinRowHeightForSelectedTableCells(selected)}> Minimum row height</p> */}
                      </Panel>
                    )}

                    <Panel header='Table options' key='2'>
                      <Button type='dashed' className='mb-2' block onClick={() => this.addManualTableColumn(selected)}>
                        Add table column
                      </Button>
                      <Button type='dashed' className='mb-2' block onClick={() => this.addManualTableRow(selected)}>
                        Add table row{' '}
                      </Button>

                      <div className='display-flex-start'>
                        <label>Table Alignment.</label>
                      </div>
                      <div className='display-flex-center'>
                        <Radio.Group block onChange={value => this.handleTableAlignmentChange(value, selected)} defaultValue='display-flex-start'>
                          <Radio.Button value='display-flex-start'>Left</Radio.Button>
                          <Radio.Button value='display-flex-center'>Center</Radio.Button>
                          <Radio.Button value='display-flex-end'>Right</Radio.Button>
                        </Radio.Group>
                      </div>
                    </Panel>
                  </Collapse>
                )}
                {selected.hasOwnProperty('title_position') && !['pie_chart', 'ring_graphic', 'pipeline_card'].includes(selected.key) && (
                  <div className='mb-2 mt-2'>
                    <SwitchToggle label='Add percentage Y Axis' disabled={false} defaultChecked={selected.hasPercentageAxis} onChange={e => this.updateYAxisProperties(e)} />
                  </div>
                )}
                {selected.hasOwnProperty('target') && (
                  <div className='mb-2'>
                    <div className='label-name'>Target</div>
                    <Field
                      name={`target`}
                      component={TextField}
                      type='text'
                      placeholder='Title'
                      validate={[validators.url]}
                      withIcon={false}
                      onChange={e => this.updateElement('target', e.nativeEvent.target.value)}
                    />
                  </div>
                )}
                {selected.hasOwnProperty('newWindow') && (
                  <div className='mb-2'>
                    <SwitchToggle label='Open link in new window' defaultChecked={selected.newWindow} onChange={e => this.updateElement('newWindow', e)} />
                  </div>
                )}
                {selected.hasOwnProperty('description') && (
                  <div className='mb-2'>
                    <Field
                      name={`description`}
                      component={TextField}
                      type='text'
                      placeholder='Description'
                      withIcon={false}
                      onChange={e => this.updateElement('description', e.nativeEvent.target.value)}
                    />
                  </div>
                )}
                {selected.hasOwnProperty('value') && selected.key !== 'score_card' && (
                  <div className='mb-2'>
                    <div className='label-name'>Value</div>
                    <Field
                      name={`value`}
                      component={TextField}
                      type='text'
                      placeholder='Value'
                      withIcon={false}
                      onChange={e => this.updateElement('value', e.nativeEvent.target.value)}
                    />
                  </div>
                )}
                {selected.hasOwnProperty('url') && !selected.croppedImage && (
                  <div className='mb-2'>
                    <div className='label-name'>Image Source</div>
                    <Spinner isLoading={this.state.uploading} />
                    <div className='mt-2'>
                      <FileInput
                        name='fileUrl'
                        label='Select a file'
                        types='image/*,application/pdf'
                        style={{
                          backgroundColor: '#03a6ff',
                          color: '#ffffff',
                          border: 'none',
                        }}
                        onChange={params => this.updateFigureElement(params.url, params.width, params.height)}
                        isLoading={uploading => this.setState({ uploading })}
                        gaEvent={() => {}}
                      />
                    </div>
                  </div>
                )}

                {selected.croppedImage && (
                  <div className='mb-2'>
                    <div className='label-name'>This is a cropped image. Press reset image to reset it to its original form.</div>
                  </div>
                )}

                {selected.hasOwnProperty('url') && (
                  <Collapse destroyInactivePanel defaultActiveKey='0'>
                    {tools.isCropping && (
                      <Panel header='Crop Image' key='0'>
                        <p onClick={() => this.handleImageCrop(selected)}> Crop image </p>
                        <Button type='dashed' className='mb-2' block onClick={() => this.handleImageCrop(selected, true)}>
                          <div className='display-flex-center' style={{ height: 32 }}>
                            <span className='material-icons mr-1'>check</span> Confirm crop
                          </div>
                        </Button>
                        <Button type='danger' className='mb-2' block onClick={() => this.handleImageCrop(selected, false)}>
                          <div className='display-flex-center' style={{ height: 32 }}>
                            <span className='material-icons mr-1'>clear</span> Cancel crop
                          </div>
                        </Button>
                      </Panel>
                    )}
                    <Panel header='Image link' key='1.0'>
                      <label> Image link: </label>
                      <div>
                        <Input value={this.getImageLink(selected)} onChange={this.handleImageLinkChange} placeholder='Paste website link here...' />
                      </div>
                    </Panel>
                    <Panel header='Adjustments' key='1'>
                      <FieldInputNumber unit='%' defaultValue={100} max={200} label='Adjust Brightness' onChange={value => this.handleImageFilter(value, selected, 'brightness')} />
                      <FieldInputNumber unit='%' defaultValue={100} max={200} label='Adjust Contrast' onChange={value => this.handleImageFilter(value, selected, 'contrast')} />
                      <FieldInputNumber
                        unit='%'
                        defaultValue={100}
                        isPercentage
                        max={100}
                        label='Adjust Opacity'
                        onChange={value => this.handleImageFilter(value, selected, 'opacity')}
                      />
                    </Panel>
                    <Panel header='Recolor' key='2'>
                      <FieldInputNumber unit='deg' defaultValue={0} max={360} label='Adjust Color Hue' onChange={value => this.handleImageFilter(value, selected, 'hue-rotate')} />
                      <FieldInputNumber unit='%' defaultValue={0} max={100} label='Adjust Inversion' onChange={value => this.handleImageFilter(value, selected, 'invert')} />
                    </Panel>
                    <Panel header='Rotation' key='3'>
                      <Button type='dashed' className='mb-2' block onClick={() => this.handleImageTransform('multi', selected, 'scaleX', -1)}>
                        <div className='display-flex-center' style={{ height: 32 }}>
                          <span className='material-icons mr-1'>flip</span> Flip horizontally
                        </div>{' '}
                      </Button>
                      <Button type='dashed' className='mb-2' block onClick={() => this.handleImageTransform('multi', selected, 'scaleY', -1)}>
                        <div className='display-flex-center' style={{ height: 32 }}>
                          <span className='material-icons mr-1'>flip</span> Flip vertically
                        </div>{' '}
                      </Button>
                      <Button type='dashed' className='mb-2' block onClick={() => this.handleImageTransform('add', selected, 'rotate', -90)}>
                        <div className='display-flex-center' style={{ height: 32 }}>
                          <span className='material-icons mr-1'>rotate_left</span> rotate left
                        </div>{' '}
                      </Button>

                      <Button type='dashed' className='mb-2' block onClick={() => this.handleImageTransform('add', selected, 'rotate', 90)}>
                        <div className='display-flex-center' style={{ height: 32 }}>
                          <span className='material-icons mr-1'>rotate_right</span> rotate right
                        </div>
                      </Button>
                    </Panel>
                    <Panel header='Image reset' key='4'>
                      <Button type='dashed' className='mb-2' block onClick={() => this.handleImageReset(selected)}>
                        <div className='display-flex-center' style={{ height: 32 }}>
                          <span className='material-icons mr-1'>restore</span> Reset image
                        </div>
                      </Button>
                    </Panel>
                    <Panel header='Shadow and border' key='5'>
                      <div className='mb-2'>
                        <ButtonColorPicker
                          label='Shadow color'
                          background={'white'}
                          onColorChange={color => {
                            this.handleImageShadow(color, selected, 'color');
                          }}
                        />
                      </div>
                      <FieldInputNumber unit='px' defaultValue={0} max={100} label='Shadow X offset' onChange={value => this.handleImageShadow(value, selected, 'xOffset')} />
                      <FieldInputNumber defaultValue={0} unit='px' max={100} label='Shadow Y offset' onChange={value => this.handleImageShadow(value, selected, 'yOffset')} />
                      <FieldInputNumber defaultValue={0} unit='px' max={100} label='Shadow blur' onChange={value => this.handleImageShadow(value, selected, 'blur')} />
                      <FieldInputNumber defaultValue={0} unit='px' max={100} label='Shadow spread' onChange={value => this.handleImageShadow(value, selected, 'spread')} />
                      <FieldInputNumber defaultValue={0} unit='px' max={1000} label='Border radius' onChange={value => this.handleBorderRadius(value, selected)} />
                    </Panel>
                    <Panel header='Extra options' key='6'>
                      <div className='mb-2'>
                        <SwitchToggle label='Clone in all pages' disabled={false} defaultChecked={selected.shared} onChange={e => this.handleCloneImageOtherPages(selected, e)} />
                      </div>
                    </Panel>
                  </Collapse>
                )}

                {selected.hasOwnProperty('header') && (
                  <div className='mb-2'>
                    <SwitchToggle label='Has header ?' disabled={false} defaultChecked={selected.hasHeader} onChange={e => this.updateElement('hasHeader', e)} />
                    {selected.hasHeader && (
                      <div className='mt-2'>
                        <div className='label-name'>Header:</div>
                        <Field name={`header`} component={TextEditor} toolbarPosition='top' handleChange={html => this.updateElement('header', html)} />
                      </div>
                    )}
                  </div>
                )}
                {selected.hasOwnProperty('search') && !['deal_card', 'contact_card'].includes(selected.key) && (
                  <div className='mb-2'>
                    <PipelinesMultiSelect
                      item={selected}
                      pipelines={this.props.pipelines}
                      loadData={this.props.getPipelineCardData}
                      handlePipelineSelection={this.handlePipelineSelection}
                      loadPipelines={this.props.loadPipelines}
                    />
                  </div>
                )}
                {selected.hasOwnProperty('search') && ['deal_card', 'contact_card'].includes(selected.key) && (
                  <div className='mb-2'>
                    <Field
                      label={`Type the name of your ${selected.search.name}`}
                      name={`data.${selected.search.field}`}
                      placeholder={`Type the name of your ${selected.search.name}`}
                      labelClass='font-size-14 font-weight-500'
                      component={AutoSearch}
                      options={this.props.pentugramItems}
                      loading={false}
                      onUpdate={event => this.props.getPentugramItems(selected.id, selected.search.url, selected.search.field, event)}
                      onSelect={object => this.updateItemData(object, selected.search)}
                      validate={[]}
                      fieldRequired={selected.search.field}
                      fieldUnique={selected.search.unique}
                    />
                  </div>
                )}
                {selected.hasOwnProperty('is_rounds_table') && (
                  <div className='mb-2'>
                    <DealsSelect item={selected} />
                  </div>
                )}
                {selected.hasOwnProperty('fields') && (
                  <div className='mb-2'>
                    <div className='border-section p-2'>
                      <SwitchFields item={selected} updateField={this.updateElementFields.bind(this)} />
                    </div>
                  </div>
                )}
                {/* {selected.hasOwnProperty("params") && (
                  <div className="mb-2">
                    <div className="mb-2">
                      <Field
                        label={`Type the name of your ${selected.params.name}`}
                        name={`field_name`}
                        placeholder={`Type the name of your ${selected.params.name}`}
                        labelClass="font-size-14 font-weight-500"
                        component={AutoSearch}
                        options={this.props.pentugramItems}
                        loading={false}
                        onUpdate={event => this.props.getPentugramItems(selected.id, selected.params.url, selected.params.field, event, { field: "is_portfolio", value: selected.is_pipeline ? 0 : 1 })}
                        onSelect={object => this.updatePieChartParams(
                          { portfolio_id: object[selected.params.unique], field_name: object[selected.params.field] },
                          "portfolio_type"
                        )} // update pie_chart data !!
                        validate={[]}
                        fieldRequired={selected.params.field}
                        fieldUnique={selected.params.unique}
                      />
                    </div>
                    <div className="border-section  p-1">
                      <Field
                        name={`portfolio_types`}
                        component={FieldSelect}
                        options={this.parseCustomFields(this.props.portfolioCustomFields)}
                        placeholder="Group by"
                        clearable={false}
                        onLoad={value => this.getPortfolioByType(selected.portfolio_id, value)}
                      />
                    </div>
                  </div>
                )} */}
                {selected.hasOwnProperty('html') && (
                  <div className='mb-2'>
                    <div className='label-name mt-2'>Content:</div>
                    <Field name={`html`} component={TextEditor} toolbarPosition='top' colorPicker={true} handleChange={html => this.updateElement('html', html)} />
                  </div>
                )}
                {/* {selected.hasOwnProperty("geometric") && (
                  <div>
                    <ItemColorPicker
                      background={selected.geometric.backgroundColor}
                      onColorChange={color => this.updateElementX("geometric.backgroundColor", color)}
                    />
                  </div>
                )} */}
                {selected.source_tools && (
                  <div className='mb-2'>
                    <ChartTools />
                  </div>
                )}
                {selected.hasOwnProperty('checkbox') && (
                  <div className='m-2'>
                    <div className='border-section'>
                      <CheckboxTools item={selected} updateList={list => this.updateElementDataDeals(list)} />
                    </div>
                  </div>
                )}
                {selected.hasOwnProperty('manual') && !selected.hasOwnProperty('tableData') && (
                  <div className='mb-2'>
                    <div className='border-section'>
                      <ManualTools initialValues={selected} updateElement={(field, data) => this.updateElement(field, data)} />
                    </div>
                  </div>
                )}
                {(selected.hasOwnProperty('aggregation') || selected.key === 'portfolio_card') && (
                  <Button type='dashed' className='mb-2' block onClick={() => this.handleConvertToManualTable(selected)}>
                    {' '}
                    Convert To Manual Table{' '}
                  </Button>
                )}
                {selected.hasOwnProperty('aggregation') && (
                  <div className='mb-2'>
                    <div className='border-section p-2'>
                      <AggregationTools item={selected} aggregations={true} aggregationList={aggregations} handleChange={(field, value) => this.updateElement(field, value)} />
                    </div>
                  </div>
                )}
                {selected.hasOwnProperty('content_table') && (
                  <div className='mb-2'>
                    <button
                      type='button'
                      className='btn btn-primary display-flex-center'
                      style={{ width: '100%', background: 'rgba(5, 167, 255, 0.75)', borderColor: 'rgba(5, 167, 255, 0.75)' }}
                      onClick={() => this.props.updateContentTable(selected)}
                    >
                      <span className='material-icons'>refresh</span>
                      Reload content table
                    </button>
                  </div>
                )}

                {selected.key === 'rectangle' && (
                  <Collapse>
                    <Panel header='Rectangle Border' key='1'>
                      <FieldInputNumber defaultValue={0} unit='px' max={1000} label='Border radius' onChange={value => this.updateElementX('geometric.borderRadius', value)} />
                    </Panel>
                  </Collapse>
                )}
                {includesChart(selected, true) && (
                  <Collapse destroyInactivePanel defaultActiveKey='1'>
                    {selected.key === 'pipeline_card' && (
                      <Panel header='Data options' isActive key='1'>
                        {!selected.is_deal_rounds && (
                          <div className='mb-2'>
                            <PipelinesMultiSelect
                              item={selected}
                              pipelines={this.props.pipelines}
                              handlePipelineSelection={this.handlePipelineSelection}
                              loadPipelines={this.props.loadPipelines}
                            />
                          </div>
                        )}
                        {selected.is_deal_rounds && (
                          <div className='mb-2'>
                            <DealsSelect item={selected} />
                          </div>
                        )}
                        <div className='mb-2 multi-chart-radio'>
                          <div>
                            {' '}
                            <label> Chart type </label>{' '}
                          </div>
                          <div className='display-flex-center'>
                            <Radio.Group
                              disabled={selected.is_deal_rounds ? !selected.deal_id : !selected.pipeline_id}
                              size='medium'
                              block
                              onChange={value => this.handleChartTypeChange(value, selected)}
                              defaultValue={selected.chartType}
                            >
                              <Radio.Button value='bar_chart'>
                                <div className='display-flex-center'>
                                  <span className='material-icons'>bar_chart</span>
                                  <span className='ml-1'>Bar </span>
                                </div>
                              </Radio.Button>
                              <Radio.Button value='pie_chart'>
                                <div className='display-flex-center'>
                                  <span className='material-icons'>pie_chart</span>
                                  <span className='ml-1'> Pie </span>
                                </div>
                              </Radio.Button>
                              <Radio.Button value='funnel_chart' disabled={selected.metricId !== 'deals_stage'}>
                                <div className='display-flex-center'>
                                  <span className='material-icons'>filter_alt</span>
                                  <span className='ml-1'> Funnel </span>
                                </div>
                              </Radio.Button>
                            </Radio.Group>
                          </div>
                        </div>
                        {!selected.is_deal_rounds && (
                          <div className='mb-2'>
                            <div>
                              <label> Metric/Dimension</label>
                            </div>
                            <div className='display-flex-center'>
                              <Cascader
                                options={this.cascaderOptions}
                                placeholder='Select metric and dimension...'
                                disabled={!selected.pipeline_id}
                                defaultValue={selected.cascader_value}
                                onChange={this.handlePipelineCardMetricSelection}
                              />
                            </div>
                          </div>
                        )}
                      </Panel>
                    )}
                    {includesChart(selected) && (
                      <Panel header='Chart container grid' key='grid0'>
                        <FieldInputNumber
                          unit='%'
                          defaultValue={this.getGrid('top', selected)}
                          max={100}
                          label='Distance from top'
                          onChange={value => this.handleChartGridChange(value, selected, 'top')}
                        />
                        <FieldInputNumber
                          unit='%'
                          defaultValue={this.getGrid('right', selected)}
                          max={100}
                          label='Distance from right'
                          onChange={value => this.handleChartGridChange(value, selected, 'right')}
                        />
                        <FieldInputNumber
                          unit='%'
                          defaultValue={this.getGrid('left', selected)}
                          max={100}
                          label='Distance from left'
                          onChange={value => this.handleChartGridChange(value, selected, 'left')}
                        />
                        <FieldInputNumber
                          unit='%'
                          defaultValue={this.getGrid('bottom', selected)}
                          max={100}
                          label='Diatance from bottom'
                          onChange={value => this.handleChartGridChange(value, selected, 'bottom')}
                        />
                      </Panel>
                    )}
                    {!includesChart(selected) && (
                      <Panel header='Pie options' key='pie0'>
                        <label> Size </label>
                        <FieldInputNumber
                          unit='%'
                          defaultValue={this.getPieSize('circle', selected)}
                          max={100}
                          label='Circle size'
                          onChange={value => this.handleChartPieSizeChange(value, selected, 'circle')}
                        />
                        <FieldInputNumber
                          unit='%'
                          defaultValue={this.getPieSize('gap', selected)}
                          max={100}
                          label='Gap size'
                          onChange={value => this.handleChartPieSizeChange(value, selected, 'gap')}
                        />
                        <label> Position </label>
                        <FieldInputNumber
                          unit='%'
                          defaultValue={this.getPiePosition('top', selected)}
                          max={100}
                          label='Vertical position'
                          onChange={value => this.handleChartPiePosChange(value, selected, 'top')}
                        />
                        <FieldInputNumber
                          unit='%'
                          defaultValue={this.getPiePosition('left', selected)}
                          max={100}
                          label='Horizontal position'
                          onChange={value => this.handleChartPiePosChange(value, selected, 'left')}
                        />
                        {
                          <div className='mb-2 mt-2'>
                            <SwitchToggle label='Show percentage' disabled={false} defaultChecked={selected.showPercent} onChange={e => this.updateShowPercentPie(e)} />
                          </div>
                        }
                      </Panel>
                    )}
                    {selected.chartType !== 'bar_chart' && (
                      <Panel header='Chart legend options' key='chart_legend_options'>
                        {selected.hasOwnProperty('title_position') && (
                          <div className='mb-2 mt-2'>
                            <SwitchToggle
                              label={get(selected, 'legend.show', true) ? 'Visible' : 'Hidden'}
                              disabled={false}
                              defaultChecked={get(selected, 'legend.show', true)}
                              onChange={e => this.showChartLegend(e)}
                            />
                          </div>
                        )}

                        {selected.hasOwnProperty('title_position') && (
                          <div className='mb-2 mt-2'>
                            <SwitchToggle
                              label={!selected.legend_horizontal_orient ? 'Set orientation to be horizontal' : 'Set orientation to be vertical'}
                              disabled={false}
                              defaultChecked={selected.legend_horizontal_orient}
                              onChange={e => this.updateLegendOrientationProperty(e)}
                            />
                          </div>
                        )}
                        <label> Position </label>
                        {selected.hasOwnProperty('legend_position') && (
                          <div className='mb-2'>
                            <Select
                              defaultValue={selected.legend_position || 'top_center'}
                              style={{ width: '100%' }}
                              onChange={value => this.updateElement('legend_position', value)}
                            >
                              {titlePos.map(pos => (
                                <Option value={pos.value}>{pos.label}</Option>
                              ))}
                            </Select>
                          </div>
                        )}
                        {selected.legend_position === 'custom' && (
                          <React.Fragment>
                            <FieldInputNumber
                              unit='%'
                              defaultValue={this.getLegendValue('top', selected)}
                              max={100}
                              label='Vertical position'
                              onChange={value => this.handleChartLegendPosChange(value, selected, 'top')}
                            />
                            <FieldInputNumber
                              unit='%'
                              defaultValue={this.getLegendValue('left', selected)}
                              max={100}
                              label='Horizontal position'
                              onChange={value => this.handleChartLegendPosChange(value, selected, 'left')}
                            />
                          </React.Fragment>
                        )}
                      </Panel>
                    )}
                    <Panel header='Chart title position' key='2'>
                      {selected.hasOwnProperty('title_position') && (
                        <div className='mb-2'>
                          <Select
                            defaultValue={selected.title_position || selected.chartType === 'funnel_chart' ? 'top_right' : 'top_center'}
                            style={{ width: '100%' }}
                            onChange={value => this.updateElement('title_position', value)}
                          >
                            {titlePos.map(pos => (
                              <Option value={pos.value}>{pos.label}</Option>
                            ))}
                          </Select>
                        </div>
                      )}
                      {selected.title_position === 'custom' && (
                        <React.Fragment>
                          <FieldInputNumber
                            unit='%'
                            defaultValue={this.getTitleValue('top', selected)}
                            max={100}
                            label='Vertical position'
                            onChange={value => this.handleChartTitlePosChange(value, selected, 'top')}
                          />
                          <FieldInputNumber
                            unit='%'
                            defaultValue={this.getTitleValue('left', selected)}
                            max={100}
                            label='Horizontal position'
                            onChange={value => this.handleChartTitlePosChange(value, selected, 'left')}
                          />
                        </React.Fragment>
                      )}
                    </Panel>
                  </Collapse>
                )}
                {selected.key === 'score_card' && (
                  <Collapse destroyInactivePanel defaultActiveKey='1'>
                    <Panel header='Data options' isActive key='1'>
                      <div className='mb-2'></div>
                      <PipelinesMultiSelect
                        item={selected}
                        pipelines={this.props.pipelines}
                        loadData={this.props.getPipelineCardData}
                        handlePipelineSelection={this.handlePipelineSelection}
                        loadPipelines={this.props.loadPipelines}
                      />
                      <div className='mb-2'>
                        <div>
                          <label> Metric </label>
                        </div>
                        <div className='display-flex-center'>
                          <Cascader
                            options={this.scorecardCascaderOptions}
                            placeholder='Select metric'
                            disabled={!selected.pipeline_id}
                            defaultValue={selected.metric ? [selected.metric] : undefined}
                            onChange={this.handleScoreCardMetricSelection}
                          />
                        </div>
                        {selected.metric && (
                          <div className='mb-2'>
                            <div>
                              <label> Operation </label>
                            </div>
                            <div className='display-flex-center'>
                              <Cascader
                                options={this.scorecardCascaderOperatorOptions}
                                placeholder='Select Operation'
                                disabled={!selected.metric}
                                defaultValue={selected.operation ? [selected.operation] : undefined}
                                onChange={this.handleScoreCardOperatorSelection}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </Panel>
                  </Collapse>
                )}
                {/* {selected.shared && <div className="mt-2">
                  <div className="border-section display-flex-start p-2"><i className="material-icons mr-2">info</i>{selected.name} is a shared component</div>
                </div>} */}
              </div>
            )}

            {this.state.tab === 2 && (
              <div>
                <Collapse>
                  <Panel header='Container style' key='1'>
                    <div className='mb-2'>
                      <SwitchToggle label='Border' disabled={false} defaultChecked={selected.properties.border.show} onChange={e => this.updateBorderProperties('show', e)} />
                    </div>
                    {selected && selected.properties && selected.properties.border && selected.properties.border.show && (
                      <div className='mb-2'>
                        <ButtonColorPicker
                          label='Border color'
                          background={selected.properties.border.color}
                          onColorChange={color => {
                            this.updateBorderProperties('color', color);
                            //this.props.updatePropertiesColor("borderColor", color);
                          }}
                        />
                      </div>
                    )}
                    {selected.key !== 'rectangle' && (
                      <div className='mb-2'>
                        <ButtonColorPicker
                          label='Background color'
                          background={selected.properties.color.backgroundColor}
                          onColorChange={color => {
                            this.updateElementX('properties.color.backgroundColor', color);
                            //this.props.updatePropertiesColor("backgroundColor", color);
                          }}
                        />
                      </div>
                    )}
                  </Panel>
                </Collapse>
                {selected.hasOwnProperty('title_position') && colorPalettesNames && (
                  <div className='mt-2 mb-2'>
                    <label> Chart color palettes:</label>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {colorPalettesNames.map(palette => {
                        return (
                          <div
                            className={
                              get(selected, 'properties.colorPaletteName') === palette ? 'color-palette-container flex-1 color-palette-selected' : 'flex-1 color-palette-container'
                            }
                            style={{ background: `linear-gradient(45deg, ${colorPalettes[palette][0]}, ${colorPalettes[palette][colorPalettes[palette].length - 1]})` }}
                            onClick={() => this.handleColorPaletteClick(colorPalettes[palette], palette)}
                          >
                            <p style={{ margin: '0' }}>
                              <b>{palette.toUpperCase()}</b>
                            </p>
                            <div className='display-flex-center' style={{ border: '1px solid white' }}>
                              {colorPalettes[palette].slice(0, 6).map(color => {
                                return <span style={{ width: 10, height: 10, backgroundColor: color }}></span>;
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {selected.hasOwnProperty('geometric') && (
                  <div className='mb-2'>
                    <ItemColorPicker background={selected.geometric.backgroundColor} onColorChange={color => this.updateElementX('geometric.backgroundColor', color)} />
                  </div>
                )}
                {selected.hasOwnProperty('data_align') && (
                  <div className='mb-2'>
                    <Field
                      name={`data_align`}
                      label='Text align'
                      labelClass='default-color font-weight-100 font-size-14 mb-2'
                      component={ToggleGroup}
                      options={dataAlign}
                      onSelect={value => this.updateElement('data_align', value)}
                      icons={true}
                      flat={true}
                    />
                  </div>
                )}
                {/* { selected.hasOwnProperty("title_position") && (
                  <div className="border-section mb-1 p-1">
                    <Field
                      label="Title position"
                      name={`title_position`}
                      component={FieldSelect}
                      options={titlePos}
                      placeholder="Title postion"
                      clearable={false}
                      onLoad={value => this.updateElement("title_position", value)}
                    />
                  </div>
                )}
                */}

                {selected && selected.hasHeader && (
                  <div className='mb-2'>
                    <ButtonColorPicker
                      label='Header background color'
                      disabled={false}
                      background={selected.properties.header.backgroundColor}
                      onColorChange={color => this.updateHeaderProperties('backgroundColor', color)}
                    />
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

TemplateSidebar = reduxForm({
  form: 'report-sidebar-form',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(TemplateSidebar);

export default TemplateSidebar;
