import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createElement, deleteElement, updateElement, saveData, save, switchElementDeal, switchElementContact, reorderElement } from 'ReduxActions/formActions';
import {
  Header,
  Paragraph,
  Label,
  LineBreak,
  TextInput,
  NumberInput,
  TextArea,
  Dropdown,
  Checkboxes,
  DatePicker,
  RadioButtons,
  Image,
  Rating,
  Tags,
  HyperLink,
  Download,
  Camera,
  Deal,
  Contact,
} from './sortable-form-elements';
import SortableContainer from './sortable-container';

import EditModal from './edit-modal';

const actions = { createElement, deleteElement, updateElement, saveData, save, switchElementDeal, reorderElement, switchElementContact };

class Preview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      answer_data: {},
    };

    this.moveCard = this.moveCard.bind(this);
    this._onDestroy = this._onDestroy.bind(this);
  }

  _setValue(text) {
    return text.replace(/[^A-Z0-9]+/gi, '_').toLowerCase();
  }

  updateElement(element) {
    let data = this.props.data;
    let found = false;

    for (var i = 0, len = data.length; i < len; i++) {
      if (element.id === data[i].id) {
        data[i] = element;
        found = true;
        break;
      }
    }

    if (found) {
      this.props.saveData(data);
    }
  }

  _onChange(data) {
    let answer_data = {};
    data.forEach(item => {
      if (item.readOnly && this.props.variables[item.variableKey]) {
        answer_data[item.field_name] = this.props.variables[item.variableKey];
      }
    });
  }

  _onDestroy(item) {
    this.props.deleteElement(item);
  }

  moveCard(dragIndex, hoverIndex) {
    const { data } = this.props;
    const dragCard = data[dragIndex];

    if (!dragCard.glued && hoverIndex) {
      this.props.reorderElement(data, dragIndex, hoverIndex);
    }
  }

  getElement(item, index) {
    switch (item.element) {
      case 'Deal':
        return (
          <Deal
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'Contact':
        return (
          <Contact
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'Header':
        return (
          <Header
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'Paragraph':
        return (
          <Paragraph
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'Label':
        return (
          <Label
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'LineBreak':
        return (
          <LineBreak
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'TextInput':
        return (
          <TextInput
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'NumberInput':
        return (
          <NumberInput
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'TextArea':
        return (
          <TextArea
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'Dropdown':
        return (
          <Dropdown
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'Checkboxes':
        return (
          <Checkboxes
            id={item.id}
            name='Checkboxes'
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'DatePicker':
        return (
          <DatePicker
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'RadioButtons':
        return (
          <RadioButtons
            id={item.id}
            name='RadioButtons'
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'Rating':
        return (
          <Rating
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'Image':
        return (
          <Image
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'Tags':
        return (
          <Tags
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'HyperLink':
        return (
          <HyperLink
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'Download':
        return (
          <Download
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
      case 'Camera':
        return (
          <Camera
            id={item.id}
            index={index}
            moveCard={this.moveCard}
            mutable={false}
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            isDraggable={true}
            key={item.id}
            sortData={item.id}
            data={item}
            _onDestroy={this._onDestroy}
          />
        );
    }
  }

  render() {
    let classes = this.props.className;
    if (this.props.editMode) {
      classes += ' is-editing';
    }
    let items = this.props.data.map((item, index) => {
      return this.getElement(item, index);
    });

    return (
      <div className={classes}>
        <EditModal
          show={this.props.editElement}
          onHide={this.props.manualEditModeOff}
          showCorrectColumn={this.props.showCorrectColumn}
          element={this.props.editElement}
          updateElement={this.updateElement}
          files={this.props.files}
          preview={this}
          switchElementDeal={this.props.switchElementDeal}
          switchElementContact={(value, prop) => this.props.switchElementContact(value, prop, this.props.editElement)}
          pipelineCustomFields={this.props.pipelineCustomFields}
          contactCustomFields={this.props.contactCustomFields}
        />
        <form>
          <SortableContainer items={items} />
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pipelineCustomFields: state.settings.company.pipelineCustomFields,
    contactCustomFields: state.settings.company.contactCustomFields,
  };
}

Preview.defaultProps = {
  showCorrectColumn: false,
  files: [],
  editMode: false,
  editElement: null,
  className: 'react-form-builder-preview pull-left',
};

Preview = reduxForm({ form: 'form-editor-preview' })(Preview);
export default connect(mapStateToProps, actions)(Preview);
