import moment from 'moment';
import React, { Component } from 'react';
import Image from 'react-bootstrap/lib/Image';
import StarRating from 'SharedComponent/StarRating';
import defaultImage from 'Assets/img/default.png';
import { TooltipAvatar } from 'SharedComponent/antd/CustomAvatar';

import { moneyFormatter } from 'Utils/objectUtils';

import './DealCard.scss';

export default class DealCard extends Component {
  checkField(fields, field) {
    const item = fields.find(e => e.value == field);
    return item ? item.show : false;
  }

  emptyValue(field) {
    if (field == 'image') return defaultImage;
    if (field == 'name') return 'Default';
    return '';
  }

  checkFieldValue(field) {
    const data = this.props.item.data;
    if (data) return data[field] ? data[field] : this.emptyValue(field);
    else return this.emptyValue(field);
  }

  switchField(field, data) {
    if (!data) return '--';
    switch (field) {
      case 'date':
        return moment(data * 1000).format('ll');
      case 'users':
        return (
          <div>
            {data.map(i => (
              <TooltipAvatar key={i.id} size='small' tooltip={i.fullname} image={i.image || defaultImage} />
            ))}
          </div>
        );
      default:
        return data;
    }
  }

  render() {
    const item = this.props.item;
    const fields = item ? item.fields : [];
    const tableFields = fields.filter(it => !['logo', 'domain', 'created_at', 'name', 'amount', 'rating', 'countryName', 'assignee'].includes(it.value));
    const data = item.data;
    return (
      <div className='DealCard'>
        <div className='card card-shadow p-3' style={{ backgroundColor: item.properties.color.backgroundColor }}>
          <div className='card-body'>
            <div className='display-flex-start mr-3 ml-3'>
              {this.checkField(fields, 'logo') && (
                <div className='mr-3'>
                  {data && data.image ? (
                    <Image
                      src={data.image}
                      alt='Logo'
                      style={{
                        height: '62px',
                        width: '62px',
                        margin: '0 auto',
                        borderRadius: '4px',
                      }}
                    />
                  ) : (
                    <div className='deal-image-null deal-image-null-border'>{this.checkFieldValue('name') && this.checkFieldValue('name').charAt(0)}</div>
                  )}
                </div>
              )}
              <div>
                <div className='display-flex-start'>
                  <div className='deal-name ellipsis-details'>{this.checkFieldValue('name')}</div>
                </div>
                {this.checkField(fields, 'amount') && (
                  <div>
                    <span className='deal-value-txt'>{data && data.is_won ? 'Total investment' : 'Ticket size'}: </span>
                    <span className='deal-value-nb'>
                      {data ? moneyFormatter(data.is_won ? data.total_investment : data.size, data && data.currencyName && data.currencyName.code) : ''}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {this.checkField(fields, 'rating') && (
              <div className='display-flex-between m-3'>
                <StarRating
                  disabled={true}
                  rating={data && data.rate ? Number(data.rate.deal_rate) : 0}
                  average={0}
                  showAverage={false}
                  tooltipMessage={`Rating average ${data && data.rate ? data.rate.deal_rate : 0}`}
                  onRatingClick={() => {}}
                />
              </div>
            )}
            {this.checkField(fields, 'created_at') && (
              <div className='display-flex-between m-3'>
                <div className='display-flex-start label-info'>
                  <i className='material-icons font-size-16 mr-2' style={{ color: '#4786ff' }}>
                    insert_invitation
                  </i>
                  {moment(this.checkFieldValue('created_at') * 1000).format('lll')}
                </div>
              </div>
            )}
            <hr />
            <div className='display-flex-start m-3'>
              <table>
                <tbody>
                  {this.checkField(fields, 'email') && (
                    <tr>
                      <td>
                        <div className='label-info mr-2'>Email:</div>
                      </td>
                      <td>
                        <div className='label-value email-value-details'>{this.checkFieldValue('cc_email')}</div>
                      </td>
                    </tr>
                  )}
                  {this.checkField(fields, 'assignee') && (
                    <tr>
                      <td>
                        <div className='label-info mr-2'>Assignee:</div>
                      </td>
                      <td>
                        <div className='label-value flex'>
                          <img
                            height='16'
                            width='16'
                            style={{ borderRadius: '50%' }}
                            src={data && data.assignee && data.assignee.profile && data.assignee.profile.image ? data.assignee.profile.image : defaultImage}
                          />
                          <div className='ml-2'>{data && data.assignee && data.assignee.profile ? data.assignee.profile.fullname : 'N/A'}</div>
                        </div>
                      </td>
                    </tr>
                  )}
                  {this.checkField(fields, 'countryName') && (
                    <tr>
                      <td>
                        <div className='label-info mr-2'>Country:</div>
                      </td>
                      <td>
                        <div className='label-value'>{data && data.countryName && data.countryName.name}</div>
                      </td>
                    </tr>
                  )}
                  {this.checkField(fields, 'domain') && (
                    <tr>
                      <td>
                        <div className='label-info mr-2'>Activity:</div>
                      </td>
                      <td>
                        <div className='label-value'>{data && data.domain && data.domain.name}</div>
                      </td>
                    </tr>
                  )}
                  {tableFields.map(field => {
                    if (field.show) {
                      const isField = this.checkFieldValue(field.value);
                      return <TableRow key={field.value} label={field.label} value={this.switchField(field.type, isField)} />;
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const TableRow = props => (
  <tr>
    <td>
      <div className='label-info mr-2'>{props.label}:</div>
    </td>
    <td>
      <div className='label-value email-value-details'>{props.value}</div>
    </td>
  </tr>
);
