import { RbacRolePermissionResponsePermissions } from '../../generated/model/models';
import { PermissionSummaryData } from './permission-summary-data';

export interface ActionSummaryData {
  name: string;
  permissions: PermissionSummaryData[];
}

export interface ActionEntities {
  [key: string]: { x: number; y: number };
}

export namespace ActionSummaryData {
  export function mapFromApi(data: RbacRolePermissionResponsePermissions): ActionSummaryData {
    return {
      name: data.name,
      permissions: (data.permissionList || []).map(permission => PermissionSummaryData.mapFromApi(permission)),
    };
  }

  export function mapToEntities(actions: ActionSummaryData[]): ActionEntities {
    const entities = {};

    actions.forEach((block, orderX) => {
      block.permissions.forEach((action, orderY) => {
        entities[action.id] = { x: orderX, y: orderY };
      });
    });

    return entities;
  }
}
