import React from 'react';
import { Upload as AntdUpload, Icon } from 'antd';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { Error } from '../../../../../../shared/components/Error/error.component';

const { Dragger } = AntdUpload;

interface UploadFieldProps {
  meta: WrappedFieldMetaProps;
  input: WrappedFieldInputProps;
}

export function Upload(props: UploadFieldProps): JSX.Element {
  const preventRequest = () => false;

  const onRemove = () => props.input.onChange([]);

  const dummyRequest = ({ file, onSuccess }: RcCustomRequestOptions) => {
    setTimeout(() => {
      onSuccess(file, file);
    }, 0);
  };

  const handleChange = ({ file }) => props.input.onChange([file]);

  const innerProps = {
    multiple: false,
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  };

  return (
    <div>
      <Error touched={props.meta && props.meta.touched} error={props.meta && props.meta.error} warning={props.meta && props.meta.warning} />
      <Dragger {...innerProps} beforeUpload={preventRequest} fileList={props.input.value || []} onChange={handleChange} onRemove={onRemove} customRequest={dummyRequest}>
        <p className='ant-upload-drag-icon'>
          <Icon type='inbox' />
        </p>
        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
        <p className='ant-upload-hint'>Support for a single upload.</p>
      </Dragger>
    </div>
  );
}
