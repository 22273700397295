import classNames from 'classnames';
import createClass from 'create-react-class';
import React from 'react';
import Selector from 'react-select';
import { Tooltip } from 'antd';
import api from 'ReduxActions/api';
import Error from '../TextField/Error';

import './SelectOption.scss';
import 'react-select/dist/react-select.css';

const ImageOption = createClass({
  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  },

  handleMouseEnter(event) {
    this.props.onFocus(this.props.option, event);
  },

  handleMouseMove(event) {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  },

  render() {
    let style = {
      borderRadius: '50%',
      display: 'inline-block',
      position: 'relative',
      verticalAlign: 'middle',
      margin: '8px 12px 8px 2px',
    };

    return (
      <div
        className={this.props.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={this.props.option.label}
      >
        {this.props.option.image ? (
          <img src={`${this.props.option.image}`} height='24' width='24' style={style} />
        ) : (
          <span className='option-image-null'>{this.props.option.label ? this.props.option.label.charAt(0) : '!'}</span>
        )}
        {this.props.children}
      </div>
    );
  },
});

const ImageValue = createClass({
  render() {
    const style = {
      borderRadius: '50%',
      display: 'inline-block',
      position: 'relative',
      verticalAlign: 'middle',
      margin: '8px 12px 8px 2px',
    };
    if (this.props.noImageCountries) return this.props.children;
    return (
      <div className='Select-value' title={this.props.value.label}>
        <span className='Select-value-label display-flex-start'>
          {this.props.value.image ? (
            <img src={`${this.props.value.image}`} height='24' width='24' style={style} />
          ) : (
            <span className='option-image-null margin-image-null'>{this.props.value && this.props.value.label && this.props.value.label.charAt(0)}</span>
          )}
          {this.props.children}
        </span>
      </div>
    );
  },
});

const MultiValueText = createClass({
  render() {
    return <React.Fragment> {this.props.value.name + ','} </React.Fragment>;
  },
});

export const FieldSelect = props => (
  <div className='SelectOption'>
    <div className='display-flex-between'>
      {props.label && (
        <div className={classNames('default-color', props.labelClass ? props.labelClass : 'label-selector')}>
          {props.label}
          {props.required && (
            <span className='' style={{ color: '#ff8181' }}>
              &nbsp;&#9679;
            </span>
          )}
          {props.onHover && (
            <Tooltip title={props.onHover}>
              <i className='ml-1 icon-info icon-info-details' />
            </Tooltip>
          )}
        </div>
      )}
      <Error touched={props.meta.touched} error={props.meta.error} warning={props.meta.warning} />
    </div>
    <div>
      <Selector
        arrowRenderer={arrowRenderer}
        value={props.input.value || props.defaultValue}
        onChange={e => {
          props.input.onChange(e);
          props.onLoad && props.onLoad(e);
        }}
        onBlur={() => props.input.onBlur(props.input.value)}
        options={props.options}
        placeholder={props.placeholder || ''}
        simpleValue
        closeOnSelect={props.closeOnSelect === false ? false : true}
        multi={props.multi ? props.multi : false}
        removeSelected={props.removeSelected === false ? false : true}
        style={{
          border: '1px solid #e6e8ea',
          backgroundColor: props.disabled ? '#f9f9f9' : '#ffffff',
          borderRadius: '2px',
          cursor: props.disabled ? 'not-allowed' : 'default',
          ...props.style,
        }}
        clearable={props.clearable}
        rtl={props.rtl}
        valueKey={props.valueKey || 'value'}
        labelKey={props.labelKey || 'label'}
        disabled={props.disabled}
        valueComponent={props.withImage ? iprops => <ImageValue {...iprops} noImageCountries={props.noImageCountries} /> : props.multiVal ? MultiValueText : undefined}
        optionComponent={props.withImage ? ImageOption : undefined}
        menuContainerStyle={props.top ? { top: 'auto', bottom: '100%' } : undefined}
      />
    </div>
  </div>
);

FieldSelect.defaultProps = {
  closeOnSelect: true,
  rtl: false,
};

export const AsyncSearch = props => (
  <div className='SelectOption'>
    <div className='display-flex-between'>
      {props.label && (
        <div className={classNames('default-color', props.labelClass ? props.labelClass : 'label-selector')}>
          {props.label}
          {props.required && (
            <span className='' style={{ color: '#ff8181' }}>
              &nbsp;&#9679;
            </span>
          )}
        </div>
      )}
      <Error touched={props.meta.touched} error={props.meta.error} warning={props.meta.warning} />
    </div>
    <div>
      <Selector.Async
        arrowRenderer={arrowRenderer}
        value={props.input.value}
        onChange={e => {
          props.input.onChange(e);
          props.onLoad && props.onLoad(e);
        }}
        onBlur={() => props.input.onBlur(props.input.value)}
        loadOptions={input => getList(input, props.url, props.field, props.listValue, props.filters, props.code)}
        valueKey={props.listValue}
        labelKey={props.field}
        placeholder={props.placeholder || ''}
        multi={props.multi ? props.multi : false}
        style={{
          border: '1px solid #e6e8ea',
          backgroundColor: '#ffffff',
          borderRadius: '2px',
          ...props.style,
        }}
        clearable={props.clearable}
        rtl={props.rtl}
        valueComponent={props.withImage ? ImageValue : undefined}
        optionComponent={props.withImage ? ImageOption : undefined}
        menuContainerStyle={props.top ? { top: 'auto', bottom: '100%' } : undefined}
      />
    </div>
  </div>
);

AsyncSearch.defaultProps = {
  rtl: false,
};

export const FieldSelectCreation = props => (
  <div className='SelectOption'>
    <div className='display-flex-between'>
      {props.label && (
        <div className={classNames('default-color', props.labelClass ? props.labelClass : 'label-selector')}>
          {props.label}
          {props.required && (
            <span className='' style={{ color: '#ff8181' }}>
              &nbsp;&#9679;
            </span>
          )}
        </div>
      )}
      {!props.errorDown && <Error touched={props.meta.touched} error={props.meta.error} warning={props.meta.warning} />}
    </div>
    <div>
      <Selector.Creatable
        arrowRenderer={arrowRenderer}
        value={props.input.value}
        onChange={e => {
          props.input.onChange(e);
          props.onLoad && props.onLoad(e);
        }}
        options={props.options}
        placeholder={props.placeholder || 'Select'}
        multi={props.multi ? props.multi : false}
        style={{
          border: '1px solid #e6e8ea',
          backgroundColor: '#ffffff',
          borderRadius: '2px',
          height: '100%',
          ...props.style,
        }}
        clearable={props.clearable}
        rtl={props.rtl}
        valueComponent={props.withImage ? ImageValue : undefined}
        optionComponent={props.withImage ? ImageOption : undefined}
        menuContainerStyle={props.top ? { top: 'auto', bottom: '100%' } : undefined}
      />
    </div>
    {props.errorDown && <Error touched={props.meta.touched} error={props.meta.error} warning={props.meta.warning} />}
  </div>
);

function arrowRenderer() {
  return <i className='icon-arrow-down' />;
}

function getList(input, url, field, value, filters, code) {
  if (!input) {
    return Promise.resolve({ options: [] });
  }
  let query = `filter[${field}][like]=${input}`;
  if (filters) query += `&fields=${filters}`;
  const filterResults = results => results.map(result => ({ name: `${result.code} (${result.name})`, value: result.value }));
  return api.get(`${url}?${query}`).then(({ data }) => {
    return Promise.resolve({ options: !code ? data.results : filterResults(data.results) });
  });
}
