import moment from 'moment';
import classNames from 'classnames';
import React, { Component } from 'react';
import { TimePicker, DatePicker } from 'antd';
import Error from '../TextField/Error';

import './TimeInputField.scss';

export default class TimeInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datetime: this.props.input.value ? moment(this.props.input.value) : null,
    };
    this.onChangeUncontrolled = this.onChangeUncontrolled.bind(this);
  }

  onChangeUncontrolled(datetime) {
    this.props.input.onChange(datetime);
  }

  render() {
    const { props } = this;
    return (
      <div className={classNames('TimeInputField display-flex-center-end', this.props.classNames)}>
        <div>
          <div className='display-flex-between'>
            {props.labelDate && <span className={this.props.labelClass ? this.props.labelClass : 'label-time font-weight-500 font-size-14'}>{props.labelDate}</span>}
            <Error
              touched={props.meta && props.meta.touched}
              error={props.meta && props.meta.error}
              warning={props.meta && props.meta.warning}
              asyncValidating={props.meta.asyncValidating}
            />
          </div>
          <DatePicker
            value={this.props.input.value ? moment(this.props.input.value) : null}
            placeholder={this.props.datePlaceholder}
            onChange={this.onChangeUncontrolled}
            className='date-input-details'
            size='large'
            disabled={this.props.disabled}
            showTime={false}
            disabledDate={e => moment(new Date()).valueOf() > moment(e).valueOf()}
          />
        </div>
        <div>
          {props.labelTime && <span className={this.props.labelClass ? this.props.labelClass : 'label-time font-weight-500 font-size-14'}>{props.labelTime}</span>}
          <TimePicker
            format={'HH:mm'}
            value={this.props.input.value ? moment(this.props.input.value, 'HH:mm') : null}
            placeholder={this.props.timePlaceholder || 'Time'}
            className='date-input-details'
            disabled={this.props.disabled}
            size='large'
            onChange={this.onChangeUncontrolled}
          />
        </div>
      </div>
    );
  }
}
