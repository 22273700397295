import moment from 'moment';
import { Avatar, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn';
import Table from 'SharedComponent/Table';

import { moneyFormatter } from 'Utils/objectUtils';

import { Permissible } from '../../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';

import defaultImage from 'Assets/img/default.png';
import portfolioSvg from 'Assets/img/empty-portfolio.svg';

class FundsTable extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    onEdit: PropTypes.func,
    onSortChange: PropTypes.func,
    noDataText: PropTypes.string,
    pagination: PropTypes.bool,
    checkBox: PropTypes.bool,
  };

  static defaultProps = {
    noDataText: 'No funds found',
    pagination: false,
    checkBox: false,
  };

  constructor(props) {
    super(props);

    this.actionsFormatter = this.actionsFormatter.bind(this);
    this.logoFormatter = this.logoFormatter.bind(this);
  }

  render() {
    const { props } = this;
    return (
      <div className='PipelinesTable'>
        <Table
          items={props.items}
          pagination={props.pagination}
          loading={props.isLoading}
          onSortChange={props.onSortChange}
          noDataText={this.emptyData()}
          checkBox={props.checkBox}
          onSelectAll={props.onSelectAll}
          onRowSelect={props.onRowSelect}
          onRowClick={props.onEdit}
        >
          <TableHeaderColumn dataField='fund_id' isKey hidden>
            ID
          </TableHeaderColumn>

          <TableHeaderColumn dataField='fund_name' dataAlign='left' width='50'>
            Name
          </TableHeaderColumn>

          <TableHeaderColumn dataField='fundAmount' dataAlign='left' width='50' dataFormat={(cell, row) => moneyFormatter(cell, 'USD')}>
            Total Amount
          </TableHeaderColumn>

          <TableHeaderColumn dataField='lpsNumber' dataAlign='left' width='50'>
            Number of LPs
          </TableHeaderColumn>

          <TableHeaderColumn dataField='owner' dataFormat={this.logoFormatter} dataAlign='left' width='80'>
            Owner
          </TableHeaderColumn>

          <TableHeaderColumn dataField='created_at' dataAlign='left' width='50' dataFormat={(cell, row) => moment(cell * 1000).format('lll')}>
            Created at
          </TableHeaderColumn>

          <TableHeaderColumn dataFormat={this.actionsFormatter} dataAlign='left' width='30' columnClassName='custome-table-outline' isActionColumn>
            Actions
          </TableHeaderColumn>
        </Table>
      </div>
    );
  }

  logoFormatter(cell, row) {
    return (
      <div>
        <Avatar src={cell.image || defaultImage} />
        <span style={{ marginLeft: '12px' }}>{cell && cell.fullname}</span>
      </div>
    );
  }

  actionsFormatter(cell, row) {
    const { props } = this;
    return (
      <div className='display-flex-end' placement='topRight'>
        <Permissible can={PermissionTypeEnum.UpdateFund}>
          <Tooltip title='Edit Fund'>
            <i
              className='icon-pencil'
              onClick={e => {
                e.stopPropagation();
                props.onEdit(row);
              }}
              style={{ width: '36px' }}
            />
          </Tooltip>
        </Permissible>
        <Permissible can={PermissionTypeEnum.DeleteFund}>
          <Tooltip title='Delete Fund' placement='topRight'>
            <i
              className='icon-trash'
              onClick={e => {
                e.stopPropagation();
                props.onRemove(row);
              }}
              style={{ width: '36px' }}
            />
          </Tooltip>
        </Permissible>
      </div>
    );
  }

  emptyData() {
    return (
      <div>
        {this.props.isLoading ? (
          <div className='custom-spinner'>
            <div id='custom-spinner-id' />
          </div>
        ) : (
          <div className='m-4'>
            <img src={portfolioSvg} />
            <div className='empty-tpl'>Looks like you dont have any Fund yet, so lets start by adding some</div>
          </div>
        )}
      </div>
    );
  }
}

export default FundsTable;
