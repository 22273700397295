import moment, { Moment } from 'moment';

import { TaskPriorityEnum } from '../../enums/task/task-priority.enum';
import { TaskStatusEnum } from '../../enums/task/task-status.enum';
import { TaskTypeEnum } from '../../enums/task/task-type.enum';
import { ZoomStatusEnum } from '../../enums/zoom/zoom-status.enum';
import { TaskResponseDTO } from '../../generated/model/taskResponseDTO';
import { DealLightData } from '../deal/deal-light-data';

import { FileLightData } from '../file/file-light-data';

import { MeetingInfoData } from '../meeting/meeting-info-data';
import { UserLightData } from '../user/user-light-data';

export interface TasksCalenderData {
  uuid: string;
  title: string;
  attendees: UserLightData[];
  guests: string[];
  description: string;
  assignee: UserLightData;
  status: TaskStatusEnum;
  dueDate: Moment;
  deal: DealLightData;
  files: FileLightData[];
  type: TaskTypeEnum;
  priority: TaskPriorityEnum;
  createdBy: UserLightData;
  forVote: boolean;
  zoom: boolean;
  meetingInfo: MeetingInfoData;
  zoomStatus: ZoomStatusEnum;
}

export namespace TasksCalenderData {
  export function mapFromApi(task: TaskResponseDTO): TasksCalenderData {
    return {
      uuid: task.task_uuid,
      title: task.title,
      attendees: (task.attendees || []).map(attendee => UserLightData.mapFromApi(attendee)),
      guests: task.guests || [],
      description: task.description,
      assignee: task.assignee && UserLightData.mapFromApi(task.assignee),
      status: TaskStatusEnum.convertFromApiValue.getValue(task.status),
      dueDate: moment.unix(task.due_date),
      deal: task.deal && DealLightData.mapFromApi(task.deal),
      files: (task.files || []).map(file => FileLightData.mapFromApi(file)),
      type: TaskTypeEnum.convertFromApiValue.getValue(task.eventype),
      priority: TaskPriorityEnum.convertFromApiValue.getValue(task.task_priority),
      createdBy: task.created_by && UserLightData.mapFromApi(task.created_by),
      forVote: task.isForVoting,
      zoom: task.is_zoom_call,
      zoomStatus: ZoomStatusEnum.convertFromApiValue(task.zoom_meeting_status),
      meetingInfo: task.meeting_info && MeetingInfoData.mapFromApi(task.meeting_info),
    };
  }
}
