import React from 'react';

import { Spin, Icon } from 'antd';

interface SpinnerProps {
  fontSize: number;
  className?: string;
}

export function Spinner(props: SpinnerProps): JSX.Element {
  const antIcon = <Icon type='loading' style={{ fontSize: props.fontSize }} spin={true} className={props.className} />;

  return <Spin indicator={antIcon} />;
}

Spinner.defaultProps = {
  fontSize: 24,
};
