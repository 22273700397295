export const CHAT_TABS_ADDED = 'live:chat:tab:added';
export const CHAT_TABS_CLOSED = 'live:chat:tab:closed';

export function addChatTab(user) {
  return dispatch => {
    dispatch({ type: CHAT_TABS_ADDED, payload: user });
  };
}

export function closeChatTab(user) {
  return dispatch => {
    dispatch({ type: CHAT_TABS_CLOSED, payload: user });
  };
}
