import remove from 'lodash/remove';
import orderBy from 'lodash/orderBy';
import { EVENTS_CREATED, EVENT_UPDATED } from 'ReduxActions/calendarActions';
import {
  LOADING_LAST_ACTIVITIES,
  LOADED_LAST_ACTIVITIES,
  LOADED_UPCOMING_FILES,
  LOADED_UPCOMING_EMAILS,
  LOADING_UPCOMING_FILES,
  LOADING_UPCOMING_EMAILS,
  UPCOMING_MEETING_DELETED,
  LOADED_MY_TASKS,
  SEARCH_ANYTHING,
  TASK_ADDED,
  TASK_UPDATED,
  TASK_DELETED,
  DASHBOARD_COUNTS_LOADED,
  LAST_ACTIVITY_CREATED,
  SEARCHING,
  SEARCHING_LIST_LOADED,
  LOADING_MY_TASKS,
  LOADING_LATEST_NOTES,
  LOADED_LATEST_NOTES,
} from 'ReduxActions/dashboardActions';
import { replace } from 'Utils/arrayUtils.js';

const initialState = {
  counts: null,
  files: [],
  emails: [],
  notes: [],
  emailsLoading: false,
  filesLoading: false,
  notesLoading: false,
  myTasks: [],
  tasksLoading: false,
  activities: [],
  activitiesLoading: false,
  searching: false,
  isSearching: false,
  searchList: [],
};

function reorderTasks(tasks) {
  const done = tasks.filter(t => t.status === 1);
  const todo = tasks.filter(t => t.status !== 1 || !t.hasOwnProperty('status'));
  const orderTodo = orderBy(todo, ['created_at'], ['desc']);
  return [...orderTodo, ...done];
}

export default function (state = initialState, action) {
  let tasks;
  switch (action.type) {
    case DASHBOARD_COUNTS_LOADED:
      return {
        ...state,
        counts: action.payload,
      };

    case LOADED_UPCOMING_FILES:
      return {
        ...state,
        files: action.payload,
        filesLoading: false,
      };
    case LOADED_UPCOMING_EMAILS:
      return {
        ...state,
        emails: action.payload,
        emailsLoading: false,
      };
    case LOADING_LATEST_NOTES:
      return {
        ...state,
        notesLoading: action.payload,
      };
    case LOADED_LATEST_NOTES:
      return {
        ...state,
        notes: action.payload,
        notesLoading: false,
      };
    case UPCOMING_MEETING_DELETED:
      const deletedMeetings = [...state.meetings];
      remove(deletedMeetings, { event_id: action.payload });
      return {
        ...state,
        meetings: deletedMeetings,
      };

    case EVENTS_CREATED:
      return {
        ...state,
        meetings: [action.payload, ...state.meetings],
      };

    case EVENT_UPDATED: {
      return {
        ...state,
        meetings: replace(state.meetings, { event_id: action.payload.event_id }, action.payload),
      };
    }

    case LOADING_UPCOMING_EMAILS:
      return {
        ...state,
        emailsLoading: action.payload,
      };
    case LOADING_UPCOMING_FILES:
      return {
        ...state,
        filesLoading: action.payload,
      };

    case LOADING_MY_TASKS:
      return {
        ...state,
        tasksLoading: true,
      };

    case LOADED_MY_TASKS:
      return {
        ...state,
        myTasks: reorderTasks(action.payload),
        tasksLoading: false,
      };

    case LOADED_LAST_ACTIVITIES:
      return {
        ...state,
        activities: action.payload,
        activitiesLoading: false,
      };

    case LOADING_LAST_ACTIVITIES:
      return {
        ...state,
        activitiesLoading: action.payload,
      };

    case SEARCH_ANYTHING:
      return {
        ...state,
        searching: action.payload ? action.payload : false,
      };

    case SEARCHING:
      return {
        ...state,
        isSearching: action.payload,
        searchList: [],
      };

    case SEARCHING_LIST_LOADED:
      return {
        ...state,
        isSearching: false,
        searchList: action.payload,
      };

    case TASK_ADDED:
      return {
        ...state,
        myTasks: reorderTasks([...state.myTasks, action.payload]),
      };

    case TASK_UPDATED:
      const updatedTasks = replace(state.myTasks, { task_id: action.payload.task_id }, action.payload);
      return {
        ...state,
        myTasks: reorderTasks(updatedTasks),
      };

    case TASK_DELETED:
      tasks = [...state.myTasks];
      remove(tasks, { task_id: action.payload });
      return {
        ...state,
        myTasks: tasks,
      };

    case LAST_ACTIVITY_CREATED:
      return {
        ...state,
        activities: [action.payload, ...state.activities],
      };
  }

  return state;
}
