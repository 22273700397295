import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './Support.scss';
import { Mixpanel } from 'App/app';

const actions = {};

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdown: !prevState.dropdown,
    }));
    if (!this.state.dropdown) {
      gaEvent(appCategories.header, appActions(appTypes.support, appEvents.opened).dropdown);
    }
  };

  onItemClicked = item => {
    if (item.action) item.action();
    gaEvent(appCategories.header, appActions(`${appTypes.extra_options}:${item.ga}`, appEvents.clicked).item);
    Mixpanel.track('Clicked on Tutorial');
  };

  render() {
    // The integration with intercom was removed, so the chat option was removed as well
    const items = [
      // {
      //   state: 'chat',
      //   label: 'Chat with us!',
      //   icon: 'icon-bubble',
      //   ga: 'OPEN_INTERCOM_CHAT',
      //   action: () => Intercom('show'),
      // },
      {
        label: 'Tutorial videos',
        icon: 'icon-social-youtube',
        ga: 'OPEN_YOUTUBE_CHANNEL_LINK',
        isLink: true,
        link: 'https://www.youtube.com/channel/UCDtiVtuUhTS-aUkWNXnDEAw/videos',
      },
    ];
    return (
      <div className='Support display-flex-center'>
        <Dropdown isOpen={this.state.dropdown} toggle={this.toggle}>
          <DropdownToggle tag='span' data-toggle='dropdown' aria-expanded={this.state.dropdown}>
            <i className='material-icons dropdown-details'>help_outline</i>
          </DropdownToggle>
          <DropdownMenu right className='drop-down-position'>
            {items.map((item, index) => (
              <DropdownItem key={index + 1} className='display-flex-start cursor-pointer' onClick={() => this.onItemClicked(item)}>
                {item.isLink ? (
                  <a href={item.link} style={{ color: 'inherit', textDecoration: 'none' }} target='_blank'>
                    <i className={item.icon} />
                    <span className='ml-2'>{item.label}</span>
                  </a>
                ) : (
                  <Fragment>
                    <i className={item.icon} />
                    <span className='ml-2'>{item.label}</span>
                  </Fragment>
                )}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(Support);
