import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { change, Field } from 'redux-form';

import { required } from '../../../../../shared/utils/validators';
import { Modal, Button, Steps, Form } from '../../../../../shared/components';
import { ButtonTypeEnum } from '../../../../../shared/enums/button-type.enum';
import { IntegrationExcelFormNamesEnum } from '../../../../../core/enums/form-names/integration-excel-form-names.enum';

import { ModalIdEnum } from '../../../../../core/enums/entity-ids/modal-id.enum';
import { ExcelStepsEnum } from '../../../../../core/enums/entity-steps/excel-steps.enum';
import { FormIdEnum } from '../../../../../core/enums/entity-ids/form-id.enum';
import { IntegrationExcelData } from '../../../../../core/models/integration-excel/integration-excel-data';

import { Template } from './template/template.component';
import { ExcelForm } from './excel-form/excel-form.component';
import { Upload } from './upload/upload.component';

import { downloadTemplate, uploadTemplate } from '../../state/integration.actions';

import './excel.component.scss';

// interface ExcelProps {
//   submitting: boolean;
//   onClose(): void;
// }

export function ExcelModal(props) {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(ExcelStepsEnum.stepToIndex.getValue(ExcelStepsEnum.Template));

  const previousStep = () => setCurrentStep(ExcelStepsEnum.previousStep.getValue(currentStep));
  const nextStep = () => setCurrentStep(ExcelStepsEnum.nextStep.getValue(currentStep));
  const onSubmit = excelForm => {
    if (currentStep === ExcelStepsEnum.stepToIndex.getValue(ExcelStepsEnum.Upload)) {
      dispatch(uploadTemplate(excelForm));
    } else if (currentStep === ExcelStepsEnum.stepToIndex.getValue(ExcelStepsEnum.Form)) {
      nextStep();
    }
  };

  const download = isPortfolio => dispatch(downloadTemplate(isPortfolio));
  const onChangePipeline = () => dispatch(change(FormIdEnum.integrationExcelForm, IntegrationExcelFormNamesEnum.formFields.getValue(IntegrationExcelFormNamesEnum.Stage), null));

  return (
    <Modal title='Microsoft Excel integration' modalId={ModalIdEnum.excelIntegration} width='42rem'>
      <Form className='ExcelModal' formId={FormIdEnum.integrationExcelForm} initialValues={{}} onSubmit={onSubmit}>
        <div className='dialog-content'>
          <Steps phases={ExcelStepsEnum.selectItems} currentStep={currentStep} />
          <div className='mt-rem-1'>
            {ExcelStepsEnum.indexToStep.getValue(currentStep) === ExcelStepsEnum.Template && <Template download={download} />}
            {ExcelStepsEnum.indexToStep.getValue(currentStep) === ExcelStepsEnum.Form && <ExcelForm onChangePipeline={onChangePipeline} />}
            {ExcelStepsEnum.indexToStep.getValue(currentStep) === ExcelStepsEnum.Upload && (
              <Field component={Upload} name={IntegrationExcelFormNamesEnum.formFields.getValue(IntegrationExcelFormNamesEnum.File)} validate={[required]} />
            )}
          </div>
        </div>
        <div className='dialog-footer'>
          <Button label='Cancel' icon='close' btnClass='pentugram-btn-default' onClick={props.onClose} />
          <div className='navigation-steps'>
            {currentStep !== ExcelStepsEnum.stepToIndex.getValue(ExcelStepsEnum.Template) && (
              <Button label='Previous' btnClass='pentugram-btn-default' disabled={props.submitting} onClick={previousStep} />
            )}
            {currentStep === ExcelStepsEnum.stepToIndex.getValue(ExcelStepsEnum.Template) && (
              <Button label='Next' btnClass='pentugram-btn-default' disabled={props.submitting} onClick={nextStep} />
            )}
            {currentStep === ExcelStepsEnum.stepToIndex.getValue(ExcelStepsEnum.Form) && (
              <Button label='Next' btnClass='pentugram-btn-default' disabled={props.submitting} htmlType={ButtonTypeEnum.Submit} />
            )}
            {currentStep === ExcelStepsEnum.stepToIndex.getValue(ExcelStepsEnum.Upload) && (
              <Button label='Save' btnClass='pentugram-primary-btn pentugram-btn-default' loading={props.submitting} disabled={props.submitting} htmlType={ButtonTypeEnum.Submit} />
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
