import React, { Component } from 'react';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './PipelineDialogType.scss';

class PipelineDialogType extends Component {
  constructor(props) {
    super(props);
  }

  handleAction() {
    this.props.update(this.props.pipeline);
    this.props.isPortfolio
      ? gaEvent(appCategories.portfolio, appActions(`${appTypes.move}_${appTypes.modal}_${appTypes.move}_`, appEvents.clicked).button)
      : gaEvent(appCategories.pipeline, appActions(`${appTypes.move}_${appTypes.modal}_${appTypes.move}_`, appEvents.clicked).button);
    this.props.onHide();
  }

  onCloseDialogBox() {
    this.props.isPortfolio
      ? gaEvent(appCategories.portfolio, appActions(`${appTypes.move}_${appTypes.modal}_${appTypes.cancel}_`, appEvents.clicked).icon)
      : gaEvent(appCategories.pipeline, appActions(`${appTypes.move}_${appTypes.modal}_${appTypes.cancel}_`, appEvents.clicked).icon);

    this.props.onHide();
  }

  onClickCancelBtn() {
    this.props.isPortfolio
      ? gaEvent(appCategories.portfolio, appActions(`${appTypes.move}_${appTypes.modal}_${appTypes.cancel}_`, appEvents.clicked).button)
      : gaEvent(appCategories.pipeline, appActions(`${appTypes.move}_${appTypes.modal}_${appTypes.cancel}_`, appEvents.clicked).button);
    this.props.onHide();
  }

  render() {
    if (!this.props.showDetails) return null;
    return (
      <div className='PipelineDialogType'>
        <DialogBox isVisible onClickOut={this.props.onHide} onClose={() => this.onCloseDialogBox()} className='small-dalog'>
          <div className='p-3'>
            <span className='font-size-16 font-weight-500'>
              Are you sure you want to move the {this.props.isPortfolio ? 'Portfolio' : 'Pipeline'} <strong>"{this.props.pipeline.name}"</strong> to{' '}
              {this.props.isPortfolio ? 'Pipeline' : 'Portfolio'} ?
            </span>
          </div>
          <div className='display-flex-end'>
            <div className='m-3'>
              <SubmitButton
                label='Cancel'
                icon='icon-close'
                style={{
                  width: '150px',
                  color: '#53627c',
                  backgroundColor: '#fff',
                  border: '1px solid #e1e4e8',
                }}
                onClick={() => this.onClickCancelBtn()}
              />
            </div>
            <div className='m-3'>
              <SubmitButton
                label={this.props.isPortfolio ? 'Move to Pipeline' : 'Move to Portfolio'}
                icon='icon-share-alt'
                style={{ width: '150px' }}
                onClick={() => this.handleAction()}
              />
            </div>
          </div>
        </DialogBox>
      </div>
    );
  }
}

export default PipelineDialogType;
