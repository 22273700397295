import { ApiUrlsEnum } from '../helpers/api-url';
import { formData, get, post, _delete } from './helpers/base-http';

import { lazyLoadPost } from './helpers/base-http';
import { apiUrlMatcher } from '../helpers/api-url-matcher';
import { HttpParamsType } from '../models/queries/http-params.type';
import { TaskResponseDTO } from '../generated/model/taskResponseDTO';
import { TaskCreateRequestDTO } from '../generated/model/taskCreateRequestDTO';
import { DataProviderRequestDTO } from '../generated/model/dataProviderRequestDTO';
import { LazyLoadResponse } from '../models/queries/lazy-response.model';
import { TasksSummaryData } from '../models/__task/tasks-summary-data';
import { TaskDetailsData } from '../models/__task/task-details-data';
import { TaskUpdateRequestDTO } from '../generated/model/taskUpdateRequestDTO';
import { MeetingInfoData } from '../models/meeting/meeting-info-data';
import { ZoomMeetingResponseDTO } from '../generated/model/zoomMeetingResponseDTO';
import { TaskForVote } from '../models/__task/task-for-vote';
import { ZoomCreateMeetingTaskRequestDTO } from '../generated/model/zoomCreateMeetingTaskRequestDTO';
import { TasksCalenderData } from '../models/calendar/tasks-calender-data';

export async function getTasksList(params: HttpParamsType<DataProviderRequestDTO>): Promise<LazyLoadResponse<TasksSummaryData[]>> {
  const data = await lazyLoadPost<TaskResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.LazyTasks), params);

  return {
    _meta: data._meta,
    results: data.results.map(task => TasksSummaryData.mapFromApi(task)),
  };
}

export async function addTask(params: HttpParamsType<TaskCreateRequestDTO>): Promise<TasksSummaryData> {
  const data = await formData<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.Tasks), params);
  return TasksSummaryData.mapFromApi(data);
}

export async function updateTask(uuid: string, params: HttpParamsType<TaskUpdateRequestDTO>): Promise<TasksSummaryData> {
  const data = await formData<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.UpdateTask, { uuid }), params);

  return TasksSummaryData.mapFromApi(data);
}

export async function zoomUrl({ id, started }: MeetingInfoData): Promise<string> {
  const data = await post<ZoomMeetingResponseDTO>(apiUrlMatcher(ApiUrlsEnum.ZoomCallUrl, { id }), { body: { meeting_start: started } });

  return data.url;
}

export async function updateTaskStatus(uuid: string, status: TaskResponseDTO.StatusEnum): Promise<TasksSummaryData> {
  const data = await post<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.TaskStatus, { uuid }), { body: { status } });

  return TasksSummaryData.mapFromApi(data);
}

export async function createZoomCall(params: HttpParamsType<ZoomCreateMeetingTaskRequestDTO>): Promise<string> {
  const data = await post<ZoomMeetingResponseDTO>(apiUrlMatcher(ApiUrlsEnum.ZoomTask), params);

  return data.url;
}

export async function votForTask(data: TaskForVote): Promise<TasksSummaryData> {
  const task = await post<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.VoteTask, { uuid: data.uuid }), { body: TaskForVote.mapToApiValue(data) });

  return TasksSummaryData.mapFromApi(task);
}

export async function getTaskByUuid(uuid: string): Promise<TaskDetailsData> {
  const data = await get<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.Task, { uuid }));

  return TaskDetailsData.mapFromApi(data);
}

export async function getTasksListCalender(params: HttpParamsType<DataProviderRequestDTO>): Promise<TasksCalenderData[]> {
  const tasks = await post<TaskResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.CalenderTasks), params);

  return tasks.map(task => TasksCalenderData.mapFromApi(task));
}

export async function addCalenderTask(params: HttpParamsType<TaskCreateRequestDTO>): Promise<TasksCalenderData> {
  const data = await formData<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.Tasks), params);

  return TasksCalenderData.mapFromApi(data);
}

export async function updateCalenderTask(uuid: string, params: HttpParamsType<TaskUpdateRequestDTO>): Promise<TasksCalenderData> {
  const data = await formData<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.UpdateTask, { uuid }), params);

  return TasksCalenderData.mapFromApi(data);
}

export async function deleteTask(taskUuid: string): Promise<boolean> {
  const task = await _delete<boolean>(apiUrlMatcher(ApiUrlsEnum.DeleteTask, { uuid: taskUuid }));

  return task;
}
