import moment from 'moment';

import { DashboardNotificationTypeEnum } from '../../../../core/enums/dashboard-notification-type/dashboard-notification-type';
import { NotificationResponseDTO } from '../../../generated/model/notificationResponseDTO';
import { DealLightData } from '../../deal/deal-light-data';
import { UserLightData } from '../../user/user-light-data';

export interface ActivitySummaryData {
  activityId: string;
  message: string;
  createTime: string;
  createDate: string;
  deal: DealLightData;
  user: UserLightData;
  sourceType: DashboardNotificationTypeEnum;
  sourceId: string;
}

export namespace ActivitySummaryData {
  export function mapFromApi(activity: NotificationResponseDTO): ActivitySummaryData {
    return {
      activityId: activity.notif_id.toString(),
      message: activity.message.toString(),
      createTime: moment(activity.created_at * 1000).format('h:mmA'),
      createDate: moment(activity.created_at * 1000).format('MM-DD-YYYY '),
      deal: activity.deal && DealLightData.mapFromApi(activity.deal),
      user: activity.user && UserLightData.mapFromApi(activity.user),
      sourceType: DashboardNotificationTypeEnum.convertFromApiValue.getValue(activity.source_type),
      sourceId: activity.source_id,
    };
  }
}
