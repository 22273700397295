import React, { useState, useRef, useEffect } from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { Select as AntdSelect } from 'antd';
import debounce from 'lodash/debounce';

import { LazyLoadResponse } from '../../../../core/models/queries/lazy-response.model';
import { initializeMetaQueries, mapPaginationQueriesToMetaQueriesData, MetaQuery } from '../../../../core/models/queries/meta-query.model';
import { PaginationQuery } from '../../../../core/models/queries/pagination-query.model';
import { HttpParamsType } from '../../../../core/models/queries/http-params.type';

import { SelectModeEnum } from '../../../enums/select-mode.enum';
import { AvatarData, AvatarShapeEnum } from '../../../interfaces/avatar-data';
import { SelectItemData } from '../../../interfaces/select-item';
import { Spinner } from '../../Spinner/spinner.component';
import { Avatar } from '../../Avatar/avatar.component';

import { Error } from '../../Error/error.component';

import './select-resolver.component.scss';

const { Option } = AntdSelect;

// interface SelectOptionItem {
//   showImage: boolean;
//   avatarData: AvatarData;
//   item: SelectItemData;
// }

const OptionItem = props => (
  <React.Fragment>
    {props.showImage && (
      <span className='mr-rem-0_5'>
        <Avatar shape={props.avatarData.shape} src={props.item.image} icon={props.item.icon} />
      </span>
    )}
    {props.item.name}
  </React.Fragment>
);

// interface SelectProps {
//   placeholder: string;
//   allowClear: boolean;
//   disabled: boolean;
//   showImage: boolean;
//   avatarData: AvatarData;
//   mode: SelectModeEnum;
//   meta: WrappedFieldMetaProps;
//   input: WrappedFieldInputProps;
//   tagRegExpression?: RegExp;
//   resolverArgs?: string[];
//   searching?: boolean;
//   resolver: (params: HttpParamsType<PaginationQuery>, ...args: string[]) => Promise<LazyLoadResponse<SelectItemData<string>[]>>;
//   onSelect(): void;
// }

export function SelectResolver(props) {
  const inputs = props.input.value;
  props.mode === SelectModeEnum.Multiple
    ? (props.input.value || []).map(element => ({ key: element.value, label: <OptionItem avatarData={props.avatarData} showImage={props.showImage} item={element} /> }))
    : props.mode === SelectModeEnum.Tags
    ? (props.input.value || []).map(element =>
        typeof element === 'string'
          ? { key: element, label: element }
          : { key: element.value, label: <OptionItem avatarData={props.avatarData} showImage={props.showImage} item={element} /> },
      )
    : props.input.value
    ? { key: props.input.value.value, label: <OptionItem avatarData={props.avatarData} showImage={props.showImage} item={props.input.value} /> }
    : undefined;
  const [selected, setSelected] = useState(undefined);
  const delayedQuery = useRef(debounce(() => loadMoreItems(), 500)).current;

  const [children, setChildren] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(initializeMetaQueries());

  function loadMoreItems() {
    props.resolver({ queryParams: mapPaginationQueriesToMetaQueriesData(meta) }, ...(props.resolverArgs || [])).then(data => {
      setChildren([...children, ...data.results]);
      setMeta(data._meta);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (inputs) {
      const selected = Array.isArray(inputs)
        ? inputs.map(element => ({ key: element.value, label: <OptionItem avatarData={props.avatarData} showImage={props.showImage} item={element} /> }))
        : { key: inputs.value, label: <OptionItem avatarData={props.avatarData} showImage={props.showImage} item={inputs} /> };
      setSelected(selected);
    }
  }, [inputs]);

  const handleChange = labeledValue => {
    if (props.mode === SelectModeEnum.Multiple) {
      const multipleItems = labeledValue.map(item => item.label.props.item);
      props.input.onChange(multipleItems);

      setSelected(multipleItems);
      return;
    }

    if (props.mode === SelectModeEnum.Tags) {
      let multipleItems = labeledValue
        .filter(item => typeof item.label === 'object' || (typeof item.label === 'string' && props.tagRegExpression.test(item.label)))
        .map(item => (typeof item.label === 'string' ? item.label : item.label.props.item));
      multipleItems = multipleItems.map(item => {
        return {
          ...item,
          key: item.value,
        };
      });
      props.input.onChange(multipleItems);
      console.log('multipleItems ', multipleItems);
      setSelected(multipleItems);
      return;
    }

    if (props.mode === SelectModeEnum.Default) {
      console.log('are you going here ? ', labeledValue.label.props.item);
      setSelected(labeledValue);
      props.input.onChange(labeledValue ? labeledValue.label.props.item : null);

      return;
    }
  };

  const handleSearch = _textSearch => {
    const newQueries = initializeMetaQueries();
    setLoading(true);
    setMeta(newQueries);
    delayedQuery();
  };

  const onDropdownVisibleChange = open => {
    setChildren([]);
    setLoading(false);
    setMeta(initializeMetaQueries());
    if (open) {
      setLoading(true);
      loadMoreItems();
    }
  };

  const onScroll = event => {
    const target = event.target;
    if (!loading && !meta.maxReached && target.scrollTop + target.offsetHeight >= target.scrollHeight) {
      setLoading(true);
      setMeta({ ...meta, currentPage: meta.currentPage++ });
      loadMoreItems();
    }
  };

  return (
    <div className='SharedSelectResolver'>
      <AntdSelect
        size='large'
        style={{ width: '100%' }}
        value={selected}
        mode={props.mode}
        showSearch={props.searching}
        placeholder={props.placeholder}
        onChange={handleChange}
        onPopupScroll={onScroll}
        loading={loading}
        allowClear={props.allowClear}
        disabled={props.disabled}
        onSelect={props.onSelect}
        onSearch={handleSearch}
        onDropdownVisibleChange={onDropdownVisibleChange}
        labelInValue={true}
        optionFilterProp='label'
        maxTagCount={5}
      >
        {children.map(opt => (
          <Option key={opt.value} value={opt.value} label={opt.name}>
            <OptionItem item={opt} showImage={props.showImage} avatarData={props.avatarData} />
          </Option>
        ))}
        {loading && (
          <Option value='disabled' disabled={true}>
            <div className='text-center'>
              <Spinner />
            </div>
          </Option>
        )}
      </AntdSelect>
      <Error touched={props.meta && props.meta.touched} error={props.meta && props.meta.error} warning={props.meta && props.meta.warning} />
    </div>
  );
}

SelectResolver.defaultProps = {
  placeholder: 'Select',
  allowClear: true,
  showImage: false,
  avatarData: { shape: AvatarShapeEnum.Square },
  disabled: false,
  value: [],
  mode: SelectModeEnum.Default,
  tagRegExpression: new RegExp('.'),
  onSelect: () => {},
};
