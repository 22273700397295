import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addEmailTemplate, updateEmailTemplate, discardEditingTemplate } from 'ReduxActions/settingActions';
import SubmitButton from 'SharedComponent/SubmitButton';
import AddEmailTemplate from '../AddEmailTemplate';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './EmailTemplatesHeader.scss';

const actions = {
  addEmailTemplate,
  discardEditingTemplate,
  updateEmailTemplate,
};
class EmailTemplatesHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.hideModal = this.hideModal.bind(this);
  }

  hideModal() {
    this.props.discardEditingTemplate();
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div className='EmailTemplatesHeader display-flex-between flex-wrap'>
        <div className='display-flex-start'>
          <div className='list-users-text mr-5'>Email templates</div>
        </div>
        {this.props.loading && (
          <div className='lds-ripple'>
            <div />
            <div />
          </div>
        )}
        <div className='display-flex-end flex-wrap'>
          <div
            className={classNames('ml-5 display-flex-center', this.props.isSelected ? 'cursor-pointer' : 'disable-button')}
            style={{ color: '#ff8181' }}
            onClick={() => {
              gaEvent(appCategories.settings_emailTemplates, appActions(`${appTypes.mass}_${appTypes.delete}`, appEvents.clicked).button);
              this.setState({
                type: 'delete',
                showDialog: true,
              });
            }}
          >
            <span className='icon-trash mr-2 icon-span-details' />
            <span className='icon-span-text'>Delete</span>
          </div>
          <div className='ml-5'>
            <SubmitButton
              label='New Template'
              icon='icon-plus'
              onClick={() => {
                gaEvent(appCategories.settings_emailTemplates, appActions(`${appTypes.add}`, appEvents.clicked).button);
                this.setState({ showModal: true });
              }}
            />
          </div>
        </div>
        <AddEmailTemplate
          showModal={this.state.showModal || this.props.editing ? true : false}
          onDismiss={this.hideModal}
          onSave={this.props.addEmailTemplate}
          onUpdate={this.props.updateEmailTemplate}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.settings.emailTemplates.loading,
    count: state.settings.emailTemplates.count,
    editing: state.settings.emailTemplates.editing,
  };
}

export default connect(mapStateToProps, actions)(EmailTemplatesHeader);
