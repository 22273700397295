import React from 'react';
import { notification } from 'antd';
import { browserHistory } from 'react-router';
import SubmitButton from 'SharedComponent/SubmitButton';

export const Notifier = ({ type, title, message, placement, redirect }) => {
  const notifType = type || 'open';
  const key = `open${Date.now()}`;
  const actionButton = (
    <SubmitButton
      label='Show details'
      style={{ width: '150px' }}
      onClick={() => {
        notification.close(key);
        browserHistory.push(redirect);
      }}
    />
  );

  notification[notifType]({
    message: title,
    description: message,
    placement: placement || 'bottomRight',
    btn: redirect ? actionButton : null,
    key,
  });
};

// type: open, success, warning, info, error
// placement: topLeft, topRight, bottomLeft, bottomRight
