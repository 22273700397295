import Fuse from 'fuse.js';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  loadUsers,
  onEdit,
  deleteUser,
  resendInvitation,
  blockUser,
  unblockUser,
  blockInvestor,
  investorResendInvitation,
  unblockInvestor,
  deleteInvestor,
  resendInvestor,
  resendAngelInvestor,
  loadAngelInvestors,
  deleteAngelInvestor,
  blockAngelInvestor,
  unblockAngelInvestor,
  angelInvestorResendInvitation,
  angelInvestorResendInvitations,
} from 'ReduxActions/settingActions';
import UserDialog from '../UserDialog';
import PdfGenerator from '../exportSoa/PdfGenerator';
import UsersTable from '../UsersTable/UsersTable.js';
import defaultImage from '../../../../assets/img/default.png';

import { canForClass, Permissible, Empty } from '../../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';

import './UserList.scss';
import get from 'lodash/get';

const actions = {
  loadUsers,
  onEdit,
  deleteUser,
  resendInvitation,
  unblockInvestor,
  investorResendInvitation,
  blockInvestor,
  blockUser,
  unblockUser,
  deleteInvestor,
  resendInvestor,
  deleteAngelInvestor,
  resendAngelInvestor,
  loadAngelInvestors,
  blockAngelInvestor,
  unblockAngelInvestor,
  angelInvestorResendInvitation,
  canForClass,
};

const fuseOptions = {
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name'],
};

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      exportSoa: false,
      investor: null,
      type: 'block',
      userId: null,
      sorting: {
        key: 'name',
        order: 'ASC',
      },
      selectAll: false,
    };

    this.updateTableSort = this.updateTableSort.bind(this);
    this.reloadDataList = this.reloadDataList.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }

  componentWillMount() {
    this.reloadDataList();
  }

  showDialog(userId, type) {
    this.setState({ userId, type, showDialog: true });
  }

  hideDialog() {
    this.setState({ userId: null, showDialog: false });
  }

  parseUsers(users) {
    const isUsers = this.props.selected === 'users';
    const isAngelInvestor = this.props.selected === 'angel-investors';
    const addInv = () => `${isUsers || isAngelInvestor ? 'profile' : 'invProfile'}`;
    return users.map(user => {
      return {
        id: user.id,
        name: user[addInv()] ? user[addInv()].fullname || user[addInv()].fullName || 'N/A' : 'N/A',
        status: user.status,
        logo: user[addInv()] && user[addInv()].image ? user[addInv()].image : defaultImage,
        email: user.email,
        updated_at: moment.unix(user.updated_at).format('ll'),
        created_at: moment.unix(user.created_at).format('ll'),
        role: user.roleName ? user.roleName.name : 'N/A',
        phone: user[addInv()] ? (user[addInv()].phone ? user[addInv()].phone : 'N/A') : 'N/A',
        lastLogin: user.last_login ? `${moment(user.last_login * 1000).format('L')} - ${moment(user.last_login * 1000).format('LT')}` : 'Not logged in yet',
      };
    });
  }

  showExport(investor) {
    this.setState({ investor, exportSoa: true });
  }

  filterInvestors(users) {
    const fuse = new Fuse(users, fuseOptions);
    return this.props.filterInvestors ? fuse.search(this.props.filterInvestors) : users;
  }

  render() {
    const { props } = this;

    const DeleteAngelInvestor = props.canForClass(PermissionTypeEnum.DeleteAngelInvestor);
    const DeleteInvestor = props.canForClass(PermissionTypeEnum.DeleteInvestor);
    const ResendInvestorInvitation = props.canForClass(PermissionTypeEnum.ResendInvestorInvitation);
    const ResendAngelInvestorInvitation = props.canForClass(PermissionTypeEnum.CreateAngelInvestor);
    const users = this.parseUsers(props.users);
    const isWealthInvestor = props.selected === 'investors';
    const isAngelInvestor = props.selected === 'angel-investors';
    const isUsers = props.selected === 'users';
    const currentPage = get(props, 'meta.currentPage', null);
    const pageCount = get(props, 'meta.pageCount', null);
    return (
      /*todo see with abdou about listUser */
      <div className={isAngelInvestor ? 'angel-table' : ''}>
        {isUsers && (
          <UsersTable
            isLoading={props.usersLoading}
            isUsers={isUsers}
            isWealthInvestor={isWealthInvestor}
            isAngelInvestor={isAngelInvestor}
            items={props.usersLoading ? [] : this.filterInvestors(users)}
            onSortChange={this.updateTableSort}
            onEdit={props.onEdit}
            onSelectAll={props.onSelectAll}
            onRowSelect={props.onRowSelect}
            page={currentPage}
            resendInvitation={user => this.showDialog(user, 'resend')}
            blockUser={user => this.showDialog(user, 'block')}
            deleteInvestor={user => this.showDialog(user, 'delete')}
            unblockUser={isUsers ? props.unblockUser : isWealthInvestor ? props.unblockInvestor : props.unblockAngelInvestor}
            isOwner={props.isOwner}
            userId={props.userId}
            pagination={isAngelInvestor}
            sizePerPage={isAngelInvestor ? 20 : undefined}
            totalDataSize={pageCount * 20}
            onPageChange={isAngelInvestor ? page => this.props.loadAngelInvestors(page) : undefined}
            key={isUsers}
            resendInvestor={isWealthInvestor ? props.resendInvestor : props.angelInvestorResendInvitation}
            exportSoa={investor => this.showExport(investor)}
            DeleteAngelInvestor={DeleteAngelInvestor}
            DeleteInvestor={DeleteInvestor}
            ResendInvestorInvitation={ResendInvestorInvitation}
            ResendAngelInvestorInvitation={ResendAngelInvestorInvitation}
          />
        )}
        {!isUsers && (
          <Permissible
            can={isAngelInvestor ? PermissionTypeEnum.ListAngelInvestors : PermissionTypeEnum.ListWealthInvestors}
            otherWise={<Empty description='You do not have permission to access this page, contact your administrator.' />}
          >
            <UsersTable
              isLoading={props.usersLoading}
              isUsers={isUsers}
              isWealthInvestor={isWealthInvestor}
              isAngelInvestor={isAngelInvestor}
              items={props.usersLoading ? [] : this.filterInvestors(users)}
              onSortChange={this.updateTableSort}
              onEdit={props.onEdit}
              onSelectAll={props.onSelectAll}
              onRowSelect={props.onRowSelect}
              page={currentPage}
              resendInvitation={user => this.showDialog(user, 'resend')}
              blockUser={user => this.showDialog(user, 'block')}
              deleteInvestor={user => this.showDialog(user, 'delete')}
              unblockUser={isUsers ? props.unblockUser : isWealthInvestor ? props.unblockInvestor : props.unblockAngelInvestor}
              isOwner={props.isOwner}
              userId={props.userId}
              pagination={isAngelInvestor}
              sizePerPage={isAngelInvestor ? 20 : undefined}
              totalDataSize={pageCount * 20}
              onPageChange={isAngelInvestor ? page => this.props.loadAngelInvestors(page) : undefined}
              key={isUsers}
              resendInvestor={isWealthInvestor ? props.resendInvestor : props.angelInvestorResendInvitation}
              exportSoa={investor => this.showExport(investor)}
              DeleteAngelInvestor={DeleteAngelInvestor}
              DeleteInvestor={DeleteInvestor}
              ResendInvestorInvitation={ResendInvestorInvitation}
              ResendAngelInvestorInvitation={ResendAngelInvestorInvitation}
            />
          </Permissible>
        )}
        <UserDialog
          type={this.state.type}
          showDetails={this.state.showDialog}
          onDismiss={this.hideDialog}
          user={this.state.userId}
          isUsers={isUsers}
          blockUser={isUsers ? props.blockUser : isWealthInvestor ? props.blockInvestor : props.blockAngelInvestor}
          deleteInvestor={isWealthInvestor ? props.deleteInvestor : props.deleteAngelInvestor}
          resend={isUsers ? props.resendInvitation : isWealthInvestor ? props.investorResendInvitation : props.angelInvestorResendInvitations}
        />
        {this.state.exportSoa && (
          <PdfGenerator
            distribution={[]}
            myCompany={this.props.myCompany}
            visible={this.state.exportSoa}
            onClose={() => this.setState({ investor: null, exportSoa: false })}
            investor={this.state.investor}
          />
        )}
      </div>
    );
  }

  reloadDataList() {
    this.props.loadUsers();
  }

  updateTableSort(sortName, sortOrder) {
    this.setState(
      {
        sorting: { key: sortName, order: sortOrder.toUpperCase() },
      },
      () => {
        this.reloadDataList();
      },
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.settings.users.all,
    meta: state.settings.users.meta,
    filterInvestors: state.settings.users.filterInvestors,
    usersLoading: state.settings.users.loading,
    isOwner: state.auth.user && state.auth.user.roleName && state.auth.user.roleName.role_id === 3,
    myCompany: state.auth.user && state.auth.user.company,
    userId: state.auth.user && state.auth.user.roleName && state.auth.user.id,
  };
}

export default connect(mapStateToProps, actions)(withRouter(UserList));
