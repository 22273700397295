import React from 'react';
import { moneyFormatter } from 'Utils/objectUtils';

const VC_SILVER_MONTHLY_ID = '589449';
const VC_SILVER_YEARLY_ID = '589533';
const VC_GOLD_MONTHLY_ID = '589451';
const VC_GOLD_YEARLY_ID = '589532';

export function companyDetails(product_id, users = 0) {
  switch (product_id) {
    case VC_SILVER_MONTHLY_ID:
      return {
        title: 'After adding a user you will be charged immediately',
        price: 32.49,
        frequency: 'monthly',
        message: (
          <div>
            Your monthly payment for <strong>{users + 1} users</strong> will be <strong>{moneyFormatter((users + 1) * 32.49)}</strong>
          </div>
        ),
      };
    case VC_SILVER_YEARLY_ID:
      return {
        title: 'After adding a user you will be charged immediately',
        price: 179.88,
        frequency: 'annual',
        message: (
          <div>
            Your Annual payment for <strong>{users + 1} users</strong> will be <strong>{moneyFormatter((users + 1) * 179.88)}</strong>
          </div>
        ),
      };
    case VC_GOLD_MONTHLY_ID:
      return {
        title: 'After adding a user you will be charged immediately',
        price: 32.49,
        frequency: 'monthly',
        message: (
          <div>
            Your monthly payment for <strong>{users + 1} users</strong> will be <strong>{moneyFormatter((users + 1) * 32.49)}</strong>
          </div>
        ),
      };
    case VC_GOLD_YEARLY_ID:
      return {
        title: 'After adding a user you will be charged immediately',
        price: 299.88,
        frequency: 'monthly',
        message: (
          <div>
            Your monthly payment for <strong>{users + 1} users</strong> will be <strong>{moneyFormatter((users + 1) * 299.88)}</strong>
          </div>
        ),
      };
    default:
      return {
        title: 'Monthly fee upon the end of trial',
        price: 32.49,
        frequency: 'monthly',
        message: (
          <div>
            Upon the end of trial your trial your monthly payment for{'  '}
            <strong>{users + 1} users</strong> will be <strong>{moneyFormatter((users + 1) * 32.49)}</strong>
          </div>
        ),
      };
  }
}
