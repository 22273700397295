import classNames from 'classnames';
import React, { Component } from 'react';
import Editor from '../../ReportingTool/Editor';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import './NavigationSidebar.scss';

let DragHandleIcon = SortableHandle(() => {
  return (
    <div className='remove-details'>
      <i className='material-icons remove-ic'>drag_indicator</i>
    </div>
  );
});
const SortablePagesContainer = SortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const SortableEditor = SortableElement(({ tools, shared, report, onChangePage, onRemovePage, idx }) => {
  return (
    <div key={idx + 1} onClick={() => onChangePage(idx + 1)} className={classNames(idx + 1 == tools.activePage && 'tab-border', 'm-2 editor-page nav-draggable')}>
      <DragHandleIcon />
      <div className='index-details'>
        <span className=''>Page: {idx + 1}</span>
      </div>
      <Editor
        objects={{ ...report, ...shared }}
        tools={{ ...tools, width: tools.width / 6, height: tools.height / 6 }}
        isNavigationSideBar
        loading={false}
        selected={null}
        dropElement={() => {}}
        makeElementActive={() => {}}
        addToScene={() => {}}
        loadTemplate={() => {}}
        loadTemplate={() => {}}
        updateEditorProps={() => {}}
        mode='view'
        id={`report_slides_${idx + 1}`}
        zoom={1 / 6}
        editorPageNumber={idx + 1}
      />
    </div>
  );
});
export default class NavigationSidebar extends Component {
  render() {
    const { tools, report, shared, onAddPage, onRemovePage, onChangePage } = this.props;
    return (
      <div className='NavigationSidebar'>
        <div className='mt-2'>
          <div>
            <SortablePagesContainer lockToContainerEdges lockAxis='y' useDragHandle onSortEnd={({ oldIndex, newIndex }) => this.props.onMovePage(oldIndex, newIndex)}>
              {report.map((report, idx) => {
                return <SortableEditor key={idx} idx={idx} index={idx} onRemovePage={onRemovePage} onChangePage={onChangePage} tools={tools} shared={shared} report={report} />;
              })}
            </SortablePagesContainer>
          </div>
          <div className='text-center'>
            <i className='material-icons plus-card' onClick={onAddPage}>
              add_circle_outline
            </i>
          </div>
        </div>
      </div>
    );
  }
}

// onAddPage={this.props.onAddPage}
//           onRemovePage={this.props.onRemovePage}
//           onChangePage={this.props.onChangePage}
