import { createSelector } from '@reduxjs/toolkit';

import { StateV2 } from '../../../../../store/v2.type';

import { selectAllTasks, selectAllUsers } from './task.reducer';

const taskState = (state: StateV2) => state.v2.dashboardTasks;

const getTaskState = createSelector(taskState, state => state.tasks);
const getZoomState = createSelector(taskState, state => state.zoom);

export const loadingTask = createSelector(taskState, state => state.loading);
export const editingTask = createSelector(taskState, state => state.editing);
export const submittingTask = createSelector(taskState, state => state.submitting);

export const loadingTasks = createSelector(getTaskState, state => state.loading);
export const getTaskMeta = createSelector(getTaskState, state => state.meta);
export const getAllTasks = createSelector(getTaskState, selectAllTasks);

// zoom selectors
export const loadingZoomUsers = createSelector(getZoomState, state => state.loading);
export const getUsersMeta = createSelector(getZoomState, state => state.meta);
export const getSubmitting = createSelector(getZoomState, state => state.submitting);
export const getAllUsers = createSelector(getZoomState, selectAllUsers);
