import moment from 'moment';
import React, { Component } from 'react';
import defaultImage from '../../../assets/img/default.png';
import './Activity.scss';

class Activity extends Component {
  render() {
    const { props } = this;
    return (
      <div className='Activity' style={this.props.noBorder ? { border: 'none' } : undefined}>
        <div className='activity-flex'>
          <img src={props.user && props.user.image ? props.user.image : defaultImage} style={{ width: '40px', height: '40px' }} alt='' />
          <div className='ml-2'>
            <div className='name-details'>{props.user ? `${props.user.fullname}` : 'N/A'}</div>
            <div className='message-details'>
              {props.message}{' '}
              {props.deal_id && (
                <span onClick={props.redirect} className='link-detail'>
                  {props.deal_name}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='date-details'>{props.createdAt}</div>
      </div>
    );
  }
}

export default Activity;
