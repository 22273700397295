import PropTypes from 'prop-types';
import React, { Component } from 'react';
import emptyActivity from '../../../assets/img/empty-activities.svg';
import BsPagination from '../../shared/BsPagination';
import Activity from '../../dashboard/Activity';
import { browserHistory } from 'react-router';
import Spinner from '../../shared/Spinner';
import './NotificationsTable.scss';
class NotificationsTable extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    onEdit: PropTypes.func,
    noDataText: PropTypes.string,
    pagination: PropTypes.bool,
  };

  static defaultProps = {
    noDataText: 'No notifications found',
    pagination: false,
  };

  constructor(props) {
    super(props);

    this.state = {};
    this.messageFormatter = this.messageFormatter.bind(this);
  }

  render() {
    const { props, state } = this;
    const { items } = props;
    return (
      <div className='NotificationsTable'>
        {props.isLoading ? <Spinner isLoading /> : items.length && !props.isLoading ? items.map(item => this.messageFormatter(item)) : this.emptyData()}
        {props.items.length && !props.isLoading ? (
          <div className='display-flex-center'>
            <BsPagination currentPage={props.tableOptions.currentPage} pageCount={props.tableOptions.pageCount} reload={props.onPageChange} />
          </div>
        ) : null}
      </div>
    );
  }

  messageFormatter(row) {
    return (
      <Activity
        key={row.notif_id}
        createdAt={
          this.props.timezone &&
          moment(row.created_at * 1000)
            .tz(this.props.timezone)
            .fromNow()
        }
        {...row}
        redirect={() => browserHistory.push(`/deals/${row.deal_id}`)}
      />
    );
  }

  emptyData() {
    return (
      <div className='m-4'>
        {this.props.loading ? (
          <div className='custom-spinner'>
            <div id='custom-spinner-id' />
          </div>
        ) : (
          <div className='display-flex-center flex-direction-column'>
            <img src={emptyActivity} />
            <div className='empty-Task'>Looks like there aren't any notifications.</div>
          </div>
        )}
      </div>
    );
  }
}

export default NotificationsTable;
