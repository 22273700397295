import moment from 'moment';

import { NoteResponseDTO } from '../../../generated/model/noteResponseDTO';
import { DealLightData } from '../../deal/deal-light-data';
import { FileLightData } from '../../file/file-light-data';
import { UserLightData } from '../../user/user-light-data';

export interface NoteSummaryData {
  noteId: string;
  content: string;
  createTime: string;
  createDate: string;
  deal: DealLightData;
  user: UserLightData;
  userMention: number[];
  files: FileLightData[];
}

export namespace NoteSummaryData {
  export function mapFromApi(note: NoteResponseDTO): NoteSummaryData {
    return {
      noteId: note.note_id.toString(),
      content: note.content.toString(),
      createTime: moment(note.created_at * 1000).format('h:mmA'),
      createDate: moment(note.created_at * 1000).format('dddd, '),
      deal: note.deal && DealLightData.mapFromApi(note.deal),
      user: note.user && UserLightData.mapFromApi(note.user),
      userMention: note.user_mention_list,
      files: (note.files || []).map(file => FileLightData.mapFromApi(file)),
    };
  }
}
