import { all, fork } from 'redux-saga/effects';

import { watchRoutes } from './routes/router.effects';
import { watchModal } from '../shared/components/Modal/state/modal.effects';

import { watchPipeline } from '../modules/pipeline/state/pipeline.effects';
import { watchKpis } from '../modules/dashboard/kpis/state/kpis.effects';
import { watchNews } from '../modules/dashboard/board/news/state/news.effects';
import { watchSummary } from '../modules/dashboard/board/summary-log/state/summary.effects';
import { watchDashboardTasks } from '../modules/dashboard/board/dashboard-tasks/state/task.effects';
import { watchReduxForm } from '../shared/components/Form/state/form.effects';
import { watchIntegration } from '../modules/settings/integrations/state/integration.effects';
import { watchTasks } from '../modules/tasks/state/tasks.effects';
import { watchPermissions } from '../modules/settings/permissions/state/permissions.effects';
import { watchMe } from './me/me.effects';

export function* rootSaga(): Generator {
  yield all([
    fork(watchRoutes),
    fork(watchModal),
    fork(watchPipeline),
    fork(watchKpis),
    fork(watchNews),
    fork(watchSummary),
    fork(watchDashboardTasks),
    fork(watchReduxForm),
    fork(watchIntegration),
    fork(watchTasks),
    fork(watchPermissions),
    fork(watchMe),
  ]);
}
