import { TaskDetailsData } from '../../models/__task/task-details-data';
import { IMap } from '../../utils/map-utils';

export enum TaskFormNamesEnum {
  Title = 1,
  DueDate = 2,
  Priority = 3,
  Description = 4,
  People = 5,
  Assignee = 6,
  RelatedDeal = 7,
  Type = 8,
  Files = 9,
  Zoom = 10,
}

export namespace TaskFormNamesEnum {
  export type formFieldName = keyof TaskDetailsData;

  export const toString: IMap<TaskFormNamesEnum, string> = new IMap<TaskFormNamesEnum, string>(
    [
      [TaskFormNamesEnum.Title, 'Title'],
      [TaskFormNamesEnum.DueDate, 'Due Date'],
      [TaskFormNamesEnum.Priority, 'priority'],
      [TaskFormNamesEnum.Description, 'Add notes'],
      [TaskFormNamesEnum.People, 'Invite people'],
      [TaskFormNamesEnum.Assignee, 'Assign to'],
      [TaskFormNamesEnum.RelatedDeal, 'Related deal'],
      [TaskFormNamesEnum.Type, 'Type'],
      [TaskFormNamesEnum.Files, 'Attach file'],
      [TaskFormNamesEnum.Zoom, 'Make it Zoom Call'],
    ],
    null,
  );

  export const formFields: IMap<TaskFormNamesEnum, formFieldName> = new IMap<TaskFormNamesEnum, formFieldName>([
    [TaskFormNamesEnum.Title, 'title'],
    [TaskFormNamesEnum.DueDate, 'dueDate'],
    [TaskFormNamesEnum.Priority, 'priority'],
    [TaskFormNamesEnum.Description, 'description'],
    [TaskFormNamesEnum.People, 'people'],
    [TaskFormNamesEnum.Assignee, 'assignee'],
    [TaskFormNamesEnum.RelatedDeal, 'deal'],
    [TaskFormNamesEnum.Type, 'type'],
    [TaskFormNamesEnum.Files, 'files'],
    [TaskFormNamesEnum.Zoom, 'zoom'],
  ]);
}
