import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import api from 'ReduxActions/api';
import { actions } from 'ReduxActions/authActions';
import { utilActions } from 'ReduxActions/utilActions';
import DialogBox from 'SharedComponent/DialogBox';
import TextField from 'SharedComponent/TextField';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';
import * as validators from 'Utils/validators';

import './RegisterModal.scss';

const asyncValidate = company_name => {
  return api.post('/register/company-name', { company_name: company_name.name }).then(({ data }) => {
    if (data.company_name_exist) {
      throw { name: 'Company already exists' };
    }
  });
};

class RegisterModal extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    this.props.loadActivities();
  }

  submit(values) {
    if (this.props.source === 1) {
      values = {
        name: values.username,
        company_name: values.name,
        activity_id: values.activity_id,
      };
      this.props.registerInfo(values);
    }
    if (this.props.source === 0) {
      values = {
        name: values.username,
        company_name: values.name,
        password: values.password,
        activity_id: values.activity_id,
      };
      this.props.registerInfo(values);
    }
  }

  render() {
    const { handleSubmit, submitting, pristine } = this.props;
    return (
      <div className='RegisterModal'>
        <DialogBox isVisible className='small-dialog' withoutClose={true}>
          <div className='header-txt ml-4'>WELCOME</div>
          <div className='ml-4 header-mesage'>Add your info to make collaborating easy</div>
          <form onSubmit={handleSubmit(this.submit)}>
            <div className='wrap-input'>
              <Field
                name={`activity_id`}
                component={TextField}
                component={FieldSelect}
                options={this.props.activities}
                validate={[validators.required]}
                placeholder='We are using PENTUGRAM for:'
                withImage={false}
                multi={false}
                labelKey='name'
                valueKey='activity_id'
              />
            </div>
            <div className='wrap-input'>
              <Field
                name={`username`}
                component={TextField}
                type='text'
                placeholder='Full name'
                withIcon={true}
                icon='icon-user'
                onChange={this.props.getUsernameValue}
                validate={[validators.required]}
              />
            </div>
            <div className='wrap-input'>
              <Field name={`name`} component={TextField} type='text' placeholder='Company' withIcon={true} icon='icon-home' validate={[validators.required]} />
            </div>
            {this.props.source === 0 ? (
              <div className='wrap-input'>
                <Field name={`password`} component={TextField} type='password' placeholder='Password' withIcon={true} icon='icon-lock' validate={[validators.required]} />
              </div>
            ) : null}
            <div className='login-form-btn-details'>
              <div className='container-login-form-btn'>
                <Button type='submit' className='login-form-btn' disabled={this.props.loading || pristine || submitting}>
                  {this.props.loading ? (
                    <div className='display-flex-center'>
                      <div className='pentugram-loader mr-2' />
                      Loading...
                    </div>
                  ) : (
                    'Start using Pentugram'
                  )}
                </Button>
              </div>
            </div>
          </form>
        </DialogBox>
      </div>
    );
  }
}

function mapStateToProps({ auth, routing, utils }) {
  return {
    routing,
    source: auth.user.register_source,
    loading: auth.loading,
    activities: utils.activities,
  };
}

RegisterModal = reduxForm({
  form: 'register-info',
  asyncValidate,
  asyncChangeFields: ['name'],
})(RegisterModal);

export default connect(mapStateToProps, { ...actions, ...utilActions })(RegisterModal);
