const VC_ACCELERATOR = 'VC_ACCELERATOR';
const WEALTH_MANAGEMENT = 'WEALTH_MANAGEMENT';
const SALES = 'SALES';

const IC_MEMBER = 'IC_MEMBER';
const OWNER = 'OWNER';
const ADMIN = 'ADMIN';
const USER = 'USER';

const BILLABLE_COMPANY = 'BILLABLE_COMPANY';
const NOT_BILLABLE_COMPANY = 'NOT_BILLABLE_COMPANY';
const CAN_INTEGRATE_ZOOM = 'CAN_INTEGRATE_ZOOM';
const CAN_INTEGRATE_DRIVE = 'CAN_INTEGRATE_DRIVE';
const CAN_INTEGRATE_CALENDAR = 'CAN_INTEGRATE_CALENDAR';
const CAN_ENABLE_ANGEL_INVESTOR = 'CAN_ENABLE_ANGEL_INVESTOR';
const ANGEL_NETWORK_ACTIVATED = 'ANGEL_NETWORK_ACTIVATED';

const STATUS_EXPIRED = 'STATUS_EXPIRED';
const STATUS_NOT_EXPIRED = 'STATUS_NOT_EXPIRED';
const STATUS_TRIALING = 'STATUS_TRIALING';
const STATUS_ACTIVE = 'STATUS_ACTIVE';
const STATUS_PAST_DUE = 'STATUS_PAST_DUE';
const STATUS_PAUSED = 'STATUS_PAUSED';
const STATUS_DELETED = 'STATUS_DELETED';
const STATUS_PENDING = 'STATUS_PENDING';

const PAYMENT_STATUS = {
  STATUS_EXPIRED,
  STATUS_NOT_EXPIRED,
  STATUS_TRIALING,
  STATUS_ACTIVE,
  STATUS_PAST_DUE,
  STATUS_PAUSED,
  STATUS_DELETED,
  STATUS_PENDING,
};

// const CAN_CREATE_DEALS = "CAN_CREATE_DEALS";
// const CAN_CREATE_REPORTS = "CAN_CREATE_REPORTS";
const CAN_NOT_CREATE_DEAL = 'CAN_NOT_CREATE_DEAL';
const CAN_NOT_CREATE_REPORT = 'CAN_NOT_CREATE_REPORT';

export const CATEGORIES = {
  1: VC_ACCELERATOR,
  5: WEALTH_MANAGEMENT,
  6: SALES,
};

export const ROLES = {
  4: IC_MEMBER,
  3: OWNER,
  2: ADMIN,
  1: USER,
};

const BILLABLE_STATUS = {
  BILLABLE_COMPANY,
  NOT_BILLABLE_COMPANY,
};

export {
  PAYMENT_STATUS,
  VC_ACCELERATOR,
  WEALTH_MANAGEMENT,
  SALES,
  IC_MEMBER,
  OWNER,
  ADMIN,
  USER,
  CAN_NOT_CREATE_DEAL,
  CAN_NOT_CREATE_REPORT,
  BILLABLE_STATUS,
  CAN_INTEGRATE_ZOOM,
  CAN_INTEGRATE_DRIVE,
  ANGEL_NETWORK_ACTIVATED,
  CAN_INTEGRATE_CALENDAR,
  CAN_ENABLE_ANGEL_INVESTOR,
};
