import { SelectItemData } from '../../../shared/interfaces/select-item';
import { ImportExcelDataRequestDTO } from '../../generated/model/models';

export interface IntegrationExcelData {
  pipeline: SelectItemData;
  stage: SelectItemData;
  file: File;
}

export namespace IntegrationExcelData {
  export function mapToApi(excel: IntegrationExcelData): ImportExcelDataRequestDTO {
    return {
      pipeline_id: Number(excel.pipeline.value),
      stage_id: Number(excel.stage.value),
      file: excel.file && excel.file[0],
    };
  }
}
