import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { ChromePicker } from 'react-color';

import './ButtonColorPicker.scss';

export default class ButtonColorPicker extends Component {
  state = {
    displayColorPicker: false,
    color: {
      r: '255',
      g: '0',
      b: '0',
      a: '1',
    },
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeComplete = color => {
    let rgba = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
    this.setState({ color: rgba });
    this.props.onColorChange(rgba);
  };

  render() {
    const { background } = this.props;
    const popover = {
      position: 'absolute',
      zIndex: '2',
    };
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    };
    return (
      <div className='ButtonColorPicker mb-2'>
        <div className='display-flex-between'>
          <div>{this.props.label}</div>
          <div className='picker-botton' onClick={this.handleClick}>
            <div className='picker-div' style={{ background: this.state.color }} />
          </div>
        </div>
        {this.state.displayColorPicker ? (
          <div style={popover}>
            <div style={cover} onClick={this.handleClose} />
            <ChromePicker color={this.state.color} onChangeComplete={this.handleChangeComplete} />
          </div>
        ) : null}
      </div>
    );
  }
}

export const FieldInputNumber = ({ onChange, label, unit, max, defaultValue, isPercentage }) => {
  let ParsePercentage = value => Math.round(Number(value) / 100);
  return (
    <div className='ButtonColorPicker mb-2'>
      <div className='display-flex-between'>
        <div>{label}</div>
        <div>
          <InputNumber
            defaultValue={defaultValue || 0}
            min={0}
            max={max || 20}
            formatter={value => `${value}${unit}`}
            parser={value => (isPercentage ? ParsePercentage(value.replace(`${unit}`, '')) : value.replace(`${unit}`, ''))}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};
