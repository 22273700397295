import { createAction } from '@reduxjs/toolkit';

import { DashboardZoomTaskForCreate } from '../../../../../core/models/dashboard-task/dashboard-zoom-task-for-create';
import { DashboardUpdateTaskStatus } from '../../../../../core/models/dashboard-task/dashboard-task-for-status';
import { ErrorResponseData } from '../../../../../core/models/http-params.type';
import { DashboardTaskDetailsData } from '../../../../../core/models/dashboard-task/dashboard-task-details-data';
import { MeetingInfoData } from '../../../../../core/models/meeting/meeting-info-data';
import { DashboardTaskForVote } from '../../../../../core/models/dashboard-task/dashboard-task-for-vote';
import { DashboardTaskSummaryData } from '../../../../../core/models/dashboard-task/dashboard-task-summary-data';

import { ITaskList, IUserList } from './types';

export const loadTaskList = createAction('[Dashboard/tasks] load task list');
export const loadTaskListNextPage = createAction('[Dashboard/tasks] load task next page');
export const loadTaskListSuccess = createAction<ITaskList>('[Dashboard/tasks] load task list success');
export const loadTaskListFail = createAction<ErrorResponseData>('[Dashboard/tasks] load task list fail');

export const loadTask = createAction<{ uuid: string }>('[Dashboard/tasks] load task');
export const loadTaskSuccess = createAction<{ task: DashboardTaskDetailsData }>('[Dashboard/tasks] load task success');
export const loadTaskFail = createAction<ErrorResponseData>('[Dashboard/tasks] load task fail');

export const createTask = createAction<{ task: DashboardTaskDetailsData }>('[Dashboard/tasks] create task');
export const createTaskSuccess = createAction('[Dashboard/tasks] create task success');
export const createTaskFail = createAction<ErrorResponseData>('[Dashboard/tasks] create task fail');

export const updateTask = createAction<{ task: DashboardTaskDetailsData }>('[Dashboard/tasks] update task');
export const updateTaskSuccess = createAction('[Dashboard/tasks] update task success');
export const updateTaskFail = createAction<ErrorResponseData>('[Dashboard/tasks] update task fail');

export const updateStatus = createAction<DashboardUpdateTaskStatus>('[Dashboard/tasks] update task status');
export const updateStatusSuccess = createAction<DashboardUpdateTaskStatus>('[Dashboard/tasks] update task status success');
export const updateStatusFail = createAction<ErrorResponseData>('[Dashboard/tasks] update task status fail');

export const resetTask = createAction('[Dashboard/tasks] reset task');

export const loadUserList = createAction<{ searchText: string }>('[Dashboard/tasks] load user list');
export const loadUserListNextPage = createAction('[Dashboard/tasks] load user next page');
export const loadUserListSuccess = createAction<IUserList>('[Dashboard/tasks] load user list success');
export const loadUserListFail = createAction<ErrorResponseData>('[Dashboard/tasks] load user list fail');

export const startZoom = createAction<DashboardZoomTaskForCreate>('[Dashboard/tasks] start zoom call');
export const startZoomSuccess = createAction<{ url: string }>('[Dashboard/tasks] start zoom call success');
export const startZoomFail = createAction<ErrorResponseData>('[Dashboard/tasks] start zoom call fail');

export const getZoomUrl = createAction<MeetingInfoData>('[Dashboard/tasks] get zoom url');
export const getZoomUrlSuccess = createAction<{ url: string }>('[Dashboard/tasks] get zoom url success');
export const getZoomUrlFail = createAction<ErrorResponseData>('[Dashboard/tasks] get zoom url fail');

export const voteForTask = createAction<DashboardTaskForVote>('[Dashboard/tasks] vote for task');
export const voteForTaskSuccess = createAction<DashboardTaskSummaryData>('[Dashboard/tasks] vote for task success');
export const voteForTaskFail = createAction<ErrorResponseData>('[Dashboard/tasks] vote for task fail');
