declare global {
  interface Window {
    // tslint:disable-next-line: no-any
    URL: any;
  }
}

import axios from 'axios';

import { ErrorResponseData } from '../../models/http-params.type';
import { HttpParamsType, RequestOptions } from '../../models/queries/http-params.type';
import { LazyLoadResponse } from '../../models/queries/lazy-response.model';
import { convertObjectToFormData } from '../../utils/object-to-form-data';

export const baseHttpService = axios.create({ baseURL: process.env.API_URL });

baseHttpService.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

    return config;
  },
  error => Promise.reject(error),
);

baseHttpService.interceptors.response.use(
  response => response,
  error => Promise.reject(error && error.response && error.response.data),
);

async function getBlob(url: string, paramsData?: HttpParamsType, fileName?: string): Promise<void> {
  try {
    const { data } = await baseHttpService.get(url, { params: paramsData && paramsData.queryParams, responseType: 'arraybuffer' });
    const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const a = document.createElement('a');
    const fileURL = window.URL.createObjectURL(file);
    a.href = fileURL;
    a.download = fileName || `template.xlsx`;
    a.click();
  } catch (error) {
    throw ErrorResponseData.mapFromApi(error);
  }
}

async function get<T>(url: string, paramsData?: HttpParamsType): Promise<T> {
  try {
    const { data } = await baseHttpService.get<T>(url, { params: paramsData && paramsData.queryParams });

    return data;
  } catch (error) {
    throw ErrorResponseData.mapFromApi(error);
  }
}

async function _delete<T>(url: string, paramsData?: HttpParamsType): Promise<T> {
  try {
    const { data } = await baseHttpService.delete(url, { params: paramsData && paramsData.queryParams });

    return data;
  } catch (error) {
    throw ErrorResponseData.mapFromApi(error);
  }
}

async function post<T>(url: string, paramsData?: HttpParamsType): Promise<T> {
  const options: RequestOptions = {
    params: paramsData && paramsData.queryParams,
    body: paramsData && paramsData.body,
  };

  try {
    const { data } = await baseHttpService.post<T>(url, options.body, { params: options && options.params });

    return data;
  } catch (error) {
    throw ErrorResponseData.mapFromApi(error);
  }
}
async function put<T>(url: string, paramsData?: HttpParamsType): Promise<T> {
  const options: RequestOptions = {
    params: paramsData && paramsData.queryParams,
    body: paramsData && paramsData.body,
  };

  try {
    const { data } = await baseHttpService.put<T>(url, options.body, { params: options && options.params });

    return data;
  } catch (error) {
    throw ErrorResponseData.mapFromApi(error);
  }
}

async function formData<T>(url: string, paramsData?: HttpParamsType): Promise<T> {
  try {
    const dataForm: FormData = convertObjectToFormData(paramsData.body);

    const { data } = await baseHttpService.post<T>(url, dataForm, {
      params: paramsData && paramsData.queryParams,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
  } catch (error) {
    throw ErrorResponseData.mapFromApi(error);
  }
}

async function lazyLoad<T>(url: string, paramsData?: HttpParamsType): Promise<LazyLoadResponse<T>> {
  const options: RequestOptions = {
    params: paramsData && paramsData.queryParams,
    body: paramsData && paramsData.body,
  };

  try {
    const { data } = await baseHttpService.get<LazyLoadResponse<T>>(url, options);

    return {
      results: data.results,
      _meta: { ...data._meta, maxReached: !!(data._meta.currentPage >= data._meta.pageCount) },
    };
  } catch (error) {
    throw ErrorResponseData.mapFromApi(error);
  }
}
async function lazyLoadPost<T>(url: string, paramsData?: HttpParamsType): Promise<LazyLoadResponse<T>> {
  const options: RequestOptions = {
    params: paramsData && paramsData.queryParams,
    body: paramsData && paramsData.body,
  };

  try {
    const { data } = await baseHttpService.post<LazyLoadResponse<T>>(url, options.body, { params: options && options.params });

    return {
      results: data.results,
      _meta: { ...data._meta, maxReached: !!(data._meta.currentPage >= data._meta.pageCount) },
    };
  } catch (error) {
    throw ErrorResponseData.mapFromApi(error);
  }
}

export { lazyLoad, get, getBlob, post, formData, lazyLoadPost, _delete, put };
