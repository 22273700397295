import React, { useState, useEffect } from 'react';
import { Modal, Switch, Tag, Radio } from 'antd';
import { PlanItem } from './PlanItem';
import orderBy from 'lodash/orderBy';
import Spinner from 'SharedComponent/Spinner';
import { at, moneyFormatter } from 'Utils/objectUtils';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './UpgradeModal.scss';
import { Mixpanel } from '../../../../app';

export default function UpgradeModal(props) {
  const [checked, setChecked] = useState(false); // default month
  const [defaultValue, setdefaultValue] = useState(undefined); // default month
  console.log('props.allPacks', props.allPacks, ' renderPacks() ', renderPacks());
  useEffect(() => {
    setdefaultValue(renderPacks().find(it => it.default) && renderPacks().find(it => it.default).id);
  }, [props.allPacks, checked]);

  function onChange(value) {
    setChecked(value);
    gaEvent(appCategories.settings_billing, appActions('MONTHLY_YEARLY', appEvents.clicked)['switch']);
  }

  function groupChanged(e) {
    setdefaultValue(e.target.value);
  }

  function filterPacks(packs = []) {
    return packs.map(it => {
      const price = it.prices.filter(it => it.billing_cycle.interval == (checked ? 'year' : 'month'));
      return price[0];
    });
  }

  function orderPacks(packs = [], order = 'asc') {
    return orderBy(packs, ['order'], order);
  }

  function renderPacks() {
    if (checked) return orderPacks(filterPacks(props.allPacks));
    return orderPacks(filterPacks(props.allPacks));
  }

  function renderInfo() {
    const unit =
      at(
        renderPacks().find(it => it.id == defaultValue),
        'recurring_price.USD',
      ) || 0;
    return (
      <div className='display-flex-end mt-2 font-weight-600 font-size-16'>
        <span>
          Estimated total after the trial ends ({props.total_users} seats) {moneyFormatter(Number(unit) * props.total_users)}
        </span>
        <span className='material-icons'>contact_support</span>
      </div>
    );
  }

  return (
    <div className='UpgradeModal'>
      <Modal
        visible={props.isVisible}
        wrapClassName='UpgradeModal'
        style={{ top: 20 }}
        bodyStyle={{ padding: 32, backgroundColor: '#f8f8f8' }}
        width={900}
        footer={null}
        closable={false}
        onCancel={props.onClose}
      >
        <div className='modal-title'> Find the plan that fits you! </div>
        <div className='mt-3 mb-3 text-center'>
          <span className='billed-frequency mr-2' style={{ color: checked ? '#b9babb' : '#1890ff' }} onClick={() => onChange(false)}>
            Billed monthly
          </span>
          <Switch className='switch-background-color' checked={checked} checkedChildren='Yearly' unCheckedChildren='Monthly' onChange={onChange} />
          <span className='billed-frequency ml-2' style={{ color: !checked ? '#b9babb' : '#1890ff' }} onClick={e => onChange(true)}>
            Billed annually <Tag color={!checked ? '#b9babb' : '#1890ff'}>Save 20%</Tag>
          </span>
        </div>
        <Spinner isLoading={!!!props.allPacks.length} />
        <div className='row justify-content-md-center'>
          <Radio.Group value={defaultValue} onChange={groupChanged}>
            {renderPacks().map(plan => (
              <Radio.Button key={plan.id} value={plan.priceId}>
                <PlanItem
                  id={plan.id}
                  dealLimitMessage={plan.custom_data.dealLimitMessage}
                  reportLimitMessage={plan.custom_data.reportLimitMessage}
                  title={plan.name}
                  price={`$${plan.unit_price.amount / 100}`}
                  duration={` Per seat per ${checked ? 'year' : 'month'}`}
                  features={['Fully responsive', 'SEO optimized', 'Social media Audit', 'Monthly report']}
                  colors={JSON.parse(plan.custom_data.colors)}
                  onSubscribe={() => {
                    props.onSubscribe(plan.id);
                    gaEvent(appCategories.settings_billing, appActions('SUBSCRIBE_MONTHLY_YEARLY_PLAN', appEvents.clicked)['button']);
                    Mixpanel.track(plan.name, {
                      package: plan.name,
                      packagePeriod: checked ? 'year' : 'month',
                    });
                  }}
                />
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
        {/* {renderInfo()} */}
      </Modal>
    </div>
  );
}
