import { Switch } from 'antd';
import React from 'react';

export default function SwitchField(props) {
  return (
    <div className='display-flex-start'>
      <Switch defaultChecked={props.defaultChecked} checked={props.input.value} disabled={props.disabled} onChange={props.input.onChange} className={props.className} />
      <span className={props.labelClass}>{props.label}</span>
    </div>
  );
}
