import { post, getBlob, formData } from '../services/helpers/base-http';
import { apiUrlMatcher } from '../helpers/api-url-matcher';
import { ApiUrlsEnum } from '../helpers/api-url';

import { MigratePipedriveDataRequestDTO } from '../generated/model/migratePipedriveDataRequestDTO';
import { SlackIntegrationRequestDTO } from '../generated/model/slackIntegrationRequestDTO';
import { CreateZoomAccountByAuthCodeRequestDTO } from '../generated/model/createZoomAccountByAuthCodeRequestDTO';
import { CreateGoogleCalendarAccountRequestDTO } from '../generated/model/createGoogleCalendarAccountRequestDTO';
import { HttpParamsType } from '../models/queries/http-params.type';
import { ImportExcelDataRequestDTO } from '../generated/model/importExcelDataRequestDTO';

export function connectPipedrive(value: MigratePipedriveDataRequestDTO): Promise<void> {
  return post(apiUrlMatcher(ApiUrlsEnum.ConnectPipedrive), { body: value }).then();
}

export function connectSlack(value: SlackIntegrationRequestDTO): Promise<void> {
  return post(apiUrlMatcher(ApiUrlsEnum.ConnectSlack), { body: value }).then();
}

export function connectGoogleCalendar(value: CreateGoogleCalendarAccountRequestDTO): Promise<void> {
  return post(apiUrlMatcher(ApiUrlsEnum.ConnectGoogleCalendar), { body: value }).then();
}

export function connectZoom(value: CreateZoomAccountByAuthCodeRequestDTO): Promise<void> {
  return post(apiUrlMatcher(ApiUrlsEnum.ConnectZoom), { body: value }).then();
}

export async function downloadTemplate(isPortfolio: boolean): Promise<void> {
  await getBlob(apiUrlMatcher(ApiUrlsEnum.DownloadTemplate), { queryParams: { isPortfolio: isPortfolio } }, `${isPortfolio ? 'Portfolio' : 'Pipeline'}`);
}

export async function uploadExcelTemplate(params: HttpParamsType<ImportExcelDataRequestDTO>): Promise<boolean> {
  await formData<ImportExcelDataRequestDTO>(apiUrlMatcher(ApiUrlsEnum.UploadExcelTemplate), params);

  return true;
}
