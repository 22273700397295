import { createSelector } from '@reduxjs/toolkit';

import { StateV2 } from '../../../../../store/v2.type';

import { selectAllActivity, selectAllEmails, selectAllFiles, selectAllNotes } from './summary.reducer';

const summaryState = (state: StateV2) => state.v2.summaryLog;

const getActivityState = createSelector(summaryState, state => state.activities);
const getNoteState = createSelector(summaryState, state => state.notes);
const getEmailState = createSelector(summaryState, state => state.emails);
const getFileState = createSelector(summaryState, state => state.files);

// selector of activity
export const activityLoading = createSelector(getActivityState, state => state.loading);
export const getActivityMeta = createSelector(getActivityState, state => state.meta);
export const getAllActivity = createSelector(getActivityState, selectAllActivity);

// selector of notes
export const notesLoading = createSelector(getNoteState, state => state.loading);
export const getNotesMeta = createSelector(getNoteState, state => state.meta);
export const getAllNotes = createSelector(getNoteState, selectAllNotes);
export const getNote = createSelector(summaryState, state => state.note);

// selector of emails
export const emailsLoading = createSelector(getEmailState, state => state.loading);
export const getEmailsMeta = createSelector(getEmailState, state => state.meta);
export const getAllEmails = createSelector(getEmailState, selectAllEmails);

// selector of file
export const filesLoading = createSelector(getFileState, state => state.loading);
export const getFilesMeta = createSelector(getFileState, state => state.meta);
export const getAllFiles = createSelector(getFileState, selectAllFiles);
