export const TOGGLE_MENU = 'header:toggle:menu';
export const PAGE_CHANGED = 'header:label:page:changed';

export function collapseMenu(toggle) {
  return dispatch => {
    dispatch({
      type: TOGGLE_MENU,
      payload: toggle,
    });
  };
}

export function changePageLabel(label) {
  return dispatch => {
    dispatch({
      type: PAGE_CHANGED,
      payload: label,
    });
  };
}
