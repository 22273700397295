import { MetaQueryDTO } from '../../generated/MetaQueryDTO';
import { Params } from './http-params.type';

export const defaultPageSize = 20;

export interface MetaQuery {
  currentPage: number;
  perPage: number;
  pageCount?: number;
  totalCount?: number;
  maxReached?: boolean;
}

export function mapMetaQueriesDTOToMetaQueriesData(_meta: MetaQueryDTO): MetaQuery {
  return {
    currentPage: _meta.currentPage,
    pageCount: _meta.pageCount,
    perPage: defaultPageSize,
    totalCount: _meta.totalCount,
    maxReached: !!(_meta.currentPage >= _meta.pageCount),
  };
}

export function mapPaginationQueriesToMetaQueriesData(meta: MetaQuery): Params {
  const newMeta = {};

  newMeta['page'] = meta.currentPage;
  newMeta['per-page'] = meta.perPage;

  return newMeta;
}

export function initializeMetaQueries(page: number = 1): MetaQuery {
  return {
    currentPage: page,
    perPage: defaultPageSize,
  };
}
