/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CurrencyDTO } from './currencyDTO';
import { UserProfileDTO } from './userProfileDTO';

export interface PipelineDealsGridViewResponseDTO {
  deal_id?: number;
  company_id?: number;
  pipeline_id?: number;
  stage_id?: number;
  name?: string;
  image?: string;
  size?: number;
  source?: PipelineDealsGridViewResponseDTO.SourceEnum;
  currency?: CurrencyDTO;
  status?: PipelineDealsGridViewResponseDTO.StatusEnum;
  created_at?: number;
  assigneeProfile?: UserProfileDTO;
}
export namespace PipelineDealsGridViewResponseDTO {
  export type SourceEnum = 1 | 2 | 3 | 4;
  export const SourceEnum = {
    NUMBER_1: 1 as SourceEnum,
    NUMBER_2: 2 as SourceEnum,
    NUMBER_3: 3 as SourceEnum,
    NUMBER_4: 4 as SourceEnum,
  };
  export type StatusEnum = 1 | 2 | 3 | 4;
  export const StatusEnum = {
    NUMBER_1: 1 as StatusEnum,
    NUMBER_2: 2 as StatusEnum,
    NUMBER_3: 3 as StatusEnum,
    NUMBER_4: 4 as StatusEnum,
  };
}
