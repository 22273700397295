import React, { useEffect, useState } from 'react';
import Script from 'react-load-script';

export default function HocBilling(props) {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const handleScriptCreate = e => {
    setScriptLoaded(false);
  };

  const handleScriptLoad = e => {
    setScriptLoaded(true);
    Paddle.Setup({ vendor: Number(process.env.PADDLE_VENDOR_ID) });
  };

  const handleScriptError = e => {
    setScriptLoaded(false);
  };

  return (
    <div className='HocBilling'>
      <Script url={process.env.PADDLE_CDN} onCreate={handleScriptCreate} onLoad={handleScriptLoad} onError={handleScriptError} attributes={{ id: 'someId' }} />
    </div>
  );
}
