import { ApiUrlsEnum } from '../helpers/api-url';
import { get, post, formData, lazyLoad } from './helpers/base-http';
import { LazyLoadResponse } from '../models/queries/lazy-response.model';
import { apiUrlMatcher } from '../helpers/api-url-matcher';
import { HttpParamsType } from '../models/queries/http-params.type';
import { PaginationQuery } from '../models/queries/pagination-query.model';
import { TaskResponseDTO } from '../generated/model/taskResponseDTO';
import { DashboardTaskSummaryData } from '../models/dashboard-task/dashboard-task-summary-data';
import { DashboardTaskDetailsData } from '../models/dashboard-task/dashboard-task-details-data';
import { TaskCreateRequestDTO } from '../generated/model/taskCreateRequestDTO';
import { ZoomCreateMeetingTaskRequestDTO } from '../generated/model/zoomCreateMeetingTaskRequestDTO';
import { ZoomMeetingResponseDTO } from '../generated/model/zoomMeetingResponseDTO';
import { MeetingInfoData } from '../models/meeting/meeting-info-data';
import { DashboardTaskForVote } from '../models/dashboard-task/dashboard-task-for-vote';
import { TaskUpdateRequestDTO } from '../generated/model/taskUpdateRequestDTO';

export async function getTaskList(params: HttpParamsType<PaginationQuery>): Promise<LazyLoadResponse<DashboardTaskSummaryData[]>> {
  const data = await lazyLoad<TaskResponseDTO[]>(apiUrlMatcher(ApiUrlsEnum.DashboardTaskLazy), params);

  return {
    _meta: data._meta,
    results: data.results.map(task => DashboardTaskSummaryData.mapFromApi(task)),
  };
}

export async function getTaskByUuid(uuid: string): Promise<DashboardTaskDetailsData> {
  const data = await get<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.Task, { uuid }));

  return DashboardTaskDetailsData.mapFromApi(data);
}

export async function createTask(params: HttpParamsType<TaskCreateRequestDTO>): Promise<DashboardTaskDetailsData> {
  const data = await formData<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.Tasks), params);

  return DashboardTaskDetailsData.mapFromApi(data);
}

export async function updateTask(uuid: string, params: HttpParamsType<TaskUpdateRequestDTO>): Promise<DashboardTaskDetailsData> {
  const data = await formData<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.UpdateTask, { uuid }), params);

  return DashboardTaskDetailsData.mapFromApi(data);
}

export async function updateTaskStatus(uuid: string, status: TaskResponseDTO.StatusEnum): Promise<DashboardTaskSummaryData> {
  const data = await post<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.TaskStatus, { uuid }), { body: { status } });

  return DashboardTaskSummaryData.mapFromApi(data);
}

export async function createZoomCall(params: HttpParamsType<ZoomCreateMeetingTaskRequestDTO>): Promise<string> {
  const data = await post<ZoomMeetingResponseDTO>(apiUrlMatcher(ApiUrlsEnum.ZoomTask), params);

  return data.url;
}

export async function zoomUrl({ id, started }: MeetingInfoData): Promise<string> {
  const data = await post<ZoomMeetingResponseDTO>(apiUrlMatcher(ApiUrlsEnum.ZoomCallUrl, { id }), { body: { meeting_start: started } }); // fixme

  return data.url;
}

export async function votForTask(data: DashboardTaskForVote): Promise<DashboardTaskSummaryData> {
  const task = await post<TaskResponseDTO>(apiUrlMatcher(ApiUrlsEnum.VoteTask, { uuid: data.uuid }), { body: DashboardTaskForVote.mapToApiValue(data) });

  return DashboardTaskSummaryData.mapFromApi(task);
}
