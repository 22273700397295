export enum FormIdEnum {
  sharedForm = 'sharedForm',
  dashboardTask = 'dashboardTask',
  pipedriveForm = 'pipedriveForm',
  tasks = 'tasks',
  addTaskForm = 'addTask',
  integrationExcelForm = 'integrationExcelForm',
  PermissionsSettingsForm = 'settingsPermissionsForm',
  createPermissionForm = 'createPermissionForm',
}
