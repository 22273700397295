/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DealCustomFieldDTO {
  field_id?: number;
  value?: string;
  deal_id?: number;
  show?: boolean;
  required?: boolean;
  field_name?: string;
  field_type?: DealCustomFieldDTO.FieldTypeEnum;
}
export namespace DealCustomFieldDTO {
  export type FieldTypeEnum = 'text' | 'number' | 'list' | 'percentage' | 'date' | 'year' | 'paragraph' | 'countries' | 'monetary' | 'users';
  export const FieldTypeEnum = {
    Text: 'text' as FieldTypeEnum,
    Number: 'number' as FieldTypeEnum,
    List: 'list' as FieldTypeEnum,
    Percentage: 'percentage' as FieldTypeEnum,
    Date: 'date' as FieldTypeEnum,
    Year: 'year' as FieldTypeEnum,
    Paragraph: 'paragraph' as FieldTypeEnum,
    Countries: 'countries' as FieldTypeEnum,
    Monetary: 'monetary' as FieldTypeEnum,
    Users: 'users' as FieldTypeEnum,
  };
}
