import remove from 'lodash/remove';
import {
  LOADED_CONTACTS,
  LOADING_CONTACTS,
  CONTACT_DELETED,
  LOADING_CONTACTS_FAILED,
  CONTACT_CREATED,
  EDIT_CONTACT,
  MODAL_DISMISS,
  CONTACT_UPDATED,
  CONTACTS_BY_DEAL,
  LOAD_CONTACT,
  UPDATED,
  CONTACT_DEAL_CREATED,
  UNLINK_DEAL_CONTACT,
  SUGGESTIONS_BY_DEAL,
  LOADING_SUGGESTIONS,
  ACCEPT_DEAL_SUGGESTION,
  REFUSE_DEAL_SUGGESTION,
  ADD_SUGGESTIONS_PUSHER,
} from 'ReduxActions/contactActions';
import { replace } from 'Utils/arrayUtils.js';

const initialState = {
  all: [],
  meta: null,
  loading: false,
  editing: null,
  result: null,
  byDeal: [],
  suggestions: [],
  suggestionsLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_CONTACTS:
      return {
        ...state,
        loading: true,
      };

    case LOADED_CONTACTS:
      return {
        ...state,
        all: action.payload.results,
        meta: action.payload._meta,
        loading: false,
      };

    case LOADING_SUGGESTIONS:
      return {
        ...state,
        suggestionsLoading: true,
      };

    case SUGGESTIONS_BY_DEAL:
      return {
        ...state,
        suggestions: action.payload,
        suggestionsLoading: false,
      };

    case ADD_SUGGESTIONS_PUSHER:
      return {
        ...state,
        suggestions: [action.payload, ...state.suggestions],
      };

    case ACCEPT_DEAL_SUGGESTION:
      const acceptSugg = [...state.suggestions];
      remove(acceptSugg, { contact_id: action.payload.contact_id });
      return {
        ...state,
        suggestions: acceptSugg,
        byDeal: [action.payload, ...state.byDeal],
      };

    case REFUSE_DEAL_SUGGESTION:
      const refuseSugg = [...state.suggestions];
      remove(refuseSugg, { contact_id: action.payload.contact_id });
      return {
        ...state,
        suggestions: refuseSugg,
      };

    case LOAD_CONTACT:
      return {
        ...state,
        result: action.payload,
        loading: false,
      };

    case UPDATED:
      return {
        ...state,
        result: action.payload,
      };

    case LOADING_CONTACTS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case CONTACT_DELETED:
      const contacts = [...state.all];
      remove(contacts, { contact_id: action.payload.contact_id });
      return {
        ...state,
        all: contacts,
      };

    case CONTACT_CREATED:
      return {
        ...state,
        all: [...state.all, action.payload],
      };

    case EDIT_CONTACT:
      return {
        ...state,
        editing: action.payload,
      };

    case MODAL_DISMISS:
      return {
        ...state,
        editing: null,
      };

    case CONTACT_UPDATED:
      return {
        ...state,
        all: replace(state.all, { contact_id: action.payload.contact_id }, action.payload),
      };

    case CONTACTS_BY_DEAL:
      return {
        ...state,
        byDeal: action.payload,
        loading: false,
      };

    case CONTACT_DEAL_CREATED:
      return {
        ...state,
        byDeal: [action.payload, ...state.byDeal],
      };

    case UNLINK_DEAL_CONTACT:
      const unlink = [...state.byDeal];
      remove(unlink, { contact_id: action.payload.contact_id });
      return {
        ...state,
        byDeal: unlink,
      };
  }

  return state;
}
