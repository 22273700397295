import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import * as validators from 'Utils/validators';
import { CheckBoxField } from 'SharedComponent/CheckBox';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import domtoimage from 'Utils/domtoimage.js';

import './ActionsModal.scss';

class ActionsModal extends Component {
  constructor(props) {
    super(props);

    this.getReportImage = this.getReportImage.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  getReportImage(values) {
    const node = document.getElementById('report_page_1');
    domtoimage
      .toPng(node)
      .then(dataUrl => {
        this.handleUpload(dataUrl, values);
      })
      .catch(e => {
        this.props.saveReport({ image: null, ...values });
      });
  }

  handleUpload(file, values) {
    let url = process.env.CLOUDINARY_URL_UPLOAD;
    let xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    xhr.onreadystatechange = e => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        let response = JSON.parse(xhr.responseText);
        let url = response.secure_url;

        this.props.saveReport({ image: url, ...values });
      }
    };

    xhr.onerror = e => {
      this.props.saveReport({ image: null, ...values });
    };

    if (!file) {
      this.props.saveReport({ image: null, ...values });
      return;
    }

    let data = new FormData();
    data.append('file', file);
    data.append('upload_preset', process.env.CLOUDINARY_UPLOAD_PRESET);
    data.append('tags', 'browser_upload');
    xhr.send(data);
  }

  onSubmit(values) {
    this.props.onChangePage(1);
    this.props.onSaving(true);
    if (this.props.editor_prop.type == 'template') {
      this.getReportImage(values);
    } else {
      if (values.save_as_template) {
        this.getReportImage(values);
      } else {
        this.props.saveReport(values);
      }
    }
    this.props.reset();
    this.props.onHide();
  }

  render() {
    if (!this.props.show) return null;
    const { editor_prop } = this.props;
    return (
      <div className='ActionsModalReport'>
        <DialogBox isVisible onClickOut={this.props.onHide} onClose={this.props.onHide} withoutClose={true} className='small-dialog'>
          <div className='header-dialog'>{editor_prop.type == 'report' ? 'SAVE REPORT' : 'UPDATE TEMPLATE'}</div>
          <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
            <div className='modal-body'>
              <Field name='title' component={TextInput} maxLength='255' validate={[validators.required]} placeholder='Title' styles={{ fontSize: '18px' }} />
              {/* <div className="mt-3">
                <Field
                  name="description"
                  component={TextInput}
                  maxLength="255"
                  placeholder="Description"
                  styles={{ fontSize: "14px" }}
                />
              </div> */}
              {editor_prop.type == 'report' && (
                <div className='mt-3 display-flex-start'>
                  <Field name='save_as_template' component={CheckBoxField} />
                  <div className='ml-2'>Save as template ?</div>
                </div>
              )}
            </div>
            <hr className='hr-details' />
            <div className='display-flex-start'>
              <div className='m-3'>
                <SubmitButton
                  label='Cancel'
                  icon='icon-close'
                  style={{
                    width: '150px',
                    color: '#53627c',
                    backgroundColor: '#fff',
                    border: '1px solid #e1e4e8',
                  }}
                  onClick={() => this.props.onHide()}
                />
              </div>
              <Button type='submit' className='submit-btn-p'>
                <i className='icon-check mr-2' />
                {editor_prop.mode == 'edit' ? 'Update' : 'Save'}
              </Button>
            </div>
          </form>
        </DialogBox>
      </div>
    );
  }
}

ActionsModal = reduxForm({
  form: 'save-report-modal-form',
  enableReinitialize: true,
})(ActionsModal);

export default ActionsModal;

const TextInput = props => {
  const touched = props.meta && props.meta.touched;
  const error = props.meta && props.meta.error;
  return (
    <div>
      <input
        {...props.input}
        className={touched && error ? 'template-input-error' : 'template-input'}
        maxLength={props.maxLength}
        type='text'
        placeholder={props.placeholder}
        style={props.styles}
        disabled={props.disabled}
        onChange={({ target: { value } }) => {
          props.input.onChange(value);
          props.onChangeValue && props.onChangeValue(value);
        }}
      />
      <br />
    </div>
  );
};
