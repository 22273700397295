import { createAction } from '@reduxjs/toolkit';

import { MetaQuery } from '../../../../../core/models/queries/meta-query.model';
import { ErrorResponseData } from '../../../../../core/models/http-params.type';
import { ActivitySummaryData } from '../../../../../core/models/summary-log/activity/activity-summary-data';
import { NoteSummaryData } from '../../../../../core/models/summary-log/note/note-summary-data';
import { EmailSummaryData } from '../../../../../core/models/summary-log/email/email-summary-data';
import { FileSummaryData } from '../../../../../core/models/summary-log/file/file-summary-data';

/* Activity action */
export const loadActivityList = createAction('[Activity/Api] load activity list');
export const loadingActivityList = createAction('[Activity/Api] loading activity list');
export const loadActivitySuccess = createAction<{ list: ActivitySummaryData[]; meta: MetaQuery; reset?: boolean }>('[Activity/Api] load activity list success');
export const loadActivityFail = createAction<ErrorResponseData>('[Activity/Api] Fail load activity list');
export const loadNextPageActivity = createAction('[Activity/Api] load Next page activity list');
export const resetStore = createAction('[summary/activity] reset store Activity');

/* Notes action */
export const loadNotesList = createAction('[Notes/Api] load Notes list');
export const loadingNotes = createAction('[Notes/Api] loading Notes list');
export const loadNotesSuccess = createAction<{ list: NoteSummaryData[]; meta: MetaQuery; reset?: boolean }>('[Notes/Api] load Notes list success');
export const loadNotesFail = createAction<ErrorResponseData>('[Notes/Api] Fail load Notes list');
export const loadNextPageNotes = createAction('[Notes/Api] load Next page Notes list');
export const resetStoreNotes = createAction('[summary/notes] reset store Notes');
export const getNoteById = createAction<{ uuid: string }>('[Notes/Api] load Note by Id');
export const getNoteSuccess = createAction<{ note: NoteSummaryData }>('[Notes/Api] load Note by Id success');

/* email action */
export const loadEmailsList = createAction('[Emails/Api] load Emails list');
export const loadingEmails = createAction('[Emails/Api] loading Emails list');
export const loadEmailsSuccess = createAction<{ list: EmailSummaryData[]; meta: MetaQuery; reset?: boolean }>('[Notes/Api] load Emails list success');
export const loadEmailsFail = createAction<ErrorResponseData>('[Emails/Api] Fail load Emails list');
export const loadNextPageEmails = createAction('[Emails/Api] load Next page Emails list');
export const resetStoreEmails = createAction('[summary/Emails] reset store Emails');

/* file action */
export const loadFilesList = createAction('[Files/Api] load Files list');
export const loadingFiles = createAction('[Files/Api] loading Files list');
export const loadFilesSuccess = createAction<{ list: FileSummaryData[]; meta: MetaQuery; reset?: boolean }>('[Files/Api] load Files list success');
export const loadFilesFail = createAction<ErrorResponseData>('[Files/Api] Fail load Files list');
export const loadNextPageFiles = createAction('[Files/Api] load Next page Files list');
export const resetStoreFiles = createAction('[summary/Files] reset store Files');
