import React, { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { Table as AntdTable } from 'antd';
import { ColumnProps, SorterResult } from 'antd/lib/table/interface';

import { ColumnTable } from '../../interfaces/table/column-table';
import { PaginationTable } from '../../interfaces/table/pagination-table';
import { IconPrefixEnum } from '../../interfaces/icon-data';

import { MetaQuery } from '../../../core/models/queries/meta-query.model';
import { InnerSortDirectionEnum } from '../../../core/enums/sort-direction.enum';
import { DrawerIdEnum } from '../../../core/enums/entity-ids/drawer-id.enum';
import { getFromLocalStorage, useStateWithLocalStorage } from '../../../core/utils/local-storage-utils';
import { LocalStorageKeyEnum } from '../../../core/enums/local-storage.enum';

import { DropdownTableActionsEnum } from '../../enums/dropdown-table-actions.enum';

import { Icon } from '../Icon/icon.component';
import { Dropdown } from '../Dropdown/dropdown.component';
import { Drawer } from '../Drawer/drawer.component';
import { Spinner } from '../Spinner/spinner.component';
import { Empty } from '../Empty/empty.component';

import { ArrangeColumns } from './arrange-columns/arrange-columns.component';
import { closeDrawer, setDrawerId } from '../Drawer/state/drawer.actions';

import './table.component.scss';

interface TableProps<R> {
  columns: ColumnTable<R>[];
  rowKey: keyof R;
  data: R[];
  meta: MetaQuery;
  arrangeColumnKey: LocalStorageKeyEnum;
  loading?: boolean;
  actions?: DropdownTableActionsEnum[];
  rowClassName?: string;
  onPageChange(page: number): void;
  onSortChange?(attribute: keyof R, direction: InnerSortDirectionEnum): void;
  onRowClicked?(row: R): void;
}

export function Table<TableData>(props: PropsWithChildren<TableProps<TableData>>): JSX.Element {
  const dispatch = useDispatch();
  const [localStorageColumns, setLocalStorageColumns] = useStateWithLocalStorage<(keyof TableData)[]>(
    props.arrangeColumnKey,
    getFromLocalStorage(
      props.arrangeColumnKey,
      props.columns.map(i => i.key),
    ),
  );

  const paginationMeta: PaginationTable = PaginationTable.mapMetaToPaginationTable(props.meta);
  // tslint:disable-next-line: no-any
  const onSortChange = (_: any, __: any, sorter: SorterResult<TableData>, ___: any) =>
    props.onSortChange(sorter.field as keyof TableData, InnerSortDirectionEnum.convertFromAntdParams.getValue(sorter.order));
  const onPageChange = (page: number) => props.onPageChange(page);

  const onItemSelected = (key: DropdownTableActionsEnum) => {
    switch (key) {
      case DropdownTableActionsEnum.Arrange:
        dispatch(setDrawerId({ drawerId: DrawerIdEnum.ArrangeTaskColumns }));
        break;

      default:
        break;
    }
  };

  const onArrange = (keys: (keyof TableData)[]) => {
    setLocalStorageColumns(keys);
    dispatch(closeDrawer());
  };

  function renderColumns(): ColumnProps<TableData>[] {
    if (props.actions.includes(DropdownTableActionsEnum.Arrange)) {
      return localStorageColumns.map(col => props.columns.find(o => o.key === col)) as ColumnProps<TableData>[];
    } else {
      return props.columns as ColumnProps<TableData>[];
    }
  }

  const onRowClicked = (record: TableData) => {
    return {
      onClick: () => props.onRowClicked(record),
    };
  };

  const getRowClassName = () => props.rowClassName || 'shared-row-class-name';

  return (
    <div className='SharedTable'>
      {props.actions.length && (
        <Dropdown
          items={DropdownTableActionsEnum.actions(props.actions)}
          onSelectItem={onItemSelected}
          trigger={
            <div className='more-actions'>
              <Icon iconData={{ name: 'ri-more-2-fill', prefix: IconPrefixEnum.Remix }} />
            </div>
          }
        />
      )}
      <AntdTable
        rowKey={props.rowKey as string}
        columns={renderColumns()}
        dataSource={props.data}
        pagination={{ ...paginationMeta, onChange: onPageChange }}
        onChange={onSortChange} // see with @ahmed onchange and loading
        useFixedHeader={true}
        style={{ backgroundColor: 'white', height: '99%' }}
        bodyStyle={{ height: '1px', overflowY: 'auto' }}
        loading={{ spinning: props.loading, indicator: <Spinner /> }}
        tableLayout='fixed'
        className='shared-table'
        locale={{ emptyText: <Empty description='No data, create an item or review your filters' /> }}
        onRow={onRowClicked}
        rowClassName={getRowClassName}
      />
      <Drawer drawerId={DrawerIdEnum.ArrangeTaskColumns} title='Change column order'>
        <ArrangeColumns columns={props.columns as ColumnTable<TableData>[]} onSave={onArrange} arrangeColumnKey={props.arrangeColumnKey} />
      </Drawer>
    </div>
  );
}

Table.defaultProps = {
  actions: [] as DropdownTableActionsEnum[],
  onRowClicked(): void {},
};
