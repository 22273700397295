import React from 'react';

import { ModalIdEnum } from '../../../../../core/enums/entity-ids/modal-id.enum';
import { Modal } from '../../../../../shared/components';

import './calendar-modal.component.scss';

interface CalendarModalProps {
  onCalendarClick(): void;
}

export function CalendarModal(props: CalendarModalProps): JSX.Element {
  return (
    <Modal title='Google calendar integration' modalId={ModalIdEnum.googleCalendarIntegration} width='30rem'>
      <div className='google-calendar-container'>
        <div className='g-sign-in-button'>
          <div className='content-wrapper' onClick={props.onCalendarClick}>
            <div className='logo-wrapper'>
              <img src='https://developers.google.com/identity/images/g-logo.png' />
            </div>
            <span className='text-container'>
              <span>Sign in with Google</span>
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
}
