import remove from 'lodash/remove';
import { CHAT_TABS_ADDED, CHAT_TABS_CLOSED } from 'ReduxActions/chatActions';

const initialState = {
  tabs: [],
};

function addOrReplace(tab, array) {
  const tabs = [...array];
  const i = tabs.findIndex(_item => _item.id == tab.id);
  if (i == -1) {
    if (tabs.length >= 4) {
      tabs.splice(array.length - 1, 1, tab);
      return tabs;
    } else return [...tabs, tab];
  }
  return tabs;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CHAT_TABS_ADDED:
      return {
        ...state,
        tabs: [...addOrReplace(action.payload, state.tabs)],
      };

    case CHAT_TABS_CLOSED:
      const tabs = [...state.tabs];
      remove(tabs, { id: action.payload.id });
      return {
        ...state,
        tabs: tabs,
      };
  }

  return state;
}
