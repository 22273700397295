import { createSelector } from '@reduxjs/toolkit';
import { StateV2 } from '../v2.type';

const meState = (state: StateV2) => state.v2.me;

export const zoomAuthorized = createSelector(meState, state => state.user.isZoom);
export const isZoomAuthorized = createSelector(meState, state => state.user.isZoom); /* @ahmed see duplicate*/
export const slackState = createSelector(meState, state => state.user.slackState);
export const isCalendarAuthorized = createSelector(meState, state => state.user.isGoogleCalendar);
export const isImportingExcel = createSelector(meState, state => !!state.user.company.importingData);
export const pipedriveMigrated = createSelector(meState, state => state.user.company.isPipeDrive);

const allowedPages = createSelector(meState, state => state.user.pages);
const allowedActions = createSelector(meState, state => state.user.actions);

export const allowedPermissions = createSelector(allowedPages, allowedActions, (pages, actions) => ({ pages, actions }));
