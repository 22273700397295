import React, { Component } from 'react';

import './GeometricItem.scss';

export default class GeometricItem extends Component {
  render() {
    const { item } = this.props;
    return (
      <div className='GeometricItem'>
        <div
          className='css-element'
          style={{
            backgroundColor: item.geometric.backgroundColor,
            borderRadius: item.geometric.round ? '50%' : item.geometric.borderRadius || 0,
          }}
        />
      </div>
    );
  }
}
