import { IconData, IconPrefixEnum } from '../../../shared/interfaces/icon-data';
import { SelectItemData } from '../../../shared/interfaces/select-item';
import { IMap } from '../../utils/map-utils';

export enum PipelineViewTabsEnum {
  Grid = 'grid',
  List = 'list',
  Analytic = 'analytic',
}

export namespace PipelineViewTabsEnum {
  export const displayedValues: PipelineViewTabsEnum[] = [PipelineViewTabsEnum.Grid, PipelineViewTabsEnum.List, PipelineViewTabsEnum.Analytic];

  export const getIcon: IMap<PipelineViewTabsEnum, IconData> = new IMap<PipelineViewTabsEnum, IconData>(
    [
      [PipelineViewTabsEnum.Grid, { name: 'appstore', prefix: IconPrefixEnum.Antd }],
      [PipelineViewTabsEnum.List, { name: 'unordered-list', prefix: IconPrefixEnum.Antd }],
      [PipelineViewTabsEnum.Analytic, { name: 'pie-chart', prefix: IconPrefixEnum.Antd }],
    ],
    undefined,
  );

  export const getTooltip: IMap<PipelineViewTabsEnum, string> = new IMap<PipelineViewTabsEnum, string>(
    [
      [PipelineViewTabsEnum.Grid, 'Grid view'],
      [PipelineViewTabsEnum.List, 'List view'],
      [PipelineViewTabsEnum.Analytic, 'Analytic view'],
    ],
    undefined,
  );

  export const toString: IMap<PipelineViewTabsEnum, string> = new IMap<PipelineViewTabsEnum, string>(
    [
      [PipelineViewTabsEnum.Grid, 'Grid'],
      [PipelineViewTabsEnum.List, 'List'],
      [PipelineViewTabsEnum.Analytic, 'Analytic'],
    ],
    undefined,
  );
  export function mapToSelectItemData(type: PipelineViewTabsEnum): SelectItemData<PipelineViewTabsEnum> {
    return {
      value: type,
      name: toString.getValue(type),
      tooltip: getTooltip.getValue(type),
      icon: getIcon.getValue(type),
    };
  }

  export const selectItems = displayedValues.map(value => mapToSelectItemData(value));
}
