import { SlackIntegrationRequestDTO } from '../../../../generated/model/slackIntegrationRequestDTO';

export interface SlackIntegrationModel {
  code: string;
  state: string;
}

export namespace SlackIntegrationModel {
  export function mapToApi(value: SlackIntegrationModel): SlackIntegrationRequestDTO {
    return {
      code: value.code,
      state: value.state,
    };
  }
}
