import React, { CSSProperties, ReactChild, ReactChildren } from 'react';

import { Card as AntdCard } from 'antd';

import './card.component.scss';

interface CardProps {
  title: string;
  bordered: boolean;
  children: ReactChildren | ReactChild;
  style?: CSSProperties;
}

export const Card = (props: CardProps): JSX.Element => {
  return (
    <div className='Card'>
      <AntdCard title={props.title} bordered={props.bordered} className='card-container' bodyStyle={{ ...props.style }}>
        {props.children}
      </AntdCard>
    </div>
  );
};

Card.defaultProps = {
  title: null,
  bordered: false,
  style: { padding: '1.25rem' },
};
