import { RbacRolePermissionResponsePages } from '../../generated/model/models';
import { PermissionSummaryData } from './permission-summary-data';

export interface PageSummaryData {
  parent: PermissionSummaryData;
  children: PermissionSummaryData[];
}

export namespace PageSummaryData {
  export function mapFromApi(data: RbacRolePermissionResponsePages): PageSummaryData {
    return {
      parent: PermissionSummaryData.mapFromApi(data.parent),
      children: (data.children || []).map(permission => PermissionSummaryData.mapFromApi(permission)),
    };
  }
}
