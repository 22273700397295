import { createReducer, Action, createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import { ErrorResponseData } from '../../../../core/models/http-params.type';
import { ActionEntities, ActionSummaryData } from '../../../../core/models/permission/action-summary-data';
import { PageSummaryData } from '../../../../core/models/permission/page-summary-data';
import { RoleSummaryData } from '../../../../core/models/role/role-summary-data';

import * as fromActions from './permissions.actions';
const adapterRole = createEntityAdapter<RoleSummaryData>({
  selectId: role => role.id,
  // sortComparer: (a, b) => a.id.localeCompare(b.id),
});
export interface RolesState extends EntityState<RoleSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
}

export interface PagesState {
  list: PageSummaryData[];
  loading: boolean;
  error: ErrorResponseData;
}

export interface ActionsState {
  list: ActionSummaryData[];
  loading: boolean;
  error: ErrorResponseData;
}

export const rolesInitialState: RolesState = adapterRole.getInitialState<RolesState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
});

export const pagesInitialState: PagesState = {
  list: [],
  loading: false,
  error: null,
};

export const actionsInitialState: ActionsState = {
  list: [],
  loading: false,
  error: null,
};

export interface PermissionsState {
  submitting: boolean;
  selectedRole: string;
  roles: RolesState;
  pages: PagesState;
  actions: ActionsState;
  actionEntities: ActionEntities;
  editingRole: RoleSummaryData;
}

const permissionsInitialState: PermissionsState = {
  submitting: false,
  selectedRole: null,
  roles: rolesInitialState,
  editingRole: {} as RoleSummaryData,
  pages: pagesInitialState,
  actions: actionsInitialState,
  actionEntities: {} as ActionEntities,
};

const _permissionsReducer = createReducer<PermissionsState>(permissionsInitialState, builder => {
  builder
    .addCase(fromActions.destroyData, (): PermissionsState => permissionsInitialState)
    .addCase(fromActions.loadRoleList, (state): PermissionsState => ({ ...state, roles: { ...state.roles, loading: true } }))
    .addCase(fromActions.updatePermissions, (state): PermissionsState => ({ ...state, submitting: true }))
    .addCase(fromActions.setSubmitting, (state, { payload: { submitting } }): PermissionsState => ({ ...state, submitting }))
    .addCase(fromActions.loadRoleListFail, (state): PermissionsState => ({ ...state, roles: { ...state.roles, loading: false } }))
    .addCase(fromActions.getRoleByIdSuccess, (state, { payload }): PermissionsState => ({ ...state, editingRole: payload }))
    .addCase(fromActions.resetRole, (state): PermissionsState => ({ ...state, editingRole: {} as RoleSummaryData }))
    .addCase(
      fromActions.loadPermissions,
      (state): PermissionsState => ({
        ...state,
        pages: { ...state.pages, loading: true },
        actions: { ...state.actions, loading: true },
      }),
    )
    .addCase(
      fromActions.loadPermissionsFail,
      (state): PermissionsState => ({
        ...state,
        pages: { ...state.pages, loading: false },
        actions: { ...state.actions, loading: false },
      }),
    )
    .addCase(
      fromActions.loadPermissionsSuccess,
      (state, { payload: { data, roleId } }): PermissionsState => ({
        ...state,
        selectedRole: roleId,
        pages: { ...state.pages, list: data.pages, loading: false },
        actions: { ...state.actions, list: data.actions, loading: false },
        actionEntities: ActionSummaryData.mapToEntities(data.actions),
      }),
    )
    .addCase(
      fromActions.loadRoleListSuccess,
      (state, { payload }): PermissionsState => {
        const newState: RolesState = { ...state.roles, loading: false };

        return { ...state, roles: adapterRole.setAll(newState, payload.roles) };
      },
    )
    .addCase(
      fromActions.updateRoleSuccess,
      (state, { payload: { previousRoleId, role } }): PermissionsState => ({
        ...state,
        selectedRole: state.selectedRole !== previousRoleId ? state.selectedRole : role.id,
      }),
    )
    .addCase(
      fromActions.createRoleSuccess,
      (state, { payload }): PermissionsState => {
        const newState: RolesState = { ...state.roles, loading: false };

        return { ...state, roles: adapterRole.addOne(newState, payload) };
      },
    )
    .addCase(
      fromActions.duplicateRoleSuccess,
      (state, { payload }): PermissionsState => {
        const newState: RolesState = { ...state.roles, loading: false };

        return { ...state, roles: adapterRole.addOne(newState, payload) };
      },
    )
    .addCase(
      fromActions.deleteRoleSuccess,
      (state, { payload: { roleId } }): PermissionsState => {
        const newTaskState: RolesState = { ...state.roles };

        return {
          ...state,
          selectedRole: roleId === state.selectedRole ? undefined : state.selectedRole,
          pages: roleId === state.selectedRole ? { ...state.pages, list: [] } : state.pages,
          actions: roleId === state.selectedRole ? { ...state.actions, list: [] } : state.actions,
          roles: adapterRole.removeOne(newTaskState, roleId),
        };
      },
    );
});
export function permissionsReducer(state: PermissionsState | undefined, action: Action): PermissionsState {
  return _permissionsReducer(state, action);
}

export const selectAllRoles = adapterRole.getSelectors().selectAll;
