import React, { ReactChildren, ReactChild } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal as AntdModal } from 'antd';

import { getModalVisibility, getModalEditMode, getCurrentModalId, getModalLoading } from './state/modal.selectors';
import { ModalIdEnum } from '../../../core/enums/entity-ids/modal-id.enum';
import { closeModal } from './state/modal.actions';

import './modal.component.scss';
import { Spinner } from '..';

interface ModalProps {
  title: string | React.ReactNode;
  modalId: ModalIdEnum;
  editTitle: string;
  maskClosable: boolean;
  footer: React.ReactNode[] | undefined;
  width: number | string;
  onSave: () => void;
  children: ReactChildren | ReactChild;
}

export function Modal(props: ModalProps): JSX.Element {
  const dispatch = useDispatch();
  const visible = useSelector(getModalVisibility);
  const currentModalId = useSelector(getCurrentModalId);
  const editMode = useSelector(getModalEditMode);
  const loading = useSelector(getModalLoading);

  const handleCancel = () => {
    dispatch(closeModal());
  };

  if (!visible || currentModalId !== props.modalId) {
    return null;
  }

  return (
    <div className='SharedModal'>
      <AntdModal
        title={editMode ? props.editTitle : props.title}
        visible={visible && currentModalId === props.modalId}
        onCancel={handleCancel}
        footer={null}
        wrapClassName='SharedModal'
        bodyStyle={{ padding: 0 }}
        style={{ top: '5%' }}
        destroyOnClose={true}
        maskClosable={props.maskClosable}
        width={props.width}
      >
        <div className='shared-modal-body'>
          {loading ? (
            <div className='spinner-shared-modal'>
              <Spinner />
            </div>
          ) : (
            props.children
          )}
        </div>
      </AntdModal>
    </div>
  );
}

Modal.defaultProps = {
  title: null,
  editTitle: null,
  visible: false,
  maskClosable: true,
  footer: null,
  width: '50',
  onSave: () => {},
};
