import React from 'react';
import { Upload } from 'antd';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { RcCustomRequestOptions, UploadFile } from 'antd/lib/upload/interface';

import { Button } from '../Button/button.component';
import { Tooltip } from '../Tooltip/tooltip.component';

import './upload-field.component.scss';

interface UploadFieldProps {
  meta: WrappedFieldMetaProps;
  input: WrappedFieldInputProps;
  placeholder?: string;
  disabled?: boolean;
  tooltip?: string;
}

export function UploadField(props: UploadFieldProps): JSX.Element {
  const preventRequest = () => false;

  const onRemove = (file: UploadFile) => {
    props.input.onChange([...props.input.value].filter(n => n.uid !== file.uid));
  };

  const dummyRequest = ({ file, onSuccess }: RcCustomRequestOptions) => {
    setTimeout(() => {
      onSuccess(file, file);
    }, 0);
  };

  const handleChange = ({ fileList }) => props.input.onChange(fileList);

  return (
    <Tooltip title={props.tooltip}>
      <div className='UploadField'>
        <Upload className='trigger-uploader' beforeUpload={preventRequest} fileList={props.input.value} onChange={handleChange} onRemove={onRemove} customRequest={dummyRequest}>
          <Button label={props.placeholder} btnClass='full-width' disabled={props.disabled} />
        </Upload>
      </div>
    </Tooltip>
  );
}
