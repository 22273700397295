import { TaskResponseDTO } from '../../generated/model/taskResponseDTO';

export enum ZoomStatusEnum {
  Started = 'started',
  Ended = 'ended',
  NotStarted = 'notStarted',
}

export namespace ZoomStatusEnum {
  export function convertFromApiValue(value: TaskResponseDTO.ZoomMeetingStatusEnum): ZoomStatusEnum {
    switch (value) {
      case TaskResponseDTO.ZoomMeetingStatusEnum.NUMBER_1:
        return ZoomStatusEnum.Started;

      case TaskResponseDTO.ZoomMeetingStatusEnum.NUMBER_2:
        return ZoomStatusEnum.NotStarted;

      case TaskResponseDTO.ZoomMeetingStatusEnum.NUMBER_3:
        return ZoomStatusEnum.Ended;

      default:
        return undefined;
    }
  }

  export function toString(value: ZoomStatusEnum): string {
    switch (value) {
      case ZoomStatusEnum.Started:
        return 'Join meeting';

      case ZoomStatusEnum.Ended:
        return 'Meeting ended';

      case ZoomStatusEnum.NotStarted:
        return 'Start meeting';

      default:
        return undefined;
    }
  }

  export function getClassName(value: ZoomStatusEnum): string {
    switch (value) {
      case ZoomStatusEnum.Started:
        return 'pentugram-primary-light-btn';

      case ZoomStatusEnum.Ended:
        return 'pentugram-danger-light-btn';

      case ZoomStatusEnum.NotStarted:
        return 'pentugram-success-light-btn';

      default:
        return undefined;
    }
  }
}
