import React, { Component } from 'react';

import './PasswordButton.scss';

class PasswordButton extends Component {
  render() {
    return (
      <div className='PasswordButton'>
        {this.props.label && <span className={this.props.labelClass ? this.props.labelClass : 'label-selector'}>{this.props.label}</span>}
        <div className='password-btn'>
          <div className='password-stars'>***********</div>
          <div className='password-message' onClick={this.props.onClick}>
            CHANGE PASSWORD
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordButton;
