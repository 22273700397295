//@ts-ignore
import { Mixpanel } from 'App/app';
import { put, PutEffect, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { all } from '@redux-saga/core/effects';

import * as fromPermissionActions from './permissions.actions';
import { ErrorResponseData } from '../../../../core/models/http-params.type';
import { NotificationTypeEnum } from '../../../../shared/enums/notification-type.enum';
import { closeModal, openModal, setEditMode, setLoading, setSubmitted } from '../../../../shared/components/Modal/state/modal.actions';
import { notification } from '../../../../shared/components';
import {
  createRole,
  deleteRole,
  duplicateRole,
  getPermissionsByRole,
  getRoles,
  loadRoleById,
  updatePermissionsByRole,
  updateRole,
} from '../../../../core/services/permissions.service';
import { RoleSummaryData } from '../../../../core/models/role/role-summary-data';
import { PageActionPermissionData } from '../../../../core/models/permission/page-action-permission-data';
import { PermissionForUpdate } from '../../../../core/models/permission/permission-for-update';
import { CreateRoleData } from '../../../../core/models/role/role-for-create';

import { ModalIdEnum } from '../../../../core/enums/entity-ids/modal-id.enum';
import { UpdateRoleData } from '../../../../core/models/role/role-for-update';

function* loadRolesEffect(): Generator<Promise<RoleSummaryData[]> | PutEffect, void, RoleSummaryData[]> {
  try {
    const roles = yield getRoles();

    yield put(fromPermissionActions.loadRoleListSuccess({ roles }));
  } catch (error) {
    yield put(fromPermissionActions.loadRoleListFail(error));
  }
}

function* loadPermissionsByRoleEffect(action: PayloadAction<string>): Generator<Promise<PageActionPermissionData> | PutEffect, void, PageActionPermissionData> {
  try {
    const data = yield getPermissionsByRole(action.payload);

    yield put(fromPermissionActions.loadPermissionsSuccess({ data, roleId: action.payload }));
  } catch (error) {
    yield put(fromPermissionActions.loadPermissionsFail(error));
  }
}

function* updatePermissionsEffect({ payload }: PayloadAction<PermissionForUpdate>): Generator<Promise<PageActionPermissionData> | PutEffect, void, PageActionPermissionData> {
  try {
    const data = yield updatePermissionsByRole(payload.role, { body: PermissionForUpdate.mapToApi(payload) });

    yield put(fromPermissionActions.setSubmitting({ submitting: false }));
    yield put(fromPermissionActions.loadPermissionsSuccess({ data, roleId: payload.role }));

    notification({ type: NotificationTypeEnum.Success, message: 'Permissions successfully updated' });
  } catch (error) {
    yield put(fromPermissionActions.updatePermissionsFail(error));
    yield put(fromPermissionActions.setSubmitting({ submitting: false }));
  }
}

function* createRoleEffect({ payload }: PayloadAction<CreateRoleData>): Generator<Promise<RoleSummaryData> | PutEffect, void, RoleSummaryData> {
  try {
    yield put(setSubmitted({ submitted: true }));
    const role = yield createRole({ body: CreateRoleData.mapToApi(payload) });
    yield put(fromPermissionActions.createRoleSuccess(role));
    yield put(closeModal());
    yield put(fromPermissionActions.loadPermissions(role.id));
    notification({ type: NotificationTypeEnum.Success, message: 'Role successfully created' });
    Mixpanel.track('Role created successfully');
  } catch (error) {
    yield put(fromPermissionActions.createRoleFail(error));
    yield put(setSubmitted({ submitted: false }));
    Mixpanel.track('Role creation failed');
  }
}

function* deleteRoleEffect({ payload: { roleId } }: PayloadAction<{ roleId: string }>): Generator {
  try {
    yield deleteRole(roleId);
    yield put(fromPermissionActions.deleteRoleSuccess({ roleId }));

    notification({ type: NotificationTypeEnum.Success, message: 'Role deleted successfully' });
    Mixpanel.track('Role deleted successfully');
  } catch (error) {
    yield put(fromPermissionActions.deleteRoleFail(error));
    Mixpanel.track('Role deletion failed');
  }
}

function* loadRoleByIdEffect({ payload: { roleId } }: PayloadAction<{ roleId: string }>): Generator<Promise<RoleSummaryData> | PutEffect, void, RoleSummaryData> {
  try {
    yield put(openModal({ modalId: ModalIdEnum.createPermission }));
    yield put(setEditMode({ editMode: true }));
    yield put(setLoading({ loading: true }));

    const role = yield loadRoleById(roleId);

    yield put(fromPermissionActions.getRoleByIdSuccess(role));
    yield put(setLoading({ loading: false }));
  } catch (error) {
    yield put(fromPermissionActions.getRoleByIdFail(error));
    yield put(setLoading({ loading: false }));
  }
}

function* updateRoleEffect({ payload }: PayloadAction<UpdateRoleData>): Generator<Promise<RoleSummaryData> | PutEffect, void, RoleSummaryData> {
  try {
    yield put(setSubmitted({ submitted: true }));

    const role = yield updateRole(payload.id, { body: UpdateRoleData.mapToApi(payload) });

    yield put(fromPermissionActions.updateRoleSuccess({ previousRoleId: payload.id, role }));
    yield put(closeModal());
    yield put(fromPermissionActions.loadPermissions(role.id));
    notification({ type: NotificationTypeEnum.Success, message: 'Role successfully updated' });
    Mixpanel.track('Role updated successfully');
  } catch (error) {
    yield put(fromPermissionActions.createRoleFail(error));
    yield put(setSubmitted({ submitted: false }));
    Mixpanel.track('Role update failed');
  }
}

function* createOrUpdateRoleEffect(): Generator {
  try {
    yield put(fromPermissionActions.loadRoleList());
    yield put(fromPermissionActions.resetRole());
    Mixpanel.track('Role created or updated successfully');
  } catch (error) {
    Mixpanel.track('Role creation or update failed');
  }
}

function* duplicateRoleEffect({ payload: { roleId } }: PayloadAction<{ roleId: string }>): Generator<Promise<RoleSummaryData> | PutEffect, void, RoleSummaryData> {
  try {
    const role = yield duplicateRole(roleId);
    yield put(fromPermissionActions.duplicateRoleSuccess(role));

    notification({ type: NotificationTypeEnum.Success, message: 'Role duplicated successfully' });
    Mixpanel.track('Role duplicated successfully');
  } catch (error) {
    yield put(fromPermissionActions.deleteRoleFail(error));
    Mixpanel.track('Role duplication failed');
  }
}

function* failEffect({ payload }: PayloadAction<ErrorResponseData>): Generator {
  try {
    notification({ type: NotificationTypeEnum.Error, message: payload.message });
  } catch (error) {}
}

export function* watchPermissions(): Generator {
  yield all([
    takeLatest(fromPermissionActions.loadRoleList.type, loadRolesEffect),
    takeLatest(fromPermissionActions.loadPermissions.type, loadPermissionsByRoleEffect),
    takeLatest(fromPermissionActions.updatePermissions.type, updatePermissionsEffect),
    takeLatest(fromPermissionActions.createRole.type, createRoleEffect),
    takeLatest(fromPermissionActions.updateRole.type, updateRoleEffect),
    takeLatest(fromPermissionActions.duplicateRole.type, duplicateRoleEffect),
    takeLatest(fromPermissionActions.updateRoleSuccess.type, createOrUpdateRoleEffect),
    takeLatest(fromPermissionActions.getRoleById.type, loadRoleByIdEffect),
    takeLatest(fromPermissionActions.deleteRole.type, deleteRoleEffect),
    takeLatest(
      [
        fromPermissionActions.loadRoleListFail.type,
        fromPermissionActions.loadPermissionsFail.type,
        fromPermissionActions.createRoleFail.type,
        fromPermissionActions.updatePermissionsFail.type,
        fromPermissionActions.deleteRoleFail.type,
        fromPermissionActions.getRoleByIdFail.type,
        fromPermissionActions.updateRoleFail.type,
      ],
      failEffect,
    ),
  ]);
}
