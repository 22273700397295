import { Notifier } from 'SharedComponent/Notifier';
import api from './api';
export const LOADING_CONTACTS = 'contacts:loading';
export const LOADED_CONTACTS = 'contacts:loaded';
export const LOADING_CONTACTS_FAILED = 'contacts:loading:failed';
export const CONTACT_DELETED = 'contacts:deleted';
export const CONTACT_DELETED_FAILED = 'contacts:deleted:failed';
export const CONTACT_CREATED = 'contacts:created';
export const EDIT_CONTACT = 'contacts:editing';
export const MODAL_DISMISS = 'contacts:dismiss:modal';
export const CONTACT_UPDATED = 'contacts:updated';
export const CONTACTS_BY_DEAL = 'contacts:by:deal:loaded';
export const SUGGESTIONS_BY_DEAL = 'contacts:suggestions:by:deal:loaded';
export const LOADING_SUGGESTIONS = 'contacts:suggestions:loading';
export const LOAD_CONTACT = 'contact:load:byId';
export const UPDATED = 'contact:updated';
export const CONTACT_DEAL_CREATED = 'contact:deal:created:success';
export const UNLINK_DEAL_CONTACT = 'contact:deal:unlink';
export const ACCEPT_DEAL_SUGGESTION = 'contact:suggestion:accept';
export const REFUSE_DEAL_SUGGESTION = 'contact:suggestion:refuse';
export const ADD_SUGGESTIONS_PUSHER = 'contacts:deal:pusher:suggestions';

function errorMessage(error) {
  const data = error.data ? error.data : error.toString();
  let errorBody = data.internalCode ? data.internalCode : data.message ? data.message : data;
  if (errorBody.length > 80) {
    errorBody = errorBody.substring(0, 80);
  }
  return errorBody;
}

function normalizeFilters(filters) {
  if (filters.custom && filters.custom.fromTo) {
    const newFilters = {
      ...filters,
      custom: {
        ...filters.custom,
        fromDate: filters.custom.fromTo[0],
        toDate: filters.custom.fromTo[1],
      },
    };
    unset(newFilters, 'custom.fromTo');
    return newFilters;
  } else return filters;
}

function setFilterFormat(filters) {
  if (filters) {
    let filter = '';
    let filtersJson = JSON.parse(filters);
    for (let key in filtersJson) {
      if (filtersJson[key]) {
        for (let key2 in filtersJson[key]) {
          if (filtersJson[key][key2]) {
            filter += `&filter[${key2}]=${filtersJson[key][key2]}`;
          }
        }
      }
    }
    return filter;
  }
  return '';
}

export function load(page, filter = []) {
  return dispatch => {
    const filters = setFilterFormat(localStorage.getItem('CONTACT_FILTERS'));
    dispatch({ type: LOADING_CONTACTS });
    api
      .get(`/contacts?page=${page}${filters ? filters : ''}`)
      .then(({ data }) => {
        dispatch({
          type: LOADED_CONTACTS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({ type: LOADING_CONTACTS_FAILED });
        Notifier({
          type: 'error',
          title: errorMessage(error),
          message: 'Please try again',
        });
      });
  };
}

export function loadContact(id) {
  return dispatch => {
    dispatch({ type: LOADING_CONTACTS });
    api
      .get(`/contacts/${id}`)
      .then(({ data }) => dispatch({ type: LOAD_CONTACT, payload: data }))
      .catch(error => dispatch({ type: LOADING_CONTACTS_FAILED }));
  };
}

export function updateContact(contact) {
  return dispatch => {
    api
      .put(`/contacts/${contact.contact_id}`, contact)
      .then(({ data }) => {
        dispatch({
          type: UPDATED,
          payload: data,
        });
        Notifier({
          type: 'success',
          title: 'Contact updated',
          message: `${contact.first_name}`,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Try again',
          message: `Cannot update contact: ${contact.first_name}`,
        });
      });
  };
}

export function remove(contact) {
  return dispatch => {
    api
      .delete(`/contacts/${contact.contact_id}`)
      .then(({ data }) => {
        dispatch({
          type: CONTACT_DELETED,
          payload: contact,
        });
        Notifier({
          type: 'info',
          title: 'Success',
          message: `${contact.first_name} deleted`,
        });
      })
      .catch(error => {
        dispatch({
          type: CONTACT_DELETED_FAILED,
          error,
        });
      });
  };
}

export function create(contact) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      api
        .post('/contacts', contact)
        .then(({ data }) => {
          dispatch({
            type: CONTACT_CREATED,
            payload: data,
          });
          resolve(data);
          Notifier({
            type: 'success',
            title: 'Contact created',
            message: `${contact.first_name}`,
            redirect: `/contacts/${data.contact_id}`,
          });
        })
        .catch(error => {
          reject(error);
          dispatch(
            Notifier({
              type: 'error',
              title: 'Error',
              message: `Cannot create contact: ${contact.first_name}`,
            }),
          );
        });
    });
  };
}

export function update(contact) {
  return dispatch => {
    api
      .put(`/contacts/${contact.contact_id}`, contact)
      .then(({ data }) => {
        dispatch({
          type: CONTACT_UPDATED,
          payload: data,
        });
        Notifier({
          type: 'info',
          title: 'Contact updated',
          message: `${contact.first_name}`,
          redirect: `/contacts/${contact.contact_id}`,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Try again',
          message: `Cannot update contact: ${contact.first_name}`,
        });
      });
  };
}

export function edit(contact) {
  return dispatch => {
    dispatch({
      type: EDIT_CONTACT,
      payload: contact,
    });
  };
}

export function dismissModal() {
  return dispatch => {
    dispatch({ type: MODAL_DISMISS });
  };
}

export function loadContactsByDeal(deal_id, is_suggest) {
  return dispatch => {
    if (is_suggest) dispatch({ type: LOADING_SUGGESTIONS });
    else dispatch({ type: LOADING_CONTACTS });
    api
      .get(`/contacts?filter[deal_id]=${deal_id}&filter[is_suggested]=${is_suggest}`)
      .then(({ data }) => {
        if (is_suggest) {
          dispatch({ type: SUGGESTIONS_BY_DEAL, payload: data.results });
        } else dispatch({ type: CONTACTS_BY_DEAL, payload: data.results });
      })
      .catch(error => {
        dispatch({ type: LOADING_CONTACTS_FAILED });
      });
  };
}

export function unlink(contact) {
  return dispatch => {
    api
      .put(`/contacts/${contact.contact_id}`, contact)
      .then(({ data }) => {
        dispatch({
          type: UNLINK_DEAL_CONTACT,
          payload: data,
        });
      })
      .catch(error => {});
  };
}

export function acceptContact(contact) {
  const body = {
    contact_id: contact.contact_id,
    deal_id: contact.deal_id,
    accept: 1,
  };
  return dispatch => {
    api
      .post(`/contacts/suggestion`, body)
      .then(({ data }) => {
        dispatch({
          type: ACCEPT_DEAL_SUGGESTION,
          payload: contact,
        });
        Notifier({
          type: 'success',
          itle: 'Contact has been successfully linked',
          message: `${contact.first_name}`,
        });
      })
      .catch(error => {});
  };
}

export function refuseContact(contact) {
  const body = {
    contact_id: contact.contact_id,
    deal_id: contact.deal_id,
    accept: 0,
  };
  return dispatch => {
    api
      .post(`/contacts/suggestion`, body)
      .then(({ data }) => {
        dispatch({
          type: REFUSE_DEAL_SUGGESTION,
          payload: contact,
        });
        Notifier({
          type: 'success',
          itle: 'Contact has been successfully unlinked',
          message: `${contact.first_name}`,
        });
      })
      .catch(error => {});
  };
}

export function addSuggestions(contacts) {
  return dispatch => {
    dispatch({ type: ADD_SUGGESTIONS_PUSHER, payload: contacts });
  };
}

export function link(contact) {
  return dispatch => {
    api
      .post('/contacts', contact)
      .then(({ data }) => {
        dispatch({
          type: CONTACT_DEAL_CREATED,
          payload: data,
        });
        Notifier({
          type: 'success',
          itle: 'Contact created',
          message: `${contact.first_name}`,
        });
      })
      .catch(error => {});
  };
}

export function linkMany(contact) {
  return dispatch => {
    api
      .post('/contacts/link', contact)
      .then(({ data }) => {
        dispatch({
          type: CONTACTS_BY_DEAL,
          payload: data,
        });
        Notifier({
          type: 'success',
          title: 'Contacts linked',
        });
      })
      .catch(error => {});
  };
}
