import React, { ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { MetaQuery } from '../../../core/models/queries/meta-query.model';
import { Spinner } from '../Spinner/spinner.component';

import './infinite-list.component.scss';

// interface InfiniteListProps {
//   meta: MetaQuery;
//   spinner: ReactElement;
//   children: ReactElement;
//   loadMore(page: number): void;
// }

export function InfiniteList(props) {
  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={props.loadMore}
      hasMore={!props.meta.maxReached}
      loader={props.spinner}
      threshold={50}
      initialLoad={false}
      useWindow={false}
      className='infinite-scroll-shared-component-class'
    >
      <div className='infinite-scroll-shared-list'>{props.children}</div>
    </InfiniteScroll>
  );
}

InfiniteList.defaultProps = {
  spinner: (
    <div key={0} className='d-flex justify-content-center m-rem-0_5'>
      <Spinner />
    </div>
  ),
};
