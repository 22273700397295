import { reset } from 'redux-form';
import pick from 'lodash/pick';
import { Notifier } from 'SharedComponent/Notifier';
import api from './api';
import { dispatchAlert } from './notificationActions';
import { handleErrors } from './authActions';
import { ANGEL_NETWORK_ACTIVATED } from '../enums/permissions';
import { get as getV2 } from '../v2.0/core/services/helpers/base-http';

export const LOADING_USERS = 'settings:users:loading';
export const LOADED_USERS = 'settings:users:loaded';
export const USER_UPDATED = 'settings:users:updated';
export const USER_ADDED = 'settings:users:added:successfully';
export const DELETE_USER = 'settings:users:deleted:successfully';
export const EDITING_USER = 'settings:users:editing';
export const COMPANY_LOADING = 'settings:company:loading';
export const CUSTOM_FIELDS_LOADING = 'settings:custom:fields:loading';
export const CUSTOM_FIELDS_LOADED = 'settings:custom:fields:loaded';
export const UPDATE_COMPANY_FAILED = 'settings:company:u^pdateing:failed';
export const COMPANY_LOADED = 'settings:company:loaded';
export const COMPANY_UPDATED = 'settings:company:updated';
export const ROLES_LOADED = 'settings:users:roles:loaded';
export const EMAIL_TEMPLATES_LOADED = 'settings:templates:loaded';
export const EMAIL_TEMPLATES_LOADING = 'settings:templates:loading';
export const EMAIL_TEMPLATE_DELETED = 'settings:templates:deletion:failed';
export const EMAIL_TEMPLATE_CREATED = 'settings:templates:created';
export const EDITING_TEMPLATE = 'settings:templates:editing';
export const EMAIL_TEMPLATES_UPDATED = 'settings:templates:updated';
export const CUSTOM_FIELD_CREATED = 'settings:custom:field:created';
export const CUSTOM_FIELD_DELETED = 'settings:custom:field:deleted';
export const CUSTOM_FIELD_UPDATED = 'settings:custom:field:updated';
export const USER_BLOCKED = 'settings:users:block';
export const USER_UNBLOCKED = 'settings:users:unblock';
export const LOADED_INVESTORS = 'settings:users:investors:loaded';
export const INVESTOR_UPDATED = 'settings:users:investors:updated';
export const INVESTOR_ADDED = 'settings:users:investors:added:successfully';
export const FILTER_INVESTORS = 'settings:users:investors:filtered';
export const REMOVE_USER_ATTRIBUTE = 'settings:users:attrs:remove';
export const ADD_USER_ATTRIBUTE = 'settings:users:attrs:add';
import * as actions from '../v2.0/store/me/me.actions';
import { Mixpanel } from 'App/app';

function formatUsers(list) {
  return list.map(user => user.id).join(',');
}

export function filterInvestors(filter) {
  return dispatch => dispatch({ type: FILTER_INVESTORS, payload: filter });
}

export function loadUsers() {
  return dispatch => {
    dispatch({ type: LOADING_USERS });
    api
      .get('/users?sort=status,last_login')
      .then(({ data }) => {
        dispatch({
          type: LOADED_USERS,
          payload: data.results,
        });
      })
      .catch(error => {});
  };
}

export function addUser(user) {
  return dispatch => {
    api
      .post('/users', user)
      .then(({ data }) => {
        dispatch({ type: USER_ADDED, payload: data });
        dispatchAlert(dispatch, {
          message: `The user ${user.fullname} have been successfully added.`,
          type: 'success',
        });
        Mixpanel.track('User Added', { user: data });
      })
      .catch(error => {
        dispatchAlert(dispatch, {
          message: handleErrors(error),
          type: 'danger',
        });
        Mixpanel.track('User Add Failed', { error });
      });
  };
}

export function updateUser(user) {
  return dispatch => {
    api
      .put(`/users/${user.id}`, user)
      .then(({ data }) => {
        dispatch({ type: USER_UPDATED, payload: data });
        dispatchAlert(dispatch, {
          message: `The user ${user.fullname} have been successfully updated.`,
          type: 'primary',
        });
        Mixpanel.track('User Updated', { user: data });
      })
      .catch(error => {
        Mixpanel.track('User Update Failed', { error });
      });
  };
}

export function deleteInvestor(id) {
  return dispatch => {
    api
      .delete(`/investors/${id}`)
      .then(response => {
        dispatch({ type: DELETE_USER, payload: id });
        dispatchAlert(dispatch, {
          message: `The investor have been successfully deleted.`,
          type: 'danger',
        });
      })
      .catch(error => {});
  };
}

export function resendInvestor(ids) {
  return dispatch => {
    api
      .post('/investors/resend', { ids })
      .then(res => {
        dispatchAlert(dispatch, {
          message: `A new invitation has been sent to investor successfully.`,
          type: 'warning',
        });
        Mixpanel.track('Investor Resend Invitation');
      })
      .catch(err => {
        dispatchAlert(dispatch, {
          message: handleErrors(err),
          type: 'danger',
        });
        Mixpanel.track('Investor Resend Invitation Failed');
      });
  };
}

export function deleteUser(id) {
  return dispatch => {
    api
      .delete(`/users/${id}`)
      .then(response => {
        dispatch({ type: DELETE_USER, payload: id });
        dispatchAlert(dispatch, {
          message: `The user ${id} have been successfully deleted.`,
          type: 'danger',
        });
      })
      .catch(error => {});
  };
}

export function resendInvitation(user) {
  return dispatch => {
    api
      .get(`/resend/${user.id}`)
      .then(({ data }) => {
        dispatchAlert(dispatch, {
          message: `A new invitation has been sent to ${data.profile.fullname}.`,
          type: 'warning',
        });
        Mixpanel.track('User Resend Invitation', { user: data });
      })
      .catch(error => {
        dispatchAlert(dispatch, {
          message: handleErrors(error),
          type: 'danger',
        });
        Mixpanel.track('User Resend Invitation Failed', { error });
      });
  };
}

export function loadRoles() {
  return dispatch => {
    api
      .get('/roles')
      .then(({ data }) => {
        dispatch({ type: ROLES_LOADED, payload: data.results });
      })
      .catch(error => {});
  };
}

export function loadCompany() {
  return dispatch => {
    dispatch({ type: COMPANY_LOADING, payload: true });
    api
      .get('/companies')
      .then(({ data }) => {
        dispatch({ type: COMPANY_LOADED, payload: data.results });
      })
      .catch(error => dispatch({ type: COMPANY_LOADING, payload: false }));
  };
}

export function updateCompany(company) {
  return dispatch => {
    dispatch({ type: COMPANY_LOADING, payload: true });
    api
      .put(`/companies/${company.company_id}`, company)
      .then(({ data }) => {
        dispatch({ type: COMPANY_UPDATED, payload: data });
        if (company.angel_investor_enabled) dispatch({ type: ADD_USER_ATTRIBUTE, payload: ANGEL_NETWORK_ACTIVATED });
        else dispatch({ type: REMOVE_USER_ATTRIBUTE, payload: ANGEL_NETWORK_ACTIVATED });
        dispatchAlert(dispatch, {
          message: 'Your company details have been successfully updated.',
          type: 'primary',
        });
        Mixpanel.track('Company Updated', { company: data });
        getV2('/v2/me')
          .then(data => dispatch({ type: 'AUTH:SIGNING:V2', payload: data }))
          .catch(({ error }) => {});
      })
      .catch(({ response }) => {
        Mixpanel.track('Company Update Failed', { error: response.data });
        dispatch({ type: UPDATE_COMPANY_FAILED, payload: response.data });
      });
  };
}

export function discardCompanyErrors() {
  return dispatch => {
    dispatch({
      type: UPDATE_COMPANY_FAILED,
      payload: [],
    });
  };
}

export function loadEmailTemplates() {
  return dispatch => {
    dispatch({ type: EMAIL_TEMPLATES_LOADING, payload: true });
    api
      .get('/emailtpls')
      .then(({ data }) => {
        dispatch({ type: EMAIL_TEMPLATES_LOADED, payload: data });
      })
      .catch(error => {
        dispatch({ type: EMAIL_TEMPLATES_LOADING, payload: false });
      });
  };
}

export function updateEmailTemplate(template) {
  return dispatch => {
    const emailtpl = pick(template, ['name', 'content']);
    api
      .put(`/emailtpls/${template.emailtpl_id}`, emailtpl)
      .then(({ data }) => {
        dispatchAlert(dispatch, {
          message: `The email template ${template.name} has been successfully updated.`,
          type: 'info',
        });
        dispatch({ type: EMAIL_TEMPLATES_UPDATED, payload: data });
        Mixpanel.track('Email Template Updated', { template: data });
      })
      .catch(error => {
        dispatchAlert(dispatch, {
          message: `The email template ${template.name} couldn't be updated.`,
          type: 'danger',
        });
        Mixpanel.track('Email Template Update Failed', { error });
      });
  };
}

export function editingTemplate(template) {
  return dispatch => {
    dispatch({ type: EDITING_TEMPLATE, payload: template });
  };
}

export function discardEditingTemplate() {
  return dispatch => {
    dispatch({ type: EDITING_TEMPLATE, payload: null });
  };
}

export function removetemplate(template) {
  return dispatch => {
    api
      .delete(`/emailtpls/${template.id}`)
      .then(response => {
        dispatch({ type: EMAIL_TEMPLATE_DELETED, payload: template.id });
        dispatchAlert(dispatch, {
          message: `The email template ${template.name} has been successfully deleted.`,
          type: 'danger',
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Email template deletion failed',
          message: template.name,
        });
      });
  };
}

export function addEmailTemplate(template) {
  return dispatch => {
    api
      .post(`/emailtpls`, template)
      .then(({ data }) => {
        dispatch({ type: EMAIL_TEMPLATE_CREATED, payload: data });
        dispatchAlert(dispatch, {
          message: `The email template ${template.name} has been successfully added.`,
          type: 'primary',
        });
        Mixpanel.track('Email Template Created', { template: data });
      })
      .catch(error => {
        dispatchAlert(dispatch, {
          message: `The email template ${template.name} couldn't be added.`,
          type: 'danger',
        });
        Mixpanel.track('Email Template Creation Failed', { error });
      });
  };
}

export function onEdit(user, view = 'users') {
  return dispatch => {
    dispatch({ type: EDITING_USER, payload: user, view });
  };
}

export function discardEditing() {
  return dispatch => {
    dispatch({ type: EDITING_USER, payload: null });
  };
}

export function deleteUsers(users) {
  return dispatch => {
    // api
    //   .post("/users/delete", { ids: formatUsers(users) })
    //   .then(({ data }) => {})
    //   .catch(error => {});
  };
}

export function resendInvitations(users) {
  return dispatch => {
    api
      .post('/resend', { ids: formatUsers(users) })
      .then(({ data }) =>
        dispatchAlert(dispatch, {
          message: `A new invitation has been sent to ${users.length} users`,
          type: 'warning',
        }),
      )
      .catch(error => {});
  };
}

export function loadCompanyCustomFields() {
  return dispatch => {
    dispatch({ type: CUSTOM_FIELDS_LOADING, payload: true });
    api
      .get(`/companies/field`)
      .then(({ data }) => {
        dispatch({ type: CUSTOM_FIELDS_LOADING, payload: false });
        dispatch({ type: CUSTOM_FIELDS_LOADED, payload: data });
      })
      .catch(err => {
        dispatch({ type: CUSTOM_FIELDS_LOADING, payload: false });
      });
  };
}

export function updateCompanyCustomFields(customFields) {
  return dispatch => {
    api
      .put(`/companies/field`, customFields)
      .then(({ data }) => {
        dispatch({ type: CUSTOM_FIELDS_LOADED, payload: data });
        dispatchAlert(dispatch, {
          message: `Custom fields has been successfully updated.`,
          type: 'info',
        });
        Mixpanel.track('Company Custom Fields Updated', { customFields: data });
      })
      .catch(err => {
        Mixpanel.track('Company Custom Fields Update Failed', { error: err });
      });
  };
}

export function loadFormCustomFields(key) {
  return dispatch => {
    api
      .get(`/forms/field/${key}`)
      .then(({ data }) => {
        dispatch({ type: CUSTOM_FIELDS_LOADED, payload: data });
      })
      .catch(err => {});
  };
}

export function createCustomField(field) {
  return dispatch => {
    api
      .post(`/companies/custom-field/field`, field)
      .then(({ data }) => {
        dispatch({ type: CUSTOM_FIELD_CREATED, payload: data });
        dispatch(reset('settings-custom-fields'));
        dispatchAlert(dispatch, {
          message: `Custom fields "${data.field_label}" has been successfully created.`,
          type: 'primary',
        });
        Mixpanel.track('Custom Field Created', { field: data });
      })
      .catch(err => {
        Mixpanel.track('Custom Field Creation Failed', { error: err });
      });
  };
}

export function updateCustomField(field) {
  return dispatch => {
    api
      .post(`/companies/custom-field/field`, field)
      .then(({ data }) => {
        dispatch({ type: CUSTOM_FIELD_UPDATED, payload: data });
        dispatch(reset('settings-custom-fields'));
        dispatchAlert(dispatch, {
          message: `Custom fields "${data.field_label}" has been successfully updated.`,
          type: 'primary',
        });
        Mixpanel.track('Custom Field Updated', { field: data });
      })
      .catch(err => {
        Mixpanel.track('Custom Field Update Failed', { error: err });
      });
  };
}

export function deleteCustomField(field) {
  return dispatch => {
    api
      .post(`/companies/custom-field/delete/${field.field_id}`)
      .then(({ data }) => {
        dispatch({ type: CUSTOM_FIELD_DELETED, payload: field });
        dispatch(reset('settings-custom-fields'));
        dispatchAlert(dispatch, {
          message: `Custom fields "${field.field_label}" has been successfully deleted.`,
          type: 'danger',
        });
        Mixpanel.track('Custom Field Deleted', { field });
      })
      .catch(err => {
        Mixpanel.track('Custom Field Deletion Failed', { error: err });
      });
  };
}

export function blockUser(userId) {
  return dispatch => {
    api
      .post(`/users/${userId}/block`)
      .then(({ data }) => {
        dispatch({ type: USER_BLOCKED, payload: userId });
        dispatchAlert(dispatch, {
          message: data.message,
          type: 'info',
        });
        Mixpanel.track('User Blocked', { userId });
      })
      .catch(err => {
        dispatchAlert(dispatch, {
          message: handleErrors(err),
          type: 'danger',
        });
        Mixpanel.track('User Block Failed', { error: err });
      });
  };
}

export function unblockUser(userId) {
  return dispatch => {
    api
      .post(`/users/${userId}/unblock`)
      .then(({ data }) => {
        dispatch({ type: USER_UNBLOCKED, payload: userId });
        dispatchAlert(dispatch, {
          message: data.message,
          type: 'info',
        });
        Mixpanel.track('User Unblocked', { userId });
      })
      .catch(err => {
        dispatchAlert(dispatch, {
          message: handleErrors(err),
          type: 'danger',
        });
        Mixpanel.track('User Unblock Failed', { error: err });
      });
  };
}

export function loadInvestors() {
  return dispatch => {
    dispatch({ type: LOADING_USERS });
    api
      .get('/investors?sort=status,last_login')
      .then(({ data }) => {
        dispatch({
          type: LOADED_USERS,
          payload: data.results,
        });
      })
      .catch(error => {});
  };
}

export function addInvestor(investor) {
  return dispatch => {
    api
      .post('/investors', investor)
      .then(({ data }) => {
        dispatch({ type: USER_ADDED, payload: data });
        dispatchAlert(dispatch, {
          message: `The investor ${investor.fullname} have been successfully added.`,
          type: 'success',
        });
      })
      .catch(error => {});
  };
}

export function updateInvestor(investor) {
  return dispatch => {
    api
      .put(`/investors/${investor.id}`, investor)
      .then(({ data }) => {
        dispatch({ type: USER_UPDATED, payload: data });
        dispatchAlert(dispatch, {
          message: `The investor ${investor.fullname} have been successfully updated.`,
          type: 'primary',
        });
      })
      .catch(error => {});
  };
}

export function blockInvestor(investorId) {
  return dispatch => {
    api
      .post(`/investors/${investorId}/block`)
      .then(({ data }) => {
        dispatch({ type: USER_BLOCKED, payload: investorId });
        dispatchAlert(dispatch, {
          message: data.message,
          type: 'info',
        });
      })
      .catch(err => {
        dispatchAlert(dispatch, {
          message: handleErrors(err),
          type: 'danger',
        });
      });
  };
}

export function unblockInvestor(investorId) {
  return dispatch => {
    api
      .post(`/investors/${investorId}/unblock`)
      .then(({ data }) => {
        dispatch({ type: USER_UNBLOCKED, payload: investorId });
        dispatchAlert(dispatch, {
          message: data.message,
          type: 'info',
        });
      })
      .catch(err => {
        dispatchAlert(dispatch, {
          message: handleErrors(err),
          type: 'danger',
        });
      });
  };
}

export function investorResendInvitations(investors) {
  const ids = investors.map(inv => inv.id);
  return dispatch => {
    api
      .post('/investors/resend', { ids })
      .then(({ data }) =>
        dispatchAlert(dispatch, {
          message: `A new invitation has been sent to ${investors.length} investors`,
          type: 'warning',
        }),
      )
      .catch(error => {});
  };
}

export function investorResendInvitation(investor) {
  return dispatch => {
    api
      .post(`/investors/resend`, { ids: [investor.id] })
      .then(({ data }) => {
        dispatchAlert(dispatch, {
          message: `A new invitation has been sent to ${data.invProfile.fullname}.`,
          type: 'warning',
        });
      })
      .catch(error => {});
  };
}

export function loadAngelInvestors(page) {
  let url = !page ? '/angel-investor?sort=status,last_login' : `/angel-investor?page=${page}&sort=status,last_login`;
  return dispatch => {
    dispatch({ type: LOADING_USERS });
    api
      .get(url)
      .then(({ data }) => {
        dispatch({
          type: LOADED_USERS,
          payload: data.results,
          meta: data._meta,
        });
      })
      .catch(error => {});
  };
}

export function addAngelInvestor(investor) {
  return dispatch => {
    api
      .post('/angel-investor', investor)
      .then(({ data }) => {
        dispatch({ type: USER_ADDED, payload: data });
        dispatchAlert(dispatch, {
          message: `The investor ${investor.email} have been successfully added.`,
          type: 'success',
        });
      })
      .catch(error => {
        dispatchAlert(dispatch, {
          message: `The investor ${investor.email} couldn't be added.`,
          type: 'danger',
        });
      });
  };
}

export function updateAngelInvestor(investor) {
  return dispatch => {
    api
      .put(`/angel-investor/${investor.id}`, investor)
      .then(({ data }) => {
        dispatch({ type: USER_UPDATED, payload: data });
        dispatchAlert(dispatch, {
          message: `The investor ${data.email} have been successfully updated.`,
          type: 'primary',
        });
      })
      .catch(error => {
        dispatchAlert(dispatch, {
          message: `The investor ${investor.email} couldn't be updated.`,
          type: 'danger',
        });
      });
  };
}

export function blockAngelInvestor(investorId) {
  return dispatch => {
    api
      .post(`/angel-investor/${investorId}/block`)
      .then(({ data }) => {
        dispatch({ type: USER_BLOCKED, payload: investorId });
        dispatchAlert(dispatch, {
          message: data.message,
          type: 'info',
        });
      })
      .catch(err => {
        dispatchAlert(dispatch, {
          message: handleErrors(err),
          type: 'danger',
        });
      });
  };
}

export function unblockAngelInvestor(investorId) {
  return dispatch => {
    api
      .post(`/angel-investor/${investorId}/unblock`)
      .then(({ data }) => {
        dispatch({ type: USER_UNBLOCKED, payload: investorId });
        dispatchAlert(dispatch, {
          message: data.message,
          type: 'info',
        });
      })
      .catch(err => {
        dispatchAlert(dispatch, {
          message: handleErrors(err),
          type: 'danger',
        });
      });
  };
}

export function angelInvestorResendInvitation(investor) {
  return dispatch => {
    api
      .post(`/angel-investor/resend`, { ids: investor })
      .then(({ data }) => {
        dispatchAlert(dispatch, {
          message: `A new invitation has been sent to investor.`,
          type: 'warning',
        });
      })
      .catch(error => {});
  };
}

export function deleteAngelInvestor(id) {
  return dispatch => {
    api
      .delete(`/angel-investor/${id}`)
      .then(response => {
        dispatch({ type: DELETE_USER, payload: id });
        dispatchAlert(dispatch, {
          message: `The investor have been successfully deleted.`,
          type: 'danger',
        });
      })
      .catch(error => {});
  };
}

export function angelInvestorResendInvitations(ids) {
  return dispatch => {
    api.post('/angel-investor/resend', { ids }).then(res => {
      dispatchAlert(dispatch, {
        message: `A new invitation has been sent to investor${ids.length > 1 ? 's' : ''} successfully.`,
        type: 'warning',
      });
    });
  };
}
