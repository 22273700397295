import { FilterDTO } from '../../generated/model/models';

export interface FilterData {
  [field: string]: string | number;
}

export namespace QueryFilters {
  export function mapToApiValue(data: FilterData): FilterDTO[] {
    return Object.keys(data)
      .filter(key => data[key] !== null && data[key] !== undefined)
      .map(key => ({ attribute: key, value: String(data[key]) }));
  }
}
