import React from 'react';
import { Icon, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { WrappedFieldInputProps } from 'redux-form';

import { loadingRoles, roleList } from '../state/permissions.selectors';
import { NotificationTypeEnum } from '../../../../shared/enums/notification-type.enum';
import { modalNotification, Spinner, Tooltip } from '../../../../shared/components';
import { deleteRole, duplicateRole, getRoleById } from '../state/permissions.actions';
import { RoleSummaryData } from '../../../../core/models/role/role-summary-data';

// interface ListRolesProps {
//   input: WrappedFieldInputProps;
//   loadPermissions(roleId: string): void;
// }

export function ListRoles(props) {
  const dispatch = useDispatch();
  const roles = useSelector(roleList);
  const loading = useSelector(loadingRoles);

  const onChange = roleId => {
    props.input.onChange(roleId);
    props.loadPermissions(roleId);
  };

  const onRename = roleId => event => {
    dispatch(getRoleById({ roleId }));
    event.stopPropagation();
    event.preventDefault();
  };

  const onRemove = roleId => {
    modalNotification({
      title: 'Are you sure you want to delete this role ?',
      type: NotificationTypeEnum.Error,
      confirmText: 'Delete',
      onConfirm() {
        dispatch(deleteRole({ roleId }));
      },
    });
  };

  const onDuplicate = role => event => {
    modalNotification({
      title: `Are you sure you want to duplicate ${role.name} ?`,
      type: NotificationTypeEnum.Info,
      confirmText: 'Duplicate',
      onConfirm() {
        dispatch(duplicateRole({ roleId: role.id }));
      },
    });
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div className='ListRoles'>
      {loading && (
        <Tabs tabPosition='left' style={{ height: '100%' }}>
          <Tabs.TabPane tab={<Spinner />} key='Owner' />
        </Tabs>
      )}
      <Tabs tabPosition='left' activeKey={props.input.value} type='editable-card' style={{ height: '100%' }} onChange={onChange} onEdit={onRemove}>
        {roles.map(role => (
          <Tabs.TabPane
            key={role.id}
            disabled={role.isOwner}
            closable={!role.isBasic}
            tab={
              <span className='tab-role-panel'>
                <span>
                  <Tooltip title={role.isOwner && 'Owner is almighty ruler who can see and do everything in Pentugram.'}>{role.name}</Tooltip>
                </span>
                {!role.isOwner && (
                  <Tooltip title='duplicate role'>
                    <Icon type='copy' onClick={onDuplicate(role)} />
                  </Tooltip>
                )}
                {!role.isBasic && (
                  <Tooltip title='Rename role'>
                    <Icon type='edit' onClick={onRename(role.id)} />
                  </Tooltip>
                )}
              </span>
            }
          />
        ))}
      </Tabs>
    </div>
  );
}
