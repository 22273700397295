import { Switch } from 'antd';
import React from 'react';

export default function CustomSwitch(props) {
  return <Switch defaultChecked={props.defaultChecked} checked={props.checked} disabled={props.disabled} onChange={props.onChange} className={props.className} />;
}

export function UncontrolledCustomSwitch(props) {
  return <Switch defaultChecked={props.defaultChecked} disabled={props.disabled} onChange={props.onChange} className={props.className} />;
}
