import { PipelineLightDTO } from '../../generated/PipelineLightDTO';
import { SelectItemData } from '../../../shared/interfaces/select-item';

export interface PipelineLightData {
  id: string;
  name: string;
}

export namespace PipelineLightData {
  export function mapToApiValue(data: PipelineLightDTO): SelectItemData {
    return {
      value: data.pipeline_id,
      name: data.name,
    };
  }
}
