import { TaskResponseDTO } from '../../generated/model/taskResponseDTO';
import { SelectItemData } from '../../../shared/interfaces/select-item';
import { IMap } from '../../utils/map-utils';

export enum TaskStatusEnum {
  Done = 'done',
  Undone = 'undone',
  Overdue = 'overdue',
}

export namespace TaskStatusEnum {
  export const displayedItems = [TaskStatusEnum.Done, TaskStatusEnum.Undone, TaskStatusEnum.Overdue];
  export const convertFromApiValue: IMap<TaskResponseDTO.StatusEnum, TaskStatusEnum> = new IMap<TaskResponseDTO.StatusEnum, TaskStatusEnum>(
    [
      [TaskResponseDTO.StatusEnum.NUMBER_0, TaskStatusEnum.Undone],
      [TaskResponseDTO.StatusEnum.NUMBER_1, TaskStatusEnum.Done],
      [TaskResponseDTO.StatusEnum.NUMBER_2, TaskStatusEnum.Overdue],
    ],
    undefined,
  );

  export const convertToApiValue: IMap<TaskStatusEnum, TaskResponseDTO.StatusEnum> = new IMap<TaskStatusEnum, TaskResponseDTO.StatusEnum>(
    [
      [TaskStatusEnum.Undone, TaskResponseDTO.StatusEnum.NUMBER_0],
      [TaskStatusEnum.Done, TaskResponseDTO.StatusEnum.NUMBER_1],
      [TaskStatusEnum.Overdue, TaskResponseDTO.StatusEnum.NUMBER_2],
    ],
    undefined,
  );
  export const toString: IMap<TaskStatusEnum, string> = new IMap<TaskStatusEnum, string>(
    [
      [TaskStatusEnum.Undone, 'Undone'],
      [TaskStatusEnum.Done, 'Done'],
      [TaskStatusEnum.Overdue, 'Overdue'],
    ],
    undefined,
  );

  export const getColor: IMap<TaskStatusEnum, string> = new IMap<TaskStatusEnum, string>(
    [
      [TaskStatusEnum.Undone, '#ffc266'],
      [TaskStatusEnum.Done, '#ff7b65'],
      [TaskStatusEnum.Overdue, '#9adbad'],
    ],
    undefined,
  );
  export function mapToSelectedItem(type: TaskStatusEnum): SelectItemData<TaskStatusEnum> {
    return {
      value: type,
      name: toString.getValue(type),
    };
  }

  export const taskStatus: SelectItemData<TaskStatusEnum>[] = displayedItems.map(item => mapToSelectedItem(item));
}
