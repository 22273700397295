import React, { Component } from 'react';

import './CustomIcon.scss';

export default class CustomIcon extends Component {
  render() {
    const { props } = this;

    return (
      <div className='CustomIcon' onClick={() => props.onClick()} style={props.styles}>
        <i className='material-icons material-details' data-tip={props.tooltip}>
          {props.icon}
        </i>
        <span className='material-details'>{props.label}</span>
      </div>
    );
  }
}
