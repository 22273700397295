import React, { Component } from 'react';
import { CheckBox } from 'SharedComponent/CheckBox/CheckBox';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './pipelinesDialog.scss';

class pipelinesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };

    this.closeModal = this.closeModal.bind(this);
    this.checkDeletion = this.checkDeletion.bind(this);
  }

  closeModal() {
    this.setState({ count: 0 });
    this.props.onDismissDialog();
  }

  handleAction() {
    if (this.props.type === 'delete') {
      this.props.deletePipelines(this.props.pipelines);
    } else
      this.props.updatePipelines({
        pipelines: this.props.pipelines,
        is_portfolio: this.props.isPortfolio ? 0 : 1,
      });

    let gaActions = null;
    let gaCategories = null;

    if (this.props.isPortfolio) {
      gaCategories = appCategories.portfolio;
    } else {
      gaCategories = appCategories.pipeline;
    }
    if (this.props.type === 'delete') {
      gaActions = appActions(`${appTypes.mass}_${appTypes.delete}_${appTypes.modal}_${appTypes.delete}_`, appEvents.clicked).button;
    } else {
      gaActions = appActions(`${appTypes.mass}_${appTypes.move}_${appTypes.modal}_${appTypes.move}_`, appEvents.clicked).button;
    }
    gaEvent(gaCategories, gaActions);
    this.closeModal();
  }

  checkDeletion(val) {
    if (val) {
      this.setState({ count: this.state.count + 1 });
    } else this.setState({ count: this.state.count - 1 });
  }

  onCloseDialogBox() {
    let gaActions = null;
    let gaCategories = null;

    if (this.props.isPortfolio) {
      gaCategories = appCategories.portfolio;
    } else {
      gaCategories = appCategories.pipeline;
    }
    if (this.props.type === 'delete') {
      gaActions = appActions(`${appTypes.mass}_${appTypes.delete}_${appTypes.modal}_${appTypes.cancel}_`, appEvents.clicked).icon;
    } else {
      gaActions = appActions(`${appTypes.mass}_${appTypes.move}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon;
    }
    gaEvent(gaCategories, gaActions);

    this.closeModal();
  }

  onClickCancelBtn() {
    let gaActions = null;
    let gaCategories = null;

    if (this.props.isPortfolio) {
      gaCategories = appCategories.portfolio;
    } else {
      gaCategories = appCategories.pipeline;
    }
    if (this.props.type === 'delete') {
      gaActions = appActions(`${appTypes.mass}_${appTypes.delete}_${appTypes.modal}_${appTypes.cancel}_`, appEvents.clicked).button;
    } else {
      gaActions = appActions(`${appTypes.mass}_${appTypes.move}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button;
    }
    gaEvent(gaCategories, gaActions);

    this.closeModal();
  }

  render() {
    if (!this.props.showDialog) return null;
    const { props, state } = this;
    return (
      <div className='pipelinesDialog'>
        <DialogBox
          isVisible
          onClickOut={this.closeModal}
          onClose={() => this.onCloseDialogBox()}
          className='small-dalog'
          title={props.type === 'delete' ? 'Delete Pipelines' : props.isPortfolio ? 'Move to Pipeline' : 'Move to Portfolio'}
        >
          <div className='p-3'>
            <span className='font-size-16 font-weight-500'>
              Are you sure you want to {props.type === 'delete' ? 'delete' : 'move'} {props.isPortfolio ? 'portfolios' : 'pipelines'}, if sure check these{' '}
              {props.isPortfolio ? 'portfolios' : 'pipelines'} to confirm.
            </span>
          </div>
          <div className='p-3'>
            <ul>
              {props.pipelines.map(pipeline => {
                return (
                  <div key={pipeline.pipeline_id} className='display-flex-start'>
                    <CheckBox onChange={e => this.checkDeletion(e.nativeEvent.target.checked)} />
                    <span className='ml-3'>{pipeline.title}</span>
                  </div>
                );
              })}
            </ul>
          </div>
          <div className='display-flex-end'>
            <div className='m-3'>
              <SubmitButton
                label='Cancel'
                icon='icon-close'
                style={{
                  width: '150px',
                  color: '#53627c',
                  backgroundColor: '#fff',
                  border: '1px solid #e1e4e8',
                }}
                onClick={() => this.onClickCancelBtn()}
              />
            </div>
            <div className='m-3'>
              <SubmitButton
                label={props.type === 'delete' ? 'Delete all' : 'Move all'}
                icon={props.type === 'delete' ? 'icon-trash' : 'icon-cursor-move'}
                className={state.count === props.pipelines.length ? undefined : 'disable-button'}
                style={{
                  width: '150px',
                  backgroundColor: props.type === 'delete' ? '#ff8181' : '#03a6ff',
                }}
                onClick={() => this.handleAction()}
              />
            </div>
          </div>
        </DialogBox>
      </div>
    );
  }
}

export default pipelinesDialog;
