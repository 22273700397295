import moment from 'moment';
import React, { Component } from 'react';
import FormsTable from '../FormsTable/FormsTable.js';
import defaultImage from 'Assets/img/default.png';
import { Modal, Typography } from 'antd';

const BASE_URL = process.env.PENTUGRAM_URL;
export default class FormList extends Component {
  parseForms(forms) {
    return forms.map(form => {
      const link = `${BASE_URL}/forms/${form.form_key}`;
      return {
        ...form,
        createdAt: moment(form.created_at * 1000).format('lll'),
        link: link,
        iframe: `<iframe src="${link}"></iframe>`,
        deal_number: form.deal_number ? form.deal_number : 0,
        logo: form.owner && form.owner.image ? form.owner.image : defaultImage,
        owner: form.owner && form.owner.fullname ? form.owner.fullname : 'N/A',
      };
    });
  }

  info(deal, field, title) {
    Modal.info({
      title,
      okType: 'default',
      okText: 'Cancel',
      content: (
        <div>
          <Typography.Paragraph copyable={{ text: deal[field] }}>
            <Typography.Text code>{deal[field]}</Typography.Text>
          </Typography.Paragraph>
        </div>
      ),
      onOk() {},
    });
  }

  showDeleteConfirm = deal => {
    let that = this;
    Modal.confirm({
      title: (
        <span>
          Are you sure you want to delete Form "<strong>{deal.title}</strong>" ?
        </span>
      ),
      content: null,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        that.props.remove(deal);
      },
      onCancel() {},
    });
  };

  showDuplicateConfirm = deal => {
    let that = this;
    Modal.confirm({
      title: (
        <span>
          Are you sure you want to duplicate this Form "<strong>{deal.title}</strong>" ?
        </span>
      ),
      content: null,
      okText: 'Duplicate',
      okType: 'info',
      cancelText: 'Cancel',
      onOk() {
        that.props.duplicate(deal);
      },
      onCancel() {},
    });
  };

  render() {
    const forms = this.parseForms(this.props.forms);
    return (
      <div className='FormList'>
        <FormsTable
          isLoading={this.props.loading}
          items={forms}
          onShare={deal => this.info(deal, 'link', 'Form Link')}
          onEdit={this.props.onEdit}
          onIntegrate={deal => this.info(deal, 'iframe', 'Integrate HTML code')}
          onUpload={this.props.export}
          onDelete={this.showDeleteConfirm}
          onDuplicate={this.showDuplicateConfirm}
          // showActions={this.props.showActions}
        />
      </div>
    );
  }
}
