import React, { useEffect, useState } from 'react';
import { Field, change } from 'redux-form';
import { useDispatch } from 'react-redux';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { toolbarConfig } from './toolbarConfig';
import ColorPicker from './ColorPicker.js';
import api from 'ReduxActions/api';
import { at } from 'Utils/objectUtils';
import { Avatar } from 'antd';
import { UploadField } from '../../../v2.0/shared/components';

import './TextEditor.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function TextEditor(props) {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [editorState, setEditorState] = useState(initEditorState(props.input.value));

  useEffect(() => {
    props.mentions && getUsers();
  }, []);

  const OptionMentions = item => (
    <div>
      <span className='mr-rem-0_5'>
        <Avatar shape='circle' src={item.image} icon={item.image || 'user'} />
      </span>
      {item.fullname}
    </div>
  );
  const getUsers = () => {
    api.get('/users').then(({ data }) => {
      setUsers(
        data
          ? (data.results || []).filter(it => it.status == 1).map(user => ({ text: OptionMentions(user.profile), value: at(user, 'profile.fullname'), url: user.id.toString() }))
          : [],
      );
    });
  };

  function initEditorState(text) {
    const html = text || '<p></p>';
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  }

  function handleChange(editorState) {
    setEditorState(editorState);
    changeValue(editorState);
  }

  function changeValue(editorState) {
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    props.input.onChange(value);
    if (props.mentions) {
      dispatch(change(props.formName, props.mentionsOutputName, extractTags(convertToRaw(editorState.getCurrentContent())) || []));
    }
    if (props.handleChange) {
      props.handleChange(value);
    }
  }

  const getUrlId = str => {
    return str.substring(str.lastIndexOf('/') + 1);
  };

  const extractTags = ({ entityMap }) => {
    return Object.keys(entityMap)
      .filter(k => entityMap[k].type === 'MENTION')
      .map(key => getUrlId(entityMap[key].data.url));
  };

  let listOptions = props.toolbarCustomButtons || [];
  const toolConfig = toolbarConfig;
  if (props.colorPicker) {
    toolConfig.colorPicker = { component: props => <ColorPicker toolbarOnFocus={props.toolbarOnFocus} {...props} /> };
  } else {
    toolConfig.colorPicker = { component: undefined };
  }

  return (
    <div id='react-wysiwyg' className='TextEditor'>
      <Editor
        editorState={editorState}
        wrapperClassName={props.toolbarPosition === 'top' ? 'demo-wrapper-top' : 'demo-wrapper'}
        editorClassName='demo-editor'
        placeholder={props.placeholder}
        toolbarClassName='toolbar-class'
        onEditorStateChange={editorState => handleChange(editorState)}
        toolbar={toolConfig}
        toolbarOnFocus={props.toolbarOnFocus}
        toolbarCustomButtons={listOptions}
        hashtag={{}}
        mention={
          props.mentions
            ? {
                separator: ' ',
                trigger: '@',
                data: 'hello',
                suggestions: users,
              }
            : {}
        }
      />
      {props.uploadFiles && (
        <div className='field-control mt-2'>
          <Field component={UploadField} name={props.filesOutputName} placeholder='Attach files' />
        </div>
      )}

      {props.mentions && <Field component={() => <div />} name={props.mentionsOutputName} />}
    </div>
  );
}

export default TextEditor;
