import React from 'react';
import ReactEcharts from 'echarts-for-react';

export function FunnelChart({ item }) {
  function getTitlePos(position, coords) {
    switch (position) {
      case 'custom':
        return { ...coords };
      case 'top_center':
        return { top: 10, left: 'center' };
      case 'top_right':
        return { top: 10, right: 10 };
      case 'top_left':
        return { top: 10, left: 10 };
      case 'bottom_center':
        return { top: 'bottom', left: 'center' };
      case 'bottom_right':
        return { bottom: 10, right: 10 };
      case 'bottom_left':
        return { bottom: 10, left: 10 };
      default:
        return { left: 'left' };
    }
  }

  function getLegendPos(position, coords) {
    switch (position) {
      case 'custom':
        return { ...coords };
      case 'top_center':
        return { top: 20, left: 'center' };
      case 'top_left':
        return { top: 20, left: 'left' };
      case 'top_right':
        return { top: 20, right: 'left' };
      case 'bottom_left':
        return { top: '85%', left: 'left' };
      case 'bottom_center':
        return { top: '85%', left: 'center' };
      case 'bottom_right':
        return { top: '85%', right: 'left' };
      default:
        return { left: 'center' };
    }
  }

  const onChartReady = chart => {
    chart.setOption(getOption());
  };

  function getOption() {
    return {
      title: {
        text: item.title,
        left: 'left',
        ...getTitlePos(item.title_position, item.titlePos),
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c}',
      },
      toolbox: {},
      legend: {
        orient: item.legend_horizontal_orient !== undefined ? (item.legend_horizontal_orient ? 'horizontal' : 'vertical') : 'horizontal',
        data: item.data.map(it => it.name),
        top: 'bottom',
        left: 'center',
        ...getLegendPos(item.legend_position, item.legendPos),
        ...item.legend,
      },

      series: [
        {
          name: 'Stage:',
          type: 'funnel',
          left: '10%',
          top: 60,
          //x2: 80,
          bottom: 60,
          ...item.grid,
          width: '80%',
          // height: {totalHeight} - y - y2,
          min: 0,
          // max: 100,
          minSize: '0%',
          maxSize: '100%',
          sort: 'descending',
          gap: 2,
          label: {
            show: true,
            position: 'inside',
            formatter: '{c}',
            color: '#fff',
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 1,
              type: 'solid',
            },
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1,
          },
          emphasis: {
            label: {
              fontSize: 20,
            },
          },
          data: item.data,
          color: item.properties && item.properties.colorPalette,
        },
      ],
    };
  }

  return (
    <div className='FunnelChart'>
      <ReactEcharts
        theme='light'
        style={{ height: '400px', width: '100%' }}
        option={{}}
        opts={{ renderer: 'svg' }}
        onChartReady={onChartReady}
        onEvents={{
          click: () => {},
          legendselectchanged: () => {},
        }}
      />
    </div>
  );
}
