import React from 'react';
import HeaderBar from './header-bar';
import Select from 'react-select';
import { DatePicker as ReactDatePicker } from 'antd';

import ArDatePicker from './ArDatePicker';
import moment from 'moment';
import CheckboxGroup from 'SharedComponent/CheckboxGroup';
import { RadioButtonGroup } from 'SharedComponent/RadioButton/RadioButtonGroup';
import FileComponent from './file';
import TextAreaAutosize from 'react-textarea-autosize';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { convertApiValueToRTLBoolean } from 'Enums/languages';

let FormElements = {};

const defaultStyle = {
  border: '1px solid #edeff1',
  borderRadius: '3px',
  margin: '16px 0px 16px 0px',
};

const Error = ({ touched, error, warning }) => (
  <div>
    {touched &&
      ((error && (
        <div className='display-flex-center'>
          <span className='material-icons error-icon-required-form mr-2'>error</span>
          <span className='error-message-form'>{error}</span>
        </div>
      )) ||
        (warning && <span className='error-message-form'>{warning}</span>))}
  </div>
);
class Header extends React.Component {
  render() {
    let headerClasses = 'dynamic-input ' + this.props.data.element + '-input';
    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            {this.props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <div className='on-hover-hide'>
          <h3
            dangerouslySetInnerHTML={{
              __html: this.props.data.content,
            }}
          />
        </div>
      </div>
    );
  }
}

class Paragraph extends React.Component {
  render() {
    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }
    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            {this.props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <div className='on-hover-hide'>
          <p
            dangerouslySetInnerHTML={{
              __html: this.props.data.content,
            }}
          />
        </div>
      </div>
    );
  }
}

class Label extends React.Component {
  // mixins: [SortableItemMixin],
  render() {
    let classNames = 'static';
    if (this.props.data.bold) {
      classNames += ' bold';
    }
    if (this.props.data.italic) {
      classNames += ' italic';
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            {this.props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <div className='on-hover-hide'>
          <label
            className={classNames}
            dangerouslySetInnerHTML={{
              __html: this.props.data.content,
            }}
          />
        </div>
      </div>
    );
  }
}

class LineBreak extends React.Component {
  // mixins: [SortableItemMixin],
  render() {
    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            {this.props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <hr />
      </div>
    );
  }
}

class TextInput extends React.Component {
  // mixins: [SortableItemMixin],
  render() {
    let props = {};
    props.type = 'text';
    props.className = 'form-control';
    props.name = this.props.data.field_name;

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = 'child_ref_' + this.props.data.field_name;
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    if (this.props.read_only) {
      props.disabled = 'disabled';
    }

    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            {this.props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <div className='form-group'>
          <div className='display-flex-between'>
            <label className='display-flex-start on-hover-hide'>
              <div
                className='on-hover-hide'
                dangerouslySetInnerHTML={{
                  __html: this.props.data.label,
                }}
              />

              {this.props.data.hasOwnProperty('required') && this.props.data.required === true && !this.props.read_only && (
                <div className='on-hover-hide' style={{ color: '#ff8181' }}>
                  &nbsp;&#9679;
                </div>
              )}
            </label>
            {this.props.meta && <Error touched={this.props.meta.touched} error={this.props.meta.error} warning={this.props.meta.warning} />}
          </div>
          <input
            {...props}
            value={this.props.input && this.props.input.value && this.props.input.value.response}
            onChange={event =>
              this.props.input.onChange({
                question: this.props.data.title,
                response: event.target.value,
              })
            }
          />
        </div>
      </div>
    );
  }
}

class NumberInput extends React.Component {
  // mixins: [SortableItemMixin],
  render() {
    let props = {};
    props.type = 'number';
    props.className = 'form-control';
    props.name = this.props.data.field_name;

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = 'child_ref_' + this.props.data.field_name;
    }

    if (this.props.read_only) {
      props.disabled = 'disabled';
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            {this.props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <div className='form-group'>
          <div className='display-flex-between'>
            <label className='display-flex-start'>
              <span
                className='on-hover-hide'
                dangerouslySetInnerHTML={{
                  __html: this.props.data.label,
                }}
              />

              {this.props.data.hasOwnProperty('required') && this.props.data.required === true && !this.props.read_only && (
                <span className='on-hover-hide' style={{ color: '#ff8181' }}>
                  &nbsp;&#9679;
                </span>
              )}
            </label>
            {this.props.meta && <Error touched={this.props.meta.touched} error={this.props.meta.error} warning={this.props.meta.warning} />}
          </div>
          <input
            {...props}
            value={this.props.input && this.props.input.value && this.props.input.value.response}
            onChange={event =>
              this.props.input.onChange({
                question: this.props.data.title,
                response: event.target.value,
              })
            }
          />
        </div>
      </div>
    );
  }
}

class TextArea extends React.Component {
  // mixins: [SortableItemMixin],
  render() {
    let props = {};
    props.className = 'form-control';
    props.name = this.props.data.field_name;

    if (this.props.read_only) {
      props.disabled = 'disabled';
    }

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = 'child_ref_' + this.props.data.field_name;
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            {this.props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <div className='form-group'>
          <div className='display-flex-between'>
            <label className='display-flex-start'>
              <span
                className='on-hover-hide'
                dangerouslySetInnerHTML={{
                  __html: this.props.data.label,
                }}
              />
              {this.props.data.hasOwnProperty('required') && this.props.data.required === true && !this.props.read_only && (
                <span className='on-hover-hide' style={{ color: '#ff8181' }}>
                  &nbsp;&#9679;
                </span>
              )}
            </label>
            {this.props.meta && <Error touched={this.props.meta.touched} error={this.props.meta.error} warning={this.props.meta.warning} />}
          </div>
          <TextAreaAutosize
            type='text'
            className='text-area-details'
            cachemeasurements={true}
            value={this.props.input && this.props.input.value && this.props.input.value.response}
            onChange={event =>
              this.props.input.onChange({
                question: this.props.data.title,
                response: event.target.value,
              })
            }
          />
        </div>
      </div>
    );
  }
}

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    var value, internalValue;

    if (props.data.defaultToday && (props.defaultValue === '' || props.defaultValue === undefined)) {
      value = moment().format('MM/DD/YYYY');
      internalValue = moment();
    } else {
      value = props.defaultValue;

      if (props.defaultValue !== '' && props.defaultValue !== undefined) {
        internalValue = moment(value, 'MM/DD/YYYY');
      }
    }

    // if(this.props.input.value && this.props.input.value.response) {
    //   value= moment(this.props.input.value.response).format("MM/DD/YYYY")
    // }

    this.state = {
      value: value,
      internalValue: internalValue,
      placeholder: 'mm/dd/yyyy',
      defaultToday: props.data.defaultToday,
    };
  }

  handleChange = dt => {
    if (dt && dt.target) {
      var placeholder = dt && dt.target && dt.target.value === '' ? 'mm/dd/yyyy' : '';
      var formattedDate = dt.target.value ? moment(dt.target.value).format('YYYY-MM-DD') : '';
      this.props.input.onChange({
        question: this.props.data.title,
        response: formattedDate,
      });
      this.setState({
        value: formattedDate,
        internalValue: formattedDate,
        placeholder: placeholder,
      });
    } else {
      this.props.input.onChange({
        question: this.props.data.title,
        response: dt ? dt.format('MM/DD/YYYY') : '',
      });
      this.setState({
        value: dt ? dt.format('MM/DD/YYYY') : '',
        internalValue: dt,
        placeholder: placeholder,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.data.defaultToday && !this.state.defaultToday) {
      this.state.value = moment().format('MM/DD/YYYY');
      this.state.internalValue = moment(this.state.value);
    } else if (!this.props.data.defaultToday && this.state.defaultToday) {
      this.state.value = '';
      this.state.internalValue = undefined;
    }

    this.state.defaultToday = this.props.data.defaultToday;
  }

  toEngLocale = dt => {
    const numberMap = {
      '١': '1',
      '٢': '2',
      '٣': '3',
      '٤': '4',
      '٥': '5',
      '٦': '6',
      '٧': '7',
      '٨': '8',
      '٩': '9',
      '٠': '0',
    };

    let output = dt
      .replace(/[١٢٣٤٥٦٧٨٩٠]/g, function (match) {
        return numberMap[match];
      })
      .replace(/،/g, ',');

    return output;
  };

  render() {
    let props = {};
    props.type = 'date';
    props.className = 'form-control';
    props.name = this.props.data.field_name;

    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = 'child_ref_' + this.props.data.field_name;
    }

    if (this.props.read_only) {
      props.disabled = 'disabled';
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            {this.props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <div className='form-group'>
          <div className='display-flex-between'>
            <label className='display-flex-start'>
              <span
                className='on-hover-hide'
                dangerouslySetInnerHTML={{
                  __html: this.props.data.label,
                }}
              />
              {this.props.data.hasOwnProperty('required') && this.props.data.required === true && !this.props.read_only && (
                <span className='on-hover-hide' style={{ color: '#ff8181' }}>
                  &nbsp;&#9679;
                </span>
              )}
            </label>
            {this.props.meta && <Error touched={this.props.meta.touched} error={this.props.meta.error} warning={this.props.meta.warning} />}
          </div>
          <div>
            {/* {this.props.data.readOnly && (
              <input
                type="text"
                name={props.name}
                ref={props.ref}
                readOnly="true"
                dateFormat="MM/DD/YYYY"
                placeholder={this.state.placeholder}
                value={this.state.value}
                className="form-control"
              />
            )}
            {iOS && !this.props.data.readOnly && (
              <input
                type="date"
                name={props.name}
                ref={props.ref}
                onChange={this.handleChange}
                dateFormat="MM/DD/YYYY"
                placeholder={this.state.placeholder}
                value={this.state.value}
                className="form-control"
              />
            )} */}
            {!iOS &&
              !this.props.data.readOnly &&
              (this.props.rtl ? (
                <ArDatePicker
                  format='MMMM Do YYYY'
                  value={
                    this.props.input && this.props.input.value ? moment(this.toEngLocale(this.props.input && this.props.input.value && this.props.input.value.response)) : null
                  }
                  placeholder='MM/DD/YYYY'
                  handleChange={this.handleChange.bind(this)}
                  className='form-control'
                  size='large'
                  disabled={this.props.disabled}
                  showTime={false}
                  internalValue={this.state.internalValue}
                />
              ) : (
                <ReactDatePicker
                  format='MMMM Do YYYY'
                  value={this.props.input && this.props.input.value ? moment(this.props.input && this.props.input.value && this.props.input.value.response) : null}
                  placeholder='MM/DD/YYYY'
                  onChange={this.handleChange.bind(this)}
                  className='form-control'
                  size='large'
                  disabled={this.props.disabled}
                  showTime={false}
                  defaultValue={this.state.internalValue}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

class Dropdown extends React.Component {
  // mixins: [SortableItemMixin],
  render() {
    let props = {};
    props.className = 'form-control';
    props.name = this.props.data.field_name;

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = 'child_ref_' + this.props.data.field_name;
    }

    if (this.props.read_only) {
      props.disabled = 'disabled';
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            {this.props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <div className='form-group'>
          <label>
            <span
              className='on-hover-hide'
              dangerouslySetInnerHTML={{
                __html: this.props.data.label,
              }}
            />
            {this.props.data.hasOwnProperty('required') && this.props.data.required === true && !this.props.read_only && (
              <span className='on-hover-hide' style={{ color: '#ff8181' }}>
                &nbsp;&#9679;
              </span>
            )}
          </label>
          <select {...props}>
            {this.props.data.options.map(function (option) {
              let this_key = 'preview_' + option.key;
              return (
                <option value={option.value} key={this_key}>
                  {option.text}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
}

class Tags extends React.Component {
  state = {
    value: this.props.defaultValue !== undefined ? this.props.defaultValue.split(',') : [],
  };

  handleChange = e => {
    this.props.input.onChange(e);
    this.setState({ value: e });
  };

  render() {
    const countries = this.props.countries || [];
    let options = [];
    let props = {};
    if (this.props.data.listOfCountries) {
      options = countries.map(option => {
        option.label = this.props.rtl ? option.ar_name : option.name;
        option.question = this.props.data.title;
        option.response = option.country_id;

        return option;
      });
    } else {
      options = this.props.data.options.map(option => {
        option.label = option.text;
        option.question = this.props.data.title;
        option.response = option.label;

        return option;
      });
    }
    if (this.props.data.listOfCountries) props.multi = false; //this.props.data.multiple;
    props.name = this.props.data.field_name;
    props.onChange = this.handleChange;

    props.options = options;

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <div className='form-group'>
          <div className='display-flex-between'>
            <label className='display-flex-start'>
              <div
                className='on-hover-hide'
                dangerouslySetInnerHTML={{
                  __html: this.props.data.label,
                }}
              />
              {this.props.data.hasOwnProperty('required') && this.props.data.required === true && !this.props.read_only && (
                <div className='on-hover-hide' style={{ color: '#ff8181' }}>
                  &nbsp;&#9679;
                </div>
              )}
            </label>
            {this.props.meta && <Error touched={this.props.meta.touched} error={this.props.meta.error} warning={this.props.meta.warning} />}
          </div>
          <Select value={this.props.input && this.props.input.value} {...props} rtl={this.props.rtl} placeholder='' />
        </div>
      </div>
    );
  }
}

const Checkboxes = props => {
  let language = useSelector(state => get(state, 'settings.formBuilder.editing.language') || 'en');
  let self = this;
  let classNames = 'checkbox-label';
  if (props.data.inline) {
    classNames += ' option-inline';
  }

  let baseClasses = 'SortableItem rfb-item';
  if (props.data.pageBreakBefore) {
    baseClasses += ' alwaysbreak';
  }
  if (props.isDragging) {
    baseClasses += ' is-placeholder';
  }
  let options = props.data.options.map(option => {
    return {
      ...option,
      label: option.text,
      value: option.text,
    };
  });

  return (
    <div className={baseClasses} style={defaultStyle}>
      {!props.mutable && (
        <div>
          {props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
          <HeaderBar
            parent={props.parent}
            editModeOn={props.editModeOn}
            data={props.data}
            onDestroy={props._onDestroy}
            onEdit={props.onEdit}
            static={props.data.static}
            required={props.data.required}
          />
        </div>
      )}
      <div className='form-group'>
        <div className='display-flex-between'>
          <label className='display-flex-start'>
            <span
              className='on-hover-hide'
              dangerouslySetInnerHTML={{
                __html: props.data.label,
              }}
            />
            {props.data.hasOwnProperty('required') && props.data.required === true && !props.read_only && (
              <span className='on-hover-hide' style={{ color: '#ff8181' }}>
                &nbsp;&#9679;
              </span>
            )}
          </label>
        </div>
        <CheckboxGroup
          name={props.name}
          title={props.data.title}
          options={options}
          validate={props.validate}
          className={classNames}
          isInline={props.data.inline}
          directionRight={convertApiValueToRTLBoolean(language)}
          style={{ margin: '.5em' }}
        />
      </div>
    </div>
  );
};

const RadioButtons = props => {
  let language = useSelector(state => get(state, 'settings.formBuilder.editing.language') || 'en');
  let self = this;
  let classNames = 'radio-label';
  if (props.data.inline) {
    classNames += ' option-inline';
  }

  let baseClasses = 'SortableItem rfb-item';
  if (props.data.pageBreakBefore) {
    baseClasses += ' alwaysbreak';
  }
  if (props.isDragging) {
    baseClasses += ' is-placeholder';
  }

  let options = props.data.options.map(option => {
    return {
      id: option.key,
      label: option.text,
      value: option.text,
    };
  });

  return (
    <div className={baseClasses} style={defaultStyle}>
      {!props.mutable && (
        <div>
          {props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
          <HeaderBar
            parent={props.parent}
            editModeOn={props.editModeOn}
            data={props.data}
            onDestroy={props._onDestroy}
            onEdit={props.onEdit}
            static={props.data.static}
            required={props.data.required}
          />
        </div>
      )}
      <div className='form-group'>
        <div className='display-flex-between'>
          <label className='display-flex-start'>
            <span
              className='on-hover-hide'
              dangerouslySetInnerHTML={{
                __html: props.data.label,
              }}
            />
            {props.data.hasOwnProperty('required') && props.data.required === true && !props.read_only && (
              <span className='on-hover-hide' style={{ color: '#ff8181' }}>
                &nbsp;&#9679;
              </span>
            )}
          </label>
          {props.meta && <Error touched={props.meta.touched} error={props.meta.error} warning={props.meta.warning} />}
        </div>
        <RadioButtonGroup
          name={props.name}
          title={props.data.title}
          isInline={props.data.inline}
          options={options}
          directionRight={convertApiValueToRTLBoolean(language)}
          validate={props.validate}
        />
      </div>
    </div>
  );
};

class Image extends React.Component {
  render() {
    var style = this.props.data.center ? { textAlign: 'center' } : '';

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    return (
      <div className={baseClasses} style={{ ...defaultStyle, textAlign: 'center' }}>
        {!this.props.mutable && (
          <HeaderBar
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            data={this.props.data}
            onDestroy={this.props._onDestroy}
            onEdit={this.props.onEdit}
            required={this.props.data.required}
          />
        )}
        {this.props.data.src && <img src={this.props.data.src} width={this.props.data.width} height={this.props.data.height} />}
        {!this.props.data.src && <div className='no-image'>No Image</div>}
      </div>
    );
  }
}

// class Rating extends React.Component {
//   render() {
//     let props = {};
//     props.name = this.props.data.field_name;
//     props.ratingAmount = 5;

//     if (this.props.mutable) {
//       props.rating =
//         this.props.defaultValue !== undefined && this.props.defaultValue.length
//           ? parseFloat(this.props.defaultValue, 10)
//           : 0;
//       props.editing = true;
//       props.ref = "child_ref_" + this.props.data.field_name;
//     }

//     let baseClasses = "SortableItem rfb-item";
//     if (this.props.data.pageBreakBefore) {
//       baseClasses += " alwaysbreak";
//     }
//     if (this.props.isDragging) {
//       baseClasses += " is-placeholder";
//     }

//     return (
//       <div className={baseClasses} style={defaultStyle}>
//         {!this.props.mutable && (
//           <div>
//             {this.props.data.pageBreakBefore && (
//               <div className="preview-page-break">Page Break</div>
//             )}
//             <HeaderBar
//               parent={this.props.parent}
//               editModeOn={this.props.editModeOn}
//               data={this.props.data}
//               onDestroy={this.props._onDestroy}
//               onEdit={this.props.onEdit}
//               static={this.props.data.static}
//               required={this.props.data.required}
//             />
//           </div>
//         )}
//         <div className="form-group">
//           <label>
//             <span
//               dangerouslySetInnerHTML={{
//                 __html: (this.props.data.label)
//               }}
//             />
//             {this.props.data.hasOwnProperty("required") &&
//               this.props.data.required === true &&
//               !this.props.read_only && (
//                 <span className="on-hover-hide" style={{ color: "#ff8181" }}>
//                   &nbsp;&#9679;
//                 </span>
//               )}
//           </label>
//           <StarRating {...props} />
//         </div>
//       </div>
//     );
//   }
// }

class HyperLink extends React.Component {
  render() {
    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            {this.props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <div className='form-group'>
          <a target='_blank' href={this.props.data.href}>
            {this.props.data.content}
          </a>
        </div>
      </div>
    );
  }
}

class Download extends React.Component {
  render() {
    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) {
      baseClasses += ' alwaysbreak';
    }
    if (this.props.isDragging) {
      baseClasses += ' is-placeholder';
    }

    return (
      <div className={baseClasses} style={defaultStyle}>
        {!this.props.mutable && (
          <div>
            {this.props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
            <HeaderBar
              parent={this.props.parent}
              editModeOn={this.props.editModeOn}
              data={this.props.data}
              onDestroy={this.props._onDestroy}
              onEdit={this.props.onEdit}
              static={this.props.data.static}
              required={this.props.data.required}
            />
          </div>
        )}
        <div className='form-group'>
          <a className='on-hover-hide' href={this.props.download_path + '?id=' + this.props.data.file_path}>
            {this.props.data.content}
          </a>
        </div>
      </div>
    );
  }
}

const Camera = props => {
  let language = useSelector(state => get(state, 'settings.formBuilder.editing.language') || 'en');
  let baseClasses = 'SortableItem rfb-item';
  if (props.data.pageBreakBefore) {
    baseClasses += ' alwaysbreak';
  }
  if (props.isDragging) {
    baseClasses += ' is-placeholder';
  }

  return (
    <div className={baseClasses} style={defaultStyle}>
      {!props.mutable && (
        <div>
          {props.data.pageBreakBefore && <div className='preview-page-break'>Page Break</div>}
          <HeaderBar
            parent={props.parent}
            editModeOn={props.editModeOn}
            data={props.data}
            onDestroy={props._onDestroy}
            onEdit={props.onEdit}
            static={props.data.static}
            required={props.data.required}
          />
        </div>
      )}

      <div className='form-group'>
        <div className='display-flex-between'>
          <label className='display-flex-start'>
            <span
              className='on-hover-hide'
              dangerouslySetInnerHTML={{
                __html: props.data.label,
              }}
            />
            {props.data.hasOwnProperty('required') && props.data.required === true && !props.read_only && (
              <span className='on-hover-hide' style={{ color: '#ff8181' }}>
                &nbsp;&#9679;
              </span>
            )}
          </label>
          {props.meta && <Error touched={props.meta.touched} error={props.meta.error} warning={props.meta.warning} />}
        </div>
        <FileComponent {...props} title={props.data.title} language={language} />
      </div>
    </div>
  );
};

FormElements.Header = Header;
FormElements.Paragraph = Paragraph;
FormElements.Label = Label;
FormElements.LineBreak = LineBreak;
FormElements.TextInput = TextInput;
FormElements.NumberInput = NumberInput;
FormElements.TextArea = TextArea;
FormElements.Dropdown = Dropdown;
// FormElements.Signature = Signature;
FormElements.Checkboxes = Checkboxes;
FormElements.DatePicker = DatePicker;
FormElements.RadioButtons = RadioButtons;
FormElements.Image = Image;
// FormElements.Rating = Rating;
FormElements.Tags = Tags;
FormElements.HyperLink = HyperLink;
FormElements.Download = Download;
FormElements.Camera = Camera;
// FormElements.Range = Range;

module.exports = FormElements;
