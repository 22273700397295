import debounce from 'lodash/debounce';

export default function debounceAction(action, wait, options) {
  const debounced = debounce((dispatch, actionArgs) => dispatch(action(...actionArgs)), wait, options);

  const thunk = (...actionArgs) => dispatch => debounced(dispatch, actionArgs);

  thunk.cancel = debounced.cancel;
  thunk.flush = debounced.flush;

  return thunk;
}
