import { browserHistory } from 'react-router';
import api from './api';
import { dispatchAlert } from './notificationActions';
import get from 'lodash/get';
import * as Sentry from '@sentry/browser';
import { Mixpanel } from 'App/app';

export const ERROR = 'auth:error';
export const INFO = 'auth:info';
export const DISCARD_ERROR = 'auth:discard:error';
export const CLEAR_STORE = 'clear:sotre';
export const SIGNED_OUT = 'auth:signedOut';
export const SIGNED_IN = 'auth:signedIn';
export const LOADING = 'auth:loading';
export const UPDATING = 'auth:profile:updating';
export const UPDATED = 'auth:profile:updated';
export const CHANGING_PASSWORD = 'auth:profile:changing:password';
export const PASSWORD_CHANGED = 'auth:profile:change:password';
export const CHANGING_PASSWORD_FAILED = 'auth:profile:change:password:failed';
export const USER_REGISTRED = 'auth:user:registred';
export const USER_REGISTRED_INFO = 'auth:user:registred';
export const GET_USERNAME_VALUE = 'get:username:value';
export const USER_ATTRIBUTES_UPDATED = 'auth:user:attributes:updated';
export const TOTAL_USERS_UPDATED = 'auth:user:total:count:updated';
import { get as getV2 } from '../v2.0/core/services/helpers/base-http';

export function authError(error) {
  return {
    type: ERROR,
    payload: error,
  };
}

function authInfo(info) {
  return {
    type: INFO,
    payload: info,
  };
}

export function handleErrors(error) {
  const message = get(error, 'response.data[0].message');
  const altMessage = get(error, 'response.data.message', 'An error has been detected');

  return message || altMessage;
}

export const actions = {
  resetPassword({ email }) {
    return function (dispatch) {
      api
        .post('me/resetpassword', { email })
        .then(() => {
          // browserHistory.push("/signin");
          dispatch(authInfo('Your password has been reset successfully! Check your email address.'));
        })
        .catch(error => {
          dispatch(authError((error && error.response && error.response.data && error.response.data.message) || 'An error has been detected'));
        });
    };
  },

  checkToken() {
    const storedToken = localStorage.getItem('token');

    if (!storedToken) {
      browserHistory.push('/signin');
      return { type: ERROR };
    }

    return dispatch => {
      api
        .get('/me')
        .then(({ data }) => {
          dispatch({
            type: SIGNED_IN,
            payload: data,
          });
          Sentry.setUser({
            id: data.id,
            email: data.email,
          });
          Mixpanel.identify(`email : ${data.email} \n company : ${data.company.company_id}`);
          localStorage.setItem('token', data.token);
        })
        .catch(() => {
          browserHistory.push('/signin');

          // dispatch(authError("Bad Login Info"));
        });
    };
  },

  signIn({ email, password }) {
    return dispatch => {
      dispatch({ type: LOADING, payload: true });
      api
        .post('/authentication', { email, password })
        .then(({ data }) => {
          const userData = { ...data, username: email };
          localStorage.setItem('token', data.token);
          dispatch({ type: LOADING, payload: false });
          // after log-in or sign-up:
          Mixpanel.track('Logged In', { email: email });
          browserHistory.push('/');
        })
        .catch(error => {
          dispatch({ type: LOADING, payload: false });
          if (localStorage.getItem('token')) localStorage.removeItem('token');
          browserHistory.push('/signin');
          Mixpanel.track('Failed Log In', { email: email });
          dispatch(authError(handleErrors(error)));
        });
    };
  },

  signinWithGoogle(responseGoogle) {
    return dispatch => {
      dispatch({ type: LOADING, payload: true });
      api
        .post('/authentication', { source: 1, token: responseGoogle.tokenId })
        .then(({ data }) => {
          localStorage.setItem('token', data.token);
          dispatch({ type: LOADING, payload: false });
          Mixpanel.track('Logged In google account', { email: responseGoogle.profileObj.email });
          browserHistory.push('/');
        })
        .catch(error => {
          dispatch({ type: LOADING, payload: false });
          browserHistory.push('/signin');
          Mixpanel.track('Failed Log In google account', { email: responseGoogle.profileObj.email });
          dispatch(authError(handleErrors(error)));
        });
    };
  },

  signUpWithGoogle(responseGoogle) {
    return dispatch => {
      dispatch({ type: LOADING, payload: true });
      api
        .post('/register', { source: 1, token: responseGoogle.tokenId })
        .then(({ data }) => {
          localStorage.setItem('token', data.token);
          dispatch({ type: LOADING, payload: false });
          Mixpanel.track('Signed Up with google account', { email: responseGoogle.profileObj.email });
          browserHistory.push('/registering');
        })
        .catch(error => {
          dispatch({ type: LOADING, payload: false });
          browserHistory.push('/register');
          Mixpanel.track('Failed Sign Up with google account', { email: responseGoogle.profileObj.email });
          dispatch(authError(handleErrors(error)));
        });
    };
  },

  // signinWithGoogleFailed(error) {
  //   return dispatch => { };
  // },

  register({ source = 0, email }) {
    return dispatch => {
      dispatch({ type: LOADING, payload: true });
      api
        .post('/register', { source, email })
        .then(result => {
          dispatch({ type: USER_REGISTRED, payload: { email } });
          dispatch({ type: LOADING, payload: false });
          localStorage.setItem('token', result.data.token);
          Mixpanel.track('Signed Up', { email });
          browserHistory.push('/registering');
        })
        .catch(error => {
          dispatch({ type: LOADING, payload: false });
          Mixpanel.track('Failed Sign Up', { email });
          dispatch(authError(handleErrors(error)));
        });
    };
  },

  registerInfo(value) {
    return dispatch => {
      dispatch({ type: LOADING, payload: true });
      api
        .post('/register/info', value)
        .then(result => {
          api.get('/me').then(({ data }) => {
            getV2('/v2/me')
              .then(data => dispatch({ type: 'AUTH:SIGNING:V2', payload: data }))
              .then(_ => {
                localStorage.setItem('token', data.token);
                dispatch({ type: 'auth:signedIn', payload: data });
                browserHistory.push('/');
              });
          });
        })
        .catch(error => {
          dispatch({ type: LOADING, payload: false });
          dispatch(authError(handleErrors(error)));
        });
    };
  },

  changePassword(object) {
    return dispatch => {
      dispatch({ type: CHANGING_PASSWORD });
      api
        .post('/me/updatepassword', object)
        .then(({ data }) => {
          dispatch({ type: PASSWORD_CHANGED });
          dispatchAlert(dispatch, {
            message: data.message || `Your password has been successfully updated.`,
            type: 'info',
          });
        })
        .catch(error => {
          dispatch({ type: CHANGING_PASSWORD_FAILED, error });
        });
    };
  },

  resetPasswordVerifyOtp({ otp, email, password }) {
    return dispatch => {
      dispatch({ type: LOADING, payload: true });
      api
        .post('/me/verify-resetpassword', { otp, email, password })
        .then(({ data }) => {
          dispatch({ type: LOADING, payload: false });
          dispatchAlert(dispatch, {
            message: data.message || `Your password has been successfully updated.`,
            type: 'info',
          });
        })
        .catch(error => {
          dispatch({ type: LOADING, payload: false });
          dispatch(authError(handleErrors(error)));
        });
    };
  },

  signOut() {
    localStorage.clear();
    browserHistory.push('/signin');
    return { type: SIGNED_OUT };
  },

  clearStore() {
    return dispatch => {
      dispatch({ type: CLEAR_STORE });
    };
  },

  discardError() {
    return dispatch => {
      dispatch({ type: DISCARD_ERROR });
    };
  },

  updateMe(me) {
    return dispatch => {
      dispatch({ type: UPDATING, payload: true });
      api
        .post('/me', me)
        .then(({ data }) => {
          dispatchAlert(dispatch, {
            message: `Your profile has been successfully updated.`,
            type: 'info',
          });
          dispatch({
            type: UPDATED,
            payload: data,
          });
        })
        .catch(error => {
          dispatch({ type: UPDATING, payload: false });
        });
    };
  },

  getUsernameValue(event) {
    return dispatch => {
      dispatch({
        type: GET_USERNAME_VALUE,
        payload: event.target.value,
      });
    };
  },

  updateSubscriptionInfos(data) {
    return dispatch => {
      dispatch({
        type: TOTAL_USERS_UPDATED,
        payload: data.total_users,
      });
      api
        .get('/users/attributes')
        .then(({ data }) => {
          dispatch({
            type: USER_ATTRIBUTES_UPDATED,
            payload: data,
          });
        })
        .catch(error => {});
    };
  },
};
