import moment from 'moment';

import { TaskPriorityEnum } from '../../enums/task/task-priority.enum';
import { TaskTypeEnum } from '../../enums/task/task-type.enum';
import { FileLightDTO, TaskUpdateRequestDTO } from '../../generated/model/models';
import { FileLightData } from '../file/file-light-data';
import { DashboardTaskDetailsData } from './dashboard-task-details-data';

export interface DashboardTaskForUpdate extends Partial<DashboardTaskDetailsData> {}

export namespace DashboardTaskForUpdate {
  export function mapToApiValue(task: DashboardTaskForUpdate): TaskUpdateRequestDTO {
    return {
      eventype_id: TaskTypeEnum.convertToApiValue.getValue(task.type),
      title: task.title,
      assigned_to: task.assignee && Number(task.assignee.value),
      task_priority: TaskPriorityEnum.convertToApiValue.getValue(task.priority),
      due_date: task.dueDate ? moment(task.dueDate).format('L HH:mm') : null,
      description: task.description,
      oldFile: JSON.stringify((task.files || []).filter(fl => fl.uuid).map(file => FileLightData.mapToApi(file))), // note: see swagger description
      files: (task.files || []).filter(fl => !fl.uuid).map(file => FileLightData.formatToFileBlob(file)) as FileLightDTO[],
      is_zoom_call: task.zoom,
      deal_id: task.deal && Number(task.deal.value),
      guests: (task.people || []).filter(user => typeof user === 'string') as string[],
      attendees: (task.people || []).filter(user => typeof user !== 'string').map(i => Number(i['value'])),
    };
  }
}
