import { createAction } from '@reduxjs/toolkit';
import { ErrorResponseData } from 'app/v2.0/core/models/http-params.type';
import { NewsTagForCreate } from 'app/v2.0/core/models/news/news-tag-for-create';
import { NewsTagsData } from '../../../../../core/models/news/news-tags-data';
import { NewsSummaryData } from '../../../../../core/models/news/news-summary-data';

import { INewsList, ISaveNewsId, ITagsList } from './types';

// News tab
export const loadNews = createAction('[Dashboard/news] load news');
export const loadNewsSuccess = createAction<INewsList>('[Dashboard/news] load news success');
export const loadNewsFail = createAction<ErrorResponseData>('[Dashboard/news] load news fail');
export const setNewsLoading = createAction<boolean>('[Dashboard/news] set news loading');
export const loadNextPageNews = createAction('[Dashboard/news] load next page news');
export const saveNews = createAction<ISaveNewsId>('[[Dashboard/news]] save news by id');
export const saveNewsSuccess = createAction<NewsSummaryData>('[Dashboard/news] save news success');
export const saveNewsFail = createAction<ErrorResponseData>('[Dashboard/news] save news fail');
export const resetNewsStore = createAction('[Dashboard/news] reset store');

// Saved news Tab
export const loadSavedNews = createAction('[Dashboard/news/saved] load saved news');
export const loadSavedNewsSuccess = createAction<INewsList>('[Dashboard/news/saved] load saved news success');
export const loadSavedNewsFail = createAction<ErrorResponseData>('[Dashboard/news] load saved news fail');
export const removeSavedNews = createAction<NewsSummaryData>('[Dashboard/news/remove] remove saved news');
export const removeNewsSuccess = createAction<NewsSummaryData>('[Dashboard/news] remove saved news success');
export const removeSavedNewsFail = createAction<ErrorResponseData>('[Dashboard/news] remove saved news fail');
export const resetSavedNewsStore = createAction('[Dashboard/news/saved] reset saved news store');
export const loadNextPageSavedNews = createAction('[Dashboard/news/saved] load next page saved news');

// Topic view
export const loadTags = createAction('[Dashboard/news/tags] load news tags');
export const loadTagsSuccess = createAction<ITagsList>('[Dashboard/news/tags] load news tags success');
export const loadTagsFail = createAction<ErrorResponseData>('[Dashboard/news/tags] load news tags fail');
export const loadNextPageTags = createAction('[Dashboard/news/tags] load next page news');
export const addTag = createAction<NewsTagForCreate>('[Dashboard/news/tags] add news tag');
export const addTagSuccess = createAction<NewsTagsData>('[Dashboard/news/tags] add news tag sucess');
export const addTagFail = createAction<ErrorResponseData>('[Dashboard/news/tags] add news tags fail');
export const removeTag = createAction<NewsTagsData>('[Dashboard/news/tags/remove] remove saved news');
export const removeTagSuccess = createAction<NewsTagsData>('[Dashboard/news/tags] remove news tag success');
export const removeTagFail = createAction<ErrorResponseData>('[Dashboard/news/tags] remove news tags fail');
