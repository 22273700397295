import { createReducer, Action } from '@reduxjs/toolkit';

import { IntegrationExcelData } from '../../../../core/models/integration-excel/integration-excel-data';
import { ErrorResponseData } from '../../../../core/models/http-params.type';

export interface PipedriveState {
  error: ErrorResponseData;
}

export interface ExcelState {
  error: ErrorResponseData;
  editing: IntegrationExcelData;
}

export interface SlackState {
  error: ErrorResponseData;
}

export const excelInitialState: ExcelState = {
  error: null,
  editing: null,
};

export const pipedriveInitialState: PipedriveState = {
  error: null,
};

export const slackInitialState: SlackState = {
  error: null,
};

export interface IntegrationState {
  pipedrive: PipedriveState;
  slack: SlackState;
  excel: ExcelState;
}

const IntegrationInitialState: IntegrationState = {
  pipedrive: pipedriveInitialState,
  slack: slackInitialState,
  excel: excelInitialState,
};

const _integrationReducer = createReducer<IntegrationState>(IntegrationInitialState, _builder => {});

export function integrationReducer(state: IntegrationState | undefined, action: Action): IntegrationState {
  return _integrationReducer(state, action);
}
