import { createAction } from '@reduxjs/toolkit';
import { ErrorResponseData } from 'app/v2.0/core/models/http-params.type';
import { SlackIntegrationModel } from '../../../../core/models/settings/integrations/slack/slack-for-create';
import { PipedriveForCreateModel } from '../../../../core/models/settings/integrations/pipedrive/pipedrive-for-create';
import { CalendarForCreateModel } from '../../../../core/models/settings/integrations/google/calendar/calendar-for-create';
import { ZoomIntegrationModel } from '../../../../core/models/settings/integrations/zoom/zoom-for-create';
import { IntegrationExcelData } from '../../../../core/models/integration-excel/integration-excel-data';

// pipedrive integration actions
export const connectPipedrive = createAction<PipedriveForCreateModel>('[integration/pipedrive] connect pipedrive');
export const connectPipedriveSuccess = createAction('[integration/pipedrive] connect pipedrive success');
export const connectPipedriveFail = createAction<ErrorResponseData>('[integration/pipedrive] connect pipedrive fail');
// slack integration actions
export const connectSlack = createAction<SlackIntegrationModel>('[integration/slack] connect slack');
export const connectSlackSuccess = createAction('[integration/slack] connect slack success');
export const connectSlackFail = createAction<ErrorResponseData>('[integration/slack] connect slack fail');
// Calendar integration
export const connectCalendar = createAction<CalendarForCreateModel>('[integration/calendar] connect calendar');
export const connectCalendarSuccess = createAction('[integration/calendar] connect calendar success');
export const connectCalendarFail = createAction<ErrorResponseData>('[integration/calendar] connect calendar fail');
// zoom integration actions
export const connectZoom = createAction<ZoomIntegrationModel>('[integration/zoom] connect zoom');
export const connectZoomSuccess = createAction('[integration/zoom] connect zoom success');
export const connectZoomFail = createAction<ErrorResponseData>('[integration/zoom] connect zoom fail');
// excel form
export const uploadTemplate = createAction<IntegrationExcelData>('[integration/excel] upload Template');
export const uploadTemplateSuccess = createAction('[integration/excel] upload Template success');
export const uploadTemplateFail = createAction<ErrorResponseData>('[integration/excel] upload Template fail');

export const downloadTemplate = createAction<boolean>('[integration/excel] download template');
