import api from '../../../../actions/api.js';

export const getBillingPackages = async setter => {
  try {
    const result = await api.get('/billing/plan');
    setter(result.data);
  } catch (error) {
    console.log('error', error);
  }
};
