import React from 'react';

class ContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.getItems = this.getItems.bind(this);
    this.closeContextMenu = this.closeContextMenu.bind(this);
    this.contextMenu = React.createRef();
    this.state = {};
  }

  closeContextMenu() {
    this.props.onClose();
  }
  _handleClick = event => {
    try {
      const wasOutside = !this.contextMenu.current.contains(event.target);
      if (wasOutside && this.props.closeOnClickOut) this.closeContextMenu();
    } catch (error) {}
  };

  componentDidMount() {
    window.addEventListener('mouseup', this._handleClick);
  }
  componentWillUnmount() {
    window.removeEventListener('mouseup', this._handleClick);
  }
  getItems() {
    const { items, closeOnClick } = this.props;
    if (closeOnClick) {
      return items.map(item => ({
        ...item,
        onClick: () => {
          this.closeContextMenu();
          item.onClick();
        },
      }));
    } else {
      return items;
    }
  }

  render() {
    if (!this.props.show) return null;
    return (
      <div className='ContextMenu' ref={this.contextMenu}>
        <div
          id={this.props.contextId}
          style={{
            position: 'fixed',
            display: 'flex',
            flexFlow: 'column',
            border: '1px solid rgba(0,0,0,0.15)',
            borderRadius: '4px',
            boxShadow: '0px 2px 4px 0px rgba(100, 109, 130, 0.14), 0px 0px 1px 0px rgba(100, 109, 130, 0.1)',
            padding: '16px 0 16px 0',
            background: '#ffffff',
            minWidth: '160px',
            left: `${this.props.xOffset}px`,
            top: `${this.props.yOffset}px`,
          }}
        >
          {this.getItems().map((item, idx) => (
            <MenuItem item={item} key={idx + 1} />
          ))}
        </div>
      </div>
    );
  }
}

export default ContextMenu;

ContextMenu.defaultProps = {
  items: [],
  closeOnClick: false,
  xOffset: 0,
  yOffset: 0,
};

const MenuItem = ({ item }) => {
  const { label, icon, onClick } = item;

  return (
    <span className='menuItem' onClick={onClick} key={label}>
      {icon && <i className='material-icons mr-3'>{icon}</i>}
      <span>{label}</span>
    </span>
  );
};
