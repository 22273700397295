import { UserNewsTagDTO } from '../../generated/model/userNewsTagDTO';

export interface NewsTagsData {
  id: number;
  tag: string;
  isRemoved: boolean;
}

export namespace NewsTagsData {
  export function mapFromApi(newsTags: UserNewsTagDTO): NewsTagsData {
    return {
      id: newsTags.user_news_id,
      tag: newsTags.news_tag,
      isRemoved: false,
    };
  }
}
