import { IconPrefixEnum } from '../../../shared/interfaces/icon-data';
import { SelectItemData } from '../../../shared/interfaces/select-item';
import { PipelineAllLightDTO } from '../../generated/model/models';

export interface AllPipelineLightData {
  id: string;
  name: string;
  isPortfolio: boolean;
}

export namespace AllPipelineLightData {
  export function mapToSelectedItem(data: PipelineAllLightDTO): SelectItemData {
    return {
      value: data.pipeline_id.toString(),
      name: data.name,
      icon: data.is_portfolio ? { name: 'ri-briefcase-4-line', prefix: IconPrefixEnum.Remix } : { name: 'ri-stack-line', prefix: IconPrefixEnum.Remix },
    };
  }

  export function mapToApiValue(data: PipelineAllLightDTO): AllPipelineLightData {
    return {
      id: data.pipeline_id.toString(),
      name: data.name,
      isPortfolio: !!data.is_portfolio,
    };
  }
}
