import ReactGA from 'react-ga';

export const gaEvent = (category, action, label = '') => {
  ReactGA.event({ category, action, label });
};

export const modalView = modalName => {
  ReactGA.modalview(modalName);
};

export const pageView = path => {
  ReactGA.pageview(path);
};
