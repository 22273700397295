import { ActionPermissionEnumDTO, PagePermissionEnumDTO } from '../../generated/model/models';
import { IMap } from '../../utils/map-utils';

export enum PermissionTypeEnum {
  UpdateCompany = 1,
  UpdateCustomField = 2,
  DeleteCustomField = 3,
  CreateCustomField = 4,
  CreateUpdateForm = 5,
  DuplicateForm = 7,
  DeleteForm = 8,
  ExportFormDealToExcel = 10,
  ImportDealsFromExcel = 11,
  CreateFund = 14,
  UpdateFund = 15,
  DeleteFund = 16,
  ListRates = 12,
  CreateRate = 18,
  UpdateRate = 19,
  DeleteRate = 20,
  CreatePipeline = 21,
  CreatePortfolio = 22,
  UpdatePipeline = 23,
  UpdatePortfolio = 24,
  DeletePipeline = 26,
  DeletePortfolio = 27,
  MovePipelineToPortfolio = 28,
  MovePortfolioToPipeline = 29,
  GetPipelineDeals = 30,
  ExportPipelineDeals = 31,
  ExportPortfolioDeals = 32,
  ExportPortfolioDashboardData = 40,
  CreateUser = 41,
  UpdateUser = 42,
  DeleteUser = 43,
  BlockUnblockUser = 44,
  CreateInvestor = 45,
  UpdateInvestor = 46,
  DeleteInvestor = 47,
  LinkUnlinkInvestor = 48,
  ResendInvestorInvitation = 49,
  ListWealthInvestors = 50,
  BlockUnblockInvestor = 51,
  ShareDealNotesWithInvestors = 52,
  ShareDealFilesWithInvestors = 53,
  ExitInvestorDeal = 54,
  CreatePipelineDeal = 55,
  CreatePortfolioDeal = 56,
  UpdatePipelineDeal = 57,
  UpdatePortfolioDeal = 58,
  DeletePipelineDeal = 60,
  DeletePortfolioDeal = 61,
  WonPipelineDeal = 62,
  LostPipelineDeal = 63,
  ReopenDeal = 64,
  EnableDisableAngelInvVote = 65,
  CreateContact = 66,
  UpdateContact = 67,
  DeleteContact = 68,
  LinkContactToPipelineDeal = 69,
  LinkContactToPortfolioDeal = 70,
  CreatePipelineNote = 75,
  CreatePortfolioNote = 76,
  UpdatePipelineNote = 77,
  UpdatePortfolioNote = 78,
  CreatePipelineDealFile = 79,
  CreatePortfolioDealFile = 80,
  DropboxImportPipelineDealFile = 81,
  DropboxImportPortfolioDealFile = 82,
  DriveImportPipelineDealFile = 83,
  DriveImportPortfolioDealFile = 84,
  UpdatePipelineDealFile = 85,
  UpdatePortfolioDealFile = 86,
  DeletePipelineDealFile = 87,
  DeletePortfolioDealFile = 88,
  DownloadPipelineDealFile = 89,
  DownloadPortfolioDealFile = 90,
  CreateUpdateReport = 97,
  ShareReport = 100,
  DeleteReport = 101,
  CreatePipelineMetricsGroup = 127,
  CreatePortfolioMetricsGroup = 128,
  UpdatePipelineMetricsGroup = 129,
  UpdatePortfolioMetricsGroup = 130,
  DeletePipelineMetricsGroup = 131,
  DeletePortfolioMetricsGroup = 132,
  MigratePipeDriveData = 139,
  IntegrateZoom = 140,
  GoogleCalendarIntegration = 142,
  ListTasks = 146,
  CreateTask = 147,
  CreatePipelineDealTask = 148,
  CreatePortfolioDealTask = 149,
  UpdateTask = 150,
  UpdatePipelineDealTask = 151,
  UpdatePortfolioDealTask = 152,
  DeleteTask = 153,
  DeletePipelineDealTask = 154,
  DeletePortfolioDealTask = 155,
  VoteOnDealTask = 156,
  VoteOnPipelineDealTask = 157,
  VoteOnPortfolioDealTask = 158,
  ListDashboardNotifications = 159,
  ListDashboardEmails = 160,
  ListDashboardFiles = 161,
  ListDashboardNotes = 162,
  DashboardPage = 169,
  PipelinePage = 170,
  PortfolioPage = 180,
  ReportPage = 181,
  ContactPage = 182,
  TaskPage = 183,
  CompanySettingPage = 184,
  UserSettingPage = 186,
  FundSettingPage = 187,
  FormSettingPage = 189,
  CustomFieldSettingPage = 190,
  IntegrationSettingPage = 191,
  SettingsPage = 192,
  PipelineDealNotePage = 193,
  PortfolioDealNotePage = 194,
  PipelineDealEmailPage = 195,
  PortfolioDealEmailPage = 196,
  PipelineDealTaskPage = 197,
  PortfolioDealTaskPage = 198,
  PipelineDealFilePage = 199,
  GetPipelineStatistics = 200,
  GetPortfolioStatistics = 201,
  ListPipelineNote = 202,
  ListPortfolioNote = 203,
  CreateDealRound = 204,
  UpdateDealRound = 205,
  DeleteDealRound = 206,
  ListDealRound = 207,
  SendEmailPipelineDeal = 208,
  SendEmailPortfolioDeal = 209,
  ListPipelineDealNotifications = 211,
  ListPortfolioDealNotifications = 212,
  DeleteAllPipeline = 213,
  DeleteAllPortfolio = 214,
  ListPipelineDealFiles = 215,
  ListPortfolioDealFiles = 216,
  PortfolioDealFilePage = 217,
  PipelineDealMetricPage = 218,
  PortfolioDealMetricPage = 219,
  PipelineDealHistoryPage = 220,
  PortfolioDealHistoryPage = 221,
  ListPipelineMetricsGroup = 222,
  ListPortfolioMetricsGroup = 223,
  ListAngelInvestors = 224,
  CreateAngelInvestor = 225,
  DeleteAngelInvestor = 226,
  LinkUnlinkPipelineDealToAngelInvestors = 227,
  LinkUnlinkPortfolioDealToAngelInvestors = 228,
  ExportAngelInvestorVoteResult = 229,
  UpdateAngelInvestor = 230,
  ShareNotesWithAngelInvestor = 231,
  GetPortfolioDeals = 232,
}

export namespace PermissionTypeEnum {
  export const pipelineDealPages: PermissionTypeEnum[] = [
    PermissionTypeEnum.PipelineDealNotePage,
    PermissionTypeEnum.PipelineDealEmailPage,
    PermissionTypeEnum.PipelineDealTaskPage,
    PermissionTypeEnum.PipelineDealFilePage,
    PermissionTypeEnum.PipelineDealMetricPage,
    PermissionTypeEnum.PipelineDealHistoryPage,
  ];
  export const portfolioDealPages: PermissionTypeEnum[] = [
    PermissionTypeEnum.PortfolioDealNotePage,
    PermissionTypeEnum.PortfolioDealEmailPage,
    PermissionTypeEnum.PortfolioDealTaskPage,
    PermissionTypeEnum.PortfolioDealFilePage,
    PermissionTypeEnum.PortfolioDealMetricPage,
    PermissionTypeEnum.PortfolioDealHistoryPage,
  ];
  export const settingPages: PermissionTypeEnum[] = [
    PermissionTypeEnum.CompanySettingPage,
    PermissionTypeEnum.PipelinePage,
    PermissionTypeEnum.PortfolioPage,
    PermissionTypeEnum.UserSettingPage,
    PermissionTypeEnum.FundSettingPage,
    PermissionTypeEnum.FormSettingPage,
    PermissionTypeEnum.CustomFieldSettingPage,
    PermissionTypeEnum.IntegrationSettingPage,
  ];
  export const sideBarPages: PermissionTypeEnum[] = [
    PermissionTypeEnum.DashboardPage,
    PermissionTypeEnum.PipelinePage,
    PermissionTypeEnum.PortfolioPage,
    PermissionTypeEnum.ReportPage,
    PermissionTypeEnum.ContactPage,
    PermissionTypeEnum.TaskPage,
    PermissionTypeEnum.SettingsPage,
  ];
  export const convertFromApi: IMap<PagePermissionEnumDTO | ActionPermissionEnumDTO, PermissionTypeEnum> = new IMap<
    PagePermissionEnumDTO | ActionPermissionEnumDTO,
    PermissionTypeEnum
  >([
    [ActionPermissionEnumDTO.ListPipelineDealNotifications, PermissionTypeEnum.ListPipelineDealNotifications],
    [ActionPermissionEnumDTO.ListPortfolioDealNotifications, PermissionTypeEnum.ListPortfolioDealNotifications],
    [ActionPermissionEnumDTO.DeleteAllPipeline, PermissionTypeEnum.DeleteAllPipeline],
    [ActionPermissionEnumDTO.DeleteAllPortfolio, PermissionTypeEnum.DeleteAllPortfolio],
    [ActionPermissionEnumDTO.ListPipelineDealFiles, PermissionTypeEnum.ListPipelineDealFiles],
    [ActionPermissionEnumDTO.ListPortfolioDealFiles, PermissionTypeEnum.ListPortfolioDealFiles],
    [ActionPermissionEnumDTO.ListDealRound, PermissionTypeEnum.ListDealRound],
    [ActionPermissionEnumDTO.UpdateDealRound, PermissionTypeEnum.UpdateDealRound],
    [ActionPermissionEnumDTO.DeleteDealRound, PermissionTypeEnum.DeleteDealRound],
    [ActionPermissionEnumDTO.SendEmailPipelineDeal, PermissionTypeEnum.SendEmailPipelineDeal],
    [ActionPermissionEnumDTO.SendEmailPortfolioDeal, PermissionTypeEnum.SendEmailPortfolioDeal],
    [ActionPermissionEnumDTO.ListPipelineMetricsGroup, PermissionTypeEnum.ListPipelineMetricsGroup],
    [ActionPermissionEnumDTO.ListPortfolioMetricsGroup, PermissionTypeEnum.ListPortfolioMetricsGroup],
    [ActionPermissionEnumDTO.CreateDealRound, PermissionTypeEnum.CreateDealRound],
    [ActionPermissionEnumDTO.ListPortfolioNote, PermissionTypeEnum.ListPortfolioNote],
    [ActionPermissionEnumDTO.ListPipelineNote, PermissionTypeEnum.ListPipelineNote],
    [ActionPermissionEnumDTO.GetPortfolioStatistics, PermissionTypeEnum.GetPortfolioStatistics],
    [ActionPermissionEnumDTO.GetPipelineStatistics, PermissionTypeEnum.GetPipelineStatistics],
    [ActionPermissionEnumDTO.UpdateCompany, PermissionTypeEnum.UpdateCompany],
    [ActionPermissionEnumDTO.UpdateCustomField, PermissionTypeEnum.UpdateCustomField],
    [ActionPermissionEnumDTO.DeleteCustomField, PermissionTypeEnum.DeleteCustomField],
    [ActionPermissionEnumDTO.CreateCustomField, PermissionTypeEnum.CreateCustomField],
    [ActionPermissionEnumDTO.CreateOrUpdateForm, PermissionTypeEnum.CreateUpdateForm],
    [ActionPermissionEnumDTO.DuplicateForm, PermissionTypeEnum.DuplicateForm],
    [ActionPermissionEnumDTO.DeleteForm, PermissionTypeEnum.DeleteForm],
    [ActionPermissionEnumDTO.ExportFormDealToExcel, PermissionTypeEnum.ExportFormDealToExcel],
    [ActionPermissionEnumDTO.ImportDealsFromExcel, PermissionTypeEnum.ImportDealsFromExcel],
    [ActionPermissionEnumDTO.CreateFund, PermissionTypeEnum.CreateFund],
    [ActionPermissionEnumDTO.UpdateFund, PermissionTypeEnum.UpdateFund],
    [ActionPermissionEnumDTO.DeleteFund, PermissionTypeEnum.DeleteFund],
    [ActionPermissionEnumDTO.CreateRate, PermissionTypeEnum.CreateRate],
    [ActionPermissionEnumDTO.UpdateRate, PermissionTypeEnum.UpdateRate],
    [ActionPermissionEnumDTO.DeleteRate, PermissionTypeEnum.DeleteRate],
    [ActionPermissionEnumDTO.ListRates, PermissionTypeEnum.ListRates],
    [ActionPermissionEnumDTO.CreatePipeline, PermissionTypeEnum.CreatePipeline],
    [ActionPermissionEnumDTO.CreatePortfolio, PermissionTypeEnum.CreatePortfolio],
    [ActionPermissionEnumDTO.UpdatePipeline, PermissionTypeEnum.UpdatePipeline],
    [ActionPermissionEnumDTO.UpdatePortfolio, PermissionTypeEnum.UpdatePortfolio],
    [ActionPermissionEnumDTO.DeletePipeline, PermissionTypeEnum.DeletePipeline],
    [ActionPermissionEnumDTO.DeletePortfolio, PermissionTypeEnum.DeletePortfolio],
    [ActionPermissionEnumDTO.MovePipelineToPortfolio, PermissionTypeEnum.MovePipelineToPortfolio],
    [ActionPermissionEnumDTO.MovePortfolioToPipeline, PermissionTypeEnum.MovePortfolioToPipeline],
    [ActionPermissionEnumDTO.GetPipelineDeals, PermissionTypeEnum.GetPipelineDeals],
    [ActionPermissionEnumDTO.ExportPipelineDeals, PermissionTypeEnum.ExportPipelineDeals],
    [ActionPermissionEnumDTO.ExportPortfolioDeals, PermissionTypeEnum.ExportPortfolioDeals],
    [ActionPermissionEnumDTO.ExportPortfolioDashboardData, PermissionTypeEnum.ExportPortfolioDashboardData],
    [ActionPermissionEnumDTO.CreateUser, PermissionTypeEnum.CreateUser],
    [ActionPermissionEnumDTO.UpdateUser, PermissionTypeEnum.UpdateUser],
    [ActionPermissionEnumDTO.DeleteUser, PermissionTypeEnum.DeleteUser],
    [ActionPermissionEnumDTO.BlockUnblockUser, PermissionTypeEnum.BlockUnblockUser],
    [ActionPermissionEnumDTO.CreateInvestor, PermissionTypeEnum.CreateInvestor],
    [ActionPermissionEnumDTO.UpdateInvestor, PermissionTypeEnum.UpdateInvestor],
    [ActionPermissionEnumDTO.DeleteInvestor, PermissionTypeEnum.DeleteInvestor],
    [ActionPermissionEnumDTO.ListWealthInvestors, PermissionTypeEnum.ListWealthInvestors],
    [ActionPermissionEnumDTO.LinkUnlinkInvestor, PermissionTypeEnum.LinkUnlinkInvestor],
    [ActionPermissionEnumDTO.ResendInvestorInvitation, PermissionTypeEnum.ResendInvestorInvitation],
    [ActionPermissionEnumDTO.BlockUnblockInvestor, PermissionTypeEnum.BlockUnblockInvestor],
    [ActionPermissionEnumDTO.ShareDealNotesWithInvestors, PermissionTypeEnum.ShareDealNotesWithInvestors],
    [ActionPermissionEnumDTO.ShareDealFilesWithInvestors, PermissionTypeEnum.ShareDealFilesWithInvestors],
    [ActionPermissionEnumDTO.ExitInvestorDeal, PermissionTypeEnum.ExitInvestorDeal],
    [ActionPermissionEnumDTO.CreatePipelineDeal, PermissionTypeEnum.CreatePipelineDeal],
    [ActionPermissionEnumDTO.CreatePortfolioDeal, PermissionTypeEnum.CreatePortfolioDeal],
    [ActionPermissionEnumDTO.UpdatePipelineDeal, PermissionTypeEnum.UpdatePipelineDeal],
    [ActionPermissionEnumDTO.UpdatePortfolioDeal, PermissionTypeEnum.UpdatePortfolioDeal],
    [ActionPermissionEnumDTO.DeletePipelineDeal, PermissionTypeEnum.DeletePipelineDeal],
    [ActionPermissionEnumDTO.DeletePortfolioDeal, PermissionTypeEnum.DeletePortfolioDeal],
    [ActionPermissionEnumDTO.WonPipelineDeal, PermissionTypeEnum.WonPipelineDeal],
    [ActionPermissionEnumDTO.LostPipelineDeal, PermissionTypeEnum.LostPipelineDeal],
    [ActionPermissionEnumDTO.ReopenDeal, PermissionTypeEnum.ReopenDeal],
    [ActionPermissionEnumDTO.EnableDisableAngelInvVote, PermissionTypeEnum.EnableDisableAngelInvVote],
    [ActionPermissionEnumDTO.CreateContact, PermissionTypeEnum.CreateContact],
    [ActionPermissionEnumDTO.UpdateContact, PermissionTypeEnum.UpdateContact],
    [ActionPermissionEnumDTO.DeleteContact, PermissionTypeEnum.DeleteContact],
    [ActionPermissionEnumDTO.LinkContactToPipelineDeal, PermissionTypeEnum.LinkContactToPipelineDeal],
    [ActionPermissionEnumDTO.LinkContactToPortfolioDeal, PermissionTypeEnum.LinkContactToPortfolioDeal],
    [ActionPermissionEnumDTO.CreatePipelineNote, PermissionTypeEnum.CreatePipelineNote],
    [ActionPermissionEnumDTO.CreatePortfolioNote, PermissionTypeEnum.CreatePortfolioNote],
    [ActionPermissionEnumDTO.UpdatePipelineNote, PermissionTypeEnum.UpdatePipelineNote],
    [ActionPermissionEnumDTO.UpdatePortfolioNote, PermissionTypeEnum.UpdatePortfolioNote],
    [ActionPermissionEnumDTO.CreatePipelineDealFile, PermissionTypeEnum.CreatePipelineDealFile],
    [ActionPermissionEnumDTO.CreatePortfolioDealFile, PermissionTypeEnum.CreatePortfolioDealFile],
    [ActionPermissionEnumDTO.DropboxImportPipelineDealFile, PermissionTypeEnum.DropboxImportPipelineDealFile],
    [ActionPermissionEnumDTO.DropboxImportPortfolioDealFile, PermissionTypeEnum.DropboxImportPortfolioDealFile],
    [ActionPermissionEnumDTO.GdriveImportPipelineDealFile, PermissionTypeEnum.DriveImportPipelineDealFile],
    [ActionPermissionEnumDTO.GdriveImportPortfolioDealFile, PermissionTypeEnum.DriveImportPortfolioDealFile],
    [ActionPermissionEnumDTO.UpdatePipelineDealFile, PermissionTypeEnum.UpdatePipelineDealFile],
    [ActionPermissionEnumDTO.UpdatePortfolioDealFile, PermissionTypeEnum.UpdatePortfolioDealFile],
    [ActionPermissionEnumDTO.DeletePipelineDealFile, PermissionTypeEnum.DeletePipelineDealFile],
    [ActionPermissionEnumDTO.DeletePortfolioDealFile, PermissionTypeEnum.DeletePortfolioDealFile],
    [ActionPermissionEnumDTO.DownloadPipelineDealFile, PermissionTypeEnum.DownloadPipelineDealFile],
    [ActionPermissionEnumDTO.DownloadPortfolioDealFile, PermissionTypeEnum.DownloadPortfolioDealFile],
    [ActionPermissionEnumDTO.CreateOrUpdateReport, PermissionTypeEnum.CreateUpdateReport],
    [ActionPermissionEnumDTO.ShareReport, PermissionTypeEnum.ShareReport],
    [ActionPermissionEnumDTO.DeleteReport, PermissionTypeEnum.DeleteReport],
    [ActionPermissionEnumDTO.CreatePipelineMetricsGroup, PermissionTypeEnum.CreatePipelineMetricsGroup],
    [ActionPermissionEnumDTO.CreatePortfolioMetricsGroup, PermissionTypeEnum.CreatePortfolioMetricsGroup],
    [ActionPermissionEnumDTO.UpdatePipelineMetricsGroup, PermissionTypeEnum.UpdatePipelineMetricsGroup],
    [ActionPermissionEnumDTO.UpdatePortfolioMetricsGroup, PermissionTypeEnum.UpdatePortfolioMetricsGroup],
    [ActionPermissionEnumDTO.DeletePipelineMetricsGroup, PermissionTypeEnum.DeletePipelineMetricsGroup],
    [ActionPermissionEnumDTO.DeletePortfolioMetricsGroup, PermissionTypeEnum.DeletePortfolioMetricsGroup],
    [ActionPermissionEnumDTO.MigratePipedriveData, PermissionTypeEnum.MigratePipeDriveData],
    [ActionPermissionEnumDTO.IntegrateZoom, PermissionTypeEnum.IntegrateZoom],
    [ActionPermissionEnumDTO.GoogleCalendarIntegration, PermissionTypeEnum.GoogleCalendarIntegration],
    [ActionPermissionEnumDTO.ListTasks, PermissionTypeEnum.ListTasks],
    [ActionPermissionEnumDTO.CreateTask, PermissionTypeEnum.CreateTask],
    [ActionPermissionEnumDTO.CreatePipelineDealTask, PermissionTypeEnum.CreatePipelineDealTask],
    [ActionPermissionEnumDTO.CreatePortfolioDealTask, PermissionTypeEnum.CreatePortfolioDealTask],
    [ActionPermissionEnumDTO.UpdateTask, PermissionTypeEnum.UpdateTask],
    [ActionPermissionEnumDTO.UpdatePipelineDealTask, PermissionTypeEnum.UpdatePipelineDealTask],
    [ActionPermissionEnumDTO.UpdatePortfolioDealTask, PermissionTypeEnum.UpdatePortfolioDealTask],
    [ActionPermissionEnumDTO.DeleteTask, PermissionTypeEnum.DeleteTask],
    [ActionPermissionEnumDTO.DeletePipelineDealTask, PermissionTypeEnum.DeletePipelineDealTask],
    [ActionPermissionEnumDTO.DeletePortfolioDealTask, PermissionTypeEnum.DeletePortfolioDealTask],
    [ActionPermissionEnumDTO.VoteOnDealTask, PermissionTypeEnum.VoteOnDealTask],
    [ActionPermissionEnumDTO.VoteOnPipelineDealTask, PermissionTypeEnum.VoteOnPipelineDealTask],
    [ActionPermissionEnumDTO.VoteOnPortfolioDealTask, PermissionTypeEnum.VoteOnPortfolioDealTask],
    [ActionPermissionEnumDTO.ListDashboardNotifications, PermissionTypeEnum.ListDashboardNotifications],
    [ActionPermissionEnumDTO.ListDashboardFiles, PermissionTypeEnum.ListDashboardFiles],
    [ActionPermissionEnumDTO.ListDashboardNotes, PermissionTypeEnum.ListDashboardNotes],
    [ActionPermissionEnumDTO.ListDashboardEmails, PermissionTypeEnum.ListDashboardEmails],
    [PagePermissionEnumDTO.DashboardPage, PermissionTypeEnum.DashboardPage],
    [PagePermissionEnumDTO.PipelinePage, PermissionTypeEnum.PipelinePage],
    [PagePermissionEnumDTO.PortfolioPage, PermissionTypeEnum.PortfolioPage],
    [PagePermissionEnumDTO.ReportPage, PermissionTypeEnum.ReportPage],
    [PagePermissionEnumDTO.ContactPage, PermissionTypeEnum.ContactPage],
    [PagePermissionEnumDTO.TaskPage, PermissionTypeEnum.TaskPage],
    [PagePermissionEnumDTO.CompanySettingPage, PermissionTypeEnum.CompanySettingPage],
    [PagePermissionEnumDTO.UserSettingPage, PermissionTypeEnum.UserSettingPage],
    [PagePermissionEnumDTO.FundSettingPage, PermissionTypeEnum.FundSettingPage],
    [PagePermissionEnumDTO.FormSettingPage, PermissionTypeEnum.FormSettingPage],
    [PagePermissionEnumDTO.CustomFieldSettingPage, PermissionTypeEnum.CustomFieldSettingPage],
    [PagePermissionEnumDTO.IntegrationSettingPage, PermissionTypeEnum.IntegrationSettingPage],
    [PagePermissionEnumDTO.SettingsPage, PermissionTypeEnum.SettingsPage],
    [PagePermissionEnumDTO.PipelineDealNotePage, PermissionTypeEnum.PipelineDealNotePage],
    [PagePermissionEnumDTO.PortfolioDealNotePage, PermissionTypeEnum.PortfolioDealNotePage],
    [PagePermissionEnumDTO.PipelineDealEmailPage, PermissionTypeEnum.PipelineDealEmailPage],
    [PagePermissionEnumDTO.PortfolioDealEmailPage, PermissionTypeEnum.PortfolioDealEmailPage],
    [PagePermissionEnumDTO.PipelineDealTaskPage, PermissionTypeEnum.PipelineDealTaskPage],
    [PagePermissionEnumDTO.PortfolioDealTaskPage, PermissionTypeEnum.PortfolioDealTaskPage],
    [PagePermissionEnumDTO.PipelineDealFilePage, PermissionTypeEnum.PipelineDealFilePage],
    [PagePermissionEnumDTO.PortfolioDealFilePage, PermissionTypeEnum.PortfolioDealFilePage],
    [PagePermissionEnumDTO.PipelineDealMetricPage, PermissionTypeEnum.PipelineDealMetricPage],
    [PagePermissionEnumDTO.PortfolioDealMetricPage, PermissionTypeEnum.PortfolioDealMetricPage],
    [PagePermissionEnumDTO.PipelineDealHistoryPage, PermissionTypeEnum.PipelineDealHistoryPage],
    [PagePermissionEnumDTO.PortfolioDealHistoryPage, PermissionTypeEnum.PortfolioDealHistoryPage],
    [ActionPermissionEnumDTO.ListAngelInvestors, PermissionTypeEnum.ListAngelInvestors],
    [ActionPermissionEnumDTO.CreateAngelInvestor, PermissionTypeEnum.CreateAngelInvestor],
    [ActionPermissionEnumDTO.DeleteAngelInvestor, PermissionTypeEnum.DeleteAngelInvestor],
    [ActionPermissionEnumDTO.LinkUnlinkPipelineDealToAngelInvestors, PermissionTypeEnum.LinkUnlinkPipelineDealToAngelInvestors],
    [ActionPermissionEnumDTO.LinkUnlinkPortfolioDealToAngelInvestors, PermissionTypeEnum.LinkUnlinkPortfolioDealToAngelInvestors],
    [ActionPermissionEnumDTO.ExportAngelInvestorVoteResult, PermissionTypeEnum.ExportAngelInvestorVoteResult],
    [ActionPermissionEnumDTO.UpdateAngelInvestor, PermissionTypeEnum.UpdateAngelInvestor],
    [ActionPermissionEnumDTO.ShareNotesWithAngelInvestor, PermissionTypeEnum.ShareNotesWithAngelInvestor],
  ]);

  export const convertToApi: IMap<PermissionTypeEnum, PagePermissionEnumDTO | ActionPermissionEnumDTO> = new IMap<
    PermissionTypeEnum,
    PagePermissionEnumDTO | ActionPermissionEnumDTO
  >([
    [PermissionTypeEnum.ListPipelineDealNotifications, ActionPermissionEnumDTO.ListPipelineDealNotifications],
    [PermissionTypeEnum.ListPortfolioDealNotifications, ActionPermissionEnumDTO.ListPortfolioDealNotifications],
    [PermissionTypeEnum.DeleteAllPipeline, ActionPermissionEnumDTO.DeleteAllPipeline],
    [PermissionTypeEnum.DeleteAllPortfolio, ActionPermissionEnumDTO.DeleteAllPortfolio],
    [PermissionTypeEnum.ListPipelineDealFiles, ActionPermissionEnumDTO.ListPipelineDealFiles],
    [PermissionTypeEnum.ListPortfolioDealFiles, ActionPermissionEnumDTO.ListPortfolioDealFiles],
    [PermissionTypeEnum.ListDealRound, ActionPermissionEnumDTO.ListDealRound],
    [PermissionTypeEnum.UpdateDealRound, ActionPermissionEnumDTO.UpdateDealRound],
    [PermissionTypeEnum.DeleteDealRound, ActionPermissionEnumDTO.DeleteDealRound],
    [PermissionTypeEnum.SendEmailPipelineDeal, ActionPermissionEnumDTO.SendEmailPipelineDeal],
    [PermissionTypeEnum.SendEmailPortfolioDeal, ActionPermissionEnumDTO.SendEmailPortfolioDeal],
    [PermissionTypeEnum.ListPipelineMetricsGroup, ActionPermissionEnumDTO.ListPipelineMetricsGroup],
    [PermissionTypeEnum.ListPortfolioMetricsGroup, ActionPermissionEnumDTO.ListPortfolioMetricsGroup],
    [PermissionTypeEnum.CreateDealRound, ActionPermissionEnumDTO.CreateDealRound],
    [PermissionTypeEnum.ListPortfolioNote, ActionPermissionEnumDTO.ListPortfolioNote],
    [PermissionTypeEnum.ListPipelineNote, ActionPermissionEnumDTO.ListPipelineNote],
    [PermissionTypeEnum.GetPortfolioStatistics, ActionPermissionEnumDTO.GetPortfolioStatistics],
    [PermissionTypeEnum.GetPipelineStatistics, ActionPermissionEnumDTO.GetPipelineStatistics],
    [PermissionTypeEnum.UpdateCompany, ActionPermissionEnumDTO.UpdateCompany],
    [PermissionTypeEnum.UpdateCustomField, ActionPermissionEnumDTO.UpdateCustomField],
    [PermissionTypeEnum.DeleteCustomField, ActionPermissionEnumDTO.DeleteCustomField],
    [PermissionTypeEnum.CreateCustomField, ActionPermissionEnumDTO.CreateCustomField],
    [PermissionTypeEnum.CreateUpdateForm, ActionPermissionEnumDTO.CreateOrUpdateForm],
    [PermissionTypeEnum.DuplicateForm, ActionPermissionEnumDTO.DuplicateForm],
    [PermissionTypeEnum.DeleteForm, ActionPermissionEnumDTO.DeleteForm],
    [PermissionTypeEnum.ExportFormDealToExcel, ActionPermissionEnumDTO.ExportFormDealToExcel],
    [PermissionTypeEnum.ImportDealsFromExcel, ActionPermissionEnumDTO.ImportDealsFromExcel],
    [PermissionTypeEnum.CreateFund, ActionPermissionEnumDTO.CreateFund],
    [PermissionTypeEnum.UpdateFund, ActionPermissionEnumDTO.UpdateFund],
    [PermissionTypeEnum.DeleteFund, ActionPermissionEnumDTO.DeleteFund],
    [PermissionTypeEnum.CreateRate, ActionPermissionEnumDTO.CreateRate],
    [PermissionTypeEnum.UpdateRate, ActionPermissionEnumDTO.UpdateRate],
    [PermissionTypeEnum.DeleteRate, ActionPermissionEnumDTO.DeleteRate],
    [PermissionTypeEnum.ListRates, ActionPermissionEnumDTO.ListRates],
    [PermissionTypeEnum.CreatePipeline, ActionPermissionEnumDTO.CreatePipeline],
    [PermissionTypeEnum.CreatePortfolio, ActionPermissionEnumDTO.CreatePortfolio],
    [PermissionTypeEnum.UpdatePipeline, ActionPermissionEnumDTO.UpdatePipeline],
    [PermissionTypeEnum.UpdatePortfolio, ActionPermissionEnumDTO.UpdatePortfolio],
    [PermissionTypeEnum.DeletePipeline, ActionPermissionEnumDTO.DeletePipeline],
    [PermissionTypeEnum.DeletePortfolio, ActionPermissionEnumDTO.DeletePortfolio],
    [PermissionTypeEnum.MovePipelineToPortfolio, ActionPermissionEnumDTO.MovePipelineToPortfolio],
    [PermissionTypeEnum.MovePortfolioToPipeline, ActionPermissionEnumDTO.MovePortfolioToPipeline],
    [PermissionTypeEnum.GetPipelineDeals, ActionPermissionEnumDTO.GetPipelineDeals],
    [PermissionTypeEnum.ExportPipelineDeals, ActionPermissionEnumDTO.ExportPipelineDeals],
    [PermissionTypeEnum.ExportPortfolioDeals, ActionPermissionEnumDTO.ExportPortfolioDeals],
    [PermissionTypeEnum.ExportPortfolioDashboardData, ActionPermissionEnumDTO.ExportPortfolioDashboardData],
    [PermissionTypeEnum.CreateUser, ActionPermissionEnumDTO.CreateUser],
    [PermissionTypeEnum.UpdateUser, ActionPermissionEnumDTO.UpdateUser],
    [PermissionTypeEnum.DeleteUser, ActionPermissionEnumDTO.DeleteUser],
    [PermissionTypeEnum.BlockUnblockUser, ActionPermissionEnumDTO.BlockUnblockUser],
    [PermissionTypeEnum.CreateInvestor, ActionPermissionEnumDTO.CreateInvestor],
    [PermissionTypeEnum.UpdateInvestor, ActionPermissionEnumDTO.UpdateInvestor],
    [PermissionTypeEnum.DeleteInvestor, ActionPermissionEnumDTO.DeleteInvestor],
    [PermissionTypeEnum.ListWealthInvestors, ActionPermissionEnumDTO.ListWealthInvestors],
    [PermissionTypeEnum.LinkUnlinkInvestor, ActionPermissionEnumDTO.LinkUnlinkInvestor],
    [PermissionTypeEnum.ResendInvestorInvitation, ActionPermissionEnumDTO.ResendInvestorInvitation],
    [PermissionTypeEnum.BlockUnblockInvestor, ActionPermissionEnumDTO.BlockUnblockInvestor],
    [PermissionTypeEnum.ShareDealNotesWithInvestors, ActionPermissionEnumDTO.ShareDealNotesWithInvestors],
    [PermissionTypeEnum.ShareDealFilesWithInvestors, ActionPermissionEnumDTO.ShareDealFilesWithInvestors],
    [PermissionTypeEnum.ExitInvestorDeal, ActionPermissionEnumDTO.ExitInvestorDeal],
    [PermissionTypeEnum.CreatePipelineDeal, ActionPermissionEnumDTO.CreatePipelineDeal],
    [PermissionTypeEnum.CreatePortfolioDeal, ActionPermissionEnumDTO.CreatePortfolioDeal],
    [PermissionTypeEnum.UpdatePipelineDeal, ActionPermissionEnumDTO.UpdatePipelineDeal],
    [PermissionTypeEnum.UpdatePortfolioDeal, ActionPermissionEnumDTO.UpdatePortfolioDeal],
    [PermissionTypeEnum.DeletePipelineDeal, ActionPermissionEnumDTO.DeletePipelineDeal],
    [PermissionTypeEnum.DeletePortfolioDeal, ActionPermissionEnumDTO.DeletePortfolioDeal],
    [PermissionTypeEnum.WonPipelineDeal, ActionPermissionEnumDTO.WonPipelineDeal],
    [PermissionTypeEnum.LostPipelineDeal, ActionPermissionEnumDTO.LostPipelineDeal],
    [PermissionTypeEnum.ReopenDeal, ActionPermissionEnumDTO.ReopenDeal],
    [PermissionTypeEnum.EnableDisableAngelInvVote, ActionPermissionEnumDTO.EnableDisableAngelInvVote],
    [PermissionTypeEnum.CreateContact, ActionPermissionEnumDTO.CreateContact],
    [PermissionTypeEnum.UpdateContact, ActionPermissionEnumDTO.UpdateContact],
    [PermissionTypeEnum.DeleteContact, ActionPermissionEnumDTO.DeleteContact],
    [PermissionTypeEnum.LinkContactToPipelineDeal, ActionPermissionEnumDTO.LinkContactToPipelineDeal],
    [PermissionTypeEnum.LinkContactToPortfolioDeal, ActionPermissionEnumDTO.LinkContactToPortfolioDeal],
    [PermissionTypeEnum.CreatePipelineNote, ActionPermissionEnumDTO.CreatePipelineNote],
    [PermissionTypeEnum.CreatePortfolioNote, ActionPermissionEnumDTO.CreatePortfolioNote],
    [PermissionTypeEnum.UpdatePipelineNote, ActionPermissionEnumDTO.UpdatePipelineNote],
    [PermissionTypeEnum.UpdatePortfolioNote, ActionPermissionEnumDTO.UpdatePortfolioNote],
    [PermissionTypeEnum.CreatePipelineDealFile, ActionPermissionEnumDTO.CreatePipelineDealFile],
    [PermissionTypeEnum.CreatePortfolioDealFile, ActionPermissionEnumDTO.CreatePortfolioDealFile],
    [PermissionTypeEnum.DropboxImportPipelineDealFile, ActionPermissionEnumDTO.DropboxImportPipelineDealFile],
    [PermissionTypeEnum.DropboxImportPortfolioDealFile, ActionPermissionEnumDTO.DropboxImportPortfolioDealFile],
    [PermissionTypeEnum.DriveImportPipelineDealFile, ActionPermissionEnumDTO.GdriveImportPipelineDealFile],
    [PermissionTypeEnum.DriveImportPortfolioDealFile, ActionPermissionEnumDTO.GdriveImportPortfolioDealFile],
    [PermissionTypeEnum.UpdatePipelineDealFile, ActionPermissionEnumDTO.UpdatePipelineDealFile],
    [PermissionTypeEnum.UpdatePortfolioDealFile, ActionPermissionEnumDTO.UpdatePortfolioDealFile],
    [PermissionTypeEnum.DeletePipelineDealFile, ActionPermissionEnumDTO.DeletePipelineDealFile],
    [PermissionTypeEnum.DeletePortfolioDealFile, ActionPermissionEnumDTO.DeletePortfolioDealFile],
    [PermissionTypeEnum.DownloadPipelineDealFile, ActionPermissionEnumDTO.DownloadPipelineDealFile],
    [PermissionTypeEnum.DownloadPortfolioDealFile, ActionPermissionEnumDTO.DownloadPortfolioDealFile],
    [PermissionTypeEnum.CreateUpdateReport, ActionPermissionEnumDTO.CreateOrUpdateReport],
    [PermissionTypeEnum.ShareReport, ActionPermissionEnumDTO.ShareReport],
    [PermissionTypeEnum.DeleteReport, ActionPermissionEnumDTO.DeleteReport],
    [PermissionTypeEnum.CreatePipelineMetricsGroup, ActionPermissionEnumDTO.CreatePipelineMetricsGroup],
    [PermissionTypeEnum.CreatePortfolioMetricsGroup, ActionPermissionEnumDTO.CreatePortfolioMetricsGroup],
    [PermissionTypeEnum.UpdatePipelineMetricsGroup, ActionPermissionEnumDTO.UpdatePipelineMetricsGroup],
    [PermissionTypeEnum.UpdatePortfolioMetricsGroup, ActionPermissionEnumDTO.UpdatePortfolioMetricsGroup],
    [PermissionTypeEnum.DeletePipelineMetricsGroup, ActionPermissionEnumDTO.DeletePipelineMetricsGroup],
    [PermissionTypeEnum.DeletePortfolioMetricsGroup, ActionPermissionEnumDTO.DeletePortfolioMetricsGroup],
    [PermissionTypeEnum.MigratePipeDriveData, ActionPermissionEnumDTO.MigratePipedriveData],
    [PermissionTypeEnum.IntegrateZoom, ActionPermissionEnumDTO.IntegrateZoom],
    [PermissionTypeEnum.GoogleCalendarIntegration, ActionPermissionEnumDTO.GoogleCalendarIntegration],
    [PermissionTypeEnum.ListTasks, ActionPermissionEnumDTO.ListTasks],
    [PermissionTypeEnum.CreateTask, ActionPermissionEnumDTO.CreateTask],
    [PermissionTypeEnum.CreatePipelineDealTask, ActionPermissionEnumDTO.CreatePipelineDealTask],
    [PermissionTypeEnum.CreatePortfolioDealTask, ActionPermissionEnumDTO.CreatePortfolioDealTask],
    [PermissionTypeEnum.UpdateTask, ActionPermissionEnumDTO.UpdateTask],
    [PermissionTypeEnum.UpdatePipelineDealTask, ActionPermissionEnumDTO.UpdatePipelineDealTask],
    [PermissionTypeEnum.UpdatePortfolioDealTask, ActionPermissionEnumDTO.UpdatePortfolioDealTask],
    [PermissionTypeEnum.DeleteTask, ActionPermissionEnumDTO.DeleteTask],
    [PermissionTypeEnum.DeletePipelineDealTask, ActionPermissionEnumDTO.DeletePipelineDealTask],
    [PermissionTypeEnum.DeletePortfolioDealTask, ActionPermissionEnumDTO.DeletePortfolioDealTask],
    [PermissionTypeEnum.VoteOnDealTask, ActionPermissionEnumDTO.VoteOnDealTask],
    [PermissionTypeEnum.VoteOnPipelineDealTask, ActionPermissionEnumDTO.VoteOnPipelineDealTask],
    [PermissionTypeEnum.VoteOnPortfolioDealTask, ActionPermissionEnumDTO.VoteOnPortfolioDealTask],
    [PermissionTypeEnum.ListDashboardNotifications, ActionPermissionEnumDTO.ListDashboardNotifications],
    [PermissionTypeEnum.ListDashboardFiles, ActionPermissionEnumDTO.ListDashboardFiles],
    [PermissionTypeEnum.ListDashboardNotes, ActionPermissionEnumDTO.ListDashboardNotes],
    [PermissionTypeEnum.ListDashboardEmails, ActionPermissionEnumDTO.ListDashboardEmails],

    [PermissionTypeEnum.ListAngelInvestors, ActionPermissionEnumDTO.ListAngelInvestors],
    [PermissionTypeEnum.CreateAngelInvestor, ActionPermissionEnumDTO.CreateAngelInvestor],
    [PermissionTypeEnum.DeleteAngelInvestor, ActionPermissionEnumDTO.DeleteAngelInvestor],
    [PermissionTypeEnum.LinkUnlinkPipelineDealToAngelInvestors, ActionPermissionEnumDTO.LinkUnlinkPipelineDealToAngelInvestors],
    [PermissionTypeEnum.LinkUnlinkPortfolioDealToAngelInvestors, ActionPermissionEnumDTO.LinkUnlinkPortfolioDealToAngelInvestors],
    [PermissionTypeEnum.ExportAngelInvestorVoteResult, ActionPermissionEnumDTO.ExportAngelInvestorVoteResult],
    [PermissionTypeEnum.UpdateAngelInvestor, ActionPermissionEnumDTO.UpdateAngelInvestor],
    [PermissionTypeEnum.ShareNotesWithAngelInvestor, ActionPermissionEnumDTO.ShareNotesWithAngelInvestor],

    [PermissionTypeEnum.DashboardPage, PagePermissionEnumDTO.DashboardPage],
    [PermissionTypeEnum.PipelinePage, PagePermissionEnumDTO.PipelinePage],
    [PermissionTypeEnum.PortfolioPage, PagePermissionEnumDTO.PortfolioPage],
    [PermissionTypeEnum.ReportPage, PagePermissionEnumDTO.ReportPage],
    [PermissionTypeEnum.ContactPage, PagePermissionEnumDTO.ContactPage],
    [PermissionTypeEnum.TaskPage, PagePermissionEnumDTO.TaskPage],
    [PermissionTypeEnum.CompanySettingPage, PagePermissionEnumDTO.CompanySettingPage],
    [PermissionTypeEnum.UserSettingPage, PagePermissionEnumDTO.UserSettingPage],
    [PermissionTypeEnum.FundSettingPage, PagePermissionEnumDTO.FundSettingPage],
    [PermissionTypeEnum.FormSettingPage, PagePermissionEnumDTO.FormSettingPage],
    [PermissionTypeEnum.CustomFieldSettingPage, PagePermissionEnumDTO.CustomFieldSettingPage],
    [PermissionTypeEnum.IntegrationSettingPage, PagePermissionEnumDTO.IntegrationSettingPage],
    [PermissionTypeEnum.SettingsPage, PagePermissionEnumDTO.SettingsPage],
    [PermissionTypeEnum.PipelineDealNotePage, PagePermissionEnumDTO.PipelineDealNotePage],
    [PermissionTypeEnum.PortfolioDealNotePage, PagePermissionEnumDTO.PortfolioDealNotePage],
    [PermissionTypeEnum.PipelineDealEmailPage, PagePermissionEnumDTO.PipelineDealEmailPage],
    [PermissionTypeEnum.PortfolioDealEmailPage, PagePermissionEnumDTO.PortfolioDealEmailPage],
    [PermissionTypeEnum.PipelineDealTaskPage, PagePermissionEnumDTO.PipelineDealTaskPage],
    [PermissionTypeEnum.PortfolioDealTaskPage, PagePermissionEnumDTO.PortfolioDealTaskPage],
    [PermissionTypeEnum.PipelineDealFilePage, PagePermissionEnumDTO.PipelineDealFilePage],
    [PermissionTypeEnum.PortfolioDealFilePage, PagePermissionEnumDTO.PortfolioDealFilePage],
    [PermissionTypeEnum.PipelineDealMetricPage, PagePermissionEnumDTO.PipelineDealMetricPage],
    [PermissionTypeEnum.PortfolioDealMetricPage, PagePermissionEnumDTO.PortfolioDealMetricPage],
    [PermissionTypeEnum.PipelineDealHistoryPage, PagePermissionEnumDTO.PipelineDealHistoryPage],
    [PermissionTypeEnum.PortfolioDealHistoryPage, PagePermissionEnumDTO.PortfolioDealHistoryPage],
  ]);
  export const convertToRoute: IMap<PermissionTypeEnum, string> = new IMap<PermissionTypeEnum, string>([
    [PermissionTypeEnum.PipelineDealNotePage, '/Pipeline/notes'],
    [PermissionTypeEnum.PortfolioDealNotePage, '/Portfolio/notes'],
    [PermissionTypeEnum.PipelineDealEmailPage, '/Pipeline/emails'],
    [PermissionTypeEnum.PortfolioDealEmailPage, '/Portfolio/emails'],
    [PermissionTypeEnum.PipelineDealTaskPage, '/Pipeline/tasks'],
    [PermissionTypeEnum.PortfolioDealTaskPage, '/Portfolio/tasks'],
    [PermissionTypeEnum.PipelineDealFilePage, '/Pipeline/files'],
    [PermissionTypeEnum.PortfolioDealFilePage, '/Portfolio/files'],
    [PermissionTypeEnum.PipelineDealMetricPage, '/Pipeline/metrics'],
    [PermissionTypeEnum.PortfolioDealMetricPage, '/Portfolio/metrics'],
    [PermissionTypeEnum.PipelineDealHistoryPage, '/Pipeline/history'],
    [PermissionTypeEnum.PortfolioDealHistoryPage, '/Portfolio/history'],
  ]);

  export const convertToRouteSetting: IMap<PermissionTypeEnum, string> = new IMap<PermissionTypeEnum, string>([
    [PermissionTypeEnum.CompanySettingPage, '/company'],
    [PermissionTypeEnum.UserSettingPage, '/users'],
    [PermissionTypeEnum.PipelinePage, '/pipelines'],
    [PermissionTypeEnum.PortfolioPage, '/portfolio'],
    [PermissionTypeEnum.FundSettingPage, '/funds'],
    [PermissionTypeEnum.FormSettingPage, '/forms'],
    [PermissionTypeEnum.CustomFieldSettingPage, '/custom-fields'],
    [PermissionTypeEnum.IntegrationSettingPage, '/integrations'],
  ]);

  export const convertToRouteSideBar: IMap<PermissionTypeEnum, string> = new IMap<PermissionTypeEnum, string>([
    [PermissionTypeEnum.DashboardPage, '/dashboard'],
    [PermissionTypeEnum.PipelinePage, '/pipeline'],
    [PermissionTypeEnum.PortfolioPage, '/portfolio'],
    [PermissionTypeEnum.ReportPage, '/reports'],
    [PermissionTypeEnum.ContactPage, '/contacts'],
    [PermissionTypeEnum.TaskPage, '/tasks'],
    [PermissionTypeEnum.SettingsPage, '/settings'],
  ]);
}
