import { PayloadAction } from '@reduxjs/toolkit';
import { reset } from 'redux-form';
import { all, put, takeLatest } from 'redux-saga/effects';

import { FormIdEnum } from '../../../../core/enums/entity-ids/form-id.enum';

import * as formActions from './form.actions';

function* resetReduxFormEffect({ payload }: PayloadAction<FormIdEnum>): Generator {
  yield put(reset(payload));
}

export function* watchReduxForm(): Generator {
  yield all([takeLatest(formActions.resetReduxForm.type, resetReduxFormEffect)]);
}
