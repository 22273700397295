import { RbacPermissionDTO } from '../../generated/model/models';

export interface PermissionSummaryData {
  id: string;
  name: string;
  isEnabled: boolean;
  relatedPermissions: PermissionSummaryData[];
}

export namespace PermissionSummaryData {
  export function mapFromApi(permission: RbacPermissionDTO): PermissionSummaryData {
    return {
      id: permission.permission_id,
      name: permission.permission_name,
      isEnabled: permission.isEnabled,
      relatedPermissions: (permission.related_permissions || []).map(related => PermissionSummaryData.mapFromApi(related)),
    };
  }
}
