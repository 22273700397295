import {
  COMPANY_LOADED,
  COMPANY_UPDATED,
  COMPANY_LOADING,
  UPDATE_COMPANY_FAILED,
  CUSTOM_FIELDS_LOADING,
  CUSTOM_FIELDS_LOADED,
  CUSTOM_FIELD_CREATED,
  CUSTOM_FIELD_DELETED,
  CUSTOM_FIELD_UPDATED,
} from 'ReduxActions/settingActions';

import remove from 'lodash/remove';
import { replace } from 'Utils/arrayUtils.js';

const initialState = {
  myCompany: null,
  loading: false,
  errors: [],
  customFields: {},
  pipelineCustomFields: [],
  portfolioCustomFields: [],
  contactCustomFields: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CUSTOM_FIELDS_LOADING:
    case COMPANY_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case COMPANY_LOADED:
      return {
        ...state,
        myCompany: action.payload.length ? action.payload[0] : null,
        loading: false,
      };

    case COMPANY_UPDATED:
      return {
        ...state,
        myCompany: action.payload,
        loading: false,
        errors: [],
      };

    case UPDATE_COMPANY_FAILED:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };

    case CUSTOM_FIELD_UPDATED:
      const updatedFields = {
        ...state.customFields,
        [action.payload.section]: replace(state.customFields[action.payload.section], { field_id: action.payload.field_id }, action.payload),
      };
      return {
        ...state,
        customFields: updatedFields,
        pipelineCustomFields: updatedFields.pipeline.filter(it => it.show),
        portfolioCustomFields: updatedFields.portfolio.filter(it => it.show),
        contactCustomFields: updatedFields.contact.filter(it => it.show),
      };

    case CUSTOM_FIELD_CREATED:
      const newFiels = {
        ...state.customFields,
        [action.payload.section]: [action.payload, ...state.customFields[action.payload.section]],
      };
      return {
        ...state,
        customFields: newFiels,
        pipelineCustomFields: newFiels.pipeline.filter(it => it.show),
        portfolioCustomFields: newFiels.portfolio.filter(it => it.show),
        contactCustomFields: newFiels.contact.filter(it => it.show),
      };

    case CUSTOM_FIELD_DELETED:
      const deleteFiels = state.customFields;
      remove(state.customFields[action.payload.section], { field_id: action.payload.field_id });
      return {
        ...state,
        customFields: deleteFiels,
        pipelineCustomFields: deleteFiels.pipeline.filter(it => it.show),
        portfolioCustomFields: deleteFiels.portfolio.filter(it => it.show),
        contactCustomFields: deleteFiels.contact.filter(it => it.show),
      };

    case CUSTOM_FIELDS_LOADED:
      return {
        ...state,
        customFields: action.payload,
        pipelineCustomFields: action.payload.pipeline.filter(it => it.show),
        portfolioCustomFields: action.payload.portfolio.filter(it => it.show),
        contactCustomFields: action.payload.contact.filter(it => it.show),
      };
  }

  return state;
}
