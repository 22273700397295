import { DashboardTaskDetailsData } from '../../models/dashboard-task/dashboard-task-details-data';
import { IMap } from '../../utils/map-utils';

export enum DashboardTaskFormNamesEnum {
  Title = 1,
  DueDate = 2,
  Priority = 3,
  Description = 4,
  People = 5,
  Assignee = 6,
  RelatedDeal = 7,
  Type = 8,
  Files = 9,
  Zoom = 10,
}

export namespace DashboardTaskFormNamesEnum {
  export type formFieldName = keyof DashboardTaskDetailsData;

  export const toString: IMap<DashboardTaskFormNamesEnum, string> = new IMap<DashboardTaskFormNamesEnum, string>(
    [
      [DashboardTaskFormNamesEnum.Title, 'Title'],
      [DashboardTaskFormNamesEnum.DueDate, 'Due Date'],
      [DashboardTaskFormNamesEnum.Priority, 'priority'],
      [DashboardTaskFormNamesEnum.Description, 'Add notes'],
      [DashboardTaskFormNamesEnum.People, 'Invite people'],
      [DashboardTaskFormNamesEnum.Assignee, 'Assign to'],
      [DashboardTaskFormNamesEnum.RelatedDeal, 'Related deal'],
      [DashboardTaskFormNamesEnum.Type, 'Type'],
      [DashboardTaskFormNamesEnum.Files, 'Attach file'],
      [DashboardTaskFormNamesEnum.Zoom, 'Make it Zoom Call'],
    ],
    null,
  );

  export const formFields: IMap<DashboardTaskFormNamesEnum, formFieldName> = new IMap<DashboardTaskFormNamesEnum, formFieldName>([
    [DashboardTaskFormNamesEnum.Title, 'title'],
    [DashboardTaskFormNamesEnum.DueDate, 'dueDate'],
    [DashboardTaskFormNamesEnum.Priority, 'priority'],
    [DashboardTaskFormNamesEnum.Description, 'description'],
    [DashboardTaskFormNamesEnum.People, 'people'],
    [DashboardTaskFormNamesEnum.Assignee, 'assignee'],
    [DashboardTaskFormNamesEnum.RelatedDeal, 'deal'],
    [DashboardTaskFormNamesEnum.Type, 'type'],
    [DashboardTaskFormNamesEnum.Files, 'files'],
    [DashboardTaskFormNamesEnum.Zoom, 'zoom'],
  ]);
}
