import lodash from 'lodash';
import { RbacSyncRolePermissionsRequest } from '../../generated/model/models';
import { PageActionPermissionData } from './page-action-permission-data';

export interface PermissionForUpdate extends PageActionPermissionData {
  role: string;
}

export namespace PermissionForUpdate {
  export function mapToApi(data: PermissionForUpdate): RbacSyncRolePermissionsRequest {
    const permissionsIDs = lodash.flatten([
      lodash.flatten(data.pages.filter(perm => perm.parent.isEnabled).map(page => page.parent.id)),
      lodash.flatten(data.pages.map(page => page.children.filter(perm => perm.isEnabled).map(permission => permission.id))),
      lodash.flatten(lodash.concat(data.actions.map(block => block.permissions.filter(perm => perm.isEnabled).map(permission => permission.id)))),
    ]);

    return { permissionsIDs };
  }
}
