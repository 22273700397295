import { NewsAddTagsRequestDTO } from '../../generated/model/newsAddTagsRequestDTO';

export interface NewsTagForCreate {
  tagValue: string;
}

export namespace NewsTagForCreate {
  export function mapToApi(value: NewsTagForCreate): NewsAddTagsRequestDTO {
    return {
      news_tag: value.tagValue,
    };
  }
}
