import moment from 'moment';
import React from 'react';
import SubmitButton from 'SharedComponent/SubmitButton';
import CustomTag from 'SharedComponent/CustomTag';
import { PentugramLogo2C } from 'SharedComponent/PentugramLogo';
import { PermissibleRender } from 'SharedComponent/permissions';
import { PAYMENT_STATUS, BILLABLE_STATUS } from 'Enums/permissions';

import './PlanCard.scss';

export default function PlanCard(props) {
  return (
    <div className='PlanCard'>
      <PentugramLogo2C colors={props.packLogo} />
      <div className='display-flex-start'>
        <span className='lable-text'>Your current plan is: </span>
        <span className='lable-text-value ml-2'>
          <CustomTag color={props.tagColor} label={props.packName} />
        </span>
      </div>
      <div className='text-left'>
        <span className='lable-text'>Billing Frequency: </span>
        <span className='lable-text-value'>{props.frequency}</span>
      </div>
      <div className='text-left'>
        <span className='lable-text'>Using Pentugram since: </span>
        <span className='lable-text-value'>{moment(props.createdAt * 1000).format('MMMM Do YYYY')}</span>
      </div>
      <PermissibleRender
        userPermissions={[]}
        requiredPermissions={[[PAYMENT_STATUS.STATUS_TRIALING]]}
        renderOtherwise={
          <div className='text-left'>
            <span className='lable-text'>Plan will expire on: </span>
            <span className='lable-text-value'>{props.planDate ? moment(props.planDate * 1000).format('MMMM Do YYYY') : ''}</span>
          </div>
        }
      >
        <div className='text-left'>
          <span className='lable-text'>Trial expiry date: </span>
          <span className='lable-text-value'>{moment(props.expiryDate * 1000).format('MMMM Do YYYY')}</span>
        </div>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={[]}
        requiredPermissions={[[BILLABLE_STATUS.BILLABLE_COMPANY]]}
        renderOtherwise={<SubmitButton label='Enter your coupon' icon='' style={{ width: '100%' }} onClick={() => props.onEnterCoupon()} />}
      >
        {props.canSubscribe ? (
          <SubmitButton label='SUBSCRIBE' icon='icon-check' style={{ width: '100%' }} onClick={() => props.onSubscribe()} />
        ) : props.canUpgrade ? (
          <SubmitButton label='UPGRADE' icon='icon-arrow-up-circle' style={{ width: '100%' }} onClick={() => props.onUpgrade()} />
        ) : null}
      </PermissibleRender>
    </div>
  );
}
