import { ZoomCreateMeetingTaskRequestDTO } from '../../generated/model/zoomCreateMeetingTaskRequestDTO';

export interface DashboardZoomTaskForCreate {
  guests: string[];
  attendees: (string | number)[];
}

export namespace DashboardZoomTaskForCreate {
  export function mapToApiValue(data: DashboardZoomTaskForCreate): ZoomCreateMeetingTaskRequestDTO {
    return {
      guests: data.guests || [],
      attendees: (data.attendees || []).map(i => Number(i)),
    };
  }
}
