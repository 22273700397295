import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from 'reactstrap/lib/Alert';
import { clearAlert } from 'ReduxActions/notificationActions';
import Profile from '../Profile';

import './ProfileContext.scss';
const actions = { clearAlert };
class ProfileContext extends Component {
  constructor(props) {
    super(props);

    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.props.clearAlert();
  }

  componentWillUnmount() {
    this.props.clearAlert();
  }

  render() {
    const alert = this.props.alert;
    return (
      <div className='ProfileContext'>
        <div className='display-flex-between'>
          <div className='display-flex-start'>
            <div className='breadcrumbs-card'>
              <i className='icon-user' />
            </div>
            <div className='breadcrumbs-card-text'>Account Settings</div>
          </div>
          <div className='display-flex-end'>
            <Alert color={(alert && alert.type) || 'info'} isOpen={(alert && alert.isOpen) || false} toggle={this.onDismiss}>
              {alert && alert.message}
            </Alert>
          </div>
        </div>
        <Profile />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alert: state.notifs.alert,
  };
}

export default connect(mapStateToProps, actions)(ProfileContext);
