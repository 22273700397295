import { UserLightDTO } from '../../generated/model/userLightDTO';

export interface SenderLightData {
  senderId: string;
  senderName: string;
  senderImage: string;
}

export namespace SenderLightData {
  export function mapFromApi(sender: UserLightDTO): SenderLightData {
    return {
      senderId: sender.id.toString(),
      senderName: sender.fullname,
      senderImage: sender.image,
    };
  }
}
