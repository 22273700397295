import find from 'lodash/find';

export const countries = [
  {
    label: 'Afghanistan',
    value: 'AF',
    code: 'AF',
    image: 'https://www.worldometers.info//img/flags/small/tn_af-flag.gif',
    ar_name: 'أفغانستان',
  },

  {
    label: 'Albania',
    value: 'AL',
    code: 'AL',
    image: 'https://www.worldometers.info//img/flags/small/tn_al-flag.gif',
    ar_name: 'ألبانيا',
  },
  {
    label: 'Algeria',
    value: 'DZ',
    code: 'DZ',
    image: 'https://www.worldometers.info//img/flags/small/tn_ag-flag.gif',
    ar_name: 'الجزائر',
  },

  {
    label: 'AndorrA',
    value: 'AD',
    code: 'AD',
    image: 'https://www.worldometers.info//img/flags/small/tn_an-flag.gif',
    ar_name: 'أندورا',
  },
  {
    label: 'Angola',
    value: 'AO',
    code: 'AO',
    image: 'https://www.worldometers.info//img/flags/small/tn_ao-flag.gif',
    ar_name: 'أنجولا',
  },

  {
    label: 'Antigua and Barbuda',
    value: 'AG',
    code: 'AG',
    image: 'https://www.worldometers.info//img/flags/small/tn_ac-flag.gif',
    ar_name: 'أنتيجوا وبربودا',
  },
  {
    label: 'Argentina',
    value: 'AR',
    code: 'AR',
    image: 'https://www.worldometers.info//img/flags/small/tn_ar-flag.gif',
    ar_name: 'الأرجنتين',
  },
  {
    label: 'Armenia',
    value: 'AM',
    code: 'AM',
    image: 'https://www.worldometers.info//img/flags/small/tn_am-flag.gif',
    ar_name: 'أرمينيا',
  },

  {
    label: 'Australia',
    value: 'AU',
    code: 'AU',
    image: 'https://www.worldometers.info//img/flags/small/tn_as-flag.gif',
    ar_name: 'أستراليا',
  },
  {
    label: 'Austria',
    value: 'AT',
    code: 'AT',
    image: 'https://www.worldometers.info//img/flags/small/tn_au-flag.gif',
    ar_name: 'النمسا',
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
    code: 'AZ',
    image: 'https://www.worldometers.info//img/flags/small/tn_aj-flag.gif',
    ar_name: 'أذربيجان',
  },
  {
    label: 'Bahamas',
    value: 'BS',
    code: 'BS',
    image: 'https://www.worldometers.info//img/flags/small/tn_bf-flag.gif',
    ar_name: 'الباهاما',
  },
  {
    label: 'Bahrain',
    value: 'BH',
    code: 'BH',
    image: 'https://www.worldometers.info//img/flags/small/tn_ba-flag.gif',
    ar_name: 'البحرين',
  },
  {
    label: 'Bangladesh',
    value: 'BD',
    code: 'BD',
    image: 'https://www.worldometers.info//img/flags/small/tn_bg-flag.gif',
    ar_name: 'بنجلاديش',
  },
  {
    label: 'Barbados',
    value: 'BB',
    code: 'BB',
    image: 'https://www.worldometers.info//img/flags/small/tn_bb-flag.gif',
    ar_name: 'بربادوس',
  },
  {
    label: 'Belarus',
    value: 'BY',
    code: 'BY',
    image: 'https://www.worldometers.info//img/flags/small/tn_bo-flag.gif',
    ar_name: 'روسيا البيضاء',
  },
  {
    label: 'Belgium',
    value: 'BE',
    code: 'BE',
    image: 'https://www.worldometers.info//img/flags/small/tn_be-flag.gif',
    ar_name: 'بلجيكا',
  },
  {
    label: 'Belize',
    value: 'BZ',
    code: 'BZ',
    image: 'https://www.worldometers.info//img/flags/small/tn_bh-flag.gif',
    ar_name: 'بليز',
  },
  {
    label: 'Benin',
    value: 'BJ',
    code: 'BJ',
    image: 'https://www.worldometers.info//img/flags/small/tn_bn-flag.gif',
    ar_name: 'بنين',
  },

  {
    label: 'Bhutan',
    value: 'BT',
    code: 'BT',
    image: 'https://www.worldometers.info//img/flags/small/tn_bt-flag.gif',
    ar_name: 'بوتان',
  },
  {
    label: 'Bolivia',
    value: 'BO',
    code: 'BO',
    image: 'https://www.worldometers.info//img/flags/small/tn_bl-flag.gif',
    ar_name: 'بوليفيا',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
    code: 'BA',
    image: 'https://www.worldometers.info//img/flags/small/tn_bk-flag.gif',
    ar_name: 'البوسنة والهرسك',
  },
  {
    label: 'Botswana',
    value: 'BW',
    code: 'BW',
    image: 'https://www.worldometers.info//img/flags/small/tn_bc-flag.gif',
    ar_name: 'بتسوانا',
  },

  {
    label: 'Brazil',
    value: 'BR',
    code: 'BR',
    image: 'https://www.worldometers.info//img/flags/small/tn_br-flag.gif',
    ar_name: 'البرازيل',
  },

  {
    label: 'Brunei Darussalam',
    value: 'BN',
    code: 'BN',
    image: 'https://www.worldometers.info//img/flags/small/tn_bx-flag.gif',
    ar_name: 'بروناي',
  },
  {
    label: 'Bulgaria',
    value: 'BG',
    code: 'BG',
    image: 'https://www.worldometers.info//img/flags/small/tn_bu-flag.gif',
    ar_name: 'بلغاريا',
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
    code: 'BF',
    image: 'https://www.worldometers.info//img/flags/small/tn_uv-flag.gif',
    ar_name: 'بوركينا فاسو',
  },
  {
    label: 'Burundi',
    value: 'BI',
    code: 'BI',
    image: 'https://www.worldometers.info//img/flags/small/tn_by-flag.gif',
    ar_name: 'بوروندي',
  },
  {
    label: 'Cambodia',
    value: 'KH',
    code: 'KH',
    image: 'https://www.worldometers.info//img/flags/small/tn_cb-flag.gif',
    ar_name: 'كمبوديا',
  },
  {
    label: 'Cameroon',
    value: 'CM',
    code: 'CM',
    image: 'https://www.worldometers.info//img/flags/small/tn_cm-flag.gif',
    ar_name: 'الكاميرون',
  },
  {
    label: 'Canada',
    value: 'CA',
    code: 'CA',
    image: 'https://www.worldometers.info//img/flags/small/tn_ca-flag.gif',
    ar_name: 'كندا',
  },
  {
    label: 'Cape Verde',
    value: 'CV',
    code: 'CV',
    image: 'https://www.worldometers.info//img/flags/small/tn_cv-flag.gif',
    ar_name: 'الرأس الأخضر',
  },

  {
    label: 'Central African Republic',
    value: 'CF',
    code: 'CF',
    image: 'https://www.worldometers.info//img/flags/small/tn_ct-flag.gif',
    ar_name: 'جمهورية افريقيا الوسطى',
  },
  {
    label: 'Chad',
    value: 'TD',
    code: 'TD',
    image: 'https://www.worldometers.info//img/flags/small/tn_cd-flag.gif',
    ar_name: 'تشاد',
  },
  {
    label: 'Chile',
    value: 'CL',
    code: 'CL',
    image: 'https://www.worldometers.info//img/flags/small/tn_ci-flag.gif',
    ar_name: 'شيلي',
  },
  {
    label: 'China',
    value: 'CN',
    code: 'CN',
    image: 'https://www.worldometers.info//img/flags/small/tn_ch-flag.gif',
    ar_name: 'الصين',
  },

  {
    label: 'Colombia',
    value: 'CO',
    code: 'CO',
    image: 'https://www.worldometers.info//img/flags/small/tn_co-flag.gif',
    ar_name: 'كولومبيا',
  },
  {
    label: 'Comoros',
    value: 'KM',
    code: 'KM',
    image: 'https://www.worldometers.info//img/flags/small/tn_cn-flag.gif',
    ar_name: 'جزر القمر',
  },
  {
    label: 'Congo',
    value: 'CG',
    code: 'CG',
    image: 'https://www.worldometers.info//img/flags/small/tn_cg-flag.gif',
    ar_name: 'الكونغو - برازافيل',
  },
  {
    label: 'The Democratic Republic of the Congo',
    value: 'CD',
    code: 'CD',
    image: 'https://www.worldometers.info//img/flags/small/tn_congo-flag.gif',
    ar_name: 'جمهورية الكونغو الديموقراطية',
  },

  {
    label: 'Costa Rica',
    value: 'CR',
    code: 'CR',
    image: 'https://www.worldometers.info//img/flags/small/tn_cs-flag.gif',
    ar_name: 'كوستاريكا',
  },
  {
    label: "Cote D'Ivoire",
    value: 'CI',
    code: 'CI',
    image: 'https://www.worldometers.info//img/flags/small/tn_iv-flag.gif',
    ar_name: 'ساحل العاج',
  },
  {
    label: 'Croatia',
    value: 'HR',
    code: 'HR',
    image: 'https://www.worldometers.info//img/flags/small/tn_hr-flag.gif',
    ar_name: 'كرواتيا',
  },
  {
    label: 'Cuba',
    value: 'CU',
    code: 'CU',
    image: 'https://www.worldometers.info//img/flags/small/tn_cu-flag.gif',
    ar_name: 'كوبا',
  },
  {
    label: 'Cyprus',
    value: 'CY',
    code: 'CY',
    image: 'https://www.worldometers.info//img/flags/small/tn_cy-flag.gif',
    ar_name: 'قبرص',
  },
  {
    label: 'Czech Republic',
    value: 'CZ',
    code: 'CZ',
    image: 'https://www.worldometers.info//img/flags/small/tn_ez-flag.gif',
    ar_name: 'جمهورية التشيك',
  },
  {
    label: 'Denmark',
    value: 'DK',
    code: 'DK',
    image: 'https://www.worldometers.info//img/flags/small/tn_da-flag.gif',
    ar_name: 'الدانمرك',
  },
  {
    label: 'Djibouti',
    value: 'DJ',
    code: 'DJ',
    image: 'https://www.worldometers.info//img/flags/small/tn_dj-flag.gif',
    ar_name: 'جيبوتي',
  },
  {
    label: 'Dominica',
    value: 'DM',
    code: 'DM',
    image: 'https://www.worldometers.info//img/flags/small/tn_do-flag.gif',
    ar_name: 'دومينيكا',
  },
  {
    label: 'Dominican Republic',
    value: 'DO',
    code: 'DO',
    image: 'https://www.worldometers.info//img/flags/small/tn_dr-flag.gif',
    ar_name: 'جمهورية الدومينيك',
  },
  {
    label: 'Ecuador',
    value: 'EC',
    code: 'EC',
    image: 'https://www.worldometers.info//img/flags/small/tn_ec-flag.gif',
    ar_name: 'الاكوادور',
  },
  {
    label: 'Egypt',
    value: 'EG',
    code: 'EG',
    image: 'https://www.worldometers.info//img/flags/small/tn_eg-flag.gif',
    ar_name: 'مصر',
  },
  {
    label: 'El Salvador',
    value: 'SV',
    code: 'SV',
    image: 'https://www.worldometers.info//img/flags/small/tn_es-flag.gif',
    ar_name: 'السلفادور',
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ',
    code: 'GQ',
    image: 'https://www.worldometers.info//img/flags/small/tn_ek-flag.gif',
    ar_name: 'غينيا الاستوائية',
  },
  {
    label: 'Eritrea',
    value: 'ER',
    code: 'ER',
    image: 'https://www.worldometers.info//img/flags/small/tn_er-flag.gif',
    ar_name: 'اريتريا',
  },
  {
    label: 'Estonia',
    value: 'EE',
    code: 'EE',
    image: 'https://www.worldometers.info//img/flags/small/tn_en-flag.gif',
    ar_name: 'استونيا',
  },
  {
    label: 'Ethiopia',
    value: 'ET',
    code: 'ET',
    image: 'https://www.worldometers.info//img/flags/small/tn_et-flag.gif',
    ar_name: 'اثيوبيا',
  },
  {
    label: 'Fiji',
    value: 'FJ',
    code: 'FJ',
    image: 'https://www.worldometers.info//img/flags/small/tn_fj-flag.gif',
    ar_name: 'فيجي',
  },
  {
    label: 'Finland',
    value: 'FI',
    code: 'FI',
    image: 'https://www.worldometers.info//img/flags/small/tn_fi-flag.gif',
    ar_name: 'فنلندا',
  },
  {
    label: 'France',
    value: 'FR',
    code: 'FR',
    image: 'https://www.worldometers.info//img/flags/small/tn_fr-flag.gif',
    ar_name: 'فرنسا',
  },
  {
    label: 'Gabon',
    value: 'GA',
    code: 'GA',
    image: 'https://www.worldometers.info//img/flags/small/tn_gb-flag.gif',
    ar_name: 'الجابون',
  },
  {
    label: 'Gambia',
    value: 'GM',
    code: 'GM',
    image: 'https://www.worldometers.info//img/flags/small/tn_ga-flag.gif',
    ar_name: 'غامبيا',
  },
  {
    label: 'Georgia',
    value: 'GE',
    code: 'GE',
    image: 'https://www.worldometers.info//img/flags/small/tn_gg-flag.gif',
    ar_name: 'جورجيا',
  },
  {
    label: 'Germany',
    value: 'DE',
    code: 'DE',
    image: 'https://www.worldometers.info//img/flags/small/tn_gm-flag.gif',
    ar_name: 'ألمانيا',
  },
  {
    label: 'Ghana',
    value: 'GH',
    code: 'GH',
    image: 'https://www.worldometers.info//img/flags/small/tn_gh-flag.gif',
    ar_name: 'غانا',
  },
  {
    label: 'Greece',
    value: 'GR',
    code: 'GR',
    image: 'https://www.worldometers.info//img/flags/small/tn_gr-flag.gif',
    ar_name: 'اليونان',
  },
  {
    label: 'Grenada',
    value: 'GD',
    code: 'GD',
    image: 'https://www.worldometers.info//img/flags/small/tn_gj-flag.gif',
    ar_name: 'جرينادا',
  },
  {
    label: 'Guatemala',
    value: 'GT',
    code: 'GT',
    image: 'https://www.worldometers.info//img/flags/small/tn_gt-flag.gif',
    ar_name: 'جواتيمالا',
  },
  {
    label: 'Guinea',
    value: 'GN',
    code: 'GN',
    image: 'https://www.worldometers.info//img/flags/small/tn_gv-flag.gif',
    ar_name: 'غينيا',
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW',
    code: 'GW',
    image: 'https://www.worldometers.info//img/flags/small/tn_pu-flag.gif',
    ar_name: 'غينيا بيساو',
  },
  {
    label: 'Guyana',
    value: 'GY',
    code: 'GY',
    image: 'https://www.worldometers.info//img/flags/small/tn_gy-flag.gif',
    ar_name: 'غيانا',
  },
  {
    label: 'Haiti',
    value: 'HT',
    code: 'HT',
    image: 'https://www.worldometers.info//img/flags/small/tn_ha-flag.gif',
    ar_name: 'هايتي',
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'VA',
    code: 'VA',
    image: 'https://www.worldometers.info//img/flags/small/tn_vt-flag.gif',
    ar_name: 'الفاتيكان',
  },
  {
    label: 'Honduras',
    value: 'HN',
    code: 'HN',
    image: 'https://www.worldometers.info//img/flags/small/tn_ho-flag.gif',
    ar_name: 'هندوراس',
  },
  {
    label: 'Hong Kong',
    value: 'HK',
    code: 'HK',
    image: 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg',
    ar_name: 'هونج كونج الصينية',
  },
  {
    label: 'Hungary',
    value: 'HU',
    code: 'HU',
    image: 'https://www.worldometers.info//img/flags/small/tn_hu-flag.gif',
    ar_name: 'المجر',
  },
  {
    label: 'Iceland',
    value: 'IS',
    code: 'IS',
    image: 'https://www.worldometers.info//img/flags/small/tn_ic-flag.gif',
    ar_name: 'أيسلندا',
  },
  {
    label: 'India',
    value: 'IN',
    code: 'IN',
    image: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif',
    ar_name: 'الهند',
  },
  {
    label: 'Indonesia',
    value: 'ID',
    code: 'ID',
    image: 'https://www.worldometers.info//img/flags/small/tn_id-flag.gif',
    ar_name: 'اندونيسيا',
  },
  {
    label: 'Iran, Islamic Republic Of',
    value: 'IR',
    code: 'IR',
    image: 'https://www.worldometers.info//img/flags/small/tn_ir-flag.gif',
    ar_name: 'ايران',
  },
  {
    label: 'Iraq',
    value: 'IQ',
    code: 'IQ',
    image: 'https://www.worldometers.info//img/flags/small/tn_iz-flag.gif',
    ar_name: 'العراق',
  },
  {
    label: 'Ireland',
    value: 'IE',
    code: 'IE',
    image: 'https://www.worldometers.info//img/flags/small/tn_ei-flag.gif',
    ar_name: 'أيرلندا',
  },
  {
    label: 'Israel',
    value: 'IL',
    code: 'IL',
    image: 'https://www.worldometers.info//img/flags/small/tn_is-flag.gif',
    ar_name: 'Israel',
  },
  {
    label: 'Italy',
    value: 'IT',
    code: 'IT',
    image: 'https://www.worldometers.info//img/flags/small/tn_it-flag.gif',
    ar_name: 'ايطاليا',
  },
  {
    label: 'Jamaica',
    value: 'JM',
    code: 'JM',
    image: 'https://www.worldometers.info//img/flags/small/tn_jm-flag.gif',
    ar_name: 'جامايكا',
  },
  {
    label: 'Japan',
    value: 'JP',
    code: 'JP',
    image: 'https://www.worldometers.info//img/flags/small/tn_ja-flag.gif',
    ar_name: 'اليابان',
  },
  {
    label: 'Jordan',
    value: 'JO',
    code: 'JO',
    image: 'https://www.worldometers.info//img/flags/small/tn_jo-flag.gif',
    ar_name: 'الأردن',
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
    code: 'KZ',
    image: 'https://www.worldometers.info//img/flags/small/tn_kz-flag.gif',
    ar_name: 'كازاخستان',
  },
  {
    label: 'Kenya',
    value: 'KE',
    code: 'KE',
    image: 'https://www.worldometers.info//img/flags/small/tn_ke-flag.gif',
    ar_name: 'كينيا',
  },
  {
    label: 'Kiribati',
    value: 'KI',
    code: 'KI',
    image: 'https://www.worldometers.info//img/flags/small/tn_kr-flag.gif',
    ar_name: 'كيريباتي',
  },
  {
    label: "Korea, Democratic People'S Republic of",
    value: 'KP',
    code: 'KP',
    image: 'https://www.worldometers.info//img/flags/small/tn_kn-flag.gif',
    ar_name: 'كوريا الشمالية',
  },
  {
    label: 'Korea, Republic of',
    value: 'KR',
    code: 'KR',
    image: 'https://www.worldometers.info//img/flags/small/tn_ks-flag.gif',
    ar_name: 'كوريا الجنوبية',
  },
  {
    label: 'Kuwait',
    value: 'KW',
    code: 'KW',
    image: 'https://www.worldometers.info//img/flags/small/tn_ku-flag.gif',
    ar_name: 'الكويت',
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG',
    code: 'KG',
    image: 'https://www.worldometers.info//img/flags/small/tn_kg-flag.gif',
    ar_name: 'قرغيزستان',
  },
  {
    label: "Lao People'S Democratic Republic",
    value: 'LA',
    code: 'LA',
    image: 'https://www.worldometers.info//img/flags/small/tn_la-flag.gif',
    ar_name: 'لاوس',
  },
  {
    label: 'Latvia',
    value: 'LV',
    code: 'LV',
    image: 'https://www.worldometers.info//img/flags/small/tn_lg-flag.gif',
    ar_name: 'لاتفيا',
  },
  {
    label: 'Lebanon',
    value: 'LB',
    code: 'LB',
    image: 'https://www.worldometers.info//img/flags/small/tn_le-flag.gif',
    ar_name: 'لبنان',
  },
  {
    label: 'Lesotho',
    value: 'LS',
    code: 'LS',
    image: 'https://www.worldometers.info//img/flags/small/tn_lt-flag.gif',
    ar_name: 'ليسوتو',
  },
  {
    label: 'Liberia',
    value: 'LR',
    code: 'LR',
    image: 'https://www.worldometers.info//img/flags/small/tn_li-flag.gif',
    ar_name: 'ليبيريا',
  },
  {
    label: 'Libyan Arab Jamahiriya',
    value: 'LY',
    code: 'LY',
    image: 'https://www.worldometers.info//img/flags/small/tn_ly-flag.gif',
    ar_name: 'ليبيا',
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
    code: 'LI',
    image: 'https://www.worldometers.info//img/flags/small/tn_ls-flag.gif',
    ar_name: 'ليختنشتاين',
  },
  {
    label: 'Lithuania',
    value: 'LT',
    code: 'LT',
    image: 'https://www.worldometers.info//img/flags/small/tn_lh-flag.gif',
    ar_name: 'ليتوانيا',
  },
  {
    label: 'Luxembourg',
    value: 'LU',
    code: 'LU',
    image: 'https://www.worldometers.info//img/flags/small/tn_lu-flag.gif',
    ar_name: 'لوكسمبورج',
  },
  {
    label: 'Macedonia, The Former Yugoslav Republic of',
    value: 'MK',
    code: 'MK',
    image: 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_North_Macedonia.svg',
    ar_name: 'مقدونيا',
  },
  {
    label: 'Madagascar',
    value: 'MG',
    code: 'MG',
    image: 'https://www.worldometers.info//img/flags/small/tn_ma-flag.gif',
    ar_name: 'مدغشقر',
  },
  {
    label: 'Malawi',
    value: 'MW',
    code: 'MW',
    image: 'https://www.worldometers.info//img/flags/small/tn_mi-flag.gif',
    ar_name: 'ملاوي',
  },
  {
    label: 'Malaysia',
    value: 'MY',
    code: 'MY',
    image: 'https://www.worldometers.info//img/flags/small/tn_my-flag.gif',
    ar_name: 'ماليزيا',
  },
  {
    label: 'Maldives',
    value: 'MV',
    code: 'MV',
    image: 'https://www.worldometers.info//img/flags/small/tn_mv-flag.gif',
    ar_name: 'جزر الملديف',
  },
  {
    label: 'Mali',
    value: 'ML',
    code: 'ML',
    image: 'https://www.worldometers.info//img/flags/small/tn_ml-flag.gif',
    ar_name: 'مالي',
  },
  {
    label: 'Malta',
    value: 'MT',
    code: 'MT',
    image: 'https://www.worldometers.info//img/flags/small/tn_mt-flag.gif',
    ar_name: 'مالطا',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
    code: 'MH',
    image: 'https://www.worldometers.info//img/flags/small/tn_rm-flag.gif',
    ar_name: 'جزر المارشال',
  },
  {
    label: 'Mauritania',
    value: 'MR',
    code: 'MR',
    image: 'https://www.worldometers.info//img/flags/small/tn_mr-flag.gif',
    ar_name: 'موريتانيا',
  },
  {
    label: 'Mauritius',
    value: 'MU',
    code: 'MU',
    image: 'https://www.worldometers.info//img/flags/small/tn_mp-flag.gif',
    ar_name: 'موريشيوس',
  },
  {
    label: 'Mexico',
    value: 'MX',
    code: 'MX',
    image: 'https://www.worldometers.info//img/flags/small/tn_mx-flag.gif',
    ar_name: 'المكسيك',
  },
  {
    label: 'Micronesia, Federated States of',
    value: 'FM',
    code: 'FM',
    image: 'https://www.worldometers.info//img/flags/small/tn_fm-flag.gif',
    ar_name: 'ميكرونيزيا',
  },
  {
    label: 'Moldova, Republic of',
    value: 'MD',
    code: 'MD',
    image: 'https://www.worldometers.info//img/flags/small/tn_md-flag.gif',
    ar_name: 'مولدافيا',
  },
  {
    label: 'Monaco',
    value: 'MC',
    code: 'MC',
    image: 'https://www.worldometers.info//img/flags/small/tn_mn-flag.gif',
    ar_name: 'موناكو',
  },
  {
    label: 'Mongolia',
    value: 'MN',
    code: 'MN',
    image: 'https://www.worldometers.info//img/flags/small/tn_mg-flag.gif',
    ar_name: 'منغوليا',
  },
  {
    label: 'Morocco',
    value: 'MA',
    code: 'MA',
    image: 'https://www.worldometers.info//img/flags/small/tn_mo-flag.gif',
    ar_name: 'المغرب',
  },
  {
    label: 'Mozambique',
    value: 'MZ',
    code: 'MZ',
    image: 'https://www.worldometers.info//img/flags/small/tn_mz-flag.gif',
    ar_name: 'موزمبيق',
  },
  {
    label: 'Myanmar',
    value: 'MM',
    code: 'MM',
    image: 'https://www.worldometers.info//img/flags/small/tn_bm-flag.gif',
    ar_name: 'ميانمار',
  },
  {
    label: 'Namibia',
    value: 'NA',
    code: 'NA',
    image: 'https://www.worldometers.info//img/flags/small/tn_wa-flag.gif',
    ar_name: 'ناميبيا',
  },
  {
    label: 'Nauru',
    value: 'NR',
    code: 'NR',
    image: 'https://www.worldometers.info//img/flags/small/tn_nr-flag.gif',
    ar_name: 'نورو',
  },
  {
    label: 'Nepal',
    value: 'NP',
    code: 'NP',
    image: 'https://www.worldometers.info//img/flags/small/tn_np-flag.gif',
    ar_name: 'نيبال',
  },
  {
    label: 'Netherlands',
    value: 'NL',
    code: 'NL',
    image: 'https://www.worldometers.info//img/flags/small/tn_nl-flag.gif',
    ar_name: 'هولندا',
  },
  {
    label: 'New Zealand',
    value: 'NZ',
    code: 'NZ',
    image: 'https://www.worldometers.info//img/flags/small/tn_nz-flag.gif',
    ar_name: 'نيوزيلاندا',
  },
  {
    label: 'Nicaragua',
    value: 'NI',
    code: 'NI',
    image: 'https://www.worldometers.info//img/flags/small/tn_nu-flag.gif',
    ar_name: 'نيكاراجوا',
  },
  {
    label: 'Niger',
    value: 'NE',
    code: 'NE',
    image: 'https://www.worldometers.info//img/flags/small/tn_ng-flag.gif',
    ar_name: 'النيجر',
  },
  {
    label: 'Nigeria',
    value: 'NG',
    code: 'NG',
    image: 'https://www.worldometers.info//img/flags/small/tn_ni-flag.gif',
    ar_name: 'نيجيريا',
  },
  {
    label: 'Norway',
    value: 'NO',
    code: 'NO',
    image: 'https://www.worldometers.info//img/flags/small/tn_no-flag.gif',
    ar_name: 'النرويج',
  },
  {
    label: 'Oman',
    value: 'OM',
    code: 'OM',
    image: 'https://www.worldometers.info//img/flags/small/tn_mu-flag.gif',
    ar_name: 'عمان',
  },
  {
    label: 'Pakistan',
    value: 'PK',
    code: 'PK',
    image: 'https://www.worldometers.info//img/flags/small/tn_pk-flag.gif',
    ar_name: 'باكستان',
  },
  {
    label: 'Palau',
    value: 'PW',
    code: 'PW',
    image: 'https://www.worldometers.info//img/flags/small/tn_ps-flag.gif',
    ar_name: 'بالاو',
  },
  {
    label: 'Palestinian Territory, Occupied',
    value: 'PS',
    code: 'PS',
    image: 'https://www.worldometers.info//img/flags/small/tn_palestine-flag.gif',
    ar_name: 'فلسطين',
  },
  {
    label: 'Panama',
    value: 'PA',
    code: 'PA',
    image: 'https://www.worldometers.info//img/flags/small/tn_pm-flag.gif',
    ar_name: 'بنما',
  },
  {
    label: 'Papua New Guinea',
    value: 'PG',
    code: 'PG',
    image: 'https://www.worldometers.info//img/flags/small/tn_pp-flag.gif',
    ar_name: 'بابوا غينيا الجديدة',
  },
  {
    label: 'Paraguay',
    value: 'PY',
    code: 'PY',
    image: 'https://www.worldometers.info//img/flags/small/tn_pa-flag.gif',
    ar_name: 'باراجواي',
  },
  {
    label: 'Peru',
    value: 'PE',
    code: 'PE',
    image: 'https://www.worldometers.info//img/flags/small/tn_pe-flag.gif',
    ar_name: 'بيرو',
  },
  {
    label: 'Philippines',
    value: 'PH',
    code: 'PH',
    image: 'https://www.worldometers.info//img/flags/small/tn_rp-flag.gif',
    ar_name: 'الفيلبين',
  },
  {
    label: 'Poland',
    value: 'PL',
    code: 'PL',
    image: 'https://www.worldometers.info//img/flags/small/tn_pl-flag.gif',
    ar_name: 'بولندا',
  },
  {
    label: 'Portugal',
    value: 'PT',
    code: 'PT',
    image: 'https://www.worldometers.info//img/flags/small/tn_po-flag.gif',
    ar_name: 'البرتغال',
  },
  {
    label: 'Qatar',
    value: 'QA',
    code: 'QA',
    image: 'https://www.worldometers.info//img/flags/small/tn_qa-flag.gif',
    ar_name: 'قطر',
  },
  {
    label: 'Romania',
    value: 'RO',
    code: 'RO',
    image: 'https://www.worldometers.info//img/flags/small/tn_ro-flag.gif',
    ar_name: 'رومانيا',
  },
  {
    label: 'Russian Federation',
    value: 'RU',
    code: 'RU',
    image: 'https://www.worldometers.info//img/flags/small/tn_rs-flag.gif',
    ar_name: 'روسيا',
  },
  {
    label: 'RWANDA',
    value: 'RW',
    code: 'RW',
    image: 'https://www.worldometers.info//img/flags/small/tn_rw-flag.gif',
    ar_name: 'رواندا',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KN',
    code: 'KN',
    image: 'https://www.worldometers.info//img/flags/small/tn_sc-flag.gif',
    ar_name: 'سانت كيتس ونيفيس',
  },
  {
    label: 'Saint Lucia',
    value: 'LC',
    code: 'LC',
    image: 'https://www.worldometers.info//img/flags/small/tn_st-flag.gif',
    ar_name: 'سانت لوسيا',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VC',
    code: 'VC',
    image: 'https://www.worldometers.info//img/flags/small/tn_vc-flag.gif',
    ar_name: 'سانت فنسنت وغرنادين',
  },
  {
    label: 'Samoa',
    value: 'WS',
    code: 'WS',
    image: 'https://www.worldometers.info//img/flags/small/tn_ws-flag.gif',
    ar_name: 'ساموا',
  },
  {
    label: 'San Marino',
    value: 'SM',
    code: 'SM',
    image: 'https://www.worldometers.info//img/flags/small/tn_sm-flag.gif',
    ar_name: 'سان مارينو',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'ST',
    code: 'ST',
    image: 'https://www.worldometers.info//img/flags/small/tn_tp-flag.gif',
    ar_name: 'ساو تومي وبرينسيبي',
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
    code: 'SA',
    image: 'https://www.worldometers.info//img/flags/small/tn_sa-flag.gif',
    ar_name: 'المملكة العربية السعودية',
  },
  {
    label: 'Senegal',
    value: 'SN',
    code: 'SN',
    image: 'https://www.worldometers.info//img/flags/small/tn_sg-flag.gif',
    ar_name: 'السنغال',
  },
  {
    label: 'Serbia and Montenegro',
    value: 'CS',
    code: 'CS',
    image: 'https://www.worldometers.info//img/flags/small/tn_ri-flag.gif',
    ar_name: 'Serbia and Montenegro',
  },
  {
    label: 'Seychelles',
    value: 'SC',
    code: 'SC',
    image: 'https://www.worldometers.info//img/flags/small/tn_se-flag.gif',
    ar_name: 'سيشل',
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
    code: 'SL',
    image: 'https://www.worldometers.info//img/flags/small/tn_sl-flag.gif',
    ar_name: 'سيراليون',
  },
  {
    label: 'Singapore',
    value: 'SG',
    code: 'SG',
    image: 'https://www.worldometers.info//img/flags/small/tn_sn-flag.gif',
    ar_name: 'سنغافورة',
  },
  {
    label: 'Slovakia',
    value: 'SK',
    code: 'SK',
    image: 'https://www.worldometers.info//img/flags/small/tn_lo-flag.gif',
    ar_name: 'سلوفاكيا',
  },
  {
    label: 'Slovenia',
    value: 'SI',
    code: 'SI',
    image: 'https://www.worldometers.info//img/flags/small/tn_si-flag.gif',
    ar_name: 'سلوفينيا',
  },
  {
    label: 'Solomon Islands',
    value: 'SB',
    code: 'SB',
    image: 'https://www.worldometers.info//img/flags/small/tn_bp-flag.gif',
    ar_name: 'جزر سليمان',
  },
  {
    label: 'Somalia',
    value: 'SO',
    code: 'SO',
    image: 'https://www.worldometers.info//img/flags/small/tn_so-flag.gif',
    ar_name: 'الصومال',
  },
  {
    label: 'South Africa',
    value: 'ZA',
    code: 'ZA',
    image: 'https://www.worldometers.info//img/flags/small/tn_sf-flag.gif',
    ar_name: 'جمهورية جنوب افريقيا',
  },

  {
    label: 'Spain',
    value: 'ES',
    code: 'ES',
    image: 'https://www.worldometers.info//img/flags/small/tn_sp-flag.gif',
    ar_name: 'أسبانيا',
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
    code: 'LK',
    image: 'https://www.worldometers.info//img/flags/small/tn_ce-flag.gif',
    ar_name: 'سريلانكا',
  },
  {
    label: 'Sudan',
    value: 'SD',
    code: 'SD',
    image: 'https://www.worldometers.info//img/flags/small/tn_su-flag.gif',
    ar_name: 'السودان',
  },
  {
    label: 'Suriname',
    value: 'SR',
    code: 'SR',
    image: 'https://www.worldometers.info//img/flags/small/tn_ns-flag.gif',
    ar_name: 'سورينام',
  },
  {
    label: 'Swaziland',
    value: 'SZ',
    code: 'SZ',
    image: 'https://upload.wikimedia.org/wikipedia/commons/f/fb/Flag_of_Eswatini.svg',
    ar_name: 'سوازيلاند',
  },
  {
    label: 'Sweden',
    value: 'SE',
    code: 'SE',
    image: 'https://www.worldometers.info//img/flags/small/tn_sw-flag.gif',
    ar_name: 'السويد',
  },
  {
    label: 'Switzerland',
    value: 'CH',
    code: 'CH',
    image: 'https://www.worldometers.info//img/flags/small/tn_sz-flag.gif',
    ar_name: 'سويسرا',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'SY',
    code: 'SY',
    image: 'https://www.worldometers.info//img/flags/small/tn_sy-flag.gif',
    ar_name: 'سوريا',
  },
  {
    label: 'Taiwan, Province of China',
    value: 'TW',
    code: 'TW',
    image: 'https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg',
    ar_name: 'تايوان',
  },
  {
    label: 'Tajikistan',
    value: 'TJ',
    code: 'TJ',
    image: 'https://www.worldometers.info//img/flags/small/tn_ti-flag.gif',
    ar_name: 'طاجكستان',
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'TZ',
    code: 'TZ',
    image: 'https://www.worldometers.info//img/flags/small/tn_tz-flag.gif',
    ar_name: 'تانزانيا',
  },
  {
    label: 'Thailand',
    value: 'TH',
    code: 'TH',
    image: 'https://www.worldometers.info//img/flags/small/tn_th-flag.gif',
    ar_name: 'تايلند',
  },
  {
    label: 'Timor-Leste',
    value: 'TL',
    code: 'TL',
    image: 'https://www.worldometers.info//img/flags/small/tn_tt-flag.gif',
    ar_name: 'Timor-Leste',
  },
  {
    label: 'Togo',
    value: 'TG',
    code: 'TG',
    image: 'https://www.worldometers.info//img/flags/small/tn_to-flag.gif',
    ar_name: 'توجو',
  },
  {
    label: 'Tonga',
    value: 'TO',
    code: 'TO',
    image: 'https://www.worldometers.info//img/flags/small/tn_tn-flag.gif',
    ar_name: 'تونجا',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TT',
    code: 'TT',
    image: 'https://www.worldometers.info//img/flags/small/tn_td-flag.gif',
    ar_name: 'ترينيداد وتوباغو',
  },
  {
    label: 'Tunisia',
    value: 'TN',
    code: 'TN',
    image: 'https://www.worldometers.info//img/flags/small/tn_ts-flag.gif',
    ar_name: 'تونس',
  },
  {
    label: 'Turkey',
    value: 'TR',
    code: 'TR',
    image: 'https://www.worldometers.info//img/flags/small/tn_tu-flag.gif',
    ar_name: 'تركيا',
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
    code: 'TM',
    image: 'https://www.worldometers.info//img/flags/small/tn_tx-flag.gif',
    ar_name: 'تركمانستان',
  },
  {
    label: 'Tuvalu',
    value: 'TV',
    code: 'TV',
    image: 'https://www.worldometers.info//img/flags/small/tn_tv-flag.gif',
    ar_name: 'توفالو',
  },
  {
    label: 'Uganda',
    value: 'UG',
    code: 'UG',
    image: 'https://www.worldometers.info//img/flags/small/tn_ug-flag.gif',
    ar_name: 'أوغندا',
  },
  {
    label: 'Ukraine',
    value: 'UA',
    code: 'UA',
    image: 'https://www.worldometers.info//img/flags/small/tn_up-flag.gif',
    ar_name: 'أوكرانيا',
  },
  {
    label: 'United Arab Emirates',
    value: 'AE',
    code: 'AE',
    image: 'https://www.worldometers.info//img/flags/small/tn_ae-flag.gif',
    ar_name: 'الامارات العربية المتحدة',
  },
  {
    label: 'United Kingdom',
    value: 'GB',
    code: 'GB',
    image: 'https://www.worldometers.info//img/flags/small/tn_uk-flag.gif',
    ar_name: 'المملكة المتحدة',
  },
  {
    label: 'United States Of America',
    value: 'US',
    code: 'US',
    image: 'https://www.worldometers.info//img/flags/small/tn_us-flag.gif',
    ar_name: 'الولايات المتحدة الأمريكية',
  },
  {
    label: 'Uruguay',
    value: 'UY',
    code: 'UY',
    image: 'https://www.worldometers.info//img/flags/small/tn_uy-flag.gif',
    ar_name: 'أورجواي',
  },
  {
    label: 'Uzbekistan',
    value: 'UZ',
    code: 'UZ',
    image: 'https://www.worldometers.info//img/flags/small/tn_uz-flag.gif',
    ar_name: 'أوزبكستان',
  },
  {
    label: 'Vanuatu',
    value: 'VU',
    code: 'VU',
    image: 'https://www.worldometers.info//img/flags/small/tn_nh-flag.gif',
    ar_name: 'فانواتو',
  },
  {
    label: 'Venezuela',
    value: 'VE',
    code: 'VE',
    image: 'https://www.worldometers.info//img/flags/small/tn_ve-flag.gif',
    ar_name: 'فنزويلا',
  },
  {
    label: 'Viet Nam',
    value: 'VN',
    code: 'VN',
    image: 'https://www.worldometers.info//img/flags/small/tn_vm-flag.gif',
    ar_name: 'فيتنام',
  },
  {
    label: 'Yemen',
    value: 'YE',
    code: 'YE',
    image: 'https://www.worldometers.info//img/flags/small/tn_ym-flag.gif',
    ar_name: 'اليمن',
  },
  {
    label: 'Zambia',
    value: 'ZM',
    code: 'ZM',
    image: 'https://www.worldometers.info//img/flags/small/tn_za-flag.gif',
    ar_name: 'زامبيا',
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
    code: 'ZW',
    image: 'https://www.worldometers.info//img/flags/small/tn_zi-flag.gif',
    ar_name: 'زيمبابوي',
  },
];

export function getCountryByCode(value) {
  return find(countries, { value });
}
