import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { create, load } from 'ReduxActions/contactActions';
import { addTask } from 'ReduxActions/dashboardActions';
import * as pipelineActions from 'ReduxActions/pipelineActions';
import { loadUsers } from 'ReduxActions/settingActions';
import { utilActions } from 'ReduxActions/utilActions';

import defaultImage from '../../../assets/img/default.png';
import AddContact from '../../contacts/AddContact';
import EditTask from '../../dashboard/EditTask';
import DealModal from '../../pipeline/DealModal';
import AddPipeline from '../../settings/pipelines/AddPipeline';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';
import { loadV2Tasks } from 'ReduxActions/tasksActions';
import { canForClass } from '../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../v2.0/core/enums/permission/permission-type.enum';
import { Permissible } from 'SharedComponent/permissions';
import { VC_ACCELERATOR, WEALTH_MANAGEMENT, SALES, CAN_NOT_CREATE_DEAL } from 'Enums/permissions';

import './ExtraOptions.scss';
import { withRouter } from 'react-router';
import { Mixpanel } from 'App/app';
import mixpanel from 'mixpanel-browser';
// import { Tasks } from 'app/v2.0/modules/dashboard/board/dashboard-tasks/tasks.component';

const actions = {
  ...pipelineActions,
  ...utilActions,
  loadUsers,
  addTask,
  create,
  loadContacts: load,
  loadV2Tasks,
  Permissible,
  canForClass,
};
class ExtraOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.onOpen = this.onOpen.bind(this);
  }

  componentDidMount() {
    this.props.loadUsers();
    this.props.loadDomains();
    this.props.loadDealList();
    this.onOpen();
  }

  toggle() {
    if (!this.state.dropdownOpen) {
      gaEvent(appCategories.header, appActions(appTypes.extra_options, appEvents.opened).dropdown);
      // this.onOpen();
      this.props.loadExtraPipelines(null);
    }
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  onItemClicked(item) {
    this.setState({ [item.state]: true, [`is_${item.state}`]: true });
    Mixpanel.track(`Clicked on header new ${item.label}`);
    gaEvent(appCategories.header, appActions(`${appTypes.extra_options}:${item.ga}`, appEvents.clicked).item);
  }

  onOpen() {
    if (this.state.is_portfolio) {
      if (this.props.router.location.pathname !== '/portfolio') {
        this.props.router.push(`/portfolio`);
      } else {
        this.props.loadPipelineTypeList(1);
      }
      this.setState({ is_pipeline: false, is_portfolio: false, is_task: false, is_contact: false });
    } else if (this.state.is_pipeline) {
      if (this.props.router.location.pathname !== '/pipeline') {
        this.props.router.push(`/pipeline`);
      } else {
        this.props.loadPipelineTypeList(0);
      }
      this.setState({ is_pipeline: false, is_portfolio: false, is_task: false, is_contact: false });
    } else if (this.state.is_task) {
      if (this.props.router.location.pathname !== '/tasks/list') {
        this.props.router.push(`/tasks/list`);
      } else {
        this.props.loadV2Tasks(1, 20);
      }
      this.setState({ is_pipeline: false, is_portfolio: false, is_task: false, is_contact: false });
    } else if (this.state.is_contact) {
      if (this.props.router.location.pathname !== '/contacts') {
        this.props.router.push(`/contacts`);
      } else {
        this.props.loadContacts(1);
      }
      this.setState({ is_pipeline: false, is_portfolio: false, is_task: false, is_contact: false });
    }
  }

  get listItems() {
    let items = [];

    const forVC = this.props.Permissible([], [VC_ACCELERATOR]);
    const forWealth = this.props.Permissible([], [WEALTH_MANAGEMENT]);
    const forSales = this.props.Permissible([], [SALES]);
    const cannotCreateDeals = this.props.Permissible([], [CAN_NOT_CREATE_DEAL]);
    const canCreateDeal = this.props.canForClass(PermissionTypeEnum.CreatePipelineDeal);
    const canCreatePipeline = this.props.canForClass(PermissionTypeEnum.CreatePipeline);
    const canCreatePortfolio = this.props.canForClass(PermissionTypeEnum.CreatePortfolio);
    const canCreateTask = this.props.canForClass(PermissionTypeEnum.CreateTask);
    const canCreateContact = this.props.canForClass(PermissionTypeEnum.CreateContact);
    if (forVC) {
      if (!cannotCreateDeals) canCreateDeal && items.push({ state: 'deal', label: 'New Deal', icon: 'icon-plus', ga: 'ADD_NEW_DEAL' });
      canCreatePipeline && items.push({ state: 'pipeline', label: 'New Pipeline', icon: 'icon-layers', ga: 'ADD_NEW_PIPELINE' });
      canCreatePortfolio && items.push({ state: 'portfolio', label: 'New Portfolio', icon: 'icon-grid', ga: 'ADD_NEW_PORTFOLIO' });
      canCreateTask && items.push({ state: 'task', label: 'New Task', icon: 'icon-note', ga: 'ADD_NEW_TASK' });
      canCreateContact && items.push({ state: 'contact', label: 'New Contact', icon: 'icon-people', ga: 'ADD_NEW_CONTACT' });
    }
    if (forSales) {
      canCreateDeal && items.push({ state: 'deal', label: 'New Deal', icon: 'icon-plus', ga: 'ADD_NEW_DEAL' });
      canCreatePipeline && items.push({ state: 'pipeline', label: 'New Pipeline', icon: 'icon-layers', ga: 'ADD_NEW_PIPELINE' });
      canCreateTask && items.push({ state: 'task', label: 'New Task', icon: 'icon-note', ga: 'ADD_NEW_TASK' });
      canCreateContact && items.push({ state: 'contact', label: 'New Contact', icon: 'icon-people', ga: 'ADD_NEW_CONTACT' });
    }
    if (forWealth) {
      canCreatePortfolio && items.push({ state: 'portfolio', label: 'New Portfolio', icon: 'icon-grid', ga: 'ADD_NEW_PORTFOLIO' });
      canCreateContact && items.push({ state: 'contact', label: 'New Contact', icon: 'icon-people', ga: 'ADD_NEW_CONTACT' });
    }

    return items;
  }

  render() {
    if (!!!this.listItems.length) {
      return null;
    }

    return (
      <React.Fragment>
        <div className='ExtraOptions display-flex-center'>
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle tag='span' data-toggle='dropdown' aria-expanded={this.state.dropdownOpen}>
              <i className='material-icons dropdown-details'>add</i>
            </DropdownToggle>
            <DropdownMenu right className='drop-down-position'>
              {this.listItems.map((item, index) => (
                <DropdownItem key={index + 1} className='display-flex-start cursor-pointer' onClick={() => this.onItemClicked(item)}>
                  <i className={item.icon} />
                  <span className='ml-2'>{item.label}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <DealModal
            showDetails={this.state.deal}
            onDismiss={() => this.setState({ deal: false })}
            formName='extra-option-deal-form'
            extra={true}
            isPortfolio={false}
            customFields={this.props.customFields}
            contactCustomFields={this.props.contactCustomFields}
          />
          {(this.state.pipeline || this.state.portfolio) && (
            <AddPipeline
              showDetails={this.state.pipeline || this.state.portfolio}
              onDismiss={() => {
                this.setState({ pipeline: false, portfolio: false });
                Mixpanel.track('Clicked on header cancel pipeline');
              }}
              addPipeline={pipeline => {
                this.props
                  .addPipeline(pipeline)
                  .then(() => {
                    this.onOpen();
                    Mixpanel.track('Clicked on header add pipeline', { pipeline: pipeline.name });
                  })
                  .catch(() => {
                    Mixpanel.track('Clicked on header add pipeline failed', { pipeline: pipeline.name });
                  });
              }}
              isPortfolio={this.state.portfolio}
              isVC={this.props.Permissible([], [VC_ACCELERATOR])}
            />
          )}
          <EditTask
            show={this.state.task}
            dismiss={() => {
              this.setState({ task: false });
              Mixpanel.track('Clicked on header cancel task');
            }}
            onSave={task => {
              this.props
                .addTask(task)
                .then(() => {
                  this.onOpen();
                  Mixpanel.track('Clicked on header add task', { task: task.name });
                })
                .catch(() => {
                  Mixpanel.track('Clicked on header add task failed', { task: task.name });
                });
            }}
            users={this.parseUsers(this.props.users)}
            deals={this.parseDeals(this.props.deals)}
          />
          <AddContact
            showDetails={this.state.contact}
            onDismiss={() => {
              this.setState({ contact: false });
              Mixpanel.track('Clicked on header cancel contact');
            }}
            onSave={contact => {
              this.props
                .create(contact)
                .then(() => {
                  this.onOpen();
                  Mixpanel.track('Clicked on header add contact', { contact: contact.name });
                })
                .catch(() => {
                  Mixpanel.track('Clicked on header add contact failed', { contact: contact.name });
                });
            }}
            customFields={this.props.contactCustomFields}
          />
        </div>
        <div className='divider' />
      </React.Fragment>
    );
  }

  parseUsers(users) {
    return users.map(user => {
      return {
        value: user.id,
        label: user.profile ? user.profile.fullname : 'N/A',
        image: user.profile && user.profile.image ? user.profile.image : defaultImage,
        is_user: true,
      };
    });
  }

  parseDeals(deals) {
    return deals.map(deal => {
      return {
        value: deal.deal_id,
        label: deal.name,
        image: deal.image,
      };
    });
  }
}

function mapStateToProps(state) {
  return {
    deals: state.pipeline.allDeals,
    users: state.settings.users.all,
    pipelines: state.pipeline.pipelines,
    customFields: state.settings.company.pipelineCustomFields,
    contactCustomFields: state.settings.company.contactCustomFields,
  };
}

export default connect(mapStateToProps, actions)(withRouter(ExtraOptions));
