export const items = [
  // {
  //   key: "undo",
  //   name: "",
  //   icon: "undo",
  //   tooltip: "Undo",
  //   element: false,
  //   disabled: true,
  // },
  // {
  //   key: "redo",
  //   name: "",
  //   icon: "redo",
  //   tooltip: "Redo",
  //   element: false,
  //   disabled: true,
  // },
  { divider: true },
  {
    key: 'text_content',
    name: 'Paragraph',
    icon: 'format_shapes',
    tooltip: 'Text',
    html: `<p><span style="font-size: 16px;font-family: plus-jakarta-sans, Arial;">Text</span></p>`,
    width: 700,
    height: 200,
  },
  {
    key: 'link',
    name: 'Link',
    icon: 'link',
    tooltip: 'Link',
    target: 'https://app.pentugram.com',
    width: 500,
    height: 75,
  },
  {
    key: 'image',
    name: 'Image',
    title: 'Image title',
    icon: 'image',
    tooltip: 'Image',
    url: '',
    width: 300,
    height: 300,
  },
  {
    key: 'rectangle',
    name: 'Rectangle',
    title: 'Rectangle',
    group: 'geometric',
    icon: 'crop_din',
    tooltip: 'Rectangle',
    width: 300,
    height: 300,
  },
  {
    key: 'circle',
    name: 'Circle',
    title: 'Circle',
    group: 'geometric',
    icon: 'panorama_fish_eye',
    tooltip: 'Circle',
    width: 300,
    height: 300,
  },
  {
    key: 'manual_table',
    label: 'Manual Table',
    name: 'Manual Table',
    icon: 'table_chart',
    group: '',
    tooltip: 'Manual Table',
    x: 0,
    y: '-548px',
    width: 790,
    height: 150,
  },
  { divider: true },
];

export const charts = [
  {
    label: 'Table',
    items: [
      {
        key: 'table',
        name: 'Table',
        group: 'table',
        // title: "Table title",
        tooltip: 'Table',
        x: 0,
        y: '-1272px',
      },
      // {
      //   key: "table_with_bars",
      //   name: "Table with bars",
      //   group: "table",
      //   title: "Table with bars",
      //   tooltip: "Table with bars",
      //   x: 0,
      //   y: "-548px",
      // },
      // {
      //   key: "table_with_density_map",
      //   name: "Table with density map",
      //   group: "table",
      //   title: "Table with density map",
      //   tooltip: "Table with density map",
      //   x: 0,
      //   y: "-144px",
      // },
    ],
  },
  {
    label: 'Scorecard',
    items: [
      {
        key: 'data_table',
        name: 'Scorecard',
        group: 'data_table',
        title: 'Scorecard',
        tooltip: 'Scorecard',
        x: 0,
        y: '-804px',
      },
      {
        key: 'data_table_with_compressed_numbers',
        name: 'Scorecard with compressed numbers',
        group: 'data_table',
        title: 'Scorecard with compressed numbers',
        tooltip: 'Scorecard with compressed numbers',
        x: 0,
        y: '-900px',
      },
    ],
  },
  {
    label: 'Line',
    items: [
      {
        key: 'time_series_graph',
        name: 'Time series graph',
        group: 'line_chart',
        title: 'Time series graph',
        tooltip: 'Time series graph',
        x: 0,
        y: '-216px',
      },
      {
        key: 'smoothed_time_series_graph',
        name: 'Smoothed time series graph',
        group: 'line_chart',
        title: 'Smoothed time series graph',
        tooltip: 'Smoothed time series graph',
        x: 0,
        y: '-1200px',
      },
      {
        key: 'stacked_area_chart',
        name: 'Stacked area chart',
        group: 'line_chart',
        title: 'Stacked area chart',
        tooltip: 'Stacked area chart',
        x: 0,
        y: '-180px',
      },
    ],
  },
  {
    label: 'Bar',
    items: [
      {
        key: 'column_chart',
        name: 'Column chart',
        group: 'bar_chart',
        title: 'Column chart',
        tooltip: 'Column chart',
        x: 0,
        y: '-704px',
      },
      {
        key: 'stacked_histogram',
        name: 'Stacked histogram',
        group: 'bar_chart',
        title: 'Stacked histogram',
        tooltip: 'Stacked histogram',
        x: 0,
        y: '-464px',
      },
      {
        key: 'bar_graph',
        name: 'Bar graph',
        group: 'bar_chart',
        title: 'Bar graph',
        tooltip: 'Bar graph',
        x: 0,
        y: '-972px',
      },
      {
        key: 'stacked_bar_chart',
        name: 'Stacked bar chart',
        group: 'bar_chart',
        title: 'Stacked bar chart',
        tooltip: 'Stacked bar chart',
        x: 0,
        y: 0,
      },
    ],
  },
  {
    label: 'Pie',
    items: [
      {
        key: 'pie_chart',
        name: 'Pie chart',
        group: 'portfolio_chart',
        params: {
          name: 'portfolio',
          url: 'pipelines',
          field: 'name',
          unique: 'pipeline_id',
        },
        title: 'Pie chart',
        tooltip: 'Pie chart',
        x: 0,
        y: '-384px',
      },
      {
        key: 'ring_graphic',
        name: 'Ring graphic',
        group: 'portfolio_chart',
        params: {
          name: 'portfolio',
          url: 'pipelines',
          field: 'name',
          unique: 'pipeline_id',
        },
        title: 'Ring graphic',
        tooltip: 'Ring graphic',
        x: 0,
        y: '-644px',
      },
    ],
  },
];

export const pentugram = [
  {
    key: 'contact_card',
    label: 'Add Contact',
    name: 'Contact',
    group: 'pentugram',
    search: {
      name: 'contact',
      url: 'contacts',
      field: 'first_name',
      unique: 'contact_id',
    },
    fields: [
      { value: 'name', label: 'Name', show: true, disabled: true },
      { value: 'logo', label: 'logo', show: true },
      { value: 'title', label: 'Title', show: true },
      { value: 'email', label: 'Email', show: true },
      { value: 'phone', label: 'phone', show: true },
    ],
    customFields: 'contact',
  },
  {
    key: 'deal_card',
    label: 'Add Deal',
    name: 'Deal',
    group: 'pentugram',
    search: {
      name: 'deal',
      url: 'deals',
      field: 'name',
      unique: 'deal_id',
    },
    fields: [
      { value: 'name', label: 'Name', show: true, disabled: true },
      { value: 'logo', label: 'logo', show: true, disabled: false },
      { value: 'amount', label: 'Amount', show: true },
      { value: 'rating', label: 'Rating', show: true },
      { value: 'created_at', label: 'Created at', show: true },
      { value: 'email', label: 'Email', show: false },
      { value: 'assignee', label: 'Assignee', show: true },
      { value: 'country', label: 'Country', show: true },
      { value: 'website', label: 'Website', show: false },
    ],
  },
  {
    key: 'portfolio_card',
    label: 'Add Portfolio',
    name: 'Portfolio',
    group: 'portfolio_table',
    checkbox: true,
    search: {
      name: 'portfolio',
      url: 'reports/portfolio',
      field: 'name',
      unique: 'pipeline_id',
    },
    fields: [
      { value: 'company', label: 'Company', field: 'name', show: true, disabled: true },
      { value: 'amount', label: 'Investment Amount in USD', field: 'size', show: true },
      { value: 'country', label: 'Country', field: 'countryName', show: false },
    ],
  },
];

export const extraOptions = [
  {
    key: 'autosave',
    label: 'Auto Save',
    name: 'Autosave',
    group: '',
    field: 'auto_save',
    type: '',
    toggle: true,
  },
  {
    key: 'grid_item',
    label: 'Show Grid',
    name: 'Grid',
    group: '',
    field: 'showGrid',
    type: '',
    toggle: true,
  },
  {
    key: 'navigation',
    label: 'Show Slides',
    name: 'Slides',
    group: '',
    field: 'showSlides',
    type: '',
    toggle: true,
  },
  {
    key: 'smart_guides',
    label: 'Show Guides',
    name: 'Guides',
    group: '',
    field: 'showGuides',
    type: '',
    toggle: true,
  },
];

export const layouts = [
  {
    key: 'cover_page_item',
    label: 'Add Cover Page',
    name: 'Cover',
    group: 'layout',
    field: 'showCoverPage',
    toggle: true,
  },
  {
    key: 'header_item',
    label: 'Add Header',
    name: 'Header',
    group: 'layout',
    field: 'showHeader',
    type: 'report_header',
    toggle: true,
  },
  {
    key: 'footer_item',
    label: 'Add Footer',
    name: 'Footer',
    group: 'layout',
    field: 'showFooter',
    type: 'report_footer',
    toggle: true,
  },
  // {
  //   key: "pages_item",
  //   label: "Add Pages",
  //   name: "Pages",
  //   group: "layout",
  //   field: "showPages",
  //   toggle: true,
  // },
  { divider: true },
  {
    key: 'author_card',
    label: 'Author card',
    name: 'Author',
    group: 'layout',
    icon: 'perm_contact_calendar',
    width: 400,
    height: 100,
    fields: [
      { value: 'name', label: 'Name', show: true, disabled: true },
      { value: 'image', label: 'Image', show: true },
      { value: 'email', label: 'Email', show: true },
      { value: 'phone', label: 'Phone', show: true },
    ],
  },

  // {
  //   key: "date_card",
  //   label: "Add Date",
  //   name: "Date",
  //   group: "layout",
  // },
];

export const portfolio_items = [
  {
    label: 'Portfolio Table',
    items: [
      {
        key: 'portfolio_card',
        label: 'Table',
        name: 'Table',
        group: 'portfolio_table',
        can_update: true,
        back_field: 'portfolio-deals',
        checkbox: true,
        tooltip: 'Portfolio Table',
        x: 0,
        y: '-1272px',
        width: 790,
        height: 500,
        search: {
          name: 'portfolio',
          url: 'reports/portfolio',
          field: 'name',
          unique: 'pipeline_id',
        },
        fields: [
          { value: 'image', label: 'Image', show: true },
          { value: 'name', label: 'Company', show: true, disabled: true },
          // { value: "country", label: "Country", field: "countryName", show: false },
        ],
        customFields: 'portfolio',
      },
      {
        key: 'portfolio_card',
        label: 'Investments table',
        name: 'Investments table',
        group: 'portfolio_table',
        can_update: true,
        back_field: 'portfolio-deals',
        is_investments_table: true,
        checkbox: true,
        tooltip: 'Investments table',
        items_name: 'Investments',
        x: 0,
        y: '-1272px',
        width: 790,
        height: 500,
        search: {
          name: 'portfolio',
          url: 'reports/portfolio',
          field: 'name',
          unique: 'pipeline_id',
        },
        fields: [
          { value: 'name', label: 'Company name', show: true, disabled: false },
          { value: 'stage', label: 'Stage', show: true, disabled: false },
          { value: 'country', label: 'Country', field: 'country', show: true },
          { value: 'investment_type', label: 'Investment type', show: true, disabled: false },
          { value: 'latest_investment_date', label: 'Latest investmet date', field: 'latest_investment_date', show: true, field_type: 'date', type: 'date' },
          { value: 'invested_amount', label: 'Invested amount', field: 'invested_amount', show: true, type: 'monetary' },
          { value: 'total_raised_amount_usd', label: 'Total raised', show: true, disabled: false, type: 'monetary' },
          { value: 'discount', label: 'Discount', show: true, disabled: false, type: 'percentage' },
          { value: 'number_of_shares', label: 'Number of shares', show: true, disabled: false },
          { value: 'price_per_share', label: 'Price per share', show: true, disabled: false, type: 'monetary' },
          { value: 'investment_rational', label: 'Investment rational', show: true, disabled: false },
          { value: 'pre_money_valuation', label: 'Pre-money valuation', show: true, disabled: false, type: 'monetary' },
          { value: 'post_money_valuation', label: 'Post-money valuation', show: true, disabled: false, type: 'monetary' },
        ],
      },
    ],
  },
  {
    label: 'Portfolio Analytics',
    items: [
      {
        key: 'portfolio-dash_card',
        name: 'Pie chart',
        group: 'portfolio_chart',
        chartType: 'pie_chart',
        can_update: true,
        back_field: 'portfolio',
        params: {
          name: 'portfolio',
          url: 'pipelines',
          field: 'name',
          unique: 'pipeline_id',
        },
        title: 'Pie chart',
        tooltip: 'Pie chart',
        x: 0,
        y: '-384px',
        width: 600,
        height: 400,
      },
      {
        key: 'portfolio-dash_card',
        name: 'Ring graphic',
        group: 'portfolio_chart',
        chartType: 'pie_chart',
        can_update: true,
        back_field: 'portfolio',
        params: {
          name: 'portfolio',
          url: 'pipelines',
          field: 'name',
          unique: 'pipeline_id',
        },
        title: 'Ring graphic',
        tooltip: 'Ring graphic',
        x: 0,
        y: '-644px',
        width: 600,
        height: 400,
        pieOptions: {
          radius: ['50%', '70%'],
        },
      },
      {
        key: 'portfolio-dash_card',
        label: 'Portfolio Card',
        group: 'pipeline_card',
        name: 'Portfolio Card',
        tooltip: 'Portfolio Card',
        x: 0,
        y: '-704px',
        width: 790,
        height: 500,
      },
    ],
  },
  {
    label: 'Portfolio Scorecard',
    items: [
      {
        key: 'score_card',
        name: 'Scorecard',
        group: 'score_card',
        title: 'Scorecard',
        tooltip: 'Scorecard',
        x: 0,
        y: '-900px',
      },
    ],
  },
];

const fund_options = [
  {
    value: 'commitment',
    label: 'Commitement per',
    children: [
      {
        value: 'LP',
        label: 'LP',
      },
    ],
  },
  {
    value: 'investment',
    label: 'Invested amount per',
    children: [
      {
        value: 'fund',
        label: 'Unused funds',
      },
    ],
  },
];

export const fund_items = [
  {
    label: 'Funds table',
    items: [
      {
        key: 'portfolio_card',
        label: 'Funds table',
        name: 'Funds table',
        group: 'funds_table',
        can_update: true,
        back_field: 'portfolio-deals',
        is_funds_table: true,
        checkbox: true,
        tooltip: 'Funds table',
        x: 0,
        y: '-1272px',
        width: 790,
        height: 500,
        search: {
          name: 'portfolio',
          url: 'reports/portfolio',
          field: 'name',
          unique: 'pipeline_id',
        },
        items_name: 'Funds',
        fields: [
          { value: 'fund_name', label: 'Fund name', show: true, disabled: false },
          { value: 'amount', label: 'Invested amount', show: true, disabled: false },
          { value: 'number_of_lps', label: '# of LPs', show: true, disabled: false },
        ],
      },
      {
        key: 'portfolio_card',
        label: 'LPs table',
        name: 'LPs table',
        group: 'lps_table',
        can_update: true,
        back_field: 'portfolio-deals',
        is_lps_table: true,
        checkbox: true,
        tooltip: 'LPs table',
        x: 0,
        y: '-1272px',
        width: 790,
        height: 500,
        search: {
          name: 'portfolio',
          url: 'reports/portfolio',
          field: 'name',
          unique: 'pipeline_id',
        },
        items_name: 'LPs',
        fields: [
          { value: 'name', label: 'LP name', show: true, disabled: false },
          { value: 'value', label: 'Commitement amount', show: true, disabled: false },
        ],
      },
    ],
  },
  {
    label: 'LP Analytics',
    items: [
      {
        key: 'portfolio-dash_card',
        name: 'Pie chart',
        group: 'portfolio_chart',
        chartType: 'pie_chart',
        can_update: true,
        back_field: 'portfolio',
        params: {
          name: 'portfolio',
          url: 'pipelines',
          field: 'name',
          unique: 'pipeline_id',
        },
        title: 'Pie chart',
        tooltip: 'Pie chart',
        x: 0,
        y: '-384px',
        width: 600,
        height: 400,
        cascader_options: fund_options,
      },
      {
        key: 'portfolio-dash_card',
        name: 'Ring graphic',
        group: 'portfolio_chart',
        chartType: 'pie_chart',
        can_update: true,
        back_field: 'portfolio',
        params: {
          name: 'portfolio',
          url: 'pipelines',
          field: 'name',
          unique: 'pipeline_id',
        },
        title: 'Ring graphic',
        tooltip: 'Ring graphic',
        x: 0,
        y: '-644px',
        width: 600,
        cascader_options: fund_options,
        height: 400,
        pieOptions: {
          radius: ['50%', '70%'],
        },
      },
      {
        key: 'portfolio-dash_card',
        label: 'Funds Card',
        group: 'fund_card',
        name: 'Funds Card',
        tooltip: 'Funds Card',
        x: 0,
        y: '-704px',
        width: 790,
        height: 500,
        cascader_options: fund_options,
      },
    ],
  },
];

export const pipeline_items = [
  {
    label: 'Pipeline Table',
    items: [
      {
        key: 'portfolio_card',
        label: 'Table',
        name: 'Table',
        group: 'portfolio_table',
        can_update: true,
        back_field: 'portfolio-deals',
        checkbox: true,
        is_pipeline: true,
        tooltip: 'Pipeline Table',
        x: 0,
        y: '-1272px',
        width: 790,
        height: 500,
        search: {
          name: 'pipeline',
          url: 'reports/portfolio',
          field: 'name',
          unique: 'pipeline_id',
        },
        fields: [
          { value: 'image', label: 'Image', show: true },
          { value: 'name', label: 'Company', show: true, disabled: true },
          // { value: "country", label: "Country", field: "countryName", show: false },
        ],
        customFields: 'pipeline',
      },
    ],
  },
  {
    label: 'Pipeline Analytics',
    items: [
      {
        key: 'pipeline_card',
        name: 'Pie chart',
        group: 'portfolio_chart',
        chartType: 'pie_chart',
        can_update: true,
        is_pipeline: true,
        back_field: 'pipeline',
        params: {
          name: 'pipeline',
          url: 'pipelines',
          field: 'name',
          unique: 'pipeline_id',
        },
        title: 'Pie chart',
        tooltip: 'Pie chart',
        x: 0,
        y: '-384px',
        width: 600,
        height: 400,
      },
      {
        key: 'pipeline_card',
        name: 'Ring graphic',
        group: 'portfolio_chart',
        chartType: 'pie_chart',
        can_update: true,
        back_field: 'pipeline',
        is_pipeline: true,
        params: {
          name: 'pipeline',
          url: 'pipelines',
          field: 'name',
          unique: 'pipeline_id',
        },
        title: 'Ring graphic',
        tooltip: 'Ring graphic',
        x: 0,
        y: '-644px',
        width: 600,
        height: 400,
        pieOptions: {
          radius: ['50%', '70%'],
        },
      },
      {
        key: 'pipeline_card',
        label: 'Pipeline Card',
        group: 'pipeline_card',
        name: 'Pipeline Card',
        tooltip: 'Pipeline Card',
        x: 0,
        y: '-704px',
        width: 790,
        height: 500,
      },
    ],
  },
  {
    label: 'Pipeline Scorecard',
    items: [
      {
        key: 'score_card',
        name: 'Scorecard',
        group: 'score_card',
        title: 'Scorecard',
        tooltip: 'Scorecard',
        is_pipeline: true,
        x: 0,
        y: '-900px',
      },
    ],
  },
];

export const company_items = [
  {
    label: 'Metrics table',
    items: [
      {
        key: 'table',
        name: 'Table',
        group: 'table',
        // title: "Table title",
        tooltip: 'Table',
        can_update: true,
        back_field: 'metric',
        x: 0,
        y: '-1272px',
        width: 700,
        height: 400,
      },
      {
        key: 'portfolio_card',
        label: 'Rounds table',
        name: 'Rounds table',
        group: 'rounds_table',
        can_update: true,
        is_rounds_table: true,
        checkbox: true,
        tooltip: 'Rounds table',
        x: 0,
        y: '-1272px',
        width: 790,
        height: 500,
        items_name: 'Rounds',
        fields: [
          { value: 'deal_name', label: 'Company name', show: true, disabled: false },
          { value: 'stage', label: 'Stage', show: true, disabled: false },
          { value: 'country', label: 'Country', field: 'country', show: true },
          { value: 'investment_type', label: 'Investment type', show: true, disabled: false },
          { value: 'latest_investment_date', label: 'Latest investmet date', field: 'latest_investment_date', show: true, field_type: 'date', type: 'date' },
          { value: 'invested_amount', label: 'Invested amount', field: 'invested_amount', show: true, type: 'monetary' },
          { value: 'total_raised_amount_usd', label: 'Total raised', show: true, disabled: false, type: 'monetary' },
          { value: 'discount', label: 'Discount', show: true, disabled: false, type: 'percentage' },
          { value: 'number_of_shares', label: 'Number of shares', show: true, disabled: false },
          { value: 'price_per_share', label: 'Price per share', show: true, disabled: false, type: 'monetary' },
          { value: 'investment_rational', label: 'Investment rational', show: true, disabled: false },
          { value: 'pre_money_valuation', label: 'Pre-money valuation', show: true, disabled: false, type: 'monetary' },
          { value: 'post_money_valuation', label: 'Post-money valuation', show: true, disabled: false, type: 'monetary' },
        ],
      },
    ],
  },
  {
    label: 'Metrics Analytics',
    items: [
      {
        key: 'time_series_graph',
        name: 'Time series graph',
        group: 'line_chart',
        title: 'Time series graph',
        tooltip: 'Time series graph',
        x: 0,
        y: '-216px',
        width: 700,
        height: 400,
      },
      {
        key: 'smoothed_time_series_graph',
        name: 'Smoothed time series graph',
        group: 'line_chart',
        title: 'Smoothed time series graph',
        tooltip: 'Smoothed time series graph',
        x: 0,
        y: '-1200px',
        width: 700,
        height: 400,
      },
      {
        key: 'stacked_area_chart',
        name: 'Stacked area chart',
        group: 'line_chart',
        title: 'Stacked area chart',
        tooltip: 'Stacked area chart',
        x: 0,
        y: '-180px',
        width: 700,
        height: 400,
      },
      {
        key: 'column_chart',
        name: 'Column chart',
        group: 'bar_chart',
        title: 'Column chart',
        tooltip: 'Column chart',
        x: 0,
        y: '-704px',
        width: 700,
        height: 400,
      },
      {
        key: 'stacked_histogram',
        name: 'Stacked histogram',
        group: 'bar_chart',
        title: 'Stacked histogram',
        tooltip: 'Stacked histogram',
        x: 0,
        y: '-464px',
        width: 700,
        height: 400,
      },
      {
        key: 'bar_graph',
        name: 'Bar graph',
        group: 'bar_chart',
        title: 'Bar graph',
        tooltip: 'Bar graph',
        x: 0,
        y: '-972px',
        width: 700,
        height: 400,
      },
      {
        key: 'stacked_bar_chart',
        name: 'Stacked bar chart',
        group: 'bar_chart',
        title: 'Stacked bar chart',
        tooltip: 'Stacked bar chart',
        x: 0,
        y: 0,
        width: 700,
        height: 400,
      },
    ],
  },
  {
    label: 'Rounds Analytics',
    items: [
      {
        key: 'pipeline_card',
        name: 'Pie chart',
        group: 'portfolio_chart',
        chartType: 'pie_chart',
        can_update: true,
        title: 'Investment per round',
        is_deal_rounds: true,
        back_field: 'pipeline',
        params: {
          name: 'pipeline',
          url: 'pipelines',
          field: 'name',
          unique: 'pipeline_id',
        },
        tooltip: 'Pie chart',
        x: 0,
        y: '-384px',
        width: 600,
        height: 400,
      },
      {
        key: 'pipeline_card',
        name: 'Ring graphic',
        group: 'rounds_chart',
        chartType: 'pie_chart',
        can_update: true,
        back_field: 'pipeline',
        is_deal_rounds: true,
        title: 'Investment per round',
        tooltip: 'Ring graphic',
        x: 0,
        y: '-644px',
        width: 600,
        height: 400,
        pieOptions: {
          radius: ['50%', '70%'],
        },
      },
      {
        key: 'pipeline_card',
        label: 'Bar Chart',
        is_deal_rounds: true,
        title: 'Investment per round',
        group: 'pipeline_card',
        name: 'Bar Chart',
        tooltip: 'Bar Chart',
        x: 0,
        y: '-704px',
        width: 790,
        height: 500,
      },
    ],
  },
];

export const companies = [
  {
    key: 'contact_card',
    label: 'Contact card',
    name: 'Contact',
    group: 'pentugram',
    icon: 'person',
    width: 300,
    height: 400,
    can_update: true,
    back_field: 'contact',
    search: {
      name: 'contact',
      url: 'contacts',
      field: 'first_name',
      unique: 'contact_id',
    },
    fields: [
      { value: 'name', label: 'Name', show: true, disabled: true },
      { value: 'logo', label: 'logo', show: true },
      { value: 'title', label: 'Title', show: true },
      { value: 'email', label: 'Email', show: true },
      { value: 'phone', label: 'phone', show: true },
    ],
    customFields: 'contact',
  },
  {
    key: 'deal_card',
    label: 'Deal card',
    name: 'Deal',
    icon: 'add_box',
    group: 'pentugram',
    width: 300,
    height: 400,
    can_update: true,
    back_field: 'deal',
    search: {
      name: 'deal',
      url: 'deals',
      field: 'name',
      unique: 'deal_id',
    },
    fields: [
      { value: 'name', label: 'Name', show: true, disabled: true },
      { value: 'logo', label: 'logo', show: true, disabled: false },
      { value: 'amount', label: 'Amount', show: true },
      { value: 'rating', label: 'Rating', show: true },
      { value: 'created_at', label: 'Created at', show: true },
      { value: 'cc_email', label: 'Email', show: true },
      // { value: "assignee", label: "Assignee", show: true },
      // { value: "country", label: "Country", show: true },
      // { value: "website", label: "Website", show: true },
    ],
    customFields: 'portfolio',
  },
];
