import React from 'react';
import { Select, Avatar, Tooltip } from 'antd';
import defaultImage from 'Assets/img/default.png';

const { Option } = Select;

export default function SelectComponent(props) {
  const inputs = props.input.value || [];

  const SmallInput = ({ image, label }) => (
    <Tooltip title={label}>
      <Avatar src={props.showImages ? image : `https://ui-avatars.com/api/?font-size=0.5&background=ebf5fa&color=03a6ff&length=2&name=${label}`} />
    </Tooltip>
  );

  const LargeInput = ({ image, label }) => (
    <span>
      {props.showImages && <Avatar src={image} />}
      <span className='ml-2'>{label}</span>
    </span>
  );

  return (
    <div>
      <Select
        onChange={value => props.input.onChange(value)}
        style={{ ...props.styles, width: '100%' }}
        value={inputs}
        placeholder={props.placeholder}
        onBlur={() => props.input.onBlur(inputs)}
        notFoundContent={props.notFoundContent}
        size={props.size}
        onSearch={props.onSearch}
        mode={props.mode}
        optionLabelProp={props.optionLabelProp}
        defaultActiveFirstOption={true}
        showArrow={false}
        filterOption={false}
      >
        {props.options.map(opt => (
          <Option
            key={opt[props.keyValue]}
            value={opt[props.keyValue]}
            label={
              inputs.length > props.collapseAt ? (
                <SmallInput image={opt[props.keyImage] || defaultImage} label={opt[props.keyLabel]} />
              ) : (
                <LargeInput image={opt[props.keyImage] || defaultImage} label={opt[props.keyLabel]} />
              )
            }
          >
            {props.showImages && <Avatar src={opt[props.keyImage] || defaultImage} />}
            <span className='ml-2' role='img'>
              {opt[props.keyLabel]}
            </span>
          </Option>
        ))}
      </Select>
    </div>
  );
}

SelectComponent.defaultProps = {
  showImages: true,
  options: [],
  keyValue: 'id',
  keyLabel: 'label',
  keyImage: 'image',
  collapseAt: 5,
  size: 'large',
  mode: 'multiple',
  optionLabelProp: 'label',
};
