export const toolbarConfig = {
  inline: {
    inDropdown: false,
    strikethrough: { className: 'hide-option-custom' },
    monospace: { className: 'hide-option-custom' },
    superscript: { className: 'hide-option-custom' },
    subscript: { className: 'hide-option-custom' },
  },
  blockType: { inDropdown: true, className: 'hide-option-custom' },
  fontSize: {
    icon: 'fontSize',
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    title: undefined,
  },
  fontFamily: {
    options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    title: undefined,
  },
  list: {
    inDropdown: false,
    unordered: { className: undefined },
    indent: { className: undefined },
    outdent: { className: undefined },
  },
  textAlign: { inDropdown: false },
  colorPicker: { inDropdown: true },
  link: { inDropdown: true, className: 'hide-option-custom' },
  embedded: { className: 'hide-option-custom' },
  emoji: { className: 'hide-option-custom' },
  image: { className: 'hide-option-custom' },
  remove: { className: 'hide-option-custom' },
  history: { inDropdown: false },
};
