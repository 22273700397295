import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { SelectResolver } from '../../../../../../shared/components';
import { required } from '../../../../../../shared/utils/validators';
import { AvatarShapeEnum } from '../../../../../../shared/interfaces/avatar-data';
import { excelPipelineValue } from '../../../../../../store/entity-selectors/form-values.selectors';

import { IntegrationExcelFormNamesEnum } from '../../../../../../core/enums/form-names/integration-excel-form-names.enum';
import { pipelineAllListLight } from '../../../../../../core/services/pipeline.service';
import { getStageList } from '../../../../../../core/services/stage.service';

import './excel-form.component.scss';

// interface ExcelFormProps {
//   onChangePipeline(): void;
// }

export function ExcelForm(props) {
  const selectedPipeline = useSelector(excelPipelineValue);
  const handlePipeline = () => props.onChangePipeline();

  return (
    <div className='ExcelForm'>
      <div className='field-container'>
        <div className='field-row'>
          <Field
            component={SelectResolver}
            name={IntegrationExcelFormNamesEnum.formFields.getValue(IntegrationExcelFormNamesEnum.Pipeline)}
            resolver={pipelineAllListLight}
            placeholder={IntegrationExcelFormNamesEnum.toString.getValue(IntegrationExcelFormNamesEnum.Pipeline)}
            validate={[required]}
            showImage={true}
            avatarData={{ shape: AvatarShapeEnum.Circle }}
            onChange={handlePipeline}
          />
        </div>
        <div className='field-row'>
          <Field
            component={SelectResolver}
            name={IntegrationExcelFormNamesEnum.formFields.getValue(IntegrationExcelFormNamesEnum.Stage)}
            resolver={getStageList}
            resolverArgs={selectedPipeline ? [selectedPipeline.value] : []}
            placeholder={IntegrationExcelFormNamesEnum.toString.getValue(IntegrationExcelFormNamesEnum.Stage)}
            disabled={!selectedPipeline}
            validate={[required]}
          />
        </div>
      </div>
    </div>
  );
}
