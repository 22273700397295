import React from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { PopoverPlacementEnum } from '../../enums/popover-placement.enum';
import { IconData } from '../../interfaces/icon-data';
import { Icon } from '../Icon/icon.component';

import './flat-button.component.scss';

interface FlatButtonProps {
  label: string;
  icon: IconData;
  tooltip: string;
  placement: TooltipPlacement;
  isActive: boolean;
  className?: string;
  labelClass?: string;
  onClick(): void;
}

export function FlatButton(props: FlatButtonProps): JSX.Element {
  return (
    <Tooltip title={props.tooltip} placement={props.placement} className={props.className}>
      <div className='FlatButton' onClick={props.onClick}>
        <div className='flat-button-center'>
          {props.icon && <Icon iconData={props.icon} />}
          <span className={props.labelClass}>{props.label}</span>
        </div>
      </div>
    </Tooltip>
  );
}

FlatButton.defaultProps = {
  label: null,
  icon: null,
  tooltip: null,
  placement: PopoverPlacementEnum.Top,
  isActive: false,
  onClick: () => {},
};
