export const REMOVE_ERROR = 'errors:remove';
export const RESET_ERRORS = 'errors:reset';

export function removeError(error) {
  return dispatch => {
    dispatch({
      type: REMOVE_ERROR,
      payload: error,
    });
  };
}
