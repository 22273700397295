import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../state/modal.actions';
import { getSubmitted } from '../state/modal.selectors';

import { Button } from '../../Button/button.component';

import { ButtonType } from '../../../../shared/interfaces/button-data';

import './modal-footer.component.scss';

interface DefaultFooterProps {
  onSave: () => void;
  saveLabel: string;
  cancelLabel: string;
  htmlType?: ButtonType;
}

export function DefaultFooter(props: DefaultFooterProps): JSX.Element {
  const dispatch = useDispatch();
  const submitting = useSelector(getSubmitted);

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleSubmit = () => {
    props.onSave();
  };

  return (
    <div className='DefaultFooter'>
      <Button label={props.cancelLabel} btnClass='pentugram-btn-default' onClick={handleCancel} />
      <Button
        label={props.saveLabel}
        htmlType={props.htmlType}
        btnClass='pentugram-primary-btn pentugram-btn-default'
        onClick={handleSubmit}
        disabled={submitting}
        loading={submitting}
      />
    </div>
  );
}

DefaultFooter.defaultProps = {
  saveLabel: 'Save',
  cancelLabel: 'Cancel',
  onSave: () => {},
};
