import classNames from 'classnames';
import React from 'react';
import HeaderBar from './header-bar';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import * as validators from 'Utils/validators';
import TextField from 'SharedComponent/TextField';
import InputCustomFields from 'SharedComponent/functions/InputCustomFields';
import { at } from 'Utils/objectUtils';

import ContactTranslation from 'Translations/contact.json';
import { convertApiValueToRTLBoolean } from 'Enums/languages';

function Contact(props) {
  let baseClasses = 'SortableItem rfb-item';
  const data = props.data;
  const defaultStyle = {
    border: '1px solid #edeff1',
    borderRadius: '3px',
    margin: '16px 0px 16px 0px',
  };
  return (
    <div className={classNames(baseClasses, props.isDragging ? 'is-placeholder' : '')} style={defaultStyle}>
      {!props.mutable && (
        <div>
          <HeaderBar
            parent={props.parent}
            editModeOn={props.editModeOn}
            data={props.data}
            onDestroy={props._onDestroy}
            onEdit={props.onEdit}
            static={data.static}
            required={data.required}
            primary={data.primary}
          />
        </div>
      )}
      <p dangerouslySetInnerHTML={{ __html: data.content }} />
      <div className='form-group'>
        <div className='row'>
          {data.first_name && (
            <div className='col-md-6 mb-3'>
              <Field
                label={ContactTranslation[props.language].first_name}
                name={`contacts.${props.data.id}.first_name`}
                component={TextField}
                type='text'
                placeholder={ContactTranslation[props.language].first_name}
                validate={[validators.required]}
                required
              />
            </div>
          )}
          {data.last_name && (
            <div className='col-md-6 mb-3'>
              <Field
                label={ContactTranslation[props.language].last_name}
                name={`contacts.${props.data.id}.last_name`}
                component={TextField}
                type='text'
                placeholder={ContactTranslation[props.language].last_name}
                validate={[validators.required]}
                required
              />
            </div>
          )}
          {data.title && (
            <div className='col-md-6 mb-3'>
              <Field
                label={ContactTranslation[props.language].title}
                name={`contacts.${props.data.id}.title`}
                component={TextField}
                type='text'
                placeholder={ContactTranslation[props.language].title}
                validate={[validators.required]}
                required
              />
            </div>
          )}
          {data.email && (
            <div className='col-md-6 mb-3'>
              <Field
                label={ContactTranslation[props.language].email}
                name={`contacts.${props.data.id}.email`}
                component={TextField}
                type='text'
                placeholder={ContactTranslation[props.language].email}
                validate={[validators.required, validators.email]}
                required
              />
            </div>
          )}
          {data.phone && (
            <div className='col-md-6 mb-3'>
              <Field
                label={ContactTranslation[props.language].phone}
                name={`contacts.${props.data.id}.phone`}
                component={TextField}
                type='text'
                required
                placeholder={ContactTranslation[props.language].phone}
                validate={[validators.required, validators.number]}
              />
            </div>
          )}
          {data.linkedin && (
            <div className='col-md-6 mb-3'>
              <Field
                label={ContactTranslation[props.language].linkedin}
                name={`contacts.${props.data.id}.linkedin`}
                component={TextField}
                type='text'
                placeholder={ContactTranslation[props.language].linkedin}
                validate={[validators.url]}
              />
            </div>
          )}
          {props.contactCustomFields.map(customField => {
            if (data[customField.field_name])
              return (
                <InputCustomFields
                  customField={{ ...customField, field_label: ContactTranslation[props.language][customField.field_name] || customField.field_label }}
                  key={customField.field_id}
                  domains={[]}
                  users={[]}
                  classLabel=''
                  noImageCountries={props.noImageCountries}
                  rtl={convertApiValueToRTLBoolean(props.language)}
                  fieldArray={`contacts.${props.data.id}.`}
                />
              );
          })}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    contactCustomFields: state.settings.company.contactCustomFields,
    language: at(state, 'settings.formBuilder.editing.language') || 'en',
  };
}

export default connect(mapStateToProps, {})(Contact);
