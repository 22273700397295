import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';

import { connect } from 'react-redux';

import { loadFunds, createFund, updateFund, deleteFund } from 'ReduxActions/fundActions';
import { utilActions } from 'ReduxActions/utilActions';

import { Permissible, canForClass } from '../../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';

import SubmitButton from 'SharedComponent/SubmitButton';
import FundList from '../FundList';
import FundModal from '../FundModal';

import './FundsContext.scss';
import { Mixpanel } from '../../../../app';

const actions = { loadFunds, createFund, updateFund, canForClass, deleteFund, ...utilActions };
const { confirm } = Modal;

function FundsContext(props) {
  const [showFund, setShowFund] = useState(false);
  const [fund, setFund] = useState(null);
  const [mode, setMode] = useState('create');

  useEffect(() => {
    onInit();
  }, []);

  const onInit = () => {
    props.loadFunds();
  };

  const closeFundModal = () => {
    Mixpanel.track('Closed Fund Modal');
    setShowFund(false);
    setFund(null);
    setMode('create');
  };

  const onOpen = fund => {
    setShowFund(true);
    props.loadCurrencies();
  };

  const onEdit = fund => {
    setShowFund(true);
    setFund(fund);
    setMode('update');
    props.loadCurrencies();
  };

  function showDeleteConfirm(fund) {
    confirm({
      title: `Are you sure you want to delete this fund ?`,
      content: fund.fund_name,
      icon: null,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        props.deleteFund(fund);
      },
      onCancel() {
        closeFundModal();
      },
    });
  }

  return (
    <div className='FundsContext'>
      <div className='display-flex-between flex-wrap'>
        <div className='page-title'>List of funds</div>
        <Permissible can={PermissionTypeEnum.CreateFund}>
          <SubmitButton
            label='New Fund'
            icon='icon-plus'
            onClick={() => {
              onOpen();
              Mixpanel.track('Clicked on Create Fund');
            }}
          />
        </Permissible>
      </div>
      <FundList funds={props.funds} loading={props.loading} onEdit={props.canForClass(PermissionTypeEnum.UpdateFund) && onEdit} onRemove={showDeleteConfirm} />
      <FundModal
        visible={showFund}
        initialValues={fund}
        mode={mode}
        handleCancel={closeFundModal}
        onCreate={props.createFund}
        onUpdate={props.updateFund}
        currencies={props.currencies}
      />
    </div>
  );
}

function mapStateToProps({ settings, utils }) {
  return {
    funds: settings.funds.all,
    loading: settings.funds.loading,
    currencies: utils.currencies,
  };
}

export default connect(mapStateToProps, actions)(FundsContext);
