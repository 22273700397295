import { createSelector } from '@reduxjs/toolkit';

import { StateV2 } from '../../../store/v2.type';
import { selectAllCalenderTasks, selectAllTasks } from './tasks.reducer';

const tasksState = (state: StateV2) => state.v2.tasks;

/* table selectors */
export const getAllTasks = createSelector(tasksState, state => state.tasksList);
export const getTasksList = createSelector(getAllTasks, selectAllTasks);
export const loadingTasks = createSelector(getAllTasks, state => state.loading);
export const getTaskMeta = createSelector(getAllTasks, state => state.meta);

export const editingTask = createSelector(tasksState, state => state.editing);

/* calender selectors */
export const getAllCalenderTasks = createSelector(tasksState, state => state.calenderTasksList);
export const getCalenderTasksList = createSelector(getAllCalenderTasks, selectAllCalenderTasks);
export const getCurrentDate = createSelector(getAllCalenderTasks, state => state.currentDate);
export const loadingCalenderTasks = createSelector(getAllCalenderTasks, state => state.loading);
