import axios from 'axios';
import api from './api';
import { message } from 'antd';

import { SIGNED_IN } from 'ReduxActions/authActions';
export const CURRENCIES_LOADED = 'utils:currencies:loaded';
export const COUNTRIES_LOADED = 'utils:countries:loaded';
export const DOMAINS_LOADED = 'utils:domains:loaded';
export const ACTIVITIES_LOADED = 'utils:activities:loaded';
export const CURRENCY_CONVERTER = 'utils:currency:conversion';
export const DOWNLOADING = 'utils:migration:download';
export const DOWNLOADING_FAILED = 'utils:migration:download:failed';
export const DOWNLOADING_SUCCESS = 'utils:migration:download:success';
export const CLEARBIT_LOADING = 'utils:clearbit:loading';
export const CLEARBIT_COMPANIES = 'utils:clearbit:companies:success';
export const CLEARBIT_LOADING_FAILED = 'utils:clearbit:loading: failed';
export const DISCARD_MESSAGES = 'utlis:migration:discard:messages';
export const IMPORTING_EXCEL = 'utlis:migration:excel:importing';
export const EXCEL_IMPORTED_SUCCESSFULLY = 'utlis:migration:excel:imported:successfully';
export const EXCEL_IMPORTED_FAILED = 'utlis:migration:excel:imported:failed';

export const utilActions = {
  loadCurrencies() {
    return dispatch => {
      api
        .get('/currencies')
        .then(({ data }) => {
          dispatch({ type: CURRENCIES_LOADED, payload: data.results });
        })
        .catch(error => {});
    };
  },

  loadCountries() {
    return dispatch => {
      api
        .get('/countries')
        .then(({ data }) => {
          dispatch({ type: COUNTRIES_LOADED, payload: data.results });
        })
        .catch(error => {});
    };
  },

  loadDomains() {
    return dispatch => {
      api
        .get('/domains')
        .then(({ data }) => {
          dispatch({ type: DOMAINS_LOADED, payload: data.results });
        })
        .catch(error => {});
    };
  },

  loadActivities() {
    return dispatch => {
      api
        .get('/activities')
        .then(({ data }) => {
          dispatch({ type: ACTIVITIES_LOADED, payload: data.results });
        })
        .catch(error => {});
    };
  },

  discardMessages() {
    return dispatch => {
      dispatch({ type: DISCARD_MESSAGES });
    };
  },

  migrateData(data) {
    return dispatch => {
      dispatch({ type: DOWNLOADING, payload: true });
      api
        .post('/migration', data)
        .then(res =>
          dispatch({
            type: DOWNLOADING_SUCCESS,
            payload: ' Congrats, Your data has been migrated',
          }),
        )
        .catch(error => dispatch({ type: DOWNLOADING_FAILED, error }));
    };
  },

  downloadExcel() {
    return dispatch => {
      api
        .get(`/imports/template`, { responseType: 'arraybuffer' })
        .then(({ data }) => {
          const file = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const a = document.createElement('a');
          const fileURL = window.URL.createObjectURL(file);
          a.href = fileURL;
          a.download = `template.xlsx`;
          a.click();
        })
        .catch(error => {});
    };
  },

  importExcel(object) {
    return dispatch => {
      dispatch({ type: IMPORTING_EXCEL, payload: true });
      const formData = new FormData();
      formData.append('file', object.file);
      formData.append('pipeline_id', object.pipeline_id);
      formData.append('stage_id', object.stage_id);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      api
        .post(`/imports`, formData, config)
        .then(({ data }) => {
          dispatch({ type: IMPORTING_EXCEL, payload: false });
          dispatch({ type: EXCEL_IMPORTED_SUCCESSFULLY, payload: data });
        })
        .catch(error => {
          dispatch({ type: IMPORTING_EXCEL, payload: false });
          dispatch({ type: EXCEL_IMPORTED_FAILED, payload: ['An error occurred while importing data, try again'] });
        });
    };
  },

  searchClearbit(suggest) {
    return dispatch => {
      dispatch({ type: CLEARBIT_LOADING });
      axios
        .get(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${suggest}`)
        .then(({ data }) => dispatch({ type: CLEARBIT_COMPANIES, payload: data }))
        .catch(err => dispatch({ type: CLEARBIT_LOADING_FAILED }));
    };
  },

  integrateGooleCalendar(code) {
    return dispatch => {
      api
        .post('/google-calendar', { code })
        .then(() => {
          message.success('Your Google Calendar is now linked to Pentugram');
          api.get('/me').then(({ data }) => dispatch({ type: SIGNED_IN, payload: data }));
        })
        .catch(() => {
          message.error('Failed to integrate with Google Calendar');
        });
    };
  },

  sendZoomCode(code) {
    return dispatch => {
      api
        .post('/zoom', { code })
        .then(({ data }) => {
          dispatch({
            type: SIGNED_IN,
            payload: data,
          });
        })
        .catch(err => {});
    };
  },
};
