import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { getPentugramItems, getMetricData, updateItem, updateElement } from '../../../../../actions/reportActions';
import { loadMetricsGroup, emptyMetricsGroup } from '../../../../../actions/metricsActions';
import * as validators from '../../../../../utils/validators';
import { FieldSelect } from '../../../../shared/SelectOption/FieldSelect';
import MetricTools from './MetricTools';

import './ChartTools.scss';

const dispatch = {
  getPentugramItems,
  loadMetricsGroup,
  emptyMetricsGroup,
  getMetricData,
  updateItem,
  updateElement,
};
class ChartTools extends Component {
  state = {};

  updateElement(field, value) {
    let item = {
      ...this.props.item,
      [field]: value,
    };
    this.props.updateItem(item.id, item);
  }

  renderOptions() {
    switch (this.props.item.type) {
      case 1:
        return (
          <MetricTools
            item={this.props.item}
            pentugramItems={this.props.pentugramItems}
            getDeals={this.props.getPentugramItems}
            getGroups={this.props.loadMetricsGroup}
            emptyMetricsGroup={this.props.emptyMetricsGroup}
            groups={this.props.groups}
            globalPeriod={this.props.tools.period}
            frequencies={this.props.frequencies}
            updateItem={this.props.updateItem}
            getMetricData={this.props.getMetricData}
          />
        );
      case 2:
        return <div>Portfolio</div>;
      case 3:
        return <div>Manual</div>;
      default:
        return null;
    }
  }

  render() {
    const chartTypes = [
      { label: 'From Metrics', value: 1 },
      { label: 'From Portfolio', value: 2, disabled: true },
      { label: 'Manual', value: 3, disabled: true },
    ];

    return (
      <div className='ChartTools'>
        {/* <div className="">
          <Field
            name={`type`}
            component={FieldSelect}
            options={chartTypes}
            onLoad={view => this.updateElement("type", view)} // TODO same field name with portfolio by country, activities
            validate={[validators.required]}
            label=""
            placeholder="Select source"
            type="text"
            multi={false}
            withImage={false}
            disabled={false}
            clearable={false}
          />
        </div> */}
        {this.renderOptions()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    item: state.reports.activeElement,
    pentugramItems: state.reports.pentugramItems,
    tools: state.reports.tools,
    groups: state.metrics.groups,
    frequencies: state.metrics.frequencies,
  };
}

export default connect(mapStateToProps, dispatch)(ChartTools);
