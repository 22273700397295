export enum ModalIdEnum {
  sharedModal = 'sharedModal',
  addContact = 'addContact',
  dashboardEditKpis = 'dashboardEditKpis',
  addTasks = 'addTasks',
  pipedriveIntegration = 'pipedriveIntegration',
  googleCalendarIntegration = 'googleCalendarIntegration',
  excelIntegration = 'excelIntegration',
  NotificationNote = 'NotificationNote',
  createPermission = 'createPermission',
}
