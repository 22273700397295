import moment from 'moment';
import { NewsDTO } from '../../generated/model/newsDTO';
import { WebsiteEnum } from './website.enum';

export interface NewsSummaryData {
  id: number;
  website: WebsiteEnum;
  link: string;
  image: string;
  title: string;
  website_url?: string;
  time: string;
  unixTime: number;
  saved: boolean;
  isRemoved: boolean;
}

export namespace NewsSummaryData {
  export function mapFromApi(news: NewsDTO): NewsSummaryData {
    return {
      id: news.news_id,
      website: WebsiteEnum.mapFromApi(news.news_website_name),
      link: news.news_link,
      image: news.news_image_url,
      title: news.news_title,
      time: moment(news.news_time * 1000).fromNow(),
      unixTime: news.news_time,
      saved: news.is_saved,
      isRemoved: false,
    };
  }
}
