import remove from 'lodash/remove';
import {
  EMAIL_TEMPLATES_LOADED,
  EMAIL_TEMPLATES_LOADING,
  EMAIL_TEMPLATE_DELETED,
  EMAIL_TEMPLATE_CREATED,
  EDITING_TEMPLATE,
  EMAIL_TEMPLATES_UPDATED,
} from 'ReduxActions/settingActions';
import { replace } from 'Utils/arrayUtils.js';

const initialState = {
  all: [],
  loading: false,
  count: 0,
  editing: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EMAIL_TEMPLATES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case EMAIL_TEMPLATES_LOADED:
      return {
        ...state,
        all: action.payload.results,
        count: action.payload._meta.totalCount,
        loading: false,
      };

    case EMAIL_TEMPLATE_DELETED:
      const templates = [...state.all];
      remove(templates, { emailtpl_id: action.payload });
      return {
        ...state,
        all: templates,
        count: templates.length,
      };

    case EMAIL_TEMPLATE_CREATED:
      return {
        ...state,
        all: [action.payload, ...state.all],
      };

    case EDITING_TEMPLATE:
      return {
        ...state,
        editing: action.payload,
      };

    case EMAIL_TEMPLATES_UPDATED:
      return {
        ...state,
        all: replace(state.all, { emailtpl_id: action.payload.emailtpl_id }, action.payload),
      };
  }

  return state;
}
