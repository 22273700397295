import PropTypes from 'prop-types';
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import Autosuggest from 'react-autosuggest';
import { withRouter } from 'react-router';
import { dealLabelStatus } from 'Enums/dealStatus';
import CustomLabel from 'SharedComponent/CustomLabel';
import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './Example.scss';

class Example extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentRefinement: PropTypes.string.isRequired,
    refine: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.currentRefinement,
    };

    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.handleKeyUp = debounce(this.handleKeyUp.bind(this), 1000);
  }

  handleKeyUp() {
    gaEvent(appCategories.header, appActions(appTypes.search, appEvents.typed).input);
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
    this.handleKeyUp();
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value);
  };

  onSuggestionsClearRequested = () => {
    this.props.refine();
  };

  getSuggestionValue(hit) {
    return hit.name;
  }

  renderSuggestion(hit) {
    return (
      <div className='hit-container' onClick={() => this.props.router.push(`/${hit.type == 'deal' ? 'deals' : 'contacts'}/${hit.objectID}`)}>
        <div className='hit'>
          {hit.image ? (
            <div className='hit-image'>
              <img src={hit.image} height='30' width='30' />
            </div>
          ) : (
            <div className='deal-image-null'>{hit.name.charAt(0)}</div>
          )}
          <div className='hit-content'>
            <div className='hit-name ml-3'>
              <span className='name-mark'>{hit.name}</span>
            </div>
            <div className='hit-url ml-4'>
              <span className='website-mark'>{hit.website}</span>
            </div>
          </div>
        </div>
        <div>
          {hit.type == 'deal' && [2, 3, 4].includes(hit.status) && (
            <CustomLabel
              label={dealLabelStatus[hit.status].label}
              color={dealLabelStatus[hit.status].color}
              border={hit.status == 2 ? '#e5e5e5' : 'transparent'}
              backgroundColor={dealLabelStatus[hit.status].backgroundColor}
              font='400 10px/20px Open Sans, sans-serif'
            />
          )}
        </div>
      </div>
    );
  }

  renderSectionTitle(section) {
    if (section.hits.length == 0) return null;
    return `${section.index.toUpperCase()}S`;
  }

  getSectionSuggestions(section) {
    return section.hits;
  }

  render() {
    const { hits } = this.props;
    const { value } = this.state;
    const inputProps = {
      placeholder: 'Search anything, contacts, deals … etc',
      onChange: this.onChange,
      value,
    };

    return (
      <div className='Example'>
        {/* <Stats /> */}
        <Autosuggest
          suggestions={hits}
          multiSection={true}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          renderSectionTitle={this.renderSectionTitle}
          getSectionSuggestions={this.getSectionSuggestions}
        />
      </div>
    );
  }
}

export default withRouter(Example);
