import classNames from 'classnames';
import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';

import './ToggleGroup.scss';

class ToggleGroup extends Component {
  constructor(props) {
    super(props);

    this.state = { error: props.error || '', selectedValue: null };
    this.selectOption = this.selectOption.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error != prevProps.error) setTimeout(() => this.setState({ error: this.props.error }), 50);
  }

  selectOption(option) {
    if (this.state.value == option.value) option = null;
    this.setState({ selectedValue: option.value });
    this.props.onSelect && this.props.onSelect(option.value);
    this.props.input.onChange(option.value);
    setTimeout(() => {
      this.props.onFocus && this.props.onFocus();
      this.props.onBlur && this.props.onBlur();
    }, 0);
  }

  renderButtons() {
    let check = false;
    if (this.props.defaultValue) {
      for (let item of this.props.options) {
        if (item.value == this.props.input.value) {
          check = true;
          break;
        }
      }
      if (!check) {
        const defaultOpt = this.props.options[0];
        this.setState({ selectedValue: defaultOpt.value });
        this.props.onSelect && this.props.onSelect(defaultOpt.value);
        this.props.input.onChange(defaultOpt.value);
      }
    }
    return this.props.options.map(opt => {
      const optClassName = classNames({
        selected: opt.value == this.props.input.value,
        'disabled-button': opt.disabled,
      });

      return (
        <button key={`${opt.value};${opt.label}`} type='button' className={optClassName} onClick={() => this.selectOption(opt)} disabled={opt.disabled}>
          {opt.icon && <i className={classNames(opt.icon, 'toggle-icon')} />}
          {opt.label}
        </button>
      );
    });
  }

  renderFlatButtons() {
    return (
      <ButtonGroup className='button-flat'>
        {this.props.options.map(item => {
          const optClassName = classNames('button-flat', {
            selected: item.value == this.props.input.value,
          });
          return (
            <Button key={`${item.value};${item.label}`} bsSize='small' className={optClassName} onClick={() => this.selectOption(item)} style={this.props.styles}>
              <div className='display-flex-center'>
                {this.props.icons && <span>{item.icon && <i className={classNames(item.icon, 'toggle-icon')} />}</span>}
                {this.props.icons && <span className='material-span'>{item.material && <i className='material-icons toggle-material'>{item.material}</i>}</span>}
                {item.label && <span>{item.label}</span>}
              </div>
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }

  render() {
    return (
      <div className='ToggleGroup'>
        {this.props.label && <div className={this.props.labelClass ? this.props.labelClass : ''}>{this.props.label}</div>}
        {this.props.flat ? <div>{this.renderFlatButtons()}</div> : <div className='buttonsContainer'>{this.renderButtons()}</div>}
      </div>
    );
  }
}

export default ToggleGroup;
