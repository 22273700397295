import React, { useEffect, useState } from 'react';

import api from 'ReduxActions/api';
import { Modal, Icon, Spin, message } from 'antd';
import domtoimage from 'Utils/domtoimage.js';
import jsPDF from 'jspdf';

import { CoverPage, Page, EndPage } from '../Pages';
import { PortfolioPanel, PieChart, CustomButton } from '../items';

import chunk from 'lodash/chunk';
import sortBy from 'lodash/sortBy';

import './PdfGenerator.scss';

const ITEMS_PER_PAGE = 7;

export default function PdfGenerator(props) {
  const [spin, setSpin] = useState(false);
  const [tip, setTip] = useState('');
  const [deals, setDeals] = useState([]);
  const [distribution, setDistribution] = useState({});

  useEffect(() => {
    onInit();
  }, []);

  const onInit = () => {
    updateIndicator({ spin: true, tip: '' });
    api
      .get(`/investors/${props.investor.id}/soa`)
      .then(({ data }) => {
        updateIndicator({ spin: false, tip: '' });
        setDeals(data.deals);
        setDistribution(data.charts);
      })
      .catch(err => {
        updateIndicator({ spin: false, tip: '' });
        message.error('Unable to fetch investor data');
      });
  };

  const antIcon = <Icon type='loading' style={{ fontSize: 24 }} spin />;

  const updateIndicator = ({ tip, spin }) => {
    setTip(tip);
    setSpin(spin);
  };

  const downLoadAsPDF = () => {
    updateIndicator({ spin: true, tip: 'Downloading...' });
    const pdf = new jsPDF('p', 'mm', 'a4', true);
    RecursiveDownLoad(pdf, 1);
  };

  const printPdf = () => {
    updateIndicator({ spin: true, tip: 'Please wait...' });
    const print = new jsPDF('p', 'mm', 'a4', true);
    RecursivePrint(print, 1);
  };

  const splitDistribution = dist => {
    const mapped = dist.map(item => ({
      ...item,
      currency: item.currencyName && item.currencyName.code,
    }));
    const sortedArray = sortBy(mapped, ['frequency', 'currency']);
    const result = chunk(sortedArray, ITEMS_PER_PAGE).map(it => grouping(it));
    return result;
  };

  const grouping = data => {
    const groups = ['frequency', 'currency'];
    const grouped = {};
    data.forEach(function (a) {
      groups
        .reduce(function (o, g, i) {
          o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {});
          return o[a[g]];
        }, grouped)
        .push(a);
    });
    return grouped;
  };

  const pdfDistribution = splitDistribution(deals);
  let tables = pdfDistribution.map((page, idx) =>
    Object.keys(page).map((frequency, index) =>
      Object.keys(page[frequency]).map((currency, x) => <PortfolioPanel key={'item' + x + 1} frequency={frequency} currency={currency} distribution={page[frequency][currency]} />),
    ),
  );
  let newTables = tables.flat().flat();
  const NumberOfPages = newTables.length;
  const pages = NumberOfPages > 0 ? new Array(NumberOfPages).fill().map(_ => newTables.splice(0, 1)) : [];
  const RecursivePrint = (print, page) => {
    const node = document.getElementById(`generate_page_${page}`);
    domtoimage
      .toPng(node)
      .then(dataUrl => {
        print.addImage(dataUrl, 'PNG', 0, 0);
        if (NumberOfPages + 4 == page) {
          updateIndicator({ spin: false, tip: '' });
          print.autoPrint();
          window.open(print.output('bloburl'), '_blank');
        } else {
          print.addPage();
          RecursivePrint(print, page + 1);
        }
      })
      .catch(e => {
        updateIndicator({ spin: false, tip: '' });
        message.error('Something went wrong, try again');
      });
  };

  const RecursiveDownLoad = (pdf, page) => {
    const node = document.getElementById(`generate_page_${page}`);

    domtoimage
      .toPng(node)
      .then(dataUrl => {
        pdf.addImage(dataUrl, 'PNG', 0, 0);
        if (NumberOfPages + 4 == page) {
          updateIndicator({ spin: false, tip: '' });
          message.success('Pdf downloaded successfully');
          pdf.save(`${props.investor ? props.investor.name : 'statement-of-account'}.pdf`);
        } else {
          pdf.addPage();
          RecursiveDownLoad(pdf, page + 1);
        }
      })
      .catch(e => {
        updateIndicator({ spin: false, tip: '' });
        message.error('Download was unabled to complete');
      });
  };
  return (
    <Modal visible={props.visible} onCancel={props.onClose} footer={null} closable={false} bodyStyle={{ minHeight: 1122, backgroundColor: '#dcdcdc' }} width={841}>
      <Spin indicator={antIcon} spinning={spin} tip={tip}>
        <div className='fixed-pos-gen-modal'>
          <CustomButton tooltip='Download as PDF' className='mr-2' placement='bottom' icon='download' size='large' shape='circle' type='primary' onClick={downLoadAsPDF} />
          <CustomButton tooltip='Print' placement='bottom' icon='printer' size='large' shape='circle' type='primary' onClick={printPdf} />
        </div>
        <div id='generate_page_1' className='PdfGenerator p-3'>
          <CoverPage investorName={props.investor && props.investor.name} myCompany={props.myCompany} />
        </div>
        {pages.map((content, idx) => {
          return (
            <div key={'page' + idx + 1} id={`generate_page_${idx + 2}`} className='PdfGenerator p-3'>
              <Page myCompany={props.myCompany} title=''>
                {content}
              </Page>
            </div>
          );
        })}
        <div id={`generate_page_${NumberOfPages + 2}`} className='PdfGenerator p-3'>
          <Page myCompany={props.myCompany} title='Portfolio Diversification'>
            <div className='row justify-content-md-center'>
              <div className='col-xs-12 col-sm-12 col-md-10 col-lg-6'>
                {distribution && distribution.rate && <PieChart data={distribution.rate} type='ring_graphic' title='Investment Securities' />}
              </div>
              <div className='col-xs-12 col-sm-12 col-md-10 col-lg-6'>
                {distribution && distribution.country && <PieChart data={distribution.country} title='Country Diversification' />}
              </div>
            </div>
          </Page>
        </div>
        <div id={`generate_page_${NumberOfPages + 3}`} className='PdfGenerator p-3'>
          <Page myCompany={props.myCompany} title='Portfolio Diversification'>
            <div className='row justify-content-md-center'>
              <div className='col-xs-12 col-sm-12 col-md-10 col-lg-6'>
                {distribution && distribution.currency && <PieChart data={distribution.currency} title='Currency Diversification' />}
              </div>
              <div className='col-xs-12 col-sm-12 col-md-10 col-lg-6'>
                {distribution && distribution.sector && <PieChart data={distribution.sector} title='Sub Sector Diversification' />}
              </div>
            </div>
          </Page>
        </div>
        <div id={`generate_page_${NumberOfPages + 4}`} className='PdfGenerator p-3'>
          <EndPage myCompany={props.myCompany} />
        </div>
      </Spin>
    </Modal>
  );
}
