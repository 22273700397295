import React, { useEffect, useState } from 'react';
import { Modal, Statistic, Card } from 'antd';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';
import SubmitButton from 'SharedComponent/SubmitButton';

import TextField from 'SharedComponent/TextField';
import * as validators from 'Utils/validators';

import { at } from 'Utils/objectUtils';
import { companyDetails } from './defList';

import './AddUser.scss';
import { getBillingPackages } from '../../billing/Billing/BillingApiCall';
import { set } from 'lodash';

function AddUser(props) {
  const [packages, setPackages] = useState([]);
  const [price, setPrice] = useState(0);
  const [messageDetails, setMessageDetails] = useState('');

  const formatUser = user => {
    return {
      email: user.email,
      fullname: user.profile.fullname,
      role: user.roleName.role_id,
    };
  };

  const closeModal = () => {
    props.reset();
    props.initialize(null);
    props.onDismiss();
  };

  const submit = values => {
    if (props.initialValues) {
      props.onUpdate({ ...formatUser(values), id: values.id });
      gaEvent(appCategories.settings_users, appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.update}`, appEvents.clicked).button);
    } else {
      props.onSave(formatUser(values));
      gaEvent(appCategories.settings_users, appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.save}`, appEvents.clicked).button);
    }
    closeModal();
  };

  const parseRoles = roles => {
    return roles
      .filter(opt => opt.name.toLowerCase() !== 'owner')
      .map(opt => {
        return {
          value: opt.role_id,
          label: opt.name,
        };
      });
  };

  useEffect(() => {
    getBillingPackages(setPackages);
  }, []);

  useEffect(() => {
    if (packages.length > 0) {
      packages.forEach(pack => {
        const userPrice = pack.prices.find(price => price.id === props.priceId);
        if (userPrice) {
          const priceWithDecimals = userPrice.unit_price.amount / 100;
          if (userPrice.billing_cycle.interval === 'year') {
            setPrice(priceWithDecimals / 12);
            setMessageDetails(
              <div>
                {' '}
                Your monthly payment for <strong>{props.total_users + 1} users</strong> will be <strong>{(priceWithDecimals / 12) * (props.total_users + 1)}</strong>
              </div>,
            );
          } else {
            setPrice(priceWithDecimals);
            setMessageDetails(
              <div>
                {' '}
                Your monthly payment for <strong>{props.total_users + 1} users</strong> will be <strong>{priceWithDecimals * (props.total_users + 1)}</strong>
              </div>,
            );
          }
        }
      });
    }
  }, [packages]);

  const onClickCancelBtn = () => {
    props.initialValues
      ? gaEvent(appCategories.settings_users, appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button)
      : gaEvent(appCategories.settings_users, appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    closeModal();
  };

  return (
    <div className='AddUser'>
      <Modal
        title={props.initialValues ? 'Update user' : 'Invite a New User'}
        visible={props.showDetails}
        onCancel={onClickCancelBtn}
        footer={null}
        wrapClassName='AddUser'
        width={700}
      >
        <form className='' onSubmit={props.handleSubmit(submit)}>
          <div className='row justify-content-md-center align-items-center'>
            <div className='col-md-7'>
              <div className='mb-2'>
                <Field label='Full name' name={`profile.fullname`} component={TextField} type='text' placeholder='Add full name' required validate={[validators.required]} />
              </div>
              <div className='mb-2'>
                <Field label='Email' name={`email`} component={TextField} type='text' placeholder='Add Email' required validate={[validators.required]} />
              </div>
              <div className='mb-2'>
                <Field
                  label='Role'
                  name={`roleName.role_id`}
                  component={FieldSelect}
                  placeholder='Add Role'
                  options={parseRoles(props.roles)}
                  clearable={false}
                  validate={[validators.required]}
                  required
                />
              </div>
            </div>
            {!props.initialValues && props.priceId && (
              <div className='col-md-5'>
                <Card>
                  <Statistic title={props.details.title} value={price} prefix='$' />
                </Card>
              </div>
            )}
          </div>
          {!props.initialValues && props.priceId && <div className='mt-2 display-flex-end'>{messageDetails}</div>}
          <div className='display-flex-end'>
            <div className='m-3'>
              <SubmitButton
                label='Cancel'
                icon='icon-close'
                style={{
                  width: '150px',
                  color: '#53627c',
                  backgroundColor: '#fff',
                  border: '1px solid #e1e4e8',
                }}
                onClick={onClickCancelBtn}
              />
            </div>
            <div className='m-3'>
              <SubmitButton
                type='submit'
                label={props.initialValues ? 'UPDATE' : 'SAVE'}
                icon='icon-check'
                className='submit-btn-p'
                disabled={props.submitting || props.pristine}
              />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
}

AddUser = reduxForm({ form: 'add-new-user', enableReinitialize: true })(AddUser);

function mapStateToProps(state) {
  const planId = at(state, 'auth.user.subscription.subscription_plan_id');
  const priceId = at(state, 'auth.user.subscription.paddle_price_id');
  const total_users = at(state, 'auth.user.total_users') || 0;
  return {
    details: companyDetails(planId, Number(total_users)),
    priceId,
    initialValues: state.settings.users.editing,
    total_users,
  };
}

AddUser = connect(mapStateToProps)(AddUser);

export default AddUser;
