import { PermissionAttributeEnum } from '../../enums/permission/permission-attribute.enum';
import { PermissionTypeEnum } from '../../enums/permission/permission-type.enum';
import { GetMeResponseDTO } from '../../generated/model/getMeResponseDTO';
import { CompanyData } from '../company/company-data';

export interface MeData {
  uuid: number;
  isZoom: boolean;
  isGoogleCalendar: boolean;
  slackState: string;
  isSlackAuthorized: boolean;
  company: CompanyData;
  pages: PermissionTypeEnum[];
  actions: PermissionTypeEnum[];
  userAttributes: PermissionAttributeEnum[];
}

export namespace MeData {
  export function mapFromApi(user: GetMeResponseDTO): MeData {
    return {
      uuid: user.id,
      company: user.company && CompanyData.mapFromApi(user.company),
      slackState: user.slack_state,
      isSlackAuthorized: user.is_slack_authorized,
      isGoogleCalendar: user.is_google_calendar_authorized,
      isZoom: user.is_zoom_authorized,
      pages: (user.page_permissions || []).map(page => PermissionTypeEnum.convertFromApi.getValue(page)),
      actions: (user.permissions || []).map(permission => PermissionTypeEnum.convertFromApi.getValue(permission)),
      userAttributes: (user.user_attributes || []).map(attribute => PermissionAttributeEnum.convertFromApiValue.getValue(attribute)),
    };
  }
}
