import { IMap } from '../../core/utils/map-utils';

export enum NotificationTypeEnum {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Confirm = 'confirm',
}

export namespace NotificationTypeEnum {
  export const toBtnClass: IMap<NotificationTypeEnum, string> = new IMap<NotificationTypeEnum, string>([
    [NotificationTypeEnum.Success, 'pentugram-success-btn'],
    [NotificationTypeEnum.Confirm, 'pentugram-success-btn'],
    [NotificationTypeEnum.Info, 'pentugram-primary-btn'],
    [NotificationTypeEnum.Warning, 'pentugram-warning-btn'],
    [NotificationTypeEnum.Error, 'pentugram-danger-btn'],
  ]);
}
