import { FileLightDTO } from '../../generated/model/fileLightDTO';

export interface FileLightData {
  uuid: string;
  uid: string;
  name: string;
  url: string;
  size: number;
  type: string;
  originFileObj: File | Blob;
}

export namespace FileLightData {
  export function mapFromApi(file: FileLightDTO): FileLightData {
    return {
      uuid: file.file_id.toString(),
      uid: file.file_id.toString(),
      name: file.title,
      url: file.url,
      size: file.file_size,
      type: file.file_type,
      originFileObj: null,
    };
  }

  export function mapToApi(file: FileLightData): FileLightDTO {
    return {
      file_id: Number(file.uuid),
      title: file.name,
      url: file.url,
      file_type: file.type,
      file_size: file.size,
    };
  }

  export function formatToFileBlob(file: FileLightData): File | Blob {
    return file.originFileObj || null;
  }
}
