import { createSelector } from '@reduxjs/toolkit';

import { StateV2 } from '../../../../store/v2.type';

const modalState = (state: StateV2) => state.v2.modal;

export const getModalVisibility = createSelector(modalState, state => state.visible);
export const getCurrentModalId = createSelector(modalState, state => state.modalId);
export const getModalEditMode = createSelector(modalState, state => state.editMode);
export const getModalLoading = createSelector(modalState, state => state.loading);
export const getSubmitted = createSelector(modalState, state => state.submitted);
