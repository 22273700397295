import * as React from 'react';
import { connect } from 'react-redux';
import { Rnd } from 'react-rnd';
import { makeElementActive, changeProperties, dropElement } from '../../../../actions/reportActions';
import './Draggable.scss';
import get from 'lodash/get';

const resizeHandlerClasses = {
  right: 'leftAndRight',
  left: 'leftAndRight',
  top: 'topAndBottom',
  bottom: 'topAndBottom',
  bottomLeft: 'handler',
  bottomRight: 'handler',
  topLeft: 'handler',
  topRight: 'handler',
};

type Props = {
  id: string,
  activeElementId: string,
  activeElement: {
    properties: {
      location: { x: number, y: number },
      size: { height: number | string, width: number | string },
    },
  },
  style: {},
  properties: {
    location: { x: number, y: number },
    size: { height: number | string, width: number | string },
  },
  makeElementActive: Function,
  changeProperties: Function,
  dropElement: Function,
  children: React.Node,
};

function getShadowValue(props) {
  let blur = props.blur || 0;
  let color = props.color || 'black';
  let spread = props.spread || 0;
  let xOffset = props.xOffset || 0;
  let yOffset = props.yOffset || 0;
  return `${xOffset}px ${yOffset}px ${blur}px ${spread}px ${color}`;
}

class Draggable extends React.Component<Props> {
  onSelectItem = () => {
    this.makeActiveIfIsNot();
  };

  getPercent(x, y) {
    if (y == 0) return 0;
    return ((x / y) * 100).toFixed(1);
  }

  getNumber(number, percent) {
    return (number * percent) / 100;
  }

  onResize = (e, direction, ref, delta, position) => {
    const { changeProperties } = this.props;

    this.makeActiveIfIsNot();

    changeProperties({
      location: {
        x: position.x,
        y: position.y,
      },

      size: {
        width: ref.clientWidth,
        height: ref.clientHeight,
      },
    });
  };

  onDrag = (event, data) => {
    const { changeProperties } = this.props;

    this.makeActiveIfIsNot();

    changeProperties({
      location: {
        x: data.x,
        y: data.y,
      },
    });
  };

  enableResize(bool) {
    return {
      bottom: bool,
      bottomLeft: bool,
      bottomRight: bool,
      left: bool,
      right: bool,
      top: bool,
      topLeft: bool,
      topRight: bool,
    };
  }

  isElementSelected = () => {
    const { activeElementId, id } = this.props;

    return activeElementId === id;
  };

  dropElement = () => this.props.dropElement(this.props.id);

  makeActiveIfIsNot() {
    if (!this.isElementSelected()) {
      const { makeElementActive, id } = this.props;
      makeElementActive(id);
    }
  }

  render() {
    let { activeElement, properties, style, children, editor_width, editor_height, mode, item, zoom } = this.props;

    const isMultiSelected = this.props.selectedItems && this.props.selectedItems[item.id] && this.props.selectedItems[item.id].id;
    const { location, size } = this.isElementSelected() ? activeElement.properties : properties;
    if (item && item.imgStyle) {
      let shadowProps = get(item, 'imgStyle.shadow', {});
      let boxShadow = getShadowValue(shadowProps);
      style = {
        ...style,
        boxShadow,
      };
    }
    if (mode == 'view')
      return (
        <Rnd
          bounds='parent'
          disableDragging={true}
          size={{ width: size.width * zoom, height: size.height * zoom }}
          position={{ x: location.x * zoom, y: location.y * zoom }}
          onClick={() => {}}
          onDragStart={() => {}}
          onResizeStart={() => {}}
          onDrag={() => {}}
          onResize={() => {}}
          resizeHandleClasses={null}
          className=''
          style={{ ...style }}
          resizeHandleWrapperClass=''
          enableResizing={this.enableResize(false)}
        >
          {children}
        </Rnd>
      );
    else
      return (
        <Rnd
          bounds='parent'
          disableDragging={false}
          size={{ width: size.width, height: size.height }}
          position={{ x: location.x, y: location.y }}
          onClick={this.onSelectItem}
          onDragStart={this.onSelectItem}
          onResizeStart={this.onSelectItem}
          onDrag={this.onDrag}
          onResize={this.onResize}
          enableUserSelectHack={false}
          resizeHandleClasses={resizeHandlerClasses}
          className={`draggable ${this.isElementSelected() ? 'active' : ''} ${isMultiSelected ? 'selected' : ''}`}
          style={{ ...style }}
          resizeHandleWrapperClass={`resizeHandlerWrapper ${this.isElementSelected() ? 'active' : ''}`}
          lockAspectRatio={properties.lockAspectRatio}
        >
          {children}
        </Rnd>
      );
  }
}

const mapStateToProp = state => ({
  ...state,
  activeElement: state.reports.activeElement,
  selectedItems: state.reports.selectedItems,
  activeElementId: state.reports.activeElement ? state.reports.activeElement.id : null,
});

export default connect(mapStateToProp, {
  makeElementActive,
  changeProperties,
  dropElement,
})(Draggable);
