import moment from 'moment';
import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import { loadContact, updateContact, edit, dismissModal } from 'ReduxActions/contactActions';
import defaultImage from 'Assets/img/default.png';
import AddContact from '../../contacts/AddContact';
import { TooltipAvatar } from 'SharedComponent/antd/CustomAvatar';

import { moneyFormatter } from 'Utils/objectUtils';

import './ContactView.scss';

const actions = { loadContact, updateContact, edit, dismissModal };
class ContactView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };

    this.show = this.show.bind(this);
    this.dismiss = this.dismiss.bind(this);
  }

  componentWillMount() {
    this.props.loadContact(this.props.params.id);
  }

  componentDidUpdate(prevProps, nextProps) {
    if (this.props.params.id != prevProps.params.id) {
      this.props.loadContact(this.props.params.id);
    }
  }

  dismiss() {
    this.setState({ show: false });
    this.props.dismissModal();
  }

  show() {
    this.props.edit(this.props.result);
    this.setState({ show: true });
  }

  openSkype(skype) {
    window.location = 'skype:' + skype + '?chat';
  }

  render() {
    const contact = this.props.result;
    if (contact) {
      return (
        <div className='ContactView'>
          <div className='display-flex-between'>
            <div className='display-flex-start'>
              <div className='breadcrumbs-card'>
                <i className='icon-people' />
              </div>
              <div className='breadcrumbs-card-text'>
                <span className='header-section'>Contact / </span>
                {contact.first_name} {contact.last_name}
              </div>
            </div>
            <div>
              <Button className='submit-btn-p' onClick={() => this.show()}>
                <i className='icon-pencil mr-2' />
                Update
              </Button>
            </div>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4'>
                <img className='img-responsive' src={contact.image || defaultImage} />
              </div>
              <div className='col-md-8'>
                <div className='row mb-4'>
                  <div className='col-md-6'>
                    <div className='label-top'>First name</div>
                    <div className='text-details'>{contact.first_name}</div>
                  </div>
                  <div className='col-md-6'>
                    <div className='label-top'>Last name</div>
                    <div className='text-details'>{contact.last_name}</div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col-md-6'>
                    <div className='label-top'>Company</div>
                    <div className='text-details'>{contact.company}</div>
                  </div>
                  <div className='col-md-6'>
                    <div className='label-top'>Title</div>
                    <div className='text-details'>{contact.title}</div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col-md-6'>
                    <div className='label-top'>Email</div>
                    <div className='text-details'>{contact.email && contact.email.toLowerCase()}</div>
                  </div>
                  <div className='col-md-6'>
                    <div className='label-top'>Phone number</div>
                    <div className='text-details'>{contact.phone}</div>
                  </div>
                </div>
                <div className='row mb-4'>
                  {this.props.contactCustomFields.map(cf => (
                    <div className='col-md-6 mb-3' key={cf.field_id}>
                      <div className='label-top'>{cf.field_label}</div>
                      <div className='text-details'>
                        <DisplayCustomField
                          field_name={contact && contact[cf.field_name]}
                          type={cf.field_type}
                          data={contact[cf.field_name]}
                          monetaryCode={contact[cf.field_name + '_code']}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className='mb-3'>Link Social Networks</div>
                <div className='row mb-4'>
                  <div className='col-md-6'>
                    <div className='label-top'>Facebook</div>
                    <a className='text-details' href={contact.facebook} target='_blank'>
                      {contact.facebook}
                    </a>
                  </div>
                  <div className='col-md-6'>
                    <div className='label-top'>Linkedin</div>
                    <a className='text-details' href={contact.linkedin} target='_blank'>
                      {contact.linkedin}
                    </a>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col-md-6'>
                    <div className='label-top'>Twitter</div>
                    <a className='text-details' href={contact.twitter} target='_blank'>
                      {contact.twitter}
                    </a>
                  </div>
                  <div className='col-md-6'>
                    <div className='label-top'>Skype</div>
                    <a className='text-details cursor-pointer' onClick={() => this.openSkype(contact.skype)}>
                      {contact.skype}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddContact showDetails={this.state.show} onDismiss={this.dismiss} update={this.props.updateContact} customFields={this.props.contactCustomFields} />
        </div>
      );
    }
    return (
      <div className='custom-spinner'>
        <div id='custom-spinner-id' />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  result: state.contacts.result,
  loading: state.contacts.loading,
  contactCustomFields: state.settings.company.contactCustomFields,
});

export default connect(mapStateToProps, actions)(ContactView);

const DisplayCustomField = props => {
  if (!props.field_name) return null;
  switch (props.type) {
    case 'date':
      return <div>{moment(props.data * 1000).format('ll')}</div>;
    case 'users':
      return (
        <div>
          {props.data.map(i => (
            <TooltipAvatar key={i.id} size='small' tooltip={i.fullname} image={i.image || defaultImage} />
          ))}
        </div>
      );
    case 'monetary':
      return <div>{moneyFormatter(props.data, props.monetaryCode)}</div>;
    default:
      return <div>{props.data}</div>;
  }
};
