import remove from 'lodash/remove';
import {
  LOADING_METRIC,
  METRIC_LOADED,
  FREQUENCIES_LOADED,
  LOADING_METRICS_GROUP,
  METRICS_GROUP_LOADED,
  METRICS_GROUP_CREATED,
  METRICS_GROUP_UPDATED,
  METRICS_GROUP_DELETED,
  LOADING_METRIC_GROUP,
  METRIC_GROUP_LOADED,
  CATEGORIES_LOADED,
  ADDED_METRIC_TO_GROUP,
  METRIC_CATEGORY_DELETED,
  SEARCHING_GROUP_METRIC,
  METRIC_GENERATE_FILE,
  METRIC_SEND_REMINDER,
  METRIC_ONLINE_FORM_LOADED,
  METRIC_TEMPLATES_LOADED,
} from '../actions/metricsActions';
import { replace } from '../utils/arrayUtils.js';

function mergeMetrics(item, metrics) {
  const metric = metrics.find(it => it.category_id === item[0].category_id);
  if (metric) return replace(metrics, { category_id: item[0].category_id }, item[0]);
  else return [...metrics, item[0]];
}

const initialState = {
  loading: true,
  groups: [],
  group: null,
  frequencies: [],
  categories: [],
  templates: [],
  metric: null,
  spinner: false,
  data: null,
  search: '',
  onlineForm: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FREQUENCIES_LOADED:
      return {
        ...state,
        frequencies: action.payload,
      };

    case LOADING_METRIC_GROUP:
    case LOADING_METRICS_GROUP:
      return {
        ...state,
        loading: action.payload,
      };

    case METRICS_GROUP_LOADED:
      return {
        ...state,
        groups: action.payload,
        loading: false,
      };

    case METRIC_ONLINE_FORM_LOADED:
      return {
        ...state,
        loading: false,
        onlineForm: action.payload,
      };

    case METRICS_GROUP_CREATED:
      return {
        ...state,
        groups: [action.payload, ...state.groups],
      };

    case METRIC_TEMPLATES_LOADED:
      return {
        ...state,
        templates: action.payload,
      };

    case METRICS_GROUP_UPDATED:
      return {
        ...state,
        groups: replace(state.groups, { group_id: action.payload.group_id }, action.payload),
      };

    case METRICS_GROUP_DELETED:
      const list = [...state.groups];
      remove(list, { group_id: action.payload });
      return {
        ...state,
        groups: list,
      };

    case METRIC_GROUP_LOADED:
      return {
        ...state,
        group: action.payload,
        loading: false,
      };

    case CATEGORIES_LOADED:
      return {
        ...state,
        categories: action.payload,
      };

    case LOADING_METRIC:
      return {
        ...state,
        spinner: action.payload,
      };

    case METRIC_LOADED:
      return {
        ...state,
        spinner: false,
        metric: action.payload,
      };

    case METRIC_GENERATE_FILE:
      const new_metric = state.groups.find(it => it.group_id == action.payload);
      return {
        ...state,
        groups: replace(state.groups, { group_id: action.payload }, { ...new_metric, is_generated: true }),
      };

    case METRIC_SEND_REMINDER:
      return {
        ...state,
        groups: replace(state.groups, { group_id: action.payload.group_id }, action.payload),
      };

    case SEARCHING_GROUP_METRIC:
      return {
        ...state,
        search: action.payload,
      };

    case ADDED_METRIC_TO_GROUP:
      return {
        ...state,
        loading: false,
        group: {
          ...state.group,
          data: mergeMetrics(action.payload, state.group.data),
        },
      };

    case METRIC_CATEGORY_DELETED:
      const items = state.group ? [...state.group.data] : [];
      remove(items, { category_id: action.payload });
      return {
        ...state,
        group: {
          ...state.group,
          data: items,
        },
      };
  }

  return state;
}
