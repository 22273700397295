import moment from 'moment';
import { FileResponseDTO } from '../../../generated/model/fileResponseDTO';
import { FileTypeEnum } from '../../../enums/file/file-type-enum';
import { DealLightData } from '../../deal/deal-light-data';
import { ApiToType } from './files-type';

export interface FileSummaryData {
  fileId: string;
  title: string;
  fileSize: string;
  urlFile: string;
  deal: DealLightData;
  createdAt: string;
  type: string | FileTypeEnum;
}

export namespace FileSummaryData {
  export function mapFromApi(file: FileResponseDTO): FileSummaryData {
    return {
      fileId: file.file_id.toString(),
      title: file.title,
      fileSize: (file.file_size / 1048576).toFixed(2) + 'mb',
      urlFile: file.url,
      type: (file.file_type && ApiToType(file.file_type.toLocaleLowerCase())) || FileTypeEnum.File,
      deal: file.deal && DealLightData.mapFromApi(file.deal),
      createdAt: moment(file.created_at * 1000).format('D MMM, YY [at] h:mm'),
    };
  }
}
