import { InnerSortDirectionEnum } from '../../enums/sort-direction.enum';

export const defaultPageSize = 20;

export interface BaseQuery<T> {
  page: number;
  size: number;
  sort: { attribute: keyof T; direction: InnerSortDirectionEnum };
  maxReached?: boolean;
  textSearch?: string;
}

export function initializeQueries<T>(attribute: keyof T, direction?: InnerSortDirectionEnum): BaseQuery<T> {
  return {
    page: 0,
    size: defaultPageSize,
    sort: attribute ? { attribute, direction } : undefined,
    maxReached: false,
  };
}
