import { CreateZoomAccountByAuthCodeRequestDTO } from '../../../../generated/model/createZoomAccountByAuthCodeRequestDTO';

export interface ZoomIntegrationModel {
  code: string;
}

export namespace ZoomIntegrationModel {
  export function mapToApi(value: ZoomIntegrationModel): CreateZoomAccountByAuthCodeRequestDTO {
    return {
      code: value.code,
    };
  }
}
