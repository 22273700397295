import lodash from 'lodash';
import { useSelector } from 'react-redux';

import { PermissionTypeEnum } from '../../../core/enums/permission/permission-type.enum';
import { allowedPermissions } from '../../../store/me/me.selectors';

export function can(permissionEnum: PermissionTypeEnum): boolean {
  const permissions = useSelector(allowedPermissions);

  return lodash.includes([...permissions.pages, ...permissions.actions], permissionEnum);
}
