import { CreateGoogleCalendarAccountRequestDTO } from '../../../../../generated/model/createGoogleCalendarAccountRequestDTO';

export interface CalendarForCreateModel {
  code: string;
}

export namespace CalendarForCreateModel {
  export function mapToApi(value: CalendarForCreateModel): CreateGoogleCalendarAccountRequestDTO {
    return {
      code: value.code,
    };
  }
}
