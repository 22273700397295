import React, { Component } from 'react';

import './Spinner.scss';

export default class Spinner extends Component {
  render() {
    if (!this.props.isLoading) return null;
    return (
      <div className='Spinner'>
        <div className='custom-spinner'>
          <div className='spinner-details' />
        </div>
      </div>
    );
  }
}
