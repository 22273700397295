import React from 'react';

import FundsTable from '../Table';

import './FundList.scss';

export default function FundList(props) {
  return (
    <div className='FundList'>
      <FundsTable items={props.funds} isLoading={props.loading} onEdit={props.onEdit} onRemove={props.onRemove} />
    </div>
  );
}
