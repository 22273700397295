// import Intercom from 'react-intercom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { actions } from 'ReduxActions/authActions';
import { loadCompanyCustomFields } from 'ReduxActions/settingActions';
import { loadFrequencies } from 'ReduxActions/metricsActions';
import { loadPipelineTypeList } from 'ReduxActions/pipelineActions';
import * as reportActions from 'ReduxActions/reportActions';
import pentugramTextLogo from '../../../assets/img/logo.svg';
import Account from '../../app/Account';
import Notifications from '../../app/Notifications';
import NavigationSidebar from './NavigationSidebar';
import TemplateSidebar from './TemplateSidebar';
import TemplateToolbar from './TemplateToolbar';
import ViewReport from './TemplateToolbar/ViewReport';
import PdfGenerator from './PdfGenerator';
import Support from '../../app/Support';

import ID from 'Utils/UUID';
import { at } from 'Utils/objectUtils';
import size from 'lodash/size';

import './TemplateNavbar.scss';
import get from 'lodash/get';

const dispatch = {
  ...actions,
  ...reportActions,
  loadFrequencies,
  loadCompanyCustomFields,
  loadPipelineTypeList,
};
class TemplateNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReport: false,
      generateReport: false,
    };
    this.updateFrequently = this.updateFrequently.bind(this);
    this.child = React.createRef();
  }

  componentWillMount() {
    this.props.checkToken();
  }

  componentDidMount() {
    this.props.loadFrequencies();
    this.props.loadCompanyCustomFields();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tools.auto_save !== this.props.tools.auto_save) {
      if (nextProps.tools.auto_save) {
        this.intervalId = setInterval(this.updateFrequently, 10000);
      } else clearInterval(this.intervalId);
    }
  }

  updateFrequently() {
    this.props.updateSimpleReport({
      ...this.props.tools,
      report_id: this.props.editor_prop.id,
      content: this.props.report,
      shared_components: this.props.shared,
      global_params: {
        auto_save: this.props.tools.auto_save,
        showHeader: this.props.tools.showHeader,
        showFooter: this.props.tools.showFooter,
        showCoverPage: this.props.tools.showCoverPage,
      },
      is_auto_save: true,
    });
  }

  componentWillUnmount() {
    this.props.unmountReducer();
    clearInterval(this.intervalId);
  }

  parsePeriod(object) {
    let fromYear = get(object, 'from.year.value', new Date().getFullYear());
    let toYear = get(object, 'to.year.value', new Date().getFullYear());
    let fromPeriod = get(object, 'from.period.value', 0);
    let toPeriod = get(object, 'to.period.value', 0);
    if (object.frequency_id == 4) {
      return {
        from: [fromYear],
        to: [toYear],
      };
    } else
      return {
        from: [fromPeriod, fromYear],
        to: [toPeriod, toYear],
      };
  }

  refreshReport(data) {
    const update = {
      ...this.parsePeriod(data),
      period: data,
      reportId: this.props.editor_prop.id,
    };
    this.props.refreshReport(update);
  }

  onUpdate(data) {
    const content = {
      ...data,
      period: this.props.tools.period,
      content: this.props.report,
      shared_components: this.props.shared,
    };

    if (this.props.editor_prop.type == 'report') {
      if (this.props.editor_prop.mode === 'edit') {
        this.props.updateSimpleReport({ ...content, report_id: this.props.editor_prop.id });
      }
    }
  }

  onSave(data) {
    const content = {
      ...data,
      global_params: {
        auto_save: this.props.tools.auto_save,
        showHeader: this.props.tools.showHeader,
        showFooter: this.props.tools.showFooter,
        showCoverPage: this.props.tools.showCoverPage,
      },
      period: this.props.tools.period,
      // auto_save: this.props.tools.auto_save,
      // showHeader: this.props.tools.showHeader,
      // showFooter: this.props.tools.showFooter,
      content: this.props.report,
      shared_components: this.props.shared,
    };
    if (this.props.editor_prop.type == 'report') {
      if (this.props.editor_prop.mode === 'create') {
        this.props.saveReport(content);
      } else if (this.props.editor_prop.mode === 'edit') {
        this.props.updateReport({ ...content, report_id: this.props.editor_prop.id });
      }
    } else this.props.updateTemplate({ ...content, template_id: this.props.editor_prop.id });
  }

  shareReport(email_body) {
    if (email_body.send_pdf) {
      this.setState({ generateReport: email_body.send_pdf });
      this.child.current.generatePdf({ ...email_body, id: this.props.editor_prop.id });
    } else {
      this.props.shareReport({ ...email_body, id: this.props.editor_prop.id });
    }
  }

  get title() {
    const tools = this.props.tools;
    return (tools && tools.title) || 'Pentugram';
  }

  addContentTable = () => {
    let location = { x: 0, y: 0 };
    let border = { show: false, color: 'white' };
    let properties = {
      location,
      alignment: '',
      border,
      color: {
        color: '#000',
        backgroundColor: 'transparent',
      },
      header: { backgroundColor: 'transparent' },
      size: { width: this.props.tools.width, height: 300 },
    };

    const object = {
      properties,
      key: 'content_table',
      content_table: this.extractReportHeadings(this.props.content_table),
      id: ID.uuid(),
    };
    this.props.addField(object);
    this.props.makeElementActive(object.id);
  };

  extractReportHeadings = ids_array => {
    let content_table = [];
    let page_number = 1;
    for (const page of this.props.report) {
      for (const elem_id in page) {
        for ({ id } of ids_array) {
          if (id === elem_id) {
            let item = page[elem_id];
            let heading = {
              id,
              page_number,
              title: item.textContent || item.title,
              level: get(item, 'properties.headingLevel', 1),
            };
            content_table.push(heading);
          }
        }
      }
      page_number++;
    }
    return content_table;
  };
  updateContentTable = id => {
    let item = {
      ...this.props.activeElement,
      content_table: this.extractReportHeadings(this.props.content_table),
    };
    this.props.updateItem(item.id, item);
  };
  render() {
    const { editor_prop, activeElement, tools, me } = this.props;
    const has_bg = get(this.props, 'objects.page_background.bg_url', null);
    return (
      <div className='TemplateNavbar'>
        <div className='header-details'>
          <div className='display-flex-between'>
            <div className='display-flex-start'>
              <i className='material-icons arrow-back-details ml-3' onClick={() => browserHistory.push(`/reports`)}>
                arrow_back
              </i>
              <div className='logo ml-3'>
                <img src={`${pentugramTextLogo}`} alt='Pentugram' height='39' width='39' />
              </div>
              <div className='ml-2'>
                <span className='logo-name'>{this.title}</span>
                <div className='template-editor-name'>{editor_prop.type == 'template' ? 'Template Editor' : 'Report Editor'}</div>
              </div>
            </div>
            <div className='right-details'>
              <div className='divider' />
              <Notifications />
              <div className='divider' />
              <Support />
              <div className='divider' />
              <Account />
            </div>
          </div>
          <TemplateToolbar
            tools={tools}
            user={this.props.user}
            loading={this.props.loading}
            onFieldAdd={this.props.addField}
            resetReport={this.props.resetReport}
            frequencies={this.props.frequencies}
            updatePeriod={this.refreshReport.bind(this)}
            onSaving={this.props.onSaving}
            saveReport={this.onSave.bind(this)}
            onUpdateReport={this.onUpdate.bind(this)}
            onAddPage={this.props.onAddPage}
            onRemovePage={this.props.onRemovePage}
            onChangePage={this.props.onChangePage}
            onMovePage={this.props.onMovePage}
            editor_prop={editor_prop}
            addCover={this.props.addCover}
            updateTools={this.props.updateTools}
            addSharedComponent={this.props.addSharedComponent}
            removeSharedComponent={this.props.removeSharedComponent}
            shareReport={this.shareReport.bind(this)}
            onViewReport={() => this.setState({ showReport: true })}
            customFields={this.props.customFields}
            togglePageOrientation={this.props.togglePageOrientation}
            addContentTable={this.addContentTable}
            hasBG={has_bg}
          />
        </div>
        {tools.showSlides ? (
          <NavigationSidebar
            report={this.props.report}
            shared={this.props.shared}
            tools={this.props.tools}
            editor_prop={this.props.editor_prop}
            onAddPage={this.props.onAddPage}
            onRemovePage={this.props.onRemovePage}
            onChangePage={this.props.onChangePage}
            onMovePage={this.props.onMovePage}
          />
        ) : null}
        <TemplateSidebar
          activeElement={activeElement}
          initialValues={activeElement}
          tools={this.props.tools}
          updateTools={this.props.updateTools}
          updateItem={this.props.updateItem}
          sharedComponent={this.props.sharedComponent}
          getPentugramItems={this.props.getPentugramItems}
          pentugramItems={this.props.pentugramItems}
          getPortfolioByType={this.props.getPortfolioByType}
          updatePropertiesColor={this.props.updatePropertiesColor}
          portfolioCustomFields={this.props.portfolioCustomFields}
          addToContentTable={this.props.addToContentTable}
          removeFromContentTable={this.props.removeFromContentTable}
          addSharedComponent={this.props.addSharedComponent}
          removeSharedComponent={this.props.removeSharedComponent}
          removeComponent={this.props.removeComponent}
          addComponent={this.props.addField}
          getPipelineCardData={this.props.getPipelineCardData}
          updateContentTable={this.updateContentTable}
          loadPipelines={this.props.loadPipelineTypeList}
          pipelines={this.props.pipelines}
          getScoreCardData={this.props.getScoreCardData}
        />
        <ViewReport
          show={this.state.showReport}
          onClose={() => this.setState({ showReport: false })}
          report={this.props.report}
          shared={this.props.shared}
          tools={this.props.tools}
          editor_prop={this.props.editor_prop}
        />
        <PdfGenerator
          ref={this.child}
          show={this.state.generateReport}
          onClose={() => this.setState({ generateReport: false })}
          report={this.props.report}
          shared={this.props.shared}
          tools={this.props.tools}
          shareReport={this.props.shareReport}
        />
        {/* {size(me) > 0 && (
          <Intercom appID={process.env.INTERCOM_APP_ID} hide_default_launcher={true} user_id={at(me, 'id')} email={at(me, 'email')} name={at(me, 'profile.fullname')} />
        )} */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    me: state.auth.user,
    report: state.reports.report,
    objects: state.reports.objects,
    loading: state.reports.loading,
    pipelines: state.pipeline.pipelines,
    shared: state.reports.shared,
    content_table: state.reports.content_table,
    activeElement: state.reports.activeElement,
    pentugramItems: state.reports.pentugramItems,
    tools: state.reports.tools,
    editor_prop: state.reports.editor_prop,
    template: state.reports.template,
    frequencies: state.metrics.frequencies,
    user: state.auth.user,
    customFields: state.settings.company.customFields,
    portfolioCustomFields: state.settings.company.portfolioCustomFields,
  };
}

export default connect(mapStateToProps, dispatch)(TemplateNavbar);
