import React, { Component } from 'react';
import { Field } from 'redux-form';
import CustomSwitch from 'SharedComponent/CustomSwitch';

import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';
import { Permissible } from '../../../../v2.0/shared/components';

import './CustomRow.scss';

export default class CustomRow extends Component {
  render() {
    const { props } = this;
    return (
      <div className='CustomRow'>
        <div className='row'>
          <div className='col-th-details col-md-8'>
            <Field name={`${props.item}.field_label`} component={LabelField} />
          </div>
          <div className='col-md-4'>
            <div className='row'>
              <div className='col-md-4'>
                <Field
                  disabled={!props.data.can_update}
                  name={`${props.item}.show`}
                  component={SwitchToggle}
                  format={value => (value ? true : false)}
                  parse={value => (value ? 1 : 0)}
                />
              </div>
              <div className='col-md-4'>
                <Field
                  name={`${props.item}.required`}
                  disabled={!props.data.show || !props.data.can_update}
                  component={CheckBoxField}
                  format={value => (value ? true : false)}
                  parse={value => (value ? 1 : 0)}
                />
              </div>
              <div className='col-md-4'>
                {!!props.data.default ? null : (
                  <div className='text-right'>
                    <Permissible can={PermissionTypeEnum.UpdateCustomField}>
                      <i className='material-icons row-icon-details' onClick={props.onEdit}>
                        create
                      </i>
                    </Permissible>
                    <Permissible can={PermissionTypeEnum.DeleteCustomField}>
                      <i className='material-icons row-icon-details ml-2' onClick={props.onRemove}>
                        delete_outline
                      </i>
                    </Permissible>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const CheckBoxField = props => {
  return (
    <div className='CheckBox'>
      <input {...props.input} disabled={props.disabled} type='checkbox' checked={props.input.value} />
    </div>
  );
};

const LabelField = props => {
  return <div>{props.input.value}</div>;
};

const SwitchToggle = props => {
  return (
    <div className='text-center'>
      <CustomSwitch onChange={props.input.onChange} checked={props.input.value} className='switch-log' disabled={!!props.disabled} />
    </div>
  );
};
