import React, { Component } from 'react';
import classNames from 'classnames';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Tooltip } from 'antd';
import ReactTooltip from 'react-tooltip';

import './DropdownList.scss';

export default class DropdownList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    const { props, state } = this;
    return (
      <div className='DropdownList'>
        <Dropdown isOpen={state.dropdownOpen} style={props.style ? { ...props.style } : undefined} toggle={e => this.toggle(e)}>
          <Tooltip title={props.tooltip}>
            <DropdownToggle tag='span' data-toggle='dropdown' aria-expanded={state.dropdownOpen} className={`${props.className}`}>
              <i className={classNames('material-icons dropdown-details', props.className)}>{props.icon}</i>
            </DropdownToggle>
          </Tooltip>
          <DropdownMenu right positionFixed={props.positionFixed} className='drop-down-position'>
            {props.items.map((item, index) => (
              <DropdownItem
                key={index + 1}
                className={classNames('display-flex-start cursor-pointer', item.disabled && 'disabled-item')}
                onClick={() => {
                  item.disabled ? {} : item.onClick();
                }}
                data-tip
                data-for={item.label}
              >
                <span className={item.icon} />
                <span className='ml-2'>{item.label}</span>
                {item.has_tooltip && (
                  <ReactTooltip id={item.label}>
                    <span>{item.tooltip}</span>
                  </ReactTooltip>
                )}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
