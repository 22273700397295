import React from 'react';
import { Radio, Tooltip } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { SelectItemData } from '../../interfaces/select-item';
import { Icon } from '../Icon/icon.component';
import { PopoverPlacementEnum } from '../../enums/popover-placement.enum';
import { GroupButtonSize } from '../../enums/group-button';
//@ts-ignore
import { Mixpanel } from 'App/app';

interface GroupButtonProps {
  // tslint:disable-next-line: no-any
  options: SelectItemData<any>[];
  selected: string;
  showIcons: boolean;
  showNames: boolean;
  placement: TooltipPlacement;
  className?: string;
  classNameText?: string;
  size?: GroupButtonSize;
  onChange(e: string): void;
}

export function GroupButton(props: GroupButtonProps): JSX.Element {
  const handleChange = (e: RadioChangeEvent) => {
    props.onChange(e.target.value);
    Mixpanel.track(`group button change ${e.target.value}`);
  };

  return (
    <Radio.Group size={props.size} value={props.selected} onChange={handleChange} className={props.className}>
      {props.options.map(item => (
        <Radio.Button key={item.value} value={item.value} disabled={item.disabled}>
          <Tooltip title={item.tooltip} placement={props.placement}>
            <div>
              {props.showIcons && <Icon iconData={item.icon} />}
              {props.showNames && <span className={props.classNameText}>{item.name}</span>}
            </div>
          </Tooltip>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}

GroupButton.defaultProps = {
  options: [],
  selected: null,
  showIcons: true,
  showNames: true,
  placement: PopoverPlacementEnum.Bottom,
  onClick: () => {},
  size: 'large',
};
