import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import { at } from 'Utils/objectUtils';

export function Permissible(userPermissions, requiredPermissions, oneperm, callbackFunction) {
  return (dispatch, getState) => {
    const userAttributes = at(getState(), 'auth.user.user_attributes');

    const permessions = [...userPermissions, ...userAttributes];

    const permissionsStatus = oneperm ? intersection(permessions, requiredPermissions).length : difference(requiredPermissions, permessions).length === 0;

    const runCallback = () => {
      if (callbackFunction) {
        return callbackFunction({
          permissionsStatus,
          permessions,
          requiredPermissions,
        });
      }
      return;
    };

    if (!permissionsStatus) {
      runCallback();
    }

    return !!permissionsStatus;
  };
}
