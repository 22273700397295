import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import DialogBox from 'SharedComponent/DialogBox';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';
import TextField from 'SharedComponent/TextField';
import SubmitButton from 'SharedComponent/SubmitButton';
import { renderFieldList } from './renderFieldList.js';

import { types } from 'Enums/customFields';
import * as validators from 'Utils/validators';

import './CustomiseFields.scss';

const selector = formValueSelector('customise-settings-fields-form');

class CustomiseFields extends Component {
  onClose() {
    this.props.onClose();
    this.props.reset();
  }

  onSubmit(values) {
    if (this.props.initialValues) {
      this.props.onUpdate(values);
    } else {
      this.props.onCreate(values);
    }
    this.onClose();
  }

  render() {
    const { props } = this;
    return (
      <div className='CustomiseSettingFields'>
        <DialogBox isVisible={props.show} onClickOut={this.onClose.bind(this)} onClose={this.onClose.bind(this)} withoutClose={true} className='small-dialog'>
          <form className='form-details' onSubmit={props.handleSubmit(this.onSubmit.bind(this))}>
            <div>
              <div className='text-title p-3'>{props.initialValues ? 'Update custom field' : 'Add custom field'}</div>
              <div className='modal-body'>
                <div className='pl-3 pr-3'>
                  <div className='panel-default mb-2'>
                    {props.showSection && (
                      <div className='row p-2'>
                        <div className='col-md-12 col-sm-12'>
                          <Field
                            component={FieldSelect}
                            label='Field section'
                            name={`section`}
                            placeholder='Field type'
                            required={true}
                            validate={[validators.required]}
                            labelClass=''
                            options={props.sections}
                            disabled={!!props.initialValues}
                          />
                        </div>
                      </div>
                    )}
                    <div className='p-2 row'>
                      <div className='col-md-12 col-sm-12'>
                        <Field
                          component={TextField}
                          label='Field name'
                          name={`field_label`}
                          placeholder='Field name'
                          required={true}
                          validate={[validators.required]}
                          labelClass=''
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='row p-2'>
                      <div className='col-md-12 col-sm-12'>
                        <Field
                          component={FieldSelect}
                          label='Field type'
                          name={`field_type`}
                          placeholder='Field type'
                          required={true}
                          validate={[validators.required]}
                          labelClass=''
                          options={types}
                        />
                      </div>
                    </div>
                    {props.field_type == 'list' && (
                      <div className=''>
                        <FieldArray className='m-3' name={`field_list`} component={renderFieldList} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='display-flex-end actions-block-details'>
              <div className='m-3'>
                <SubmitButton
                  label='Cancel'
                  icon='icon-close'
                  style={{
                    width: '150px',
                    color: '#53627c',
                    backgroundColor: '#fff',
                    border: '1px solid #e1e4e8',
                  }}
                  onClick={this.onClose.bind(this)}
                />
              </div>
              <div className='mr-3'>
                <Button type='submit' className='submit-btn-p'>
                  <i className='icon-check mr-2' />
                  Done
                </Button>
              </div>
            </div>
          </form>
        </DialogBox>
      </div>
    );
  }
}

CustomiseFields = reduxForm({
  form: 'customise-settings-fields-form',
  enableReinitialize: true,
})(CustomiseFields);

CustomiseFields = connect(state => ({
  field_type: selector(state, 'field_type'),
}))(CustomiseFields);

export default CustomiseFields;
