import React from 'react';
import SubmitButton from 'SharedComponent/SubmitButton';
import { Tabs, Icon, Tooltip, Modal, Input } from 'antd';

export default function FormSteps(props) {
  function editStepTitle(key, title) {
    let val = title;
    Modal.confirm({
      title: 'Rename Title',
      content: (
        <div className='p-3'>
          <Input
            onChange={e => {
              val = e.target.value;
            }}
            size='large'
            maxLength={25}
            placeholder='Step title'
            defaultValue={title}
          />
        </div>
      ),
      onOk() {
        props.renameStep(key, val || title);
      },
      okText: 'Save',
      cancelText: 'Cancel',
    });
  }

  const onChange = activeKey => {
    props.onChangeStep(activeKey);
  };

  const onEdit = (targetKey, action) => {
    if (action == 'remove') remove(targetKey);
    if (action == 'add') add(targetKey);
  };

  const add = () => {
    props.onAddStep();
  };

  const remove = targetKey => {
    props.onRemoveStep(targetKey);
  };

  return (
    <div className='FormSteps'>
      <div className='add-new-step'>
        <SubmitButton label='New Step' icon='icon-plus' style={{ width: '100px' }} onClick={add} />
      </div>
      <Tabs hideAdd onChange={onChange} activeKey={props.activeStep} type='editable-card' onEdit={onEdit}>
        {Object.keys(props.steps).map(key => (
          <Tabs.TabPane
            tab={
              <span>
                <Tooltip title='Rename step title'>
                  <Icon type='edit' onClick={() => editStepTitle(key, props.steps[key].title)} />
                </Tooltip>
                <span className='ml-2'>{props.steps[key].title}</span>
              </span>
            }
            key={key}
            closable={props.steps[key].closable}
          />
        ))}
      </Tabs>
    </div>
  );
}
