import { Modal } from 'antd';
import { browserHistory } from 'react-router';

export function goToBilling(cb) {
  Modal.info({
    title: 'You can not add users before entering your coupon code',
    okText: 'Go to Billing',
    onOk: () => browserHistory.push('/settings/billing'),
  });
}
