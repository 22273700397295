import { PutEffect, takeLatest } from 'redux-saga/effects';
import { all, put, select, SelectEffect } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import * as serviceAction from '../../../../../core/services/summary.service';
import * as summaryActions from './summary.action';
import { getActivityMeta, getFilesMeta, getNotesMeta, getEmailsMeta } from './summary.selectors';
import { LazyLoadResponse } from '../../../../../core/models/queries/lazy-response.model';

import { initializeMetaQueries, mapPaginationQueriesToMetaQueriesData, MetaQuery } from '../../../../../core/models/queries/meta-query.model';
import { ErrorResponseData } from '../../../../../core/models/http-params.type';

import { NotificationTypeEnum } from '../../../../../shared/enums/notification-type.enum';
import { notification } from '../../../../../shared/components';
import { ActivitySummaryData } from '../../../../../core/models/summary-log/activity/activity-summary-data';
import { NoteSummaryData } from '../../../../../core/models/summary-log/note/note-summary-data';
import { FileSummaryData } from '../../../../../core/models/summary-log/file/file-summary-data';
import { EmailSummaryData } from '../../../../../core/models/summary-log/email/email-summary-data';
import { closeModal, openModal, setLoading } from '../../../../../shared/components/Modal/state/modal.actions';
import { ModalIdEnum } from '../../../../../core/enums/entity-ids/modal-id.enum';

function* loadActivityListEffect(): Generator<Promise<LazyLoadResponse<ActivitySummaryData[]>> | PutEffect, void, LazyLoadResponse<ActivitySummaryData[]>> {
  try {
    yield put(summaryActions.loadingActivityList());
    const activity = yield serviceAction.getActivityList({ queryParams: mapPaginationQueriesToMetaQueriesData(initializeMetaQueries()) });
    yield put(summaryActions.loadActivitySuccess({ list: activity.results, meta: activity._meta, reset: true }));
  } catch (error) {
    yield put(summaryActions.loadActivityFail(error));
  }
}

function* loadNextPageActivityEffect(): Generator<
  SelectEffect | Promise<LazyLoadResponse<ActivitySummaryData[]>> | PutEffect,
  void,
  MetaQuery & LazyLoadResponse<ActivitySummaryData[]>
> {
  try {
    const queryNews = yield select(getActivityMeta);
    const nextPageQueryNews = { ...queryNews, currentPage: queryNews.currentPage + 1 };
    const activity = yield serviceAction.getActivityList({ queryParams: mapPaginationQueriesToMetaQueriesData(nextPageQueryNews) });
    yield put(summaryActions.loadActivitySuccess({ list: activity.results, meta: activity._meta, reset: false }));
  } catch (error) {
    yield put(summaryActions.loadActivityFail(error));
  }
}

function* loadNotesListEffect(): Generator<Promise<LazyLoadResponse<NoteSummaryData[]>> | PutEffect, void, LazyLoadResponse<NoteSummaryData[]>> {
  try {
    yield put(summaryActions.loadingNotes());
    const notes = yield serviceAction.getNoteList({ queryParams: mapPaginationQueriesToMetaQueriesData(initializeMetaQueries()) });
    yield put(summaryActions.loadNotesSuccess({ list: notes.results, meta: notes._meta, reset: true }));
  } catch (error) {
    yield put(summaryActions.loadNotesFail(error));
  }
}
function* GetNoteByIdEffect({ payload: { uuid } }: PayloadAction<{ uuid: string }>): Generator<Promise<NoteSummaryData> | PutEffect, void, NoteSummaryData> {
  try {
    yield put(openModal({ modalId: ModalIdEnum.NotificationNote }));

    yield put(setLoading({ loading: true }));
    const note = yield serviceAction.getNoteById(uuid);
    yield put(summaryActions.getNoteSuccess({ note }));
    yield put(setLoading({ loading: false }));
  } catch (error) {
    yield put(closeModal());
    yield put(summaryActions.loadNotesFail(error));
  }
}

function* loadNextPageNotesEffect(): Generator<SelectEffect | Promise<LazyLoadResponse<NoteSummaryData[]>> | PutEffect, void, MetaQuery & LazyLoadResponse<NoteSummaryData[]>> {
  try {
    const queryNews = yield select(getNotesMeta);
    const nextPageQueryNews = { ...queryNews, currentPage: queryNews.currentPage + 1 };
    const note = yield serviceAction.getNoteList({ queryParams: mapPaginationQueriesToMetaQueriesData(nextPageQueryNews) });
    yield put(summaryActions.loadNotesSuccess({ list: note.results, meta: note._meta, reset: false }));
  } catch (error) {
    yield put(summaryActions.loadNotesFail(error));
  }
}

function* loadEmailsListEffect(): Generator<Promise<LazyLoadResponse<EmailSummaryData[]>> | PutEffect, void, LazyLoadResponse<EmailSummaryData[]>> {
  try {
    yield put(summaryActions.loadingEmails());
    const emails = yield serviceAction.getEmailsList({ queryParams: mapPaginationQueriesToMetaQueriesData(initializeMetaQueries()) });
    yield put(summaryActions.loadEmailsSuccess({ list: emails.results, meta: emails._meta, reset: true }));
  } catch (error) {
    yield put(summaryActions.loadNotesFail(error));
  }
}

function* loadNextPageEmailsEffect(): Generator<SelectEffect | Promise<LazyLoadResponse<EmailSummaryData[]>> | PutEffect, void, MetaQuery & LazyLoadResponse<EmailSummaryData[]>> {
  try {
    const queryNews = yield select(getEmailsMeta);
    const nextPageQueryNews = { ...queryNews, currentPage: queryNews.currentPage + 1 };
    const emails = yield serviceAction.getEmailsList({ queryParams: mapPaginationQueriesToMetaQueriesData(nextPageQueryNews) });
    yield put(summaryActions.loadEmailsSuccess({ list: emails.results, meta: emails._meta, reset: false }));
  } catch (error) {
    yield put(summaryActions.loadEmailsFail(error));
  }
}

function* loadFilesListEffect(): Generator<Promise<LazyLoadResponse<FileSummaryData[]>> | PutEffect, void, LazyLoadResponse<FileSummaryData[]>> {
  try {
    yield put(summaryActions.loadingFiles());
    const files = yield serviceAction.getFilesList({ queryParams: mapPaginationQueriesToMetaQueriesData(initializeMetaQueries()) });
    yield put(summaryActions.loadFilesSuccess({ list: files.results, meta: files._meta, reset: true }));
  } catch (error) {
    yield put(summaryActions.loadEmailsFail(error));
  }
}

function* loadNextPageFilesEffect(): Generator<SelectEffect | Promise<LazyLoadResponse<FileSummaryData[]>> | PutEffect, void, MetaQuery & LazyLoadResponse<FileSummaryData[]>> {
  try {
    const query = yield select(getFilesMeta);
    const nextPageQueryNews = { ...query, currentPage: query.currentPage + 1 };
    const files = yield serviceAction.getFilesList({ queryParams: mapPaginationQueriesToMetaQueriesData(nextPageQueryNews) });
    yield put(summaryActions.loadFilesSuccess({ list: files.results, meta: files._meta, reset: false }));
  } catch (error) {
    yield put(summaryActions.loadFilesFail(error));
  }
}

function* failActionEffect({ payload }: PayloadAction<ErrorResponseData>): Generator {
  try {
    notification({ type: NotificationTypeEnum.Error, message: payload.message });
  } catch (error) {}
}

export function* watchSummary(): Generator {
  yield all([
    takeLatest(summaryActions.loadActivityList, loadActivityListEffect),
    takeLatest(summaryActions.loadNextPageActivity.type, loadNextPageActivityEffect),
    takeLatest(summaryActions.loadNotesList, loadNotesListEffect),
    takeLatest(summaryActions.loadNextPageNotes.type, loadNextPageNotesEffect),
    takeLatest(summaryActions.loadEmailsList, loadEmailsListEffect),
    takeLatest(summaryActions.loadNextPageEmails.type, loadNextPageEmailsEffect),
    takeLatest(summaryActions.loadFilesList, loadFilesListEffect),
    takeLatest(summaryActions.loadNextPageFiles.type, loadNextPageFilesEffect),
    takeLatest(summaryActions.getNoteById.type, GetNoteByIdEffect),
    takeLatest([summaryActions.loadActivityFail.type, summaryActions.loadNotesFail.type, summaryActions.loadEmailsFail.type, summaryActions.loadFilesFail.type], failActionEffect),
  ]);
}
