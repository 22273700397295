import React, { Component } from 'react';
import HeaderPipelines from '../HeaderPipelines';
import PipelineList from '../PipelineList';

import './Pipelines.scss';

class Pipelines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      selectedRows: [],
    };
  }

  selectedRows(row, isSelected) {
    if (isSelected) {
      this.setState({
        isSelected: true,
        selectedRows: [...this.state.selectedRows, row],
      });
    } else {
      let array = this.state.selectedRows.filter(it => it.pipeline_id !== row.pipeline_id);
      this.setState({
        isSelected: array.length ? true : false,
        selectedRows: array,
      });
    }
  }

  selectAll(isSelected, rows) {
    this.setState({ isSelected });
    if (isSelected) {
      this.setState({ selectedRows: rows });
    } else {
      this.setState({ selectedRows: [] });
    }
  }

  updateSelectedLists(array) {
    this.setState({
      isSelected: false,
      selectedRows: [],
    });
  }

  render() {
    return (
      <div className='Pipelines'>
        <div>
          <HeaderPipelines
            isPortfolio={this.props.isPortfolio}
            isSelected={this.state.isSelected}
            rows={this.state.selectedRows}
            updateSelectedLists={rows => this.updateSelectedLists(rows)}
          />
        </div>
        <div>
          <PipelineList
            isPortfolio={this.props.isPortfolio}
            onSelectAll={(isSelected, rows) => this.selectAll(isSelected, rows)}
            onRowSelect={(row, isSelected, e) => this.selectedRows(row, isSelected)}
            updateSelectedList={row => this.selectedRows(row, false)}
          />
        </div>
      </div>
    );
  }
}

export default Pipelines;
