import { VC_PACKS } from 'Enums/payment';
import { LOADING_PAYMENT_HISTORY, LOADED_PAYMENT_HISTORY, LOADED_PAYMENT_PACKS, SET_PADDLE } from 'ReduxActions/billingActions';

const initialState = {
  all: [],
  paid: [],
  rejected: [],
  loading: false,
  count: 0,
  packs: [],
};

function parsePacks(plans) {
  return plans.map(plan => ({ ...plan, ...VC_PACKS[plan.id] }));
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PADDLE:
      return {
        ...state,
        paddle: action.payload,
      };

    case LOADING_PAYMENT_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case LOADED_PAYMENT_HISTORY:
      return {
        ...state,
        paid: action.payload.paid,
        rejected: action.payload.rejected,
        loading: false,
      };

    case LOADED_PAYMENT_PACKS:
      return {
        ...state,
        packs: parsePacks(action.payload),
      };
  }

  return state;
}
