import React from 'react';
import { Input } from 'antd';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { Error } from '../Error/error.component';

import './text-area-field.component.scss';

const { TextArea } = Input;

interface TextAreaFieldProps {
  meta: WrappedFieldMetaProps;
  input: WrappedFieldInputProps;
  placeholder?: string;
}

export function TextAreaField(props: TextAreaFieldProps): JSX.Element {
  return (
    <div className='TextAreaField'>
      <Error touched={props.meta && props.meta.touched} error={props.meta && props.meta.error} warning={props.meta && props.meta.warning} />
      <TextArea {...props.input} autoSize={{ minRows: 3, maxRows: 5 }} placeholder={props.placeholder} />
    </div>
  );
}
