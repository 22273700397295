import { ApiUrlsEnum } from '../helpers/api-url';
import { lazyLoad } from './helpers/base-http';
import { apiUrlMatcher } from '../helpers/api-url-matcher';

import { PipelineLightDTO } from '../generated/PipelineLightDTO';
import { LazyLoadResponse } from '../models/queries/lazy-response.model';

import { SelectItemData } from '../../shared/interfaces/select-item';
import { HttpParamsType } from '../models/queries/http-params.type';
import { PaginationQuery } from '../models/queries/pagination-query.model';
import { PipelineAllLightDTO } from '../generated/model/models';
import { PipelineLightData } from '../models/pipeline/pipeline-light-data';
import { AllPipelineLightData } from '../models/pipeline/all-pipeline-light-data';

export function pipelineListLight(params: HttpParamsType<PaginationQuery>): Promise<LazyLoadResponse<SelectItemData[]>> {
  return lazyLoad<PipelineLightDTO[]>(apiUrlMatcher(ApiUrlsEnum.Pipelines), params).then(data => ({
    ...data,
    results: data.results.map(res => PipelineLightData.mapToApiValue(res)),
  }));
}

export function pipelineAllListLight(params: HttpParamsType<PaginationQuery>): Promise<LazyLoadResponse<SelectItemData[]>> {
  return lazyLoad<PipelineAllLightDTO[]>(apiUrlMatcher(ApiUrlsEnum.Pipelines), params).then(data => ({
    ...data,
    results: data.results.map(res => AllPipelineLightData.mapToSelectedItem(res)),
  }));
}
