import moment from 'moment';
// import Intercom from 'react-intercom';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Account from '../Account';
import ExtraOptions from '../ExtraOptions';
import Support from '../Support';
import Notifications from '../Notifications';
import SearchBar from '../SearchBar';
import { Link } from 'react-router';
import { at } from 'Utils/objectUtils';
import size from 'lodash/size';

import CustomTag from 'SharedComponent/CustomTag';
import { PermissibleRender, Permissible } from 'SharedComponent/permissions';
import { VC_ACCELERATOR, OWNER, ADMIN, USER, PAYMENT_STATUS, BILLABLE_STATUS } from 'Enums/permissions';

import './Header.scss';
import { Mixpanel } from 'App/app';
class Header extends Component {
  trial() {
    if (this.props.Permissible([], [PAYMENT_STATUS.STATUS_EXPIRED]))
      return (
        <span>
          Your trial has ended. <strong style={{ color: '#4a4a4a' }}>UPGRADE NOW</strong>
        </span>
      );
    return (
      <span>
        You have <strong>{moment(at(this.props.me, 'subscription.trial_expire_at') * 1000).toNow(true)}</strong> left in your trial.{' '}
        <strong style={{ color: '#4a4a4a' }}>UPGRADE NOW</strong>
      </span>
    );
  }

  couponMsg() {
    return (
      <span>
        You need to Enter your coupon code before <strong>{moment(at(this.props.me, 'subscription.trial_expire_at') * 1000).toNow(true)}.</strong>
      </span>
    );
  }

  render() {
    const { me } = this.props;

    const user = {
      user_id: at(me, 'id'),
      email: at(me, 'email'),
      name: at(me, 'profile.fullname'),
    };

    return (
      <div className={classNames('Header non-printable', this.props.collapsed ? 'collapsed' : null)}>
        <SearchBar />
        <div className='toggle-details' />
        <div className='right-details'>
          <PermissibleRender userPermissions={[]} requiredPermissions={[[PAYMENT_STATUS.STATUS_TRIALING], [VC_ACCELERATOR], [BILLABLE_STATUS.BILLABLE_COMPANY], [OWNER]]}>
            <div className='display-flex-center'>
              <Link to='/settings/billing' onClick={() => Mixpanel.track('Click on trial upgrade')}>
                <CustomTag color='gold' label={this.trial()} />
              </Link>
            </div>
          </PermissibleRender>
          <PermissibleRender userPermissions={[]} requiredPermissions={[[PAYMENT_STATUS.STATUS_TRIALING], [VC_ACCELERATOR], [BILLABLE_STATUS.NOT_BILLABLE_COMPANY], [OWNER]]}>
            <div className='display-flex-center'>
              <Link to='/settings/billing'>
                <CustomTag color='gold' label={this.couponMsg()} />
              </Link>
            </div>
          </PermissibleRender>
          <PermissibleRender
            userPermissions={[]}
            requiredPermissions={[[PAYMENT_STATUS.STATUS_PENDING, PAYMENT_STATUS.STATUS_NOT_EXPIRED, PAYMENT_STATUS.STATUS_PAST_DUE, PAYMENT_STATUS.STATUS_ACTIVE]]}
          >
            <div className='divider' />
            <ExtraOptions />
            <Notifications />
          </PermissibleRender>
          <div className='divider' />
          <Support />
          <div className='divider' />
          <Account />
        </div>
        {/* {size(me) > 0 && <Intercom appID={process.env.INTERCOM_APP_ID} hide_default_launcher={true} {...user} />} */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collapsed: state.header.collapsed,
    me: state.auth.user,
  };
}

export default connect(mapStateToProps, { Permissible })(Header);
