import { TabItemData } from '../../../shared/interfaces/tab-item';
import { defaultIcon, IconData, IconPrefixEnum } from '../../../shared/interfaces/icon-data';
import { EventypeDTO } from '../../generated/model/eventypeDTO';
import { IMap } from '../../utils/map-utils';

export enum TaskTypeEnum {
  Call = 'call',
  Meeting = 'meeting',
  Conference = 'conference',
  Task = 'task',
  Email = 'email',
}

export namespace TaskTypeEnum {
  export const displayedTypes: TaskTypeEnum[] = [TaskTypeEnum.Email, TaskTypeEnum.Task, TaskTypeEnum.Call, TaskTypeEnum.Conference];
  export const allowedZoomTypes: TaskTypeEnum[] = [TaskTypeEnum.Call, TaskTypeEnum.Conference];

  export const convertFromApiValue: IMap<EventypeDTO.EventypeIdEnum, TaskTypeEnum> = new IMap<EventypeDTO.EventypeIdEnum, TaskTypeEnum>(
    [
      [EventypeDTO.EventypeIdEnum.NUMBER_1, TaskTypeEnum.Call],
      [EventypeDTO.EventypeIdEnum.NUMBER_2, TaskTypeEnum.Meeting],
      [EventypeDTO.EventypeIdEnum.NUMBER_3, TaskTypeEnum.Conference],
      [EventypeDTO.EventypeIdEnum.NUMBER_4, TaskTypeEnum.Task],
      [EventypeDTO.EventypeIdEnum.NUMBER_5, TaskTypeEnum.Email],
    ],
    undefined,
  );

  export const convertToApiValue: IMap<TaskTypeEnum, EventypeDTO.EventypeIdEnum> = new IMap<TaskTypeEnum, EventypeDTO.EventypeIdEnum>(
    [
      [TaskTypeEnum.Call, EventypeDTO.EventypeIdEnum.NUMBER_1],
      [TaskTypeEnum.Meeting, EventypeDTO.EventypeIdEnum.NUMBER_2],
      [TaskTypeEnum.Conference, EventypeDTO.EventypeIdEnum.NUMBER_3],
      [TaskTypeEnum.Task, EventypeDTO.EventypeIdEnum.NUMBER_4],
      [TaskTypeEnum.Email, EventypeDTO.EventypeIdEnum.NUMBER_5],
    ],
    undefined,
  );
  export const toString: IMap<TaskTypeEnum, string> = new IMap<TaskTypeEnum, string>(
    [
      [TaskTypeEnum.Call, 'Call'],
      [TaskTypeEnum.Meeting, 'Meeting'],
      [TaskTypeEnum.Conference, 'Conference'],
      [TaskTypeEnum.Task, 'Task'],
      [TaskTypeEnum.Email, 'Email'],
    ],
    undefined,
  );
  export const getIcon: IMap<TaskTypeEnum, IconData> = new IMap<TaskTypeEnum, IconData>(
    [
      [TaskTypeEnum.Call, { name: 'ri-phone-line', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_125' }],
      [TaskTypeEnum.Meeting, { name: 'ri-customer-service-line', prefix: IconPrefixEnum.Remix }],
      [TaskTypeEnum.Conference, { name: 'ri-slideshow-line', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_125' }],
      [TaskTypeEnum.Task, { name: 'ri-task-line', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_125' }],
      [TaskTypeEnum.Email, { name: 'ri-mail-line', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_125' }],
    ],
    defaultIcon,
  );
  export const getColor: IMap<TaskTypeEnum, string> = new IMap<TaskTypeEnum, string>(
    [
      [TaskTypeEnum.Call, '#9adbad'],
      [TaskTypeEnum.Meeting, '#ffc266'],
      [TaskTypeEnum.Conference, '#00a8ff'],
      [TaskTypeEnum.Task, '#ffc266'],
      [TaskTypeEnum.Email, '#ff7b65'],
    ],
    '#b1afdb',
  );

  export function mapToTabItemData(type: TaskTypeEnum): TabItemData<TaskTypeEnum> {
    return {
      value: type,
      name: toString.getValue(type),
      icon: getIcon.getValue(type),
    };
  }

  export const taskTypes: TabItemData<TaskTypeEnum>[] = displayedTypes.map(item => mapToTabItemData(item));
}
