import React from 'react';
import Draggable from '../Draggable/Draggable';
import ItemCard from '../../ItemCard';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

type Props = {
  context?: {
    properties: {
      alignment?: string,
      color?: {
        color: string | null,
        backgroundColor: string | null,
      },
    },
    content: string,
  },
  id: string,
};

const computedStyle = properties => ({
  textAlign: properties ? properties.alignment : null,
  color: properties && properties.color ? properties.color.color : null,
  backgroundColor: properties && properties.color ? properties.color.backgroundColor : null,
});

const Default = ({
  id,
  context,
  isNavigationSideBar,
  zoom,
  item,
  dropElement,
  mode,
  editor_height,
  editor_width,
  handleShortcut,
  removeSharedComponent,
  onContextMenu,
  removeComponent,
  tools,
  editorPageNumber,
  reloadMetrics,
}: Props) => {
  const { content, properties } = context;
  let activeElementId = useSelector(state => get(state, 'reports.activeElement.id', null));
  let isActive = activeElementId === id;
  return (
    <Draggable id={id} properties={properties} style={computedStyle(properties)} mode={mode} item={item} editor_height={editor_height} editor_width={editor_width} zoom={zoom}>
      <ItemCard
        removeSharedComponent={removeSharedComponent}
        data={item}
        mode={mode}
        isNavigationSideBar={isNavigationSideBar}
        activeElementId={activeElementId}
        id={id}
        tools={tools}
        isActive={isActive}
        reloadMetrics={reloadMetrics}
        removeComponent={removeComponent}
        zoom={zoom}
        onContextMenu={onContextMenu}
        dropElement={dropElement}
        handleShortcut={handleShortcut}
        editorPageNumber={editorPageNumber}
      />
    </Draggable>
  );
};

Default.defaultProps = {
  context: {
    properties: {
      alignment: 'left',
      color: {
        color: '#000',
        backgroundColor: '#fff',
      },
    },
  },
};

export default Default;
