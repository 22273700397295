import React, { Component } from 'react';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './UsersDialog.scss';

class UsersDialog extends Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.onDismissDialog();
  }

  handleAction() {
    if (this.props.type === 'delete') {
      this.props.deleteUsers(this.props.users);
      gaEvent(appCategories.settings_users, appActions(`${appTypes.mass}_${appTypes.delete}_${appTypes.modal}_${appTypes.delete}`, appEvents.clicked).button);
    } else {
      this.props.resendInvitations(this.props.users);
      gaEvent(appCategories.settings_users, appActions(`${appTypes.mass}_${appTypes.resend}_${appTypes.modal}_${appTypes.resend}`, appEvents.clicked).button);
    }
    this.closeModal();
  }

  onCloseDialogBox() {
    this.props.type === 'delete'
      ? gaEvent(appCategories.settings_users, appActions(`${appTypes.mass}_${appTypes.delete}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon)
      : gaEvent(appCategories.settings_users, appActions(`${appTypes.mass}_${appTypes.resend}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
    this.closeModal();
  }

  onClickCancelBtn() {
    this.props.type === 'delete'
      ? gaEvent(appCategories.settings_users, appActions(`${appTypes.mass}_${appTypes.delete}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button)
      : gaEvent(appCategories.settings_users, appActions(`${appTypes.mass}_${appTypes.resend}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    this.closeModal();
  }

  render() {
    if (!this.props.showDialog) return null;
    return (
      <div className='UsersDialog'>
        <DialogBox
          isVisible
          onClickOut={this.closeModal}
          onClose={() => this.onCloseDialogBox()}
          className='small-dalog'
          title={this.props.type === 'delete' ? 'Delete Users' : 'Resend Invitations'}
        >
          <div className='p-3'>
            <span className='font-size-16 font-weight-500'>
              {this.props.type === 'delete' ? 'Are you sure you want to delete users:' : 'Are you sure you want to resend the invitation for:'}
            </span>
          </div>
          <div className='p-3'>
            <ul>
              {this.props.users.map(user => {
                return <li key={user.id}>{user.name}</li>;
              })}
            </ul>
          </div>
          <div className='display-flex-end'>
            <div className='m-3'>
              <SubmitButton
                label='Cancel'
                icon='icon-close'
                style={{
                  width: '150px',
                  color: '#53627c',
                  backgroundColor: '#fff',
                  border: '1px solid #e1e4e8',
                }}
                onClick={() => this.onClickCancelBtn()}
              />
            </div>
            <div className='m-3'>
              <SubmitButton
                label={this.props.type === 'delete' ? 'Delete' : 'Resend Invitation'}
                icon={this.props.type === 'delete' ? 'icon-trash' : 'icon-paper-plane'}
                style={{
                  width: '150px',
                  backgroundColor: this.props.type === 'delete' ? '#ff8181' : '#ffbc34',
                }}
                onClick={() => this.handleAction()}
              />
            </div>
          </div>
        </DialogBox>
      </div>
    );
  }
}

export default UsersDialog;
