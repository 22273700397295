import remove from 'lodash/remove';
import {
  FIELD_ADD,
  FIELD_INSERT,
  FIELD_REMOVE,
  SAVE_DATA,
  SAVE,
  REMOVE_ALL_FIELD,
  FORMS_LOADED,
  FORM_DUPLICATED,
  FORMS_LOADING,
  SWITCH_DEAL_ELEMENT,
  FORM_LOADED,
  FORM_INPROGRESS_LOADED,
  RESET_FORM,
  FORM_DELETED,
  FORM_LOADING_ERROR,
  FORM_SAVING_SUBMITTING_DATA,
  QUESTIONS_FORM_LOADED,
  REORDER_ELEMENT,
  CHECK_DEAL_NAME_INFORM,
  FORM_ADDED_STEP,
  FORM_CHANGE_STEP,
  FORM_REMOVE_STEP,
  FORM_RENAME_STEP,
  UPDATE_CARD,
} from 'ReduxActions/formActions';
import ID from 'Utils/UUID';
import get from 'lodash/get';

const initialForm = [
  {
    id: ID.uuid(),
    content: 'Untitled form',
    element: 'Header',
    text: 'Form title',
    fixed: true,
    glued: true,
    showItem: true,
    title: 'Untitled form',
    is_question: false,
    hover_text: 'Add a title in the form',
  },
  {
    id: ID.uuid(),
    element: 'Deal',
    text: 'Deal Card',
    content: 'Deal Card',
    showItem: false,
    fixed: true,
    logo: false,
    is_question: false,
  },
  {
    id: ID.uuid(),
    element: 'Contact',
    text: 'Primary Contact',
    content: 'Contact Card',
    showItem: false,
    fixed: true,
    logo: false,
    is_question: false,
    primary: true,
    first_name: true,
  },
];

const _initialForm = JSON.parse(JSON.stringify(initialForm));

const initialSteps = {
  online_form_first_step: {
    title: 'Step 1',
    closable: false,
    content: initialForm,
  },
};

const _initialSteps = JSON.parse(JSON.stringify(initialSteps));

const initialState = {
  all: [],
  loading: false,
  isSaving: false,
  editing: null,
  data: [], //initialForm,
  error: null,
  questions: null,
  questionForm: [],
  savedValues: null,
  form_key: null,
  available_deal: undefined,
  steps: initialSteps,
  activeStep: 'online_form_first_step',
};

function switchDealElement(data, field, value) {
  return data.map(it => {
    if (it.element == 'Deal') {
      let copy = Object.assign({}, it);
      copy[field] = value;
      return copy;
    }
    return it;
  });
}

function removeStep(steps, activeKey, targetKey) {
  const copy = Object.assign({}, steps);
  delete copy[targetKey];
  return {
    steps: copy,
    activeStep: Object.keys(copy)[0],
  };
}

function addFieldStepForm(steps, field, key) {
  const newObj = { ...steps };
  newObj[key].content = [...newObj[key].content, field];
  return newObj;
}

function updateFieldStepForm(steps, payload, activeStep) {
  const newForm = { ...steps };
  const newContent = newForm[activeStep].content.map(element => {
    if (element.id == payload.element.id) {
      return {
        ...element,
        [payload.field]: payload.value,
      };
    } else return element;
  });

  newForm[activeStep].content = newContent;

  return newForm;
}

function removeFieldStepForm(steps, id, key) {
  const newObj = { ...steps };
  const forms = newObj[key].content;

  remove(forms, { id });
  newObj[key].content = forms;

  return newObj;
}

function insertFieldsInStep(steps, key, list) {
  const newObj = { ...steps };
  newObj[key].content = list;
  return newObj;
}

export default function form(state = initialState, action) {
  switch (action.type) {
    case FORM_SAVING_SUBMITTING_DATA:
      return {
        ...state,
        isSaving: action.payload,
      };

    case FORMS_LOADING:
      return {
        ...state,
        loading: action.payload,
        questions: null,
        questionForm: [],
      };

    case FORMS_LOADED:
      return {
        ...state,
        all: action.payload,
      };
    case FORM_DUPLICATED:
      return {
        ...state,
        all: [action.payload, ...state.all],
      };

    case FORM_LOADED:
      return {
        ...state,
        editing: action.payload,
        steps: action.payload.data,
        form_loaded: true,
        activeStep: Object.keys(action.payload.data)[0],
        form_key: action.payload.form_key,
      };

    case FORM_INPROGRESS_LOADED:
      return {
        ...state,
        editing: action.payload,
        steps: action.payload.data,
        savedValues: action.savedValues,
        form_key: action.payload.form_key,
      };

    case FIELD_ADD:
      return {
        ...state,
        steps: addFieldStepForm(state.steps, action.payload, state.activeStep),
      };

    case UPDATE_CARD:
      return {
        ...state,
        steps: updateFieldStepForm(state.steps, action.payload, state.activeStep),
      };

    case RESET_FORM:
      return {
        ...state,
        data: _initialForm,
        steps: { ..._initialSteps, content: _initialForm },
        activeStep: Object.keys(_initialSteps)[0],
        editing: null,
        error: null,
      };

    case REMOVE_ALL_FIELD:
      return {
        ...initialState,
      };

    case FORM_DELETED:
      const list = [...state.all];
      remove(list, { form_id: action.payload.form_id });
      return {
        ...state,
        all: list,
      };

    case FORM_LOADING_ERROR:
      return {
        ...state,
        error: action.payload,
        steps: {},
        loading: false,
      };

    case FIELD_INSERT:
      return {
        ...state,
      };

    case FIELD_REMOVE:
      return {
        ...state,
        steps: removeFieldStepForm(state.steps, action.payload.id, state.activeStep),
      };

    case REORDER_ELEMENT:
      let newLists = [...state.steps[state.activeStep].content];
      newLists[action.to] = newLists.splice(action.from, 1, newLists[action.to])[0];
      return {
        ...state,
        steps: insertFieldsInStep(state.steps, state.activeStep, newLists),
      };

    case SWITCH_DEAL_ELEMENT:
      return {
        ...state,
        steps: insertFieldsInStep(state.steps, state.activeStep, switchDealElement(state.steps[state.activeStep].content, action.field, action.value)),
      };

    case QUESTIONS_FORM_LOADED:
      return {
        ...state,
        questionForm: action.payload.Form ? action.payload.Form.data : [],
        questions: action.payload.values,
        editing: {
          language: get(action.payload, 'Form.language', 'en'),
        },
      };

    case SAVE_DATA:
      return {
        ...state,
        // data: action.payload,
      };

    case SAVE:
      return {
        ...state,
      };

    case CHECK_DEAL_NAME_INFORM:
      return {
        ...state,
        available_deal: action.payload,
      };

    case FORM_ADDED_STEP:
      const newActiveStep = ID.uuid();
      return {
        ...state,
        steps: { ...state.steps, [newActiveStep]: { title: 'New Step', content: [] } },
        activeStep: newActiveStep,
      };

    case FORM_CHANGE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };

    case FORM_RENAME_STEP:
      const rename = { ...state.steps };
      rename[action.stepKey].title = action.title;
      return {
        ...state,
        steps: rename,
      };

    case FORM_REMOVE_STEP:
      return {
        ...state,
        ...removeStep(state.steps, state.activeStep, action.payload),
      };

    default:
      return state;
  }
}
