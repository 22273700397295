import { SelectItemData } from '../../../shared/interfaces/select-item';
import { TaskCalendarRequestDTO } from '../../generated/model/taskCalendarRequestDTO';
import { IMap } from '../../utils/map-utils';

export enum CalenderTasksModeEnum {
  Month = 'month',
  Year = 'year',
}

export namespace CalenderTasksModeEnum {
  export const displayedValues: CalenderTasksModeEnum[] = [CalenderTasksModeEnum.Month, CalenderTasksModeEnum.Year];

  export const convertToApiValue: IMap<CalenderTasksModeEnum, TaskCalendarRequestDTO.ModeEnum> = new IMap<CalenderTasksModeEnum, TaskCalendarRequestDTO.ModeEnum>(
    [
      [CalenderTasksModeEnum.Year, TaskCalendarRequestDTO.ModeEnum.Year],
      [CalenderTasksModeEnum.Month, TaskCalendarRequestDTO.ModeEnum.Month],
    ],
    undefined,
  );

  export const getTooltipMap: IMap<CalenderTasksModeEnum, string> = new IMap<CalenderTasksModeEnum, string>([
    [CalenderTasksModeEnum.Year, 'Year view'],
    [CalenderTasksModeEnum.Month, 'Month view'],
  ]);
  export const toString: IMap<CalenderTasksModeEnum, string> = new IMap<CalenderTasksModeEnum, string>([
    [CalenderTasksModeEnum.Year, 'Year'],
    [CalenderTasksModeEnum.Month, 'Month'],
  ]);
  export function mapToSelectItemData(type: CalenderTasksModeEnum): SelectItemData<CalenderTasksModeEnum> {
    return {
      value: type,
      name: CalenderTasksModeEnum.toString.getValue(type),
      tooltip: CalenderTasksModeEnum.getTooltipMap.getValue(type),
    };
  }

  export const selectItems = displayedValues.map(value => mapToSelectItemData(value));
}
