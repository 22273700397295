import React from 'react';
import { Avatar as AntdAvatar } from 'antd';

import { Icon, Tooltip } from '..';
import { AvatarData, AvatarShapeEnum } from '../../interfaces/avatar-data';

export function Avatar(props: AvatarData): JSX.Element {
  return (
    <Tooltip title={props.tooltip}>
      <AntdAvatar shape={props.shape} src={props.src} icon={props.icon && <Icon iconData={props.icon} />} style={props.style} size={props.size} />
    </Tooltip>
  );
}

Avatar.defaultProps = {
  shape: AvatarShapeEnum.Circle,
};
