import api from './api';
export const LOADING_NOTIFICATIONS = 'notifications:loading';
export const LOADED_NOTIFICATIONS = 'notifications:loaded';
export const LOADED_ALL_NOTIFICATIONS = 'notifications:all:loaded';
export const CLEAR_NOTIFICATIONS = 'notifications:clear';
export const CLEAR_ALERT = 'notifications:alert:clear';
export const DISPATCH_ALERT = 'notifications:alert:display';
export const NOTIFICATION_CREATED = 'notifications:pusher:created';

function showAlert(data) {
  return { type: DISPATCH_ALERT, payload: data };
}
function hideAlert() {
  return { type: CLEAR_ALERT };
}

export function notificationCreated(notification) {
  return dispatch => {
    dispatch({ type: NOTIFICATION_CREATED, payload: notification });
  };
}

export function loadNotifications() {
  return dispatch => {
    dispatch({ type: LOADING_NOTIFICATIONS });
    api
      .get('/me/notifications')
      .then(({ data }) => {
        dispatch({
          type: LOADED_NOTIFICATIONS,
          payload: data.reverse(),
        });
      })
      .catch(error => {});
  };
}
export function loadAllNotifications(page, size, deal, user, sort = '-notif_id') {
  let filter = `&per-page=${size}`;
  if (sort) filter += `&sort=${sort}`;
  if (deal) filter += `&filter[deal_id]=${deal}`;
  if (user) filter += `&filter[user_id]=${user}`;
  return dispatch => {
    dispatch({ type: LOADING_NOTIFICATIONS, payload: true });
    api
      .get(`/notifications?page=${page}${filter}`)
      .then(({ data }) => {
        dispatch({
          type: LOADED_ALL_NOTIFICATIONS,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({ type: LOADED_NOTIFICATIONS, payload: false });
      });
  };
}
export function clearNotifications() {
  return dispatch => {
    dispatch({ type: CLEAR_NOTIFICATIONS });
    api
      .get('/me/readnotifications')
      .then(({ data }) => {})
      .catch(error => {});
  };
}

export function dispatchAlert(dispatch, data) {
  dispatch(showAlert(data));
  setTimeout(() => {
    dispatch(hideAlert());
  }, 4000);
}

export function dispatchFixedAlert(dispatch, data) {
  dispatch(showAlert(data));
}

export function clearAlert() {
  return dispatch => {
    dispatch(hideAlert());
  };
}
