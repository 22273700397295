import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

import './ItemColorPicker.scss';

export default class ItemColorPicker extends Component {
  handleChangeComplete = color => {
    this.props.onColorChange(color.hex);
  };

  render() {
    const { background } = this.props;
    return (
      <div className='ItemColorPicker'>
        <SketchPicker width='94%' color={background} onChangeComplete={this.handleChangeComplete} />
      </div>
    );
  }
}
