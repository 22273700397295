import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadEmailTemplates, removetemplate, editingTemplate } from 'ReduxActions/settingActions';
import ConfirmationDialog from '../ConfirmationDialog';
import EmailTemplatesTable from '../EmailTemplatesTable/EmailTemplatesTable.js';

import './EmailTemplatesList.scss';

const actions = {
  loadEmailTemplates,
  removetemplate,
  editingTemplate,
};

class EmailTemplatesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      template: null,
    };

    this.updateTableSort = this.updateTableSort.bind(this);
    this.reloadDataList = this.reloadDataList.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
  }

  componentWillMount() {
    this.reloadDataList();
  }

  hideDialog() {
    this.setState({ template: null, showDialog: false });
  }

  confirmDeletion(template) {
    this.setState({ template, showDialog: true });
  }

  show(template) {}

  parseEmailTemplates(templates) {
    return templates.map(template => {
      return {
        ...template,
        id: template.emailtpl_id,
        name: template.name,
        created_by: (template.owner && template.owner.profile && template.owner.profile.fullname) || 'N/A',
        created_at: moment(template.created_at && template.created_at * 1000).format('LL'),
      };
    });
  }

  render() {
    const { props } = this;
    const templates = this.parseEmailTemplates(props.emailTemplates);
    return (
      <div className='EmailTemplatesList'>
        <EmailTemplatesTable
          isLoading={props.loading}
          items={templates}
          onSortChange={this.updateTableSort}
          onEdit={this.props.editingTemplate}
          onDelete={template => this.confirmDeletion(template)}
          show={template => this.show(template)}
          onSelectAll={props.onSelectAll}
          onRowSelect={props.onRowSelect}
        />
        <ConfirmationDialog showDetails={this.state.showDialog} onDismiss={this.hideDialog} template={this.state.template} remove={this.props.removetemplate} />
      </div>
    );
  }

  reloadDataList() {
    this.props.loadEmailTemplates();
  }

  updateTableSort(sortName, sortOrder) {
    this.setState(
      {
        sorting: { key: sortName, order: sortOrder.toUpperCase() },
      },
      () => {
        this.reloadDataList();
      },
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.settings.emailTemplates.loading,
    emailTemplates: state.settings.emailTemplates.all,
  };
}

export default connect(mapStateToProps, actions)(withRouter(EmailTemplatesList));
