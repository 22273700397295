import { browserHistory } from 'react-router';
import { reset } from 'redux-form';
import api from './api';
import get from 'lodash/get';
import { dispatchAlert, dispatchFixedAlert } from './notificationActions';
import { Notifier } from 'SharedComponent/Notifier';

import { CUSTOM_FIELDS_LOADED } from 'ReduxActions/settingActions';
import { handleErrors } from './authActions';
import { Mixpanel } from 'App/app';

export const FIELD_ADD = 'form:builder:FIELD_ADD';
export const FIELD_REMOVE = 'form:builder:FIELD_REMOVE';
export const FIELD_UPDATE = 'form:builder:FIELD_UPDATE';
export const UPDATE_CARD = 'form:builder:UPDATE_CARD';
export const SAVE_DATA = 'form:builder:SAVE_DATA';
export const SAVE = 'form:builder:SAVE';
export const REMOVE_ALL_FIELD = 'form:builder:REMOVE_ALL_FIELD';
export const RESET_FORM = 'form:builder:RESET_FORM';
export const FORMS_LOADING = 'form:builder:FORMS_LOADING';
export const FORM_SAVING_SUBMITTING_DATA = 'form:builder:FORM_SAVING_SUBMITTING_DATA';
export const FORM_LOADING_ERROR = 'form:builder:FORM_LOADING_ERROR';
export const FORMS_LOADED = 'form:builder:FORMS_LOADED';
export const FORM_LOADED = 'form:builder:FORM_LOADED';
export const FORM_INPROGRESS_LOADED = 'form:builder:FORM_INPROGRESS_LOADED';
export const SWITCH_DEAL_ELEMENT = 'form:build:SWITCH_DEAL_ELEMENT';
export const FORM_DELETED = 'form:builder:FORM_DELETED';
export const FORM_DUPLICATED = 'form:builder:FORM_DUPLICATED';
export const QUESTIONS_FORM_LOADED = 'form:builder:QUESTIONS_FORM_LOADED';
export const REORDER_ELEMENT = 'form:builder:REORDER_ELEMENT';
export const CHECK_DEAL_NAME_INFORM = 'form:builder:CHECK_DEAL_NAME_INFORM';
export const FORM_ADDED_STEP = 'form:builder:FORM_ADDED_STEP';
export const FORM_CHANGE_STEP = 'form:builder:FORM_CHANGE_STEP';
export const FORM_REMOVE_STEP = 'form:builder:FORM_REMOVE_STEP';
export const FORM_RENAME_STEP = 'form:builder:FORM_RENAME_STEP';

export function createElement(field) {
  return { type: FIELD_ADD, payload: field };
}

export function deleteElement(field) {
  return { type: FIELD_REMOVE, payload: field };
}

export function removeForm() {
  return { type: REMOVE_ALL_FIELD };
}

export function resetForm() {
  return { type: RESET_FORM };
}

export function editElement(field) {
  return { type: FIELD_UPDATE, payload: field };
}

export function saveData(data) {
  return { type: SAVE_DATA, payload: data };
}

export function save(data) {
  return { type: SAVE, payload: data };
}

export function reorderElement(element, from, to) {
  return dispatch => {
    dispatch({ type: REORDER_ELEMENT, element, from, to });
  };
}

export function onAddStep() {
  return dispatch => {
    dispatch({ type: FORM_ADDED_STEP });
  };
}

export function onChangeStep(activekey) {
  return dispatch => dispatch({ type: FORM_CHANGE_STEP, payload: activekey });
}

export function renameStep(stepKey, title) {
  return dispatch => dispatch({ type: FORM_RENAME_STEP, title, stepKey });
}

export function onRemoveStep(targetKey) {
  return dispatch => {
    dispatch({ type: FORM_REMOVE_STEP, payload: targetKey });
  };
}

export function createForm(object) {
  const form = {
    ...object,
    title: get(object, 'data.online_form_first_step.content[0].title', 'Default Title'),
    data: object.data,
  };
  return dispatch => {
    dispatch({ type: FORMS_LOADING, payload: true });
    api
      .post('/forms', form)
      .then(() => {
        dispatch({ type: FORMS_LOADING, payload: false });
        browserHistory.push('/settings/forms');
        dispatchAlert(dispatch, {
          message: `The form "${form.title}" have been successfully created.`,
          type: 'success',
        });
        Mixpanel.track('Form Created', { form_id: form.form_id });
      })
      .catch(error => {
        dispatch({ type: FORMS_LOADING, payload: false });
        Notifier({
          type: 'error',
          title: 'Failed to save your form',
          placement: 'topRight',
          message: handleErrors(error),
        });
        Mixpanel.track('Form Creation Failed', { error: error.message });
      });
  };
}

export function updateForm(object) {
  const form = {
    ...object,
    title: get(object, 'data.online_form_first_step.content[0].title', 'Default Title'),
    data: object.data,
  };
  return dispatch => {
    dispatch({ type: FORMS_LOADING, payload: true });
    api
      .put(`/forms/${form.form_id}`, form)
      .then(() => {
        dispatch({ type: FORMS_LOADING, payload: false });
        browserHistory.push('/settings/forms');
        dispatchAlert(dispatch, {
          message: `The form "${form.title}" have been successfully updated.`,
          type: 'info',
        });
        Mixpanel.track('Form Updated', { form_id: form.form_id });
      })
      .catch(error => {
        dispatch({ type: FORMS_LOADING, payload: false });
        Mixpanel.track('Form Update Failed', { error: error.message });
      });
  };
}

export function loadForms() {
  return dispatch => {
    dispatch({ type: FORMS_LOADING, payload: true });
    api
      .get('/forms?fields=form_id,title,form_key,status,owner,created_at,deal_number')
      .then(({ data }) => {
        dispatch({ type: FORMS_LOADED, payload: data.results });
        dispatch({ type: FORMS_LOADING, payload: false });
      })
      .catch(error => dispatch({ type: FORMS_LOADING, payload: false }));
  };
}

export function loadForm(id) {
  return dispatch => {
    dispatch({ type: FORMS_LOADING, payload: true });
    api
      .get(`/forms/${id}`)
      .then(({ data }) => {
        dispatch({ type: FORM_LOADED, payload: data });
        dispatch({ type: FORMS_LOADING, payload: false });
      })
      .catch(error => dispatch({ type: FORMS_LOADING, payload: false }));
  };
}

export function loadSharedForm(key) {
  return dispatch => {
    dispatch({ type: FORMS_LOADING, payload: true });
    api
      .get(`/forms/key/${key}`)
      .then(({ data }) => {
        dispatch({ type: FORM_LOADED, payload: data });
        dispatch({ type: FORMS_LOADING, payload: false });
      })
      .catch(({ response }) => {
        dispatch({ type: FORM_LOADING_ERROR, payload: response });
      });
  };
}

export function loadInProgressForm(key) {
  return dispatch => {
    dispatch({ type: FORMS_LOADING, payload: true });
    api
      .get(`/forms/key/${key}/save`)
      .then(({ data }) => {
        dispatch({
          type: FORM_INPROGRESS_LOADED,
          payload: data.form,
          savedValues: data.values,
        });
        dispatch({ type: FORMS_LOADING, payload: false });
        if (data.form && data.form.form_key) loadFormCustomFields(dispatch, data.form.form_key);
      })
      .catch(({ response }) => {
        dispatch({ type: FORM_LOADING_ERROR, payload: response });
      });
  };
}

function loadFormCustomFields(dispatch, key) {
  api
    .get(`/forms/field/${key}`)
    .then(({ data }) => {
      dispatch({ type: CUSTOM_FIELDS_LOADED, payload: data });
    })
    .catch(err => {});
}

export function deleteForm(form) {
  return dispatch => {
    api
      .delete(`/forms/${form.form_id}`)
      .then(() => {
        dispatch({ type: FORM_DELETED, payload: form });
        dispatchAlert(dispatch, {
          message: `The form "${form.title}" have been successfully deleted.`,
          type: 'danger',
        });
        Mixpanel.track('Form Deleted', { form_id: form.form_id });
      })
      .catch(error => {
        Mixpanel.track('Form Deletion Failed', { error: error.message });
      });
  };
}

export function duplicateForm(form) {
  return dispatch => {
    api
      .post(`/forms/${form.form_id}/duplicate`)
      .then(({ data }) => {
        dispatch({ type: FORM_DUPLICATED, payload: data });
        browserHistory.push('/settings/forms');
        dispatchAlert(dispatch, {
          message: `The form "${form.title}" have been successfully created.`,
          type: 'success',
        });
        Mixpanel.track('Form Duplicated', { form_id: form.form_id });
      })
      .catch(error => {
        Mixpanel.track('Form Duplication Failed', { error: error.message });
      });
  };
}

export function exportForm(form) {
  return dispatch => {
    dispatchAlert(dispatch, {
      message: `Wait while "${form.title}" is being exported!`,
      type: 'info',
    });
    api
      .get(`/forms/${form.form_id}/export`, {
        responseType: 'arraybuffer',
      })
      .then(response => {
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const a = document.createElement('a');
        const fileURL = window.URL.createObjectURL(file);
        a.href = fileURL;
        a.download = `${form.title}-Deals-${new Date().toISOString().split('T')[0]}.xls`;
        a.click();
        dispatchAlert(dispatch, {
          message: `The form "${form.title}" have been successfully exported.`,
          type: 'success',
        });
        Mixpanel.track('Form Exported', { form_id: form.form_id });
      })
      .catch(error => {
        try {
          dispatchAlert(dispatch, {
            message: `${error.data.message}`,
            type: 'danger',
          });
          Mixpanel.track('Form Export Failed', { error: error.message });
        } catch (err) {
          dispatchAlert(dispatch, {
            message: `There was an error exporting "${form.title}"`,
            type: 'danger',
          });
        }
      });
  };
}

export function switchElementDeal(value, field) {
  return dispatch => {
    dispatch({ type: SWITCH_DEAL_ELEMENT, field, value });
  };
}

export function switchElementContact(value, field, element) {
  return dispatch => {
    dispatch({ type: UPDATE_CARD, payload: { element, field, value } });
  };
}

export function saveDataForm(form) {
  return dispatch => {
    dispatch({ type: FORM_SAVING_SUBMITTING_DATA, payload: true });
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const formData = new FormData();
    const formObject = {
      ...form,
      questions: form.questions ? form.questions : null,
      contacts: form.contacts ? form.contacts : [],
      files: form.files ? Object.keys(form.files).map(i => form.files[i]) : [],
    };
    formData.append('deal', JSON.stringify(formObject.deal));
    formData.append('savedKey', JSON.stringify(formObject.savedKey));
    formData.append('contacts', JSON.stringify(formObject.contacts));
    formData.append('questions', JSON.stringify(formObject.questions));
    formObject.files.forEach((element, idx) => {
      formData.append('files[]', element);
    });

    api
      .post(`/forms/key/${form.key}`, formData, config)
      .then(({ data }) => {
        dispatch({ type: FORMS_LOADING, payload: false });
        dispatch({ type: FORM_SAVING_SUBMITTING_DATA, payload: false });
        dispatch(reset('form-editor-generator'));
        dispatchFixedAlert(dispatch, {
          message: data.message,
          type: 'success',
        });
      })
      .catch(({ response }) => {
        dispatch({ type: FORM_SAVING_SUBMITTING_DATA, payload: false });
        dispatch({ type: FORM_LOADING_ERROR, payload: response });
      });
  };
}

export function loadQuestions(deal_id) {
  return dispatch => {
    dispatch({ type: FORMS_LOADING, payload: true });
    api
      .get(`/forms/questions/${deal_id}`)
      .then(({ data }) => {
        dispatch({ type: FORMS_LOADING, payload: false });
        dispatch({ type: QUESTIONS_FORM_LOADED, payload: data });
      })
      .catch(({ response }) => dispatch({ type: FORMS_LOADING, payload: false }));
  };
}

export function saveInProgressForm(form_data) {
  return dispatch => {
    dispatch({ type: FORM_SAVING_SUBMITTING_DATA, payload: true });
    // for (let prop in form_data.questions) {
    //   if( form_data.questions.hasOwnProperty( prop ) ) {
    //     form_data.questions[prop] = form_data.questions[prop] ? form_data.questions[prop].response : null;
    //   }
    // }
    api
      .post(`/forms/key/${form_data.key}/save`, form_data)
      .then(({ data }) => {
        dispatch({ type: FORM_SAVING_SUBMITTING_DATA, payload: false });
        dispatch(reset('form-editor-generator'));
        dispatchFixedAlert(dispatch, {
          message: 'Your form has been successfully saved, please check your email',
          type: 'success',
        });
      })
      .catch(({ response }) => {
        Notifier({
          type: 'error',
          title: 'Failed to save your form',
          placement: 'topRight',
          message: 'Please try again',
        });
        dispatch({ type: FORM_SAVING_SUBMITTING_DATA, payload: false });
      });
  };
}

export function checkDealForm(key, name) {
  return dispatch => {
    if (!name) return dispatch({ type: CHECK_DEAL_NAME_INFORM, payload: undefined });
    api
      .post(`/forms/key/${key}/check`, { name })
      .then(({ data }) => {
        dispatch({ type: CHECK_DEAL_NAME_INFORM, payload: data.response });
      })
      .catch(err => {});
  };
}
