import { RoleSummaryData } from '../../models/role/role-summary-data';
import { IMap } from '../../utils/map-utils';

export enum RoleCreateFormNamesEnum {
  RoleName = 1,
}

export namespace RoleCreateFormNamesEnum {
  export type formFieldName = keyof RoleSummaryData;

  export const toPlaceholder: IMap<RoleCreateFormNamesEnum, string> = new IMap<RoleCreateFormNamesEnum, string>([[RoleCreateFormNamesEnum.RoleName, 'Role name']], null);

  export const formFields: IMap<RoleCreateFormNamesEnum, formFieldName> = new IMap<RoleCreateFormNamesEnum, formFieldName>([[RoleCreateFormNamesEnum.RoleName, 'name']]);
}
