import React, { Component } from 'react';
import { Field, Fields } from 'redux-form';

import { FieldSelect, AsyncSearch } from 'SharedComponent/SelectOption/FieldSelect';
import TextField from 'SharedComponent/TextField';
import { customFieldProperties, CustomFieldUsers } from 'SharedComponent/functions/customFields.js';

import * as validators from 'Utils/validators';

export default class InputCustomFields extends Component {
  fieldName(name) {
    return `${this.props.fieldArray}${name}`;
  }

  render() {
    const { customField, preventTypes } = this.props;
    const properties = customFieldProperties(customField);
    if (preventTypes.includes(customField.field_type)) return null;
    switch (customField.field_name) {
      case 'countryName':
        return (
          <div className='col-md-6' style={{ marginBottom: '10px' }}>
            <Field
              name={this.fieldName('countryName')}
              label={customField.field_label}
              placeholder={customField.placeHolder}
              required={!!customField.required}
              validate={!!customField.required ? [validators.required] : []}
              component={AsyncSearch}
              labelClass={this.props.classLabel}
              rtl={this.props.language}
              url='/countries'
              field={this.props.rtl ? 'ar_name' : 'name'}
              listValue='country_id'
              onLoad={event => {}}
            />
          </div>
        );
      case 'website':
        return (
          <div className='col-md-6' style={{ marginBottom: '10px' }}>
            <Field
              name={this.fieldName(customField.field_name)}
              label={customField.field_label}
              placeholder={customField.placeHolder}
              required={!!customField.required}
              validate={!!customField.required ? [validators.required] : []}
              labelClass={this.props.classLabel}
              component={TextField}
              type='text'
              onChange={event => {}}
            />
          </div>
        );
      case 'assignee':
        return (
          <div className='col-md-6' style={{ marginBottom: '10px' }}>
            <Field
              name={this.fieldName(`assignee.id`)}
              label={customField.field_label}
              placeholder={customField.placeHolder}
              required={!!customField.required}
              validate={!!customField.required ? [validators.required] : []}
              labelClass={this.props.classLabel}
              component={FieldSelect}
              options={this.props.users}
              rtl={this.props.rtl}
              withImage={true}
              onLoad={event => {}}
              top={true}
            />
          </div>
        );
      case 'domain':
        return (
          <div className='col-md-6' style={{ marginBottom: '10px' }}>
            <Field
              name={this.fieldName(`domain.domain_id`)}
              label={customField.field_label}
              placeholder={customField.placeHolder}
              required={!!customField.required}
              validate={!!customField.required ? [validators.required] : []}
              labelKey='name'
              valueKey='domain_id'
              labelClass={this.props.classLabel}
              options={this.props.domains}
              component={FieldSelect}
              rtl={this.props.rtl}
              onLoad={event => {}}
              top={true}
            />
          </div>
        );

      default:
        if (customField.field_type == 'monetary')
          return (
            <div style={{ marginBottom: '10px' }} className={`col-md-${properties.col}`}>
              <Fields
                names={[this.fieldName(customField.field_name), this.fieldName(`${customField.field_name}_currency_id`), this.fieldName(`${customField.field_name}_code`)]}
                formSection={this.props.formSection}
                label={customField.field_label}
                placeholder={customField.placeholder}
                required={!!customField.required}
                labelClass={this.props.classLabel}
                component={properties.component}
                style={properties.style}
                rtl={this.props.rtl}
                validate={properties.validation}
                amount={this.fieldName(customField.field_name)}
                currency={this.fieldName(`${customField.field_name}_currency_id`)}
                code={this.fieldName(`${customField.field_name}_code`)}
                format={(value, name) => {
                  if (!name.includes('_code') && !name.includes('_currency_id')) return properties.format(value);
                  else return value;
                }}
                parse={(value, name) => {
                  if (!name.includes('_code') && !name.includes('_currency_id')) return properties.normalize(value);
                  else return value;
                }}
              />
            </div>
          );
        else if (customField.field_type == 'users')
          return (
            <div className='col-md-6' style={{ marginBottom: '10px' }}>
              <CustomFieldUsers
                name={this.fieldName(customField.field_name)}
                label={customField.field_label}
                placeholder={customField.placeHolder}
                required={!!customField.required}
                validate={!!customField.required ? [validators.multiselect] : []}
                labelClass={this.props.classLabel}
                rtl={this.props.rtl}
              />
            </div>
          );
        return (
          <div style={{ marginBottom: '10px' }} className={`col-md-${properties.col}`}>
            {properties ? (
              <Field
                name={this.fieldName(customField.field_name)}
                label={customField.field_label}
                placeholder={customField.placeHolder}
                required={!!customField.required}
                labelClass={this.props.classLabel}
                component={properties.component}
                style={properties.style}
                validate={properties.validation}
                withIcon={properties.withIcon}
                prefix={properties.prefix}
                style={properties.style}
                divider={properties.divider}
                options={properties.options}
                noImageCountries={this.props.noImageCountries}
                withImage={properties.withImage}
                labelKey={this.props.rtl ? 'ar_name' : 'label'}
                rtl={this.props.rtl}
                top={properties.top}
                format={properties.format ? input => properties.format(input) : input => input}
                normalize={properties.format ? input => properties.normalize(input) : input => input}
              />
            ) : null}
          </div>
        );
    }
  }
}

InputCustomFields.defaultProps = {
  fieldArray: '',
  formSection: '',
  preventTypes: [],
};
