const Languages = {
  AR: 'ar',
  EN: 'en',
};

const Directions = {
  RTL: 'rtl',
  LTR: 'ltr',
};

export const formLanguages = [
  { label: 'Arabic', value: Languages.AR },
  { label: 'English', value: Languages.EN },
];

export function convertLanguageToDirection(language) {
  switch (language) {
    case Languages.AR:
      return Directions.RTL;
    case Languages.EN:
      return Directions.LTR;
    default:
      return Directions.LTR;
  }
}

export function convertApiValueToRTLBoolean(language) {
  switch (language) {
    case Languages.AR:
      return true;
    case Languages.EN:
      return false;
    default:
      return false;
  }
}

export function convertLanguageToClassDirection(language) {
  switch (language) {
    case Languages.AR:
      return 'pn-rtl';
    case Languages.EN:
      return 'pn-ltr';
    default:
      return 'pn-ltr';
  }
}
