import { put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { all } from '@redux-saga/core/effects';

import { DashboardTaskDetailsData } from '../../../../../core/models/dashboard-task/dashboard-task-details-data';
import { ErrorResponseData } from '../../../../../core/models/http-params.type';
import { initializeMetaQueries, mapPaginationQueriesToMetaQueriesData, MetaQuery } from '../../../../../core/models/queries/meta-query.model';
import { LazyLoadResponse } from '../../../../../core/models/queries/lazy-response.model';
import { DashboardTaskSummaryData } from '../../../../../core/models/dashboard-task/dashboard-task-summary-data';
import { DashboardTaskForCreate } from '../../../../../core/models/dashboard-task/dashboard-task-for-create';
import { DashboardUpdateTaskStatus } from '../../../../../core/models/dashboard-task/dashboard-task-for-status';
import { DashboardZoomTaskForCreate } from '../../../../../core/models/dashboard-task/dashboard-zoom-task-for-create';
import { MeetingInfoData } from '../../../../../core/models/meeting/meeting-info-data';
import { DashboardTaskForVote } from '../../../../../core/models/dashboard-task/dashboard-task-for-vote';
import { DashboardTaskForUpdate } from '../../../../../core/models/dashboard-task/dashboard-task-for-update';

import { TaskStatusEnum } from '../../../../../core/enums/task/task-status.enum';
import { FormIdEnum } from '../../../../../core/enums/entity-ids/form-id.enum';

import { createTask, createZoomCall, getTaskByUuid, getTaskList, updateTask, updateTaskStatus, votForTask, zoomUrl } from '../../../../../core/services/dashboard-task.service';
import { users } from '../../../../../core/services/user.service';

import { SelectItemData } from '../../../../../shared/interfaces/select-item';
import { NotificationTypeEnum } from '../../../../../shared/enums/notification-type.enum';
import { notification } from '../../../../../shared/components';
import { resetReduxForm } from '../../../../../shared/components/Form/state/form.actions';

import * as taskAction from './task.actions';
import { getTaskMeta } from './task.selectors';
//@ts-ignore
import { Mixpanel } from 'App/app';

function* loadTaskListEffect(): Generator<Promise<LazyLoadResponse<DashboardTaskSummaryData[]>> | PutEffect, void, LazyLoadResponse<DashboardTaskSummaryData[]>> {
  try {
    const tasks = yield getTaskList({ queryParams: mapPaginationQueriesToMetaQueriesData(initializeMetaQueries()) });
    yield put(taskAction.loadTaskListSuccess({ list: tasks.results, meta: tasks._meta, reset: true }));
  } catch (error) {
    yield put(taskAction.loadTaskListFail(error));
  }
}

function* loadTaskNextPageEffect(): Generator<
  SelectEffect | Promise<LazyLoadResponse<DashboardTaskSummaryData[]>> | PutEffect,
  void,
  MetaQuery & LazyLoadResponse<DashboardTaskSummaryData[]>
> {
  try {
    const queryNews = yield select(getTaskMeta);

    const tasks = yield getTaskList({ queryParams: mapPaginationQueriesToMetaQueriesData({ ...queryNews, currentPage: queryNews.currentPage + 1 }) });
    yield put(taskAction.loadTaskListSuccess({ list: tasks.results, meta: tasks._meta, reset: false }));
  } catch (error) {
    yield put(taskAction.loadTaskListFail(error));
  }
}

function* loadTaskEffect(action: PayloadAction<{ uuid: string }>): Generator<Promise<DashboardTaskDetailsData> | PutEffect, void, DashboardTaskDetailsData> {
  try {
    const task = yield getTaskByUuid(action.payload.uuid);
    yield put(taskAction.loadTaskSuccess({ task }));
  } catch (error) {
    yield put(taskAction.loadTaskFail(error));
  }
}

function* createTaskEffect({ payload: { task } }: PayloadAction<{ task: DashboardTaskDetailsData }>): Generator {
  try {
    yield createTask({ body: DashboardTaskForCreate.mapToApiValue(task) });
    yield put(taskAction.createTaskSuccess());
    Mixpanel.track(`Task created successfully ${task.type}`);
    notification({ type: NotificationTypeEnum.Success, message: 'Task created successfully' });
    yield put(resetReduxForm(FormIdEnum.dashboardTask));
  } catch (error) {
    Mixpanel.track(`Task creation failed ${task.type}`);
    yield put(taskAction.createTaskFail(error));
  }
}

function* updateTaskEffect({ payload: { task } }: PayloadAction<{ task: DashboardTaskDetailsData }>): Generator {
  try {
    yield updateTask(task.uuid, { body: DashboardTaskForUpdate.mapToApiValue(task) });
    yield put(taskAction.updateTaskSuccess());
    Mixpanel.track(`Task updated successfully ${task.type}`);
    notification({ type: NotificationTypeEnum.Info, message: 'Task updated successfully' });
  } catch (error) {
    Mixpanel.track(`Task update failed ${task.type}`);
    yield put(taskAction.updateTaskFail(error));
  }
}

function* createOrUpdateTaskEffect(): Generator {
  try {
    yield put(taskAction.resetTask());
    yield put(taskAction.loadTaskList());
  } catch (error) {}
}

function* updateStatusEffect({ payload: { status, uuid } }: PayloadAction<DashboardUpdateTaskStatus>): Generator {
  try {
    yield updateTaskStatus(uuid, TaskStatusEnum.convertToApiValue.getValue(status));
    yield put(taskAction.updateStatusSuccess({ status, uuid }));
    notification({ type: NotificationTypeEnum.Success, message: 'Task updated successfully' });
  } catch (error) {
    notification({ type: NotificationTypeEnum.Error, message: error.message });
  }
}

// zoom effects

function* loadUsersListEffect({
  payload: { searchText },
}: PayloadAction<{ searchText: string }>): Generator<Promise<LazyLoadResponse<SelectItemData[]>> | PutEffect, void, LazyLoadResponse<SelectItemData[]>> {
  try {
    const lazy = yield users({ queryParams: mapPaginationQueriesToMetaQueriesData(initializeMetaQueries()) }, searchText);
    yield put(taskAction.loadUserListSuccess({ list: lazy.results, meta: lazy._meta, reset: true }));
  } catch (error) {}
}

function* startZoomEffect({ payload: { guests, attendees } }: PayloadAction<DashboardZoomTaskForCreate>): Generator<Promise<string> | PutEffect, void, string> {
  try {
    const url = yield createZoomCall({ body: DashboardZoomTaskForCreate.mapToApiValue({ guests, attendees }) });

    window.open(url, '_blank');

    yield put(taskAction.startZoomSuccess({ url }));
  } catch (error) {
    yield put(taskAction.startZoomFail(error));
  }
}

function* getZoomUrlEffect({ payload }: PayloadAction<MeetingInfoData>): Generator<Promise<string> | PutEffect, void, string> {
  try {
    const url = yield zoomUrl(payload);

    window.open(url, '_blank');
  } catch (error) {
    yield put(taskAction.startZoomFail(error));
  }
}

function* voteForTaskEffect({ payload }: PayloadAction<DashboardTaskForVote>): Generator<Promise<DashboardTaskSummaryData> | PutEffect, void, DashboardTaskSummaryData> {
  try {
    const task = yield votForTask(payload);

    yield put(taskAction.voteForTaskSuccess(task));
    notification({ type: NotificationTypeEnum.Success, message: `Task voted: ${payload.vote ? 'YES' : 'NO'}` });
  } catch (error) {
    yield put(taskAction.voteForTaskFail(error));
  }
}

function* failEffect({ payload }: PayloadAction<ErrorResponseData>): Generator {
  try {
    notification({ type: NotificationTypeEnum.Error, message: payload.message });
  } catch (error) {}
}

export function* watchDashboardTasks(): Generator {
  yield all([
    takeLatest(taskAction.loadTaskList.type, loadTaskListEffect),
    takeLatest(taskAction.loadTaskListNextPage.type, loadTaskNextPageEffect),
    takeLatest(taskAction.loadTask.type, loadTaskEffect),
    takeLatest(taskAction.createTask.type, createTaskEffect),
    takeLatest(taskAction.updateTask.type, updateTaskEffect),
    takeLatest(taskAction.updateStatus.type, updateStatusEffect),
    takeLatest(taskAction.loadUserList.type, loadUsersListEffect),
    takeLatest(taskAction.getZoomUrl.type, getZoomUrlEffect),
    takeLatest(taskAction.startZoom.type, startZoomEffect),
    takeLatest(taskAction.voteForTask.type, voteForTaskEffect),
    takeLatest([taskAction.createTaskSuccess.type, taskAction.updateTaskSuccess.type], createOrUpdateTaskEffect),
    takeLatest([taskAction.loadTaskFail.type, taskAction.createTaskFail.type, taskAction.updateTaskFail.type, taskAction.startZoomFail.type], failEffect),
  ]);
}
