import { Tag } from 'antd';
import React from 'react';

import './CustomTag.scss';

export default function CustomTag(props) {
  return (
    <div className='CustomTag'>
      <Tag
        color={props.color}
        visible={props.visible}
        icon={props.icon}
        closable={props.closable}
        // actions
        onClose={props.onClose}
        onClick={props.onClick}
      >
        {props.label}
      </Tag>
    </div>
  );
}

export function CustomCheckableTag(props) {
  return (
    <Tag.CheckableTag
      color='blue'
      color={props.color}
      visible={props.visible}
      icon={props.icon}
      closable={props.closable}
      checked={props.onChecked}
      // actions
      onClick={props.onClick}
      onChange={props.onChange}
      onClose={props.onClose}
    >
      {props.label}
    </Tag.CheckableTag>
  );
}

CustomTag.defaultProps = {
  color: 'blue',
  label: 'label',
  visible: true,
};
