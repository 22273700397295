import React from 'react';
import { connect } from 'react-redux';
import { UncontrolledCustomSwitch } from 'SharedComponent/CustomSwitch';
import { at } from 'Utils/objectUtils';

import ContactTranslation from 'Translations/contact.json';

class ContactEdit extends React.Component {
  render() {
    const element = this.props.element;
    const language = this.props.language;
    return (
      <div>
        <div>Fields to display:</div>
        <div className='deal-form-build-details'>
          <div className='row mb-1'>
            <div className='col-md-6'>
              <SwitchToggle
                onChange={this.props.switchElement}
                defaultChecked={element.first_name}
                label={ContactTranslation[language].first_name}
                value='first_name'
                disabled={true}
              />
            </div>
            <div className='col-md-6'>
              <SwitchToggle
                onChange={this.props.switchElement}
                defaultChecked={element.last_name}
                label={ContactTranslation[language].last_name}
                value='last_name'
                disabled={false}
              />
            </div>
          </div>
          <div className='row mb-1'>
            <div className='col-md-6'>
              <SwitchToggle onChange={this.props.switchElement} defaultChecked={element.title} label={ContactTranslation[language].title} value='title' disabled={false} />
            </div>
            <div className='col-md-6'>
              <SwitchToggle onChange={this.props.switchElement} defaultChecked={element.email} label={ContactTranslation[language].email} value='email' disabled={false} />
            </div>
          </div>
          <div className='row mb-1'>
            <div className='col-md-6'>
              <SwitchToggle onChange={this.props.switchElement} defaultChecked={element.phone} label={ContactTranslation[language].phone} value='phone' disabled={false} />
            </div>
            <div className='col-md-6'>
              <SwitchToggle onChange={this.props.switchElement} defaultChecked={element.linkedin} label={ContactTranslation[language].linkedin} value='linkedin' disabled={false} />
            </div>
          </div>
          <div className='row'>
            {this.props.contactCustomFields.map((cf, idx) => (
              <div key={idx + 1} className='col-md-6 mb-1'>
                <SwitchToggle
                  onChange={this.props.switchElement}
                  defaultChecked={element[cf.field_name]}
                  label={ContactTranslation[language][cf.field_name] || cf.field_label}
                  value={cf.field_name}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: at(state, 'settings.formBuilder.editing.language') || 'en',
  };
}

export default connect(mapStateToProps, {})(ContactEdit);

const SwitchToggle = props => {
  return (
    <div>
      <div className='display-flex-start'>
        <div>
          <UncontrolledCustomSwitch onChange={value => props.onChange(value, props.value)} disabled={props.disabled} defaultChecked={props.defaultChecked} />
        </div>
        <div className='ml-2'>{props.label}</div>
      </div>
    </div>
  );
};
