import { Card } from 'antd';
import React from 'react';

import PieChartItem from '../../../../reports/items/PieChartItem/PieChartItem';

import './items.scss';

const defaultEmptyChart = [{ value: 0, name: 'No Data' }];

export const PieChart = props => {
  const piechartDataGenerator = (values = [], name = 'Untitled chart') => ({
    item: {
      key: props.type,
      name,
      title: name,
      items: values.length ? values : defaultEmptyChart,
      legend_position: 'bottom_center',
      legend_horizontal_orient: true,
      center: ['50%', '45%'],
      showPercent: true,
    },
    theme: 'light',
    showStat: true,
  });
  return (
    <div className='PortfolioDashboard'>
      <Card>
        <PieChartItem {...piechartDataGenerator(props.data, props.title)} />
      </Card>
    </div>
  );
};

PieChart.defaultProps = {
  type: 'pie_chart',
};
