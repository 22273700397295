import ReactEcharts from 'echarts-for-react';
import React, { PureComponent } from 'react';

import './BarChartItem.scss';

export default class BarChartItem extends PureComponent {
  static defaultProps = {
    types: {
      column_chart: {
        serie: {},
        xAxis: 'category',
        yAxis: 'value',
      },
      stacked_histogram: {
        serie: { stack: 'Stack' },
        xAxis: 'category',
        yAxis: 'value',
      },
      bar_graph: {
        serie: {},
        xAxis: 'value',
        yAxis: 'category',
      },
      stacked_bar_chart: {
        serie: { stack: 'Stack' },
        xAxis: 'value',
        yAxis: 'category',
      },
    },
  };

  componentWillUnmount() {}

  onChartReady = chart => {
    chart.setOption(this.getOption());
  };

  getLoadingOption = () => {
    return {
      text: 'Loading...',
      color: '#4413c2',
      textColor: '#270240',
      maskColor: 'rgba(0, 0, 0, 0.5)',
      zlevel: 0,
    };
  };

  getSeries(group, properties) {
    let legend = [];
    let series = [];
    group.data.forEach(category => {
      category.metrics.forEach(metric => {
        if (!metric.hide) {
          legend.push(metric.name);
          series.push({
            name: metric.name,
            type: 'bar',
            data: metric.values.map(it => it.value),
            ...properties.serie,
          });
        }
      });
    });
    return { legend, series };
  }

  getTtitlePos(position, coords) {
    switch (position) {
      case 'custom':
        return { ...coords };
      case 'top_center':
        return { x: 'center' };
      case 'top_right':
        return { top: 10, right: 10 };
      case 'top_left':
        return { top: 10, left: 10 };
      case 'bottom_center':
        return { top: 'bottom', left: 'center' };
      case 'bottom_right':
        return { bottom: 10, right: 10 };
      case 'bottom_left':
        return { bottom: 10, left: 10 };
      default:
        return { x: 'center' };
    }
  }
  getLegendPos(position, coords) {
    switch (position) {
      case 'custom':
        return { ...coords };
      case 'top_center':
        return { top: 20, left: 'center' };
      case 'top_left':
        return { top: 20, left: 'left' };
      case 'top_right':
        return { top: 20, left: 'right' };
      case 'bottom_left':
        return { top: '85%', left: 'left' };
      case 'bottom_center':
        return { top: '85%', left: 'center' };
      case 'bottom_right':
        return { top: '85%', left: 'right' };
      default:
        return { left: 'left' };
    }
  }
  getOption() {
    const item = this.props.item;
    const properties = this.props.types[item.key];
    const metric = item.data ? item.data : { axes: [], data: [] };
    const { series, legend } = this.getSeries(metric, properties);

    return {
      title: {
        text: item.title,
        subtext: item.description,
        ...this.getTtitlePos(item.title_position, item.titlePos),
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        orient: item.legend_horizontal_orient ? 'horizontal' : 'vertical',
        ...this.getLegendPos(item.legend_position, item.legendPos),
        data: legend,
        ...item.legend,
      },
      grid: {
        top: (item.grid && item.grid.top) || '20%',
        left: (item.grid && item.grid.left) || '10%',
        right: (item.grid && item.grid.right) || '10%',
        bottom: (item.grid && item.grid.bottom) || '20%',
        containLabel: true,
      },
      toolbox: {
        // show: true,
        // feature: {
        //   // restore: { title: "Restore" },
        //   // dataView: { readOnly: false, title: "Data view" },
        //   saveAsImage: { title: "Save as image" },
        // },
        right: '30px',
      },
      xAxis: properties.xAxis == 'category' ? { type: 'category', data: metric.axes } : [{ type: 'value' }],
      yAxis: properties.yAxis == 'category' ? { type: 'category', data: metric.axes } : this.getYAxis(),
      series,
      color: item.properties && item.properties.colorPalette,
    };
  }

  getYAxis = () => {
    if (!this.props.item.hasPercentageAxis) {
      return { type: 'value' };
    }
    return [
      { type: 'value' },
      {
        type: 'value',
        name: 'Percentage',
        min: 0,
        max: 100,
        position: 'right',
        offset: 10,
        axisLine: {
          lineStyle: {
            color: '#675bba',
          },
        },
        axisLabel: {
          formatter: '{value} %',
        },
      },
    ];
  };

  render() {
    let onEvents =
      this.props.mode == 'view'
        ? {}
        : {
            click: () => {},
            legendselectchanged: () => {},
          };
    return (
      <div className='BarChartItem'>
        <ReactEcharts
          ref='echartsInstance'
          option={{}}
          opts={{ renderer: 'svg' }}
          onChartReady={this.onChartReady}
          onEvents={onEvents}
          // loadingOption={this.getLoadingOption()}
          // showLoading={true}
        />
      </div>
    );
  }
}
