/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FilterDTO } from './filterDTO';

export interface TaskCalendarRequestDTO {
  mode: TaskCalendarRequestDTO.ModeEnum;
  /**
   * date(unix timestamp)
   */
  date: number;
  filters?: Array<FilterDTO>;
}
export namespace TaskCalendarRequestDTO {
  export type ModeEnum = 'year' | 'month';
  export const ModeEnum = {
    Year: 'year' as ModeEnum,
    Month: 'month' as ModeEnum,
  };
}
