import React from 'react';
import { Card } from 'antd';
import Billing from '../Billing';

import './ExpiredBilling.scss';

export default function ExpiredBilling(props) {
  return (
    <div className='ExpiredBilling m-5'>
      <div style={{ paddingBottom: '12px' }}>
        <Card bordered={false}>
          <div className='suspended-title-card text-center'>Your subscription has been suspended.</div>
        </Card>
      </div>
      <Billing />
    </div>
  );
}
