import React from 'react';
import { connect } from 'react-redux';
import { UncontrolledCustomSwitch } from 'SharedComponent/CustomSwitch';
import { at } from 'Utils/objectUtils';

import DealTranslation from 'Translations/deal.json';

class DealEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const element = this.props.element;
    const language = this.props.language;
    return (
      <div>
        <div>Fields to display:</div>
        <div className='deal-form-build-details'>
          <div className='row mb-1'>
            <div className='col-md-6'>
              <SwitchToggle onChange={this.props.switchElement} defaultChecked={element.name} label={DealTranslation[language].name} value='name' disabled={true} />
            </div>
          </div>
          <div className='row mb-1'>
            <div className='col-md-6'>
              <SwitchToggle onChange={this.props.switchElement} defaultChecked={element.size} label={DealTranslation[language].value} value='size' disabled={false} />
            </div>
            <div className='col-md-6'>
              <SwitchToggle onChange={this.props.switchElement} defaultChecked={element.logo} label={DealTranslation[language].logo} value='logo' disabled={false} />
            </div>
          </div>
          <div className='row'>
            {this.props.pipelineCustomFields.map((cf, idx) => (
              <div key={idx + 1} className='col-md-6 mb-1'>
                <SwitchToggle
                  onChange={this.props.switchElement}
                  defaultChecked={element[cf.field_name]}
                  label={DealTranslation[language][cf.field_name] || cf.field_label}
                  value={cf.field_name}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: at(state, 'settings.formBuilder.editing.language') || 'en',
  };
}

export default connect(mapStateToProps, {})(DealEdit);

const SwitchToggle = props => {
  return (
    <div>
      <div className='display-flex-start'>
        <div>
          <UncontrolledCustomSwitch onChange={value => props.onChange(value, props.value)} disabled={props.disabled} defaultChecked={props.defaultChecked} />
        </div>
        <div className='ml-2'>{props.label}</div>
      </div>
    </div>
  );
};
