/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PagePermissionEnumDTO =
  | 'dashboard_page'
  | 'pipeline_page'
  | 'portfolio_page'
  | 'report_page'
  | 'contact_page'
  | 'task_page'
  | 'settings_page'
  | 'user_setting_page'
  | 'fund_setting_page'
  | 'form_setting_page'
  | 'custom_field_setting_page'
  | 'integration_setting_page'
  | 'pipeline_deal_note_page'
  | 'company_setting_page'
  | 'portfolio_deal_note_page'
  | 'pipeline_deal_email_page'
  | 'portfolio_deal_email_page'
  | 'pipeline_deal_task_page'
  | 'portfolio_deal_task_page'
  | 'pipeline_deal_file_page'
  | 'portfolio_deal_file_page'
  | 'pipeline_deal_metric_page'
  | 'portfolio_deal_metric_page'
  | 'pipeline_deal_history_page'
  | 'portfolio_deal_history_page';

export const PagePermissionEnumDTO = {
  DashboardPage: 'dashboard_page' as PagePermissionEnumDTO,
  PipelinePage: 'pipeline_page' as PagePermissionEnumDTO,
  PortfolioPage: 'portfolio_page' as PagePermissionEnumDTO,
  ReportPage: 'report_page' as PagePermissionEnumDTO,
  ContactPage: 'contact_page' as PagePermissionEnumDTO,
  TaskPage: 'task_page' as PagePermissionEnumDTO,
  SettingsPage: 'settings_page' as PagePermissionEnumDTO,
  UserSettingPage: 'user_setting_page' as PagePermissionEnumDTO,
  FundSettingPage: 'fund_setting_page' as PagePermissionEnumDTO,
  FormSettingPage: 'form_setting_page' as PagePermissionEnumDTO,
  CustomFieldSettingPage: 'custom_field_setting_page' as PagePermissionEnumDTO,
  IntegrationSettingPage: 'integration_setting_page' as PagePermissionEnumDTO,
  PipelineDealNotePage: 'pipeline_deal_note_page' as PagePermissionEnumDTO,
  CompanySettingPage: 'company_setting_page' as PagePermissionEnumDTO,
  PortfolioDealNotePage: 'portfolio_deal_note_page' as PagePermissionEnumDTO,
  PipelineDealEmailPage: 'pipeline_deal_email_page' as PagePermissionEnumDTO,
  PortfolioDealEmailPage: 'portfolio_deal_email_page' as PagePermissionEnumDTO,
  PipelineDealTaskPage: 'pipeline_deal_task_page' as PagePermissionEnumDTO,
  PortfolioDealTaskPage: 'portfolio_deal_task_page' as PagePermissionEnumDTO,
  PipelineDealFilePage: 'pipeline_deal_file_page' as PagePermissionEnumDTO,
  PortfolioDealFilePage: 'portfolio_deal_file_page' as PagePermissionEnumDTO,
  PipelineDealMetricPage: 'pipeline_deal_metric_page' as PagePermissionEnumDTO,
  PortfolioDealMetricPage: 'portfolio_deal_metric_page' as PagePermissionEnumDTO,
  PipelineDealHistoryPage: 'pipeline_deal_history_page' as PagePermissionEnumDTO,
  PortfolioDealHistoryPage: 'portfolio_deal_history_page' as PagePermissionEnumDTO,
};
