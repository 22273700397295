import {
  LOADING_NOTIFICATIONS,
  LOADED_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  LOADED_ALL_NOTIFICATIONS,
  CLEAR_ALERT,
  DISPATCH_ALERT,
  DISPATCH_NOTIFICATION,
  NOTIFICATION_CREATED,
} from 'ReduxActions/notificationActions';

const initialState = {
  all: [],
  loading: false,
  alert: null,
  meta: null,
  allNotifs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };

    case NOTIFICATION_CREATED:
      return {
        ...state,
        all: [{ ...action.payload, seen: 10 }, ...state.all],
      };

    case LOADED_NOTIFICATIONS:
      return {
        ...state,
        all: action.payload,
        loading: false,
      };
    case LOADED_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifs: action.payload ? action.payload.results : [],
        meta: action.payload ? action.payload._meta : null,
        loading: false,
      };
    case CLEAR_NOTIFICATIONS: {
      const seen = state.all.map(input => {
        return { ...input, seen: 20 };
      });
      return {
        ...state,
        all: seen,
      };
    }

    case CLEAR_ALERT:
      return {
        ...state,
        alert: state.alert ? { ...state.alert, isOpen: false } : null,
      };

    case DISPATCH_ALERT:
      return {
        ...state,
        alert: { ...action.payload, isOpen: true },
      };
  }

  return state;
}
