import React, { ReactNode } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';

interface CheckboxProps {
  label?: string | ReactNode; // note: use <span>...<span/> incase of ReactNode!
  disabled?: boolean;
  defaultChecked?: boolean;
  onChange(checked: boolean): void;
}

export function Checkbox(props: CheckboxProps): JSX.Element {
  const onChange = (event: CheckboxChangeEvent) => props.onChange(event.target.checked);

  const onClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <span onClick={onClick}>
      <AntdCheckbox defaultChecked={props.defaultChecked} onChange={onChange} disabled={props.disabled}>
        {props.label}
      </AntdCheckbox>
    </span>
  );
}
