import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions/notificationActions';
import defaultImage from '../../../assets/img/default.png';
import { at } from '../../../utils/objectUtils';
import NotificationsHeader from '../NotificationsHeader';
import NotificationsList from '../NotificationsList';
import { eventTypes } from '../../../enums/eventTypes';

import './NotificationsContext.scss';

class NotificationsContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      curr_page: 1,
      user_id: null,
      deal_id: null,
    };
  }

  componentDidMount() {
    this.props.loadAllNotifications(1, 20, this.state.deal_id, this.state.user_id);
  }

  render() {
    return (
      <div className='NotificationsContext'>
        <div className='display-flex-between mb-3'>
          <div className='display-flex-start'>
            <div className='breadcrumbs-card'>
              <i className='icon-bell' />
            </div>
            <div className='breadcrumbs-card-text'>Notifications</div>
          </div>

          <NotificationsHeader
            users={this.parseUsers(this.props.users)}
            filterByUser={user_id => {
              this.setState({ user_id });
              this.props.loadAllNotifications(this.state.curr_page, 10, this.state.deal_id, user_id);
            }}
            filterByDeal={deal => {
              let deal_id = undefined;
              if (deal) deal_id = deal.deal_id;
              this.setState({ deal_id });
              this.props.loadAllNotifications(this.state.curr_page, 10, deal_id, this.state.user_id);
            }}
          />
        </div>
        <div className=''>
          <div className=''>
            <div className='card-flex'>
              <React.Fragment>
                <div className='addNotificationsContainer'></div>
                <NotificationsList
                  notifs={this.props.notifs}
                  meta={this.props.meta}
                  timezone={this.props.timezone}
                  loading={this.props.loading}
                  reloadData={curr_page => {
                    this.setState({ curr_page });
                    this.props.loadAllNotifications(curr_page, 10, this.state.deal_id, this.state.user_id);
                  }}
                  tableOptions={{ currentPage: this.state.curr_page, pageCount: this.props.meta ? this.props.meta.pageCount : 0 }}
                  redirectTo={e => this.props.router.push(`/deals/${e.deal_id}/notifs`)} /* see with @ahmed*/
                />
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }

  parseUsers(users) {
    return users.map(user => {
      return {
        value: user.id,
        label: user.profile ? user.profile.fullname : 'N/A',
        image: user.profile && user.profile.image ? user.profile.image : defaultImage,
        is_user: true,
      };
    });
  }
}

function mapStateToProps(state) {
  return {
    notifs: state.notifs.allNotifs,
    loading: state.notifs.loading,
    meta: state.notifs.meta,
    timezone: at(state, 'auth.user.profile.timezone'),
    users: state.settings.users.all,
  };
}

export default connect(mapStateToProps, actions)(NotificationsContext);
