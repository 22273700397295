import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Tooltip } from 'antd';
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn';
import Image from 'react-bootstrap/lib/Image';
import defaultImage from 'Assets/img/default.png';
import pipelineSvg from 'Assets/img/empty-pipeline.svg';
import portfolioSvg from 'Assets/img/empty-portfolio.svg';
import Table from 'SharedComponent/Table';
import DropdownList from 'SharedComponent/DropdownList';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import { Permissible } from '../../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';

import { PermissibleRender } from 'SharedComponent/permissions';
import { VC_ACCELERATOR, SALES } from 'Enums/permissions';

import './PipelinesTable.scss';
class PipelinesTable extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    onEdit: PropTypes.func,
    onSortChange: PropTypes.func,
    noDataText: PropTypes.string,
    pagination: PropTypes.bool,
    checkBox: PropTypes.bool,
  };

  static defaultProps = {
    noDataText: 'No pipeline found',
    pagination: false,
    checkBox: true,
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.actionsFormatter = this.actionsFormatter.bind(this);
  }

  onClickEdit() {
    this.props.isPortfolio
      ? gaEvent(appCategories.settings_portfolio, appActions(`${appTypes.edit}`, appEvents.clicked).icon)
      : gaEvent(appCategories.settings_pipelines, appActions(`${appTypes.edit}`, appEvents.clicked).icon);
  }

  onClickMove() {
    this.props.isPortfolio
      ? gaEvent(appCategories.settings_portfolio, appActions(`${appTypes.move}`, appEvents.clicked).icon)
      : gaEvent(appCategories.settings_pipelines, appActions(`${appTypes.move}`, appEvents.clicked).icon);
  }

  onClickDelete() {
    this.props.isPortfolio
      ? gaEvent(appCategories.settings_portfolio, appActions(`${appTypes.delete}`, appEvents.clicked).icon)
      : gaEvent(appCategories.settings_pipelines, appActions(`${appTypes.delete}`, appEvents.clicked).icon);
  }

  render() {
    const { props, state } = this;
    return (
      <div className='PipelinesTable'>
        <Table
          items={props.items}
          pagination={props.pagination}
          loading={props.isLoading}
          onSortChange={props.onSortChange}
          noDataText={this.emptyData()}
          checkBox={props.checkBox}
          onSelectAll={props.onSelectAll}
          onRowSelect={props.onRowSelect}
          onRowClick={props.onEdit}
        >
          <TableHeaderColumn dataField='pipeline_id' isKey hidden>
            ID
          </TableHeaderColumn>

          <TableHeaderColumn dataField='title' dataAlign='left' width='80' dataFormat={this.nameFormatter}>
            {props.isPortfolio ? 'Portfolio name' : 'Pipeline name'}
          </TableHeaderColumn>

          <TableHeaderColumn dataField='stage' dataAlign='left' width='50'>
            Number of stages
          </TableHeaderColumn>

          <TableHeaderColumn dataField='deals' dataAlign='left' width='50'>
            Number of deals
          </TableHeaderColumn>

          <TableHeaderColumn dataFormat={this.logoFormatter} dataAlign='left' width='80'>
            Owner
          </TableHeaderColumn>

          <TableHeaderColumn dataField='createdAt' dataAlign='left' width='80'>
            Created at
          </TableHeaderColumn>

          <TableHeaderColumn dataFormat={this.actionsFormatter} dataAlign='left' width='40' columnClassName='custom-table-outline-details' isActionColumn>
            Actions
          </TableHeaderColumn>
        </Table>
      </div>
    );
  }

  nameFormatter(cell, row) {
    return (
      <div>
        {row.isPrivate && (
          <Tooltip title={row.isPortfolio ? 'Private portfolio' : 'Private pipeline'}>
            <i className='icon-lock mr-2' />
          </Tooltip>
        )}
        {cell}
      </div>
    );
  }

  actionsFormatter(cell, row) {
    const { props } = this;
    const items = [
      {
        label: 'Set privacy',
        icon: 'icon-lock',
        onClick: () => this.props.onSetPrivacy(row),
        disabled: this.props.isPortfolio ? !this.props.UpdatePortfolio : !this.props.UpdatePipeline,
      },
      {
        label: 'Export Deals',
        icon: 'icon-cloud-download',
        onClick: () => this.props.onExportDeals(row),
        disabled: this.props.isPortfolio ? !this.props.ExportPortfolioDeals : !this.props.ExportPipelineDeals,
      },
      {
        label: this.props.isPortfolio ? 'Delete portfolio' : 'Delete pipeline',
        icon: 'icon-trash',
        onClick: e => {
          props.onDelete(row);
          this.onClickDelete();
        },
        disabled: this.props.isPortfolio ? !this.props.DeletePortfolio : !this.props.DeletePipeline,
      },
    ];
    return (
      <div className='actions-details'>
        <Permissible can={this.props.isPortfolio ? PermissionTypeEnum.UpdatePortfolio : PermissionTypeEnum.UpdatePipeline}>
          <Tooltip title={this.props.isPortfolio ? 'Edit portfolio' : 'Edit pipeline'}>
            <i
              className='icon-pencil'
              onClick={e => {
                e.stopPropagation();
                props.onEdit(row);
                this.onClickEdit();
              }}
              style={{ width: '36px' }}
            />
          </Tooltip>
        </Permissible>
        <PermissibleRender userPermissions={[]} requiredPermissions={[[VC_ACCELERATOR, SALES]]}>
          <Permissible can={this.props.isPortfolio ? PermissionTypeEnum.MovePortfolioToPipeline : PermissionTypeEnum.MovePipelineToPortfolio}>
            <Tooltip title={this.props.isPortfolio ? 'Move to pipeline' : 'Move to portfolio'}>
              <i
                className={'icon-cursor-move'}
                onClick={e => {
                  e.stopPropagation();
                  props.onUpdate(row);
                  this.onClickMove();
                }}
                style={{ width: '36px' }}
              />
            </Tooltip>
          </Permissible>
        </PermissibleRender>
        <div className='ml-1'>
          <DropdownList icon='more_vert' items={items} tooltip='More actions' />
        </div>
      </div>
    );
  }

  logoFormatter(cell, row) {
    return (
      <div>
        {row.owner ? (
          <div>
            <Image
              src={row.owner.image ? row.owner.image : defaultImage}
              alt='Logo'
              style={{
                height: '30px',
                margin: '0 auto',
                width: '30px',
                borderRadius: '50%',
              }}
              circle
            />
            <span style={{ marginLeft: '24px' }}>{row.owner && row.owner.fullname}</span>
          </div>
        ) : (
          <span>N/A</span>
        )}
      </div>
    );
  }

  emptyData() {
    return (
      <div>
        {this.props.isLoading ? (
          <div className='custom-spinner'>
            <div id='custom-spinner-id' />
          </div>
        ) : (
          <div className='m-4'>
            <img src={this.props.isPortfolio ? portfolioSvg : pipelineSvg} />
            <div className='empty-tpl'>Looks like you dont have any {this.props.isPortfolio ? 'Portfolio' : 'Pipeline'} yet, so lets start by adding some</div>
          </div>
        )}
      </div>
    );
  }
}

export default PipelinesTable;
