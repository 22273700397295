import remove from 'lodash/remove';
import { TASK_ADDED } from 'ReduxActions/dashboardActions';
import { LOADING_TASKS, TASKS_LOADED, EDITING_TASK, TASK_UPDATED, TASK_DELETED } from 'ReduxActions/tasksActions';
import { replace } from 'Utils/arrayUtils.js';

const initialState = {
  all: [],
  loading: false,
  meta: null,
  editing: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_TASKS:
      return {
        ...state,
        loading: action.payload,
      };

    case TASKS_LOADED:
      return {
        ...state,
        all: action.payload ? action.payload.results : [],
        meta: action.payload ? action.payload._meta : null,
        loading: false,
      };

    case EDITING_TASK:
      return {
        ...state,
        editing: action.payload,
      };

    case TASK_UPDATED:
      return {
        ...state,
        all: replace(state.all, { task_id: action.payload.task_id }, action.payload),
      };

    case TASK_ADDED:
      return {
        ...state,
        all: [action.payload, ...state.all],
      };

    case TASK_DELETED:
      const deletedtasks = [...state.all];
      remove(deletedtasks, { task_id: action.payload });
      return {
        ...state,
        all: deletedtasks,
      };
  }

  return state;
}
