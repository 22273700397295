import React, { Component } from 'react';
import ItemHandler from '../ItemHandler';
import {
  EditableText,
  Text,
  Figure,
  PieChartItem,
  LineChartItem,
  BarChartItem,
  ScoreCard,
  PortfolioCard,
  DealCard,
  ContactCard,
  GeometricItem,
  LinkCard,
  AuthorCard,
  TableCard,
  ManualTable,
  TableOfContents,
  PipelineCard,
} from '../items';
import { HotKeys } from 'react-hotkeys';

import './ItemCard.scss';

export default class ItemCard extends Component {
  renderComponent() {
    const item = this.props.data;
    switch (item.key) {
      case 'content_table':
        return <TableOfContents item={item} />;
      case 'pasted_element':
        return <div dangerouslySetInnerHTML={{ __html: item.htmlContent }} />;
      case 'text_content':
        return (
          <EditableText
            isActive={this.props.isActive}
            pageNum={this.props.mode === 'view' ? this.props.editorPageNumber : this.props.tools.activePage}
            mode={this.props.mode}
            item={item}
          />
        );
      case 'text':
        return <Text item={item} />;
      case 'image':
        return <Figure item={item} isEdit={this.props.mode === 'edit'} isNavigationSideBar={this.props.isNavigationSideBar} />;
      case 'score_card':
      case 'data_table':
      case 'data_table_with_compressed_numbers':
        return <ScoreCard item={item} />;
      case 'pie_chart':
      case 'ring_graphic':
        return <PieChartItem item={item} mode={this.props.mode} />;
      case 'time_series_graph':
      case 'sparkline_chart':
      case 'smoothed_time_series_graph':
      case 'stacked_area_chart':
        return <LineChartItem item={item} mode={this.props.mode} />;
      case 'column_chart':
      case 'stacked_histogram':
      case 'bar_graph':
      case 'stacked_bar_chart':
        return <BarChartItem item={item} mode={this.props.mode} />;
      case 'portfolio_card':
        return <PortfolioCard item={item} mode={this.props.mode} />;
      case 'pipeline_card':
        return <PipelineCard item={item} mode={this.props.mode} />;
      case 'deal_card':
        return <DealCard item={item} />;
      case 'contact_card':
        return <ContactCard item={item} />;
      case 'rectangle':
      case 'circle':
        return <GeometricItem item={item} />;
      case 'link':
        return <LinkCard item={item} />;
      case 'author_card':
        return <AuthorCard item={item} />;
      case 'table':
        return <TableCard item={item} item={item} mode={this.props.mode} />;
      case 'manual_table':
        return <ManualTable item={item} isActive={this.props.isActive} mode={this.props.mode} />;
    }
    return <div className='text-element'>{item.name} coming soon...</div>;
  }
  shortcutHandlers = item => {
    return {
      DELETE_NODE: () => this.props.dropElement(),
      MOVE_LEFT: () => this.props.handleShortcut('MOVE_LEFT', item),
      MOVE_RIGHT: () => this.props.handleShortcut('MOVE_RIGHT', item),
      MOVE_DOWN: () => this.props.handleShortcut('MOVE_DOWN', item),
      MOVE_UP: () => this.props.handleShortcut('MOVE_UP', item),
      COPY_NODE: () => this.props.handleShortcut('COPY_NODE', item),
      PASTE_NODE: () => this.props.handleShortcut('PASTE_NODE', item),
    };
  };
  handleContextMenu = (e, item) => {
    e.preventDefault();
    this.props.onContextMenu(item, e.clientX, e.clientY);
  };
  render() {
    const { data, itemSelected, selected, dropElement, removeSharedComponent, mode } = this.props;
    let style = { zoom: `${this.props.zoom * 100}%` };
    if (data.properties.border.show)
      style = {
        ...style,
        borderRadius: '2px',
        border: `1px solid ${data.properties.border.color}`,
      };
    return (
      <div className='ItemCard' onDoubleClick={e => e.stopPropagation()} onContextMenu={(e, item) => this.handleContextMenu(e, data)} style={style}>
        <ItemHandler
          item={data}
          removeComponent={this.props.removeComponent}
          reloadMetrics={this.props.reloadMetrics}
          dropElement={dropElement}
          removeSharedComponent={removeSharedComponent}
          mode={mode}
        >
          {mode == 'view' ? (
            this.renderComponent()
          ) : (
            <HotKeys handlers={this.shortcutHandlers(data)} className='full-dimensions'>
              {this.renderComponent()}
            </HotKeys>
          )}
        </ItemHandler>
      </div>
    );
  }
}
