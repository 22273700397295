import { notification as antdNotification } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';

import { NotificationTypeEnum } from '../../enums/notification-type.enum';
import { PopoverPlacementEnum } from '../../enums/popover-placement.enum';
import { IconData } from '../../interfaces/icon-data';

interface ButtonProps {
  label: string;
  type: string;
  icon?: IconData;
  onClick(): void;
}

interface NotificationProps {
  type: NotificationTypeEnum;
  message: string;
  placement?: PopoverPlacementEnum;
  button?: ButtonProps;
  description?: string;
}

export function notification({ type, message, description, placement, button }: NotificationProps): void {
  const _placement = (placement || PopoverPlacementEnum.BottomRight) as NotificationPlacement;

  antdNotification[type]({
    message,
    description,
    placement: _placement,
    btn: button,
  });
}
