import { all, put, takeLatest } from 'redux-saga/effects';
import { GetMeResponseDTO } from '../../core/generated/model/models';
import { MeData } from '../../core/models/me/me-data';

import * as actions from './me.actions';

// tslint:disable-next-line: interface-over-type-literal
type Params = { payload: GetMeResponseDTO; type: string };

function* meEffect({ payload }: Params): Generator {
  try {
    yield put(actions.loadMeSuccess({ me: MeData.mapFromApi(payload) }));
  } catch (error) {}
}

export function* watchMe(): Generator {
  yield all([takeLatest('AUTH:SIGNING:V2', meEffect)]);
}
