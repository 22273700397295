import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import api from 'ReduxActions/api';
import { actions } from 'ReduxActions/authActions';
import { CHANGING_PASSWORD, CHANGING_PASSWORD_FAILED, PASSWORD_CHANGED } from 'ReduxActions/authActions';
import { dispatchAlert } from 'ReduxActions/notificationActions';
import * as validators from 'Utils/validators';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import TextField from 'SharedComponent/TextField';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './PasswordDialog.scss';
const dispatchActions = { ...actions, dispatchAlert };
class PasswordDialog extends Component {
  static propTypes = {
    showDetails: PropTypes.bool,
    onDismiss: PropTypes.func,
  };

  static defaultProps = {
    showDetails: false,
    onDismiss: new Function(),
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };

    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  closeModal() {
    this.props.reset();
    this.props.onDismiss();
    this.setState({ error: null });
    this.props.discardError();
  }

  submit(data) {
    if (data.newPassword !== data.confirmPassword) {
      this.setState({
        error: 'New password and confirmation password do not match',
      });
    } else {
      this.setState({ error: null });
      this.changePassword(data);
      gaEvent(appCategories.profile, appActions(`CHANGE_PASSWORD_${appTypes.modal}_${appTypes.save}`, appEvents.clicked).button);
    }
  }

  onCloseDialogBox() {
    this.closeModal();
    gaEvent(appCategories.profile, appActions(`CHANGE_PASSWORD_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
  }

  onClickCancelBtn() {
    this.closeModal();
    gaEvent(appCategories.profile, appActions(`CHANGE_PASSWORD_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
  }

  changePassword(data) {
    this.props.dispatch({ type: CHANGING_PASSWORD });
    api
      .post('/me/updatepassword', data)
      .then(({ data }) => {
        this.closeModal();
        this.props.dispatch({ type: PASSWORD_CHANGED });
        this.props.dispatchAlert(dispatch, {
          message: `Your password has been successfully updated.`,
          type: 'info',
        });
      })
      .catch(({ response }) => {
        this.props.dispatch({
          type: CHANGING_PASSWORD_FAILED,
          error: response && response.data,
        });
      });
  }

  render() {
    if (!this.props.showDetails) return null;
    const { handleSubmit, submitting, pristine, editing, reset } = this.props;
    const onSubmit = handleSubmit(this.submit);
    return (
      <div className='PasswordDialog'>
        <DialogBox isVisible onClickOut={this.closeModal} onClose={() => this.onCloseDialogBox()} className='small-dialog'>
          <form className='m-3' onSubmit={onSubmit}>
            <div>Change Password</div>
            {this.props.pwFailed && <div className='message danger'>{this.props.pwFailed.message.password[0]}</div>}
            <div className='pl-3 pr-3'>
              <div className='input-container'>
                <Field label='Current Password' name={`password`} component={TextField} type='password' placeholder='' validate={[validators.minLength]} required />
                <Field
                  label='New Password (minimum 6 characters)'
                  name={`newPassword`}
                  component={TextField}
                  type='password'
                  placeholder=''
                  validate={[validators.minLength]}
                  required
                />
                <Field label='Confirm New Password' name={`confirmPassword`} component={TextField} type='password' placeholder='' validate={[validators.minLength]} required />
                {this.state.error && <span className='error-message'>{this.state.error}</span>}
              </div>
            </div>
            <div className='display-flex-end mt-3'>
              <div className='m-3'>
                <SubmitButton
                  label='Cancel'
                  icon='icon-close'
                  style={{
                    width: '150px',
                    color: '#53627c',
                    backgroundColor: '#fff',
                    border: '1px solid #e1e4e8',
                  }}
                  onClick={() => this.onClickCancelBtn()}
                />
              </div>
              <div className='m-3'>
                <Button disabled={submitting || pristine} type='submit' className='submit-btn-p'>
                  <i className='icon-check mr-2' />
                  SAVE
                </Button>
              </div>
            </div>
          </form>
        </DialogBox>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    changingPassword: state.auth.changingPassword,
    pwFailed: state.auth.error,
  };
}

PasswordDialog = reduxForm({ form: 'change-password' })(PasswordDialog);
export default connect(mapStateToProps, dispatchActions)(PasswordDialog);
