/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CountryDTO } from './countryDTO';
import { CurrencyDTO } from './currencyDTO';

export interface CompanyDTO {
  company_id?: number;
  currency?: CurrencyDTO;
  name?: string;
  website?: string;
  logo?: string;
  address?: string;
  countryName?: CountryDTO;
  /**
   *      1 - VC/Accelerators     5 - Wealth Management     6 - Sales
   */
  activity?: CompanyDTO.ActivityEnum;
  cc_email?: string;
  /**
   *      1 - Free     2 - Silver     3 - Gold     4 - Platinium
   */
  plan?: CompanyDTO.PlanEnum;
  created_at?: number;
  angel_investor_enabled?: boolean;
  /**
   *      0 - Not migrated     2 - Migration in progress     1 - Migrated
   */
  is_pipedrive_migrated?: CompanyDTO.IsPipedriveMigratedEnum;
  is_importing_data?: boolean;
}
export namespace CompanyDTO {
  export type ActivityEnum = 1 | 5 | 6;
  export const ActivityEnum = {
    NUMBER_1: 1 as ActivityEnum,
    NUMBER_5: 5 as ActivityEnum,
    NUMBER_6: 6 as ActivityEnum,
  };
  export type PlanEnum = 1 | 2 | 3 | 4;
  export const PlanEnum = {
    NUMBER_1: 1 as PlanEnum,
    NUMBER_2: 2 as PlanEnum,
    NUMBER_3: 3 as PlanEnum,
    NUMBER_4: 4 as PlanEnum,
  };
  export type IsPipedriveMigratedEnum = 0 | 1 | 2;
  export const IsPipedriveMigratedEnum = {
    NUMBER_0: 0 as IsPipedriveMigratedEnum,
    NUMBER_1: 1 as IsPipedriveMigratedEnum,
    NUMBER_2: 2 as IsPipedriveMigratedEnum,
  };
}
