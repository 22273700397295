import classNames from 'classnames';
import React from 'react';

import { IntegrationsEnum } from '../../../../../../core/enums/settings/integrations-enums/integrations-enum';

import './excel-button.component.scss';

interface ExcelButtonProps {
  title: string;
  disabled?: boolean;
  onClick(): void;
}

export const ExcelButton = (props: ExcelButtonProps) => (
  <div className='ExcelButton'>
    <div className='wrapper'>
      <div className={classNames('button-images', props.disabled ? 'disabled-button' : null)} onClick={props.onClick}>
        <div className='one-fourth'>
          <div className='button-container'>
            {!props.disabled && <a>{props.title}</a>}
            <img src={IntegrationsEnum.getImage(IntegrationsEnum.Excel)} />
          </div>
        </div>
      </div>
    </div>
  </div>
);
