import React from 'react';
import Preview from './preview';

import { convertLanguageToDirection } from 'Enums/languages';

class FormBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editElement: null,
    };
  }

  editModeOn(data, e) {
    e.stopPropagation();
    if (this.state.editMode) {
      this.setState({ editMode: !this.state.editMode, editElement: null });
    } else {
      this.setState({ editMode: !this.state.editMode, editElement: data });
    }
  }

  manualEditModeOff() {
    if (this.state.editMode) {
      this.setState({
        editMode: false,
        editElement: null,
      });
    }
  }

  editModeOff(e) {}

  render() {
    let toolbarProps = {};
    if (this.props.toolbarItems) toolbarProps.items = this.props.toolbarItems;
    return (
      <div>
        <div className='react-form-builder clearfix' style={{ direction: convertLanguageToDirection(this.props.language) }}>
          <div>
            <Preview
              manualEditModeOff={this.manualEditModeOff.bind(this)}
              editModeOn={this.editModeOn.bind(this)}
              editMode={this.state.editMode}
              editElement={this.state.editElement}
              data={this.props.data}
              files={this.props.files}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default FormBuilder;
