import { CompanyDTO } from '../../generated/model/companyDTO';
import { IMap } from '../../utils/map-utils';

export enum PipeDriveMigrationEnum {
  NotMigrated = 1,
  MigrationInProgress = 2,
  Migrated = 3,
}

export namespace PipeDriveMigrationEnum {
  export const toString: IMap<PipeDriveMigrationEnum, string> = new IMap<PipeDriveMigrationEnum, string>(
    [
      [PipeDriveMigrationEnum.NotMigrated, 'Not migrated'],
      [PipeDriveMigrationEnum.MigrationInProgress, 'Migration in progress'],
      [PipeDriveMigrationEnum.Migrated, 'Migrated'],
    ],
    undefined,
  );

  export const convertFromApiValue: IMap<CompanyDTO.IsPipedriveMigratedEnum, PipeDriveMigrationEnum> = new IMap<CompanyDTO.IsPipedriveMigratedEnum, PipeDriveMigrationEnum>([
    [CompanyDTO.IsPipedriveMigratedEnum.NUMBER_0, PipeDriveMigrationEnum.NotMigrated],
    [CompanyDTO.IsPipedriveMigratedEnum.NUMBER_2, PipeDriveMigrationEnum.MigrationInProgress],
    [CompanyDTO.IsPipedriveMigratedEnum.NUMBER_1, PipeDriveMigrationEnum.Migrated],
  ]);

  export const convertToApiValue: IMap<PipeDriveMigrationEnum, CompanyDTO.IsPipedriveMigratedEnum> = new IMap<PipeDriveMigrationEnum, CompanyDTO.IsPipedriveMigratedEnum>(
    [
      [PipeDriveMigrationEnum.NotMigrated, CompanyDTO.IsPipedriveMigratedEnum.NUMBER_1],
      [PipeDriveMigrationEnum.MigrationInProgress, CompanyDTO.IsPipedriveMigratedEnum.NUMBER_2],
      [PipeDriveMigrationEnum.Migrated, CompanyDTO.IsPipedriveMigratedEnum.NUMBER_1],
    ],
    undefined,
  );
}
