import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { FieldSelect, AsyncSearch } from '../../shared/SelectOption/FieldSelect';
import './NotificationsHeader.scss';

class NotificationsHeader extends Component {
  render() {
    return (
      <div className='NotificationsHeader'>
        <form className='display-flex-between'>
          <div className='ml-3'>
            <Field
              name={`deal_id`}
              component={AsyncSearch}
              placeholder='Filter by deal'
              url='/deals'
              field='name'
              listValue='deal_id'
              filters='deal_id,image,name'
              multi={false}
              withImage={true}
              onLoad={this.props.filterByDeal}
            />
          </div>
          <div className='ml-3'>
            <Field
              options={this.props.users}
              component={FieldSelect}
              placeholder='Filter by user'
              name={`user_id`}
              onLoad={this.props.filterByUser}
              clearable={true}
              withImage={true}
              closeOnSelect={false}
              // style={{ width: "200px" }}
            />
          </div>{' '}
        </form>
      </div>
    );
  }
}

NotificationsHeader = reduxForm({ form: 'notifications-header' })(NotificationsHeader);
export default NotificationsHeader;
