import { createReducer, Action, createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import { ErrorResponseData } from '../../../../core/models/http-params.type';
import { KpisSummaryData } from '../../../../core/models/kpis/kpis-summary-data';

import * as fromKpisActions from './kpis.actions';

const adapter = createEntityAdapter<KpisSummaryData>({
  selectId: kpi => kpi.uuid,
  sortComparer: (a, b) => String(a.order).localeCompare(String(b.order)),
});

export const selectAll = adapter.getSelectors().selectAll;

export interface DashboardUsedKpisState extends EntityState<KpisSummaryData> {
  loading: boolean;
  allKpis: KpisSummaryData[];
  error: ErrorResponseData;
}

export const usedKpisInitialState: DashboardUsedKpisState = adapter.getInitialState<DashboardUsedKpisState>({
  ids: [],
  entities: {},
  allKpis: [],
  loading: false,
  error: null,
});

export interface DashboardAllKpisState {
  allKpis: KpisSummaryData[];
  loading: boolean;
}

export const initialAllKpisState = {
  allKpis: [],
  loading: false,
};

export interface DashboardKpisState {
  used: DashboardUsedKpisState;
  all: DashboardAllKpisState;
}

export const dashboardKpisInitialState: DashboardKpisState = {
  used: usedKpisInitialState,
  all: initialAllKpisState,
};

const _dashboardKpisReducer = createReducer<DashboardKpisState>(dashboardKpisInitialState, builder => {
  builder
    .addCase(fromKpisActions.loadKpis, (state): DashboardKpisState => ({ ...state, used: { ...state.used, loading: true } }))
    .addCase(fromKpisActions.loadAllKpis, (state): DashboardKpisState => ({ ...state, all: { ...state.all, loading: true } }))
    .addCase(fromKpisActions.loadAllKpisSuccess, (state, { payload: { list } }): DashboardKpisState => ({ ...state, all: { ...state.all, allKpis: list, loading: false } }))
    .addCase(
      fromKpisActions.loadKpisSuccess,
      (state, { payload: { list } }): DashboardKpisState => {
        const newState: DashboardUsedKpisState = { ...state.used, loading: false };

        return {
          ...state,
          used: adapter.setAll(newState, list),
        };
      },
    )
    .addCase(fromKpisActions.resetAllKpisState, (state): DashboardKpisState => ({ ...state, all: initialAllKpisState }));
});

export function dashboardKpisReducer(state: DashboardKpisState | undefined, action: Action): DashboardKpisState {
  return _dashboardKpisReducer(state, action);
}
