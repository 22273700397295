import moment from 'moment';
import classNames from 'classnames';
import React, { Component } from 'react';
import { DatePicker } from 'antd';
import Error from '../TextField/Error';

import './DateField.scss';

export default class DateField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onChangeUncontrolled = this.onChangeUncontrolled.bind(this);
  }

  onChangeUncontrolled(datetime) {
    this.props.input.onChange(Math.trunc(moment(datetime).valueOf() / 1000));
    this.props.onChange && this.props.onChange();
  }

  range(date) {
    if (this.props.minDate && this.props.maxDate) {
      return moment(this.props.minDate).valueOf() >= moment(date).valueOf() || moment(this.props.maxDate).valueOf() < moment(date).valueOf();
    } else if (this.props.minDate) {
      return moment(this.props.minDate).valueOf() >= moment(date).valueOf();
    } else if (this.props.minDate) {
      return moment(this.props.maxDate).valueOf() < moment(date).valueOf();
    }
    return false;
  }

  render() {
    const { props } = this;

    return (
      <div className={classNames('DateField', props.classNames)}>
        <div>
          <div className='display-flex-between'>
            {props.label && (
              <div className={classNames('default-color', props.labelClass ? props.labelClass : 'input-label')}>
                {props.label}
                {props.required && (
                  <span className='' style={{ color: '#ff8181' }}>
                    &nbsp;&#9679;
                  </span>
                )}
              </div>
            )}
            <Error
              touched={props.meta && props.meta.touched}
              error={props.meta && props.meta.error}
              warning={props.meta && props.meta.warning}
              asyncValidating={props.meta.asyncValidating}
            />
          </div>
          <DatePicker
            format='MMMM Do YYYY'
            value={this.props.input.value ? moment(this.props.input.value * 1000) : null}
            placeholder={this.props.placeholder}
            onChange={this.onChangeUncontrolled}
            className='date-input-details'
            size='large'
            disabled={this.props.disabled}
            showTime={false}
            disabledDate={e => this.range(e)}
          />
        </div>
      </div>
    );
  }
}
