import React from 'react';
import TextEditor from '../../../shared/TextEditor/TextEditor';
import { useDispatch } from 'react-redux';
import { updateItem } from '../../../../actions/reportActions';

const EditorToolbarWhenFocused = ({ text, item }) => {
  let dispatch = useDispatch();

  const handleEditorChange = html => {
    let temp = document.createElement('div');
    temp.innerHTML = html;
    let parsedText = temp.textContent || temp.innerText || '';
    dispatch(updateItem(item.id, { ...item, htmlContent: html, textContent: parsedText }));
  };
  return (
    <div onMouseDown={e => e.stopPropagation()}>
      <TextEditor input={{ value: text, onChange: handleEditorChange }} toolbarOnFocus colorPicker placeholder='Start writing here...' />
    </div>
  );
};
const EditableText = props => {
  const item = props.item;
  const isView = props.mode === 'view';
  let formattedText = item.htmlContent.replace(/<br>/g, '&nbsp;');
  if (item.isPageNumber) {
    formattedText = formattedText.replace(/{{page_num}}/g, `${props.pageNum}`);
    formattedText = formattedText.replace(/>(\d+)</g, `>${props.pageNum}<`);
  }
  return (
    <div className={!props.isActive ? 'p-1 editable-text-component' : 'p-1'}>
      {!isView && props.isActive && <EditorToolbarWhenFocused item={item} text={formattedText} />}
      {(isView || !props.isActive) && <div dangerouslySetInnerHTML={{ __html: formattedText }}></div>}
    </div>
  );
};
export default EditableText;
