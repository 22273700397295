import React, { Component } from 'react';
import { Field } from 'redux-form';
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn';
import AutoSearch from '../../../../../shared/AutoSearch';
import { FieldSelect } from '../../../../../shared/SelectOption/FieldSelect';
import Table from '../../../../../shared/Table';
import { replace } from '../../../../../../utils/arrayUtils';
import CustomPopover from '../../../../../shared/CustomPopover';
import Period from '../../../TemplateToolbar/Period';

import './MetricTools.scss';
import get from 'lodash/get';

export default class MetricTools extends Component {
  static defaultProps = {
    column_chart: {
      path: 'data.data[].metrics',
      isKey: 'metric_id',
      field: 'name',
      label: 'Metrics',
    },
  };

  state = {
    frequencies: this.props.frequencies,
  };

  componentDidMount() {
    let item = this.props.item;
    let deal_id = get(item, 'deal_id', null);
    if (item && deal_id) {
      this.props.getGroups(deal_id);
    }
  }

  componentWillUnmount() {
    this.props.emptyMetricsGroup();
  }

  parseGroups(groups) {
    return groups.map(group => {
      return {
        label: group.name,
        value: group.group_id,
      };
    });
  }

  parsePeriod(object) {
    let fromYear = get(object, 'from.year.value', new Date().getFullYear());
    let toYear = get(object, 'to.year.value', new Date().getFullYear());
    let fromPeriod = get(object, 'from.period.value', 0);
    let toPeriod = get(object, 'to.period.value', 0);
    if (object.frequency_id == 4) {
      return {
        from: [fromYear],
        to: [toYear],
      };
    } else
      return {
        from: [fromPeriod, fromYear],
        to: [toPeriod, toYear],
      };
  }

  updateChartPeriod(field, values) {
    let item = {
      ...this.props.item,
      hasCustomPeriod: true,
      [field]: values,
    };
    // this.props.updateItem(item.id, item);
    this.props.getMetricData(item, item.group_metric_id, this.parsePeriod(values));
  }

  updatedElement(field, value) {
    return {
      ...this.props.item,
      [field]: value,
    };
  }

  updatedGroupMetric(group_metric_id) {
    let group = this.props.groups.find(grp => grp.group_id === group_metric_id);
    const metric_frequency = this.props.groups.find(e => e.group_id == group_metric_id);
    if (group && group.metricsDateRange) {
      let period = {
        from: group.metricsDateRange.minDate,
        to: group.metricsDateRange.maxDate,
      };
      let periodForForm = {
        from: {
          period: {
            label: period.from[0],
            value: Number(period.from[0]),
          },
          year: {
            label: Number(period.from[1]),
            value: Number(period.from[1]),
          },
        },
        to: {
          period: {
            label: period.to[0],
            value: Number(period.to[0]),
          },
          year: {
            label: Number(period.to[1]),
            value: Number(period.to[1]),
          },
        },
      };
      return {
        ...this.props.item,
        group_metric_id,
        period: {
          frequency_id: metric_frequency ? metric_frequency.frequency && metric_frequency.frequency.metric_frequency_id : null,
          ...periodForForm,
        },
      };
    }
    return {
      ...this.props.item,
      group_metric_id,
      period: {
        frequency_id: metric_frequency ? metric_frequency.frequency && metric_frequency.frequency.metric_frequency_id : null,
      },
    };
  }

  dealSelected(deal) {
    this.props.getGroups(deal.deal_id);
    let newItem = {
      ...this.props.item,
      deal_name: deal.name,
      deal_id: deal.deal_id,
    };
    this.props.updateItem(newItem.id, newItem);
  }

  groupSelected(group_id) {
    const groupItem = this.updatedGroupMetric(group_id);
    if (this.props.item.hasCustomPeriod) {
      // load metric CUSTOM_PERIOD
      this.props.getMetricData(groupItem, group_id, this.parsePeriod(this.props.item.period));
    } else if (this.props.globalPeriod && this.props.globalPeriod.frequency_id) {
      // load metric GLOBAL_PERIOD
      if (groupItem.period.frequency_id == this.props.globalPeriod.frequency_id) {
        // load metric GLOBAL_PERIOD == group_metric_id
        this.props.getMetricData(groupItem, group_id, this.parsePeriod(this.props.globalPeriod));
      } else {
        this.props.updateItem(groupItem.id, groupItem);
      }
    } else this.props.updateItem(groupItem.id, groupItem);
    let group = this.props.groups.find(grp => grp.group_id === group_id);
    if (group && group.metricsDateRange) {
      let period = {
        from: group.metricsDateRange.minDate,
        to: group.metricsDateRange.maxDate,
      };
      let periodForForm = {
        from: {
          period: {
            label: period.from[0],
            value: period.from[0],
          },
          year: {
            label: period.from[1],
            value: period.from[1],
          },
        },
        to: {
          period: {
            label: period.from[0],
            value: period.from[0],
          },
          year: {
            label: period.from[1],
            value: period.from[1],
          },
        },
      };

      this.props.getMetricData(groupItem, group_id, period);
      this.props.updateItem(groupItem.id, { ...groupItem, period: { ...groupItem.priod, ...periodForForm } });
    }
  }

  parseItems(items) {
    let list = [];
    items.data.forEach(element => {
      list = [...list, { name: element.name, id: 'category', disabled: true }, ...element.metrics];
    });
    return list;
  }

  getselectedItems(list, key) {
    return list
      .filter(it => !it.hide)
      .map((it, idx) => {
        return it[key];
      });
  }

  onSelectAll(bool, item) {
    const object = {
      ...item,
      data: {
        ...item.data,
        data: item.data.data.map(it => {
          return {
            ...it,
            metrics: it.metrics.map(m => ({ ...m, hide: !bool })),
          };
        }),
      },
    };
    this.props.updateItem(object.id, object);
  }

  onRowSelect(object, bool, item, key) {
    const newObject = {
      ...item,
      data: {
        ...item.data,
        data: item.data.data.map(it => {
          return {
            ...it,
            metrics: it.metrics.map(metric => {
              if (metric[key] == object[key]) return { ...metric, hide: !bool };
              else return metric;
            }),
          };
        }),
      },
    };
    this.props.updateItem(newObject.id, newObject);
  }

  render() {
    const properties = this.props[this.props.item.key];
    const items = this.props.item.data ? this.parseItems(this.props.item.data) : [];
    const { globalPeriod, pentugramItems, item, groups } = this.props;
    return (
      <div className='MetricTools'>
        <Field
          name={`deal_name`}
          placeholder={`Type the name of your deal`}
          component={AutoSearch}
          options={pentugramItems}
          loading={false}
          onUpdate={event => this.props.getDeals(item.id, 'deals', 'name', event)}
          onSelect={this.dealSelected.bind(this)}
          validate={[]}
          fieldRequired='name'
          fieldUnique='deal_id'
          itemStyles={{ top: '40px' }}
        />
        <div className='mt-2 display-flex-between'>
          <Field
            name={`group_metric_id`}
            component={FieldSelect}
            options={this.parseGroups(groups)}
            onLoad={this.groupSelected.bind(this)}
            placeholder='Select metric group'
            type='text'
            disabled={true}
            multi={false}
            withImage={false}
            disabled={!item.deal_name}
            clearable={false}
          />
          <div className='m-2'>
            {/* <i className="icon-trash" ></i> */}
            <CustomPopover label='' classes='period-popover' hideAfterClick={false} arrow={true} title='' icon='calendar_today' parent={this}>
              <Period
                initialValues={item.period}
                frequencies={this.state.frequencies}
                disableFrequency={true}
                hideFrequency={true}
                updatePeriod={period => this.updateChartPeriod('period', period)}
              />
            </CustomPopover>
          </div>
        </div>
        {/* <div className="mt-2">
          <CustomPopover
            label={ (item.period && item.period.label) || "Custom Period"}
            classes="period-popover"
            hideAfterClick={false}
            arrow={true}
            title=""
            icon=""
            parent={this}
          >
            <Period
              initialValues={item.period}
              frequencies={this.state.frequencies}
              disableFrequency={true}
              updatePeriod={(period) => this.updateChartPeriod("period", period)}
            />
          </CustomPopover>
        </div> */}
        <div className='mt-2'>
          <Table
            items={items}
            pagination={false}
            loading={false}
            onSortChange={() => {}}
            noDataText='No metrics found'
            checkBox={false}
            maxHeight='400'
            onSelectAll={bool => this.onSelectAll(bool, item)}
            onRowSelect={(object, bool) => this.onRowSelect(object, bool, item, 'metric_id')}
            checkBox={true}
            checkedRows={true}
            selected={this.getselectedItems(items, 'metric_id')}
          >
            <TableHeaderColumn dataField='metric_id' isKey hidden>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField='name'
              dataAlign='left'
              dataFormat={(cell, row) => {
                if (row.id === 'category') {
                  return <b> {row.name} </b>;
                }
                return <div> {row.name} </div>;
              }}
            >
              Metrics
            </TableHeaderColumn>
          </Table>
        </div>
      </div>
    );
  }
}
