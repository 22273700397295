import React from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import './CheckBox.scss';

export const CheckBox = props => {
  let { input, value, checked, onChange } = props;

  return (
    <React.Fragment>
      {props['data-tip'] && <ReactTooltip />}
      <div className='CheckBox' data-tip={props['data-tip']}>
        <input
          {...props.input}
          type='checkbox'
          value={input ? !!input.value : checked}
          checked={input ? !!input.value : checked}
          onChange={input ? props.input.onChange : onChange}
        />
        {props.label && <label className={classNames('default-color', props.labelClass ? props.labelClass : 'input-label')}>{props.label}</label>}
      </div>
    </React.Fragment>
  );
};

export const CheckBoxField = props => {
  return (
    <div className='CheckBox'>
      <input {...props.input} type='checkbox' />
    </div>
  );
};

export const CheckBoxFieldEditor = props => {
  const touched = props.meta && props.meta.touched;
  const error = props.meta && props.meta.error;
  return (
    <div className='CheckBox'>
      <input {...props.input} type='checkbox' onChange={e => props.onClick(e)} className='mr-2' required={touched && error} />
      <span className={classNames('label-text', props.labelClass)}>{props.label}</span>
    </div>
  );
};
