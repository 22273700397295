import { RbacRoleDTO } from '../../generated/model/models';

export interface RoleSummaryData {
  id: string;
  name: string;
  isBasic: boolean;
  isOwner: boolean;
}

export namespace RoleSummaryData {
  export function mapFromApi(role: RbacRoleDTO): RoleSummaryData {
    return {
      id: role.role_id,
      name: role.role_name,
      isBasic: role.isDefault,
      isOwner: role.isOwner,
    };
  }
}
