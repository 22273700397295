import lodash from 'lodash';
import { useSelector } from 'react-redux';

import { PermissionTypeEnum } from '../../../core/enums/permission/permission-type.enum';
import { allowedPermissions } from '../../../store/me/me.selectors';

interface PermissibleProps {
  can: PermissionTypeEnum;
  children: JSX.Element;
  otherWise?: JSX.Element;
}

export const Permissible = (props: PermissibleProps): JSX.Element => {
  const permissions = useSelector(allowedPermissions);
  if (lodash.includes([...permissions.pages, ...permissions.actions], props.can)) {
    return props.children;
  }

  return props.otherWise;
};
Permissible.defaultProps = {
  otherWise: null,
};
