import React, { Component } from 'react';
import HeaderUsers from '../HeaderUsers';
import UserList from '../UserList';
import { connect } from 'react-redux';

import './Users.scss';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      selectedRows: [],
      view: 'users',
    };
  }

  selectedRows(row, isSelected) {
    if (isSelected) {
      this.setState({
        isSelected: true,
        selectedRows: [...this.state.selectedRows, row],
      });
    } else {
      let array = this.state.selectedRows.filter(it => it.id !== row.id);
      this.setState({
        isSelected: array.length ? true : false,
        selectedRows: array,
      });
    }
  }

  selectAll(isSelected, rows) {
    this.setState({ isSelected });
    if (isSelected) {
      this.setState({ selectedRows: rows });
    } else {
      this.setState({ selectedRows: [] });
    }
  }

  render() {
    return (
      <div className='Users'>
        <div>
          <HeaderUsers
            isSelected={this.state.isSelected}
            rows={this.state.selectedRows}
            selected={this.state.view}
            updateView={view => this.setState({ view, selectedRows: [], isSelected: false })}
          />
        </div>
        <div>
          <UserList
            selected={this.state.view}
            onSelectAll={(isSelected, rows) => this.selectAll(isSelected, rows)}
            onRowSelect={(row, isSelected, e) => this.selectedRows(row, isSelected)}
          />
        </div>
      </div>
    );
  }
}

export default Users;
