import { createSelector } from '@reduxjs/toolkit';
import { getFormValues, formValueSelector } from 'redux-form';

import { FormIdEnum } from '../../core/enums/entity-ids/form-id.enum';
import { IntegrationExcelFormNamesEnum } from '../../core/enums/form-names/integration-excel-form-names.enum';
import { DashboardTaskFormNamesEnum } from '../../core/enums/form-names/dashboard-task-form-names.enum';
import { TaskFormNamesEnum } from '../../core/enums/form-names/task-form-names.enums';
import { IntegrationExcelData } from '../../core/models/integration-excel/integration-excel-data';
import { SelectItemData } from '../../shared/interfaces/select-item';

const dashboardTaskFormSelector = formValueSelector(FormIdEnum.dashboardTask);

export const dashboardTaskFormValues = createSelector(getFormValues(FormIdEnum.dashboardTask), formValues => formValues);
export const dashboardTaskFormTypeValue = createSelector(
  state => dashboardTaskFormSelector(state, DashboardTaskFormNamesEnum.formFields.getValue(DashboardTaskFormNamesEnum.Type)),
  type => type,
);

export const dashboardTaskFormDealValue = createSelector(
  state => dashboardTaskFormSelector(state, DashboardTaskFormNamesEnum.formFields.getValue(DashboardTaskFormNamesEnum.RelatedDeal)),
  deal => deal,
);

/* task form */
const TasksFormSelector = formValueSelector(FormIdEnum.addTaskForm);

export const TasksFormValues = createSelector(getFormValues(FormIdEnum.addTaskForm), formValues => formValues);
export const TasksFormTypeValue = createSelector(
  state => TasksFormSelector(state, TaskFormNamesEnum.formFields.getValue(TaskFormNamesEnum.Type)),
  type => type,
);

export const TaskFormDealValue = createSelector(
  state => TasksFormSelector(state, TaskFormNamesEnum.formFields.getValue(TaskFormNamesEnum.RelatedDeal)),
  deal => deal,
);

/* excel form */
const excelFormSelector = formValueSelector<IntegrationExcelData>(FormIdEnum.integrationExcelForm);

export const excelPipelineValue = createSelector(
  (state: IntegrationExcelData) => excelFormSelector(state, IntegrationExcelFormNamesEnum.formFields.getValue(IntegrationExcelFormNamesEnum.Pipeline)),
  (type: SelectItemData) => type,
);
