import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { at } from 'Utils/objectUtils';
import { hasMetrix } from 'Utils/arrayUtils';

class PermissibleRender extends Component {
  static propTypes = {
    oneperm: PropTypes.bool,
    userPermissions: PropTypes.array.isRequired,
    requiredPermissions: PropTypes.arrayOf(PropTypes.array).isRequired,
    renderOtherwise: PropTypes.element, // optional
    // children: PropTypes.element.isRequired,
  };

  get myPermissions() {
    const { userPermissions, userAttributes } = this.props;
    return [...userPermissions, ...userAttributes];
  }

  checkPermissions() {
    return hasMetrix(this.props.requiredPermissions, this.myPermissions);
  }

  render() {
    const { children, requiredPermissions, renderOtherwise } = this.props;

    const permessions = this.myPermissions;
    if (!children || !permessions || !requiredPermissions) {
      return null;
    }

    if (this.checkPermissions()) {
      return children;
    } else if (renderOtherwise) {
      return renderOtherwise;
    }
    return null;
  }
}

function mapStateToProps({ auth }) {
  return {
    userAttributes: at(auth, 'user.user_attributes'),
  };
}

export default connect(mapStateToProps, {})(PermissibleRender);
