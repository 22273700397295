import React from 'react';

import { Typography } from 'antd';

interface CopyableProps {
  text: string;
  copyable?: string;
}

export function Copyable(props: CopyableProps): JSX.Element {
  return <Typography.Paragraph copyable={{ text: props.copyable || props.text }}>{props.text}</Typography.Paragraph>;
}
