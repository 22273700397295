import reduce from 'lodash/reduce';
import find from 'lodash/find';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updatePipeline, deletePipeline, loadPipelinesSettings, editingPipeline, updatePipelineType, updatePipelines, exportPipelineDeals } from 'ReduxActions/pipelineActions';
import PipelineDialog from '../PipelineDialog/PipelineDialog';

import { canForClass } from '../../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';

import PipelineDialogType from '../PipelineDialogType';
import PipelinesTable from '../PipelinesTable/PipelinesTable.js';
import SetPrivacy from '../../../pipeline/SetPrivacy';

const actions = {
  updatePipeline,
  deletePipeline,
  loadPipelinesSettings,
  editingPipeline,
  updatePipelines,
  updatePipelineType,
  exportPipelineDeals,
  canForClass,
};

class PipelineList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      showDetailsType: false,
      showSetPrivacy: false,
      pipeline: null,
      sorting: {
        key: 'name',
        order: 'ASC',
      },
    };

    this.updateTableSort = this.updateTableSort.bind(this);
    this.reloadDataList = this.reloadDataList.bind(this);
    this.showDialog = this.showDialog.bind(this);
    this.showDialogType = this.showDialogType.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.getPipeline = this.getPipeline.bind(this);
    this.findAndUpdatePipeline = this.findAndUpdatePipeline.bind(this);
    this.getPipeline = this.getPipeline.bind(this);
  }

  componentWillMount() {
    this.reloadDataList();
  }

  showDialog(pipeline) {
    this.setState({ pipeline, showDetails: true });
  }

  showDialogType(pipeline) {
    this.setState({ pipeline, showDetailsType: true });
  }

  hideDialog() {
    this.setState({
      pipeline: null,
      showDetails: false,
      showDetailsType: false,
    });
  }

  getPipeline(p) {
    const canUpdatePipeline = this.props.canForClass(PermissionTypeEnum.UpdatePipeline);
    const canUpdatePortfolio = this.props.canForClass(PermissionTypeEnum.UpdatePortfolio);
    const pipeline = this.props.pipelines.find(it => it.pipeline_id === p.pipeline_id);

    ((this.props.isPortfolio && canUpdatePortfolio) || (!this.props.isPortfolio && canUpdatePipeline)) && this.props.editingPipeline(pipeline);
  }

  findAndUpdatePipeline(object) {
    const pipeline = find(this.props.pipelines, {
      pipeline_id: object.pipeline_id,
    });
    return {
      ...pipeline,
      is_portfolio: pipeline.is_portfolio ? 0 : 1,
    };
  }

  findPipeline(object) {
    return find(this.props.pipelines, {
      pipeline_id: object.pipeline_id,
    });
  }

  parsePipelines(pipelines) {
    return pipelines.map(pipeline => {
      return {
        pipeline_id: pipeline.pipeline_id,
        title: pipeline.name,
        stage: pipeline.stages_numbers,
        deals: pipeline.deals_numbers,
        owner: pipeline.owner,
        isPrivate: pipeline.is_private,
        isPortfolio: !!pipeline.is_portfolio,
        createdAt: moment(pipeline.created_at * 1000).format('lll'),
      };
    });
  }

  onRemove(pipeline) {
    this.props.deletePipeline(pipeline);
    this.props.updateSelectedList(pipeline);
  }

  onUpdate(pipeline) {
    this.props.updatePipelines({ pipelines: [pipeline], is_portfolio: pipeline.is_portfolio });
    this.props.updateSelectedList(pipeline);
  }

  render() {
    const { props } = this;
    const pipelines = this.parsePipelines(props.pipelines);
    const UpdatePipeline = props.canForClass(PermissionTypeEnum.UpdatePipeline);
    const UpdatePortfolio = props.canForClass(PermissionTypeEnum.UpdatePortfolio);
    const DeletePipeline = props.canForClass(PermissionTypeEnum.DeletePipeline);
    const DeletePortfolio = props.canForClass(PermissionTypeEnum.DeletePortfolio);
    const ExportPipelineDeals = props.canForClass(PermissionTypeEnum.ExportPipelineDeals);
    const ExportPortfolioDeals = props.canForClass(PermissionTypeEnum.ExportPortfolioDeals);

    return (
      <div>
        <PipelinesTable
          isLoading={props.loading}
          items={pipelines}
          onSortChange={this.updateTableSort}
          onEdit={this.getPipeline}
          onDelete={this.showDialog}
          onUpdate={object => this.showDialogType(this.findAndUpdatePipeline(object))}
          onSetPrivacy={pipe => this.setState({ pipeline: this.findPipeline(pipe), showSetPrivacy: true })}
          onExportDeals={row => props.exportPipelineDeals(row.pipeline_id, row.title)}
          isPortfolio={this.props.isPortfolio}
          onSelectAll={props.onSelectAll}
          onRowSelect={props.onRowSelect}
          UpdatePipeline={UpdatePipeline}
          UpdatePortfolio={UpdatePortfolio}
          DeletePipeline={DeletePipeline}
          DeletePortfolio={DeletePortfolio}
          ExportPipelineDeals={ExportPipelineDeals}
          ExportPortfolioDeals={ExportPortfolioDeals}
        />
        <PipelineDialog
          showDetails={this.state.showDetails}
          onDismiss={this.hideDialog}
          pipeline={this.state.pipeline}
          remove={this.onRemove.bind(this)}
          isPortfolio={this.props.isPortfolio}
        />
        <PipelineDialogType
          showDetails={this.state.showDetailsType}
          onHide={this.hideDialog}
          pipeline={this.state.pipeline}
          isPortfolio={this.props.isPortfolio}
          update={this.onUpdate.bind(this)}
        />
        <SetPrivacy
          show={this.state.showSetPrivacy}
          onDismiss={() => this.setState({ showSetPrivacy: false, pipeline: null })}
          update={obj => {
            this.props.updatePipeline(obj);
          }}
          initialValues={this.state.pipeline}
        />
      </div>
    );
  }

  reloadDataList() {
    if (this.props.isPortfolio) {
      this.props.loadPipelinesSettings(1);
    } else {
      this.props.loadPipelinesSettings(0);
    }
  }

  updateTableSort(sortName, sortOrder) {
    this.setState(
      {
        sorting: { key: sortName, order: sortOrder.toUpperCase() },
      },
      () => {
        this.reloadDataList();
      },
    );
  }
}

function mapStateToProps(state) {
  return {
    pipelines: state.settings.pipelines.all,
    loading: state.settings.pipelines.loading,
  };
}

export default connect(mapStateToProps, actions)(withRouter(PipelineList));
