import classNames from 'classnames';
import { withRouter } from 'react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadNotifications, clearNotifications } from 'ReduxActions/notificationActions';
import NotificationItem from './NotificationItem';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './Notifications.scss';
import { Mixpanel } from 'App/app';

const actions = { loadNotifications, clearNotifications };
class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentWillMount() {
    this.props.loadNotifications();
    document.addEventListener('mouseup', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    try {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.target.offsetParent.id !== 'toggle-notifications-id') this.setState({ isOpen: false });
    } catch (error) {}
  }

  get count() {
    const notifs = this.props.notifications;
    const number = notifs.filter(n => n.seen === 10).length;
    return number < 10 ? number : '+9';
  }

  toggleNotifications() {
    if (!this.state.isOpen) {
      this.props.clearNotifications();
      gaEvent(appCategories.header, appActions(appTypes.notifications, appEvents.opened).dropdown);
    }
    Mixpanel.track('Clicked on Notifications');
    this.setState({ isOpen: !this.state.isOpen });
  }

  redirectTo(item) {
    this.toggleNotifications();
    this.props.router.push(`/deals/${item.deal_id}`);
  }

  render() {
    return (
      <div className='Notifications'>
        <div className='nav navbar-nav navbar-right'>
          <li className={classNames('dropdown', this.state.isOpen && 'open')}>
            <a onClick={this.toggleNotifications} id='toggle-notifications-id'>
              <div className='notifications'>
                {this.count ? <i className='icon-bell notifications-details' data-badge={this.count} /> : <i className='icon-bell notifications-details' />}
              </div>
            </a>
            <ul className='dropdown-menu media-list pull-right' ref={this.setWrapperRef}>
              <li className='dropdown-header'>
                <i className='icon-bell' /> Notifications ({this.props.notifications.length})
              </li>
              {!this.props.notifications.length && (
                <li className='media' id='no-notifications'>
                  <div className='text-muted'>No notifications found</div>
                </li>
              )}
              {this.props.notifications.map((item, index) => {
                return <NotificationItem key={index * 2} {...item} redirect={() => this.redirectTo(item)} />;
              })}
              <li
                className='dropdown-footer text-center cursor-pointer'
                onClick={() => {
                  this.props.router.push('notifications');
                  gaEvent(appCategories.header, appActions(appTypes.notifications + ':VIEW_MORE', appEvents.clicked).item);
                }}
              >
                <a>View more</a>
              </li>
            </ul>
          </li>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notifs.all,
  };
}

export default connect(mapStateToProps, actions)(withRouter(Notifications));
