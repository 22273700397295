import React, { Component } from 'react';
import Editor from '../../ReportingTool/Editor';
import DialogBox from 'SharedComponent/DialogBox';
import Spinner from 'SharedComponent/Spinner';

import domtoimage from 'Utils/domtoimage.js';
import jsPDF from 'jspdf';

import './PdfGenerator.scss';

export default class PdfGenerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      retry: false,
    };
  }

  RecursiveDownLoad(pdf, page, email_body) {
    if (this.props.report.length < page) {
      throw new Error('');
    }

    const node = document.getElementById(`generate_page_${page}`);

    domtoimage
      .toJpeg(node)
      .then(dataUrl => {
        pdf.addImage(dataUrl, 'PNG', 0, 0);
        if (this.props.report.length == page) {
          this.props.shareReport({ file: pdf.output('blob'), ...email_body });

          this.props.onClose();
          this.setState({ retry: false, cancel: false });
        } else {
          pdf.addPage();
          this.RecursiveDownLoad(pdf, page + 1, email_body);
        }
      })
      .catch(e => {
        console.group('error generating report', e);
        this.setState({ retry: true, cancel: true });
        // this.props.onClose();
      });
  }

  downLoadAsPDF(email_body) {
    const pdf = new jsPDF('p', 'mm', 'a4', true);
    this.RecursiveDownLoad(pdf, 1, email_body);
  }

  generatePdf(email_body) {
    setTimeout(() => {
      this.downLoadAsPDF(email_body);
    }, 1000);
  }

  render() {
    if (!this.props.show) return null;
    return (
      <div className='PdfGenerator'>
        <DialogBox isVisible={true} onClickOut={this.props.onClose} onClose={this.props.onClose} withoutClose={true} className='small-dialog'>
          <div className='modal-body'>
            <div className='' style={{ width: '100%' }}>
              <div className='generating-text'>Generating PDF. Please wait...</div>
              <Spinner isLoading={true} />
            </div>
            <div className='generate-hidden-pdf'>
              {this.props.report.map((report, idx) => {
                let objects = { ...report, ...this.props.shared };
                return (
                  <div className='mb-3' key={idx + 1}>
                    <Editor
                      objects={{ ...report, ...this.props.shared }}
                      tools={this.props.tools}
                      loading={false}
                      selected={null}
                      dropElement={() => {}}
                      makeElementActive={() => {}}
                      addToScene={() => {}}
                      loadTemplate={() => {}}
                      loadTemplate={() => {}}
                      updateEditorProps={() => {}}
                      mode='view'
                      editorPageNumber={idx + 1}
                      id={`generate_page_${idx + 1}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </DialogBox>
      </div>
    );
  }
}
