export const userStatus = {
  1: { label: 'Active', color: '#06d26f' },
  2: { label: 'Inactive', color: '#cdcdcd' },
  3: { label: 'Invited', color: '#ffbc34' },
  4: { label: 'Deactivated', color: '#ff7474' },
  9: { label: 'Inactive', color: '#cdcdcd' },
  10: { label: 'Active', color: '#06d26f' },
  11: { label: 'Invited', color: '#ffbc34' },
  // 5: { label: "Deleted", color: "red"},
};
