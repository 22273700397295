/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SortDTO {
  attribute: string;
  /**
   *       3 - Descending order      4 - Ascending order
   */
  direction: SortDTO.DirectionEnum;
}
export namespace SortDTO {
  export type DirectionEnum = 3 | 4;
  export const DirectionEnum = {
    NUMBER_3: 3 as DirectionEnum,
    NUMBER_4: 4 as DirectionEnum,
  };
}
