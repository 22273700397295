import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Image from 'react-bootstrap/lib/Image';
import Label from 'react-bootstrap/lib/Label';
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn';
import ReactTooltip from 'react-tooltip';
import { userStatus } from 'Enums/userStatus';
import Table from 'SharedComponent/Table';
// import { Popconfirm, Icon } from "antd";

import { Permissible } from '../../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';

import DropdownList from 'SharedComponent/DropdownList';
import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './UsersTable.scss';
class UsersTable extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    onEdit: PropTypes.func.isRequired,
    onSortChange: PropTypes.func,
    noDataText: PropTypes.string,
    pagination: PropTypes.bool,
    checkBox: PropTypes.bool,
  };

  static defaultProps = {
    noDataText: 'No users found',
    pagination: false,
    checkBox: true,
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.actionsFormatter = this.actionsFormatter.bind(this);
    this.statusFormatter = this.statusFormatter.bind(this);
  }

  render() {
    const { props, state } = this;
    const isUsers = props.isUsers;
    const isAngelInvestor = props.isAngelInvestor;
    return (
      <div className='UsersTable'>
        <Table
          items={props.items}
          pagination={props.pagination}
          loading={props.isLoading}
          onSortChange={props.onSortChange}
          noDataText={this.emptyData()}
          checkBox={isUsers && props.checkBox}
          onSelectAll={props.onSelectAll}
          sizePerPage={props.sizePerPage}
          page={props.page}
          totalDataSize={props.totalDataSize}
          onPageChange={props.onPageChange}
          onRowSelect={props.onRowSelect}
          tableName={isUsers ? 'users' : 'investors'}
        >
          <TableHeaderColumn dataField='id' isKey hidden>
            ID
          </TableHeaderColumn>

          <TableHeaderColumn dataFormat={this.logoFormatter} dataAlign='left' width='80'>
            Name
          </TableHeaderColumn>

          <TableHeaderColumn dataField='email' dataAlign='left' width='80'>
            Email
          </TableHeaderColumn>

          {!isAngelInvestor && (
            <TableHeaderColumn dataField='role' dataFormat={this.rolesFormatter} dataAlign='left' width='50'>
              Role
            </TableHeaderColumn>
          )}

          {!isAngelInvestor && (
            <TableHeaderColumn dataAlign='left' dataFormat={this.statusFormatter} width='80'>
              Status
            </TableHeaderColumn>
          )}

          {!isUsers && (
            <TableHeaderColumn dataField='phone' dataAlign='left' width='80'>
              Phone
            </TableHeaderColumn>
          )}

          {!isAngelInvestor && (
            <TableHeaderColumn dataField='created_at' dataAlign='left' width='80'>
              Created at
            </TableHeaderColumn>
          )}

          {!isAngelInvestor && (
            <TableHeaderColumn dataField='updated_at' dataAlign='left' width='80'>
              Updated at
            </TableHeaderColumn>
          )}

          {!isAngelInvestor && (
            <TableHeaderColumn dataField='lastLogin' dataAlign='left' width='80'>
              Last login
            </TableHeaderColumn>
          )}

          <TableHeaderColumn dataFormat={this.actionsFormatter} dataAlign='left' width='50' columnClassName='custome-table-outline' isActionColumn>
            Actions
          </TableHeaderColumn>
        </Table>
      </div>
    );
  }
  rolesFormatter = (cell, row) => {
    return <span> {this.props.isUsers ? cell : 'Investor'} </span>;
  };
  actionsFormatter(cell, row) {
    const { props } = this;
    const userId = row.id;
    const isBlocked = row.status === 4;
    const isUser = props.userId === userId;
    const isUsers = props.isUsers;
    const isWealthInvestor = props.isWealthInvestor;
    const isAngelInvestor = props.isAngelInvestor;
    const items = [
      {
        label: 'Resend Invitation',
        icon: 'icon-paper-plane',
        onClick: () => props.resendInvestor([userId]),
        disabled: isAngelInvestor ? !props.ResendAngelInvestorInvitation : !props.ResendInvestorInvitation,
      },
    ];
    const deleteObj = {
      label: 'Delete investor',
      icon: 'icon-trash',
      onClick: () => props.deleteInvestor(row),
      disabled: isAngelInvestor ? !props.DeleteAngelInvestor : !props.DeleteInvestor,
    };
    if (!isAngelInvestor && isWealthInvestor && !isUsers) items.push(deleteObj);

    return (
      <div className='display-flex-end'>
        <ReactTooltip />
        {/*todo permission for angell investor */}
        <Permissible can={isUsers ? PermissionTypeEnum.UpdateUser : isAngelInvestor ? PermissionTypeEnum.UpdateAngelInvestor : PermissionTypeEnum.UpdateInvestor}>
          <i
            className='icon-pencil cursor-pointer'
            onClick={() => {
              gaEvent(appCategories.settings_users, appActions(`${appTypes.edit}`, appEvents.clicked).icon);
              props.onEdit(row, isUsers ? 'users' : isAngelInvestor ? 'angel-investors' : 'investors');
            }}
            style={{ width: '36px' }}
            data-tip={isUsers ? 'Edit user' : 'Edit investor'}
          />
        </Permissible>

        {isAngelInvestor && (
          <Fragment>
            <Permissible can={PermissionTypeEnum.DeleteAngelInvestor}>
              <i className='icon-trash cursor-pointer' onClick={() => props.deleteInvestor(row)} style={{ width: '36px' }} data-tip={'Delete investor'} />
            </Permissible>
            <Permissible can={PermissionTypeEnum.CreateAngelInvestor}>
              <i className='icon-paper-plane cursor-pointer' onClick={() => props.resendInvestor([userId])} style={{ width: '36px' }} data-tip={'Resent invitation'} />
            </Permissible>
          </Fragment>
        )}
        {!isUsers && isWealthInvestor && <i className='icon-share-alt cursor-pointer' data-tip='Export SOA' style={{ width: '36px' }} onClick={() => props.exportSoa(row)} />}
        {!isUsers && !isAngelInvestor && (
          <div className=''>
            <DropdownList positionFixed icon='more_vert' items={items} tooltip='More actions' />
          </div>
        )}
        {/* {
          !isUsers && (
            <Popconfirm
              title="Are you sure you want to delete this investor ?"
              placement="topLeft"
              onConfirm={() => props.deleteInvestor(userId)}
              okText="Delete"
              okType="danger"
              cancelText="Cancel"
              icon={<Icon type="delete" style={{ color: "red" }} />}
            >
              <i
                className="icon-trash cursor-pointer"
                data-tip="Delete investor"
                style={{ width: "36px" }}
              />
            </Popconfirm>
          )
        } */}
        {isUsers && (
          <Permissible can={PermissionTypeEnum.CreateUser}>
            <i
              className='icon-paper-plane cursor-pointer'
              onClick={() => {
                gaEvent(appCategories.settings_users, appActions(`${appTypes.resend}`, appEvents.clicked).icon);
                props.resendInvitation(row);
              }}
              style={{ width: '36px' }}
              data-tip='Resend invitation'
            />
          </Permissible>
        )}
        {(isUsers ? !isUser : false) /* see with @ahmed for this permission */ && (
          <Permissible can={isUsers ? PermissionTypeEnum.BlockUnblockUser : PermissionTypeEnum.BlockUnblockInvestor}>
            <i
              className={` ${isBlocked ? 'icon-lock-open' : 'icon-lock'} cursor-pointer`}
              onClick={() => {
                if (isBlocked) {
                  gaEvent(appCategories.settings_users, appActions(`${appTypes.unblock}`, appEvents.clicked).icon);
                  return props.unblockUser(userId);
                }
                gaEvent(appCategories.settings_users, appActions(`${appTypes.block}`, appEvents.clicked).icon);
                return props.blockUser(row);
              }}
              style={{ width: '36px' }}
              data-tip={isBlocked ? 'Unblock' : 'Block'}
            />
          </Permissible>
        )}
      </div>
    );
  }

  statusFormatter(cell, row) {
    if (userStatus[row.status]) {
      return <span style={{ color: userStatus[row.status].color }}>{userStatus[row.status].label}</span>;
    } else return null;
  }

  logoFormatter(cell, row) {
    return (
      <div>
        <Image
          src={row.logo}
          alt='Logo'
          style={{
            height: '35px',
            margin: '0 auto',
            width: '35px',
            borderRadius: '50%',
          }}
          circle
        />
        <span style={{ marginLeft: '24px' }}>{row.name}</span>
      </div>
    );
  }

  checkBoxFormatter(cell, row) {
    return <Label bsStyle='success'>check</Label>;
  }

  emptyData() {
    return (
      <div className='m-4'>
        {this.props.isLoading ? (
          <div className='custom-spinner'>
            <div id='custom-spinner-id' />
          </div>
        ) : (
          <div className='m-4'>
            <div className='empty-Task'>No Data</div>
          </div>
        )}
      </div>
    );
  }
}

export default UsersTable;
