import { MigratePipedriveDataRequestDTO } from '../../../../generated/model/migratePipedriveDataRequestDTO';

export interface PipedriveForCreateModel {
  token: string;
}

export namespace PipedriveForCreateModel {
  export function mapToApi(value: PipedriveForCreateModel): MigratePipedriveDataRequestDTO {
    return {
      token: value.token,
    };
  }
}
