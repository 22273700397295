import { createSelector } from '@reduxjs/toolkit';

import { StateV2 } from '../../../../../store/v2.type';
import * as fromNewsActions from './news.reducer';

const dashboardNewsState = (state: StateV2) => state.v2.dashboardNews;

export const getNewsLoading = createSelector(dashboardNewsState, state => state.News.loading);
export const getNewsMeta = createSelector(dashboardNewsState, state => state.News.meta);
export const newsStateSelector = createSelector(dashboardNewsState, state => state.News);
export const getNewsList = createSelector(newsStateSelector, fromNewsActions.selectAllNews);
export const savedNewsStateSelector = createSelector(dashboardNewsState, state => state.SavedNews);
export const getSavedNewsList = createSelector(savedNewsStateSelector, fromNewsActions.selectAllSavedNews);
export const getSaveNewsLoading = createSelector(dashboardNewsState, state => state.News.saveLoading);
export const getSavedNewsMeta = createSelector(dashboardNewsState, state => state.SavedNews.meta);
export const getSavedNewsLoading = createSelector(dashboardNewsState, state => state.SavedNews.loading);
export const getRemoveNewsLoading = createSelector(dashboardNewsState, state => state.SavedNews.removeLoading);
export const tagsStateSelector = createSelector(dashboardNewsState, state => state.Tags);
export const getTagsMeta = createSelector(dashboardNewsState, state => state.Tags.meta);
export const getTagsList = createSelector(tagsStateSelector, fromNewsActions.selectAllTags);
export const getTagsLoading = createSelector(dashboardNewsState, state => state.Tags.loading);
export const getAddTagsLoading = createSelector(dashboardNewsState, state => state.Tags.addTagLoading);
