import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import * as validators from 'Utils/validators';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import SwitchField from 'SharedComponent/CustomSwitch/SwitchField';
import TextField from 'SharedComponent/TextField';
import SelectComponent from 'SharedComponent/SelectOption/SelectComponent';

const selector = formValueSelector('set-privacy-pipeline-form');
function SetPrivacy(props) {
  const { isPrivate, handleSubmit, submitting, pristine, show, initialValues } = props;
  const [users, setUsers] = useState(props.users);

  const submit = values => {
    props.update(values);
    onClose();
  };

  const onClose = () => {
    props.onDismiss();
    props.reset();
  };

  const closeModal = () => {
    onClose();
  };

  const onClickCancelBtn = () => {
    onClose();
  };

  const onCloseDialogBox = () => {
    onClose();
  };

  const onSearchUser = value => {
    if (!value) {
      setUsers(props.users);
      return;
    }
    const filteredUsers = users.filter(user => user.profile && user.profile.fullname && user.profile.fullname.toLowerCase().includes(value.toLowerCase()));
    setUsers(filteredUsers);
  };

  const parseUsers = users => {
    return users.map(user => ({
      id: user.id,
      label: user.profile && user.profile.fullname,
      image: user.profile && user.profile.image,
    }));
  };

  const isPortfolio = initialValues && initialValues.is_portfolio;

  return (
    <div className='AddPipeline'>
      <div className='dialog-container'>
        <DialogBox isVisible={show} title='Set Privacy' onClickOut={closeModal} onClose={onCloseDialogBox} className='small-dalog'>
          <form style={{ padding: '16px 32px 16px 32px' }} onSubmit={handleSubmit(submit)}>
            <div className='mb-3'>
              <Field
                label={isPortfolio ? 'Portfolio Name' : 'Pipeline Name'}
                name={`name`}
                component={TextField}
                type='text'
                placeholder={isPortfolio ? "Enter portfolio's name" : "Enter pipeline's name"}
                required
                validate={[validators.required]}
                labelClass='font-size-14 font-weight-500 label-color'
              />
            </div>
            <div className='mb-2'>
              <Field
                label={`Private ${isPortfolio ? 'Portfolio' : 'Pipeline'} ?`}
                labelClass='font-size-14 font-weight-500 label-color ml-2'
                name='is_private'
                component={SwitchField}
                format={value => (value ? true : false)}
              />
            </div>
            {isPrivate && (
              <div className='mb-2'>
                <Field
                  name='access_user_ids'
                  component={SelectComponent}
                  onSearch={onSearchUser}
                  options={parseUsers(users)}
                  placeholder={`Link users to ${isPortfolio ? 'Portfolio' : 'Pipeline'}`}
                  notFoundContent='No users found'
                />
              </div>
            )}
            <div className='display-flex-end'>
              <div className='m-3'>
                <SubmitButton
                  label='Cancel'
                  icon='icon-close'
                  style={{
                    width: '150px',
                    color: '#53627c',
                    backgroundColor: '#fff',
                    border: '1px solid #e1e4e8',
                  }}
                  onClick={() => onClickCancelBtn()}
                />
              </div>
              <div className='m-3'>
                <Button disabled={submitting || pristine} type='submit' className='submit-btn-p'>
                  <i className='icon-check mr-2' />
                  UPDATE
                </Button>
              </div>
            </div>
          </form>
        </DialogBox>
      </div>
    </div>
  );
}

SetPrivacy = reduxForm({ form: 'set-privacy-pipeline-form', enableReinitialize: true })(SetPrivacy);

SetPrivacy = connect((state, ownProps) => {
  return {
    users: state.settings.users.all,
    isPrivate: selector(state, 'is_private'),
  };
}, {})(SetPrivacy);

export default SetPrivacy;
