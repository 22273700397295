import React from 'react';
import { Tooltip } from 'antd';
import { Field } from 'redux-form';

import { numberFormatter, normalizeNumber } from 'Utils/objectUtils';

import NumberField from 'SharedComponent/NumberField';
import TextField from 'SharedComponent/TextField';

export default function CoInvestors({ fields }) {
  return (
    <div className='row align-items-end'>
      <div className='col-md-11'>
        {fields.map((item, index) => (
          <li className='' key={index}>
            <CoInvestor index={index} item={item} onRemove={() => fields.remove(index)} />
          </li>
        ))}
      </div>
      <div className='col-md-1'>
        <Tooltip title='Add new co-investor'>
          <i className='icon-plus cursor-pointer' onClick={() => fields.push({ name: '', invested_amount: '' })} />
        </Tooltip>
      </div>
    </div>
  );
}

const CoInvestor = props => (
  <div className='row align-items-center'>
    <div className='col-md-6'>
      <Field name={`${props.item}.name`} component={TextField} placeholder='Name' />
    </div>
    <div className='col-md-5'>
      <Field
        name={`${props.item}.invested_amount`}
        component={NumberField}
        addonAfter='USD'
        placeholder='Invested amount'
        format={input => numberFormatter(input)}
        normalize={input => normalizeNumber(input)}
      />
    </div>
    <div className='col-md-1'>
      <Tooltip title='Remove co-investor'>
        <i className='icon-trash cursor-pointer' onClick={props.onRemove} />
      </Tooltip>
    </div>
  </div>
);
