import { PipedriveForCreateModel } from '../../models/settings/integrations/pipedrive/pipedrive-for-create';
import { IMap } from '../../utils/map-utils';

export enum PipedriveFormNamesEnum {
  Token = 1,
}

export namespace PipedriveFormNamesEnum {
  export type formFieldName = keyof PipedriveForCreateModel;

  export function toString(value: PipedriveFormNamesEnum): string {
    switch (value) {
      case PipedriveFormNamesEnum.Token:
        return 'Token';
      default:
        return null;
    }
  }

  export const formFields: IMap<PipedriveFormNamesEnum, formFieldName> = new IMap<PipedriveFormNamesEnum, formFieldName>([[PipedriveFormNamesEnum.Token, 'token']]);
}
