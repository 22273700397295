//@ts-ignore
import { Mixpanel } from 'App/app';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, PutEffect, select, takeLatest } from 'redux-saga/effects';
import { all, SelectEffect } from '@redux-saga/core/effects';

import { TaskForCreate } from '../../../core/models/__task/task-for-create';
import { TasksSummaryData } from '../../../core/models/__task/tasks-summary-data';
import { TaskDetailsData } from '../../../core/models/__task/task-details-data';
import { TaskForUpdate } from '../../../core/models/__task/task-for-update';
import { LazyLoadResponse } from '../../../core/models/queries/lazy-response.model';
import {
  addCalenderTask,
  addTask,
  getTaskByUuid,
  getTasksListCalender,
  updateCalenderTask,
  updateTask,
  updateTaskStatus,
  votForTask,
  zoomUrl,
  deleteTask,
} from '../../../core/services/tasks.service';
import { getTasksList } from '../../../core/services/tasks.service';
import { TaskQueryRequest } from '../../../core/models/__task/task-query-request';
import { MeetingInfoData } from '../../../core/models/meeting/meeting-info-data';
import { TaskStatusEnum } from '../../../core/enums/task/task-status.enum';
import { UpdateTaskStatus } from '../../../core/models/__task/task-for-status';
import { ModalIdEnum } from '../../../core/enums/entity-ids/modal-id.enum';
import { TaskForVote } from '../../../core/models/__task/task-for-vote';
import { ErrorResponseData } from '../../../core/models/http-params.type';
import { MetaQuery } from '../../../core/models/queries/meta-query.model';
import { TaskQueryCalenderRequest } from '../../../core/models/calendar/calendar-query-request';
import { BaseCalenderQuery } from '../../../core/models/queries/base-calender-query';
import { TasksCalenderData } from '../../../core/models/calendar/tasks-calender-data';

import { NotificationTypeEnum } from '../../../shared/enums/notification-type.enum';
import { notification } from '../../../shared/components';
import { closeModal, openModal, setEditMode, setLoading, setSubmitted } from '../../../shared/components/Modal/state/modal.actions';

import * as tasksAction from './tasks.actions';
import { getCurrentDate, getTaskMeta } from './tasks.selectors';

function* loadTasksListEffect(): Generator<Promise<LazyLoadResponse<TasksSummaryData[]>> | PutEffect, void, LazyLoadResponse<TasksSummaryData[]>> {
  try {
    const getTasksFilters = TaskQueryRequest.queries();
    const tasks = yield getTasksList({ body: TaskQueryRequest.mapToApiValue(getTasksFilters) });

    yield put(tasksAction.loadAllTasksListSuccess({ list: tasks.results, meta: tasks._meta, reset: true }));
  } catch (error) {
    yield put(tasksAction.loadAllTasksListFail(error));
  }
}

function* loadTaskNextPageEffect({
  payload: { currentPage },
}: PayloadAction<{ currentPage: number }>): Generator<Promise<LazyLoadResponse<TasksSummaryData[]>> | PutEffect, void, LazyLoadResponse<TasksSummaryData[]>> {
  try {
    const getTasksFilters = TaskQueryRequest.queries();
    const tasks = yield getTasksList({ body: TaskQueryRequest.mapToApiValue({ ...getTasksFilters, page: currentPage }) });
    yield put(tasksAction.loadAllTasksListSuccess({ list: tasks.results, meta: tasks._meta, reset: false }));
  } catch (error) {
    yield put(tasksAction.loadAllTasksListFail(error));
  }
}

function* addTaskEffect({ payload: { task } }: PayloadAction<{ task: TaskDetailsData }>): Generator {
  try {
    yield put(setSubmitted({ submitted: true }));
    yield addTask({ body: TaskForCreate.mapToApiValue(task) });
    yield put(tasksAction.addTaskSuccess());
    yield put(closeModal());
    Mixpanel.track(`Task created ${task.type}`);

    notification({ type: NotificationTypeEnum.Success, message: 'Task created successfully' });
  } catch (error) {
    Mixpanel.track(`Task created failed ${task.type}`);
    yield put(tasksAction.addTaskFail(error));
  }
}

function* updateTaskEffect({ payload: { task } }: PayloadAction<{ task: TaskDetailsData }>): Generator<Promise<TasksSummaryData> | PutEffect, void, TasksSummaryData> {
  try {
    yield put(setSubmitted({ submitted: true }));
    const taskUpdate = yield updateTask(task.uuid, { body: TaskForUpdate.mapToApiValue(task) });
    yield put(tasksAction.updateTaskSuccess(taskUpdate));
    yield put(closeModal());
    Mixpanel.track(`Task updated ${task.type}`);
    notification({ type: NotificationTypeEnum.Info, message: 'Task updated successfully' });
  } catch (error) {
    Mixpanel.track(`Task updated failed ${task.type}`);
    yield put(tasksAction.updateTaskFail(error));
  }
}

function* updateStatusEffect({ payload: { status, uuid } }: PayloadAction<UpdateTaskStatus>): Generator<Promise<TasksSummaryData> | PutEffect, void, TasksSummaryData> {
  try {
    const task = yield updateTaskStatus(uuid, TaskStatusEnum.convertToApiValue.getValue(status));
    yield put(tasksAction.updateTaskSuccess(task));
    notification({ type: NotificationTypeEnum.Success, message: 'Task updated successfully' });
  } catch (error) {
    notification({ type: NotificationTypeEnum.Error, message: error.message });
  }
}

function* loadTaskEffect(action: PayloadAction<{ uuid: string }>): Generator<Promise<TaskDetailsData> | PutEffect, void, TaskDetailsData> {
  try {
    yield put(setEditMode({ editMode: true }));
    yield put(openModal({ modalId: ModalIdEnum.addTasks }));
    yield put(setLoading({ loading: true }));
    const task = yield getTaskByUuid(action.payload.uuid);
    yield put(tasksAction.getTaskSuccess({ task }));
    yield put(setLoading({ loading: false }));
  } catch (error) {
    yield put(tasksAction.getTaskFail(error));
    yield put(setLoading({ loading: false }));
  }
}

function* createOrUpdateTaskEffect(): Generator<SelectEffect | PutEffect, void, MetaQuery> {
  try {
    const meta = yield select(getTaskMeta);
    yield put(tasksAction.resetTasks());
    yield put(tasksAction.loadAllTasksNextPage({ currentPage: meta.currentPage }));
  } catch (error) {}
}

function* getZoomUrlEffect({ payload }: PayloadAction<MeetingInfoData>): Generator<Promise<string> | PutEffect, void, string> {
  try {
    const url = yield zoomUrl(payload);

    window.open(url, '_blank');
  } catch (error) {
    yield put(tasksAction.getZoomUrlFail(error));
  }
}

function* voteForTaskEffect({ payload }: PayloadAction<TaskForVote>): Generator<Promise<TasksSummaryData> | PutEffect, void, TasksSummaryData> {
  try {
    const task = yield votForTask(payload);

    yield put(tasksAction.updateTaskSuccess(task));
    notification({ type: NotificationTypeEnum.Success, message: `Task voted: ${payload.vote ? 'YES' : 'NO'}` });
  } catch (error) {
    yield put(tasksAction.voteForTaskFail(error));
  }
}

function* loadCalenderTaskListEffect(): Generator<Promise<TasksCalenderData[]> | PutEffect | SelectEffect, void, BaseCalenderQuery & TasksCalenderData[]> {
  try {
    const currentDate = yield select(getCurrentDate);
    const getTasksFilters = TaskQueryCalenderRequest.queries();
    const tasks = yield getTasksListCalender({ body: TaskQueryCalenderRequest.mapToApiValue({ ...getTasksFilters, mode: currentDate.mode, date: currentDate.date }) });
    yield put(tasksAction.loadCalenderTasksListSuccess({ list: tasks, currentDate: currentDate }));
  } catch (error) {
    yield put(tasksAction.loadAllTasksListFail(error));
  }
}
function* getTakCalenderEffect({ payload: { Date } }: PayloadAction<{ Date: number }>): Generator<PutEffect, void, TaskDetailsData> {
  try {
    const task = {} as TaskDetailsData;
    yield put(openModal({ modalId: ModalIdEnum.addTasks }));
    yield put(setLoading({ loading: true }));
    yield put(tasksAction.getTaskSuccess({ task: { ...task, dueDate: Date } }));
    yield put(setLoading({ loading: false }));
  } catch (error) {
    yield put(tasksAction.getTaskFail(error));
  }
}

function* loadCalenderTaskCurrentDateEffect({
  payload: { currentDate },
}: PayloadAction<{ currentDate: BaseCalenderQuery }>): Generator<Promise<TasksCalenderData[]> | PutEffect | SelectEffect, void, TasksCalenderData[]> {
  try {
    const getTasksFilters = TaskQueryCalenderRequest.queries();
    const tasks = yield getTasksListCalender({ body: TaskQueryCalenderRequest.mapToApiValue({ ...getTasksFilters, mode: currentDate.mode, date: currentDate.date }) });
    yield put(tasksAction.loadCalenderTasksListSuccess({ list: tasks, currentDate: currentDate }));
  } catch (error) {
    yield put(tasksAction.loadAllTasksListFail(error));
  }
}
function* addCalenderTaskEffect({ payload: { task } }: PayloadAction<{ task: TaskDetailsData }>): Generator<Promise<TasksCalenderData> | PutEffect, void, TasksCalenderData> {
  try {
    yield put(setSubmitted({ submitted: true }));
    const tasks = yield addCalenderTask({ body: TaskForCreate.mapToApiValue(task) });
    yield put(tasksAction.addTaskCalenderSuccess(tasks));
    yield put(setSubmitted({ submitted: false }));
    yield put(closeModal());
    notification({ type: NotificationTypeEnum.Success, message: 'Task created successfully' });
  } catch (error) {
    yield put(tasksAction.addTaskFail(error));
  }
}

function* deleteTaskEffect({ payload: { uuid } }: PayloadAction<{ uuid: string }>): Generator {
  try {
    yield deleteTask(uuid);
    yield put(tasksAction.deleteTaskSuccess({ uuid }));

    notification({ type: NotificationTypeEnum.Success, message: 'Task deleted successfully' });
  } catch (error) {
    yield put(tasksAction.deleteTaskFail(error));
  }
}

function* updateCalenderTaskEffect({ payload: { task } }: PayloadAction<{ task: TaskDetailsData }>): Generator<Promise<TasksCalenderData> | PutEffect, void, TasksCalenderData> {
  try {
    yield put(setSubmitted({ submitted: true }));
    const taskUpdate = yield updateCalenderTask(task.uuid, { body: TaskForUpdate.mapToApiValue(task) });
    yield put(tasksAction.updateCalenderTaskSuccess(taskUpdate));
    yield put(closeModal());
    notification({ type: NotificationTypeEnum.Info, message: 'Task updated successfully' });
  } catch (error) {
    yield put(tasksAction.updateTaskFail(error));
  }
}

function* failEffect({ payload }: PayloadAction<ErrorResponseData>): Generator {
  try {
    notification({ type: NotificationTypeEnum.Error, message: payload.message });
  } catch (error) {}
}

export function* watchTasks(): Generator {
  yield all([
    takeLatest(tasksAction.loadAllTasksList, loadTasksListEffect),
    takeLatest(tasksAction.addTask, addTaskEffect),
    takeLatest(tasksAction.addTaskCalender, addCalenderTaskEffect),
    takeLatest(tasksAction.getTask.type, loadTaskEffect),
    takeLatest(tasksAction.updateTask.type, updateTaskEffect),
    takeLatest(tasksAction.updateCalenderTask.type, updateCalenderTaskEffect),
    takeLatest(tasksAction.updateStatus.type, updateStatusEffect),
    takeLatest(tasksAction.getZoomUrl.type, getZoomUrlEffect),
    takeLatest(tasksAction.voteForTask.type, voteForTaskEffect),
    takeLatest(tasksAction.loadAllTasksNextPage.type, loadTaskNextPageEffect),
    takeLatest(tasksAction.getTaskCalender.type, getTakCalenderEffect),
    takeLatest(tasksAction.addTaskSuccess.type, createOrUpdateTaskEffect),
    takeLatest(tasksAction.loadCalenderTasksList.type, loadCalenderTaskListEffect),
    takeLatest(tasksAction.loadCalenderTasksCurrentDate.type, loadCalenderTaskCurrentDateEffect),
    takeLatest([tasksAction.loadAllTasksListFail.type, tasksAction.addTaskFail.type, tasksAction.updateTaskFail.type], failEffect),
    takeLatest(tasksAction.deleteTask.type, deleteTaskEffect),
    takeLatest([tasksAction.loadAllTasksListFail.type, tasksAction.addTaskFail.type, tasksAction.deleteTaskFail.type, tasksAction.updateTaskFail.type], failEffect),
  ]);
}
