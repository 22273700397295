import { Notifier } from 'SharedComponent/Notifier';
import omit from 'lodash/omit';
import api from './api';
export const EVENTS_LOADED = 'calendar:events:loading';
export const LOADING_EVENTS = 'calendar:events:loaded';
export const EVENTS_CREATED = 'calendar:events:created';
export const EVENT_UPDATED = 'calendar:events:updated';
export const EVENT_DELETED = 'calendar:events:deleted';
export const EDITING_EVENT = 'calendar:event:editing';
export const MODAL_DISMISS = 'calendar:event:dismiss';

function normalizeEvent(eventData) {
  const today = new Date(eventData.from);
  today.setHours(0, 0, 0, 0);
  return {
    ...omit(eventData, ['date', 'from', 'to', 'start']),
    start_date: Math.trunc((eventData.start.getTime() + (eventData.from.getTime() - today.getTime())) / 1000),
    end_date: Math.trunc((eventData.start.getTime() + eventData.to.getTime() - today.getTime()) / 1000),
    attendees: {
      ids: eventData.attendees ? eventData.attendees.split(',') : [],
    },
  };
}

export function loadEvents() {
  return dispatch => {
    dispatch({ type: LOADING_EVENTS, payload: true });
    api
      .get('/events')
      .then(({ data }) => {
        dispatch({ type: EVENTS_LOADED, payload: data.results });
      })
      .catch(error => {
        dispatch({ type: LOADING_EVENTS, payload: false });
      });
  };
}

export function createEvent(event) {
  return dispatch => {
    dispatch({ type: LOADING_EVENTS, payload: true });
    // const event = normalizeEvent(object);
    api
      .post('/events', event)
      .then(({ data }) => {
        dispatch({ type: EVENTS_CREATED, payload: data });
      })
      .catch(error => {
        dispatch({ type: LOADING_EVENTS, payload: false });
      });
  };
}

export function updateEvent(event) {
  return dispatch => {
    dispatch({ type: LOADING_EVENTS, payload: true });
    // const event = normalizeEvent(object);
    api
      .put(`/events/${event.id}`, event)
      .then(({ data }) => {
        dispatch({ type: EVENT_UPDATED, payload: data });
      })
      .catch(error => {
        dispatch({ type: LOADING_EVENTS, payload: false });
      });
  };
}

export function addEvent(event) {
  return dispatch => {
    dispatch({ type: LOADING_EVENTS, payload: true });
    api
      .post('/events', event)
      .then(({ data }) => {
        dispatch({ type: EVENTS_CREATED, payload: data });
      })
      .catch(({ response }) => {
        Notifier({
          type: 'error',
          title: 'Error',
          message: response && response.data && response.data.message,
        });
        dispatch({ type: LOADING_EVENTS, payload: false });
      });
  };
}

export function editEvent(event) {
  return dispatch => {
    dispatch({ type: LOADING_EVENTS, payload: true });
    api
      .put(`/events/${event.event_id}`, event)
      .then(({ data }) => {
        dispatch({ type: EVENT_UPDATED, payload: data });
      })
      .catch(error => {
        dispatch({ type: LOADING_EVENTS, payload: false });
      });
  };
}

export function editing(event) {
  return dispatch => {
    dispatch({
      type: EDITING_EVENT,
      payload: event,
    });
  };
}

export function dismissModal() {
  return dispatch => {
    dispatch({ type: MODAL_DISMISS });
  };
}
