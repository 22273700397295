import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';

import { configureSagaStore } from './v2.0/store/index';

import ReactGA from 'react-ga';
import localeData from './translations/data.json';

import routes from './routes';

import 'antd/dist/antd.css';
import './assets/styles/main.scss';
import './assets/styles/default.scss';
import './v2.0/assets/scss/fonts.scss';
import './v2.0/assets/scss/default-v2.0.scss';
import 'antd/dist/antd.css';
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { initializePaddle } from '@paddle/paddle-js';
import { setPaddle } from './actions/billingActions';
import { connect } from 'react-redux';

Hotjar.init(process.env.HOTJAR_ID, process.env.HOTJAR_VERSION);

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_UA);

if (process.env.NODE_ENV === 'production') {
  mixpanel.init(process.env.MIXPANEL_TOKEN, { track_pageview: 'full-url' });
}

let mixpanelActions = {
  identify: id => {
    if (process.env.NODE_ENV === 'production') {
      mixpanel.identify(id);
    }
  },
  track: (name, data) => {
    if (process.env.NODE_ENV === 'production') {
      mixpanel.track(name, data);
    }
  },
};

export let Mixpanel = mixpanelActions;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [],
  });
}

function App(props) {
  const [paddleRoot, setPaddleRoot] = React.useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const queryPaddle = urlParams.get('_ptxn');

  useEffect(() => {
    if (queryPaddle && paddleRoot) {
      paddleRoot.Checkout.open({
        transactionId: queryPaddle,
      });
    }
  }, [paddleRoot]);

  React.useEffect(() => {
    initializePaddle({
      environment: process.env.NODE_ENV !== 'production' ? 'sandbox' : 'production',
      token: process.env.PADDLE_TOKEN,
      eventCallback: event => {
        if (event.name === 'checkout.completed') {
          const products = event.data.items.map(item => {
            return {
              id: item.product.id,
              price_id: item.price_id,
            };
          });
          const payload = {
            checkout: {
              id: event.data.id,
            },
            product: {
              id: products[0].id,
              price_id: products[0].price_id,
            },
          };
          props.callbackSubscription(payload);
        }
      },
    }).then(paddleInstance => {
      if (paddleInstance) {
        configureSagaStore.dispatch(setPaddle(paddleInstance));
        setPaddleRoot(paddleInstance);
        // configureSagaStore.dispatch({ type: 'SET_PADDLE', payload: paddleInstance });
        // setPaddle(paddleInstance);
      }
    });
  }, []);

  return (
    <div>
      <IntlProvider locale={'en-US'} messages={localeData.en}>
        <Provider store={configureSagaStore}>{routes(configureSagaStore)}</Provider>
      </IntlProvider>
    </div>
  );
}

const actions = { setPaddle };

ReactDOM.render(<App />, document.querySelector('#root'));
