import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import * as validators from 'Utils/validators';
import InputCustomFields from 'SharedComponent/functions/InputCustomFields';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';
import TextField from 'SharedComponent/TextField';
import { CustomCheckbox } from 'SharedComponent/CustomCheckbox';
import ImageUpload from 'SharedComponent/ImageUpload';
import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';
import { Collapse } from 'reactstrap';

import './AddContact.scss';
import { Mixpanel } from 'App/app';

class AddContact extends Component {
  static propTypes = {
    showDetails: PropTypes.bool,
    onDismiss: PropTypes.func,
  };

  static defaultProps = {
    showDetails: false,
    onDismiss: new Function(),
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      image: null,
      collapse: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps.updating !== this.props.updating) {
      this.setState({
        image: this.props.updating && this.props.updating.image,
      });
    }
  }

  closeModal() {
    this.props.initialize(null);
    this.setState({ isLoading: false, image: null });
    this.props.reset();
    this.props.onDismiss();
  }

  submit(values) {
    const contact = {
      ...values,
      visibility: values.is_private ? 0 : 1,
    };
    delete contact.is_private;

    if (this.props.updating) {
      this.props.update({ ...contact, image: this.state.image });
      gaEvent(appCategories.contacts, appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.save}`, appEvents.clicked).button);
      Mixpanel.track('Contact Updated');
    } else {
      this.props.onSave({ ...contact, image: this.state.image });
      gaEvent(appCategories.contacts, appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.save}`, appEvents.clicked).button);
      Mixpanel.track('Contact Added');
    }

    this.closeModal();
  }

  onCloseDialogBox() {
    if (this.props.updating) {
      gaEvent(appCategories.contacts, appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
    } else {
      gaEvent(appCategories.contacts, appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
    }
    this.closeModal();
  }

  onClickCancelBtn() {
    if (this.props.updating) {
      gaEvent(appCategories.contacts, appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    } else {
      gaEvent(appCategories.contacts, appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    }
    this.closeModal();
  }
  handleImageUpload = image => {
    this.setState({ image });
  };
  handleImageLoading = isLoading => {
    this.setState({ isLoading });
  };
  render() {
    const { handleSubmit, submitting, updating, customFields } = this.props;
    const onSubmit = handleSubmit(this.submit);
    if (!this.props.showDetails) return null;
    return (
      <div className='AddContact'>
        <DialogBox isVisible onClickOut={this.closeModal} onClose={() => this.onCloseDialogBox()} className='' title={updating ? 'Update Contact' : 'New Contact'}>
          <form className='m-3' onSubmit={onSubmit}>
            <div className='display-flex-center'>
              <div className='flex-grow-1'>
                <ImageUpload
                  appCategory={appCategories.contacts}
                  value={(this.props.initialValues && this.props.initialValues.image) || this.state.image}
                  onChange={this.handleImageUpload}
                  loading={this.handleImageLoading}
                />
              </div>
              <div className='flex-grow-2'>
                <Field label='First name' name={`first_name`} component={TextField} type='text' placeholder='Add First name' required validate={[validators.required]} />
              </div>
              <div className='flex-grow-2'>
                <Field label='Last name' name={`last_name`} component={TextField} type='text' placeholder='Add Last name' required validate={[validators.required]} />
              </div>
            </div>
            <div className='row margin-null'>
              <div className='col-md-6'>
                <Field label='Company' name={`company`} component={TextField} type='text' placeholder='Add Company' />
              </div>
              <div className='col-md-6'>
                <Field label='Title' name={`title`} component={TextField} type='text' placeholder='Add Title' />
              </div>
            </div>
            <div className='row margin-null mt-3'>
              <div className='col-md-6'>
                <Field label='Email' name={`email`} component={TextField} type='text' placeholder='Add Email' />
              </div>
              <div className='col-md-6'>
                <Field label='Phone Number' name={`phone`} component={TextField} type='text' placeholder='Add Phone' />
              </div>
            </div>
            <div className='row margin-null mt-3'>
              {customFields.map(customField => (
                <InputCustomFields customField={customField} key={customField.field_name} classLabel='' />
              ))}
            </div>
            <div className='display-flex-start'>
              <Field
                label='Private contact?'
                name={`is_private`}
                component={CustomCheckbox}
                format={value => (value ? true : false)}
                parse={value => (value ? 1 : 0)}
                tooltip='This will make the contact visible to you only.'
              />
            </div>
            <div className='division display-flex-between'>
              <div className='line l' />
              <span className='show-more' onClick={() => this.setState(state => ({ collapse: !state.collapse }))}>
                {this.state.collapse ? <i className='icon-arrow-up' /> : 'show more'}
              </span>
            </div>
            <Collapse isOpen={this.state.collapse}>
              <div className='display-flex-center'>
                <div className='flex-grow-1'>
                  <Field label='Facebook' name={`facebook`} component={TextField} type='text' placeholder='Add Facebook Link' />
                </div>
                <div className='flex-grow-1'>
                  <Field label='Linkedin' name={`linkedin`} component={TextField} type='text' placeholder='Add Linkedin Link' />
                </div>
              </div>
              <div className='display-flex-center'>
                <div className='flex-grow-1'>
                  <Field label='Twitter' name={`twitter`} component={TextField} type='text' placeholder='Add twitter Link' />
                </div>
                <div className='flex-grow-1'>
                  <Field label='Skype' name={`skype`} component={TextField} type='text' placeholder='Add Skype ID' />
                </div>
              </div>
            </Collapse>
            <div className='display-flex-end'>
              <div className='m-3'>
                <SubmitButton
                  label='Cancel'
                  icon='icon-close'
                  style={{
                    width: '150px',
                    color: '#53627c',
                    backgroundColor: '#fff',
                    border: '1px solid #e1e4e8',
                  }}
                  onClick={() => this.onClickCancelBtn()}
                />
              </div>
              <div className='m-3'>
                <Button disabled={submitting} type='submit' className='submit-btn'>
                  <i className='icon-check mr-2' />
                  SAVE
                </Button>
              </div>
            </div>
          </form>
        </DialogBox>
      </div>
    );
  }
}

AddContact = reduxForm({ form: 'add-new-contact', enableReinitialize: true })(AddContact);

AddContact = connect(state => ({
  updating: state.contacts.editing,
  initialValues: {
    ...state.contacts.editing,
    is_private: state.contacts.editing ? !state.contacts.editing.visibility : false,
  },
}))(AddContact);

export default AddContact;
