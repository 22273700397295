import { createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import 'regenerator-runtime/runtime';

import { formsMiddleware } from '../../services/googleAnalytics/formTrackMiddleware';
import { rootSaga } from './app.effects';
import { createSagaReducer } from './app.reducers';

const sagaMiddleware = createSagaMiddleware();
const middleWares = [sagaMiddleware, thunk, formsMiddleware];

export const configureSagaStore: Store = createStore(createSagaReducer, applyMiddleware(...middleWares));

sagaMiddleware.run(rootSaga);
