export const onlineForm = [
  { id: 1, label: 'Pentugram' },
  { id: 2, label: 'Online form' },
  { id: 3, label: 'Email' },
];

export const formStatusList = [
  // { value: "0", label: "Published" },
  { value: '1', label: 'Active' },
  { value: '2', label: 'Inactive' },
];

export const formStatusObject = {
  // 0: { label: "N/A", color: "#ffbc34" },
  1: { label: 'Active', color: '#06d26f' },
  2: { label: 'Inactive', color: '#ff8181' },
};
