import React, { useState } from 'react';
import HandleUploadFile from '../HandleUploadFile/HandleUploadFile';

import './ImageUpload.scss';

export default function ImageUploadField(props) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className='avatar-upload'>
      <div>
        <div className='avatar-edit' title='Upload image'>
          <HandleUploadFile isLoading={isLoading => setIsLoading(isLoading)} url={url => props.input.onChange(url)}>
            <div>
              <input type='file' id='imageUpload' accept='.png, .jpg, .jpeg' />
            </div>
            <label htmlFor='imageUpload' className='icon-note' />
          </HandleUploadFile>
        </div>
        <div className='avatar-preview'>
          <i className='material-icons remove-img-icon' title='Remove image' onClick={() => props.input.onChange(null)}>
            cancel
          </i>
          {isLoading ? (
            <div className='display-flex-center'>
              <div className='lds-roller'>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          ) : (
            <div
              id='imagePreview'
              className='text-muted'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${props.input.value} )`,
              }}
            >
              {' '}
              {!props.input.value ? 'No image.' : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
