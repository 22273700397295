import remove from 'lodash/remove';
import {
  LOADING_USERS,
  LOADED_USERS,
  ROLES_LOADED,
  USER_ADDED,
  DELETE_USER,
  USER_BLOCKED,
  USER_UNBLOCKED,
  EDITING_USER,
  USER_UPDATED,
  FILTER_INVESTORS,
} from 'ReduxActions/settingActions';
import { replace } from 'Utils/arrayUtils.js';

const initialState = {
  all: [],
  loading: false,
  count: 0,
  roles: [],
  meta: null,
  editing: null,
  view: 'users',
  filterInvestors: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_USERS:
      return {
        ...state,
        loading: true,
        filterInvestors: '',
      };

    case FILTER_INVESTORS:
      return {
        ...state,
        filterInvestors: action.payload,
      };

    case ROLES_LOADED:
      return {
        ...state,
        roles: action.payload,
      };

    case LOADED_USERS:
      return {
        ...state,
        all: action.payload,
        meta: action.meta,
        loading: false,
        count: action.payload.length,
      };

    case USER_ADDED:
      return {
        ...state,
        all: [action.payload, ...state.all],
      };

    case DELETE_USER:
      const users = [...state.all];
      remove(users, { id: action.payload });
      return {
        ...state,
        all: users,
        count: users.length,
      };

    case EDITING_USER:
      return {
        ...state,
        editing: action.payload ? state.all.find(user => user.id === action.payload.id) : null,
        view: action.view,
      };

    case USER_BLOCKED:
      return {
        ...state,
        all: state.all.map(user => {
          if (user.id === action.payload) {
            return {
              ...user,
              status: 4,
            };
          }
          return user;
        }),
      };

    case USER_UNBLOCKED:
      return {
        ...state,
        all: state.all.map(user => {
          if (user.id === action.payload) {
            return {
              ...user,
              status: 1,
            };
          }
          return user;
        }),
      };
    case USER_UPDATED:
      return {
        ...state,
        all: replace(state.all, { id: action.payload.id }, action.payload),
      };
  }

  return state;
}
