import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray, GenericFieldArray } from 'redux-form';
import { Divider, Alert, Icon } from 'antd';
import lodash from 'lodash';

import { ButtonTypeEnum } from '../../../shared/enums/button-type.enum';
import { Button, Empty, Form } from '../../../shared/components';
import { openModal } from '../../../shared/components/Modal/state/modal.actions';

import { ModalIdEnum } from '../../../core/enums/entity-ids/modal-id.enum';
import { FormIdEnum } from '../../../core/enums/entity-ids/form-id.enum';
import { PermissionForUpdate } from '../../../core/models/permission/permission-for-update';

import { ListPermissions } from './list-permissions/list-permissions.component';
import { ListPages } from './list-pages/list-pages.component';
import { ListRoles } from './list-roles/list-roles.component';

import { createRole, loadPermissions, loadRoleList, updatePermissions, updateRole, destroyData } from './state/permissions.actions';
import { editingRole, getPermissionInitialState, isSubmitting, getRole } from './state/permissions.selectors';
import { CreateUpdateRole } from '../../common/create-update-role/create-update-role.component';
import { PageSummaryData } from '../../../core/models/permission/page-summary-data';

import './permissions.component.scss';
import { Mixpanel } from 'App/app';

export function Permissions() {
  const dispatch = useDispatch();
  const initialValues = useSelector(getPermissionInitialState);
  const submitting = useSelector(isSubmitting);
  const editing = useSelector(editingRole);
  const getRoles = useSelector(getRole);

  useEffect(() => {
    dispatch(loadRoleList());

    return () => {
      dispatch(destroyData());
    };
  }, []);

  const onSubmit = data => {
    if (!lodash.isEqual(data, initialValues)) {
      dispatch(updatePermissions(data));
    }
    Mixpanel.track('Clicked on Save button permissions page');
  };
  // interface DataRole {
  //   name: string;
  // }
  const onSubmitRole = data => {
    if (editing.id) {
      dispatch(updateRole(data));
    } else {
      dispatch(createRole(data));
    }
  };

  const onAddRole = () => dispatch(openModal({ modalId: ModalIdEnum.createPermission }));

  const onRoleChange = roleId => dispatch(loadPermissions(roleId));

  const FieldArrayCustom = FieldArray;

  return (
    <React.Fragment>
      <CreateUpdateRole onSubmit={onSubmitRole} editing={editing} />
      <Form formId={FormIdEnum.PermissionsSettingsForm} initialValues={initialValues} onSubmit={onSubmit} className='Permissions'>
        <div className='permission-header'>
          <Button icon='plus' label='Add Role' btnClass='pentugram-btn-default' onClick={onAddRole} />
          <Button icon='check' label='Save' btnClass='pentugram-primary-btn pentugram-btn-default' htmlType={ButtonTypeEnum.Submit} disabled={submitting} loading={submitting} />
        </div>
        {initialValues.role && getRoles.isBasic && (
          <div className='mb-rem-0_5'>
            <Alert
              message={
                <span>
                  This is a system role that cannot be edited. You can create a new role based on this role permissions by duplicating it ( <Icon type='copy' /> ) then updating the
                  permissions to meet your needs.
                </span>
              }
              type='info'
              banner={true}
            />
          </div>
        )}
        <div className='permissions-layout'>
          <Field name='role' component={ListRoles} loadPermissions={onRoleChange} />
          {initialValues.role ? (
            <div className='permissions-container'>
              <div className='permissions-pages'>
                <FieldArrayCustom name='pages' component={ListPages} isBasic={getRoles.isBasic} />
              </div>
              <Divider type='vertical' style={{ height: '100%' }} />
              <div className='permissions-roles'>
                <FieldArrayCustom name='actions' component={ListPermissions} isBasic={getRoles.isBasic} />
              </div>
            </div>
          ) : (
            <div className='permission-empty-state'>
              <Empty description='No role selected' />
            </div>
          )}
        </div>
      </Form>
    </React.Fragment>
  );
}
