import { at } from 'Utils/objectUtils';
import { CATEGORIES } from 'Enums/permissions';

export function getLabelValue(is_portfolio, read_only) {
  return (dispatch, getState) => {
    const activityId = at(getState(), 'auth.user.company.activity.activity_id');

    if (CATEGORIES[activityId] == CATEGORIES[1]) {
      return is_portfolio ? { label: 'Total investment', field: 'total_investment', read_only: true } : { label: 'Ticket size', field: 'size', read_only: false };
    } else return { label: 'Value', field: 'size', read_only: false };
  };
}
