import { Dispatch } from 'react';
import lodash from 'lodash';
import { PermissionTypeEnum } from '../../../core/enums/permission/permission-type.enum';
import { at } from '../../../../utils/objectUtils';
import { StateV2 } from '../../../store/v2.type';

export function canForClass(permissionEnum: PermissionTypeEnum): (__: Dispatch<null>, getState: () => StateV2) => boolean {
  /* fixme see with @ahmed type getState*/
  return (__, getState) => {
    const allowedActions = at(getState(), 'v2.me.user.actions');
    const allowedPages = at(getState(), 'v2.me.user.pages');

    const permissions = [...allowedActions, ...allowedPages];

    return lodash.includes(permissions, permissionEnum);
  };
}
