import React from 'react';

import { Avatar, Badge, Tooltip } from 'antd';

export const CustomAvatar = props => {
  return <Avatar shape={props.shape} size={props.size} icon={props.icon} src={props.src} style={props.style} />;
};

export const TooltipAvatar = props => {
  return (
    <Tooltip placement='topLeft' title={props.tooltip}>
      <Avatar shape={props.shape} size={props.size} icon={props.icon} src={props.image} style={props.style} />
    </Tooltip>
  );
};

export const CustomBadge = props => {
  return (
    <Badge showZero={false} count={props.count} overflowCount={props.overflowCount} dot={props.dot} style={{ right: '-20px', ...props.style }}>
      {props.children}
    </Badge>
  );
};

export const BadgeAvatar = props => {
  return (
    <div className='display-flex-start' style={props.badgeStyle}>
      <div title={props.title}>
        <Badge status={props.status}>
          <CustomAvatar shape={props.shape} size={props.size} icon={props.icon} src={props.src} style={props.style} />
        </Badge>
      </div>
      {props.children}
    </div>
  );
};

BadgeAvatar.defaultProps = {
  status: 'default', //"success"
  shapre: 'circle',
  size: 48,
};
