import { DashboardKpisEnum } from '../../enums/dashboard-kpi/dashboard-kpis.enum';
import { KpiResponseDTO } from '../../generated/model/kpiResponseDTO';

export interface KpisSummaryData {
  uuid: string;
  type: DashboardKpisEnum;
  order: number;
  value: string;
}

export namespace KpisSummaryData {
  export function mapFromApi(kpi: KpiResponseDTO): KpisSummaryData {
    return {
      uuid: kpi.uuid,
      type: DashboardKpisEnum.convertFromApiValue.getValue(kpi.type),
      order: kpi.order,
      value: kpi.value,
    };
  }

  export function mapToApi(kpi: KpisSummaryData): KpiResponseDTO {
    return {
      uuid: kpi.uuid,
      type: DashboardKpisEnum.convertToApiValue.getValue(kpi.type),
      order: kpi.order,
      value: kpi.value,
    };
  }
}
