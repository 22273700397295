import ReactEcharts from 'echarts-for-react';
import React, { Component } from 'react';
import { numberFormatter } from 'Utils/objectUtils';

import './BarChartItem.scss';
export default class SimpleBarChart extends Component {
  onChartReady = chart => {
    chart.setOption(this.getOption());
  };

  getLoadingOption = () => {
    return {
      text: 'Loading...',
      color: '#4413c2',
      textColor: '#270240',
      maskColor: 'rgba(0, 0, 0, 0.5)',
      zlevel: 0,
    };
  };

  getSeries(data) {
    let legend = [];
    let entries = [];
    let item = this.props.item;
    data.forEach(element => {
      legend.push(element.name);
      entries.push(element.value);
    });
    return {
      legend,
      series: [
        {
          data: entries,
          color: item.properties && item.properties.colorPalette,
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            normal: {
              position: 'top',
              show: true,
              formatter: data => numberFormatter(data.value || 0),
            },
          },
        },
      ],
    };
  }

  getTtitlePos(position) {
    switch (position) {
      case 'top_center':
        return { x: 'center' };
      case 'top_right':
        return { top: 10, right: 10 };
      case 'top_left':
        return { top: 10, left: 10 };
      case 'bottom_center':
        return { top: 'bottom', left: 'center' };
      case 'bottom_right':
        return { bottom: 10, right: 10 };
      case 'bottom_left':
        return { bottom: 10, left: 10 };
      default:
        return { x: 'center' };
    }
  }

  getOption() {
    const item = this.props.item;
    //  { axes: [], data: []};
    const { series, legend } = this.getSeries(item.data);
    return {
      title: {
        text: item.title,
        subtext: item.description,
        ...this.getTtitlePos(item.title_position),
        ...item.titlePos,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: legend,
      },
      grid: {
        top: (item.grid && item.grid.top) || '20%',
        left: (item.grid && item.grid.left) || '10%',
        right: (item.grid && item.grid.right) || '10%',
        bottom: (item.grid && item.grid.bottom) || '20%',
        containLabel: true,
      },
      toolbox: {
        right: '30px',
      },
      xAxis: {
        type: 'category',
        data: legend,
        axisLabel: {
          interval: 0,
          rotate: 30,
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
      },
      series,
    };
  }

  render() {
    let onEvents =
      this.props.mode == 'view'
        ? {}
        : {
            click: () => {},
            legendselectchanged: () => {},
          };
    return (
      <div className='BarChartItem'>
        <ReactEcharts option={{}} opts={{ renderer: 'canvas' }} onChartReady={this.onChartReady} onEvents={{}} theme={this.props.theme || 'light'} onEvents={onEvents} />
      </div>
    );
  }
}
