export interface ErrorResponseData {
  status: number;
  name: string;
  message: string;
}

export namespace ErrorResponseData {
  // tslint:disable-next-line: no-any
  export function mapFromApi(error: any): ErrorResponseData {
    return {
      status: error && error.status,
      name: error ? error.name : 'Http Error',
      message: error ? error.message : 'Something wont wrong, try again!',
    };
  }
}
