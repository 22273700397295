// components that can be refactored.
import React from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';

const DragHandle = SortableHandle(({ isDisabled }) => (
  <i className='material-icons handle-dnd ml-3' style={isDisabled ? { opacity: '0', pointer: 'none' } : undefined}>
    dehaze
  </i>
));

const SortableField = SortableElement(({ column, inputIndex, formValues, difference, onValuesChange, isDisabled }) => {
  return (
    <div className='mb-1 display-flex-start' style={{ zIndex: 99999999 }}>
      <div className='mr-1' style={{ width: '20px' }}>
        {' '}
        {`${inputIndex + 1} - `}{' '}
      </div>
      <div style={{ width: 'calc(100% - 20px)' }}>
        {' '}
        <Field
          key={inputIndex}
          disabled={isDisabled}
          name={`inputs.${inputIndex}`}
          component={FieldSelect}
          type='text'
          options={difference
            .map(option => ({ value: option, label: option }))
            .concat({ value: column, label: column })
            .filter(it => it.label)}
          placeholder={column}
          style={{ width: '100%' }}
          labelClass='font-weight-500 font-size-14'
          onLoad={id => {
            let values = [...formValues.inputs];
            values[inputIndex] = id;
            onValuesChange({ inputs: values });
          }}
        />
      </div>
      <DragHandle isDisabled={isDisabled} />
    </div>
  );
});

const SortableInputsContainer = SortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const ArrangeInputs = props => {
  const {
    formValues,
    clearStoredValues,
    handleSubmit,
    onSubmitValues,
    defaultColumns,
    options,
    onValuesChange, // For sorting
  } = props;
  const submit = values => {
    const inputs = values.inputs;
    if (inputs.length > 0) {
      onSubmitValues(inputs);
    }
  };
  let difference = options.filter(column => !(defaultColumns || []).includes(column));
  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newFields = arrayMove(defaultColumns, oldIndex, newIndex);
    onValuesChange({ inputs: newFields });
  };

  return (
    <div className='m-3'>
      <form onSubmit={handleSubmit(submit)}>
        <SortableInputsContainer onSortEnd={onSortEnd}>
          {defaultColumns.map((column, index) => {
            const disabled = index === 0 || (index === 1 && window.location.pathname === '/tasks');
            return (
              <SortableField
                column={column}
                key={`${column}${index}`}
                disabled={disabled}
                inputIndex={index}
                index={index}
                isDisabled={disabled}
                difference={difference}
                formValues={formValues}
                onValuesChange={onValuesChange}
              />
            );
          })}
        </SortableInputsContainer>
        <div className='display-flex-between mt-3'>
          <div>
            <Button
              type='submit'
              className='submit-btn-p'
              onClick={clearStoredValues}
              style={{
                background: '#fff',
                color: '#222',
                width: 100,
              }}
            >
              Clear
            </Button>
          </div>
          <div>
            <Button type='submit' style={{ width: 100 }} className='submit-btn-p'>
              Done
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export const ArrangeInputsForm = connect(
  state => ({ formValues: getFormValues('arrange-inputs-form')(state) }),
  {},
)(reduxForm({ form: 'arrange-inputs-form', enableReinitialize: true })(ArrangeInputs));
