import React, { Component } from 'react';

import './Text.scss';

export default class Text extends Component {
  render() {
    const item = this.props.item;
    const formattedText = item.html.replace(/<br>/g, '&nbsp;');

    return (
      <div className='Text'>
        {item.hasHeader && (
          <div style={{ backgroundColor: item.properties.header.backgroundColor }}>
            <div className='text-element' dangerouslySetInnerHTML={{ __html: item.header }} />
          </div>
        )}
        <div className='text-element' dangerouslySetInnerHTML={{ __html: formattedText }} />
      </div>
    );
  }
}
