import { MetaQuery } from '../../../core/models/queries/meta-query.model';

export interface PaginationTable {
  total: number;
  current: number;
  pageSize: number;
  hideOnSinglePage: boolean;
}

export namespace PaginationTable {
  export function mapMetaToPaginationTable(meta: MetaQuery): PaginationTable {
    return {
      total: meta.totalCount,
      current: meta.currentPage,
      pageSize: meta.perPage,
      hideOnSinglePage: meta.pageCount === 1,
    };
  }
}
