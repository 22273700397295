import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import icons from './present_splite.svg';

import './Charts.scss';

export default class Charts extends Component {
  render() {
    return (
      <div className='Charts'>
        <div className='m-3'>
          {this.props.items.map((block, index) => {
            return (
              <div key={index + 1} className='mb-2'>
                <div className='chart-sector-label'>{block.label}</div>
                <Row>
                  {block.items.map((it, idx) => (
                    <Col key={idx * 3 + 1} xs={4} sm={4}>
                      <CanvasItem
                        x={it.x}
                        y={it.y}
                        tooltip={it.tooltip}
                        onClick={() => {
                          this.props.closePopover();
                          this.props.onAdd(it);
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const CanvasItem = props => {
  const background = `no-repeat url(${icons}) ${props.x} ${props.y}`;
  return (
    <div className='present-preview-pentugram' onClick={props.onClick}>
      <ReactTooltip place='bottom' />
      <div style={{ background, height: '36px', width: '48px' }} data-tip={props.tooltip} />
    </div>
  );
};
