export const ApiToType = url => {
  const array = url.split('.');
  const type = array.pop();
  if (type === 'xlsx') {
    return 'xls';
  } else if (type === 'pptx') {
    return 'ppt';
  } else if (type === 'docx') {
    return 'doc';
  }

  return type;
};
