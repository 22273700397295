/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DealLightDTO } from './dealLightDTO';
import { FileLightDTO } from './fileLightDTO';
import { MeetingInfoDTO } from './meetingInfoDTO';
import { UserLightDTO } from './userLightDTO';

export interface TaskResponseDTO {
  task_uuid?: string;
  title?: string;
  attendees?: Array<UserLightDTO>;
  guests?: Array<string>;
  description?: string;
  assignee?: UserLightDTO;
  /**
   *      0 - UNDONE     1 - DONE     2 - OVERDUE
   */
  status?: TaskResponseDTO.StatusEnum;
  due_date?: number;
  deal?: DealLightDTO;
  files?: Array<FileLightDTO>;
  /**
   *      1 - Call     2 - Meeting     3 - Conference     4 - Task     5 - Email
   */
  eventype?: TaskResponseDTO.EventypeEnum;
  is_zoom_call?: boolean;
  meeting_info?: MeetingInfoDTO;
  /**
   *      1 - STARTED     2 - NOT STARTED     3 - ENDED
   */
  zoom_meeting_status?: TaskResponseDTO.ZoomMeetingStatusEnum;
  /**
   *      1 - URGENT     2 - HIGH     3 - NORMAL     4 - LOW
   */
  task_priority?: TaskResponseDTO.TaskPriorityEnum;
  isForVoting?: boolean;
  /**
   *      0 - No     1 - Yes     2 - N/A
   */
  voteResult?: boolean;
  created_by?: UserLightDTO;
}
export namespace TaskResponseDTO {
  export type StatusEnum = 0 | 1 | 2;
  export const StatusEnum = {
    NUMBER_0: 0 as StatusEnum,
    NUMBER_1: 1 as StatusEnum,
    NUMBER_2: 2 as StatusEnum,
  };
  export type EventypeEnum = 1 | 2 | 3 | 4 | 5;
  export const EventypeEnum = {
    NUMBER_1: 1 as EventypeEnum,
    NUMBER_2: 2 as EventypeEnum,
    NUMBER_3: 3 as EventypeEnum,
    NUMBER_4: 4 as EventypeEnum,
    NUMBER_5: 5 as EventypeEnum,
  };
  export type ZoomMeetingStatusEnum = 1 | 2 | 3;
  export const ZoomMeetingStatusEnum = {
    NUMBER_1: 1 as ZoomMeetingStatusEnum,
    NUMBER_2: 2 as ZoomMeetingStatusEnum,
    NUMBER_3: 3 as ZoomMeetingStatusEnum,
  };
  export type TaskPriorityEnum = 1 | 2 | 3 | 4;
  export const TaskPriorityEnum = {
    NUMBER_1: 1 as TaskPriorityEnum,
    NUMBER_2: 2 as TaskPriorityEnum,
    NUMBER_3: 3 as TaskPriorityEnum,
    NUMBER_4: 4 as TaskPriorityEnum,
  };
}
