import { put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { all } from '@redux-saga/core/effects';

import { LazyLoadResponse } from '../../../../../core/models/queries/lazy-response.model';
import { NewsTagForCreate } from '../../../../../core/models/news/news-tag-for-create';
import { NewsSummaryData } from '../../../../../core/models/news/news-summary-data';
import { NewsTagsData } from '../../../../../core/models/news/news-tags-data';
import { getSavedNewsList, getNewsList, saveNews, removeSavedNews, getTagsList, removeTag, addTag } from '../../../../../core/services/news.service';
import * as newsAction from './news.actions';
import { ISaveNewsId } from './types';
import { initializeMetaQueries, mapPaginationQueriesToMetaQueriesData, MetaQuery } from '../../../../../core/models/queries/meta-query.model';
import { getNewsMeta, getSavedNewsMeta, getTagsMeta } from './news.selectors';
import { ErrorResponseData } from '../../../../../core/models/http-params.type';
import { notification } from '../../../../../shared/components';
import { NotificationTypeEnum } from '../../../../../shared/enums/notification-type.enum';

function* loadNewsEffect(): Generator<Promise<LazyLoadResponse<NewsSummaryData[]>> | PutEffect, void, LazyLoadResponse<NewsSummaryData[]>> {
  try {
    const news = yield getNewsList({ queryParams: mapPaginationQueriesToMetaQueriesData(initializeMetaQueries()) });
    yield put(newsAction.loadNewsSuccess({ list: news.results, meta: news._meta, reset: true }));
  } catch (error) {
    yield put(newsAction.loadNewsFail(error));
  }
}

function* loadNextPageNewsEffect(): Generator<SelectEffect | Promise<LazyLoadResponse<NewsSummaryData[]>> | PutEffect, void, MetaQuery & LazyLoadResponse<NewsSummaryData[]>> {
  try {
    const queryNews = yield select(getNewsMeta);
    const nextPageQueryNews = { ...queryNews, currentPage: queryNews.currentPage + 1 };
    const news = yield getNewsList({ queryParams: mapPaginationQueriesToMetaQueriesData(nextPageQueryNews) });
    yield put(newsAction.loadNewsSuccess({ list: news.results, meta: news._meta, reset: false }));
  } catch (error) {
    yield put(newsAction.loadNewsFail(error));
  }
}

function* loadSavedNewsEffect(): Generator<Promise<LazyLoadResponse<NewsSummaryData[]>> | PutEffect, void, LazyLoadResponse<NewsSummaryData[]>> {
  try {
    const news = yield getSavedNewsList({ queryParams: mapPaginationQueriesToMetaQueriesData(initializeMetaQueries()) });
    yield put(newsAction.loadSavedNewsSuccess({ list: news.results, meta: news._meta, reset: true }));
  } catch (error) {
    yield put(newsAction.loadSavedNewsFail(error));
  }
}

function* loadNextPageSavedNewsEffect(): Generator<SelectEffect | Promise<LazyLoadResponse<NewsSummaryData[]>> | PutEffect, void, MetaQuery & LazyLoadResponse<NewsSummaryData[]>> {
  try {
    const queryNews = yield select(getSavedNewsMeta);
    const nextPageQueryNews = { ...queryNews, currentPage: queryNews.currentPage + 1 };
    const news = yield getSavedNewsList({ queryParams: mapPaginationQueriesToMetaQueriesData(nextPageQueryNews) });
    yield put(newsAction.loadSavedNewsSuccess({ list: news.results, meta: news._meta, reset: false }));
  } catch (error) {
    yield put(newsAction.loadSavedNewsFail(error));
  }
}

function* saveNewsEffect(action: PayloadAction<ISaveNewsId>): Generator<Promise<NewsSummaryData> | PutEffect, void, NewsSummaryData> {
  try {
    const news = yield saveNews(action.payload.newsId);
    yield put(newsAction.saveNewsSuccess(news));
  } catch (error) {
    yield put(newsAction.saveNewsFail(error));
  }
}

function* removeSavedNewsEffect(action: PayloadAction<NewsSummaryData>): Generator<Promise<NewsSummaryData> | PutEffect, void, NewsSummaryData> {
  try {
    yield removeSavedNews(action.payload.id);
    yield put(newsAction.removeNewsSuccess(action.payload));
  } catch (error) {
    yield put(newsAction.removeSavedNewsFail(error));
  }
}

function* loadTagsEffect(): Generator<Promise<LazyLoadResponse<NewsTagsData[]>> | PutEffect, void, LazyLoadResponse<NewsTagsData[]>> {
  try {
    const news = yield getTagsList({ queryParams: mapPaginationQueriesToMetaQueriesData(initializeMetaQueries()) });
    yield put(newsAction.loadTagsSuccess({ list: news.results, meta: news._meta, reset: true }));
  } catch (error) {
    yield put(newsAction.loadTagsFail(error));
  }
}

function* loadNextPageTagsEffect(): Generator<SelectEffect | Promise<LazyLoadResponse<NewsTagsData[]>> | PutEffect, void, MetaQuery & LazyLoadResponse<NewsTagsData[]>> {
  try {
    const queryNews = yield select(getTagsMeta);
    const nextPageQueryNews = { ...queryNews, currentPage: queryNews.currentPage + 1 };
    const news = yield getTagsList({ queryParams: mapPaginationQueriesToMetaQueriesData(nextPageQueryNews) });
    yield put(newsAction.loadTagsSuccess({ list: news.results, meta: news._meta, reset: false }));
  } catch (error) {
    yield put(newsAction.loadTagsFail(error));
  }
}

function* removeTagEffect(action: PayloadAction<NewsTagsData>): Generator<Promise<NewsTagsData> | PutEffect, void, NewsTagsData> {
  try {
    yield removeTag(action.payload.id);
    yield put(newsAction.removeTagSuccess(action.payload));
  } catch (error) {
    yield put(newsAction.removeTagFail(error));
  }
}

function* addTagEffect(action: PayloadAction<NewsTagForCreate>): Generator<Promise<NewsTagsData> | PutEffect, void, NewsTagsData> {
  try {
    const addedTag = yield addTag(action.payload);
    yield put(newsAction.addTagSuccess(addedTag));
  } catch (error) {
    yield put(newsAction.addTagFail(error));
  }
}

function* failEffect({ payload }: PayloadAction<ErrorResponseData>): Generator {
  try {
    notification({ type: NotificationTypeEnum.Error, message: payload.message });
  } catch (error) {}
}

export function* watchNews(): Generator {
  yield all([
    takeLatest(newsAction.loadNews.type, loadNewsEffect),
    takeLatest(newsAction.saveNews.type, saveNewsEffect),
    takeLatest(newsAction.loadNextPageNews.type, loadNextPageNewsEffect),
    takeLatest(newsAction.loadNextPageSavedNews.type, loadNextPageSavedNewsEffect),
    takeLatest(newsAction.loadSavedNews.type, loadSavedNewsEffect),
    takeLatest(newsAction.removeSavedNews.type, removeSavedNewsEffect),
    takeLatest(newsAction.loadTags.type, loadTagsEffect),
    takeLatest(newsAction.loadNextPageTags.type, loadNextPageTagsEffect),
    takeLatest(newsAction.removeTag.type, removeTagEffect),
    takeLatest(newsAction.addTag.type, addTagEffect),
    takeLatest(
      [
        newsAction.addTagFail.type,
        newsAction.removeTagFail.type,
        newsAction.loadTagsFail.type,
        newsAction.removeSavedNewsFail.type,
        newsAction.loadNewsFail.type,
        newsAction.saveNewsFail.type,
        newsAction.loadSavedNewsFail.type,
      ],
      failEffect,
    ),
  ]);
}
