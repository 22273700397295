import { createAction } from '@reduxjs/toolkit';

import { ModalIdEnum } from '../../../../core/enums/entity-ids/modal-id.enum';

export const closeModal = createAction('[Shared/Modal] close modal');
export const resetDialogsState = createAction('[Shared/Modal] reset modal state'); // todo: reset modal state

export const openModal = createAction<{ modalId: ModalIdEnum }>('[Shared/Modal] open modal');
export const setModalId = createAction<{ modalId: ModalIdEnum }>('[Shared/Modal] set modal id');
export const setEditMode = createAction<{ editMode: boolean }>('[Shared/Modal] set modal edit mode');
export const setLoading = createAction<{ loading: boolean }>('[Shared/Modal] set modal loading state');
export const setSubmitted = createAction<{ submitted: boolean }>('[Shared/Modal] set modal submitted');
