import remove from 'lodash/remove';
import {
  UPDATE_EDITOR_PROPS,
  UNMOUNT_REDUCER,
  LOADING_REPORTS,
  LOADED_REPORTS,
  LOADED_REPORT,
  REPORT_DELETED,
  TEMPLATE_EDITOR_FIELD_ADDED,
  ITEM_SELECTED,
  ITEM_DELETED,
  RESET_REPORT,
  INITIALIZE_APP,
  ADD_TO_SCENE,
  MAKE_ELEMENT_ACTIVE,
  CHANGE_PROPERTIES,
  DROP_ELEMENT,
  UPDATE_ELEMENT,
  REPORT_ITEM_LOADING,
  PENTUGRAM_ITEMS_LOADED,
  PERIOD_UPDATED,
  LOADED_TEMPLATES,
  TEMPLATE_DELETED,
  REPORT_TEMPLATE_LOADED,
  LOADING_CONTENT,
  ADDED_PAGE_TO_REPORT,
  REMOVE_PAGE_FROM_REPORT,
  CHANGE_REPORT_PAGE,
  ADDED_COVER_PAEGE,
  UPDATE_TOOLS,
  SHARED_COMPONENT_ADDED,
  SHARED_COMPONENT_REMOVED,
  UPDATE_GLOBAL_COLOR,
  COPY_ELEMENT,
  ZOOM_IN_OUT,
  MOVE_REPORT_PAGE,
  SWITCH_PAGE_ORIENTATION,
  ADD_TO_CONTENT_TABLE,
  REMOVE_FROM_CONTENT_TABLE,
  SEND_TO_BACK,
  SEND_TO_FRONT,
  DUPLICATE_PAGE,
  ADD_REPORT_PAGE_COMMENT,
  REMOVE_REPORT_PAGE_COMMENT,
  UPDATE_REPORT_PAGE_COMMENT,
  SELECT_ITEMS,
  PASTE_SELECTED_ITEMS,
  CLEAR_SELECTED_ITEMS,
} from '../actions/reportActions';

import get from 'lodash/get';
import clone from 'lodash/clone';

import { arrayToEntities } from 'Utils/arrayUtils';

import { arrayMove } from 'react-sortable-hoc';
import ID from 'Utils/UUID';

const SMART_GRID_THRESHOLD = 1;
const initialTools = {
  pages: 1,
  activePage: 1,
  height: 1122,
  width: 793,
  title: null,
  description: null,
  showCoverPage: false,
  showPages: false,
  showHeader: false,
  showFooter: false,
  showGrid: true,
  showGuides: true,
  showSlides: true,
  auto_save: false,
  manualTableSelectedItems: [],
  columnSelected: {},
  rowSelected: {},
  selectedItemsInfos: {},
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  period: {
    frequency_id: null,
    from: null,
    to: null,
    label: null,
  },
};
const initialTemplate = {
  fields: [],
  selected: null,
  copy_past_element: null,
  tools: initialTools,
  report: [{}],
  shared: {},
  objects: {},
  activeElement: null,
  selectedItems: null,
  pentugramItems: [],
  editor_prop: {
    type: null,
    mode: null,
    id: null,
    key: null,
  },
  content_table: [],
};
const initialState = {
  reports: [],
  entities: {},
  count: 0,
  loading: false,
  templates: [],
  ...initialTemplate,
};

function findElementsInsideBounds(bounds, objects) {
  if (!objects || !bounds) return {};
  let { start, end } = bounds;
  let items = {};
  for (const key in objects) {
    let item = objects[key];
    let x = get(item, 'properties.location.x');
    let y = get(item, 'properties.location.y');
    if (isPointBetween(x, start.x, end.x) && isPointBetween(y, start.y, end.y)) {
      items[key] = clone(item, true);
    }
  }
  return items;
}

function setNewIds(objects) {
  let items = {};
  for (const key in objects) {
    let item = clone(objects[key], true);
    let newId = ID.uuid();

    items[newId] = { ...item, id: newId };
  }
  return items;
}

function isPointBetween(point, start, end) {
  return start <= point && point <= end;
}

function updateLoadingObject(id, value, objects) {
  return objects;
}

function spliceList(list, position, object) {
  list.splice(position, 1, object);
  return list;
}

function removeList(list, position) {
  list.splice(position, 1);
  return list;
}

function insertIntoReport(report, object, active, hasCover) {
  return report.map((page, index) => {
    if (index == active - 1) return page;
    else return { ...page, ...object };
  });
}

function checkPageProperties(objects, showCoverPage, activePage, shared) {
  if (showCoverPage && activePage == 1) return objects;
  else return { ...objects, ...shared };
}

function parseHeadings(report) {
  let headings = [];
  for (const page of report) {
    for (const elem_id in page) {
      let item = page[elem_id];
      if (get(item, 'properties.isHeading')) {
        let heading = {
          id: elem_id,
        };
        headings.push(heading);
      }
    }
  }
  return headings;
}

function filterEdges(selected, positions, axis) {
  return positions.filter(position => {
    let nX = selected.properties.location.x;
    let nY = selected.properties.location.y;
    let nH = selected.properties.size.height;
    let nW = selected.properties.size.width;
    let isX = axis === 'x';
    var distance = isX ? nX : nY;
    let side = isX ? 'width' : 'height';
    let widthOrHeight = side === 'height' ? nH : nW;
    let endDistance = distance + widthOrHeight;
    let halfSideLength = Math.abs(widthOrHeight / 2);
    let center = distance + halfSideLength;

    if (Math.abs(distance - position) <= SMART_GRID_THRESHOLD) return true;
    if (Math.abs(center - position) <= SMART_GRID_THRESHOLD) return true;
    if (Math.abs(endDistance - position) <= SMART_GRID_THRESHOLD) return true;

    return false;
  });
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UNMOUNT_REDUCER:
      return initialState;

    case UPDATE_EDITOR_PROPS:
      return {
        ...state,
        editor_prop: action.payload,
      };

    case UPDATE_TOOLS:
      return {
        ...state,
        tools: {
          ...state.tools,
          [action.payload.field]: action.payload.value,
        },
      };

    case ADDED_COVER_PAEGE:
      return {
        ...state,
        tools: {
          ...state.tools,
          pages: state.tools.pages + 1,
          activePage: 1,
          showCoverPage: true,
        },
        report: [action.payload, ...spliceList(state.report, state.tools.activePage - 1, { ...state.objects })],
        objects: action.payload,
        activeElement: null,
      };

    case ADDED_PAGE_TO_REPORT:
      return {
        ...state,
        tools: {
          ...state.tools,
          pages: state.tools.pages + 1,
          activePage: state.tools.pages + 1,
        },
        report: state.report.length
          ? [...spliceList(state.report, state.tools.activePage - 1, { ...state.objects }), { orientation: state.tools.orientation }]
          : [...spliceList(state.report, state.tools.activePage - 1, { ...state.objects })],
        objects: { orientation: state.tools.orientation },
        activeElement: null,
      };

    case REMOVE_PAGE_FROM_REPORT:
      let newReport = removeList([...state.report], action.payload - 1);
      return {
        ...state,
        tools: {
          ...state.tools,
          pages: state.tools.pages > 0 ? state.tools.pages - 1 : state.tools.pages,
          activePage: newReport.length !== 0 ? 1 : 0,
          showCoverPage: state.showCoverPage ? action.payload > 1 : false,
        },
        report: newReport,
        objects: newReport.length ? newReport[0] : {},
        activeElement: null,
      };

    case CHANGE_REPORT_PAGE:
      if (state.report.length === 0) {
        return state;
      }
      return {
        ...state,
        tools: {
          ...state.tools,
          activePage: action.payload,
          edges: undefined,
        },
        report: spliceList(state.report, state.tools.activePage - 1, { ...state.objects }),
        objects: state.report[action.payload - 1] ? state.report[action.payload - 1] : {},
        activeElement: null,
      };
    case MOVE_REPORT_PAGE:
      const { oldIndex, newIndex } = action;
      let objects;
      let report = arrayMove(state.report, action.oldIndex, action.newIndex);
      if (oldIndex + 1 === state.tools.activePage) {
        objects = report[oldIndex];
      }

      if (newIndex + 1 === state.tools.activePage) {
        objects = report[newIndex];
      }
      return {
        ...state,
        tools: {
          ...state.tools,
          edges: undefined,
        },
        report,
        objects: objects ? objects : state.objects,
        activeElement: null,
      };

    case SHARED_COMPONENT_ADDED:
      return {
        ...state,
        tools: {
          ...state.tools,
          [action.payload.field]: true,
          edges: undefined,
        },
        shared: { ...state.shared, ...action.payload.data },
      };

    case SHARED_COMPONENT_REMOVED:
      const dropShared = Object.keys(state.shared)
        .filter(key => state.shared[key].type !== action.payload.type)
        .reduce((obj, key) => {
          obj[key] = state.shared[key];
          return obj;
        }, {});

      return {
        ...state,
        tools: {
          ...state.tools,
          [action.payload.field]: false,
        },
        shared: dropShared,
      };

    case LOADING_CONTENT:
    case LOADING_REPORTS:
      return {
        ...state,
        loading: action.payload,
      };

    case LOADED_REPORTS:
      return {
        ...state,
        reports: action.payload.results,
        entities: arrayToEntities(action.payload.results, 'report_id'),
        loading: false,
        totalCount: action.payload._meta.totalCount,
        perPage: action.payload._meta.perPage,
        count: action.payload.length,
      };

    case LOADED_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      };

    case LOADED_REPORT:
      return {
        ...state,
        editor_prop: {
          ...state.editor_prop,
          key: action.payload.key,
        },
        tools: {
          ...state.tools,
          title: action.payload.title,
          auto_save: !!get(action.payload, 'global_params.auto_save', true),
          showHeader: !!get(action.payload, 'global_params.showHeader', false),
          orientation: action.payload.content && Array.isArray(action.payload.content) && action.payload.content[0].orientation,
          showFooter: !!get(action.payload, 'global_params.showFooter', false),
          // showSlides: !!action.payload.showSlides,
          // showGrid: !!action.payload.showGrid,
          showCoverPage: !!get(action.payload, 'global_params.showCoverPage', false),
          description: action.payload.description,
          period: action.payload.period,
          pages: action.payload.content.length,
          activePage: action.payload.content.length ? 1 : 0,
        },
        content_table: parseHeadings(action.payload.content),
        shared: action.payload.shared_components,
        report: action.payload.content || [],
        objects: action.payload.content.length ? action.payload.content[0] : {},
      };

    case TEMPLATE_DELETED:
      const deletedTemplates = [...state.templates];
      remove(deletedTemplates, { template_id: action.payload });
      return {
        ...state,
        templates: deletedTemplates,
      };

    case REPORT_DELETED:
      const deletedReports = [...state.reports];
      remove(deletedReports, { report_id: action.payload });
      return {
        ...state,
        reports: deletedReports,
        entities: arrayToEntities(deletedReports, 'report_id'),
      };

    case RESET_REPORT:
      return {
        ...state,
        fields: [],
      };

    case TEMPLATE_EDITOR_FIELD_ADDED:
      return {
        ...state,
        fields: [...state.fields, action.payload],
      };

    case ITEM_DELETED:
      const deletedItems = [...state.fields];
      remove(deletedItems, { id: action.payload.id });
      return {
        ...state,
        selected: null,
        fields: deletedItems,
      };

    case INITIALIZE_APP:
      return {
        ...state,
        tools: action.payload.tools,
      };

    case ADD_TO_SCENE:
      if (state.report.length === 0) {
        return state;
      }
      const add_to_scene = {
        ...state.objects,
        [action.payload.id]: { ...action.payload.object },
      };
      return {
        ...state,
        activeElement: action.payload.object,
        objects: add_to_scene,
        report: spliceList(state.report, state.tools.activePage - 1, add_to_scene),
        // report: [...state.report].splice(state.tools.activePage - 1, 1, add_to_scene),
      };

    case DROP_ELEMENT: {
      const objects = Object.keys(state.objects)
        .filter(key => key !== action.payload)
        .reduce((obj, key) => {
          obj[key] = state.objects[key];
          return obj;
        }, {});

      // const shared = Object.keys(state.shared)
      // .filter(key => key !== action.payload)
      // .reduce((obj, key) => {
      //   obj[key] = state.shared[key];
      //   return obj;
      // }, {});

      return {
        ...state,
        objects,
        // shared,
        report: spliceList(state.report, state.tools.activePage - 1, objects),
        activeElement: null,
        pentugramItems: [],
      };
    }

    case MAKE_ELEMENT_ACTIVE: {
      const activeElement = { ...state.objects, ...state.shared }[action.payload];

      if (!activeElement) {
        return {
          ...state,
          report: spliceList(state.report, state.tools.activePage - 1, { ...state.objects }),
          activeElement: null,
          pentugramItems: [],
        };
      }

      activeElement.id = action.payload;

      return {
        ...state,
        activeElement,
        pentugramItems: [],
      };
    }

    case CHANGE_PROPERTIES: {
      if (!state.activeElement) return state;

      const activeElement = state.objects[state.activeElement.id];
      const activeSharedElement = state.shared[state.activeElement.id];

      let newActiveElement = {};

      if (activeElement) {
        newActiveElement = {
          ...activeElement,
          properties: {
            ...activeElement.properties,
            ...action.payload,
          },
        };
        let newObjects = {
          ...state.objects,
          [state.activeElement.id]: {
            ...newActiveElement,
          },
        };
        let pageX = state.objects.orientation ? state.tools.height : state.tools.width;
        let pageY = state.objects.orientation ? state.tools.width : state.tools.height;
        let pageXCenter = Math.round(pageX / 2);
        let pageYCenter = Math.round(pageY / 2);
        let edges = { x: filterEdges(activeElement, [pageXCenter], 'x'), y: filterEdges(activeElement, [pageYCenter], 'y') };
        let nX = newActiveElement.properties.location.x;
        let nY = newActiveElement.properties.location.y;
        let nH = newActiveElement.properties.size.height;
        let nW = newActiveElement.properties.size.width;
        let nCenterX = nX + Math.round(nW / 2);
        let nCenterY = nY + Math.round(nH / 2);
        for (let id in newObjects) {
          // make sure its an element.
          if (newObjects[id] && newObjects[id].hasOwnProperty('properties') && newObjects[id].hasOwnProperty('id')) {
            let element = newObjects[id];
            // get element x,y,h,w, right, bottom
            let x = element.properties.location.x;
            let y = element.properties.location.y;
            let height = element.properties.size.height;
            let width = element.properties.size.width;
            let right = width + x;
            let bottom = height + y;
            let centerX = x + Math.round(width / 2);
            let centerY = y + Math.round(height / 2);

            // make interest points
            let interestPoints = {
              x: [x, centerX, right],
              y: [y, centerY, bottom],
            };
            // filter edges that arent close to active element.
            // push to edges
            if (element.id !== newActiveElement.id) {
              edges.x.push.apply(edges.x, filterEdges(newActiveElement, interestPoints.x, 'x'));
              edges.y.push.apply(edges.y, filterEdges(newActiveElement, interestPoints.y, 'y'));
            }
          }
        }

        return {
          ...state,
          objects: newObjects,
          activeElement: {
            id: state.activeElement.id,
            ...newActiveElement,
          },
          tools: {
            ...state.tools,
            edges,
          },
        };
      }
      if (activeSharedElement) {
        newActiveElement = {
          ...activeSharedElement,
          properties: {
            ...activeSharedElement.properties,
            ...action.payload,
          },
        };
        return {
          ...state,
          shared: {
            ...state.shared,
            [state.activeElement.id]: {
              ...newActiveElement,
            },
          },
          activeElement: {
            id: state.activeElement.id,
            ...newActiveElement,
          },
        };
      }
      return state;
    }

    case PENTUGRAM_ITEMS_LOADED:
      return {
        ...state,
        pentugramItems: action.payload,
      };

    case REPORT_ITEM_LOADING:
      return {
        ...state,
        objects: updateLoadingObject(action.id, action.payload, state.objects),
      };

    case UPDATE_ELEMENT:
      let previousElement = state.shared[action.id];
      let sharedElement;
      let update_element = {
        ...state.objects,
        [action.id]: action.payload,
      };
      if (previousElement && previousElement.shared) {
        sharedElement = {
          ...action.payload,
          properties: {
            ...action.payload.properties,
            location: previousElement.properties.location,
            size: previousElement.properties.size,
          },
        };
      }

      let edges = { x: [], y: [] };
      // for(let id in update_element) {
      //   // make sure its an element.
      //   if(update_element[id].hasOwnProperty("properties") && update_element[id].hasOwnProperty("id") ) {
      //     let element = update_element[id];
      //     // get element x,y,h,w, right, bottom
      //     let x = element.properties.location.x;
      //     let y = element.properties.location.y;
      //     let height = element.properties.size.height;
      //     let width = element.properties.size.width;
      //     let right = width + x;
      //     let bottom = height + y;

      //     // make interest points
      //     let interestPoints = {
      //       x : [ x, x + Math.round(width/2), right ],
      //       y : [ y, y + Math.round(height/2), bottom ]
      //     }
      //     // push to edges
      //     edges.x.push.apply(edges.x, interestPoints.x);
      //     edges.y.push.apply(edges.y, interestPoints.y);
      //   }
      // }
      return {
        ...state,
        objects: action.payload.shared ? state.objects : update_element,
        shared: action.payload.shared ? { ...state.shared, [action.id]: sharedElement } : state.shared,
        activeElement: sharedElement ? sharedElement : action.payload.for_page ? null : action.payload,
        report: [...spliceList(state.report, state.tools.activePage - 1, action.payload.shared ? { ...state.objects } : { ...update_element })],
        tools: {
          ...state.tools,
          // UPDATE EDGES
          edges: edges,
          // used to invoke state tree change...
          mrand: Math.random(),
        },
      };

    case PERIOD_UPDATED:
      return {
        ...state,
        tools: {
          ...state.tools,
          period: action.payload,
        },
      };

    case REPORT_TEMPLATE_LOADED:
      return {
        ...state,
        tools: {
          ...state.tools,
          title: action.payload.title,
          description: action.payload.description,
          period: action.payload.period,
          pages: action.payload.content.length,
        },
        shared: action.payload.shared_components,
        report: action.payload.content || [],
        objects: action.payload.content.length ? action.payload.content[0] : {},
      };

    case UPDATE_GLOBAL_COLOR:
      return {
        ...state,
        tools: {
          ...state.tools,
          [action.field]: action.payload,
        },
      };

    case COPY_ELEMENT:
      return {
        ...state,
        copy_past_element: action.payload,
      };

    case ZOOM_IN_OUT:
      return {
        ...state,
        tools: {
          ...state.tools,
          height: state.tools.height + 100,
          width: state.tools.width + 100,
        },
      };
    case SWITCH_PAGE_ORIENTATION:
      let page_orientation = {
        ...state.objects,
        orientation: !state.objects.orientation,
      };
      let reportOrientation =
        action.payload === 'all'
          ? [...state.report].map(rep => ({ ...rep, orientation: !state.objects.orientation }))
          : spliceList(state.report, state.tools.activePage - 1, page_orientation);
      return {
        ...state,
        objects: page_orientation,
        tools: {
          ...state.tools,
          orientation: action.payload === 'all' ? page_orientation.orientation : state.tools.orientation,
        },
        report: reportOrientation,
        activeElement: null,
      };
    case ADD_TO_CONTENT_TABLE:
      return {
        ...state,
        content_table: [
          ...state.content_table,
          {
            id: action.payload.id,
          },
        ],
      };
    case REMOVE_FROM_CONTENT_TABLE:
      return {
        ...state,
        content_table: state.content_table.filter(heading => {
          return heading.id !== action.payload;
        }),
      };
    case SEND_TO_FRONT:
      let frontObject = {
        ...state.objects,
      };
      delete frontObject[action.payload.id];
      frontObject[action.payload.id] = action.payload;

      return {
        ...state,
        objects: frontObject,
      };
    case SEND_TO_BACK:
      return {
        ...state,
        objects: {
          [action.payload.id]: action.payload,
          ...state.objects,
        },
      };
    case DUPLICATE_PAGE:
      return {
        ...state,
        tools: {
          ...state.tools,
          pages: state.tools.pages + 1,
          activePage: state.tools.activePage + 1,
        },
        report: [...state.report.slice(0, state.tools.activePage), action.payload, ...state.report.slice(state.tools.activePage)],
        objects: action.payload,
        activeElement: null,
      };
    case ADD_REPORT_PAGE_COMMENT:
      let comments = state.objects.comments ? [...state.objects.comments] : [];
      comments.push(action.payload);
      update_element = {
        ...state.objects,
        comments,
      };

      return {
        ...state,
        objects: update_element,
        activeElement: null,
        report: [...spliceList(state.report, state.tools.activePage - 1, { ...update_element })],
        //mrand: Math.random(),
      };
    case REMOVE_REPORT_PAGE_COMMENT:
      comments = state.objects.comments ? [...state.objects.comments] : [];
      comments = comments.filter(comment => comment.id !== action.payload);
      update_element = {
        ...state.objects,
        comments,
      };

      return {
        ...state,
        objects: update_element,
        activeElement: null,
        report: [...spliceList(state.report, state.tools.activePage - 1, action.payload.shared ? { ...state.objects } : { ...update_element })],
        //mrand: Math.random(),
      };
    case UPDATE_REPORT_PAGE_COMMENT:
      comments = state.objects.comments ? [...state.objects.comments] : [];
      comments = comments.map(comment => {
        if (comment.id === action.payload.id) {
          return action.payload;
        }
        return comment;
      });
      update_element = {
        ...state.objects,
        comments,
      };

      return {
        ...state,
        objects: update_element,
        activeElement: null,
        report: [...spliceList(state.report, state.tools.activePage - 1, { ...update_element })],
        //mrand: Math.random(),
      };

    case SELECT_ITEMS:
      let bounds = action.payload;
      let elements = findElementsInsideBounds(bounds, state.objects);

      return {
        ...state,
        selectedItems: elements,
      };
    case PASTE_SELECTED_ITEMS:
      return {
        ...state,
        objects: {
          ...state.objects,
          ...setNewIds(state.selectedItems),
        },
      };
    case CLEAR_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: {},
      };
  }
  return state;
}
