import { CalenderTasksModeEnum } from '../../enums/calendar/calender-task-mode.enum';

export interface BaseCalenderQuery {
  mode: CalenderTasksModeEnum;
  date: number;
}
export function initializeCalenderQueries(): BaseCalenderQuery {
  /* see with @ahmed*/
  return {
    mode: null,
    date: null,
  };
}
