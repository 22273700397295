/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface KpiResponseDTO {
  uuid?: string;
  type?: KpiResponseDTO.TypeEnum;
  order?: number;
  value?: string;
}
export namespace KpiResponseDTO {
  export type TypeEnum =
    | 'open_deal'
    | 'portfolio_companies'
    | 'total_investment'
    | 'round_investment'
    | 'dry_powder'
    | 'submitted_application'
    | 'portfolio_value'
    | 'convertible_investment'
    | 'equity_investment'
    | 'top_deal_source'
    | 'top_deal_country'
    | 'top_note_taker';
  export const TypeEnum = {
    OpenDeal: 'open_deal' as TypeEnum,
    PortfolioCompanies: 'portfolio_companies' as TypeEnum,
    TotalInvestment: 'total_investment' as TypeEnum,
    RoundInvestment: 'round_investment' as TypeEnum,
    DryPowder: 'dry_powder' as TypeEnum,
    SubmittedApplication: 'submitted_application' as TypeEnum,
    PortfolioValue: 'portfolio_value' as TypeEnum,
    ConvertibleInvestment: 'convertible_investment' as TypeEnum,
    EquityInvestment: 'equity_investment' as TypeEnum,
    TopDealSource: 'top_deal_source' as TypeEnum,
    TopDealCountry: 'top_deal_country' as TypeEnum,
    TopNoteTaker: 'top_note_taker' as TypeEnum,
  };
}
