import React from 'react';

const Error = ({ touched, error, warning }) => {
  const errorIconRequired = {
    fontSize: '16px',
    color: '#ff8181',
    textAlign: 'right',
    lineHeight: '16px',
  };
  const errorMessage = {
    color: '#ff8181',
    display: 'inline-block',
    fontSize: '12px',
    lineHeight: '15px',
  };
  return (
    <div>
      {touched &&
        ((error && (
          <div className='display-flex-start'>
            <span className='material-icons mr-2' style={errorIconRequired}>
              error
            </span>
            <span style={errorMessage}>{error}</span>
          </div>
        )) ||
          (warning && <span style={errorMessage}>{warning}</span>))}
    </div>
  );
};
export default Error;
