import React from 'react';
import { Field } from 'redux-form';

import { required } from '../../../../../shared/utils/validators';
import { Modal, TextField, Form, Button } from '../../../../../shared/components';
import { ButtonTypeEnum } from '../../../../../shared/enums/button-type.enum';

import { ModalIdEnum } from '../../../../../core/enums/entity-ids/modal-id.enum';
import { FormIdEnum } from '../../../../../core/enums/entity-ids/form-id.enum';
import { PipedriveFormNamesEnum } from '../../../../../core/enums/form-names/pipedrive-form-names.enum';

import './pipedrive-modal.component.scss';

// interface PipedriveModalProps {
//   submitting: boolean;
//   onSubmit(values: {}): void;
//   onClose(): void;
// }

export function PipedriveModal(props) {
  return (
    <Modal title='Pipedrive integration' modalId={ModalIdEnum.pipedriveIntegration} width='40rem'>
      <Form className='pipedriveModalForm' formId={FormIdEnum.pipedriveForm} initialValues={{}} onSubmit={props.onSubmit}>
        <div className='dialog-content'>
          <div className='pipedrive-content-body'>
            <div className='pipedrive-modal-label'>Please introduce your Pipedrive personal API key in order to import your data to Pentugram</div>
            <div className='pipedrive-modal-label-details'>{'( it can be found at: Settings > Personal > Other > API )'}</div>
            <div className='field-control'>
              <Field
                component={TextField}
                name={PipedriveFormNamesEnum.formFields.getValue(PipedriveFormNamesEnum.Token)}
                placeholder='Pipedrive Personal API Key'
                validate={[required]}
              />
            </div>
          </div>
        </div>
        <div className='dialog-footer'>
          <Button label='Cancel' icon='close' btnClass='pentugram-btn-default' onClick={props.onClose} />
          <Button htmlType={ButtonTypeEnum.Submit} loading={props.submitting} disabled={props.submitting} label='Add' btnClass='pentugram-primary-btn pentugram-btn-default' />
        </div>
      </Form>
    </Modal>
  );
}
