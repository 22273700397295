import React, { Component } from 'react';
import { Field } from 'redux-form';
import ToggleGroup from 'SharedComponent/ToggleGroup';
import { SwitchToggle } from '../SwitchFields';

import './AggregationTools.scss';

export default class AggregationTools extends Component {
  render() {
    const { item, aggregations, aggregationList, handleChange } = this.props;
    return (
      <div className='AggregationTools'>
        <SwitchToggle onChange={e => handleChange('aggregation', e)} defaultChecked={item.aggregation} label='Aggregations' value='name' disabled={false} />
        {item.aggregation && (
          <div className='mt-2'>
            <Field
              name={`aggregation_type`}
              component={ToggleGroup}
              options={aggregationList}
              defaultValue={true}
              value={1}
              flat={true}
              icons={false}
              onSelect={value => handleChange('aggregation_type', value)}
            />
          </div>
        )}
      </div>
    );
  }
}
