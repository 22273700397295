import { VC_ACCELERATOR, WEALTH_MANAGEMENT, SALES, OWNER, ADMIN, USER, PAYMENT_STATUS } from 'Enums/permissions';
import { IC_MEMBER } from '../enums/permissions';

export const REGISTERED = 'REGISTERED';
export const NOT_REGISTRED = 'NOT_REGISTERED';

export const config = {
  notExpired: {
    authorize: [[PAYMENT_STATUS.STATUS_ACTIVE, PAYMENT_STATUS.STATUS_PAST_DUE, PAYMENT_STATUS.STATUS_PENDING, PAYMENT_STATUS.STATUS_NOT_EXPIRED]],
    redirect: '/notice',
  },
  expired: {
    authorize: [[PAYMENT_STATUS.STATUS_EXPIRED, PAYMENT_STATUS.STATUS_PAUSED, PAYMENT_STATUS.STATUS_DELETED]],
    redirect: '/',
  },
  index: {
    authorize: [[REGISTERED]],
    redirect: '/registering',
  },
  dashboard: {
    authorize: [[OWNER, ADMIN, USER]],
    redirect: '/pipeline',
  },
  pipeline: {
    authorize: [[VC_ACCELERATOR, SALES]],
    redirect: '/',
  },
  portfolio: {
    authorize: [
      [VC_ACCELERATOR, WEALTH_MANAGEMENT],
      [OWNER, ADMIN, USER],
    ],
    redirect: '/',
  },
  reports: {
    authorize: [[VC_ACCELERATOR], [OWNER, ADMIN, USER]],
    redirect: '/',
  },
  templateEditor: {
    authorize: [[VC_ACCELERATOR], [OWNER, ADMIN, USER]],
    redirect: '/',
  },
  tasks: {
    authorize: [
      [VC_ACCELERATOR, SALES],
      [OWNER, ADMIN, USER],
    ],
    redirect: '/',
  },
  contacts: {
    authorize: [[OWNER, ADMIN, USER]],
    redirect: '/',
  },
  reportEditor: {
    authorize: [[REGISTERED], [VC_ACCELERATOR], [OWNER, ADMIN, USER]],
    redirect: '/',
  },
  registering: {
    authorize: [[NOT_REGISTRED]],
    redirect: '/',
  },
  deals_metrics: {
    authorize: [[VC_ACCELERATOR]],
    redirect: '/',
  },
  deals_tasks: {
    authorize: [[VC_ACCELERATOR, SALES]],
    redirect: '/',
  },
  notifications: {
    authorize: [[OWNER, ADMIN, USER]],
    redirect: '/',
  },
  formEditor: {
    authorize: [[OWNER, ADMIN, USER]],
    redirect: '/',
  },
  settings: {
    authorize: [[OWNER, ADMIN, USER]],
    redirect: '/',
  },
  settings_users: {
    authorize: [[OWNER, ADMIN]],
    redirect: '/',
  },
  settings_pipelines: {
    authorize: [[VC_ACCELERATOR, SALES]],
    redirect: '/',
  },
  settings_portfolios: {
    authorize: [[VC_ACCELERATOR, WEALTH_MANAGEMENT]],
    redirect: '/',
  },
  settings_billing_owner: {
    authorize: [[VC_ACCELERATOR], [OWNER]],
    redirect: '/',
  },
  settings_billing_employees: {
    authorize: [[VC_ACCELERATOR], [ADMIN, USER, IC_MEMBER]],
    redirect: '/',
  },
  settings_funds: {
    authorize: [[VC_ACCELERATOR]],
    redirect: '/',
  },
  settings_permissions: {
    authorize: [[OWNER]],
    redirect: '/',
  },
  // example: {
  //   authorize: [[x or y or z] and [a or b] and [t]]...
  //   redirect: "",
  // }
};
