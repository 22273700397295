export enum IntegrationsEnum {
  Slack = 1,
  Pipedrive = 2,
  Zoom = 3,
  GoogleCalendar = 4,
  Excel = 5,
}

export namespace IntegrationsEnum {
  export function getName(value: IntegrationsEnum): string {
    switch (value) {
      case IntegrationsEnum.Slack:
        return 'Slack';
      case IntegrationsEnum.Pipedrive:
        return 'Pipedrive';
      case IntegrationsEnum.Zoom:
        return 'Zoom';
      case IntegrationsEnum.Excel:
        return 'Microsoft Excel';
      case IntegrationsEnum.GoogleCalendar:
        return 'Google Calendar';
      default:
        return null;
    }
  }
  export function getDescription(value: IntegrationsEnum): string {
    switch (value) {
      case IntegrationsEnum.Slack:
        return 'By connecting with Slack, you will be able to receive all Pentugram notifications to a specific channel and keep up with updates on Slack.';
      case IntegrationsEnum.Pipedrive:
        return 'Seamlessly pull your Pipedrive data to Pentugram and consolidate your pipeline with a simple Pipedrive API Key.';
      case IntegrationsEnum.Zoom:
        return 'Integrate with Zoom to easily create rooms on Zoom for any of your calls or meetings within your tasks. Links for the rooms with be automatically shared with all invitees.';
      case IntegrationsEnum.Excel:
        return 'Get your data into Pentugram by importing from excel. Download our template, fill the details and upload to Pentugram to safely transfer all your data.';
      case IntegrationsEnum.GoogleCalendar:
        return 'Keep up with your Pentugram meetings on google calendar. All meetings and calls created on Pentugram will be added automatically to your google calendar.';
      default:
        return null;
    }
  }
  export function getImage(value: IntegrationsEnum): string {
    switch (value) {
      case IntegrationsEnum.Slack:
        return require('Images/integrations/slack');
      case IntegrationsEnum.Pipedrive:
        return require('Images/integrations/pipedrive');
      case IntegrationsEnum.Zoom:
        return require('Images/integrations/zoom.svg');
      case IntegrationsEnum.Excel:
        return require('Images/integrations/excel');
      case IntegrationsEnum.GoogleCalendar:
        return require('Images/integrations/calendar');
      default:
        return null;
    }
  }

  export function getImageColor(value: IntegrationsEnum): string {
    switch (value) {
      case IntegrationsEnum.Slack:
        return '#5b0e57';
      case IntegrationsEnum.Pipedrive:
        return '#24292c';
      case IntegrationsEnum.Zoom:
        return '#1a82ee';
      case IntegrationsEnum.Excel:
        return '#ffffff';
      case IntegrationsEnum.GoogleCalendar:
        return '#ffffff';
      default:
        return null;
    }
  }
}
