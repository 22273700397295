import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SubmitButton from 'SharedComponent/SubmitButton';

import './DialogBox.scss';

class DialogBox extends Component {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClickOut: PropTypes.func,
    className: PropTypes.string,
    title: PropTypes.string,
    handleConfirm: PropTypes.func,
    onClose: PropTypes.func,
    text: PropTypes.string,
  };

  static defaultProps = {
    backgroundColor: 'rgba(0,0,0,.5)',
    onClickOut: new Function(),
  };

  constructor(props) {
    super(props);

    this._handleClickOut = this._handleClickOut.bind(this);
  }

  _handleClickOut(e) {
    if (e.target == this.refs.root) this.props.onClickOut();
  }

  render() {
    const { backgroundColor, isVisible, className, withoutClose, title, text } = this.props;
    return (
      <div
        ref='root'
        className={classNames('DialogBox', { isVisible })}
        style={{ backgroundColor }}
        // onClick={this._handleClickOut}
      >
        <div id='custom-scroll-bar' className={classNames('whiteBox', className)}>
          {!withoutClose && (
            <div className='display-flex-between close-div'>
              <div>
                {title && (
                  <div className='p-3'>
                    <span className='font-size-20 font-weight-600'>{title}</span>
                  </div>
                )}
              </div>
              <i className='material-icons close-tab' onClick={() => this.props.onClose()}>
                clear
              </i>
            </div>
          )}

          {text && (
            <div className='p-3'>
              <span className='font-size-16 font-weight-500'>{text}</span>
            </div>
          )}
          {this.props.children}
          {this.props.onClose && this.props.handleConfirm && (
            <div className='display-flex-end'>
              {this.props.onClose && (
                <div className='m-3'>
                  <SubmitButton
                    label='Cancel'
                    icon='icon-close'
                    style={{
                      width: '150px',
                      color: '#53627c',
                      backgroundColor: '#fff',
                      border: '1px solid #e1e4e8',
                    }}
                    onClick={this.props.onClose}
                  />
                </div>
              )}
              {this.props.handleConfirm && (
                <div className='m-3'>
                  <SubmitButton
                    label={this.props.confirmLabel}
                    icon={this.props.confirmIcon}
                    style={{
                      width: '150px',
                      backgroundColor: this.props.confirmColor,
                    }}
                    onClick={() => {
                      try {
                        this.props.handleConfirm();
                      } catch (e) {
                      } finally {
                        if (!this.props.avoidCloseOnConfirm) this.props.onClose();
                      }
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DialogBox;
