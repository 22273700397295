import React from 'react';
import { WrappedFieldInputProps } from 'redux-form';
import { Switch } from 'antd';

import './permission-item.component.scss';

interface PermissionItemProps {
  input: WrappedFieldInputProps;
  label: string;
  isBasic: boolean;
  disableChildren: boolean;
}

export function PermissionItem(props: PermissionItemProps): JSX.Element {
  const onChange = (value: boolean) => props.input.onChange(value);
  const onClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div onClick={onClick} className='PermissionItem'>
      <Switch checked={!!props.input.value} onChange={onChange} disabled={props.isBasic || props.disableChildren} />
      <span className='ml-3'>{props.label}</span>
    </div>
  );
}
