import React, { Component } from 'react';

import './ItemHandler.scss';
import get from 'lodash/get';

export default class ItemHandler extends Component {
  onDelete(e) {
    this.props.removeComponent();
    e.stopPropagation();
    e.preventDefault();
  }

  onReload(e) {
    this.props.reloadMetrics();
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    const { onSelect, onDelete, item, selected, mode } = this.props;
    let canUpdateMetric = get(item, 'reload_data.can_update');
    return (
      <div className='ItemHandler full-card'>
        {mode !== 'view' && (
          <div className='action-icon-details'>
            {canUpdateMetric && (
              <span className='material-icons mr-2' title='update data' onClick={this.onReload.bind(this)}>
                refresh
              </span>
            )}
            <span className='material-icons mr-2' title='remove card' onClick={this.onDelete.bind(this)}>
              delete_outline
            </span>
          </div>
        )}
        <div className='full-card'>{this.props.children}</div>
      </div>
    );
  }
}
