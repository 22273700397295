export enum ButtonTypeEnum {
  Button = 'button',
  Reset = 'reset',
  Submit = 'submit',
}
export enum ButtonSizeEnum {
  Small = 'small',
  Default = 'default',
  Large = 'large',
}
