import axios from 'axios';
import { browserHistory } from 'react-router';
import { at } from 'Utils/objectUtils';

const api = axios.create({ baseURL: process.env.API_URL + '/v1' });

api.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  error => Promise.reject(error),
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const status = at(JSON.parse(JSON.stringify(error)), 'response.status');
    if (status == 404) browserHistory.push('notFound');
    return Promise.reject(JSON.parse(JSON.stringify(error)));
  },
);

export default api;
