import React, { Component } from 'react';
import SubTabs from 'SharedComponent/SubTabs';

import './ReportsContext.scss';

class ReportsContext extends Component {
  constructor(props) {
    super(props);
  }

  get routes() {
    const routes = [];

    routes.push({ route: '/', label: 'Reports', icon: <i className='icon-book-open subtab-icon' /> });
    routes.push({ route: '/templates', label: 'Templates', icon: <i className='icon-puzzle subtab-icon' /> });

    return routes;
  }

  render() {
    return (
      <div className='ReportsContext'>
        <div className='display-flex-start'>
          <div className='breadcrumbs-card'>
            <i className='icon-book-open' />
          </div>
          <div className='breadcrumbs-card-text'>Reports</div>
        </div>
        <div className='white-container'>
          <div className=''>
            <SubTabs root='/reports' items={this.routes} />
            <div className='child-container'>{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportsContext;
