import structure from 'redux-form/lib/structure/plain';
import { Icon, Input, Select } from 'antd';
import classNames from 'classnames';
import Error from '../TextField/Error';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';
import api from 'ReduxActions/api';

import './MonetaryField.scss';

const { Option } = Select;

export default function MonetaryField(props) {
  const [options, setOptions] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const amount = fieldsToArray(props)[props.amount];
  const code = fieldsToArray(props)[props.code].input.value;
  const currency_id = fieldsToArray(props)[props.currency].input.value;
  const isSet = code && currency_id;

  function onSearch(text) {
    setSpinner(true);
    getList(text, '/currencies', 'code', 'currency_id')
      .then(res => {
        setOptions(res);
        setSpinner(false);
      })
      .catch(err => setSpinner(false));
  }

  const onChange = e => {
    const { value } = e.target;
    const removedText = value.replace(/\D+/g, '');
    amount.input.onChange(removedText);
    // const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    // if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
    //   amount.input.onChange(value);
    // }
  };

  const afterChange = (label, value) => {
    fieldsToArray(props)[props.code].input.onChange(label);
    fieldsToArray(props)[props.currency].input.onChange(value);
  };

  return (
    <div className='MonetaryField'>
      <div className='display-flex-between'>
        {props.label && (
          <div className={classNames('default-color', props.labelClass ? props.labelClass : 'input-label')}>
            {props.label}
            {props.required && (
              <span className='' style={{ color: '#ff8181' }}>
                &nbsp;&#9679;
              </span>
            )}
          </div>
        )}
        <Error
          touched={(amount.meta && amount.meta.touched) || (fieldsToArray(props)[props.currency].meta && fieldsToArray(props)[props.currency].meta.touched)}
          error={(amount.meta && amount.meta.error) || (fieldsToArray(props)[props.currency].meta && fieldsToArray(props)[props.currency].meta.error)}
          warning={(amount.meta && amount.meta.warning) || (fieldsToArray(props)[props.currency].meta && fieldsToArray(props)[props.currency].meta.warning)}
        />
      </div>
      <div className='row no-marging'>
        <Input.Group compact style={{ width: '100%', ...props.style }} size='large'>
          <Input
            {...amount.input}
            addonBefore={props.addonBefore}
            addonAfter={props.addonAfter}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder || 'Value'}
            style={{ width: '50%', ...props.inputStyle }}
            disabled={props.disabled}
            onChange={onChange}
          />
          <Select
            labelInValue
            value={isSet ? { label: code, key: currency_id } : undefined}
            allowClear={true}
            disabled={props.disabled}
            showArrow={false}
            showSearch={true}
            placeholder='Type currency'
            filterOption={false}
            notFoundContent={spinner ? <Icon type='loading' style={{ fontSize: 24 }} spin /> : 'No results'}
            loading={spinner}
            onSearch={debounce(onSearch, 300)}
            onSelect={e => afterChange(e && e.label, e && e.key)}
            onBlur={e => afterChange(e && e.label, e && e.key)}
            onChange={e => {
              if (!e) afterChange(null, null);
            }}
            style={{ width: '50%' }}
          >
            {options.map(d => (
              <Option key={d.currency_id}>{d.code}</Option>
            ))}
          </Select>
        </Input.Group>
      </div>
    </div>
  );
}

function getList(input, url, field, value, filters, code) {
  if (!input) {
    return Promise.resolve([]);
  }
  let query = `filter[${field}][like]=${input}`;
  if (filters) query += `&fields=${filters}`;
  const filterResults = results =>
    results.map(result => ({
      code: result[field],
      currency_id: result[value],
    }));
  return api.get(`${url}?${query}`).then(({ data }) => {
    return Promise.resolve(filterResults(data.results));
  });
}

function fieldsToArray({ names, ...props }) {
  const fields = {};
  let lastNames = [...names];
  if (props.formSection) lastNames = [...names].map(n => n.split(/[.]/).pop());
  lastNames.forEach(n => {
    fields[n] = structure.getIn(props, n);
  });
  return fields;
}
