import { ApiUrlsEnum } from '../helpers/api-url';
import { get } from './helpers/base-http';
import { apiUrlMatcher } from '../helpers/api-url-matcher';

import { SelectItemData } from '../../shared/interfaces/select-item';
import { StageLightDTO } from '../generated/model/models';

import { StageLightData } from '../models/stage/stage-light-data';
import { HttpParamsType } from '../models/queries/http-params.type';
import { PaginationQuery } from '../models/queries/pagination-query.model';
import { LazyLoadResponse } from '../models/queries/lazy-response.model';
import { MetaQuery } from '../models/queries/meta-query.model';

export async function getStageList(_: HttpParamsType<PaginationQuery>, pipelineId: string): Promise<LazyLoadResponse<SelectItemData[]>> {
  const data = await get<StageLightDTO[]>(apiUrlMatcher(ApiUrlsEnum.LightStageByPipeline, { pipelineId }));

  return {
    _meta: {} as MetaQuery,
    results: data.map(user => StageLightData.mapToSelectedItem(user)),
  };
}
