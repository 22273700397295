import React, { Component } from 'react';

import './FileInput.scss';
class FileInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };

    this.handleUpload = this.handleUpload.bind(this);
  }

  handleUpload(it) {
    this.props.isLoading(true);
    let url = process.env.CLOUDINARY_URL_UPLOAD;
    let xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState == 4 && xhr.status == 200) {
        let response = JSON.parse(xhr.responseText);
        let url = response.secure_url;

        this.props.isLoading(false);
        this.props.onChange({ url, width: response.width, height: response.height });
      }
    }.bind(this);

    xhr.onerror = function (e) {
      this.props.isLoading(false);
      this.props.onChange('error cloudinary');
    }.bind(this);

    let file = typeof it == 'string' ? Object.assign((0, _dataUriToBlob2.default)(it), { name: 'photo.jpg' }) : it.target.files[0];

    if (file == null) {
      this.refs.finalUrl.value = '';
      return;
    }

    let data = new FormData();
    data.append('file', file);

    data.append('upload_preset', process.env.CLOUDINARY_UPLOAD_PRESET);
    data.append('tags', 'browser_upload');
    xhr.send(data);
  }

  dataURItoBlob(dataURI) {
    let binary = atob(dataURI.split(',')[1]);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  }

  render() {
    return (
      <div className='FileInput'>
        <label
          className='btn btn-primary mb-0'
          style={{
            width: '100%',
            backgroundColor: 'rgba(3, 166, 255, 0.06)',
            color: '#03a6ff',
            border: 'none',
            ...this.props.style,
          }}
        >
          <i className='icon-picture mr-2' />
          {this.props.label}
          <div onClick={() => this.props.gaEvent()}>
            <input id='filesToUpload' type='file' className='form-control' onChange={this.handleUpload} accept='image/x-png,image/gif,image/jpeg' />
          </div>
        </label>
      </div>
    );
  }
}

export default FileInput;
