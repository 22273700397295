import React, { Component } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { connect } from 'react-redux';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import debounce from 'lodash/debounce';
import Button from 'react-bootstrap/lib/Button';
import TextField from 'SharedComponent/TextField';
import * as validators from 'Utils/validators';

import './ManualTools.scss';

const selector = formValueSelector('report-sidebar-form');

const DragHandle = SortableHandle(() => (
  // <i className={classNames("icon-menu ml-2 pt-3 cursor-pointer")} />
  <div />
));
const SortableItem = SortableElement(({ column, i, fields, onEditColumn }) => {
  return (
    <li key={i} className='stageItem'>
      <div className='display-flex-between mb-4'>
        <div className='flex-grow-3'>
          <Field
            label='Column Name'
            name={`${column}.name`}
            component={TextField}
            type='text'
            placeholder="Enter column's name"
            onChange={() => onEditColumn('on change')}
            required
            labelClass='font-size-14 font-weight-500 label-color'
          />
        </div>
        <i
          className='icon-trash ml-2 pt-3 cursor-pointer'
          onClick={() => {
            fields.remove(i);
            onEditColumn('remove');
          }}
        />
        <DragHandle />
      </div>
    </li>
  );
});

class ManualTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.currentRefinement,
    };

    this.handleKeyUp = debounce(this.handleKeyUp.bind(this), 500);
  }

  handleKeyUp(e) {
    this.props.updateElement('columns', this.props.columns);
  }

  render() {
    return (
      <div className='ManualTools'>
        <FieldArray
          name='columns'
          component={renderColumns}
          props={{
            onSortEnd: this.onSortEnd,
            useDragHandle: true,
          }}
          onEditColumns={this.handleKeyUp}
        />
        {/* <div className="p-3">
          <hr />
          <Button
            className="login-form-btn display-flex-center"
            onClick={() => {}}
            style={{
              width: "100%",
              backgroundColor: "rgba(3, 166, 255, 0.06)",
              color: "#03a6ff",
            }}
          >
            <i className="icon-plus mr-2" />
            New Row
          </Button>
        </div> */}
      </div>
    );
  }
}

const renderColumns = SortableContainer(({ fields, meta: { error, submitFailed }, onEditColumns }) => {
  if (!fields.length) fields.push();
  return (
    <div className='stages-container p-3'>
      {fields.map((value, i) => (
        <SortableItem key={`item-${i}`} i={i} column={value} index={i} fields={fields} onEditColumn={onEditColumns} />
      ))}
      <Button
        className='login-form-btn display-flex-center'
        onClick={() => {
          fields.push({});
          onEditColumns('add column');
        }}
        style={{
          width: '100%',
          backgroundColor: 'rgba(3, 166, 255, 0.06)',
          color: '#03a6ff',
        }}
      >
        <i className='icon-plus mr-2' />
        New Column
      </Button>
    </div>
  );
});

ManualTools = connect((state, ownProps) => {
  return {
    columns: selector(state, 'columns'),
  };
})(ManualTools);
export default ManualTools;
