import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import * as validators from 'Utils/validators';
import DialogBox from 'SharedComponent/DialogBox';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';
import SubmitButton from 'SharedComponent/SubmitButton';
import TextField from 'SharedComponent/TextField';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './AddInvestor.scss';

class AddInvestor extends Component {
  static propTypes = {
    showDetails: PropTypes.bool,
    onDismiss: PropTypes.func,
  };

  static defaultProps = {
    showDetails: false,
    onDismiss: new Function(),
  };

  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  formatInvestor(investor) {
    return {
      email: investor.email,
      fullname: investor.invProfile.fullname,
      phone: investor.invProfile.phone,
    };
  }

  closeModal() {
    this.props.reset();
    this.props.initialize(null);
    this.props.onDismiss();
  }

  submit(values) {
    if (this.props.initialValues) {
      this.props.onUpdate({ ...this.formatInvestor(values), id: values.id });
      gaEvent(appCategories.settings_users, appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.update}`, appEvents.clicked).button);
    } else {
      this.props.onSave(this.formatInvestor(values));
      gaEvent(appCategories.settings_users, appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.save}`, appEvents.clicked).button);
    }
    this.closeModal();
  }

  onCloseDialogBox() {
    this.props.initialValues
      ? gaEvent(appCategories.settings_users, appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon)
      : gaEvent(appCategories.settings_users, appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
    this.closeModal();
  }

  onClickCancelBtn() {
    this.props.initialValues
      ? gaEvent(appCategories.settings_users, appActions(`${appTypes.edit}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button)
      : gaEvent(appCategories.settings_users, appActions(`${appTypes.add}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).button);
    this.closeModal();
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, initialValues } = this.props;
    if (!this.props.showDetails) return null;
    return (
      <div className='AddInvestor'>
        <DialogBox
          isVisible
          onClickOut={this.closeModal}
          onClose={() => this.onCloseDialogBox()}
          className='small-dialog'
          title={initialValues ? 'Update investor' : 'Add new investor'}
        >
          <form className='p-3' onSubmit={handleSubmit(this.submit)}>
            <div className='display-flex-center'>
              <Field label='Full name' name={`invProfile.fullname`} component={TextField} type='text' placeholder='Add full name' required validate={[validators.required]} />
            </div>
            <div className='display-flex-center'>
              <Field label='Email' name={`email`} component={TextField} type='text' placeholder='Add Email' required validate={[validators.required]} />
            </div>
            <div className='display-flex-center'>
              <Field label='Phone' name={`invProfile.phone`} component={TextField} type='text' placeholder='Phone number' />
            </div>
            <div className='display-flex-end'>
              <div className='m-3'>
                <SubmitButton
                  label='Cancel'
                  icon='icon-close'
                  style={{
                    width: '150px',
                    color: '#53627c',
                    backgroundColor: '#fff',
                    border: '1px solid #e1e4e8',
                  }}
                  onClick={() => this.onClickCancelBtn()}
                />
              </div>
              <div className='m-3'>
                <Button disabled={submitting || pristine} type='submit' className='submit-btn-p'>
                  <i className='icon-check mr-2' />
                  {initialValues ? 'UPDATE' : 'SAVE'}
                </Button>
              </div>
            </div>
          </form>
        </DialogBox>
      </div>
    );
  }
}

AddInvestor = reduxForm({ form: 'add-new-investor', enableReinitialize: true })(AddInvestor);
AddInvestor = connect(state => ({
  initialValues: state.settings.users.editing,
}))(AddInvestor);
export default AddInvestor;
