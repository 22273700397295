import lodash from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, WrappedFieldArrayProps, GenericFieldArray, FieldArray, change } from 'redux-form';
import { Collapse } from 'antd';

import { Empty, Skeleton } from '../../../../shared/components';

import { PermissionSummaryData } from '../../../../core/models/permission/permission-summary-data';
import { PageSummaryData } from '../../../../core/models/permission/page-summary-data';
import { FormIdEnum } from '../../../../core/enums/entity-ids/form-id.enum';

import { PermissionItem } from '../permission-item/permission-item.component';
import { renderPermissions } from './permission-block/permission-block.component';

import { getActionEntities, loadingPages } from '../state/permissions.selectors';

import './list-pages.component.scss';

const FieldArrayCustomPage = FieldArray;
const customPanelStyle = {
  background: '#f8f9fa',
  borderRadius: 4,
  marginBottom: 2,
  border: 0,
  overflow: 'hidden',
};

export function ListPages(props) {
  const dispatch = useDispatch();
  const loading = useSelector(loadingPages);
  const entities = useSelector(getActionEntities);

  if (loading) {
    return <Skeleton loading={true} paragraph={{ rows: 3 }} title={true} />;
  } else if (!props.fields.length) {
    return <Empty description='No pages found' />;
  }

  const changeParent = (index, blocks) => value => {
    blocks.children.forEach((permissionSummary, idx) => {
      dispatch(change(FormIdEnum.PermissionsSettingsForm, `pages[${index}].children[${idx}].isEnabled`, value));

      permissionSummary.relatedPermissions.forEach(item => {
        changeChildren(item, value);
      });
    });

    blocks.parent.relatedPermissions.forEach(relatedPerm => {
      changeChildren(relatedPerm, value);
    });
  };

  function changeChildren(block, value) {
    if (!lodash.isEmpty(entities[block.id])) {
      changeForm(entities[block.id].x, entities[block.id].y, value);
    }

    (block.relatedPermissions || []).forEach(relatedPermission => {
      changeForm(entities[relatedPermission.id].x, entities[relatedPermission.id].y, value);

      if ((relatedPermission.relatedPermissions || []).length) {
        changeChildren(relatedPermission, value);
      }
    });
  }

  function changeForm(x, y, value) {
    dispatch(change(FormIdEnum.PermissionsSettingsForm, `actions[${x}].permissions[${y}].isEnabled`, value));
  }

  const activePanel = () => props.fields.map((__, index) => (props.fields.get(index).children.length ? index + 1 : undefined));

  return (
    <div className='ListPages'>
      <div>Page Permission</div>
      <Collapse bordered={false} expandIconPosition='right' defaultActiveKey={activePanel()}>
        {props.fields.map((block, index) => {
          return (
            <Collapse.Panel
              disabled={props.fields.get(index).children.length ? false : true}
              header={props.fields.get(index).parent && props.fields.get(index).parent.name}
              key={index + 1}
              style={customPanelStyle}
              showArrow={props.fields.get(index).children.length ? true : false}
              extra={<Field name={`${block}.parent.isEnabled`} onChange={changeParent(index, props.fields.get(index))} component={PermissionItem} isBasic={props.isBasic} />}
            >
              <FieldArrayCustomPage
                name={`${block}.children`}
                component={renderPermissions}
                disableChildren={!props.fields.get(index).parent.isEnabled}
                isBasic={props.isBasic}
                onChange={changeChildren}
              />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
}
