import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CompactPicker, SketchPicker } from 'react-color';
import picker from './picker.svg';

export default class ColorPicker extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    onExpandEvent: PropTypes.func,
    onChange: PropTypes.func,
    currentState: PropTypes.object,
  };

  stopPropagation = event => {
    event.stopPropagation();
  };

  onChange = color => {
    const { onChange } = this.props;
    onChange('color', color.hex);
  };

  renderModal = () => {
    const { color } = this.props.currentState;
    const style = {
      position: 'absolute',
      zIndex: '40',
      right: 0,
    };
    return (
      <div style={this.props.toolbarOnFocus ? style : {}} onClick={this.stopPropagation}>
        <CompactPicker color={color} onChangeComplete={this.onChange} />
      </div>
    );
  };

  render() {
    const { expanded, onExpandEvent } = this.props;
    return (
      <div
        aria-haspopup={true}
        aria-expanded={expanded}
        // aria-label="rdw-color-picker"
      >
        <div onClick={onExpandEvent}>
          <img src={picker} width='25' height='25' style={{ border: '1px solid #F1F1F1' }} />
        </div>
        {expanded ? this.renderModal() : undefined}
      </div>
    );
  }
}
