import React from 'react';

import dealLogo from 'Assets/img/deal-pentugram.svg';
import excel1 from 'Assets/img/excel-1.png';

export const dealStatus = [
  { value: 1, label: 'Open deals' },
  { value: 2, label: 'Deleted deals' },
  { value: 3, label: 'Lost deals' },
  { value: 4, label: 'Won deals' },
];

export const pipelineDealStatus = [
  { value: 1, label: 'Open deals' },
  { value: 2, label: 'Deleted deals' },
  { value: 3, label: 'Lost deals' },
  { value: 5, label: 'Draft deals' },
];

export const dealLabelStatus = {
  1: { label: 'Open', color: '', backgroundColor: '', tag: '' },
  2: { label: 'Deleted', color: '#f94839', backgroundColor: '#fff', tag: '#f94839' },
  3: { label: 'Lost', color: '#fff', backgroundColor: '#ff8181', tag: '#ff8181' },
  4: { label: 'Won', color: '#fff', backgroundColor: '#06d26f', tag: '#06d26f' },
  5: { label: 'Draft', color: '#fff', backgroundColor: '#06d26f', tag: '#ffdfac' },
};

export function dealLabelSources(source, status, iconClass) {
  switch (source) {
    case 1:
      return {
        label: 'Pentugram',
        icon: <img src={dealLogo} height='13' width='13' />,
      };
    case 2: {
      if (status == 5)
        return {
          label: 'Online form ( DRAFT )',
          icon: (
            <i className={`material-icons ${iconClass}`} style={{ color: '#ffdfac' }}>
              public
            </i>
          ),
        };
      else
        return {
          label: 'Online form',
          icon: (
            <i className={`material-icons ${iconClass}`} style={{ color: '#c5c9cc' }}>
              public
            </i>
          ),
        };
    }
    case 3:
      return {
        label: 'Email',
        icon: <i className={`material-icons ${iconClass}`}>email</i>,
      };
    case 4:
      return {
        label: 'Excel',
        icon: <img src={excel1} height='13' width='13' />,
      };
    default:
      return { label: '', icon: <img src={dealLogo} height='13' width='13' /> };
  }
}
