import { SortOrder } from 'antd/lib/table/interface';
import { SortDTO } from '../generated/model/models';
import { IMap } from '../utils/map-utils';

export enum InnerSortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export namespace InnerSortDirectionEnum {
  export const invertDirection: IMap<InnerSortDirectionEnum, InnerSortDirectionEnum> = new IMap<InnerSortDirectionEnum, InnerSortDirectionEnum>(
    [
      [InnerSortDirectionEnum.Asc, InnerSortDirectionEnum.Desc],
      [InnerSortDirectionEnum.Desc, null],
    ],
    InnerSortDirectionEnum.Asc,
  );

  export const convertFromApiValue: IMap<SortDTO.DirectionEnum, InnerSortDirectionEnum> = new IMap<SortDTO.DirectionEnum, InnerSortDirectionEnum>(
    [[SortDTO.DirectionEnum.NUMBER_3, InnerSortDirectionEnum.Desc]],
    InnerSortDirectionEnum.Asc,
  );

  export const convertToApiValue: IMap<InnerSortDirectionEnum, SortDTO.DirectionEnum> = new IMap<InnerSortDirectionEnum, SortDTO.DirectionEnum>(
    [[InnerSortDirectionEnum.Desc, SortDTO.DirectionEnum.NUMBER_3]],
    SortDTO.DirectionEnum.NUMBER_4,
  );

  export const convertFromAntdParams: IMap<SortOrder, InnerSortDirectionEnum> = new IMap<SortOrder, InnerSortDirectionEnum>(
    [
      ['descend', InnerSortDirectionEnum.Desc],
      ['ascend', InnerSortDirectionEnum.Asc],
    ],
    null,
  );

  export const convertToAntdParams: IMap<InnerSortDirectionEnum, SortOrder> = new IMap<InnerSortDirectionEnum, SortOrder>(
    [
      [InnerSortDirectionEnum.Desc, 'descend'],
      [InnerSortDirectionEnum.Asc, 'ascend'],
    ],
    null,
  );
}
