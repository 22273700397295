import React, { ReactNode } from 'react';
import { Timeline as AntdTimeLine } from 'antd';
import { TimeLinesMode } from '../../interfaces/timeLine-data';
import { timeLinePlacementEnum } from '../../enums/timeLine-placement.enum';
import './timeLine.scss';

interface TimeLineProps {
  label?: string | ReactNode;
  text?: string | ReactNode;
  dot?: string | ReactNode;
  mode: TimeLinesMode;
  className?: string;
}
export const TimeLine = (props: TimeLineProps): JSX.Element => {
  return (
    <AntdTimeLine mode={props.mode} className={props.className}>
      <div className='TimeLine'>
        {props.label && <span className='label-time-line-details'>{props.label}</span>}
        <div className='content-time-line-details'>
          <AntdTimeLine.Item dot={props.dot}>{props.text}</AntdTimeLine.Item>
        </div>
      </div>
    </AntdTimeLine>
  );
};

TimeLine.defaultProps = {
  mode: timeLinePlacementEnum.Left,
};
