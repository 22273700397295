import React from 'react';
import { CheckBox } from 'SharedComponent/CheckBox/CheckBox';
import ID from 'Utils/UUID.js';

export default class DynamicOptionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      element: this.props.element,
      data: this.props.data,
      dirty: false,
    };
  }

  _setValue(text) {
    return text.replace(/[^A-Z0-9]+/gi, '_').toLowerCase();
  }

  editOption(option_index, e) {
    let this_element = this.state.element;
    let val =
      this_element.options[option_index].value !== this._setValue(this_element.options[option_index].text)
        ? this_element.options[option_index].value
        : this._setValue(e.target.value);

    this_element.options[option_index].text = e.target.value;
    this_element.options[option_index].value = val;
    this.setState({
      element: this_element,
      dirty: true,
    });
  }

  editValue(option_index, e) {
    let this_element = this.state.element;
    let val = e.target.value === '' ? this._setValue(this_element.options[option_index].text) : e.target.value;
    this_element.options[option_index].value = val;
    this.setState({
      element: this_element,
      dirty: true,
    });
  }

  editOptionCorrect(option_index, e) {
    let this_element = this.state.element;
    if (this_element.options[option_index].hasOwnProperty('correct')) {
      delete this_element.options[option_index]['correct'];
    } else {
      this_element.options[option_index].correct = true;
    }
    this.setState({ element: this_element });
    // this.props.updateElement.call(this.props.preview, this_element);
  }

  updateOption() {
    let this_element = this.state.element;
    if (this.state.dirty) {
      // this.props.updateElement.call(this.props.preview, this_element);
      this.setState({ dirty: false });
    }
  }

  addOption(index) {
    let this_element = this.state.element;
    this_element.options.splice(index + 1, 0, {
      value: '',
      text: '',
      key: ID.uuid(),
    });
    this.setState({ element: this_element });
    // this.props.updateElement.call(this.props.preview, this_element);
  }

  removeOption(index) {
    let this_element = this.state.element;
    this_element.options.splice(index, 1);
    this.setState({ element: this_element });
    // this.props.updateElement.call(this.props.preview, this_element);
  }

  render() {
    if (this.props.element.listOfCountries) return null;
    return (
      <div className='dynamic-option-list'>
        <ul>
          <li>
            <div className='row'>
              <div className='col-sm-9'>
                <b>Options</b>
              </div>
            </div>
          </li>
          {this.props.element.options.map((option, index) => {
            let this_key = 'edit_' + option.key;
            let val = option.value !== this._setValue(option.text) ? option.value : '';
            return (
              <li className='clearfix' key={this_key}>
                <div className='row option-edit-row-details'>
                  <div className='col-sm-9'>
                    <input
                      tabIndex={index + 1}
                      className='form-control'
                      style={{ width: '100%' }}
                      type='text'
                      name={'text_' + index}
                      placeholder='Option text'
                      value={option.text}
                      onBlur={this.updateOption.bind(this)}
                      onChange={this.editOption.bind(this, index)}
                    />
                  </div>
                  <div className='col-sm-3'>
                    <div className='dynamic-options-actions-buttons'>
                      <button onClick={this.addOption.bind(this, index)} className='btn btn-success add-remove-btn'>
                        <i className='material-icons'>add</i>
                      </button>
                      {index > 0 && (
                        <button onClick={this.removeOption.bind(this, index)} className='btn btn-danger add-remove-btn ml-2'>
                          <i className='material-icons'>remove</i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
