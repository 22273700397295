import { SelectItemData } from '../../../shared/interfaces/select-item';
import { StageLightDTO } from '../../generated/model/models';

export interface StageLightData {
  id: string;
  name: string;
}

export namespace StageLightData {
  export function mapToApiValue(stage: StageLightDTO): StageLightData {
    return {
      id: stage.stage_id.toString(),
      name: stage.name,
    };
  }

  export function mapToSelectedItem(stage: StageLightDTO): SelectItemData {
    return {
      value: stage.stage_id.toString(),
      name: stage.name,
    };
  }
}
