import { createReducer, Action, createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import lodash from 'lodash';

import { PipelineViewTabsEnum } from '../../../core/enums/tabs/pipeline-view-tabs.enum';
import { LocalStorageKeyEnum } from '../../../core/enums/local-storage.enum';
import { getFromLocalStorage } from '../../../core/utils/local-storage-utils';
import { StageSummaryData } from '../../../core/models/stage/stage-summary-data';
import { ErrorResponseData } from '../../../core/models/http-params.type';
import { DealForGridViewSummaryData } from '../../../core/models/deal/deal-for-grid-view-summary';

import * as fromPipelineActions from './pipeline.actions';
import { IDealsByStage } from './types';

const stageAdapter = createEntityAdapter<StageSummaryData>({
  selectId: stage => stage.id,
  sortComparer: (a, b) => String(a.order).localeCompare(String(b.order)),
});

export const selectAllStages = stageAdapter.getSelectors().selectAll;
export const selectAllStagesIds = stageAdapter.getSelectors().selectIds;

export interface StageState extends EntityState<StageSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
}

export const stageInitialState: StageState = stageAdapter.getInitialState<StageState>({
  ids: [],
  entities: {},
  loading: false,
  error: null,
});

export interface GridState {
  deals: DealForGridViewSummaryData[];
  dealsByStages: IDealsByStage;
  loading: boolean;
  totalCount: number;
  totalFilteredCount: number;
  error: ErrorResponseData;
}

export const gridInitialState: GridState = {
  deals: [],
  dealsByStages: {},
  loading: false,
  error: null,
  totalCount: undefined,
  totalFilteredCount: undefined,
};

export interface PipelineState {
  loading: boolean;
  view: string;
  selectedPipelineId: string;
  stages: StageState;
  grid: GridState;
}

export const pipelineInitialState: PipelineState = {
  loading: false,
  view: getFromLocalStorage<string>(LocalStorageKeyEnum.PipelineView, PipelineViewTabsEnum.Grid),
  selectedPipelineId: getFromLocalStorage<string>(LocalStorageKeyEnum.DefaultPipelineId),
  stages: stageInitialState,
  grid: gridInitialState,
};

const _pipelineReducer = createReducer<PipelineState>(pipelineInitialState, builder => {
  builder
    .addCase(fromPipelineActions.setPipelineView, (state, { payload: { view } }): PipelineState => ({ ...state, view }))
    .addCase(fromPipelineActions.setSelectedPipeline, (state, { payload: { pipelineId } }): PipelineState => ({ ...state, selectedPipelineId: pipelineId }))
    .addCase(
      fromPipelineActions.loadPipelineStageSuccess,
      (state, { payload: { stages } }): PipelineState => {
        const newState: StageState = { ...state.stages, loading: false };

        return {
          ...state,
          stages: stageAdapter.setAll(newState, stages),
        };
      },
    )
    .addCase(
      fromPipelineActions.loadDealListForGridViewSuccess,
      (state, { payload: { deals, reset } }): PipelineState => {
        const newDeals = reset ? deals : [...state.grid.deals, ...deals];
        const newGrid: GridState = {
          ...state.grid,
          deals: newDeals,
          dealsByStages: lodash.groupBy(newDeals, 'stageId'),
          loading: false,
          totalCount: 0,
        };

        return { ...state, grid: newGrid };
      },
    );
});

export function pipelineReducer(state: PipelineState | undefined, action: Action): PipelineState {
  return _pipelineReducer(state, action);
}
