import React from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import { PermissionSummaryData } from '../../../../../core/models/permission/permission-summary-data';

import { PermissionItem } from '../../permission-item/permission-item.component';

export const renderPermissions = props => {
  const onFieldChange = blocks => value => props.onChange(blocks, value);

  return (
    <div className='actions-block'>
      {props.fields.map((permission, index) => {
        return (
          <Field
            key={index + 1}
            name={`${permission}.isEnabled`}
            component={PermissionItem}
            disableChildren={props.disableChildren}
            isBasic={props.isBasic}
            label={props.fields.get(index).name}
            onChange={onFieldChange(props.fields.get(index))}
          />
        );
      })}
    </div>
  );
};
