import moment from 'moment';
import { EmailResponseDTO } from '../../../generated/model/emailResponseDTO';
import { DealLightData } from '../../deal/deal-light-data';
import { SenderLightData } from '../../sender/sender-for-email-data';

export interface EmailSummaryData {
  emailId: string;
  from: string;
  message: string;
  createdAt: string;
  deal: DealLightData;
  sender: SenderLightData;
  content: string;
}

export namespace EmailSummaryData {
  export function mapFromApi(email: EmailResponseDTO): EmailSummaryData {
    return {
      emailId: email.email_id.toString(),
      from: email.from.toString(),
      message: email.subject,
      createdAt: moment(email.created_at * 1000)
        .startOf('day')
        .fromNow(),
      deal: email.deal && DealLightData.mapFromApi(email.deal),
      sender: email.sender && SenderLightData.mapFromApi(email.sender),
      content: email.html,
    };
  }
}
