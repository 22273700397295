import { KpiResponseDTO } from '../../generated/model/kpiResponseDTO';
import { defaultIcon, IconData, IconPrefixEnum } from '../../../shared/interfaces/icon-data';
import { IMap } from '../../utils/map-utils';

export enum DashboardKpisEnum {
  OpenDeals = 1,
  PortfolioCompanies = 2,
  TotalInvestment = 3,
  RoundInvestments = 4,
  DryPowder = 5,
  SubmittedApplications = 6,
  PortfoliosValue = 7,
  ConvertibleInvestments = 8,
  EquityInvestments = 9,
  TopDealSource = 10,
  TopDealsCountry = 11,
  TopNoteTaker = 12,
  Exists = 13,
  Nav = 14,
  Irr = 15,
}

export namespace DashboardKpisEnum {
  export const convertFromApiValue: IMap<KpiResponseDTO.TypeEnum, DashboardKpisEnum> = new IMap<KpiResponseDTO.TypeEnum, DashboardKpisEnum>(
    [
      [KpiResponseDTO.TypeEnum.OpenDeal, DashboardKpisEnum.OpenDeals],
      [KpiResponseDTO.TypeEnum.PortfolioCompanies, DashboardKpisEnum.PortfolioCompanies],
      [KpiResponseDTO.TypeEnum.TotalInvestment, DashboardKpisEnum.TotalInvestment],
      [KpiResponseDTO.TypeEnum.RoundInvestment, DashboardKpisEnum.RoundInvestments],
      [KpiResponseDTO.TypeEnum.DryPowder, DashboardKpisEnum.DryPowder],
      [KpiResponseDTO.TypeEnum.SubmittedApplication, DashboardKpisEnum.SubmittedApplications],
      [KpiResponseDTO.TypeEnum.PortfolioValue, DashboardKpisEnum.PortfoliosValue],
      [KpiResponseDTO.TypeEnum.ConvertibleInvestment, DashboardKpisEnum.ConvertibleInvestments],
      [KpiResponseDTO.TypeEnum.EquityInvestment, DashboardKpisEnum.EquityInvestments],
      [KpiResponseDTO.TypeEnum.TopDealSource, DashboardKpisEnum.TopDealSource],
      [KpiResponseDTO.TypeEnum.TopDealCountry, DashboardKpisEnum.TopDealsCountry],
      [KpiResponseDTO.TypeEnum.TopNoteTaker, DashboardKpisEnum.TopNoteTaker],
    ],
    null,
  );
  export const convertToApiValue: IMap<DashboardKpisEnum, KpiResponseDTO.TypeEnum> = new IMap<DashboardKpisEnum, KpiResponseDTO.TypeEnum>(
    [
      [DashboardKpisEnum.OpenDeals, KpiResponseDTO.TypeEnum.OpenDeal],
      [DashboardKpisEnum.PortfolioCompanies, KpiResponseDTO.TypeEnum.PortfolioCompanies],
      [DashboardKpisEnum.TotalInvestment, KpiResponseDTO.TypeEnum.TotalInvestment],
      [DashboardKpisEnum.RoundInvestments, KpiResponseDTO.TypeEnum.RoundInvestment],
      [DashboardKpisEnum.DryPowder, KpiResponseDTO.TypeEnum.DryPowder],
      [DashboardKpisEnum.SubmittedApplications, KpiResponseDTO.TypeEnum.SubmittedApplication],
      [DashboardKpisEnum.PortfoliosValue, KpiResponseDTO.TypeEnum.PortfolioValue],
      [DashboardKpisEnum.ConvertibleInvestments, KpiResponseDTO.TypeEnum.ConvertibleInvestment],
      [DashboardKpisEnum.EquityInvestments, KpiResponseDTO.TypeEnum.EquityInvestment],
      [DashboardKpisEnum.TopDealSource, KpiResponseDTO.TypeEnum.TopDealSource],
      [DashboardKpisEnum.TopDealsCountry, KpiResponseDTO.TypeEnum.TopDealCountry],
      [DashboardKpisEnum.TopNoteTaker, KpiResponseDTO.TypeEnum.TopNoteTaker],
    ],
    null,
  );

  export const toString: IMap<DashboardKpisEnum, string> = new IMap<DashboardKpisEnum, string>(
    [
      [DashboardKpisEnum.OpenDeals, 'Open Deals'],
      [DashboardKpisEnum.PortfolioCompanies, 'Portfolio Companies'],
      [DashboardKpisEnum.TotalInvestment, 'Total investment'],
      [DashboardKpisEnum.RoundInvestments, 'Round Investments'],
      [DashboardKpisEnum.DryPowder, 'Dry Powder'],
      [DashboardKpisEnum.SubmittedApplications, 'Received Applications'],
      [DashboardKpisEnum.PortfoliosValue, 'Portfolios Value'],
      [DashboardKpisEnum.ConvertibleInvestments, 'Convertible investments'],
      [DashboardKpisEnum.EquityInvestments, 'Equity investments'],
      [DashboardKpisEnum.TopDealSource, 'Top Deal Sourcer'],
      [DashboardKpisEnum.TopDealsCountry, 'Top Deals Country'],
      [DashboardKpisEnum.TopNoteTaker, 'Top Note Taker'],
    ],
    null,
  );
  export const getTooltip: IMap<DashboardKpisEnum, string> = new IMap<DashboardKpisEnum, string>(
    [
      [DashboardKpisEnum.OpenDeals, 'Total number of open deals under all pipelines'],
      [DashboardKpisEnum.PortfolioCompanies, 'Total number of companies that you have invested in.'],
      [DashboardKpisEnum.TotalInvestment, 'Total amount invested in all companies under all portfolios'],
      [DashboardKpisEnum.RoundInvestments, 'Total number of rounds that you have participated in.'],
      [DashboardKpisEnum.DryPowder, 'Remaining uninvested amount from your different funds.'],
      [DashboardKpisEnum.SubmittedApplications, 'Total number of applications received through the application forms'],
      [DashboardKpisEnum.PortfoliosValue, 'Total valuation of all companies under your portfolio based on the latest round valuation.'],
      [DashboardKpisEnum.ConvertibleInvestments, 'Total number of investments done via convertible notes.'],
      [DashboardKpisEnum.EquityInvestments, 'Total number of investments done as equity round.'],
      [DashboardKpisEnum.TopDealSource, 'The team member who sourced most deals.'],
      [DashboardKpisEnum.TopDealsCountry, 'The country that has the highest number of deals.'],
      [DashboardKpisEnum.TopNoteTaker, 'The team member who filled the most notes on the system.'],
    ],
    undefined,
  );
  export const getIcon: IMap<DashboardKpisEnum, IconData> = new IMap<DashboardKpisEnum, IconData>(
    [
      [DashboardKpisEnum.OpenDeals, { name: 'ri-profile-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
      [DashboardKpisEnum.PortfolioCompanies, { name: 'ri-briefcase-4-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
      [DashboardKpisEnum.TotalInvestment, { name: 'ri-money-dollar-circle-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
      [DashboardKpisEnum.RoundInvestments, { name: 'ri-money-dollar-box-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
      [DashboardKpisEnum.DryPowder, { name: 'ri-coin-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
      [DashboardKpisEnum.SubmittedApplications, { name: 'ri-file-copy-2-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
      [DashboardKpisEnum.PortfoliosValue, { name: 'ri-safe-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
      [DashboardKpisEnum.ConvertibleInvestments, { name: 'ri-swap-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
      [DashboardKpisEnum.EquityInvestments, { name: 'ri-safe-2-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
      [DashboardKpisEnum.TopDealSource, { name: 'ri-medal-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
      [DashboardKpisEnum.TopDealsCountry, { name: 'ri-flag-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
      [DashboardKpisEnum.TopNoteTaker, { name: 'ri-sticky-note-fill', prefix: IconPrefixEnum.Remix, iconClassName: 'font-rem-1_5' }],
    ],
    defaultIcon,
  );

  export const getColor: IMap<DashboardKpisEnum, string> = new IMap<DashboardKpisEnum, string>(
    [
      [DashboardKpisEnum.OpenDeals, '#0054fe'],
      [DashboardKpisEnum.PortfolioCompanies, '#fdcc0c'],
      [DashboardKpisEnum.TotalInvestment, '#2ed124'],
      [DashboardKpisEnum.RoundInvestments, '#32c5ff'],
      [DashboardKpisEnum.DryPowder, '#fa6400'],
      [DashboardKpisEnum.SubmittedApplications, '#ff00d8'],
      [DashboardKpisEnum.PortfoliosValue, '#ff65e8'],
      [DashboardKpisEnum.ConvertibleInvestments, '#19c509'],
      [DashboardKpisEnum.EquityInvestments, '#6236ff'],
      [DashboardKpisEnum.TopDealSource, '#32c5ff'],
      [DashboardKpisEnum.TopDealsCountry, '#6d7278'],
      [DashboardKpisEnum.TopNoteTaker, '#f7b500'],
    ],
    '#929699',
  );
}
