import { createReducer, Action, createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import { DashboardTaskDetailsData } from '../../../../../core/models/dashboard-task/dashboard-task-details-data';
import { ErrorResponseData } from '../../../../../core/models/http-params.type';
import { initializeMetaQueries, MetaQuery } from '../../../../../core/models/queries/meta-query.model';
import { DashboardTaskSummaryData } from '../../../../../core/models/dashboard-task/dashboard-task-summary-data';
import { SelectItemData } from '../../../../../shared/interfaces/select-item';

import * as taskActions from './task.actions';

const adapter = createEntityAdapter<DashboardTaskSummaryData>({
  selectId: task => task.uuid,
});

const zoomAdapter = createEntityAdapter<SelectItemData>({
  selectId: user => user.value,
});

export interface TasksState extends EntityState<DashboardTaskSummaryData> {
  loading: boolean;
  error: ErrorResponseData;
  meta: MetaQuery;
}

export interface ZoomState extends EntityState<SelectItemData> {
  loading: boolean;
  submitting: boolean;
  error: ErrorResponseData;
  meta: MetaQuery;
}

export const tasksInitialState: TasksState = adapter.getInitialState<TasksState>({
  ids: [],
  entities: {},
  meta: null,
  loading: false,
  error: null,
});

export const zoomInitialState: ZoomState = zoomAdapter.getInitialState<ZoomState>({
  ids: [],
  entities: {},
  meta: initializeMetaQueries(),
  loading: false,
  submitting: false,
  error: null,
});

export interface DashboardTasksState {
  tasks: TasksState;
  zoom: ZoomState;
  editing: DashboardTaskDetailsData;
  loading: boolean;
  submitting: boolean;
}

const dashboardTasksInitialState: DashboardTasksState = {
  tasks: tasksInitialState,
  zoom: zoomInitialState,
  editing: {} as DashboardTaskDetailsData,
  loading: false,
  submitting: false,
};

const _dashboardTaskReducer = createReducer<DashboardTasksState>(dashboardTasksInitialState, builder => {
  builder
    .addCase(taskActions.loadTaskList, (state): DashboardTasksState => ({ ...state, tasks: { ...state.tasks, loading: true } }))
    .addCase(taskActions.loadUserList, (state): DashboardTasksState => ({ ...state, zoom: { ...state.zoom, loading: true } }))
    .addCase(taskActions.startZoom, (state): DashboardTasksState => ({ ...state, zoom: { ...state.zoom, submitting: true } }))
    .addCase(taskActions.startZoomFail, (state): DashboardTasksState => ({ ...state, zoom: { ...state.zoom, submitting: false } }))
    .addCase(taskActions.createTask, (state): DashboardTasksState => ({ ...state, submitting: true }))
    .addCase(taskActions.updateTask, (state): DashboardTasksState => ({ ...state, submitting: true }))
    .addCase(taskActions.createTaskSuccess, (state): DashboardTasksState => ({ ...state, submitting: false }))
    .addCase(taskActions.updateTaskSuccess, (state): DashboardTasksState => ({ ...state, submitting: false }))
    .addCase(taskActions.createTaskFail, (state): DashboardTasksState => ({ ...state, submitting: false }))
    .addCase(taskActions.updateTaskFail, (state): DashboardTasksState => ({ ...state, submitting: false }))
    .addCase(taskActions.loadTask, (state): DashboardTasksState => ({ ...state, loading: true }))
    .addCase(taskActions.resetTask, (state): DashboardTasksState => ({ ...state, editing: {} as DashboardTaskDetailsData }))
    .addCase(taskActions.loadTaskSuccess, (state, { payload: { task } }): DashboardTasksState => ({ ...state, editing: task, loading: false }))
    .addCase(taskActions.startZoomSuccess, (state): DashboardTasksState => ({ ...state, zoom: { ...state.zoom, submitting: false } }))
    .addCase(
      taskActions.voteForTaskSuccess,
      (state, { payload }): DashboardTasksState => {
        const newState: TasksState = { ...state.tasks };

        return {
          ...state,
          tasks: adapter.removeOne(newState, payload.uuid),
        };
      },
    )
    .addCase(
      taskActions.updateStatusSuccess,
      (state, { payload }): DashboardTasksState => {
        const newState: TasksState = { ...state.tasks };

        return {
          ...state,
          tasks: adapter.removeOne(newState, payload.uuid),
        };
      },
    )
    .addCase(
      taskActions.loadTaskListSuccess,
      (state, { payload: { list, meta, reset } }): DashboardTasksState => {
        const newState: TasksState = { ...state.tasks, loading: false, meta };

        return {
          ...state,
          tasks: reset ? adapter.setAll(newState, list) : adapter.addMany(newState, list),
        };
      },
    )
    .addCase(
      taskActions.loadUserListSuccess,
      (state, { payload: { list, meta, reset } }): DashboardTasksState => {
        const zoom: ZoomState = { ...state.zoom, loading: false, meta };

        return {
          ...state,
          zoom: reset ? zoomAdapter.setAll(zoom, list) : zoomAdapter.addMany(zoom, list),
        };
      },
    );
});

export function dashboardTasksReducer(state: DashboardTasksState | undefined, action: Action): DashboardTasksState {
  return _dashboardTaskReducer(state, action);
}

export const selectAllTasks = adapter.getSelectors().selectAll;
export const selectAllUsers = zoomAdapter.getSelectors().selectAll;
