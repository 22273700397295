/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PipelineDealsFilterRequestDTOCustom } from './pipelineDealsFilterRequestDTOCustom';

export interface PipelineDealsFilterRequestDTO {
  status?: Array<PipelineDealsFilterRequestDTO.StatusEnum>;
  custom?: PipelineDealsFilterRequestDTOCustom;
  pipelineIds?: Array<number>;
}
export namespace PipelineDealsFilterRequestDTO {
  export type StatusEnum = '1' | '2' | '3' | '4';
  export const StatusEnum = {
    _1: '1' as StatusEnum,
    _2: '2' as StatusEnum,
    _3: '3' as StatusEnum,
    _4: '4' as StatusEnum,
  };
}
