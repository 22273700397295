import { KpiSetActiveRequestDTO } from '../../generated/model/kpiSetActiveRequestDTO';
import { KpisSummaryData } from './kpis-summary-data';

export interface KpisForReorder extends Partial<KpisSummaryData> {}

export namespace KpisForReorder {
  export function mapToApi(kpi: KpisForReorder): KpiSetActiveRequestDTO {
    return {
      uuid: kpi.uuid,
      order: kpi.order,
    };
  }
}
