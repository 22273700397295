/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ActionPermissionEnumDTO } from './actionPermissionEnumDTO';
import { CompanyDTO } from './companyDTO';
import { PagePermissionEnumDTO } from './pagePermissionEnumDTO';
import { RoleNameDTO } from './roleNameDTO';
import { SettingDTO } from './settingDTO';
import { SubscriptionDTO } from './subscriptionDTO';
import { UserProfileDTO } from './userProfileDTO';

export interface GetMeResponseDTO {
  id?: number;
  company?: CompanyDTO;
  subscription?: SubscriptionDTO;
  roleName?: RoleNameDTO;
  company_owner_name?: string;
  email?: string;
  token?: string;
  algolia_token?: string;
  /**
   *      1 - Active     2 - Inactive     3 - Invited     4 - Deactivated     5 - Deleted
   */
  status?: GetMeResponseDTO.StatusEnum;
  slack_state?: string;
  register_completed?: boolean;
  /**
   *      0 - Pentugram     1 - Google
   */
  register_source?: GetMeResponseDTO.RegisterSourceEnum;
  created_at?: number;
  profile?: UserProfileDTO;
  is_zoom_authorized?: boolean;
  is_google_calendar_authorized?: boolean;
  total_company_report?: number;
  total_portfolio_deal?: number;
  total_users?: number;
  is_slack_authorized?: boolean;
  user_attributes?: Array<GetMeResponseDTO.UserAttributesEnum>;
  setting?: SettingDTO;
  permissions?: Array<ActionPermissionEnumDTO>;
  page_permissions?: Array<PagePermissionEnumDTO>;
}
export namespace GetMeResponseDTO {
  export type StatusEnum = 1 | 2 | 3 | 4 | 5;
  export const StatusEnum = {
    NUMBER_1: 1 as StatusEnum,
    NUMBER_2: 2 as StatusEnum,
    NUMBER_3: 3 as StatusEnum,
    NUMBER_4: 4 as StatusEnum,
    NUMBER_5: 5 as StatusEnum,
  };
  export type RegisterSourceEnum = 0 | 1;
  export const RegisterSourceEnum = {
    NUMBER_0: 0 as RegisterSourceEnum,
    NUMBER_1: 1 as RegisterSourceEnum,
  };
  export type UserAttributesEnum =
    | 'VC_ACCELERATOR'
    | 'WEALTH_MANAGEMENT'
    | 'SALES'
    | 'USER'
    | 'ADMIN'
    | 'OWNER'
    | 'IC_MEMBER'
    | 'REGISTERED'
    | 'NOT_REGISTERED'
    | 'STATUS_ACTIVE'
    | 'STATUS_TRIALING'
    | 'STATUS_PAST_DUE'
    | 'STATUS_PAUSED'
    | 'STATUS_DELETED'
    | 'STATUS_PENDING'
    | 'CAN_INTEGRATE_CALENDAR'
    | 'BILLABLE_COMPANY'
    | 'CAN_INTEGRATE_ZOOM'
    | 'CAN_INTEGRATE_DRIVE'
    | 'STATUS_EXPIRED'
    | 'STATUS_NOT_EXPIRED'
    | 'CAN_NOT_CREATE_REPORT'
    | 'CAN_CREATE_REPORT'
    | 'CAN_NOT_CREATE_DEAL'
    | 'CAN_CREATE_DEAL'
    | 'CAN_ENABLE_ANGEL_INVESTOR'
    | 'ANGEL_NETWORK_ACTIVATED';
  export const UserAttributesEnum = {
    VCACCELERATOR: 'VC_ACCELERATOR' as UserAttributesEnum,
    WEALTHMANAGEMENT: 'WEALTH_MANAGEMENT' as UserAttributesEnum,
    SALES: 'SALES' as UserAttributesEnum,
    USER: 'USER' as UserAttributesEnum,
    ADMIN: 'ADMIN' as UserAttributesEnum,
    OWNER: 'OWNER' as UserAttributesEnum,
    ICMEMBER: 'IC_MEMBER' as UserAttributesEnum,
    REGISTERED: 'REGISTERED' as UserAttributesEnum,
    NOTREGISTERED: 'NOT_REGISTERED' as UserAttributesEnum,
    STATUSACTIVE: 'STATUS_ACTIVE' as UserAttributesEnum,
    STATUSTRIALING: 'STATUS_TRIALING' as UserAttributesEnum,
    STATUSPASTDUE: 'STATUS_PAST_DUE' as UserAttributesEnum,
    STATUSPAUSED: 'STATUS_PAUSED' as UserAttributesEnum,
    STATUSDELETED: 'STATUS_DELETED' as UserAttributesEnum,
    STATUSPENDING: 'STATUS_PENDING' as UserAttributesEnum,
    CANINTEGRATECALENDAR: 'CAN_INTEGRATE_CALENDAR' as UserAttributesEnum,
    BILLABLECOMPANY: 'BILLABLE_COMPANY' as UserAttributesEnum,
    CANINTEGRATEZOOM: 'CAN_INTEGRATE_ZOOM' as UserAttributesEnum,
    CANINTEGRATEDRIVE: 'CAN_INTEGRATE_DRIVE' as UserAttributesEnum,
    STATUSEXPIRED: 'STATUS_EXPIRED' as UserAttributesEnum,
    STATUSNOTEXPIRED: 'STATUS_NOT_EXPIRED' as UserAttributesEnum,
    CANNOTCREATEREPORT: 'CAN_NOT_CREATE_REPORT' as UserAttributesEnum,
    CANCREATEREPORT: 'CAN_CREATE_REPORT' as UserAttributesEnum,
    CANNOTCREATEDEAL: 'CAN_NOT_CREATE_DEAL' as UserAttributesEnum,
    CANCREATEDEAL: 'CAN_CREATE_DEAL' as UserAttributesEnum,
    CANENABLEANGELINVESTOR: 'CAN_ENABLE_ANGEL_INVESTOR' as UserAttributesEnum,
    ANGELNETWORKACTIVATED: 'ANGEL_NETWORK_ACTIVATED' as UserAttributesEnum,
  };
}
