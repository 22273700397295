import { AutoComplete, Avatar } from 'antd';
import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import api from 'ReduxActions/api';

import defaultImage from 'Assets/img/default.png';

import './CustomAutoComplete.scss';

const { Option } = AutoComplete;

export default function CustomAutoComplete(props) {
  const [options, setOptions] = useState([]);

  const onSearch = searchText => {
    loadLps(searchText);
    props.input.onChange(searchText);
  };

  const onSelect = data => {
    const selected = options.find(element => element[props.keyValue] == data);
    if (selected) props.input.onChange(selected);
  };

  function loadLps(query) {
    let url = props.path;
    if (query) url = `${props.path}?filter[${props.keyLabel}][like]=${query}`;
    api
      .get(url)
      .then(({ data }) => {
        setOptions(data);
      })
      .catch();
  }

  function renderOption(item) {
    return (
      <Option
        key={item[props.keyValue]}
        label={item[props.keyLabel]}
        // value={{key: item[props.keyValue], label: item[props.keyLabel]}}
      >
        <div>
          {props.showImages && (
            <span className='mr-2'>
              <Avatar src={item[props.keyImage] || defaultImage} />
            </span>
          )}
          {item[props.keyLabel]}
        </div>
      </Option>
    );
  }

  return (
    <div className='CustomAutoComplete'>
      <AutoComplete
        dataSource={options.map(renderOption)}
        defaultValue={typeof props.input.value == 'object' ? props.input.value[props.keyLabel] : props.input.value}
        onSelect={onSelect}
        onSearch={debounce(onSearch, 500)}
        placeholder={props.placeholder}
        size='large'
        optionLabelProp='label'
      />
    </div>
  );
}
