import React, { Component, useState, useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table/lib/BootstrapTable';
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn';
import SubmitButton from 'SharedComponent/SubmitButton';
import { updateItem, updateTools } from 'ReduxActions/reportActions';
import { useDispatch } from 'react-redux';
import TextEditor from '../../../shared/TextEditor/TextEditor';

import './ManualTable.scss';
import set from 'lodash/set';
import get from 'lodash/get';
import clone from 'lodash/clone';

const EditorToolbarWhenFocused = ({ text, item, setContent }) => {
  let dispatch = useDispatch();
  const handleEditorChange = html => {
    setContent(html);
  };
  return (
    <div>
      <TextEditor input={{ value: text, onChange: handleEditorChange }} toolbarOnFocus colorPicker />
    </div>
  );
};
class ManualTable extends Component {
  confirmDeletion(next, dropRowKeys) {}

  createCustomInsertButton = onClick => {
    return <SubmitButton label='' icon='icon-plus' style={{ width: '50px' }} onClick={this.handleInsertButtonClick.bind(this)} />;
  };

  handleInsertButtonClick = onClick => {
    const item = {
      ...this.props.item,
      data: [...this.props.item.data, { id: Math.random() * 100 }],
    };

    this.props.updateItem(item.id, item);
  };

  render() {
    const { item, mode } = this.props;

    const columns = item.columns ? item.columns : [];
    const data = item.data ? item.data : [];

    const cellEditProp = mode == 'edit' ? { mode: 'click', blurToSave: true } : {};

    return (
      <div className='ManualTable'>
        {mode == 'edit' && (
          <div className='add-new-btn'>
            <SubmitButton label='' tooltip='Add New Row' icon='icon-plus' style={{ width: '40px', borderRadius: '50%' }} onClick={this.handleInsertButtonClick.bind(this)} />
          </div>
        )}
        <BootstrapTable data={data} cellEdit={cellEditProp} deleteRow={false} insertRow={false} trClassName='custom-class-tr'>
          <TableHeaderColumn dataField='id' hidden={true} isKey={true}>
            ID
          </TableHeaderColumn>
          {columns.map((col, idx) => (
            <TableHeaderColumn key={idx + 1} dataAlign={item.data_align} dataField={col ? col.value : '...'}>
              {col ? col.name : '...'}
            </TableHeaderColumn>
          ))}
        </BootstrapTable>
      </div>
    );
  }
}

//ManualTable = connect(null, { updateItem })(ManualTable);

ManualTable = ({ item, ...props }) => {
  let [dragging, setDragging] = useState(false);
  let [startCell, setStartCell] = useState(null);
  let selectedItem = useSelector(state => state.reports.activeElement);
  let [dragSelectedIds, setDragSelectedIds] = useState([]);
  let dragClass = 'selected-cell';
  let tbody = useRef({});
  let dispatch = useDispatch();

  useEffect(() => {
    if (!selectedItem) {
      clearTDclasses();
    }
  }, [selectedItem]);
  const handleMouseDown = (e, cell) => {
    if (props.mode !== 'edit') return;
    if (cell.current) {
      setDragging(true);
      setStartCell(cell.current);
      setEndCell(cell.current);
    }
    e.stopPropagation();
  };
  const handleMouseUp = () => {
    setDragging(false);
  };
  const handleMouseEnter = (e, cell) => {
    if (!dragging) return;
    if (cell.current) {
      setEndCell(cell.current);
    }
  };
  const clearTDclasses = () => {
    let items = Array.prototype.slice.call(document.querySelectorAll(`.id-${item.id} td`));
    for (let td of items) {
      td.classList.remove(dragClass);
    }
  };
  const setEndCell = cell => {
    if (!startCell || !cell) return null;
    let selectedDragIds = [];
    clearTDclasses();
    cellsBetween(startCell, cell).forEach(selectedCell => {
      selectedCell.classList.add(dragClass);
      selectedDragIds.push(selectedCell.getAttribute('data-index'));
    });
    if (selectedDragIds.length === 2) {
      let [first, second] = selectedDragIds;
      let [firstRow, firstCol] = first.split('.');
      let [secondRow, secondCol] = second.split('.');
      if (Number(firstCol) > Number(secondCol) || Number(firstRow) > Number(secondRow)) {
        selectedDragIds.reverse();
      }
    }
    setDragSelectedIds(selectedDragIds);
    const [selectedColumn, selectedRow, details] = checkSelectedIdsRowsColumnsDetails(selectedDragIds);
    dispatch(updateTools('manualTableSelectedItems', selectedDragIds));
    dispatch(updateTools('selectedItemsInfos', details));
    dispatch(updateTools('columnSelected', selectedColumn));
    dispatch(updateTools('rowSelected', selectedRow));
  };
  const cellsBetween = (start, end) => {
    let bounds, elementsInside;
    elementsInside = [start, end];
    bounds = getBoundsForElements(elementsInside);
    do {
      let elementsInsideAfterExpansion = rectangleSelect('td', bounds);
      if (elementsInside.length == elementsInsideAfterExpansion.length) {
        return elementsInside;
      } else elementsInside = elementsInsideAfterExpansion;
    } while (true);
  };
  const rectangleSelect = (selector = 'td', bounds) => {
    let elements = [];
    if (tbody.current) {
      let items = [];
      for (let tr of Array.prototype.slice.call(tbody.current.children)) {
        for (let td of Array.prototype.slice.call(tr.children)) {
          items.push(td);
        }
      }
      items.map(function (element) {
        let $this = element;
        let offset = $this.getBoundingClientRect();
        let x1 = offset.left;
        let x2 = offset.right;
        let y1 = offset.top;
        let y2 = offset.bottom;
        if (
          isPointBetween(x1, bounds.x1, bounds.x2) &&
          isPointBetween(y1, bounds.y1, bounds.y2) &&
          isPointBetween(x2, bounds.x1, bounds.x2) &&
          isPointBetween(y2, bounds.y1, bounds.y2)
        ) {
          elements.push($this);
        }
      });
    }
    return elements;
  };
  const getBoundsForElements = elements => {
    let x1 = elements.reduce(function (currMinX, element) {
      let elementLeft = element.getBoundingClientRect().left;
      return currMinX && currMinX < elementLeft ? currMinX : elementLeft;
    }, undefined);
    let x2 = elements.reduce(function (currMaxX, element) {
      let elementRight = element.getBoundingClientRect().right;
      return currMaxX && currMaxX > elementRight ? currMaxX : elementRight;
    }, undefined);
    let y1 = elements.reduce(function (currMinY, element) {
      let elementTop = element.getBoundingClientRect().top;
      return currMinY && currMinY < elementTop ? currMinY : elementTop;
    }, undefined);
    let y2 = elements.reduce(function (currMaxY, element) {
      let elementBottom = element.getBoundingClientRect().bottom;
      return currMaxY && currMaxY > elementBottom ? currMaxY : elementBottom;
    }, undefined);
    return {
      x1: x1,
      x2: x2,
      y1: y1,
      y2: y2,
    };
  };
  const isPointBetween = (point, x1, x2) => {
    return (point >= x1 && point <= x2) || (point <= x1 && point >= x2);
  };

  const updateTableCellContent = (cellContent, location) => {
    let newTableData = clone(
      [...item.tableData].map(row => row.map(cell => clone(cell, true))),
      true,
    );
    set(newTableData, location + '.content', cellContent);
    let newItem = {
      ...item,
      tableData: newTableData,
    };
    dispatch(updateItem(item.id, newItem));
  };
  const checkSelectedIdsRowsColumnsDetails = ids => {
    let selectedColumn = {},
      selectedRow = {},
      details = { firstIndex: ids[0], merge: false, split: true },
      mergable = true,
      splittable = false;
    if (ids.length < 2) return [{}, {}, details];
    let firstIndex = ids[0];
    let [firstRow, firstCol] = firstIndex.split('.');
    let [lastRow, lastCol] = ids[ids.length - 1].split('.');
    ids.forEach(path => {
      let cell = get(item.tableData, path);
      if (cell) {
        if (Number(cell.colSpan) > 1 || Number(cell.rowSpan) > 1) {
          mergable = false;
          splittable = true;
        }
      }
    });
    details = {
      colSpan: Number(lastCol) + 1 - Number(firstCol),
      rowSpan: Number(lastRow) + 1 - Number(firstRow),
      firstIndex: firstIndex,
      merge: mergable,
      split: splittable,
    };
    let rowIndexesSame = ids.map(cell => cell.split('.')[0]).every((val, i, arr) => val === arr[0]);
    let colIndexesSame = ids.map(cell => cell.split('.')[1]).every((val, i, arr) => val === arr[0]);
    selectedRow = {
      isSelected: rowIndexesSame,
      rowIndex: rowIndexesSame ? ids[0].split('.')[0] : null,
    };
    selectedColumn = {
      isSelected: colIndexesSame,
      colIndex: colIndexesSame ? ids[0].split('.')[1] : null,
    };
    return [selectedColumn, selectedRow, details];
  };
  useEffect(() => {
    document.body.addEventListener('mouseup', handleMouseUp);
    dispatch(updateTools('manualTableSelectedItems', []));
    return () => {
      document.body.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);
  let cls = get(item, 'tableStyle.TableAlignment', 'display-flex-start');
  return (
    <div className={cls + ` id-${item.id}`} style={{ width: '100%', height: '100%', padding: 3 }}>
      {false && <p> {JSON.stringify(dragSelectedIds)} </p>}
      <table onMouseDown={e => e.stopPropagation()} style={{ cursor: 'auto', width: '100%', height: '100%' }}>
        <tbody drag-select='true' id='drag-table' ref={tbody}>
          {(item.tableData || []).map((row, rowIndex) => (
            <tr key={`${rowIndex}`}>
              {(row || []).map((cell, cellIndex) => (
                <TD
                  key={cell && cell.id}
                  isActive={props.isActive}
                  index={`${rowIndex}.${cellIndex}`}
                  mode={props.mode}
                  handleMouseDown={handleMouseDown}
                  setContent={updateTableCellContent}
                  handleMouseEnter={handleMouseEnter}
                  {...cell}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TD = ({ handleMouseDown, handleMouseEnter, handleMouseUp, isActive, setContent, ...props }) => {
  let cellRef = useRef({});
  const [editable, setEditable] = useState(false);
  return (
    <td
      onClick={() => setEditable(true)}
      onMouseDown={e => handleMouseDown(e, cellRef)}
      onMouseEnter={e => handleMouseEnter(e, cellRef)}
      ref={cellRef}
      {...props}
      style={{ ...props.style }}
      data-index={props.index}
    >
      {(props.mode !== 'edit' || !editable) && <div style={{ pointerEvents: 'none' }} dangerouslySetInnerHTML={{ __html: props.content }} />}
      {props.mode === 'edit' && editable && <EditorToolbarWhenFocused text={props.content} setContent={content => setContent(content, props.index)} />}
    </td>
  );
};
export default ManualTable;
