import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { loadForms, deleteForm, exportForm, duplicateForm } from 'ReduxActions/formActions';
import SubmitButton from 'SharedComponent/SubmitButton';
import FormList from '../FormList';
import ActionsModal from './ActionsModal';

import { Permissible } from '../../../../v2.0/shared/components';
import { PermissionTypeEnum } from '../../../../v2.0/core/enums/permission/permission-type.enum';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './FormContext.scss';
import { Mixpanel } from 'App/app';

const actions = { loadForms, deleteForm, exportForm, duplicateForm };
class FormContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      form: null,
      type: 'delete',
    };
  }
  componentWillMount() {
    this.props.loadForms();
  }

  onClickAdd() {
    gaEvent(appCategories.settings_forms, appActions(appTypes.add, appEvents.clicked).button);
    browserHistory.push(`/form-editor`);
    Mixpanel.track('Clicked on New Form');
  }

  render() {
    const { forms, loading } = this.props;
    return (
      <div className='FormContext'>
        <div className='display-flex-between flex-wrap'>
          <div className='display-flex-start'>
            <div className='list-text mr-5'>Forms</div>
          </div>
          <div className='display-flex-end flex-wrap'>
            <div className='ml-5 display-flex-center disable-button' style={{ color: '#ff8181' }}>
              <span className='icon-trash mr-2 icon-span-details' />
              <span className='icon-span-text'>Delete</span>
            </div>
            <Permissible can={PermissionTypeEnum.CreateUpdateForm}>
              <div className='ml-5'>
                <SubmitButton label='New Form' icon='icon-plus' onClick={() => this.onClickAdd()} />
              </div>
            </Permissible>
          </div>
        </div>
        <div className='mt-3'>
          <FormList
            loading={loading}
            forms={forms}
            onEdit={form => browserHistory.push(`/form-editor/edit/${form.form_id}`)}
            remove={this.props.deleteForm}
            duplicate={this.props.duplicateForm}
            export={this.props.exportForm}
          />
        </div>
        <ActionsModal
          show={this.state.show}
          initialValues={this.state.form}
          onHide={() => this.setState({ show: false, form: null })}
          onEdit={form => browserHistory.push(`/form-editor/edit/${form.form_id}`)}
          remove={this.props.deleteForm}
          export={this.props.exportForm}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    forms: state.settings.formBuilder.all,
    loading: state.settings.formBuilder.loading,
  };
}

export default connect(mapStateToProps, actions)(FormContext);
