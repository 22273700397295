/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type NotificationSourceTypeEnumDTO =
  | 'Task'
  | 'Form'
  | 'User'
  | 'Field'
  | 'Event'
  | 'Stage'
  | 'ReturnRate'
  | 'GroupMetrics'
  | 'Emailtpl'
  | 'Fund'
  | 'Note'
  | 'AngelInvDeal'
  | 'MetricGroup'
  | 'FormInProgress'
  | 'DealVote'
  | 'Email'
  | 'ContactDeal'
  | 'Contact'
  | 'File'
  | 'Deal'
  | 'Report'
  | 'LPs'
  | 'Round'
  | 'Pipeline';

export const NotificationSourceTypeEnumDTO = {
  Task: 'Task' as NotificationSourceTypeEnumDTO,
  Form: 'Form' as NotificationSourceTypeEnumDTO,
  User: 'User' as NotificationSourceTypeEnumDTO,
  Field: 'Field' as NotificationSourceTypeEnumDTO,
  Event: 'Event' as NotificationSourceTypeEnumDTO,
  Stage: 'Stage' as NotificationSourceTypeEnumDTO,
  ReturnRate: 'ReturnRate' as NotificationSourceTypeEnumDTO,
  GroupMetrics: 'GroupMetrics' as NotificationSourceTypeEnumDTO,
  Emailtpl: 'Emailtpl' as NotificationSourceTypeEnumDTO,
  Fund: 'Fund' as NotificationSourceTypeEnumDTO,
  Note: 'Note' as NotificationSourceTypeEnumDTO,
  AngelInvDeal: 'AngelInvDeal' as NotificationSourceTypeEnumDTO,
  MetricGroup: 'MetricGroup' as NotificationSourceTypeEnumDTO,
  FormInProgress: 'FormInProgress' as NotificationSourceTypeEnumDTO,
  DealVote: 'DealVote' as NotificationSourceTypeEnumDTO,
  Email: 'Email' as NotificationSourceTypeEnumDTO,
  ContactDeal: 'ContactDeal' as NotificationSourceTypeEnumDTO,
  Contact: 'Contact' as NotificationSourceTypeEnumDTO,
  File: 'File' as NotificationSourceTypeEnumDTO,
  Deal: 'Deal' as NotificationSourceTypeEnumDTO,
  Report: 'Report' as NotificationSourceTypeEnumDTO,
  LPs: 'LPs' as NotificationSourceTypeEnumDTO,
  Round: 'Round' as NotificationSourceTypeEnumDTO,
  Pipeline: 'Pipeline' as NotificationSourceTypeEnumDTO,
};
