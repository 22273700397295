import React from 'react';
import CustomTag from 'SharedComponent/CustomTag';

export default class HeaderBar extends React.Component {
  render() {
    return (
      <div className='toolbar-header'>
        {/* <span className="label label-default label-default-details">{this.props.data.hover_text}</span> */}
        <div className='toolbar-header-buttons'>
          {this.props.data.primary && (
            <div className='is-isolated btn-school'>
              <CustomTag color='gold' label='Primary' />
            </div>
          )}
          {this.props.data.element !== 'LineBreak' && (
            <div className='is-isolated btn-school'>
              <i className='is-isolated icon-pencil' onClick={this.props.editModeOn.bind(this.props.parent, this.props.data)} />
            </div>
          )}
          {!this.props.data.fixed && (
            <div className='is-isolated btn-school ml-2'>
              <i className='is-isolated icon-trash' onClick={this.props.onDestroy.bind(this, this.props.data)} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
