import { SelectItemData } from 'app/v2.0/shared/interfaces/select-item';
import { DealLightDTO } from '../../generated/model/dealLightDTO';

export interface DealLightData {
  id: string;
  name: string;
  image: string;
}

export namespace DealLightData {
  export function mapFromApi(deal: DealLightDTO): DealLightData {
    return {
      id: deal.deal_id,
      name: deal.name,
      image: deal.image,
    };
  }

  export function mapToSelectedItem(data: DealLightDTO): SelectItemData {
    return {
      value: data.deal_id,
      name: data.name,
      image: data.image,
    };
  }
}
