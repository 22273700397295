import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeDrawer } from '../state/drawer.actions';
import { getDrawerSubmitted } from '../state/drawer.selectors';

import { Button } from '../../Button/button.component';

import { ButtonType } from '../../../interfaces/button-data';

import './drawer-footer.component.scss';

interface DefaultFooterProps {
  saveLabel: string;
  cancelLabel: string;
  htmlType?: ButtonType;
  onSave: () => void;
}

export function DrawerFooter(props: DefaultFooterProps): JSX.Element {
  const dispatch = useDispatch();
  const submitting = useSelector(getDrawerSubmitted);

  const handleCancel = () => {
    dispatch(closeDrawer());
  };

  const handleSubmit = () => {
    props.onSave();
  };

  return (
    <div className='DrawerFooter'>
      <Button label={props.cancelLabel} btnClass='pentugram-btn-default' onClick={handleCancel} />
      <Button
        label={props.saveLabel}
        htmlType={props.htmlType}
        btnClass='pentugram-primary-btn pentugram-btn-default'
        onClick={handleSubmit}
        disabled={submitting}
        loading={submitting}
      />
    </div>
  );
}

DrawerFooter.defaultProps = {
  saveLabel: 'Save',
  cancelLabel: 'Cancel',
};
