import {
  ERROR,
  INFO,
  SIGNED_IN,
  SIGNED_OUT,
  DISCARD_ERROR,
  LOADING,
  UPDATED,
  UPDATING,
  PASSWORD_CHANGED,
  CHANGING_PASSWORD,
  CHANGING_PASSWORD_FAILED,
  USER_REGISTRED_INFO,
  GET_USERNAME_VALUE,
  USER_ATTRIBUTES_UPDATED,
  TOTAL_USERS_UPDATED,
} from 'ReduxActions/authActions';
import { COMPANY_UPDATED, ADD_USER_ATTRIBUTE, REMOVE_USER_ATTRIBUTE } from 'ReduxActions/settingActions';

const initialState = {
  authenticated: false,
  user: {},
  error: null,
  info: null,
  loading: false,
  spinner: false,
  changingPassword: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGNED_IN:
      return {
        ...state,
        authenticated: true,
        user: action.payload,
        error: null,
      };
    case USER_REGISTRED_INFO:
      return {
        ...state,
        authenticated: true,
        user: action.payload,
        error: null,
      };
    case GET_USERNAME_VALUE:
      return {
        ...state,
        authenticated: true,
        user: { ...state.user, profile: { ...state.user.profile, fullname: action.payload } },
        error: null,
      };

    case ERROR:
      return {
        ...state,
        authenticated: false,
        error: action.payload,
      };

    case INFO:
      return {
        ...state,
        info: action.payload,
      };

    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case CHANGING_PASSWORD:
      return {
        ...state,
        changingPassword: true,
      };

    case PASSWORD_CHANGED:
      return {
        ...state,
        changingPassword: false,
      };

    case CHANGING_PASSWORD_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case DISCARD_ERROR:
      return {
        ...state,
        error: null,
        info: null,
      };

    case UPDATING:
      return {
        ...state,
        spinner: action.payload,
      };

    case UPDATED:
      return {
        ...state,
        user: action.payload,
        spinner: false,
      };

    case USER_ATTRIBUTES_UPDATED:
      return {
        ...state,
        user: {
          ...state.user,
          user_attributes: action.payload,
        },
      };

    case ADD_USER_ATTRIBUTE:
      return {
        ...state,
        user: {
          ...state.user,
          user_attributes: [...state.user.user_attributes, action.payload],
        },
      };
    case REMOVE_USER_ATTRIBUTE:
      return {
        ...state,
        user: {
          ...state.user,
          user_attributes: state.user.user_attributes.filter(attr => action.payload !== attr),
        },
      };

    case TOTAL_USERS_UPDATED:
      return {
        ...state,
        user: {
          ...state.user,
          total_users: action.payload,
        },
      };

    case COMPANY_UPDATED:
      return {
        ...state,
        user: {
          ...state.user,
          company: {
            ...state.user.company,
            currency_id: action.payload.currency && action.payload.currency.currency_id,
          },
        },
      };

    case SIGNED_OUT:
      return {
        ...state,
        authenticated: false,
      };
  }

  return state;
}
