import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Label from 'react-bootstrap/lib/Label';
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn';
import ReactTooltip from 'react-tooltip';
import emptyTpl from '../../../../assets/img/empty-emailtpl.svg';
import Table from 'SharedComponent/Table';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './EmailTemplatesTable.scss';
class EmailTemplatesTable extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    onEdit: PropTypes.func.isRequired,
    onSortChange: PropTypes.func,
    noDataText: PropTypes.string,
    pagination: PropTypes.bool,
    checkBox: PropTypes.bool,
  };

  static defaultProps = {
    noDataText: 'No templates found',
    pagination: false,
    checkBox: true,
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.actionsFormatter = this.actionsFormatter.bind(this);
  }

  render() {
    const { props, state } = this;
    return (
      <div className='EmailTemplatesTable'>
        <Table
          items={props.items}
          pagination={props.pagination}
          loading={props.isLoading}
          onSortChange={props.onSortChange}
          noDataText={this.emptyData()}
          checkBox={props.checkBox}
          onSelectAll={props.onSelectAll}
          onRowSelect={props.onRowSelect}
        >
          <TableHeaderColumn dataField='id' isKey hidden>
            ID
          </TableHeaderColumn>

          <TableHeaderColumn dataField='name' dataAlign='left' width='80'>
            Template title
          </TableHeaderColumn>

          <TableHeaderColumn dataField='created_by' dataAlign='left' width='80'>
            Owner
          </TableHeaderColumn>

          <TableHeaderColumn dataField='created_at' dataAlign='left' width='80'>
            Created at
          </TableHeaderColumn>

          <TableHeaderColumn dataFormat={this.actionsFormatter} dataAlign='left' width='40' columnClassName='custome-table-outline' isActionColumn>
            Actions
          </TableHeaderColumn>
        </Table>
      </div>
    );
  }

  actionsFormatter(cell, row) {
    const { props } = this;
    return (
      <div className='actions-details'>
        <ReactTooltip />
        <i
          className='icon-pencil cursor-pointer'
          onClick={() => {
            gaEvent(appCategories.settings_emailTemplates, appActions(appTypes.edit, appEvents.clicked).icon);
            props.onEdit(row);
          }}
          style={{ width: '36px' }}
          data-tip='Edit template'
        />
        <i
          className='icon-trash cursor-pointer'
          onClick={() => {
            gaEvent(appCategories.settings_emailTemplates, appActions(appTypes.delete, appEvents.clicked).icon);
            props.onDelete(row);
          }}
          style={{ width: '36px' }}
          data-tip='Delete template'
        />
      </div>
    );
  }

  checkBoxFormatter(cell, row) {
    return <Label bsStyle='success'>check</Label>;
  }

  emptyData() {
    return (
      <div className='m-4'>
        <img src={emptyTpl} />
        <div className='empty-tpl'>Looks like you dont have any Email Templates yet, so lets start by adding some</div>
      </div>
    );
  }
}

export default EmailTemplatesTable;
