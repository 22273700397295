import { gaEvent } from 'GoogleAnalytics/eventFunctions';
import { appActions, appEvents } from 'GoogleAnalytics/eventArgs';
let filled_inputs = { form: null, inputs: [], initialized: null };
const regex = /[\-\.\[\]]/gi;

export const formsMiddleware = store => next => action => {
  if (action.type === '@@redux-form/INITIALIZE' && action.payload) {
    filled_inputs.initialized = action.meta.form;
  }
  if (action.type === '@@redux-form/CHANGE') {
    let form = action.meta.form;
    let field = action.meta.field;
    let payload = action.payload;
    if (!payload) {
      filled_inputs.inputs = filled_inputs.inputs.filter(item => field !== item);
    }
    if (form && field && payload) {
      if (form === filled_inputs.form) {
        if (!filled_inputs.inputs.includes(field)) filled_inputs.inputs.push(field);
      } else {
        filled_inputs.form = form;
        filled_inputs.inputs = [field];
      }
    }
  }
  if (action.type === '@@redux-form/SET_SUBMIT_SUCCEEDED') {
    const isEdit = filled_inputs.initialized === filled_inputs.form;
    let category = transformField(`${isEdit ? transformAdd(filled_inputs.form) : transformEdit(filled_inputs.form)}`);
    let action = item => appActions(transformField(item), appEvents.entered).input;
    filled_inputs.inputs.map(item => {
      gaEvent(category, action(item));
    });
    filled_inputs.inputs = [];
    filled_inputs.form = null;
    filled_inputs.initialized = null;
  }

  const result = next(action);
  return result;
};

function transformField(field) {
  if (!field) return undefined;
  return field.toUpperCase().replace(regex, '_');
}

function transformAdd(field) {
  if (!field) return undefined;
  return field.toUpperCase().replace(/(ADD)/gi, 'EDIT').replace(/(NEW)/gi, 'EXISTING');
}

function transformEdit(field) {
  if (!field) return undefined;
  return field.toUpperCase().replace(/(EDIT|UPDATE|EDITING)/gi, 'ADD');
}
