import { RbacRolePermissionResponse } from '../../generated/model/models';
import { ActionSummaryData } from './action-summary-data';
import { PageSummaryData } from './page-summary-data';

export interface PageActionPermissionData {
  actions: ActionSummaryData[];
  pages: PageSummaryData[];
}

export namespace PageActionPermissionData {
  export function mapFromApi(data: RbacRolePermissionResponse): PageActionPermissionData {
    return {
      actions: (data.permissions || []).map(action => ActionSummaryData.mapFromApi(action)),
      pages: (data.pages || []).map(page => PageSummaryData.mapFromApi(page)),
    };
  }
}
