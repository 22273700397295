import React from 'react';

import { Field } from 'redux-form';

import SubmitButton from 'SharedComponent/SubmitButton';
import NumberField from 'SharedComponent/NumberField';
import { FieldSelect } from 'SharedComponent/SelectOption/FieldSelect';
import CustomAutoComplete from 'SharedComponent/CustomAutoComplete';

import { numberFormatter, normalizeNumber } from 'Utils/objectUtils';
import * as validators from 'Utils/validators';

export default function RenderLPs(props) {
  return (
    <div className='lps-container p-3'>
      {props.fields.map((item, index) => (
        <div className='row mb-3' key={index + 1}>
          <div className='col-md-5'>
            <Field
              name={`${item}.lp_name`}
              component={CustomAutoComplete}
              placeholder='Enter LP name'
              validate={[validators.required]}
              path='/lps'
              keyLabel='lp_name'
              keyValue='lp_id'
            />
          </div>
          <div className='col-md-3'>
            <Field
              name={`${item}.lp_commitment`}
              component={NumberField}
              placeholder='Commitment'
              format={numberFormatter}
              normalize={normalizeNumber}
              validate={[validators.required]}
            />
          </div>
          <div className='col-md-3'>
            <Field
              name={`${item}.currency_id`}
              component={FieldSelect}
              placeholder='Currency'
              options={props.currencies}
              clearable={false}
              style={{ width: '100%', height: '40px' }}
              validate={[validators.required]}
              labelKey='code'
              valueKey='currency_id'
            />
          </div>
          <div className='display-flex-center'>
            <i className='material-icons delete-outline' onClick={() => props.fields.remove(index)}>
              delete_outline
            </i>
          </div>
        </div>
      ))}
      <SubmitButton
        label='New LP'
        icon='icon-plus'
        className='login-form-btn display-flex-center'
        onClick={() => props.fields.push({})}
        style={{
          width: '100%',
          backgroundColor: 'rgba(3, 166, 255, 0.06)',
          color: '#03a6ff',
        }}
      />
    </div>
  );
}
