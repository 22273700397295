import { ApiUrlsEnum } from '../helpers/api-url';
import { lazyLoad } from './helpers/base-http';
import { apiUrlMatcher } from '../helpers/api-url-matcher';

import { UserLightDTO } from '../generated/model/models';
import { LazyLoadResponse } from '../models/queries/lazy-response.model';
import { SelectItemData } from '../../shared/interfaces/select-item';
import { HttpParamsType } from '../models/queries/http-params.type';
import { PaginationQuery } from '../models/queries/pagination-query.model';
import { UserLightData } from '../models/user/user-light-data';

export async function usersFilterLightResolver(params: HttpParamsType<PaginationQuery>): Promise<LazyLoadResponse<SelectItemData[]>> {
  const data = await lazyLoad<UserLightDTO[]>(apiUrlMatcher(ApiUrlsEnum.LightUsers), params);

  return {
    ...data,
    results: data.results.map(user => UserLightData.mapToSelectedItem(user)),
  };
}

export async function users(param: HttpParamsType<PaginationQuery>, searchText: string): Promise<LazyLoadResponse<SelectItemData[]>> {
  // fixme TODO @ahmed priority high (refactoring)
  const params = {
    ...param,
    queryParams: {
      ...param.queryParams,
      'filter[fullname][like]': searchText,
    },
  };

  const data = await lazyLoad<UserLightDTO[]>(apiUrlMatcher(ApiUrlsEnum.LightUsers), params);

  return {
    ...data,
    results: data.results.map(user => UserLightData.mapToSelectedItem(user)),
  };
}
