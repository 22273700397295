export const priorities = {
  1: { label: 'Urgent', color: '#ff7b65' },
  2: { label: 'High', color: '#ffc266' },
  3: { label: 'Normal', color: '#9adbad' },
  4: { label: 'Low', color: '#b1afdb' },
};

export const taskPriority = [
  { value: 1, label: 'Urgent' },
  { value: 2, label: 'High' },
  { value: 3, label: 'Normal' },
  { value: 4, label: 'Low' },
];

export const taskStatus = {
  1: { label: 'Done', color: '#ff7b65' },
  2: { label: 'Undone', color: '#ffc266' },
  3: { label: 'Overdue', color: '#9adbad' },
};

export const zoom_status = {
  MEETING_ENDED: 3,
  MEETING_NOT_STARTED: 2,
};
