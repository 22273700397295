import React, { Component } from 'react';
import DialogBox from 'SharedComponent/DialogBox';
import SubmitButton from 'SharedComponent/SubmitButton';

import { appCategories, appActions, appEvents, appTypes } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './UserDialog.scss';

class UserDialog extends Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.onDismiss();
  }

  handleAction(ga, fn) {
    fn();
    gaEvent(appCategories.settings_users, appActions(`${ga}_${appTypes.modal}_${appTypes.delete}`, appEvents.clicked).button);
    this.closeModal();
  }

  onCloseDialogBox(ga) {
    this.closeModal();
    gaEvent(appCategories.settings_users, appActions(`${ga}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
  }

  onClickCancelBtn(ga) {
    this.closeModal();
    gaEvent(appCategories.settings_users, appActions(`${ga}_${appTypes.modal}_${appTypes.cancel}`, appEvents.clicked).icon);
  }

  render() {
    if (!this.props.showDetails) return null;
    const PROPERTIES = type => {
      const userType = type ? 'user' : 'investor';
      return {
        block: {
          title: `Block ${userType}`,
          message: `Are you sure you want to block this ${userType}: ${this.props.user && this.props.user.name} ?`,
          label: 'Block',
          icon: 'icon-lock',
          color: '#ff8181',
          submit: () => this.props.blockUser(this.props.user && this.props.user.id),
        },
        resend: {
          title: 'Resend Invitation',
          message: `Are you sure you want to resend invitation for ${userType}: ${this.props.user && this.props.user.name} ?`,
          label: 'Resend invitation',
          icon: 'icon-paper-plane',
          color: '#ffbc34',
          submit: () => this.props.resend(this.props.user),
        },
        delete: {
          title: `Delete ${userType}`,
          message: `Are you sure you want to delete this ${userType}: ${this.props.user && this.props.user.name} ?`,
          label: 'Delete',
          icon: 'icon-trash',
          color: '#ff8181',
          submit: () => this.props.deleteInvestor(this.props.user.id),
        },
      };
    };
    return (
      <div className='UserDialog'>
        <DialogBox
          isVisible
          onClickOut={this.closeModal}
          onClose={() => this.onCloseDialogBox(PROPERTIES(this.props.isUsers)[this.props.type].label)}
          className='small-dalog'
          title={PROPERTIES(this.props.isUsers)[this.props.type].title}
        >
          <div className='p-3'>
            <span className='font-size-16 font-weight-500'>{PROPERTIES(this.props.isUsers)[this.props.type].message}</span>
          </div>
          <div className='display-flex-end'>
            <div className='m-3'>
              <SubmitButton
                label='Cancel'
                icon='icon-close'
                style={{
                  width: '150px',
                  color: 'rgb(83, 98, 124)',
                  backgroundColor: '#fff',
                  border: '1px solid #e1e4e8',
                }}
                onClick={() => this.onClickCancelBtn(PROPERTIES(this.props.isUsers)[this.props.type].label)}
              />
            </div>
            <div className='m-3'>
              <SubmitButton
                label={PROPERTIES(this.props.isUsers)[this.props.type].label}
                icon={PROPERTIES(this.props.isUsers)[this.props.type].icon}
                style={{
                  width: '150px',
                  backgroundColor: PROPERTIES(this.props.isUsers)[this.props.type].color,
                }}
                onClick={() => this.handleAction(PROPERTIES(this.props.isUsers)[this.props.type].label, PROPERTIES(this.props.isUsers)[this.props.type].submit)}
              />
            </div>
          </div>
        </DialogBox>
      </div>
    );
  }
}

export default UserDialog;
