import { isEqual, pull } from 'lodash';
import React, { useState } from 'react';
import { Switch } from 'antd'; // refactoring @ahmed
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { LocalStorageKeyEnum } from '../../../../core/enums/local-storage.enum';
import { getFromLocalStorage } from '../../../../core/utils/local-storage-utils';
import { IconPrefixEnum } from '../../../interfaces/icon-data';
import { ColumnTable } from '../../../interfaces/table/column-table';
import { Icon } from '../../Icon/icon.component';
import { DrawerFooter } from '../../Drawer/drawer-footer/drawer-footer.component';

import './arrange-columns.component.scss';

// interface ArrangeColumnsProps<R> {
//   columns: ColumnTable<R>[];
//   arrangeColumnKey: LocalStorageKeyEnum;
//   onSave?(keys: (keyof R)[]): void;
//   onClose?(): void;
// }

export function ArrangeColumns(props) {
  const [tempList, setTempList] = useState(
    getFromLocalStorage(
      props.arrangeColumnKey,
      props.columns.map(i => i.key),
    ),
  );

  const onSave = () => {
    const localStorageColumns = getFromLocalStorage(
      props.arrangeColumnKey,
      props.columns.map(i => i.key),
    );
    if (!isEqual(tempList, localStorageColumns)) {
      props.onSave(tempList);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTempList(arrayMove(tempList, oldIndex, newIndex).filter(t => !!t));
  };

  const onSwitch = (checked, key) => {
    const copy = [...tempList];
    if (checked) {
      setTempList([...copy, key]);
    } else {
      setTempList(pull(copy, key));
    }
  };

  const DragHandle = SortableHandle(() => <Icon iconData={{ name: 'ri-menu-line', prefix: IconPrefixEnum.Remix, iconClassName: 'showcase-handle' }} />);
  const SortableItem = SortableElement(({ column }) => (
    <li className='item d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        <DragHandle />
        {column.title || column.key}
      </div>
      {/* tslint:disable-next-line: jsx-no-lambda */}
      <Switch onChange={checked => onSwitch(checked, column.key)} defaultChecked={tempList.includes(column.key)} />
    </li>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul>
        {items.map((column, index) => (
          <SortableItem key={`item-${index}`} index={index} column={column} />
        ))}
      </ul>
    );
  });

  function mapKeysToColumns(keys) {
    const ordered = keys.map(col => props.columns.find(o => o.key === col));
    const rest = props.columns.filter(col => !keys.includes(col.key));

    return [...ordered, ...rest];
  }

  return (
    <div className='ArrangeColumns'>
      <div className='drawer-content'>
        <SortableList helperClass='sortable-helper' onSortEnd={onSortEnd} items={mapKeysToColumns(tempList)} useDragHandle={true} />
      </div>
      <DrawerFooter onSave={onSave} />
    </div>
  );
}
