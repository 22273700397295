import React from 'react';
import { Empty } from '../Empty/empty.component';

function NotAuthorized(): JSX.Element {
  return (
    <div className='centre'>
      <Empty description='You do not have permission to access this page, contact your administrator.' />
    </div>
  );
}
// tslint:disable-next-line: no-default-export
export default NotAuthorized;
