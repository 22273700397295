import { createAction } from '@reduxjs/toolkit';

// import { MetaQuery } from '../../../core/models/queries/meta-query.model';
// import { SelectItemData } from '../../../shared/interfaces/select-item';

import { IPipelineDealsForGrid, IPipelineStages, IPipelineView, ISelectedPipeline } from './types';

export const setPipelineView = createAction<IPipelineView>('[Pipeline] set view');
export const setSelectedPipeline = createAction<ISelectedPipeline>('[Pipeline] select pipeline');

export const loadPipelineList = createAction('[Pipeline/Api] load pipeline list');
export const loadPipelineListSuccess = createAction('[Pipeline/Api] load pipeline list success');
export const loadPipelineListFail = createAction('[Pipeline/Api] load pipeline list fail');

export const loadPipelineStage = createAction('[Pipeline/Api] load pipeline stages');
export const loadPipelineStageSuccess = createAction<IPipelineStages>('[Pipeline/Api] load pipeline stages success');
export const loadPipelineStageFail = createAction('[Pipeline/Api] load pipeline stages fail');

export const loadDealListForGridView = createAction('[Pipeline/Api] load pipeline deals for grid view');
export const loadDealListForGridViewSuccess = createAction<IPipelineDealsForGrid>('[Pipeline/Api] load pipeline deals for grid view success');
export const loadDealListForGridViewNextPage = createAction('[Pipeline/Api] load pipeline deals for grid view next page list');
export const loadDealListForGridViewFail = createAction('[Pipeline/Api] load pipeline deals for grid view fail');

// export const loadTestPipeline = createAction('[Pipeline/Api] load notes');
// // tslint:disable-next-line: no-any
// export const loadTestPipelineSuccess = createAction<{ pipelines: SelectItemData[], meta: MetaQuery, reset?: boolean }>('[Pipeline/Api] load loadTestPipeline success');
// export const loadTestPipelineNextPage = createAction('[Pipeline/Api] load loadTestPipeline next page list');
// export const loadTestPipelineFail = createAction('[Pipeline/Api] load loadTestPipeline fail');
