import { createAction } from '@reduxjs/toolkit';

import { DrawerIdEnum } from '../../../../core/enums/entity-ids/drawer-id.enum';

export const closeDrawer = createAction('[Shared/Drawer] close drawer');
export const resetDrawerState = createAction('[Shared/Drawer] reset drawer state');

export const setDrawerId = createAction<{ drawerId: DrawerIdEnum }>('[Shared/Drawer] set drawer id');
export const setDrawerLoading = createAction<{ loading: boolean }>('[Shared/Drawer] set drawer loading state');
export const setDrawerSubmitted = createAction<{ submitted: boolean }>('[Shared/Drawer] set drawer submitted');
