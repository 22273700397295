export function headerHtml(title) {
  return `<p style="text-align:center;"><span style="font-size: 30px;">${title} REPORT</span></p>`;
}

export function footerHtml(title) {
  return `<p style="text-align:center;"><span style="font-size: 30px;">${title} REPORT</span></p>`;
}

export function coverBody(company, frequency) {
  return `<p style="text-align:center;">&nbsp;<span style="font-size: 30px;">${company} FUND</span>&nbsp;</p>
  <p style="text-align:center;"><span style="font-size: 12px;">${frequency}</span><br><span style="font-size: 12px;">Summary Report</span>&nbsp;</p>`;
}

export function htmlShareTemplate(base_url, period, key) {
  const frequency = period ? getFrequency(period.frequency_id) : '-n/-';
  const label = period ? period.label : '-n/a-';
  return `<p>Hello,</p>\n<p></p>\n<p>Please find bellow the ${frequency} report for the period : ${label}.</p>\n<p>link : </p><a href=${base_url}/report/${key} target=\"_self\">${base_url}/report/${key}</a>&nbsp;</p>\n<p>Please let us know if you need any further details</p>\n<p></p>\n<p>Regards,</p>\n<p></p>\n`;
}

function getFrequency(id) {
  if (id == 1) return 'Weekly';
  if (id == 2) return 'Monthly';
  if (id == 3) return 'Quarterly';
  if (id == 4) return 'Yearly';
  else return '-n/a-';
}
