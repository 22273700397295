import React from 'react';

import { ExcelButton } from '../excel-button/excel-button.component';

import './template.component.scss';

interface TemplateProps {
  download(isPortfolio: boolean): void;
}

export function Template(props: TemplateProps): JSX.Element {
  const pipeline = () => props.download(false);
  const portfolio = () => props.download(true);

  return (
    <div className='Template'>
      <div className='display-flex-center'>
        <div className='text-center'>
          <div className='text-description'>
            <div>Download the template below and add your deals inside it.</div>
            <div>(Please don’t change the headers)</div>
          </div>
          <div className='display-flex-center'>
            <ExcelButton title='Pipeline' onClick={pipeline} />
            <ExcelButton title='Portfolio' onClick={portfolio} />
          </div>
        </div>
      </div>
    </div>
  );
}
