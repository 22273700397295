import { RbacRoleCreateRequest } from '../../generated/model/rbacRoleCreateRequest';
import { RoleSummaryData } from './role-summary-data';

export interface CreateRoleData extends Partial<RoleSummaryData> {}

export namespace CreateRoleData {
  export function mapToApi(role: CreateRoleData): RbacRoleCreateRequest {
    return {
      name: role.name,
    };
  }
}
