import { TaskDealVoteRequestDTO } from '../../generated/model/taskDealVoteRequestDTO';

export interface TaskForVote {
  vote: boolean;
  uuid: string;
}

export namespace TaskForVote {
  export function mapToApiValue(data: TaskForVote): TaskDealVoteRequestDTO {
    return {
      vote: data.vote ? 1 : 0,
    };
  }
}
