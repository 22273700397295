import { createReducer, Action } from '@reduxjs/toolkit';

import { ModalState } from './modal-type';
import * as fromModalActions from './modal.actions';

const initialModalState: ModalState = {
  modalId: null,
  visible: false,
  loading: false,
  editMode: false,
  submitted: false,
};

const _modalReducer = createReducer(initialModalState, builder => {
  builder
    .addCase(fromModalActions.closeModal, () => initialModalState)
    .addCase(fromModalActions.setEditMode, (state, { payload: { editMode } }) => ({ ...state, editMode }))
    .addCase(fromModalActions.setLoading, (state, { payload: { loading } }) => ({ ...state, loading }))
    .addCase(fromModalActions.setSubmitted, (state, { payload: { submitted } }) => ({ ...state, submitted }))
    .addCase(fromModalActions.openModal, (state, { payload: { modalId } }) => ({ ...state, modalId, visible: true }))
    .addCase(fromModalActions.setModalId, (state, { payload: { modalId } }) => ({ ...state, modalId }));
});

export function modalReducer(state: ModalState | undefined, action: Action): ModalState {
  return _modalReducer(state, action);
}
