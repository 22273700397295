import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { Error } from '../Error/error.component';

import './date-field.component.scss';

// interface DateFieldProps {
//   meta: WrappedFieldMetaProps;
//   input: WrappedFieldInputProps;
//   showTime: boolean;
//   formatDate: string;
//   placeholder?: string;
// }

export function DateField(props) {
  const onChangeUncontrolled = date => {
    props.input.onChange(date);
  };

  return (
    <div className='DateField'>
      <Error touched={props.meta && props.meta.touched} error={props.meta && props.meta.error} warning={props.meta && props.meta.warning} />
      <DatePicker
        size='large'
        value={props.input.value ? moment(props.input.value) : null}
        onChange={onChangeUncontrolled}
        style={{ width: '100%' }}
        format={props.formatDate}
        placeholder={props.placeholder}
        showTime={props.showTime}
      />
    </div>
  );
}

DateField.defaultProps = {
  showTime: false,
  formatDate: 'MMMM Do YYYY',
};
