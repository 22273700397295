/**
 * Pentugram Web Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: support@example.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EventypeDTO {
  /**
   *      1 - Call     2 - Meeting     3 - Conference     4 - Task     5 - Email
   */
  eventype_id?: EventypeDTO.EventypeIdEnum;
  name?: string;
}
export namespace EventypeDTO {
  export type EventypeIdEnum = 1 | 2 | 3 | 4 | 5;
  export const EventypeIdEnum = {
    NUMBER_1: 1 as EventypeIdEnum,
    NUMBER_2: 2 as EventypeIdEnum,
    NUMBER_3: 3 as EventypeIdEnum,
    NUMBER_4: 4 as EventypeIdEnum,
    NUMBER_5: 5 as EventypeIdEnum,
  };
}
