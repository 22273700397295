import { SelectItemData } from '../../../shared/interfaces/select-item';
import { UserLightDTO } from '../../generated/model/userLightDTO';

export interface UserLightData {
  id: string;
  name: string;
  image: string;
}

export namespace UserLightData {
  export function mapFromApi(user: UserLightDTO): UserLightData {
    return {
      id: user.id.toString(),
      name: user.fullname,
      image: user.image,
    };
  }

  export function mapToSelectedItem(data: UserLightDTO): SelectItemData {
    return {
      value: data.id.toString(),
      name: data.fullname,
      image: data.image,
    };
  }
}
