import React from 'react';
import { Collapse } from 'antd';
import { useSelector } from 'react-redux';
import { Field, FieldArray, GenericFieldArray, WrappedFieldArrayProps } from 'redux-form';

import { Empty, Skeleton } from '../../../../shared/components';
import { PermissionItem } from '../permission-item/permission-item.component';
import { loadingActions } from '../state/permissions.selectors';

import './list-permissions.component.scss';

const FieldArrayCustomAction = FieldArray;

const renderPermissions = props => (
  <div className='actions-block'>
    {props.fields.map((permission, index) => (
      <Field key={index + 1} name={`${permission}.isEnabled`} component={PermissionItem} isBasic={props.isBasic} label={props.fields.get(index).name} />
    ))}
  </div>
);

export function ListPermissions(props) {
  const loading = useSelector(loadingActions);

  if (loading) {
    return <Skeleton loading={true} paragraph={{ rows: 5 }} title={true} />;
  } else if (!props.fields.length) {
    return <Empty description='No permission found' />;
  }

  return (
    <div className='ListPermissions'>
      <div className='title-permission'>Action Permission</div>
      <Collapse defaultActiveKey={['1', '2', '3', '4']}>
        {props.fields.map((block, index) => (
          <Collapse.Panel header={props.fields.get(index).name} key={index + 1}>
            <FieldArrayCustomAction name={`${block}.permissions`} component={renderPermissions} isBasic={props.isBasic} />
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
}
