import { createSelector } from '@reduxjs/toolkit';
import lodash from 'lodash';
import { StateV2 } from '../../../../store/v2.type';
import { selectAllRoles } from './permissions.reducer';

const permissionsState = (state: StateV2) => state.v2.permissions;

const selectedRole = createSelector(permissionsState, state => state.selectedRole);
const rolesState = createSelector(permissionsState, state => state.roles);
export const loadingRoles = createSelector(rolesState, state => state.loading);
export const roleList = createSelector(rolesState, selectAllRoles);
export const editingRole = createSelector(permissionsState, state => state.editingRole);

const pagesState = createSelector(permissionsState, state => state.pages);
export const loadingPages = createSelector(pagesState, state => state.loading);
export const pageList = createSelector(pagesState, state => state.list);

const actionsState = createSelector(permissionsState, state => state.actions);
export const loadingActions = createSelector(actionsState, state => state.loading);
export const actionList = createSelector(actionsState, state => state.list);

export const isSubmitting = createSelector(permissionsState, state => state.submitting);
export const getPermissionInitialState = createSelector(selectedRole, pageList, actionList, (role, pages, actions) => ({ role, pages, actions }));
export const getActionEntities = createSelector(permissionsState, state => state.actionEntities);

export const getRole = createSelector(selectedRole, roleList, (roleId, list) => lodash.find(list, role => role.id === roleId));
