import { RouteIdEnum } from '../../enums/router-enums/route-id.enum';
import { BreadCrumbData } from './route-segment';

export interface RouterState {
  url: string;
  params: { [key: string]: string };
  routeId: RouteIdEnum;
  breadCrumbs: BreadCrumbData[];
}

// tslint:disable-next-line: typedef
export function mapRouterStateToRouterDetails(data): RouterState {
  return {
    url: data.pathname,
    params: data.query || {},
    routeId: data.routeId,
    breadCrumbs: [],
  };
}
