import React from 'react';

const TableOfContents = ({ item: { content_table, id } }) => {
  return (
    <div className='p-3' id='table_of_contents' data-id={id}>
      <h2> Table of contents </h2>
      {content_table &&
        content_table.map(row => (
          <div className='display-flex-between' style={{ alignItems: 'flex-start', fontWeight: row.level === 1 ? 'bolder' : 'normal' }}>
            <div style={{ textAlign: 'left', paddingLeft: row.level * 15 }}>
              <p> {row.title} </p>
            </div>
            <div style={{ textAlign: 'right' }}>{row.page_number}</div>
          </div>
        ))}
      {!content_table.length && 'Add Headings (Properties > Set heading) and they will appear in your table of contents.'}
    </div>
  );
};

export default TableOfContents;
