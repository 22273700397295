import { all } from '@redux-saga/core/effects';
// import { PayloadAction } from '@reduxjs/toolkit';
// import { all, put, takeEvery, select } from '@redux-saga/core/effects';

// import { PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects';

// import { pipelineListLight } from '../../../core/services/pipeline.service';
// import { getStageList } from '../../../core/services/stage.service';

// import { StageSummaryData } from '../../../core/models/stage/stage-summary-data';
// import { LazyLoadResponse } from '../../../core/models/queries/lazy-response.model';

// import { SelectItemData } from '../../../shared/interfaces/select-item';
// import { LocalStorageKeyEnum } from '../../../core/enums/local-storage.enum';
// import { setToLocalStorage } from '../../../core/utils/local-storage-utils';
// import { dealsByGridView } from '../../../core/services/deal.service';
// import { DealForGridViewSummaryData } from '../../../core/models/deal/deal-for-grid-view-summary';

// import * as pipelineActions from './pipeline.actions';
// import { getSelectedPipelineId } from './pipeline.selectors';
// import { ISelectedPipeline } from './types';
// // import { initializeMetaQueries, mapPaginationQueriesToMetaQueriesData } from '../../../core/models/queries/meta-query.model';

// // const queryPipeline = initializeMetaQueries();

// function* loadPipelineStagesEffect(): Generator<SelectEffect | Promise<StageSummaryData[]> | PutEffect, void, string & StageSummaryData[]> {
//   // try {
//   //   const selectedPipelineId: string = yield select(getSelectedPipelineId);

//   //   if (!selectedPipelineId) {
//   //     yield put(pipelineActions.loadPipelineList());
//   //   } else {
//   //     const stages = yield getStageList(selectedPipelineId);
//   //     yield put(pipelineActions.loadPipelineStageSuccess({ stages }));
//   //     yield put(pipelineActions.loadDealListForGridView());
//   //   }
//   // } catch (error) {
//   //   yield put({ type: pipelineActions.loadPipelineStageFail, error });
//   // }
// }

// function* loadPipelineListEffect(): Generator<Promise<LazyLoadResponse<SelectItemData<string>[]>> | PutEffect, void, LazyLoadResponse<SelectItemData<string>[]>> {
//   // try {
//   //   // TODO: fixme add initial queries
//   //   const pipelines = yield pipelineListLight({ queryParams: { page: 1 } });

//   //   if (!!pipelines.results.length) {
//   //     yield put(pipelineActions.setSelectedPipeline({ pipelineId: pipelines.results[0].value }));
//   //   }
//   // } catch (error) {
//   //   yield put({ type: pipelineActions.loadPipelineListFail, error });
//   // }
// }

// function* setSelectedPipelineEffect(action: PayloadAction<ISelectedPipeline>): Generator {
//   // yield setToLocalStorage(LocalStorageKeyEnum.DefaultPipelineId, action.payload.pipelineId);
//   // yield put(pipelineActions.loadDealListForGridView());
// }

// function* loadDealListForGridViewEffect(): Generator<SelectEffect | Promise<DealForGridViewSummaryData[]> | PutEffect, void, string & DealForGridViewSummaryData[]> {
//   // try {
//   //   const selectedPipelineId: string = yield select(getSelectedPipelineId);
//   //   const deals = yield dealsByGridView(selectedPipelineId);

//   //   yield put(pipelineActions.loadDealListForGridViewSuccess({ deals, reset: true }));
//   // } catch (error) {
//   //   yield put({ type: pipelineActions.loadDealListForGridViewFail, error });
//   // }
// }

// function* loadDealListForGridViewNextPageEffect(): Generator {
//   // TODO: load next page grid deals
//   try {
//   } catch (error) {}
// }

// function* loadPipelinesEffect(): Generator<Promise<LazyLoadResponse<SelectItemData<string>[]>> | PutEffect, void, LazyLoadResponse<SelectItemData<string>[]>> {
//   const lazyPipelines = yield pipelineListLight({ queryParams: mapPaginationQueriesToMetaQueriesData(queryPipeline) });
//   yield put(pipelineActions.loadTestPipelineSuccess({ pipelines: lazyPipelines.results, meta: lazyPipelines._meta, reset: true }));

//   try {
//   } catch (error) {}
// }

// function* loadNextPagePipelinesEffect(): Generator<Promise<LazyLoadResponse<SelectItemData<string>[]>> | PutEffect, void, LazyLoadResponse<SelectItemData<string>[]>> {
//   queryPipeline.currentPage++;
//   const lazyPipelines = yield pipelineListLight({ queryParams: mapPaginationQueriesToMetaQueriesData(queryPipeline) });
//   yield put(pipelineActions.loadTestPipelineSuccess({ pipelines: lazyPipelines.results, meta: lazyPipelines._meta, reset: false }));

//   try {
//   } catch (error) {}
// }

export function* watchPipeline(): Generator {
  yield all([
    // takeEvery(pipelineActions.loadPipelineStage.type, loadPipelineStagesEffect),
    // takeLatest(pipelineActions.loadPipelineList.type, loadPipelineListEffect),
    // takeLatest(pipelineActions.setSelectedPipeline.type, setSelectedPipelineEffect),
    // takeLatest(pipelineActions.loadDealListForGridView.type, loadDealListForGridViewEffect),
    // takeLatest(pipelineActions.loadDealListForGridViewNextPage.type, loadDealListForGridViewNextPageEffect),
    // takeLatest(pipelineActions.loadTestPipeline.type, loadPipelinesEffect),
    // takeLatest(pipelineActions.loadTestPipelineNextPage.type, loadNextPagePipelinesEffect),
  ]);
}

// takeLatest === switchMap
