import { Notifier } from 'SharedComponent/Notifier';
import api from './api';
import { message } from 'antd';
import { browserHistory } from 'react-router';
import { reset } from 'redux-form';

export const FREQUENCIES_LOADED = 'METRICS:metrics:frequencies:loaded';
export const LOADING_METRICS_GROUP = 'METRICS:metrics:group:loading';
export const METRICS_GROUP_LOADED = 'METRICS:metrics:group:loaded';
export const METRICS_GROUP_CREATED = 'METRICS:metrics:group:created';
export const METRICS_GROUP_DELETED = 'METRICS:metrics:group:deleted';
export const METRICS_GROUP_UPDATED = 'METRICS:metrics:group:updated';
export const UPLOAD_METRICS_GROUP_EXCEL = 'METRICS:metrics:group:upload:excel';

export const LOADING_METRIC_GROUP = 'METRICS:metric:group:loading';
export const METRIC_GROUP_LOADED = 'METRICS:metric:group:loaded';
export const CATEGORIES_LOADED = 'METRICS:metrics:categories:loaded';
export const LOADING_METRIC = 'METRICS:metric:loading';
export const METRIC_LOADED = 'METRICS:metric:loaded';
export const ADDED_METRIC_TO_GROUP = 'METRICS:group:metric:added';
export const METRIC_CATEGORY_DELETED = 'METRICS:group:metric:METRIC_CATEGORY_DELETED';
export const SEARCHING_GROUP_METRIC = 'METRICS:group:metric:SEARCHING_GROUP_METRIC';
export const METRIC_GENERATE_FILE = 'METRICS:group:metric:METRIC_GENERATE_FILE';
export const METRIC_SEND_REMINDER = 'METRICS:group:metric:METRIC_SEND_REMINDER';
export const METRIC_ONLINE_FORM_LOADED = 'METRICS:online:form:loaded';
export const METRIC_TEMPLATES_LOADED = 'METRICS:metrics:template:loaded';
export const METRIC_SAVE_TEMPLATE = 'METRICS:metrics:template:saved';

function normalizeMetric(group) {
  if (!group.is_custom) {
    return {
      ...group,
      items: group.metrics ? group.metrics.filter(it => it.is_used).map(it => it.metric_id) : [],
    };
  } else
    return {
      ...group,
      items: group.metrics ? group.metrics.filter(it => it.is_used) : [],
    };
}

function parseFrequency(frequency) {
  let list;
  if (!frequency) return [];
  if (Array.isArray(frequency)) {
    list = frequency.map(it => it.value);
  } else list = [frequency.value];
  return list;
}

export function loadFrequencies() {
  return dispatch => {
    api
      .get('/metric-frequency')
      .then(({ data }) => {
        dispatch({
          type: FREQUENCIES_LOADED,
          payload: data.results,
        });
      })
      .catch();
  };
}

export function loadMetricsGroup(deal_id) {
  return dispatch => {
    dispatch({ type: LOADING_METRICS_GROUP, payload: true });
    api
      .get(`/metric-group?filter[deal_id]=${deal_id}`)
      .then(({ data }) => {
        dispatch({
          type: METRICS_GROUP_LOADED,
          payload: data.results,
        });
      })
      .catch(err => dispatch({ type: LOADING_METRICS_GROUP, payload: false }));
  };
}

export function emptyMetricsGroup() {
  return dispatch => {
    dispatch({
      type: METRICS_GROUP_LOADED,
      payload: [],
    });
  };
}

export function createMetricGroup(group, RouteIndex) {
  return dispatch => {
    api
      .post('/metric-group', group)
      .then(({ data }) => {
        browserHistory.push(`/deals/${data.deal_id}/${RouteIndex}/metrics/${data.group_id}`);
      })
      .catch();
  };
}

export function updateMetricGroup(group) {
  return dispatch => {
    api
      .put(`/metric-group/${group.group_id}`, group)
      .then(({ data }) => {
        dispatch({
          type: METRICS_GROUP_UPDATED,
          payload: group,
        });
      })
      .catch();
  };
}

export function deleteMetricGroup(group) {
  return dispatch => {
    api
      .delete(`/metric-group/${group.group_id}`)
      .then(({ data }) => {
        dispatch({
          type: METRICS_GROUP_DELETED,
          payload: group.group_id,
        });
      })
      .catch();
  };
}

export function uploadExcel(object) {
  return dispatch => {
    const formData = new FormData();
    formData.append('metric_group_id', object.metric_group_id);
    formData.append('file', object.file);
    formData.append('period', object.period);
    formData.append('year', object.year);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    api
      .post('/metric-group/import', formData, config)
      .then(res => {
        Notifier({
          type: 'success',
          title: 'File uploaded successfully',
          message: '',
        });
      })
      .catch(({ response }) => {
        Notifier({
          type: 'error',
          title: response && response.data && response.data.message,
          message: 'Please try again',
        });
      });
  };
}

export function loadMetricGroup(group_id, frequency) {
  const body = { metric_group_id: group_id };
  if (frequency) {
    body.year = frequency.year ? [frequency.year] : [];
    body.period = frequency.period ? [frequency.period] : [];
  }
  return dispatch => {
    dispatch({ type: LOADING_METRIC_GROUP, payload: true });
    api
      .post(`/metrics/data`, body)
      .then(({ data }) => {
        dispatch({ type: METRIC_GROUP_LOADED, payload: data });
      })
      .catch(error => dispatch({ type: LOADING_METRIC_GROUP, payload: false }));
  };
}

export function generateExcel(metric) {
  return dispatch => {
    api
      .post(`/metric-group/export`, metric, {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => {
        if (metric.sendByEmail) {
          Notifier({
            type: 'success',
            title: 'Generated Excel has been sent to:',
            message: metric.email,
          });
        }
        if (metric.saveCopy) {
          const file = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const a = document.createElement('a');
          const fileURL = window.URL.createObjectURL(file);
          a.href = fileURL;
          a.download = `${metric.name}.xlsx`;
          a.click();
        }
        dispatch({
          type: METRIC_GENERATE_FILE,
          payload: metric.metric_group_id,
        });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Failed to generate excel',
          message: 'Please try again',
        });
      });
  };
}

export function dismissGroup() {
  return dispatch => {
    dispatch({ type: METRIC_GROUP_LOADED, payload: null });
  };
}

export function loadCategories() {
  return dispatch => {
    api
      .get('/metrics/categories')
      .then(({ data }) => {
        dispatch({
          type: CATEGORIES_LOADED,
          payload: data,
        });
      })
      .catch();
  };
}

export function loadMetricItems(metric_category, metric_group_id) {
  return dispatch => {
    if (!metric_category) return dispatch({ type: METRIC_LOADED, payload: null });
    dispatch({ type: LOADING_METRIC, payload: true });
    const metric = { metric_category, metric_group_id };
    api
      .post(`/metrics/categories/items`, metric)
      .then(({ data }) => {
        dispatch({
          type: METRIC_LOADED,
          payload: data,
        });
      })
      .catch();
  };
}

export function addMetric(metric) {
  return dispatch => {
    dispatch({ type: LOADING_METRIC_GROUP, payload: true });
    const newMetric = normalizeMetric(metric);
    api
      .post('/metrics', newMetric)
      .then(({ data }) => {
        dispatch({
          type: ADDED_METRIC_TO_GROUP,
          payload: data,
        });
      })
      .catch(err => dispatch({ type: LOADING_METRIC_GROUP, payload: false }));
  };
}

export function deleteMetric(category) {
  return dispatch => {
    const body = {
      group_id: category.group_id,
      metrics: category.metrics.map(it => it.metric_id),
    };
    api
      .post(`/metrics/delete`, body)
      .then(({ data }) => {
        dispatch({
          type: METRIC_CATEGORY_DELETED,
          payload: category.category_id,
        });
        Notifier({
          type: 'open',
          title: 'Metric deleted successfully',
          message: category.name,
        });
      })
      .catch(err => {
        Notifier({
          type: 'error',
          title: 'Failed to delete metric',
          message: 'Please try again',
        });
      });
  };
}

export function searchMetric(search) {
  return dispatch => dispatch({ type: SEARCHING_GROUP_METRIC, payload: search });
}

export function sendReminder(reminder) {
  return dispatch => {
    api
      .post('/metric-group/reminder', reminder)
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Reminder has been sent to:',
          message: reminder.email,
        });
        dispatch({
          type: METRIC_SEND_REMINDER,
          payload: data,
        });
      })
      .catch(err => {
        Notifier({
          type: 'error',
          title: 'Failed to send reminder',
          message: 'Please try again',
        });
      });
  };
}

export function onMetricValueUpdated(values) {
  return dispatch => {
    api.post('/metrics/value', values).then(res => {
      message.success('Metric value updated');
    });
  };
}

export function generateMetricForm(form) {
  return dispatch => {
    api
      .post(`/metric-group-form/${form.formKey}/form-period`, form)
      .then(({ data }) => {
        message.success('Metric Online Form generated');
        dispatch({
          type: METRICS_GROUP_UPDATED,
          payload: data,
        });
      })
      .catch(() => message.error('Metric Form not generated, please try again.'));
  };
}

export function loadMetricOnlineForm(formKey) {
  return dispatch => {
    dispatch({ type: LOADING_METRICS_GROUP, payload: true });
    api
      .post(`/metric-group-form/${formKey}/fields`)
      .then(({ data }) => {
        dispatch({
          type: METRIC_ONLINE_FORM_LOADED,
          payload: data,
        });
      })
      .catch(error => {
        dispatch({ type: LOADING_METRICS_GROUP, payload: false });
        message.error('Failed to fetch online form metric');
      });
  };
}

export function submitOnlineForm({ form_key, data, action, onsuccess }) {
  return dispatch => {
    dispatch({ type: LOADING_METRICS_GROUP, payload: true });
    api
      .post(`/metric-group-form/${form_key}/submit`, { data, action })
      .then(({ data }) => {
        dispatch({ type: LOADING_METRICS_GROUP, payload: false });
        dispatch(reset('metric-onlin-form-data'));
        if (onsuccess) onsuccess();
      })
      .catch(error => {
        dispatch({ type: LOADING_METRICS_GROUP, payload: false });
        message.error('Failed to submit online form data, please try again.');
      });
  };
}

export function saveFormForLater(formKey, data, onsuccess) {
  return dispatch => {
    api
      .post(`/metric-group-form/${formKey}/submit`, data)
      .then(({ data }) => {
        dispatch({ type: LOADING_METRICS_GROUP, payload: false });
        if (onsuccess) onsuccess();
      })
      .catch(error => {
        message.error('Failed to save online form data, please try again.');
      });
  };
}

export function loadMetricTemplates() {
  return dispatch => {
    api
      .get(`/metric-template`)
      .then(({ data }) => {
        dispatch({ type: METRIC_TEMPLATES_LOADED, payload: data });
      })
      .catch(error => {});
  };
}

export function saveMetricAsTemplate(body) {
  return dispatch => {
    api
      .post('/metric-template/save', body)
      .then(() => message.success('Metric saved as template.'))
      .catch(() => message.error('Failed to save metric as template, please try again.'));
  };
}
