import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Error from './Error';

import './TextField.scss';

class TextField extends Component {
  static propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    withIcon: PropTypes.bool,
    icon: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    copyToClipboard: PropTypes.bool,
  };
  static defaultProps = {
    value: '',
    type: 'text',
    onChange: new Function(),
    placeholder: '',
    withIcon: false,
    icon: '',
    label: '',
    required: false,
    disabled: false,
    copyToClipboard: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      type: '',
      tooltipMessage: 'Copy to clipboard',
    };

    this.showPassword = this.showPassword.bind(this);
    this.hidePassword = this.hidePassword.bind(this);
    // this._handleKeyDown = this._handleKeyDown.bind(this);
  }

  componentWillMount() {
    this.setState({
      type: this.props.type,
    });
  }

  showPassword() {
    this.setState({
      type: 'text',
    });
  }

  // _handleKeyDown(e) {
  //   if (e.key === 'Enter') {
  //     this.props.onPressEnter && this.props.onPressEnter();
  //   }
  // }

  hidePassword() {
    this.setState({
      type: 'password',
    });
  }

  render() {
    const props = this.props;
    return (
      <div className='TextField'>
        <div className='display-flex-between'>
          {props.label && (
            <div className={classNames('default-color', props.labelClass ? props.labelClass : 'input-label')}>
              {props.label}
              {props.required && (
                <span className='' style={{ color: '#ff8181' }}>
                  &nbsp;&#9679;
                </span>
              )}
            </div>
          )}
          {!props.errorDown && (
            <Error
              touched={props.meta && props.meta.touched}
              error={props.meta && props.meta.error}
              warning={props.meta && props.meta.warning}
              asyncValidating={props.meta.asyncValidating}
            />
          )}
        </div>
        <div className='TextField' style={{ ...this.props.style }}>
          <input
            {...props.input}
            value={props.input.value || ''}
            type={this.state.type}
            placeholder={props.placeholder || ''}
            style={{
              paddingLeft: props.withIcon || props.withCurrency ? '60px' : '10px',
              paddingRight: props.type === 'password' ? '50px' : '0px',
            }}
            disabled={props.disabled}
            onBlur={() => props.blur && props.blur()}
            // onKeyDown={this._handleKeyDown}
            ref={input => props.setFieldToBeFocused && props.setFieldToBeFocused(input)}
          />
          {props.withIcon && (
            <div>
              <i className={classNames('right-icon-details', props.icon)} aria-hidden='true'>
                {props.prefix}
              </i>
              <div className='divider' style={props.divider} />
            </div>
          )}

          {props.withCurrency && (
            <div>
              <i className={classNames('right-icon-details', props.icon)} aria-hidden='true'>
                {props.currencyCode}
              </i>
              <div className='currency_divider' style={props.divider} />
            </div>
          )}

          {props.copyToClipboard && (
            <span>
              <span
                className='material-icons icon-eye-details'
                onClick={e => {
                  this.props.googleAnayticsEvent();
                  this.copyToClipboard(props.input.value, e);
                }}
                onMouseOut={() =>
                  this.setState({
                    tooltipMessage: 'Copy to clipboard',
                  })
                }
                aria-hidden='true'
                data-tip
                data-for='copyToClipboared'
              >
                filter_none
              </span>
              <ReactTooltip id='copyToClipboared' type='dark' effect='float' place='top'>
                <span>{this.state.tooltipMessage}</span>
              </ReactTooltip>
            </span>
          )}
          {props.type === 'password' && (
            <span className={classNames('icon-eye-details', props.insideIcon || 'icon-eye')} aria-hidden='true' onMouseDown={this.showPassword} onMouseUp={this.hidePassword} />
          )}
        </div>
        {props.errorDown && <Error touched={props.meta.touched} error={props.meta.error} warning={props.meta.warning} />}
      </div>
    );
  }

  copyToClipboard(value, event) {
    event.stopPropagation();
    if (window.clipboardData && window.clipboardData.setData) {
      return clipboardData.setData('Text', value);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = value;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.select();
      try {
        this.setState({ tooltipMessage: 'Copied !' });
        return document.execCommand('copy');
      } catch (error) {
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }
}

export default TextField;
