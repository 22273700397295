import api from './api';
import moment from 'moment';
import objectToFormData from 'Utils/objToFormData';
import omit from 'lodash/omit';
import { Notifier } from 'SharedComponent/Notifier';
import { Mixpanel } from 'App/app';

export const LOADING_LAST_ACTIVITIES = 'dashboard:loading:lastActivities';
export const LOADED_LAST_ACTIVITIES = 'dashboard:loaded:lastActivities';
export const LOADING_UPCOMING_FILES = 'dashboard:loading:upcomingFiles';
export const LOADING_UPCOMING_EMAILS = 'dashboard:loading:upcomingEmails';
export const LOADED_UPCOMING_EMAILS = 'dashboard:loaded:upcomingEmails';
export const LOADED_UPCOMING_FILES = 'dashboard:loaded:upcomingFiles';
export const UPCOMING_MEETING_DELETED = 'dashboard:deleted:upcomingMeetings';
export const LOADING_MY_TASKS = 'dashboard:loading:myTasks';
export const LOADED_MY_TASKS = 'dashboard:loaded:myTasks';
export const SEARCH_ANYTHING = 'dashboard:search';
export const SEARCHING = 'dashboard:searching';
export const SEARCHING_LIST_LOADED = 'dashboard:searching:list:loaded';
export const TASK_ADDED = 'dashboard:tasks:added';
export const TASK_UPDATED = 'dashboard:tasks:updated';
export const TASK_DELETED = 'dashboard:tasks:deleted';
export const DASHBOARD_COUNTS_LOADED = 'dashboard:counts:loaded';
export const LAST_ACTIVITY_CREATED = 'dashboard:last:activity:created';
export const LOADING_LATEST_NOTES = 'dashboard:loading:latestNotes';
export const LOADED_LATEST_NOTES = 'dashboard:loaded:latestNotes';

function normalizeTask(task) {
  let guests = task.people ? task.people.filter(it => !it.is_user).map(it => it.value) : [];
  let attendees = task.people ? task.people.filter(it => it.is_user).map(it => it.value) : [];
  let due_date = task.due_date ? moment(task.due_date).tz(task.timezone).format('L HH:mm') : null;

  return {
    ...omit(task, ['people']),
    guests: JSON.stringify(guests),
    attendees: JSON.stringify(attendees),
    deal_id: task.deal ? task.deal.deal_id : null,
    due_date,
  };
}

export function loadLastActivities() {
  return dispatch => {
    dispatch({ type: LOADING_LAST_ACTIVITIES, payload: true });
    api
      .get('/notifications?page=1&sort=-notif_id')
      .then(({ data }) => {
        dispatch({
          type: LOADED_LAST_ACTIVITIES,
          payload: data.results,
        });
      })
      .catch(error => {
        dispatch({ type: LOADING_LAST_ACTIVITIES, payload: false });
      });
  };
}

export function loadUpcomingMeetings() {
  return dispatch => {
    dispatch({ type: LOADING_UPCOMING_MEETINGS, payload: true });
    api
      .get('me/events?sort=-start_date')
      .then(({ data }) => {
        dispatch({ type: LOADED_UPCOMING_MEETINGS, payload: data.results });
      })
      .catch(error => {
        dispatch({ type: LOADING_UPCOMING_MEETINGS, payload: false });
      });
  };
}

export function deleteUpcomingMeeting(event) {
  return dispatch => {
    api
      .delete(`/events/${event.event_id}`)
      .then(res => dispatch({ type: UPCOMING_MEETING_DELETED, payload: event.event_id }))
      .catch();
  };
}

export function loadMyTasks(status, page) {
  return dispatch => {
    dispatch({ type: LOADING_MY_TASKS });
    api
      .get(`/mytasks?filter[status]=${status}&per-page=${page}`)
      .then(({ data }) => {
        dispatch({
          type: LOADED_MY_TASKS,
          payload: data.results,
        });
      })
      .catch(error => {});
  };
}

export function addTask(task) {
  const config = {
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  };

  const newTask = normalizeTask(task);
  let formData = objectToFormData(newTask);

  return dispatch => {
    return new Promise((resolve, reject) => {
      const taskType = taskTypeGetter(newTask.eventype_id);
      api
        .post(`/tasks`, formData, config)
        .then(({ data }) => {
          dispatch({
            type: TASK_ADDED,
            payload: data,
          });
          resolve(data);
          Notifier({
            type: 'success',
            title: 'Success',
            message: 'Task has been successfully assigned!',
          });
          Mixpanel.track(`Task Added ${taskType}`, { task_id: data.task_id });
        })
        .catch(error => {
          reject(error);
          Notifier({
            type: 'error',
            title: 'Error',
            message: 'Please try again later',
          });
          Mixpanel.track(`Task Fialed Adding ${taskType}`, { error: error.response.data.message });
        });
    });
  };
}

export function lastActivityCreated(activity) {
  return dispatch => {
    dispatch({
      type: LAST_ACTIVITY_CREATED,
      payload: activity,
    });
  };
}

export function updateTask(task) {
  const config = {
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  };
  const newTask = normalizeTask(task);
  let oldFile = JSON.stringify(
    task.files.filter(function (file) {
      return file.hasOwnProperty('title');
    }),
  );
  let files = task.files.filter(function (file) {
    return file.hasOwnProperty('key');
  });

  const formattedTask = { ...newTask, oldFile, files };
  let formData = objectToFormData(formattedTask);
  return dispatch => {
    api
      .post(`/mytasks/${task.task_id}/update`, formData, config)
      .then(({ data }) => {
        dispatch({
          type: TASK_UPDATED,
          payload: data,
        });
      })
      .catch(error => {});
  };
}

export function updateDoneTask(task) {
  return dispatch => {
    api
      .put(`/mytasks/${task.task_id}`, task)
      .then(({ data }) => {
        dispatch({
          type: TASK_UPDATED,
          payload: data,
        });
      })
      .catch(error => {});
  };
}

export function deleteTask(task_id) {
  return dispatch => {
    api
      .delete(`/mytasks/${task_id}/delete`)
      .then(({ data }) => {
        dispatch({
          type: TASK_DELETED,
          payload: task_id,
        });
      })
      .catch(error => {});
  };
}

export function search(value) {
  return dispatch => {
    dispatch({ type: SEARCH_ANYTHING, payload: value });
  };
}

export function searchingFor(item, value) {
  return dispatch => {
    dispatch({ type: SEARCHING, payload: true });
    api
      .get(`/${item.route}?filter[${item.field}][like]=${value}`)
      .then(({ data }) => {
        dispatch({ type: SEARCHING_LIST_LOADED, payload: data.results });
      })
      .catch(error => dispatch({ type: SEARCHING, payload: false }));
  };
}

export function searchingEnd(item, value) {
  return dispatch => {
    dispatch({ type: SEARCHING_LIST_LOADED, payload: [] });
  };
}

export function loadDashboardCounts() {
  return dispatch => {
    api
      .get('/dashboard')
      .then(({ data }) => {
        dispatch({ type: DASHBOARD_COUNTS_LOADED, payload: data });
      })
      .catch(error => {});
  };
}

export function loadUpcomingEmails() {
  return dispatch => {
    dispatch({ type: LOADING_UPCOMING_EMAILS, payload: true });
    api
      .get('emails?filter[type]=2&sort=-created_at&per-page=20')
      .then(({ data }) => {
        dispatch({ type: LOADED_UPCOMING_EMAILS, payload: data.results });
      })
      .catch(error => {
        dispatch({ type: LOADING_UPCOMING_EMAILS, payload: false });
      });
  };
}

export function loadUpcomingNotes() {
  return dispatch => {
    dispatch({ type: LOADING_LATEST_NOTES, payload: true });
    api
      .get('notes?sort=-created_at&per-page=20')
      .then(({ data }) => {
        dispatch({ type: LOADED_LATEST_NOTES, payload: data.results });
      })
      .catch(error => {
        dispatch({ type: LOADING_LATEST_NOTES, payload: false });
      });
  };
}

export function loadUpcomingFiles() {
  return dispatch => {
    dispatch({ type: LOADING_UPCOMING_FILES, payload: true });
    api
      .get('files?sort=-created_at&per-page=20')
      .then(({ data }) => {
        dispatch({ type: LOADED_UPCOMING_FILES, payload: data.results });
      })
      .catch(error => {
        dispatch({ type: LOADING_UPCOMING_FILES, payload: false });
      });
  };
}

export function getFileById(file_id) {
  return dispatch => {
    api.get(`/files/view/${file_id}`).then(({ data }) => {
      window.open(data.url);
    });
  };
}
