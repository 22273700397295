import { IMap } from '../../core/utils/map-utils';
import { IconData, IconPrefixEnum } from '../interfaces/icon-data';
import { SelectItemData } from '../interfaces/select-item';

export enum DropdownTableActionsEnum {
  Arrange = 'arrange',
}

export namespace DropdownTableActionsEnum {
  export const toString: IMap<DropdownTableActionsEnum, string> = new IMap<DropdownTableActionsEnum, string>([[DropdownTableActionsEnum.Arrange, 'Arrange columns']]);

  export const toIcon: IMap<DropdownTableActionsEnum, IconData> = new IMap<DropdownTableActionsEnum, IconData>([
    [DropdownTableActionsEnum.Arrange, { name: 'ri-shuffle-fill', prefix: IconPrefixEnum.Remix }],
  ]);

  export function actions(enums: DropdownTableActionsEnum[]): SelectItemData<DropdownTableActionsEnum>[] {
    return enums.map(action => mapToActionMenuItem(action));
  }

  function mapToActionMenuItem(action: DropdownTableActionsEnum): SelectItemData<DropdownTableActionsEnum> {
    return {
      name: DropdownTableActionsEnum.toString.getValue(action),
      value: action,
      icon: DropdownTableActionsEnum.toIcon.getValue(action),
    };
  }
}
