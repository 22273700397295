import React from 'react';

import './error.component.scss';

interface ErrorProps {
  error: boolean;
  touched: boolean;
  warning: boolean;
}

export function Error({ touched, error, warning }: ErrorProps): JSX.Element {
  return <div className='Error'>{touched && ((error && <span className='error-message'>{error}</span>) || (warning && <span className='error-message'>{warning}</span>))}</div>;
}
