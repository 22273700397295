import React from 'react';
import { Input } from 'antd';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { Error } from '../Error/error.component';

import './text-field.component.scss';

interface TextFieldProps {
  meta: WrappedFieldMetaProps;
  input: WrappedFieldInputProps;
  placeholder?: string;
  disabled?: boolean;
}

export function TextField(props: TextFieldProps): JSX.Element {
  return (
    <div className='TextField'>
      <Error touched={props.meta && props.meta.touched} error={props.meta && props.meta.error} warning={props.meta && props.meta.warning} />
      <Input {...props.input} disabled={props.disabled} placeholder={props.placeholder} size='large' />
    </div>
  );
}
