import classNames from 'classnames';
import React, { Component, createRef } from 'react';
import { HotKeys } from 'react-hotkeys';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import ID from 'Utils/UUID';
import { Default } from '../Elements';
import { Slider } from 'antd';
import './Editor.scss';
import ContextMenu from '../../../deal/FilesContext/FileManager/components/ContextMenu';
import get from 'lodash/get';

const shortcuts = {
  DELETE_NODE: ['del', 'backspace'],
  MOVE_LEFT: ['ctrl+left', 'left'],
  MOVE_RIGHT: ['ctrl+right', 'right'],
  MOVE_UP: ['ctrl+up', 'up'],
  MOVE_DOWN: ['ctrl+down', 'down'],
  COPY_NODE: ['ctrl+c'],
  PASTE_NODE: ['ctrl+v'],
};

type Props = {
  showRuler?: string | boolean,
  width: 950,
  height: 672,
  objects?: {},
  addToScene: Function,
  activeElement: {
    properties: {
      location: { x: number, y: number },
      size: { w: number, h: number },
    },
  },
  makeElementActive: Function,
};

class Editor extends Component<Props> {
  _element = React.createRef();
  static defaultProps = {
    showRuler: true,
    objects: {},
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      editor_height: 0,
      editor_width: 0,
      popoverOpen: false,
      zoom: 100,
      contextMenu: false,
      contextPos: { x: 0, y: 0 },
      mousePos: { x: 0, y: 0 },
      contextMenuItem: null,
    };
    this.editor = createRef();
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  onDrop = e => {};
  handleDoubleClick = e => {
    let rect = e.target.getBoundingClientRect();
    let mouseX = e.clientX - rect.left;
    let mouseY = e.clientY - rect.top;
    let location = { x: mouseX, y: mouseY };
    let border = { show: false, color: 'white' };

    let properties = {
      location,
      alignment: '',
      border,
      color: {
        color: '#000',
        backgroundColor: 'transparent',
      },
      header: { backgroundColor: 'transparent' },
      size: { width: 300, height: 300 },
    };

    const object = {
      properties,
      key: 'text_content',
      htmlContent: '',
      id: ID.uuid(),
    };
    this.props.addField(object);
    this.props.makeElementActive(object.id);
  };
  onSelectEditor = e => {
    const { makeElementActive } = this.props;
    if (e.target.classList.contains('editor')) {
      makeElementActive(null);
    }
  };

  updatePosition(item, x, y) {
    const newItem = {
      ...item,
      properties: {
        ...item.properties,
        location: {
          x: Number(item.properties.location.x) + x,
          y: Number(item.properties.location.y) + y,
        },
      },
    };
    this.props.updateItem(newItem);
  }

  copyElement(item) {
    const newItem = {
      ...item,
      id: ID.uuid(),
      properties: {
        ...item.properties,
        location: {
          x: Number(item.properties.location.x) + 10,
          y: Number(item.properties.location.y) + 10,
        },
      },
    };
    this.props.copyElement(newItem);
  }
  shortcutHandlers = (action, item) => {
    const selected = this.props.selected;
    switch (action) {
      case 'MOVE_LEFT':
        this.updatePosition(selected, -1, 0);
        break;
      case 'MOVE_RIGHT':
        this.updatePosition(selected, 1, 0);
        break;
      case 'MOVE_UP':
        this.updatePosition(selected, 0, -1);
        break;
      case 'MOVE_DOWN':
        this.updatePosition(selected, 0, 1);
        break;
      case 'COPY_NODE':
        this.copyElement(selected);
        break;
      case 'PASTE_NODE':
        this.props.pasteElement();
        break;

      default:
        break;
    }
  };

  pasteHandler() {
    if (this.props.mode == 'view') return {};
    return {
      PASTE_NODE: () => this.props.pasteElement(),
    };
  }

  handleZoom = value => {
    this.editor.current.style.zoom = `${value}%`;
    this.setState({ zoom: value });
  };

  handleContextMenu = (item, x, y) => {
    let rect = this._element.current.getBoundingClientRect();
    let mX = x - rect.left; //x position within the element.
    let mY = y - rect.top; //y position within the element.
    let yVal = y - 250 > 250 ? y - 250 : y;
    this.setState({ contextMenuItem: item, pageContextMenu: false, contextMenu: true, contextPos: { x, y: yVal }, mousePos: { x: mX, y: mY } });
  };
  handleContextMenuCapture = e => {
    let rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left; //x position within the element.
    let y = e.clientY - rect.top; //y position within the element.
    let yVal = e.clientY - 250 > 250 ? e.clientY - 250 : e.clientY;
    this.setState({ contextMenuItem: null, pageContextMenu: true, contextMenu: true, contextPos: { x: e.clientX, y: yVal }, mousePos: { x, y } });
    e.preventDefault();
  };
  updateIDS = content => {
    let newDuplicatedPage = {};
    Object.keys(content).map(key => {
      if (!content[key].id) newDuplicatedPage[key] = content[key];
      else {
        let newId = ID.uuid();
        newDuplicatedPage[newId] = { ...content[key], id: newId };
      }
    });
    return newDuplicatedPage;
  };
  get contextMenuItems() {
    if (this.state.pageContextMenu) {
      let grid = this.props.tools.showGrid;
      let { orientation } = this.props.objects;
      return [
        { label: 'Add comment', icon: 'comment', onClick: () => this.props.openCommentModal(this.state.mousePos) },
        {
          label: 'Paste',
          icon: 'file_copy',
          onClick: () => this.props.pasteElement(this.state.mousePos),
        },
        {
          label: grid ? 'Hide Grid' : 'Show Grid',
          icon: grid ? 'grid_off' : 'grid_on',
          onClick: () => this.props.updateTools('showGrid', !grid),
        },
        {
          label: 'Add page',
          icon: 'filter_1',
          onClick: () => this.props.onAddPage(),
        },
        {
          label: 'Duplicate page',
          icon: 'flip_to_front',
          onClick: () => this.props.duplicatePage(this.updateIDS(this.props.objects)),
        },
        {
          label: 'Remove page',
          icon: 'delete',
          onClick: () => this.props.onRemovePage(this.props.tools.activePage),
        },
        {
          label: !orientation ? 'Set orientation to landscape' : 'Set orientation to portrait',
          icon: !orientation ? 'crop_landscape' : 'crop_portrait',
          onClick: () => {
            if (!orientation) {
              this.handleZoom(75);
            }
            this.props.togglePageOrientation();
          },
        },
      ];
    }
    let ItemMenu = [
      { label: 'Add comment', icon: 'comment', onClick: () => this.props.openCommentModal(this.state.mousePos) },
      {
        label: 'Copy',
        icon: 'file_copy',
        onClick: () => this.copyElement(this.state.contextMenuItem),
      },
      {
        label: 'Duplicate',
        icon: 'filter_1',
        onClick: () => {
          this.copyElement(this.state.contextMenuItem);
          setTimeout(this.props.pasteElement, 0);
        },
      },
      { label: 'Delete', icon: 'delete', onClick: () => this.props.removeComponent(this.state.contextMenuItem) },
      { label: 'Send to front', icon: 'flip_to_front', onClick: () => this.props.sendElementToFront(this.state.contextMenuItem) },
      { label: 'Send to back', icon: 'flip_to_back', onClick: () => this.props.sendElementToBack(this.state.contextMenuItem) },
    ];
    if (get(this.state, 'contextMenuItem.url') && !get(this.state, 'contextMenuItem.croppedImage')) {
      ItemMenu.unshift({ label: 'Crop image', icon: 'crop', onClick: () => this.props.updateTools('isCropping', this.state.contextMenuItem.id) });
      ItemMenu.unshift({ label: 'Set as page background', icon: 'wallpaper', onClick: () => this.props.openPageBGModal(this.state.contextMenuItem) });
    }
    return ItemMenu;
  }
  translateValues = (eHeight, eWidth, eX, eY) => {
    let {
      objects: { orientation },
      tools: { height, width },
      isNavigationSideBar,
    } = this.props;
    height = isNavigationSideBar ? height * 6 : height;
    width = isNavigationSideBar ? width * 6 : width;
    let newHeight, newWidth, newX, newY;
    switch (eX) {
      case 'right':
        newX = !orientation ? width - eWidth : height - eWidth;
        break;
      default:
        newX = eX;
        break;
    }
    switch (eY) {
      case 'bottom':
        newY = orientation ? width - eHeight : height - eHeight;
        break;
      default:
        newY = eY;
        break;
    }
    switch (eHeight) {
      default:
        newHeight = eHeight;
        break;
    }
    switch (eWidth) {
      case 'fullwidth':
        newWidth = !orientation ? width : height;
        break;
      default:
        newWidth = eWidth;
        break;
    }

    return [newHeight, newWidth, newX, newY];
  };
  handleCommentClick = (e, comment) => {
    this.props.openCommentModal(undefined, comment);
  };

  handleMetricReload = item => {
    let metric_group_id = get(item, 'reload_data.metric_group_id');
    let period = get(item, 'reload_data.period');
    let type = get(item, 'reload_data.type');

    if (type === 'metric' && metric_group_id && period) {
      return this.props.getMetricData(item, metric_group_id, period);
    }
    if (type === 'portfolio') {
      let portfolio_id = get(item, 'reload_data.portfolio_id');
      return this.props.getPortfolioData(item, portfolio_id);
    }
    if (type === 'portfolio_pie') {
      let portfolio_id = get(item, 'reload_data.portfolio_id');
      let field_name = get(item, 'reload_data.field_name');
      return this.props.getPortfolioByType(portfolio_id, field_name, item);
    }
    if (type === 'deal_card') {
      let deal_id = get(item, 'reload_data.deal_id');
      let field_name = get(item, 'reload_data.field_name');
      return this.props.getDealData(item, deal_id);
    }

    if (type === 'contact_card') {
      let contact_id = get(item, 'reload_data.contact_id');
      let field_name = get(item, 'reload_data.field_name');
      return this.props.getContactData(item, contact_id);
    }
    if (type === 'dashboard_stats') {
      let metric = get(item, 'reload_data.metric');
      let category = get(item, 'reload_data.category');
      let ids = get(item, 'reload_data.ids');
      return this.props.getPipelineCardData(item, metric, category, ids);
    }
    if (type === 'deal_rounds') {
      let deal_id = get(item, 'reload_data.deal_id');
      let deal_name = get(item, 'reload_data.deal_name');
      let stat = get(item, 'reload_data.stat');
      return this.props.getDealRounds(item, deal_id, deal_name, stat);
    }

    if (type === 'score_card') {
      let operator = get(item, 'reload_data.operator');
      return this.props.getScoreCardData(item, operator);
    }
  };
  renderGuide = (axis, position, additionalClass) => {
    var className = 'guide axis-' + axis;
    if (additionalClass) className += ' ' + additionalClass;
    return <div key={ID.uuid()} className={className} style={{ [axis === 'x' ? 'left' : 'top']: position }} />;
  };
  renderGuides = axis => {
    let edges = this.props.tools.edges;
    let guides = [];

    for (var i = 0; i < edges[axis].length; i++) {
      guides.push(this.renderGuide(axis, edges[axis][i], 'static'));
    }
    return guides;
  };
  renderBox = () => {
    if (!this.state.boxOn) return null;
    return (
      <div
        style={{
          top: this.state.boxTop,
          left: this.state.boxLeft,
          height: this.state.boxHeight,
          width: this.state.boxWidth,
          position: 'absolute',
          background: '#40a9ff80',
        }}
      />
    );
  };
  getBackgroundOptions = bg => {
    let styleObj = {};
    if (!bg) return styleObj;
    styleObj.backgroundColor = bg.color || 'white';
    if (bg.bg_url) styleObj.backgroundImage = `url(${bg.bg_url})`;
    if (bg.repeat) styleObj.backgroundRepeat = bg.repeat;
    if (bg.size) styleObj.backgroundSize = bg.size;
    if (bg.posX) styleObj.backgroundPositionX = bg.posX;
    if (bg.posY) styleObj.backgroundPositionY = bg.posY;
    styleObj.backgroundSize = bg.size || 'contain';
    return styleObj;
  };
  handlePageMouseDown = e => {
    let rect = this._element.current.getBoundingClientRect();
    let mouseX = e.clientX - rect.left;
    let mouseY = e.clientY - rect.top;
    this.setState({
      boxOn: true,
      boxStartTop: mouseY,
      boxStartLeft: mouseX,
      boxLeft: mouseX,
      boxTop: mouseX,
      boxHeight: 0,
      boxWidth: 0,
    });
  };
  handlePageMouseUp = e => {
    if (this.props.mode !== 'edit') return null;

    if (this.state.boxWidth > 10 || this.state.boxHeight > 10) {
      let bounds = {
        start: {
          x: this.state.boxLeft,
          y: this.state.boxTop,
        },
        end: {
          x: this.state.boxLeft + this.state.boxWidth,
          y: this.state.boxTop + this.state.boxHeight,
        },
      };

      this.props.updateSelectedItems(bounds);
    } else {
      if ('buttons' in e) {
        if (e.buttons == 1) {
          this.props.clearSelectedItems();
        }
      }
    }

    this.setState({
      boxOn: false,
      boxTop: 0,
      boxHeight: 0,
      boxWidth: 0,
      boxLeft: 0,
    });
  };

  handlePageMouseMove = e => {
    if (!this.state.boxOn) return null;
    if (!this._element.current) return null;
    let rect = this._element.current.getBoundingClientRect();
    let boxEndLeft = e.clientX - rect.left;
    let boxEndTop = e.clientY - rect.top;

    this.setState({
      boxWidth: Math.abs(this.state.boxStartLeft - boxEndLeft),
      boxHeight: Math.abs(this.state.boxStartTop - boxEndTop),
      boxEndLeft: boxEndLeft,
      boxEndTop: boxEndTop,
      boxLeft: this.state.boxStartLeft < boxEndLeft ? this.state.boxStartLeft : boxEndLeft,
      boxTop: this.state.boxStartTop < boxEndTop ? this.state.boxStartTop : boxEndTop,
    });
  };
  render() {
    const { objects, tools, selected, loading, mode } = this.props;
    const { orientation, comments, page_background, ...elements } = objects;
    const style = {
      height: `${orientation ? tools.width : tools.height}px`,
      width: orientation ? tools.height : tools.width,
      margin: 'auto',
      position: 'relative',
    };
    let isEdit = mode === 'edit';
    let grid_style = mode == 'view' ? '' : tools.showGrid ? 'editor-grid-details' : '';
    let edges = mode === 'edit' && this.props.tools.edges && this.props.tools.showGuides && this.props.selected;
    let hasSpace = !this.props.hasSpaceTopBottom ? '' : 'editor-has-space';
    let backgroundOptions = this.getBackgroundOptions(page_background);
    let editorStyle = { ...backgroundOptions, height: `${orientation ? tools.width : tools.height}px`, width: `${orientation ? tools.height : tools.width}px` };
    // let comments = [{x: 50, y: 50, comment:"hi", user:"amer", time: Date.now()}]
    if (Array.isArray(this.props.report) && !this.props.report.length) {
      return (
        <div className='display-flex-center cursor-pointer' onClick={this.props.onAddPage} style={{ height: '100vh', width: '100%' }}>
          {' '}
          Click to add new page.{' '}
        </div>
      );
    }
    let rectBox = !this.props.selected && isEdit;
    return (
      <div className='Editor'>
        <div ref={this.editor} className={mode == 'view' ? 'display-flex-center' : ''}>
          <div onClickCapture={this.onSelectEditor} onDragOver={e => e.preventDefault()} onDrop={e => this.onDrop(e)} className='' style={style}>
            <HotKeys keyMap={shortcuts} handlers={this.pasteHandler()} className='display-flex-center'>
              <div
                className={classNames('editor', grid_style, hasSpace)}
                style={editorStyle}
                id={this.props.id}
                data-orientation={orientation ? 'l' : 'p'}
                ref={this._element}
                onDoubleClick={this.handleDoubleClick}
                onContextMenuCapture={this.handleContextMenuCapture}
                onMouseDown={this.handlePageMouseDown}
                onMouseMove={this.handlePageMouseMove}
                onMouseUp={this.handlePageMouseUp}
              >
                {mode != 'view' && (
                  <ContextMenu
                    contextId={'clickable-area'}
                    show={this.state.contextMenu}
                    xOffset={this.state.contextPos.x}
                    yOffset={this.state.contextPos.y}
                    closeOnClick
                    closeOnClickOut
                    onClose={() => this.setState({ contextMenu: false })}
                    items={this.contextMenuItems}
                  />
                )}

                {elements &&
                  Object.keys(elements).map(object => {
                    const { properties, ...restOfItems } = elements[object];
                    let {
                      size: { height, width },
                      location: { x, y },
                    } = properties;
                    // let newHeight = this.translateHeight(height);
                    // let newWidth = this.translateWidth(width);
                    // let newX = this.translateX(x);
                    // let newY = this.translateY(y);
                    let [newHeight, newWidth, newX, newY] = this.translateValues(height, width, x, y);
                    const item = {
                      ...restOfItems,
                      properties: {
                        ...properties,
                        size: {
                          height: newHeight,
                          width: newWidth,
                        },
                        location: {
                          x: newX,
                          y: newY,
                        },
                      },
                    };
                    return (
                      <Default
                        item={item}
                        context={item}
                        id={item.id}
                        key={String(item.id + tools.activePage)}
                        editor_height={tools.height}
                        editor_width={tools.width}
                        onContextMenu={this.handleContextMenu}
                        removeComponent={() => this.props.removeComponent(item)}
                        reloadMetrics={() => this.handleMetricReload(item)}
                        removeSharedComponent={() => this.props.removeSharedComponent(item)}
                        dropElement={() => this.props.dropElement(item.id)}
                        mode={mode}
                        isNavigationSideBar={this.props.isNavigationSideBar}
                        tools={this.props.tools}
                        editorPageNumber={this.props.editorPageNumber}
                        handleShortcut={(action, item) => this.shortcutHandlers(action, item)}
                        zoom={this.props.zoom || 1}
                      />
                    );
                  })}
                {this.props.hasComments &&
                  comments &&
                  comments.map(comment => {
                    return (
                      <div
                        key={comment.id}
                        title={`${comment.fullname}: ${comment.comment}`}
                        onClick={e => this.handleCommentClick(e, comment)}
                        className='comment-dot display-flex-center'
                        style={{ top: comment.y, right: -17.5, borderColor: 'white', backgroundColor: 'white', boxShadow: '0 0 5px -2px black' }}
                      >
                        <span className='material-icons'> insert_comment </span>
                      </div>
                    );
                  })}
                {edges && this.renderGuides('x')}
                {edges && this.renderGuides('y')}

                {rectBox && this.renderBox()}
              </div>
            </HotKeys>
          </div>
        </div>
        {mode != 'view' && (
          <div className='shortcut-popup display-flex-center'>
            {/* <div className="mr-2">
              <i className="material-icons material-zoom-inout mr-2" data-tip="Zoom in" onClick={() => this.props.zoomInOut("+")}>zoom_in</i>
              <i className="material-icons material-zoom-inout" data-tip="Zoom out" onClick={() => this.props.zoomInOut("-")}>zoom_out</i>
            </div> */}
            <div className='zoom-slider'>
              <Slider tipFormatter={value => `${value}%`} min={50} max={100} value={this.state.zoom} onChange={this.handleZoom} />
            </div>
            <div className='mr-2' data-tip='Keyboard Shortcut Help'>
              <div className='button-shortcut' id='Popover-shortcut-help' onClick={this.toggle}>
                <i className='material-icons'>keyboard</i>
              </div>
              <Popover placement='top' trigger='legacy' isOpen={this.state.popoverOpen} target='Popover-shortcut-help' toggle={this.toggle}>
                <PopoverHeader>Keyboard Shortcut Help</PopoverHeader>
                <PopoverBody>
                  <div className='mb-2'>
                    <span className='shortcut-key mr-2'>del, backspace</span>
                    <span className=''>Remove card</span>
                  </div>
                  <div className='mb-2'>
                    <span className='shortcut-key mr-2'>Ctrl + c</span>
                    <span className=''>Copy card</span>
                  </div>
                  <div className='mb-2'>
                    <span className='shortcut-key mr-2'>Ctrl + v</span>
                    <span className=''>Paste copied card</span>
                  </div>
                  <div className='mb-2'>
                    <span className='shortcut-key mr-2'>↑</span>
                    <span className=''>Move selected card "up"</span>
                  </div>
                  <div className='mb-2'>
                    <span className='shortcut-key mr-2'>↓</span>
                    <span className=''>Move selected card "down"</span>
                  </div>
                  <div className='mb-2'>
                    <span className='shortcut-key mr-2'>←</span>
                    <span className=''>Move selected card "left"</span>
                  </div>
                  <div className='mb-2'>
                    <span className='shortcut-key mr-2'>→</span>
                    <span className=''>Move selected card "right"</span>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Editor;
