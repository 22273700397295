import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection, change, touch } from 'redux-form';
import Button from 'react-bootstrap/lib/Button';
import DialogBox from 'SharedComponent/DialogBox';
import * as validators from 'Utils/validators';
import SubmitButton from 'SharedComponent/SubmitButton';
import MetricPeriod from '../../../../deal/metrics/MetricPeriod';

import './PeriodModal.scss';

class PeriodModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year_to: null,
      period_to: null,
    };
  }

  displayPeriod(from, to) {
    const start = `${from.period ? from.period.label : 'Year'} ${from.year.label}`;
    const end = `${to.period ? to.period.label : 'Year'} ${to.year.label}`;
    if (start == end) return start;
    return `${start} to ${end}`;
  }

  onSubmit(values) {
    const period = {
      ...this.props.initialValues,
      ...values,
      label: this.displayPeriod(values.from, values.to),
    };

    this.props.updatePeriod(period);
    this.props.reset();
    this.setState({ year_to: null, period_to: null });
    this.props.onClose();
  }

  render() {
    const { initialValues } = this.props;
    if (!this.props.show) return null;
    return (
      <div className='PeriodModal'>
        <DialogBox isVisible onClickOut={this.props.onClose} onClose={this.props.onClose} withoutClose={true} className='small-dialog'>
          <div className='header-dialog'>UPDATE REPORT PERIOD</div>
          <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
            <div className='modal-body'>
              <Field
                name='from'
                haslabel={true}
                label='Report start from'
                component={MetricPeriod}
                placeholderYear='Year'
                placeholder='Period'
                frequency={initialValues.frequency_id}
                startYear={new Date().getFullYear() - 11}
                endYear={new Date().getFullYear() + 5}
                startPeriod={1}
                disabled={false}
                multiYear={false}
                multiPeriod={false}
                has_period={initialValues.frequency_id != 4}
                validate={[initialValues.frequency_id != 4 ? validators.period : validators.year]}
                onPeriodChange={e => {
                  this.setState({ year_to: e.year, period_to: e.period });
                  this.props.dispatch(change('update-report-period-modal', 'to', null));
                  this.props.dispatch(touch('update-report-period-modal', 'to'));
                }}
                flexYear='flex-2'
                flexPeriod='flex-3'
                disabled={false}
              />
              <div className='mt-2'>
                <Field
                  name='to'
                  haslabel={true}
                  label='Report end at'
                  component={MetricPeriod}
                  placeholderYear='Year'
                  placeholder='Period'
                  frequency={initialValues.frequency_id}
                  startYear={this.state.year_to}
                  endYear={new Date().getFullYear() + 5}
                  startPeriod={this.state.period_to}
                  disabled={false}
                  multiYear={false}
                  multiPeriod={false}
                  has_period={initialValues.frequency_id != 4}
                  validate={[initialValues.frequency_id != 4 ? validators.period : validators.year]}
                  onPeriodChange={() => {}}
                  flexYear='flex-2'
                  flexPeriod='flex-3'
                  disabled={false}
                />
              </div>
            </div>
            <hr className='hr-details' />
            <div className='display-flex-start'>
              <div className='m-3 display-flex-start'>
                <SubmitButton
                  label='Cancel'
                  icon='icon-close'
                  style={{
                    width: '150px',
                    color: '#53627c',
                    backgroundColor: '#fff',
                    border: '1px solid #e1e4e8',
                  }}
                  onClick={() => {
                    this.props.reset();
                    this.setState({ year_to: null, period_to: null });
                    this.props.onClose();
                  }}
                />
                <div className='ml-2'>
                  <Button type='submit' className='submit-btn-p display-flex-center'>
                    <i className='icon-refresh mr-2' />
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </DialogBox>
      </div>
    );
  }
}

PeriodModal = reduxForm({
  form: 'update-report-period-modal',
  destroyOnUnmount: false,
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
})(PeriodModal);

export default PeriodModal;
